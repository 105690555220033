import {
  Button,
  Form,
  Input,
  InputNumber,
  List,
  Space,
  Typography,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useGetLocale,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { enUS, ru } from 'date-fns/locale';
import { Line, LineConfig } from '@ant-design/charts';
import {
  ArrowLeftOutlined,
  BulbOutlined,
  EditOutlined,
  HourglassOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  emptyExposition,
  emptySud,
  situationInitialValues,
  User,
  UserRole,
} from '@cc/schema';
import {
  deepOmit,
  formatTimestampToReadableString,
  generateToken,
} from '@cc/utils';
import routerProvider from '@pankod/refine-react-router-v6';
import {
  Breadcrumb,
  Col,
  Drawer,
  message,
  notification,
  Row,
  Select,
  Slider,
  Table,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { useWatch } from 'antd/lib/form/Form';
import {
  addSeconds,
  differenceInSeconds,
  format,
  formatDuration,
  intervalToDuration,
  parseISO,
  set,
} from 'date-fns';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { debounce, flatten } from 'lodash';
import qs from 'qs';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'rooks';
import { DateFnsTimePicker } from '../../components/Pickers';
import { useSubscriptionWarnings } from '../../hooks';
import { IUser } from '../../providers';
import {
  Exposure,
  ExposureStimulus,
  GenerateDalleImageResponse,
} from '../../schema/be-graphql-generated';
import './styles.less';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { useActor } from '@xstate/react';
import { TherapistActivityReactContext } from '../../App';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';

const { Text, Title } = Typography;

export const EditExposureSituation: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { i18n } = useTranslation();

  const location = routerProvider.useLocation();

  const lastGeneratedImageUrlRef = useRef<string | null>(null);

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { innerWidth } = useWindowSize();

  const {
    clientId: locationClientId,
    exposureStimulusId: savedExposureStimulusId,
  } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const [exposureStimulusId, setExposureStimulusId] = useState(
    savedExposureStimulusId as string,
  );

  const [clientId, setClientId] = useState<string | undefined>(
    locationClientId as string,
  );

  useActivityTracking(exposureStimulusId);

  const [clientUid, setClienttUid] = useState<string | undefined>();

  useEffect(() => {
    therapistActivitySend({
      type: 'ENTITY_CLIENT_ID_CHANGED',
      clientId,
    });
  }, [clientId]);

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const [files, setFiles] = useState<UploadFile[]>([]);

  const [expositionIdx, setExpositionIdx] = useState(0);

  const {
    data: exposureStimulusData,
    isLoading: exposureStimulusLoading,
    refetch: refetchStimulusExposure,
    error: exposureStimulusError,
  } = useCustom<ExposureStimulus>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'exposureStimulus',
      fields: [
        '_id',
        'rank',
        'title',
        'description',
        'exposureId',
        'imageUrls',
        'imagePrompt',
        'sud',
        'avoidance',
        'worstThingThatCouldHappen',
        {
          expositions: [
            'createdAt',
            'anticipatedSud',
            'reactionNotes',
            {
              suds: ['sud', 'reactedAt'],
            },
          ],
        },
      ],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: exposureStimulusId,
        },
      },
    },
  });

  const [exposureId, setExposureId] = useState('');

  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    data: exposureData,
    isLoading: exposureLoading,
    refetch: refetchExposure,
    error: exposureError,
  } = useCustom<Exposure>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'exposure',
      fields: ['userId', 'safetyBehaviorsToAvoid'],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: exposureId,
        },
      },
    },
  });

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'uid',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  useEffect(() => {
    const getClientUidData = async () => {
      if (exposureId && !clientUid) {
        const res = await refetchExposure();
        if (res.data?.data?.userId) {
          setClientId(res.data?.data?.userId);

          let refetchedClients: User[] = [];
          if (!clients?.data) {
            const refreshedClients = await refetchClients();
            // @ts-ignore
            refetchedClients = refreshedClients?.data?.data || [];
          }

          // @ts-ignore
          const clientsToUse = clients?.data?.data?.length
            ? // @ts-ignore
              clients?.data?.data
            : refetchedClients?.length
            ? refetchedClients
            : [];

          // @ts-ignore
          const uid = clientsToUse?.find(
            // @ts-ignore
            (c) => c._id === res.data?.data?.userId,
          )?.uid;

          if (uid) {
            setClienttUid(uid);
          }
        }
      }
    };
    getClientUidData();
    // @ts-ignore
  }, [exposureId, !clients?.data?.data?.length]);

  const imagePrefix = `tools/${clientUid || ''}/exposureStimuli/${(
    (exposureStimulusId as string) || ''
  ).replace('exposureStimuli/', '')}/`;

  const [isStopwatchRunning, setIsStopwatchRunning] = useState<boolean>(false);
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isStopwatchRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      interval && clearInterval(interval);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [isStopwatchRunning]);

  const {
    mutate: upsertExposureStimulus,
    data: upsertExposureStimulusData,
    isLoading: upsertExposureStimulusLoading,
    error: upsertExposureStimulusError,
  } = useCustomMutation<{ exposureStimulus: ExposureStimulus }>();

  const memoizedAvoidanceOptions = useMemo(() => {
    return t('shared.exposure.avoidances', {
      returnObjects: true,
      // @ts-ignore
    }).map((avoidance: string, index: number) => (
      <Select.Option key={index} value={index}>
        {avoidance}
      </Select.Option>
    ));
  }, [i18n.language]);

  const [exposureForm] = Form.useForm<typeof emptyExposition>();

  const [form] = Form.useForm<typeof situationInitialValues>();

  const imagePrompt = useWatch('imagePrompt', form);

  useEffect(() => {
    if (exposureStimulusData?.data?._id) {
      setExposureStimulusId(exposureStimulusData?.data?._id);
      setExposureId(exposureStimulusData?.data?.exposureId || '');

      const { _id, createdAt, updatedAt, __typename, userId, ...rest } =
        deepOmit(exposureStimulusData?.data || {}, ['__typename']);
      form.setFieldsValue({
        ...rest,
      });

      setFiles(
        (exposureStimulusData?.data?.imageUrls || []).map((url, idx) => ({
          uid: String(idx + 1),
          name: `photo-${idx + 1}.png`,
          status: 'done',
          // @ts-ignore
          url: url || '',
        })),
      );
    }
  }, [exposureStimulusData]);

  const [imageLoading, setImageLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const {
    mutate: generateAiImage,
    data: generateAiImageData,
    isLoading: generateAiImageLoading,
    error: generateAiImageError,
  } = useCustomMutation<GenerateDalleImageResponse>();

  const getLocale = useGetLocale();
  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const generateNewImage = async () => {
    await generateAiImage({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'generateDalleImage',
        fields: ['contentUrl', 'status', 'errorMessage', 'code'],
        variables: {
          content: {
            name: 'content',
            type: 'String!',
            value: imagePrompt,
          },
          lng: {
            name: 'lng',
            type: 'String!',
            value: currentLocale?.startsWith('ru') ? 'ru' : 'en',
          },
        },
      },
    });
  };

  useEffect(() => {
    const processImage = async () => {
      if (generateAiImageData?.data) {
        if (
          generateAiImageData?.data?.errorMessage &&
          generateAiImageData?.data?.code === 'ContentFilter'
        ) {
          notification.error({
            message: t('shared.exposure.imageGenerationError'),
            description: t('shared.exposure.contentFilter'),
            duration: 20,
          });
          return;
        }

        if (
          generateAiImageData?.data?.contentUrl !== null &&
          lastGeneratedImageUrlRef.current ===
            generateAiImageData?.data?.contentUrl
        ) {
          return;
        }

        setImageLoading(true);

        if (generateAiImageData?.data?.contentUrl) {
          const response = await fetch(generateAiImageData?.data?.contentUrl);

          if (!response.ok) {
            setImageLoading(false);
            throw new Error(t('common.failedToFetchImage'));
          }
          const blob = await response.blob();

          if (blob) {
            const storage = getStorage();
            const imageRef = ref(storage, imagePrefix + generateToken());
            try {
              const image = await uploadBytes(imageRef, blob);

              const imageUrl = await getDownloadURL(imageRef);

              setFiles([
                ...files,
                {
                  uid: String(files.length + 1),
                  name: `photo-${files.length + 1}.png`,
                  status: 'done',
                  // @ts-ignore
                  url: imageUrl || '',
                },
              ]);

              form!.setFields([
                {
                  name: 'imageUrls',
                  touched: true,
                  value: [...(form.getFieldValue('imageUrls') || []), imageUrl],
                },
              ]);

              lastGeneratedImageUrlRef.current =
                generateAiImageData?.data?.contentUrl;

              setTimeout(() => updateExposureStimulus(), 50);

              message.success(t('common.uploadSuccess'));
            } catch (e) {
              message.error(String(e));

              setImageLoading(false);
            }
          }
        }

        setImageLoading(false);
      }
    };
    processImage();
  }, [JSON.stringify(generateAiImageData?.data)]);

  const handlePreview = async (file: any) => {
    if (file.url) {
      setPreviewImage(file.url);
    } else if (file.response) {
      setPreviewImage(file.response.url);
    }
    startExposure();
  };

  const updateExposureStimulus = useCallback(
    async (passedValues?: typeof situationInitialValues) => {
      const values = passedValues || form.getFieldsValue();
      const { _id, createdAt, updatedAt, __typename, userId, ...rest } =
        deepOmit(values, ['__typename']);

      const res = await upsertExposureStimulus({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertExposureStimulus',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'ExposureStimulusInput',
              value: {
                data: {
                  ...rest,
                },
                where: {
                  clientId,
                  exposureId,
                  _id: exposureStimulusId || null,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.exposure.situationOrStimulusSaveSuccess'));

      setTimeout(() => {
        refetchStimulusExposure();
      }, 300);
    },
    [clientId, exposureId, exposureStimulusId],
  );

  const expositionsStats = useMemo(() => {
    const data = flatten(
      (form.getFieldValue('expositions') || [])
        // @ts-ignore
        .filter((e) => e.suds.length > 0)
        // @ts-ignore
        .map((ex, exIdx, exArr) => {
          // @ts-ignore
          return ex.suds.map((e, idx, arr) => {
            return {
              type: t('shared.exposure.title') + ' ' + (exIdx + 1),
              count: e.sud,
              date:
                idx === 0
                  ? 0
                  : differenceInSeconds(
                      parseISO(e.reactedAt),
                      parseISO(arr[0].reactedAt),
                    ),
            };
          });
        }),
    )
      .sort((a, b) => {
        // @ts-ignore
        return a.date - b.date;
      })
      .map((e, idx) => {
        return {
          // @ts-ignore
          ...e,
          date: `${format(
            addSeconds(
              set(new Date(), {
                hours: 0,
                minutes: 0,
                seconds: 0,
              }),
              // @ts-ignore
              e.date,
            ),
            currentLocale?.startsWith('ru') ? 'H:mm:ss' : 'h:mm:ss aaa',
          )}`,
        };
      });

    return {
      smooth: true,
      xField: 'date',
      yField: 'count',
      point: {
        size: 5,
        shape: 'diamond',
      },
      seriesField: 'type',
      lineStyle: {
        lineWidth: 3,
      },
      data,
      legend: {
        layout: 'horizontal',
      },
      xAxis: {
        title: {
          text: t('shared.exposure.expositionDuration'),
        },
      },

      yAxis: {
        title: {
          text: t('shared.exposure.sud'),
        },
      },
    };
  }, [JSON.stringify(form.getFieldValue('expositions'))]);

  const onValuesChange = useCallback(
    debounce(
      async (
        changedValues: Partial<typeof situationInitialValues>,
        allValues: typeof situationInitialValues,
      ) => {
        updateExposureStimulus(allValues);
      },
      700,
    ),
    [updateExposureStimulus],
  );

  const startExposure = () => {
    const oldValues = form.getFieldValue('expositions') || [];
    form.setFieldValue('expositions', [...oldValues, { ...emptyExposition }]);
    setExpositionIdx(oldValues.length);
    setOpenDrawer(true);
    const neededExpositon =
      form.getFieldValue('expositions')?.[oldValues.length];
    exposureForm.setFieldsValue({
      ...neededExpositon,
    });
    setTime(0);
  };

  const onCloseDrawer = () => {
    setIsStopwatchRunning(false);
    setOpenDrawer(false);
    if (!exposureForm.getFieldValue('suds').length) {
      const newExpositions = [...form.getFieldValue('expositions')];
      newExpositions.splice(expositionIdx, 1);
      form.setFieldValue('expositions', newExpositions);
    }

    if (previewImage) {
      setPreviewImage('');
    }
  };
  useEffect(() => {
    if (!!upsertExposureStimulusData?.data && exposureStimulusId) {
      therapistActivitySend({
        type: 'UPDATED',
        resource: exposureStimulusId,
      });
    }
  }, [upsertExposureStimulusData]);

  return (
    <Form<typeof situationInitialValues>
      form={form}
      onValuesChange={onValuesChange}
      onFinish={(values) => {}}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      labelWrap
    >
      <List
        title={t('shared.exposure.situationOrStimulus')}
        headerButtons={
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 10,
            }}
          ></div>
        }
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key={'exposure'}>
              <Link
                to={`create?exposureId=${exposureId}`}
                className="exposure--back-row"
              >
                <ArrowLeftOutlined
                  style={{
                    marginRight: 10,
                    fontSize: 16,
                  }}
                />
                {t('common.back')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Form.Item
          name={'title'}
          label={t('shared.exposure.situationOrStimulusTitle')}
        >
          <Input.TextArea
            placeholder={t(
              'shared.exposure.situationOrStimulusTitlePlaceholder',
            )}
            autoSize={{ minRows: 1, maxRows: 4 }}
          />
        </Form.Item>
        <Form.Item
          name={'description'}
          label={t('shared.exposure.situationOrStimulusDescription')}
        >
          <Input.TextArea
            placeholder={t(
              'shared.exposure.situationOrStimuliDescriptionPlaceholder',
            )}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Row>
          <Col
            span={(innerWidth || 0) > 800 ? 8 : 24}
            offset={(innerWidth || 0) > 800 ? 5 : 0}
          >
            <Form.Item
              label={t('shared.exposure.avoiding')}
              tooltip={t('shared.exposure.doYouAvoid')}
              name={'avoidance'}
              style={{ margin: 0, minWidth: 120 }}
            >
              <Select
                className="exposure--client-selector"
                placeholder={t('shared.exposure.avoiding')}
                optionFilterProp="children"
                dropdownStyle={{ minWidth: 240 }}
              >
                {memoizedAvoidanceOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={(innerWidth || 0) > 800 ? 8 : 24}>
            <Form.Item
              name={'sud'}
              tooltip={t('shared.exposure.sud')}
              label={t('shared.exposure.sudTip')}
              labelCol={{ span: (innerWidth || 0) > 800 ? 20 : 7 }}
              wrapperCol={{ span: 4 }}
              style={{ marginTop: (innerWidth || 0) > 800 ? 0 : 20 }}
            >
              <InputNumber min={0} max={100} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'expositions'}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item
          name={'worstThingThatCouldHappen'}
          label={t('shared.exposure.worstThingThatCouldHappen')}
        >
          <Input.TextArea
            placeholder={t('shared.exposure.worstThingThatCouldHappen')}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          name={'imagePrompt'}
          tooltip={t('shared.exposure.imagePromptTip')}
          label={t('shared.exposure.imagePrompt')}
        >
          <Input.TextArea
            placeholder={t('shared.exposure.imagePromptPlaceholder')}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Form.Item
          name="imageUrls"
          label={t('shared.exposure.situationOrStimuliImages')}
        >
          <div className="exposure--generate-row">
            <Button
              icon={<BulbOutlined />}
              onClick={generateNewImage}
              loading={imageLoading || generateAiImageLoading}
              disabled={
                files.length > 4 ||
                imageLoading ||
                !imagePrompt ||
                generateAiImageLoading
              }
            >
              {t('shared.exposure.generateImageUsingAI')}
            </Button>
            <div className="exposure--generate-row--or">{t('common.or')}</div>
            <ImgCrop rotate shape="rect" zoom>
              <Upload
                accept="image/*"
                className="profile-upload-card"
                beforeUpload={(file) => {
                  const isImage = file.type.indexOf('image/') === 0;
                  if (!isImage) {
                    message.error(t('profile.onlyImage'));
                  }

                  // You can remove this validation if you want
                  const isLt5M = file.size / 1024 / 1024 < 5;
                  if (!isLt5M) {
                    message.error(t('profile.sizeLimit'));
                  }
                  return isImage && isLt5M;
                }}
                customRequest={({ onError, onSuccess, file }) => {
                  const upload = async () => {
                    const storage = getStorage();
                    const imageRef = ref(
                      storage,
                      imagePrefix + generateToken(),
                    );

                    try {
                      const image = await uploadBytes(imageRef, file as RcFile);
                      // @ts-ignore
                      onSuccess(null, image);

                      const imageUrl = await getDownloadURL(imageRef);

                      form!.setFields([
                        {
                          name: 'imageUrls',
                          touched: true,
                          value: [
                            ...(form.getFieldValue('imageUrls') || []),
                            imageUrl,
                          ],
                        },
                      ]);

                      setTimeout(() => updateExposureStimulus(), 50);
                      message.success(t('profile.uploadSuccess'));
                    } catch (e) {
                      message.error(String(e));
                    }
                  };
                  upload();
                }}
                listType="picture-card"
                fileList={files}
                onChange={({ file, fileList }) => {
                  if (file.status === 'done') {
                    const filteredDone = fileList.filter(
                      (f) => f.status === 'done',
                    );
                    setFiles([...files, ...filteredDone]);
                  }
                  if (file.status === 'removed') {
                    const filteredRemoved = files.filter(
                      (f) => f.uid !== file.uid,
                    );
                    setFiles(
                      filteredRemoved.map((f, idx) => {
                        return {
                          ...f,
                          uid: String(idx + 1),
                          name: `photo-${idx + 1}.png`,
                        };
                      }),
                    );
                  }
                }}
                onRemove={async (file) => {
                  const index = files.indexOf(file);
                  const newFileList = files.slice();
                  newFileList.splice(index, 1);
                  const newImageUrls = form.getFieldValue('imageUrls');
                  newImageUrls?.splice(index, 1);

                  try {
                    setFiles(
                      newFileList.map((f, idx) => {
                        return {
                          ...f,
                          uid: String(idx + 1),
                          name: `photo-${idx + 1}.png`,
                        };
                      }),
                    );

                    form.setFields([
                      {
                        name: 'imageUrls',
                        touched: true,
                        value: newImageUrls,
                      },
                    ]);

                    const suffix = (file.url || '')
                      .split('?')[0]
                      .split('%2F')
                      .pop();

                    const storage = getStorage();
                    const imageRef = ref(storage, imagePrefix + suffix);

                    const res = await deleteObject(imageRef);

                    setTimeout(() => updateExposureStimulus(), 50);
                  } catch (e) {
                    message.error(String(e));
                  }
                }}
                onPreview={handlePreview}
                multiple={false}
                maxCount={1}
              >
                {files.length < 5 ? (
                  <>
                    <UploadOutlined className="profile-upload-card__upload-icon" />{' '}
                    {t('profile.uploadPhoto')}
                  </>
                ) : null}
              </Upload>
            </ImgCrop>
          </div>
          <Button
            type="primary"
            onClick={startExposure}
            icon={<PlusOutlined />}
          >
            {t('shared.exposure.startExposure')}
          </Button>
          <Drawer
            title={t('shared.exposure.newExposure')}
            width={(innerWidth || 0) > 720 ? 720 : '100%'}
            open={openDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            autoFocus
            onClose={onCloseDrawer}
            extra={
              <Space>
                <Button onClick={onCloseDrawer}>{t('common.cancel')}</Button>
                <Button
                  disabled={!exposureForm.getFieldValue('suds')?.length}
                  onClick={() => {
                    setOpenDrawer(false);
                    setIsStopwatchRunning(false);
                    const newValue = exposureForm.getFieldsValue();
                    form.setFieldValue(['expositions', expositionIdx], {
                      ...newValue,
                    });

                    setTimeout(updateExposureStimulus, 50);
                    if (previewImage) {
                      setPreviewImage('');
                    }
                  }}
                  type="primary"
                >
                  {t('common.save')}
                </Button>
              </Space>
            }
          >
            <Form<typeof emptyExposition>
              form={exposureForm}
              onFinish={(values) => {}}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 16 }}
              labelWrap
            >
              <>
                {previewImage ? (
                  <Row>
                    <img
                      alt="preview"
                      style={{ width: '100%' }}
                      src={previewImage}
                    />
                  </Row>
                ) : null}
                <Row>
                  <Col span={15} push={1}>
                    {exposureData?.data?.safetyBehaviorsToAvoid ? (
                      <Form.Item
                        label={t('shared.exposure.safetyBehaviorsToAvoid')}
                        labelCol={{ span: 12 }}
                      >
                        <Text>
                          {exposureData?.data?.safetyBehaviorsToAvoid || ''}
                        </Text>
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Form.Item name={'createdAt'}>
                    <Input type="hidden" />
                  </Form.Item>

                  <Col span={15} push={1}>
                    <Form.Item
                      label={
                        t('shared.exposure.anticipated') +
                        ' ' +
                        t('shared.exposure.sud')
                      }
                      name={'anticipatedSud'}
                      labelCol={{ span: 12 }}
                    >
                      <Slider
                        min={1}
                        max={100}
                        style={{
                          minWidth: 200,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={2}>
                    <Form.Item name={'anticipatedSud'}>
                      <InputNumber min={1} max={100} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.List name={['suds']}>
                  {(fields, { add, remove }, errors) => {
                    return (
                      <>
                        {fields.map((field, index) => {
                          const date1 = exposureForm.getFieldValue('createdAt');
                          const date2 = exposureForm.getFieldValue([
                            'suds',
                            index,
                            'reactedAt',
                          ]);

                          const startDateAtMidnight = set(new Date(date1), {
                            hours: 0,
                            minutes: 0,
                            seconds: 0,
                          });
                          const secondsDifference = differenceInSeconds(
                            parseISO(date2),
                            parseISO(date1),
                          );
                          const intervalAsDate = addSeconds(
                            new Date(startDateAtMidnight),
                            secondsDifference,
                          );

                          return (
                            <Row
                              key={field.key}
                              align={(innerWidth || 0) > 700 ? 'top' : 'bottom'}
                            >
                              <Col span={14}>
                                <div className="exposure--sud-row">
                                  <div>
                                    <DateFnsTimePicker
                                      style={{
                                        minWidth: 110,
                                      }}
                                      defaultValue={intervalAsDate}
                                      format={
                                        currentLocale?.startsWith('ru')
                                          ? 'HH:mm:ss'
                                          : 'hh:mm:ss a'
                                      }
                                      secondStep={5}
                                      showNow={false}
                                      onChange={(date) => {
                                        if (date) {
                                          const secondsDifference =
                                            differenceInSeconds(
                                              date,
                                              startDateAtMidnight,
                                            );

                                          exposureForm.setFieldValue(
                                            ['suds', index, 'reactedAt'],
                                            addSeconds(
                                              date2,
                                              secondsDifference,
                                            ),
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <Form.Item
                                    {...field}
                                    label={t('shared.exposure.sud')}
                                    key={field.key}
                                    name={[field.key, 'sud']}
                                    labelCol={{ span: 12 }}
                                  >
                                    <Slider
                                      min={1}
                                      max={100}
                                      style={{
                                        minWidth: 200,
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col span={1} offset={3}>
                                <Form.Item
                                  {...field}
                                  key={field.key}
                                  name={[field.key, 'sud']}
                                >
                                  <InputNumber min={1} max={100} />
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        })}

                        <div className="exposure--stopwatch">
                          <div>
                            <Text
                              strong
                              style={{
                                fontSize: '24px',
                                marginRight: '15px',
                              }}
                            >
                              {formatTimestampToReadableString(time)}
                            </Text>
                            <HourglassOutlined
                              style={{
                                fontSize: 24,
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              type="primary"
                              onClick={() => {
                                setIsStopwatchRunning(!isStopwatchRunning);
                                if (!exposureForm.getFieldValue('createdAt')) {
                                  exposureForm.setFieldsValue({
                                    createdAt: new Date().toISOString(),
                                  });
                                }
                              }}
                              style={{
                                margin: '0 20px',
                              }}
                            >
                              {t(
                                isStopwatchRunning
                                  ? 'common.stop'
                                  : 'common.start',
                              )}
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                const previusSud = exposureForm.getFieldValue([
                                  'suds',
                                  fields.length - 1,
                                ]);

                                add({
                                  reactedAt: new Date().toISOString(),
                                  sud: previusSud?.sud || emptySud.sud,
                                });
                              }}
                              icon={<PlusOutlined />}
                              disabled={!isStopwatchRunning}
                            >
                              {t('shared.exposure.addReaction')}
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Form.List>

                <Form.Item
                  label={t('shared.exposure.reactionNotes')}
                  name={'reactionNotes'}
                >
                  <Input.TextArea
                    placeholder={t('shared.exposure.reactionNotes')}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                  />
                </Form.Item>
              </>
            </Form>
          </Drawer>
        </Form.Item>
        <>
          <Title level={5}>{t('shared.exposure.exposureGraph')}</Title>
          <Line {...(expositionsStats as unknown as LineConfig)} />
        </>
        <Table
          rowKey="createdAt"
          dataSource={form.getFieldValue('expositions') || []}
          pagination={false}
          scroll={{
            x: true,
          }}
        >
          <Table.Column<Exposure>
            key="createdAt"
            dataIndex="createdAt"
            title={t('common.createdAt')}
            render={(createdAt) => {
              return createdAt
                ? format(
                    parseISO(createdAt),

                    currentLocale?.startsWith('ru')
                      ? 'MMM dd, yyyy - HH:mm'
                      : 'MMM dd, yyyy - hh:mm aaa',
                  )
                : '';
            }}
          />
          <Table.Column<typeof emptyExposition>
            key="duration"
            title={t('shared.exposure.duration')}
            render={(record) => {
              return record.suds.length > 0 && record.createdAt
                ? formatDuration(
                    intervalToDuration({
                      start: parseISO(
                        record.suds[record.suds.length - 1].reactedAt,
                      ),
                      end: parseISO(record.createdAt),
                    }),
                    {
                      format: ['hours', 'minutes', 'seconds'],
                      locale: currentLocale?.startsWith('ru') ? ru : enUS,
                    },
                  )
                : '';
            }}
          />
          <Table.Column<Exposure>
            key="anticipatedSud"
            dataIndex="anticipatedSud"
            title={
              t('shared.exposure.anticipated') + ' ' + t('shared.exposure.sud')
            }
          />
          <Table.Column<Exposure>
            key="maxSud"
            dataIndex="suds"
            title={t('shared.exposure.highestSud')}
            render={(suds = []) => {
              return Math.max(...suds.map((s: typeof emptySud) => s.sud));
            }}
          />

          <Table.Column<Exposure>
            key="maxSud"
            dataIndex="suds"
            title={t('shared.exposure.endSud')}
            render={(suds) => {
              return suds?.[suds.length - 1]?.sud || '';
            }}
          />
          <Table.Column<Exposure>
            key="reactionNotes"
            dataIndex="reactionNotes"
            title={t('shared.exposure.reactionNotes')}
          />
          <Table.Column<Exposure>
            title={t('resourcesCommon.actions')}
            dataIndex="actions"
            render={(_, record) => {
              // @ts-ignore
              const client: Client = // @ts-ignore
              ((clients?.data?.data as Client[]) || []).find(
                (c) => c._id === clientId,
              );

              const hasPremiumAccess = client?.accessLevel;

              const canEdit =
                client?.access?.allowEdit ||
                (client?.access?.allowAdminEdit &&
                  identity?.db?.role === UserRole.THERAPIST_ADMIN);

              return (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    disabled={!hasPremiumAccess || !canEdit}
                    onClick={() => {
                      const oldValues = form.getFieldValue('expositions');
                      setExpositionIdx(
                        oldValues.findIndex(
                          (e: typeof emptyExposition) =>
                            e.createdAt === record.createdAt,
                        ),
                      );
                      setOpenDrawer(true);
                      const neededExpositon =
                        form.getFieldValue('expositions')?.[
                          oldValues.findIndex(
                            (e: typeof emptyExposition) =>
                              e.createdAt === record.createdAt,
                          )
                        ];
                      exposureForm.setFieldsValue({
                        ...neededExpositon,
                      });
                      setTime(0);
                    }}
                  />
                </Space>
              );
            }}
          />
        </Table>

        <Button
          type="primary"
          style={{
            marginTop: 20,
          }}
          onClick={startExposure}
          icon={<PlusOutlined />}
        >
          {t('shared.exposure.startExposure')}
        </Button>
      </List>
    </Form>
  );
};
