import { QuestionCircleOutlined } from '@ant-design/icons';
import { getValidators, Invite, InviteType, UserRole } from '@cc/schema';
import { generateToken } from '@cc/utils';
import {
  Create,
  Divider,
  Form,
  Input,
  Radio,
  Tooltip,
  Typography,
  useForm,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useTranslate,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { RuleObject } from 'antd/lib/form';
import { add } from 'date-fns';
import qs from 'qs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { getYupSyncValidator } from '../../helpers';
import { useFormSubmitDisabledAnyTouched } from '../../hooks';
import { IUser } from '../../providers';
import { generateInviteLink } from './list';

const { Title } = Typography;

type CreateInviteForm = {
  email: string;
  firstName: string;
  lastName: string;
};

export const CreateInvite: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<
    Pick<Invite, '_id' | 'firstName' | 'lastName' | 'email' | 'message'>,
    any,
    CreateInviteForm
  >({
    metaData: {
      operation: 'createInvite',
      fields: ['_id', 'firstName', 'lastName', 'email', 'message'],
    },
  });

  const { t, i18n } = useTranslation();

  const { practiceInviteValidator } = useMemo(
    () => getValidators(t),
    [i18n.languages[0]],
  );

  const validationRules = getYupSyncValidator(
    t,
    practiceInviteValidator,
  ) as RuleObject;

  const { data: user } = useGetIdentity<IUser>();
  const translate = useTranslate();

  const location = routerProvider.useLocation();

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  const token = useMemo(() => generateToken(), []);

  const [inviteRadio, setInviteRadio] = useState(
    Number(parsedSearch?.type) || 1,
  );

  const [userRadio, setUserRadio] = useState(3);

  const { initialValues, ...mainProps } = formProps;

  const { innerWidth } = useWindowSize();
  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const nameValue = Form.useWatch('firstName', formProps?.form);

  const message = useMemo(() => {
    return translate(
      inviteRadio === 1 ? 'invites.clientInviteHtml' : 'invites.userInviteHtml',
      {
        role: translate(`invites.types.${inviteRadio === 1 ? 1 : userRadio}`),
        name: nameValue ? ' ' + nameValue : '',
        practitioner:
          (user?.db?.firstName || '') + ' ' + (user?.db?.lastName || ''),
        token,
        link: generateInviteLink(
          token,
          inviteRadio === InviteType.CLIENT ? InviteType.CLIENT : userRadio,
        ),
      },
    );
  }, [translate, inviteRadio, userRadio, nameValue, token, user]);

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabledAnyTouched(formProps.form!);

  return (
    <Create
      title={translate('invites.createInvite')}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: isSubmitDisabled,
        children: translate('invites.sendInvite'),
      }}
    >
      <Title level={4} style={{ textAlign }}>
        {translate('invites.who')}
      </Title>
      {user?.db?.role === UserRole.THERAPIST_ADMIN ? (
        <>
          <Radio.Group
            onChange={(e) => setInviteRadio(e.target.value)}
            value={inviteRadio}
          >
            <Radio value={1}>{translate('invites.client')}</Radio>
            <Radio value={2}>{translate('invites.practiceMember')}</Radio>
          </Radio.Group>
          <Divider />
        </>
      ) : null}
      {inviteRadio === 2 && (
        <>
          <Title level={4} style={{ textAlign }}>
            {translate('invites.practicionerRole')}
          </Title>
          <Radio.Group
            onChange={(e) => setUserRadio(e.target.value)}
            value={userRadio}
          >
            <Radio value={3}>
              {translate('invites.types.3')}
              <Tooltip title={translate('invites.userTooltip')}>
                <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </Radio>
            <Radio value={4}>
              {translate('invites.types.4')}
              <Tooltip title={translate('invites.adminTooltip')}>
                <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </Radio>
          </Radio.Group>
          <Divider />
        </>
      )}
      <Form<CreateInviteForm>
        {...mainProps}
        onFinish={(values) => {
          const data = {
            ...values,
            email: values.email.trim().toLowerCase(),
            expiresAt: add(new Date(), { weeks: 4 }),
            type: inviteRadio === 1 ? 1 : userRadio,
            token,
            message,
          };
          mainProps.onFinish?.(data);
        }}
        labelCol={{ span: 3 }}
        onChange={handleFormChange}
        wrapperCol={{ span: 10 }}
      >
        <Form.Item
          label={translate('invites.fields.email')}
          name="email"
          rules={[validationRules]}
          required
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label={translate('invites.fields.firstName')}
          name="firstName"
          rules={[validationRules]}
          required
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={translate('invites.fields.lastName')}
          name="lastName"
          rules={[validationRules]}
          required
        >
          <Input />
        </Form.Item>

        <Divider />
        <Form.Item label={translate('invites.messagePreview')} name="messaage">
          <div
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
