import { format } from 'date-fns';
import { WorryTreeContext } from './machines.ts';

const splitStringAndJoin = (str: string) => {
  const middle = Math.ceil(str.length / 2);
  const before = str.lastIndexOf(' ', middle);
  const after = str.indexOf(' ', middle + 1);
  if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
    return [str.slice(0, after), str.slice(after + 1)].join('\\n');
  }
  return [str.slice(0, before), str.slice(before + 1)].join('\\n');
};

export const generateWorryTreeUmlText = (
  values: WorryTreeContext,
  t: (key: string, opts?: any) => string | any,
  lng = 'en',
  isSmall = false,
) => {
  if (!values.worryAbout) {
    return '';
  }

  const refocusLinkStyle =
    !values.options && !values.isNotSolvable ? '[#2B7895,bold]' : '[dotted]';
  const refocusNodeStyle =
    (!values.options ||
      (values.options && !values.plan) ||
      values.isNotSolvable ||
      (values.plan && !values.immediateAction)) &&
    !values.immediateAction
      ? '##[bold]2B7895'
      : '##[dotted]';

  const selectedActivityLinkStyle =
    (!values.options ||
      (values.options && !values.plan) ||
      values.isNotSolvable ||
      (values.plan && !values.immediateAction)) &&
    !values.immediateAction
      ? '[#2B7895,bold]'
      : '[dotted]';

  const title = `title ${t('shared.worryTree.title')}  - ${format(
    new Date(),
    lng?.startsWith('en') ? 'MM/dd/yyyy - h:mm bbb' : 'dd/MM/yyyy - H:mm',
  )}\n`;
  let nodesText = '@startuml\n';
  nodesText += 'hide empty description\n';
  nodesText += title;
  nodesText += `state "${t(
    'shared.worryTree.worryAbout',
  )}" as worryAbout ##[bold]2B7895\n`;
  nodesText += `worryAbout : `;
  values.worryAbout
    .trim()
    ?.replace(/\n/g, '\\n')
    .split('\\n')
    .forEach((t, idx) => {
      nodesText += `${t}\\n`;
    });
  nodesText += `\nworryAbout -[#2B7895,bold]-> isReal\n`;
  nodesText += `state "${t(
    'shared.worryTree.isReal',
  )}" as isReal ##[bold]2B7895\n`;

  if (!isSmall || refocusNodeStyle !== '##[dotted]') {
    nodesText += `state "${t('shared.worryTree.refocusTitle')
      .split('. ')
      .join('.\\n')}" as refocus ${refocusNodeStyle}\n`;
  }

  if (!isSmall || refocusLinkStyle !== '[dotted]') {
    nodesText += `\nisReal -${refocusLinkStyle}-> refocus : ${t(
      'common.no',
    )}\n`;
  }

  if (
    !values.options ||
    (values.options && !values.plan) ||
    (values.plan && !values.immediateAction)
  ) {
    if (!isSmall || selectedActivityLinkStyle !== '[dotted]') {
      nodesText += `state "${t(
        'shared.worryTree.selectedActivity',
      )}" as selectedActivity ${refocusNodeStyle}\n`;
      nodesText +=
        values.selectedActivityIdx === null
          ? ''
          : `selectedActivity : ${
              t('shared.worryTree.refocusIdeas', {
                returnObjects: true,
              })[values.selectedActivityIdx].title
            }\n`;

      nodesText += `\nrefocus -${selectedActivityLinkStyle}-> selectedActivity\n`;
    }
  }

  const isDoableLinkStyle =
    values.options || values.isNotSolvable ? '[#2B7895,bold]' : '[dotted]';
  const isDoableStyle =
    values.options || values.isNotSolvable ? '##[bold]2B7895' : '##[dotted]';

  if (!isSmall || isDoableLinkStyle !== '[dotted]') {
    nodesText += `\nisReal -${isDoableLinkStyle}-> isDoable : ${t(
      'common.yes',
    )}\n`;
    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.isDoable'),
    )}" as isDoable ${isDoableStyle}\n`;
  }

  const refocusLinkStyle2 = values.isNotSolvable
    ? '[#2B7895,bold]'
    : '[dotted]';
  const doableStyle = values.options ? '##[bold]2B7895' : '##[dotted]';

  if (!isSmall || refocusLinkStyle2 !== '[dotted]') {
    nodesText += `isDoable -${refocusLinkStyle2}-> refocus : ${t(
      'common.no',
    )}\n`;
  }
  const optionsLinkStyle = values.options ? '[#2B7895,bold]' : '[dotted]';

  if (!isSmall || optionsLinkStyle !== '[dotted]') {
    nodesText += `isDoable -${optionsLinkStyle}-> listOptions : ${t(
      'common.yes',
    )}\n`;

    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.listOptions'),
    )}" as listOptions ${doableStyle}\n`;
    nodesText += `listOptions : `;
    values.options
      .trim()
      ?.replace(/\n/g, '\\n')
      .split('\\n')
      .forEach((t, idx) => {
        nodesText += `${t}\\n`;
      });
    nodesText += `\nlistOptions -${optionsLinkStyle}-> isDoableNow \n`;
  }

  if (!isSmall || doableStyle !== '##[dotted]') {
    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.isDoableNow'),
    )}" as isDoableNow ${doableStyle}\n`;
  }

  const planLinkStyle = values.plan ? '[#2B7895,bold]' : '[dotted]';
  const immediateActionLinkStyle = values.immediateAction
    ? '[#2B7895,bold]'
    : '[dotted]';

  const planStyle = values.plan ? '##[bold]2B7895' : '##[dotted]';
  const immediateActionStyle = values.immediateAction
    ? '##[bold]2B7895'
    : '##[dotted]';

  if (!isSmall || planLinkStyle !== '[dotted]') {
    nodesText += `isDoableNow -${planLinkStyle}-> developPlan : ${t(
      'common.no',
    )}\n`;
  }

  if (!isSmall || planStyle !== '##[dotted]') {
    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.developPlan'),
    )}" as developPlan ${planStyle}\n`;

    nodesText += values.plan.length ? `developPlan : ` : '';

    values.plan &&
      values.plan
        .trim()
        ?.replace(/\n/g, '\\n')
        .split('\\n')
        .forEach((t, idx) => {
          nodesText += `${t}\\n`;
        });

    nodesText += values.plan.length ? `\n ` : '';
    nodesText += `developPlan -${planLinkStyle}-> refocus \n`;
  }

  if (!isSmall || immediateActionLinkStyle !== '[dotted]') {
    nodesText += `isDoableNow -${immediateActionLinkStyle}-> immediateAction : ${t(
      'common.yes',
    )}\n`;

    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.immediateAction'),
    )}" as immediateAction ${immediateActionStyle}\n`;

    nodesText += values.immediateAction.length ? `immediateAction : ` : '';

    values.immediateAction &&
      values.immediateAction
        .trim()
        ?.replace(/\n/g, '\\n')
        .split('\\n')
        .forEach((t, idx) => {
          nodesText += `${t}\\n`;
        });

    nodesText += values.immediateAction.length ? `\n ` : '';
    nodesText += `immediateAction -${immediateActionLinkStyle}-> doneTitle \n`;

    nodesText += `state "${splitStringAndJoin(
      t('shared.worryTree.doneTitle'),
    )}" as doneTitle ${immediateActionStyle}\n`;
  }
  // const logoText = '';
  // const logoText = 'by: <img:http://copingcard.com/static/logo.png>';
  // const logoText = 'by: <img:http://copingcard.com/static/favicon-32x32.png>';
  // 'by: <img:https://copingcard.com/static/logo-39bd0b4ba0c580edf887d8a70027b3e4.svg>';
  // 'usecase (by) as "by:": <img:https://storage.googleapis.com/copingcard.appspot.com/shared/images/logo.png{scale=0.5}>';
  // const logoText =
  //   'by: <img:https://storage.googleapis.com/copingcard.appspot.com/shared/images/logo.png{scale=0.5}>';

  // return nodesText + +logoText + '\n@enduml';
  return nodesText + '\n@enduml';
};
