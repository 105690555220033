import { FREE_SUBSCRIPTION_APPS, Practice, SubscriptionType } from '@cc/schema';
import { useGetIdentity } from '@pankod/refine-core';
import { useQueryClient } from '@tanstack/react-query';
import { add, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { IUser } from '../providers';
import { Client } from '../schema/be-graphql-generated';

export const useSubscriptionWarnings = () => {
  const queryClient = useQueryClient();

  const { data: identity, remove, refetch } = useGetIdentity<IUser>();

  const clientsData = queryClient.getQueriesData({
    queryKey: ['default', 'clients', 'list'],
  });

  const subscription = (
    queryClient.getQueriesData({
      queryKey: ['getUserIdentity'],
    })?.[0]?.[1] as any
  )?.db?.practice?.subscription as Practice['subscription'];

  const clientsAdded = (clientsData?.[0]?.[1] as any)?.total || 0;

  const clientsPremiumViaPractice = (
    ((clientsData?.[0]?.[1] as any)?.data?.data as Client[]) || []
  ).filter((u) =>
    u.activeSubscriptions?.data?.some(
      (s) =>
        s?.type === SubscriptionType.PROVIDER &&
        s.practiceId === identity?.db?.practiceId,
    ),
  );

  const stringifiedSubscrition = JSON.stringify(subscription);

  const res = useMemo(() => {
    const subscriptionExpirationDate = ['active', 'trialing'].includes(
      subscription?.status || '',
    )
      ? subscription?.cancelAtPeriodEnd &&
        subscription?.currentPeriodEnd &&
        parseISO(subscription?.currentPeriodEnd)
      : subscription?.status === 'past_due'
      ? subscription?.currentPeriodStart &&
        add(parseISO(subscription?.currentPeriodStart), {
          days: 15,
        })
      : null;

    const daysTillSubscriptionExpires =
      (subscriptionExpirationDate &&
        Math.floor(
          (subscriptionExpirationDate.getTime() - Date.now()) /
            (1000 * 60 * 60 * 24),
        )) ||
      0;

    const baseInfo = {
      subscriptionExpired:
        (subscription?.status && ['past_due'].includes(subscription.status)) ||
        false,
      subscriptionExpirationDate,
      daysTillSubscriptionExpires,
      subscriptionExpiringInDays:
        daysTillSubscriptionExpires > 0 && daysTillSubscriptionExpires < 7
          ? daysTillSubscriptionExpires
          : 0,
      clientsPurchased: subscription?.quantity || FREE_SUBSCRIPTION_APPS,
      clientsAdded: subscription?.quantity
        ? clientsPremiumViaPractice.length
        : subscription?.freeAppsUsed || 0,
    };

    const limitReached = baseInfo.clientsAdded / baseInfo.clientsPurchased >= 1;
    const limitApproaching =
      baseInfo.clientsAdded / baseInfo.clientsPurchased >= 0.8;

    return {
      ...baseInfo,
      limitReached,
      limitApproaching,
      clientsLeft: baseInfo.clientsPurchased - baseInfo.clientsAdded,
    };
  }, [stringifiedSubscrition, clientsAdded, clientsPremiumViaPractice]);

  return res;
};
