import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { portalEn, User } from '@cc/schema';
import { timeout } from '@cc/utils';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
  Tag,
  Tooltip,
  TreeSelect,
  Typography,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
} from '@pankod/refine-core';
import { useWatch } from 'antd/lib/form/Form';
import { capitalize, debounce, isEqual, omitBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useWindowSize } from 'rooks';
import { Editor } from '../../../components/Editor';
import { ProgressNote as ProgressNoteType } from '../../../schema/be-graphql-generated';
import * as Sentry from '@sentry/react';
import { colors } from '../../../styles/colors';
import '../styles.less';

const { Title } = Typography;

type Props = {
  sessionId: string;
  diagnosesOnClient: string[];
  preferredPronounsOnclient?: number | null;
  clientId?: string;
  placeOfServiceCode?: string;
  sessionRange: Date[];
  accessLevel?: string | null;
};

// @ts-ignore
export const recursivelyDisableIfChildren = (options: any[]) => {
  // @ts-ignore
  return options.map((option) => {
    if (option.children?.length) {
      return {
        ...option,
        // disabled: true,
        disableCheckbox: true,
        children: recursivelyDisableIfChildren(option.children),
      };
    }
    return option;
  });
};

const generateEditorStateFromFormat = (format: {
  name: string;
  nonAbbreviated: string;
}) => {
  const splitFormat = format.nonAbbreviated.split(', ');
  let ops = [] as any;
  splitFormat.forEach((f) => {
    ops = [
      ...ops,
      { attributes: { header: 1 }, insert: `${f}\n` },
      { insert: '\n\n' },
    ];
  });
  return ops;
};
export const ProgressNote: React.FC<Props> = ({
  sessionId,
  diagnosesOnClient,
  preferredPronounsOnclient,
  clientId,
  placeOfServiceCode,
  sessionRange,
  accessLevel,
}) => {
  const { t, i18n } = useTranslation();

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const { innerWidth } = useWindowSize();

  const [isConfirmChangeVisibleOption, setIsConfirmChangeVisibleOption] =
    useState<number>();

  const [isConfimGenerateVisible, setIsConfimGenerateVisible] = useState(false);

  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [isFirstDiagnosesUpdateSkipped, setIsFirstDiagnosesUpdateSkipped] =
    useState(false);
  const [progressNoteId, setProgressNoteId] = useState<string | undefined>();
  const [isGeneratingNote, setIsGeneratingNote] = useState(false);
  const [generationTipIndex, setGenerationTipIndex] = useState(0);

  const mountDatetime = useMemo(() => new Date(), []);

  const gptNoteContentRef = useRef('');

  const getGptNoteContent = () => {
    return gptNoteContentRef.current;
  };

  useEffect(() => {
    let interval: any = undefined;
    if (isGeneratingNote) {
      interval = setInterval(() => {
        setGenerationTipIndex((prev) => {
          if (prev === portalEn.pages.sessions.generationTips.length - 1) {
            return prev;
          }
          return prev + 1;
        });
      }, 5000);

      return () => interval && clearInterval(interval);
    }

    interval && clearInterval(interval);
  }, [isGeneratingNote]);

  const [editorState, setEditorState] = useState({
    ops: generateEditorStateFromFormat(
      // @ts-ignore
      t('pages.sessions.progressNotesFormats', {
        returnObjects: true,
      })[0],
    ),
  } as ReactQuill.Value);

  const [detectedInterventions, setDetectedInterventions] = useState('');

  const quillRef = useRef<ReactQuill>(null);

  const onGenerateNote = async () => {
    const delta = quillRef.current?.getEditor().getContents();
    if (delta) {
      setIsConfimGenerateVisible(true);
    }
  };

  const initialValues = useMemo(() => {
    return {
      diagnoses: diagnosesOnClient?.length
        ? diagnosesOnClient
        : ([] as string[]),
      preferredPronouns: preferredPronounsOnclient,
      progressNoteType: 0,
      shortSummary: '',
      clientProgress: 1,

      // Mental Status Exam
      appearance: undefined as number | undefined,
      attitude: undefined as number | undefined,
      mood: undefined as number | undefined,
      affect: undefined as number | undefined,
      insight: undefined as number | undefined,
      concentration: undefined as number | undefined,
      speech: [] as number[],
      thoughtProcess: [] as number[],
      perception: [] as number[],
      orientation: [] as number[],
      memory: [] as number[],

      // Interventions
      interventionsEducation: [] as number[],
      interventionsCognitive: [] as number[],
      interventionsBehavioralActivation: [] as number[],
      interventionsExposures: [] as number[],
      interventionsSkillsTraining: [] as number[],
      interventionsRelaxation: [] as number[],
      interventionsOther: [] as string[],
    };
  }, []);

  const [form] = Form.useForm<typeof initialValues>();

  const diagnoses = useWatch('diagnoses', form);
  const preferredPronouns = useWatch('preferredPronouns', form);
  const progressNoteType = useWatch('progressNoteType', form) || 0;

  // TODO: merge with client update
  const {
    mutate: updateClientDiagnoses,
    data: updateClientDiagnosesData,
    isLoading: updateClientDiagnosesLoading,
    error: update,
  } = useCustomMutation<{ client: User }>();

  const {
    mutate: updateClient,
    data: updateClientData,
    isLoading: updateClientLoading,
    error: updateClientError,
  } = useCustomMutation<{ client: User }>();

  useEffect(() => {
    if (!diagnoses?.length && diagnosesOnClient?.length) {
      form?.setFieldsValue({
        diagnoses: diagnosesOnClient,
      });
    }
    if (
      !(!!preferredPronouns || preferredPronouns === 0) &&
      (!!preferredPronounsOnclient || preferredPronounsOnclient === 0)
    ) {
      form?.setFieldsValue({
        preferredPronouns: preferredPronounsOnclient,
      });
    }
  }, [clientId]);

  useEffect(() => {
    const updateClient = async () => {
      if (
        !clientId ||
        !diagnoses ||
        !isInitialLoadDone ||
        isEqual(diagnosesOnClient, diagnoses)
      ) {
        return;
      }

      // Note: we need to skip the first update since it's the initial load and setting the data
      if (!isFirstDiagnosesUpdateSkipped) {
        setIsFirstDiagnosesUpdateSkipped(true);
        return;
      }

      const res = await updateClientDiagnoses({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'updateClientDiagnoses',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'UpdateClientDiagnosesInput',
              value: {
                data: diagnoses,
                where: {
                  id: clientId,
                },
              },
            },
          },
        },
      });
    };
    updateClient();
  }, [clientId, diagnoses, isInitialLoadDone]);

  useEffect(() => {
    const update = async () => {
      if (
        !clientId ||
        preferredPronouns === undefined ||
        preferredPronouns === null ||
        preferredPronouns === preferredPronounsOnclient
      ) {
        return;
      }

      const res = await updateClient({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'updateClient',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'updateClientInput',
              value: {
                data: {
                  accessLevel,
                  preferredPronouns,
                },
                where: {
                  id: clientId,
                },
              },
            },
          },
        },
      });
    };
    update();
  }, [clientId, preferredPronouns, preferredPronounsOnclient]);

  useEffect(() => {
    setTimeout(() => {
      quillRef.current?.blur();
    }, 100);
  }, [quillRef.current]);

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const {
    data: progressNoteData,
    isLoading: progressNoteLoading,
    refetch: refetchProgressNote,
    error: progressNoteError,
  } = useCustom<ProgressNoteType>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'progressNote',
      fields: [
        '_id',

        'sessionId',
        'diagnoses',
        'progressNoteType',
        'shortSummary',
        'clientProgress',
        'appearance',
        'attitude',
        'mood',
        'affect',
        'insight',
        'concentration',
        'speech',
        'thoughtProcess',
        'perception',
        'orientation',
        'memory',
        'interventionsEducation',
        'interventionsCognitive',
        'interventionsBehavioralActivation',
        'interventionsExposures',
        'interventionsSkillsTraining',
        'interventionsRelaxation',
        'interventionsOther',

        'editorState',
      ],
      variables: {
        id: {
          name: 'sessionId',
          type: 'ID',
          value: sessionId,
        },
      },
    },
  });

  useEffect(() => {
    const { _id, editorState, __typename, ...rest } =
      progressNoteData?.data || {};
    if (_id) {
      setProgressNoteId(progressNoteData?.data?._id);
    }
    if (editorState) {
      const editorStateOld = quillRef.current?.getEditor().getContents();
      setEditorState(editorState);
      const editorStateNew = quillRef.current?.getEditor().getContents();
    }
    if (rest) {
      // @ts-ignore
      form?.setFieldsValue(rest);
    }
    if (progressNoteData && !isInitialLoadDone) {
      setTimeout(() => setIsInitialLoadDone(true), 50);
    }
  }, [progressNoteData]);

  const {
    mutate: upsertProgressNote,
    data: upsertProgressNoteData,
    isLoading: upsertProgressNoteLoading,
    error: upsertProgressNoteError,
  } = useCustomMutation<{ progressNote: ProgressNoteType }>();

  const saveData = useCallback(
    async (values?: typeof initialValues) => {
      const isReady = true;

      if (!sessionId || !isReady) {
        return;
      }

      const { preferredPronouns, ...rest } = form.getFieldsValue();

      const data = {
        ...rest,
        editorState: quillRef.current?.getEditor().getContents(),
      };

      const {
        _id,
        sessionId: oldSessionId,
        ...oldData
      } = progressNoteData?.data || {};

      const res = await upsertProgressNote({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertProgressNote',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'ProgressNoteInput',
              value: {
                data,
                where: {
                  _id: progressNoteId,
                  sessionId,
                },
              },
            },
          },
        },
      });
    },
    [sessionId, t],
  );

  useEffect(() => {
    if (
      upsertProgressNoteData?.data &&
      !upsertProgressNoteLoading &&
      !upsertProgressNoteError
    ) {
      message.success(t('pages.sessions.saveSuccess'));
    }
  }, [
    upsertProgressNoteData?.data,
    upsertProgressNoteLoading,
    upsertProgressNoteError,
  ]);

  const {
    data: practiceUsageEventsForNoteData,
    isLoading: practiceUsageEventsForNoteLoading,
    refetch: refetchpracticeUsageEventsForNote,
    error: practiceUsageEventsForNoteError,
  } = useCustom<string[]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'practiceUsageEventsForNote',
      variables: {
        lng: {
          name: 'lng',
          type: 'String!',
          value: currentLocale?.startsWith('ru') ? 'ru' : 'en',
        },
        endDate: {
          name: 'endDate',
          type: 'DateTime',
          value: sessionRange[1] || mountDatetime,
        },
        startDate: {
          name: 'startDate',
          type: 'DateTime',
          value: sessionRange[0] || mountDatetime,
        },
        clientId: {
          name: 'clientId',
          type: 'ID',
          value: clientId,
        },
        showOffsessionData: {
          name: 'showOffsessionData',
          type: 'Boolean',
          value: false,
        },
        showInSessionData: {
          name: 'showInSessionData',
          type: 'Boolean',
          value: true,
        },
      },
    },
  });

  useEffect(() => {
    if (practiceUsageEventsForNoteData?.data) {
      setDetectedInterventions(
        practiceUsageEventsForNoteData?.data
          ?.map((d, idx) => `${idx + 1}. ${d}`)
          .join('\n'),
      );
    }
  }, [practiceUsageEventsForNoteData]);

  const getNoteContent = () => {
    const values = form.getFieldsValue();
    const mentalStatus = omitBy(
      {
        appearance:
          values.appearance === undefined
            ? undefined
            : t('pages.sessions.appearance', {
                returnObjects: true,
              })[values.appearance],
        attitude:
          values.attitude === undefined
            ? undefined
            : t('pages.sessions.attitude', {
                returnObjects: true,
              })[values.attitude],
        mood:
          values.mood === undefined
            ? undefined
            : t('pages.sessions.mood', {
                returnObjects: true,
              })[values.mood],
        affect:
          values.affect === undefined
            ? undefined
            : t('pages.sessions.affect', {
                returnObjects: true,
              })[values.affect],
        insight:
          values.insight === undefined
            ? undefined
            : t('pages.sessions.insight', {
                returnObjects: true,
              })[values.insight],
        concentration:
          values.concentration === undefined
            ? undefined
            : t('pages.sessions.concentration', {
                returnObjects: true,
              })[values.concentration],
        speech:
          (values.speech?.length || 0) < 1
            ? undefined
            : values.speech.map(
                (value: number) =>
                  t('pages.sessions.speech', {
                    returnObjects: true,
                  })[value],
              ),
        thoughtProcess:
          (values.thoughtProcess?.length || 0) < 1
            ? undefined
            : values.thoughtProcess.map(
                (value: number) =>
                  t('pages.sessions.thoughtProcess', {
                    returnObjects: true,
                  })[value],
              ),
        perception:
          (values.perception?.length || 0) < 1
            ? undefined
            : values.perception.map(
                (value: number) =>
                  t('pages.sessions.perception', {
                    returnObjects: true,
                  })[value],
              ),
        orientation:
          (values.orientation?.length || 0) < 1
            ? undefined
            : values.orientation.map(
                (value: number) =>
                  t('pages.sessions.orientation', {
                    returnObjects: true,
                  })[value],
              ),
        memory:
          (values.memory?.length || 0) < 1
            ? undefined
            : values.memory.map(
                (value: number) =>
                  t('pages.sessions.memory', {
                    returnObjects: true,
                  })[value],
              ),
      },
      (value) => value === undefined || (value?.length && value.length < 1),
    );

    const interventionsUsed = omitBy(
      {
        psychoeducation:
          (values.interventionsEducation?.length || 0) < 1
            ? undefined
            : values.interventionsEducation.map(
                (value: number) =>
                  t('pages.sessions.interventionsEducation', {
                    returnObjects: true,
                  })[value],
              ),
        cognitiveTechniques:
          (values.interventionsCognitive?.length || 0) < 1
            ? undefined
            : values.interventionsCognitive.map(
                (value: number) =>
                  t('pages.sessions.interventionsCognitive', {
                    returnObjects: true,
                  })[value],
              ),
        behavioralActivation:
          (values.interventionsBehavioralActivation?.length || 0) < 1
            ? undefined
            : values.interventionsBehavioralActivation.map(
                (value: number) =>
                  t('pages.sessions.interventionsBehavioralActivation', {
                    returnObjects: true,
                  })[value],
              ),
        exposures:
          (values.interventionsExposures?.length || 0) < 1
            ? undefined
            : values.interventionsExposures.map(
                (value: number) =>
                  t('pages.sessions.interventionsExposures', {
                    returnObjects: true,
                  })[value],
              ),
        skillsTraining:
          (values.interventionsSkillsTraining?.length || 0) < 1
            ? undefined
            : values.interventionsSkillsTraining.map(
                (value: number) =>
                  t('pages.sessions.interventionsSkillsTraining', {
                    returnObjects: true,
                  })[value],
              ),
        relaxation:
          (values.interventionsRelaxation?.length || 0) < 1
            ? undefined
            : values.interventionsRelaxation.map(
                (value: number) =>
                  t('pages.sessions.interventionsRelaxation', {
                    returnObjects: true,
                  })[value],
              ),
        other:
          (values.interventionsOther?.length || 0) < 1
            ? undefined
            : values.interventionsOther,
      },
      (value) => value === undefined || (value?.length && value.length < 1),
    );

    const diagnosesForGeneration = values.diagnoses?.map(
      (diagnosis: string) => {
        const diagnosesTree = t('icd10', {
          returnObjects: true,
        });

        const traverseDiagnosticTree = (tree: any[], diagnosis: string) => {
          for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.value === diagnosis) {
              return node;
            }
            if (node.children) {
              const result: any = traverseDiagnosticTree(
                node.children,
                diagnosis,
              );
              if (result) {
                return result;
              }
            }
          }
        };

        // @ts-ignore
        const diagnosisNode = traverseDiagnosticTree(diagnosesTree, diagnosis);

        const diagnosesTypeIndex = diagnoses.indexOf(diagnosis);

        return (
          t('pages.sessions.diagnoses', {
            returnObjects: true,
          })[diagnosesTypeIndex] +
          ': ' +
          diagnosisNode.title
        );
      },
    );

    const data: any = {
      mentalStatus,
      detectedInterventions,
      interventionsUsed,
      shortSummary: values.shortSummary,
      clientProgress: t('pages.sessions.clientProgressOptions', {
        returnObjects: true,
      })[values.clientProgress],
      diagnoses: diagnosesForGeneration,
      placeOfService: (
        t('pages.sessions.placeOfServiceCodes', {
          returnObjects: true,
        }) as any[]
      )
        .filter((c) => c.code === placeOfServiceCode)
        .map((format, idx) => format.code + ' - ' + format.name)?.[0],
    };

    if (
      values.preferredPronouns !== undefined &&
      values.preferredPronouns !== null
    ) {
      data.preferredPronouns = t('shared.preferredPronounsOptions', {
        returnObjects: true,
      })[values.preferredPronouns];
    }

    return data;
  };

  const {
    data: gptProgressNoteData,
    isLoading: gptProgressNoteLoading,
    refetch: refetchGptProgressNote,
    error: gptProgressNoteError,
  } = useCustom<any>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'generateProgressNote',
      fields: ['response', 'isContentFiltered'],
      variables: {
        content: {
          name: 'content',
          type: 'String!',
          value: getGptNoteContent(),
        },
        type: {
          name: 'type',
          type: 'Int!',
          value: progressNoteType,
        },
        language: {
          name: 'language',
          type: 'String!',
          value: currentLocale,
        },
      },
    },
  });

  const onValuesChange = useCallback(
    debounce(
      (
        changedValues: Partial<typeof initialValues>,
        allValues: typeof initialValues,
      ) => {
        saveData(allValues);
      },
      1000,
    ),
    [saveData, progressNoteId],
  );

  const treeData = useMemo(() => {
    return recursivelyDisableIfChildren(
      t('icd10', {
        returnObjects: true,
      }) as any[],
    );
  }, [currentLocale]);

  useEffect(() => {
    if (isInitialLoadDone) {
      onValuesChange({}, form.getFieldsValue());
    }
  }, [editorState]);

  const generateGptNoteAndSetEditorContent = async () => {
    setIsGeneratingNote(true);
    gptNoteContentRef.current = JSON.stringify(getNoteContent());

    await timeout(100);

    const res = await refetchGptProgressNote();
    try {
      const jsonBadControlCharacterFixer: any = (json: string) => {
        try {
          return JSON.parse(json);
        } catch (e) {
          // @ts-ignore
          if (e.message.includes('control character')) {
            // @ts-ignore
            const position = e.message.match(/\d+/g)?.[0];

            if (position !== undefined) {
              const fixedJson =
                json.slice(0, Number(position)) +
                '\\n' +
                json.slice(Number(position) + 1);
              return jsonBadControlCharacterFixer(fixedJson);
            }
          } else {
            throw e;
          }
        }
      };
      let parsedJson = {} as any;
      if (!res?.data?.data?.response) {
        Sentry.captureException({
          noResponse: true,
        });
      }
      try {
        parsedJson = JSON.parse(res?.data?.data?.response);
      } catch (e) {
        if (res?.data?.data?.response.length > 0) {
          try {
            parsedJson = jsonBadControlCharacterFixer(
              res?.data?.data?.response,
            );
          } catch (e2) {
            Sentry.captureException({
              error1: e,
              error2: e2,
              response: res?.data?.data?.response,
            });
          }
        }
      }
      const isContentFiltered = res?.data?.data?.isContentFiltered || false;

      if (isContentFiltered) {
        notification.error({
          message: t('pages.sessions.gptNotContentFilteredErrorTitle'),
          description: t(
            'pages.sessions.gptNoteContentFilteredErrorDescription',
          ),
          duration: 30,
        });
      }

      const headers = Object.keys(parsedJson) || [];

      const headersAbbreviation = headers
        .map((header) => header.charAt(0).toUpperCase())
        .join('');

      const headerType = t('pages.sessions.progressNotesFormats', {
        returnObjects: true,
        // @ts-ignore
      }).find((header) => header.name === headersAbbreviation);

      const headersLocalized = headerType?.nonAbbreviated.split(', ') || [];

      let ops = [] as any;

      headers.forEach((header, idx) => {
        ops = [
          ...ops,
          {
            attributes: { header: 1 },
            insert: capitalize(headersLocalized[idx] || header) + '\n',
          },
          {
            insert: (parsedJson[header] || '').replace('\\\\n', '\\n') + '\n\n',
          },
        ];
      });

      setEditorState({
        ops,
      } as ReactQuill.Value);
    } catch (e) {
      console.log(e);
    } finally {
      setIsGeneratingNote(false);
      setGenerationTipIndex(0);
    }
  };

  return (
    <Form<typeof initialValues>
      form={form}
      initialValues={{
        ...initialValues,
      }}
      onValuesChange={(changes, allChanges) => {
        // Note: need to exclude from automatic update since we need a confirm from the user
        const isProgressNoteTypeChanged =
          Object.keys(changes).includes('progressNoteType');
        if (
          (!sessionId && upsertProgressNoteLoading) ||
          isProgressNoteTypeChanged
        ) {
          return;
        }

        onValuesChange(changes, allChanges);
      }}
      onFinish={(values) => {}}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 24 }}
      labelWrap
    >
      <div>
        <div className="sessions--mental-status">
          <div className="sessions--mental-status">
            <Form.Item
              name="progressNoteType"
              label={t('pages.sessions.progressNotesFormatLabel')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="sessions--data-column--max"
            >
              <Select
                defaultValue={0}
                className="sessions--progress-note-type-select"
                options={(
                  t('pages.sessions.progressNotesFormats', {
                    returnObjects: true,
                  }) as any[]
                ).map((format, idx) => ({
                  label: format.name + ' - ' + format.nonAbbreviated,
                  value: idx,
                }))}
                onChange={(value) => {
                  setIsConfirmChangeVisibleOption(value);
                }}
                size="small"
              />
            </Form.Item>

            <Modal
              title={
                <>
                  <ExclamationCircleOutlined
                    style={{ color: colors['--primary-0'] }}
                  />{' '}
                  <span>{t('pages.sessions.warning')}</span>
                </>
              }
              cancelText={t('common.cancel')}
              open={isConfirmChangeVisibleOption !== undefined}
              onOk={() => {
                if (isConfirmChangeVisibleOption === undefined) return;
                form.setFieldsValue({
                  progressNoteType: isConfirmChangeVisibleOption,
                });
                setEditorState(
                  generateEditorStateFromFormat(
                    // @ts-ignore
                    t('pages.sessions.progressNotesFormats', {
                      returnObjects: true,
                    })[isConfirmChangeVisibleOption],
                  ),
                );
                // Note: without the delay the form is not updated
                setTimeout(() => saveData(form.getFieldsValue()), 50);
                setIsConfirmChangeVisibleOption(undefined);
              }}
              onCancel={() => {
                setIsConfirmChangeVisibleOption(undefined);
              }}
            >
              {t('pages.sessions.confirmChange')}
            </Modal>

            <Form.Item
              name="clientProgress"
              label={t('pages.sessions.clientProgressLabel')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="sessions--data-column--l"
            >
              <Select
                options={(
                  t('pages.sessions.clientProgressOptions', {
                    returnObjects: true,
                  }) as any[]
                ).map((label, idx) => ({
                  label: label,
                  value: idx,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="preferredPronouns"
              label={t('pages.sessions.preferredPronounsLabel')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="sessions--data-column--l"
              style={{ marginLeft: 10 }}
            >
              <Select
                options={(
                  t('shared.preferredPronounsOptions', {
                    returnObjects: true,
                  }) as any[]
                ).map((label, idx) => ({
                  label: label,
                  value: idx,
                }))}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="diagnoses"
            label={t('pages.sessions.diagnosesLabel')}
            tooltip={t('pages.sessions.icd10Hint')}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="sessions--data-column--max"
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder={t('pages.sessions.selectOrSearch')}
              allowClear
              onChange={(values) => {}}
              treeData={treeData}
              treeLine
              treeCheckable
              dropdownMatchSelectWidth
              treeNodeFilterProp="title"
              tagRender={({ label, value, closable, onClose }) => {
                const index = diagnoses?.findIndex(
                  (diagnosis) => diagnosis === value,
                );
                return (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    className="diagnosis--tag"
                  >
                    <b>
                      {t('pages.sessions.diagnoses', {
                        returnObjects: true,
                      })[index] || ''}
                      :
                    </b>{' '}
                    {label}
                  </Tag>
                );
              }}
              {...((diagnoses?.length || 0) > 2 && {
                open: false,
                onDropdownVisibleChange: (open) => {
                  if (open) {
                    message.warning(t('pages.sessions.diagnosesLimit'));
                  }
                },
              })}
            />
          </Form.Item>
        </div>

        <Divider />

        <Title level={3} style={{ textAlign }}>
          {t('pages.sessions.clientMentalStatus')}
          <Tooltip title={t('pages.sessions.clientMentalStatusTip')}>
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </Title>

        <div className="sessions--mental-status">
          <Form.Item
            name="appearance"
            label={t('pages.sessions.appearanceLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.appearance', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="attitude"
            label={t('pages.sessions.attitudeLabel')}
            className="sessions--vertical-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.attitude', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="mood"
            label={t('pages.sessions.moodLabel')}
            className={'sessions--data-column--s'}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.mood', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="affect"
            label={t('pages.sessions.affectLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.affect', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="thoughtProcess"
            label={t('pages.sessions.thoughtProcessLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.thoughtProcess', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="insight"
            label={t('pages.sessions.insightLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--m">
                {(
                  t('pages.sessions.insight', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="concentration"
            label={t('pages.sessions.concentrationLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.concentration', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="speech"
            label={t('pages.sessions.speechLabel')}
            className="sessions--data-column--s"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.speech', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="perception"
            label={t('pages.sessions.perceptionLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--m">
                {(
                  t('pages.sessions.perception', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="orientation"
            label={t('pages.sessions.orientationLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--m">
                {(
                  t('pages.sessions.orientation', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="memory"
            label={t('pages.sessions.memoryLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--m">
                {(
                  t('pages.sessions.memory', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
        </div>

        <Divider />
        <Title level={3} style={{ textAlign }}>
          {t('pages.sessions.detectedInterventionsTitle')}
          <Tooltip title={t('pages.sessions.detectedInterventionsHint')}>
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </Title>

        <Form.Item
          label={t('pages.sessions.detectedInterventionsLabel')}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 10 }}
            value={detectedInterventions}
            onChange={(e) => {
              setDetectedInterventions(e.target.value);
            }}
          />
        </Form.Item>

        <Divider />
        <Title level={3} style={{ textAlign }}>
          {t('pages.sessions.interventionsLabel')}
          <Tooltip title={t('pages.sessions.interventionsHint')}>
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </Title>
        <div className="sessions--mental-status">
          <Form.Item
            name="interventionsEducation"
            label={t('pages.sessions.interventionsEducationLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsEducation', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="interventionsCognitive"
            label={t('pages.sessions.interventionsCognitiveLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsCognitive', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="interventionsBehavioralActivation"
            label={t('pages.sessions.interventionsBehavioralActivationLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsBehavioralActivation', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="interventionsExposures"
            label={t('pages.sessions.interventionsExposuresLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsExposures', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="interventionsSkillsTraining"
            label={t('pages.sessions.interventionsSkillsTrainingLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsSkillsTraining', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="interventionsRelaxation"
            label={t('pages.sessions.interventionsRelaxationLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <div className="sessions--vertical-column--s">
                {(
                  t('pages.sessions.interventionsRelaxation', {
                    returnObjects: true,
                  }) as any[]
                )
                  .map((label, idx) => ({
                    label: label,
                    value: idx,
                  }))
                  .map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="interventionsOther"
            label={t('pages.sessions.interventionsOtherLabel')}
            className="sessions--data-column--m"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              placeholder={t(
                'pages.sessions.interventionsOtherLabelPlaceholder',
              )}
              mode="tags"
            />
          </Form.Item>
        </div>

        <div className="sessions--mental-status"></div>

        <Divider />

        <Form.Item
          name="shortSummary"
          label={t('pages.sessions.shortSummaryLabel')}
        >
          <Input.TextArea
            placeholder={t('pages.sessions.shortSummaryPlaceholder')}
            autoSize
          />
        </Form.Item>

        <Divider />

        <div className="note-title-container">
          <Title level={3} style={{ textAlign }}>
            {t('pages.sessions.readyNote')}
            <Tooltip title={t('pages.sessions.readyNoteHint')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>
          </Title>

          <Button
            className="save-pdf--button"
            onClick={onGenerateNote}
            type="primary"
            disabled={
              !clientId ||
              isGeneratingNote ||
              isConfimGenerateVisible ||
              gptProgressNoteLoading
            }
          >
            {t('pages.sessions.generateNote')}
          </Button>
        </div>

        <Modal
          title={
            <>
              <ExclamationCircleOutlined
                style={{ color: colors['--primary-0'] }}
              />{' '}
              <span>{t('pages.sessions.warning')}</span>
            </>
          }
          cancelText={t('common.cancel')}
          open={isConfimGenerateVisible}
          onOk={async () => {
            setIsConfimGenerateVisible(false);
            generateGptNoteAndSetEditorContent();
          }}
          onCancel={() => {
            setIsConfimGenerateVisible(false);
          }}
        >
          {' '}
          {t('pages.sessions.confirmGenerateChange')}
        </Modal>
        <div className="spin-container">
          <Spin
            spinning={isGeneratingNote}
            tip={
              t('pages.sessions.generationTips', {
                returnObjects: true,
              })[generationTipIndex]
            }
          />
        </div>
        <Editor
          quillRef={quillRef}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </div>
    </Form>
  );
};
