import { Steps, Typography } from '@pankod/refine-antd';
import {
  Authenticated,
  LayoutWrapper,
  useGetIdentity,
  useTranslate,
} from '@pankod/refine-core';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'rooks';
import { IUser } from '../../providers';
import { Step0 } from './step0';
import { Step1 } from './step1';
import { Step2 } from './step2';

const { Title } = Typography;
const { Step } = Steps;

export const InitialSetup = () => {
  const translate = useTranslate();
  const [currentStep, setCurrentStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const steps = [
    {
      subTitle: 'Legal & Privacy',
      description: translate('pages.initialSetup.step0.title'),
    },
    {
      subTitle: 'Name & Location',
      description: translate('pages.initialSetup.step1.title'),
    },
    {
      subTitle: 'Practice',
      description: translate('pages.initialSetup.step2.title'),
    },
  ];

  const onChange = (value: number) => {
    if (value === currentStep) {
      return;
    }
    if (value < currentStep) {
      setCurrentStep(value);
    } else if (!isNextDisabled) {
      setCurrentStep(currentStep + 1);
    }
  };

  const { data: identity } = useGetIdentity<IUser>();

  const [initialStepSet, setInitialStepSet] = useState(false);

  useEffect(() => {
    if (initialStepSet || !identity?.db) {
      return;
    }

    let stepToGo = 0;

    if (
      identity?.db?.privacySettings?.isPrivacyAccepted &&
      identity?.db?.privacySettings?.isTermsAccepted
    ) {
      stepToGo = 1;
    }

    if (
      stepToGo === 1 &&
      identity?.db?.phone &&
      identity?.db?.firstName &&
      identity?.db?.lastName &&
      identity?.db?.country
    ) {
      stepToGo = 2;
    }

    setInitialStepSet(true);
    if (stepToGo !== currentStep) {
      setCurrentStep(stepToGo);
    }
  }, [identity, initialStepSet, currentStep]);

  const { innerWidth } = useWindowSize();

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <Step0
            currentStep={currentStep}
            stepsLenght={steps.length}
            setCurrentStep={setCurrentStep}
            setIsNextDisabled={setIsNextDisabled}
          />
        );
      case 1:
        return (
          <Step1
            currentStep={currentStep}
            stepsLenght={steps.length}
            setCurrentStep={setCurrentStep}
            setIsNextDisabled={setIsNextDisabled}
          />
        );
      case 2:
        return (
          <Step2
            currentStep={currentStep}
            stepsLenght={steps.length}
            setCurrentStep={setCurrentStep}
            setIsNextDisabled={setIsNextDisabled}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Authenticated>
      <LayoutWrapper>
        <Title level={3} style={{ textAlign }}>
          {translate('pages.initialSetup.title')}
        </Title>
        <Steps
          type="navigation"
          size="small"
          current={currentStep}
          onChange={onChange}
          className="site-navigation-steps"
          responsive
        >
          {steps.map((s, idx) => (
            <Step
              key={s.subTitle}
              title={`Step ${idx + 1}`}
              description={s.description}
              status={
                idx === currentStep
                  ? 'process'
                  : currentStep < idx
                  ? 'wait'
                  : 'finish'
              }
            />
          ))}
        </Steps>
        {renderStep()}
      </LayoutWrapper>
    </Authenticated>
  );
};
