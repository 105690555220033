import { UserRole } from '@cc/schema';
import {
  ConfigProvider,
  ErrorComponent,
  notificationProvider,
  ReadyPage,
} from '@pankod/refine-antd';
import { Refine } from '@pankod/refine-core';
import dataProvider from '@pankod/refine-graphql';
import routerProvider from '@pankod/refine-react-router-v6';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useMachine } from '@xstate/react';
import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/es/locale/ru_RU';
import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider,
  Title,
} from 'components/layout';
import { throttle } from 'lodash';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/antd.less';
import { firebaseAuth, httpClient } from './authProvider';
import {
  createTherapistActivityMachine,
  TherapistActivityService,
} from './machines/therapistActivityMachine';
import { AbcModelCreate, AbcModelsList } from './pages/abcModels';
import {
  BehaviorExperimentCreate,
  BehaviorExperimentsList,
} from './pages/behaviorExperiments';
import { ClientList } from './pages/clients';
import { ClientShow } from './pages/clients/show';
import { ConceptualizationsList } from './pages/conceptualization';
import { CreateConceptualization } from './pages/conceptualization/create';
import { DownwardArrowCreate, DownwardArrowsList } from './pages/downwardArrow';
import { ExposureCreate, ExposuresList } from './pages/exposure';
import { EditExposureSituation } from './pages/exposure/edit';
import { InitialSetup } from './pages/initialSetup';
import { InviteList } from './pages/invites';
import { CreateInvite } from './pages/invites/create';
import { InviteJoin } from './pages/invites/InviteJoin';
import { LoginPage } from './pages/login';
import { MessageList } from './pages/messages';
import { PasswordResetPage } from './pages/passwordReset';
import { CopingCardsList } from './pages/portalCards';
import { CreateCopingCard } from './pages/portalCards/create';
import { PortalSubscriptionList } from './pages/portalSubscription';
import { TestsList } from './pages/portalTests';
import { ThoughtList } from './pages/portalThoughts';
import { CreateThought } from './pages/portalThoughts/create';
import { PracticeEdit } from './pages/practice/edit';
import { ProfileEdit } from './pages/profile/edit';
import { SessionsList } from './pages/sessions';
import { SessionsCreate } from './pages/sessions/create';
import { SignupPage } from './pages/signup';
import { SupportingCyclesCreate } from './pages/supportingCycles';
import { SupportingCyclesList } from './pages/supportingCycles/list';
import { UserList } from './pages/users';
import { VerifyEmail } from './pages/verifyEmail';
import { WorryTreeCreate, WorryTreeList } from './pages/worryTree';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const authProvider = firebaseAuth.getAuthProvider();

export const gqlDataProvider = dataProvider(httpClient);

// Note: for some strange reason the context needs to be setup in App.tsx
// Doing it in a separate file and importing it does not work, breaks refine
export const TherapistActivityReactContext =
  createContext<TherapistActivityService>(null!);

export const TherapistActivityProvider = (props: any) => {
  const therapistActivityMachine = useMemo(
    () =>
      createTherapistActivityMachine({
        gqlDataProvider,
      }),
    [],
  );

  const [
    therapistActivityState,
    therapistActivitySend,
    therapistActivityService,
  ] = useMachine(therapistActivityMachine);

  return (
    <TherapistActivityReactContext.Provider
      value={therapistActivityService}
      {...props}
    />
  );
};

function App() {
  const { t, i18n } = useTranslation();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 500);
  }, []);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.languages[0],
  };

  useEffect(() => {
    httpClient.setHeader('Accept-Language', i18n.languages[0]);
  }, [i18n.languages[0]]);

  // Refreshing Firebase auth token
  useEffect(() => {
    const interval = setInterval(() => {
      firebaseAuth.updateHeaderToken();
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  // Note: it reduces multiple simultaneous calls to getIdentity
  const throttledGetIdentity = throttle(async () => {
    const identity = await authProvider?.getUserIdentity?.();
    return identity;
  }, 200);

  useEffect(() => {
    setTimeout(() => setReady(true), 500);
  }, []);

  return ready ? (
    <TherapistActivityProvider>
      <ConfigProvider
        locale={i18n.languages[0]?.startsWith('ru') ? ruRU : enUS}
      >
        <Refine
          accessControlProvider={{
            can: async ({ resource, action, params, ...rest }) => {
              const identity = await throttledGetIdentity();

              const notAuthorizedForUser = [
                'practice',
                'practiceusers',
                'practiceinfo',
                'practiceInfo',
                'portalSubscriptions',
              ];

              if (
                notAuthorizedForUser.includes(resource) &&
                identity?.db?.role !== UserRole.THERAPIST_ADMIN
              ) {
                return {
                  can: false,
                  reason: t('notifications.unauthorized'),
                };
              }

              // const inReview = ['behaviorExperiments'];
              // const allowedUsers = ['users/45', 'users/233438', 'users/234162'];

              // if (
              //   inReview.includes(resource) &&
              //   !allowedUsers.includes(identity?.db?._id)
              // ) {
              //   return {
              //     can: false,
              //     reason: t('notifications.unauthorized'),
              //   };
              // }

              return {
                can: true,
                reason: '',
              };
            },
          }}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                element: <SignupPage />,
                path: '/signup',
              },
              {
                element: <InviteJoin />,
                path: '/join/:token',
              },
              {
                element: <InviteJoin />,
                path: '/join/users/:token',
              },
              {
                element: <VerifyEmail />,
                path: '/verify-email',
              },
              {
                element: <PasswordResetPage />,
                path: '/reset-password',
              },
              {
                element: <VerifyEmail />,
                path: '/deep/auth',
              },
              {
                element: <InitialSetup />,
                path: '/initial-setup',
              },
            ],
          }}
          dataProvider={gqlDataProvider}
          authProvider={authProvider}
          LoginPage={LoginPage}
          resources={[
            { name: 'clients', list: ClientList, show: ClientShow },
            {
              name: 'sessions',
              create: SessionsCreate,
              list: SessionsList,
              options: {
                label: t('pages.sessions.title'),
              },
            },
            {
              name: 'tools',
              options: {
                label: t('tools.tools'),
              },
            },
            {
              parentName: 'tools',
              name: 'conceptualizations',
              create: CreateConceptualization,
              list: ConceptualizationsList,
              options: {
                label: t('pages.conceptualization.conceptualizations'),
              },
            },
            {
              parentName: 'tools',
              name: 'portalCards',
              list: CopingCardsList,
              create: CreateCopingCard,
              options: {
                label: t('pages.copingCards.title'),
              },
            },
            {
              parentName: 'tools',
              name: 'abcModels',
              create: AbcModelCreate,
              list: AbcModelsList,
              options: {
                label: t('shared.abcModels.title'),
              },
            },
            {
              parentName: 'tools',
              name: 'portalThoughts',
              list: ThoughtList,
              create: CreateThought,
            },
            {
              parentName: 'tools',
              name: 'worryTree',
              create: WorryTreeCreate,
              list: WorryTreeList,
              options: {
                label: t('shared.worryTree.worryTrees'),
              },
            },
            {
              parentName: 'tools',
              name: 'downwardArrows',
              create: DownwardArrowCreate,
              list: DownwardArrowsList,
              options: {
                label: t('shared.downwardArrows.downwardArrows'),
              },
            },
            {
              parentName: 'tools',
              name: 'behaviorExperiments',
              create: BehaviorExperimentCreate,
              list: BehaviorExperimentsList,
              options: {
                label: t('shared.behaviorExperiments.title'),
              },
            },

            { parentName: 'tools', name: 'portalTests', list: TestsList },
            {
              parentName: 'tools',
              name: 'supportingCycles',
              create: SupportingCyclesCreate,
              list: SupportingCyclesList,
              options: {
                label: t('pages.supportingCycle.supportingCyclesShort'),
              },
            },

            {
              parentName: 'tools',
              name: 'exposures',
              create: ExposureCreate,
              list: ExposuresList,
              edit: EditExposureSituation,
              options: {
                label: t('shared.exposure.titlePlural'),
              },
            },
            {
              name: 'practice',
              options: {
                label: t('practice.practice'),
              },
            },
            { parentName: 'practice', name: 'practiceUsers', list: UserList },
            {
              parentName: 'practice',
              name: 'practiceInfo',
              list: PracticeEdit,
              options: {
                route: 'practiceinfo/edit',
              },
            },
            { parentName: 'practice', name: 'locations' },
            {
              name: 'profile',
              list: ProfileEdit,
              options: {
                route: 'profile/edit',
              },
            },
            {
              name: 'message',
              list: MessageList,
              options: {
                label: t('messages.messages'),
              },
            },
            {
              name: 'invites',
              create: CreateInvite,
              list: InviteList,
            },
            {
              name: 'portalSubscriptions',
              list: PortalSubscriptionList,
              options: {
                label: t('portalSubscriptions.title'),
              },
            },
          ]}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          i18nProvider={i18nProvider}
          options={{
            disableTelemetry: true,
          }}
        />
      </ConfigProvider>
    </TherapistActivityProvider>
  ) : null;
}

export default App;
