import {
  BulbOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  ActivatingEvent,
  baseEmotionsEn,
  baseEmotionsRu,
  baseFeelingsEn,
  baseFeelingsRu,
  dbCols,
  translationEn,
  translationRu,
} from '@cc/schema';
import { timeout } from '@cc/utils';
import {
  Button,
  Col,
  Create,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
  useWarnAboutChange,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { useWatch } from 'antd/lib/form/Form';
import { isEqual } from 'lodash';
import qs from 'qs';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { useClientOptions } from '../../hooks/client-options';
import { BehaviorExperiment } from '../../schema/be-graphql-generated';
import * as Sentry from '@sentry/react';
import './styles.less';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';

const { Title } = Typography;

const { OptGroup, Option } = Select;

export const BehaviorExperimentCreate = () => {
  const { t, i18n } = useTranslation();
  const location = routerProvider.useLocation();

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const [generatedVariants, setGeneratedVariants] = useState<any[]>([]);
  const [generatedVariantValue, setGeneratedVariantValue] = useState<number>();

  const { setWarnWhen } = useWarnAboutChange();
  const contentRef = useRef('');

  const getContent = () => {
    return contentRef.current;
  };

  useEffect(() => {
    return () => {
      contentRef.current = '';
    };
  }, []);

  const { clientId: locationClientId, behaviorExperimentId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const { innerWidth } = useWindowSize();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState?.context?.filterClientId ||
      (locationClientId as string),
  );

  const baseEmotions = useMemo(() => {
    const baseEmotions = currentLocale?.startsWith('ru')
      ? baseEmotionsRu
      : baseEmotionsEn;

    return baseEmotions.map((e) => ({
      ...e,
      children: e.children.map((ch) => ({ ...ch, strength: 50 })),
    }));
  }, [currentLocale]);

  const flattenedEmotions = useMemo(() => {
    return baseEmotions.reduce(
      (acc, val) => [...acc, ...val.children],
      [] as typeof baseEmotionsEn[0]['children'],
    );
  }, [baseEmotions]);

  const baseFeelings = currentLocale?.startsWith('ru')
    ? baseFeelingsRu
    : baseFeelingsEn;

  const initialValues = useMemo(() => {
    return {
      purpose: undefined as number | undefined,
      design: undefined as number | undefined,
      hypothesisType: undefined as number | undefined,
      hypothesisA: '' as string | undefined | null,
      hypothesisAStrength: 50 as number | undefined | null,
      hypothesisAConfirmationCriteria: '' as string | undefined | null,
      hypothesisB: '' as string | undefined | null,
      hypothesisBStrength: 50 as number | undefined | null,
      hypothesisBConfirmationCriteria: '' as string | undefined | null,
      type: undefined as number | undefined,
      activeSubtype: undefined as number | undefined | null,
      observationalSubtype: undefined as number | undefined | null,
      settings: {
        experiment: '' as string | undefined | null,
        time: '' as string | undefined | null,
        place: '' as string | undefined | null,
        people: '' as string | undefined | null,
        resources: '' as string | undefined | null,
        survey: '' as string | undefined | null,
      },
      prediction: {
        emotions: [] as ActivatingEvent['emotions'] | null[],
        feelings: [] as ActivatingEvent['feelings'],
        behaviors: '' as string | undefined | null,
        otherPredictions: '' as string | undefined | null,
      },
      safetyBehaviors: '' as string | undefined | null,
      helpfulCopingStrategies: '' as string | undefined | null,
      outcome: {
        behaviors: '' as string | undefined | null,
        emotions: [] as ActivatingEvent['emotions'] | null[],
        feelings: [] as ActivatingEvent['feelings'],
        patientReflections: '' as string | undefined | null,
        therapistReflections: '' as string | undefined | null,
        newLearnings: '' as string | undefined | null,
        meaningForOriginalBelief: '' as string | undefined | null,
        oldThoughtStrength: 50 as number | undefined | null,
        newThought: '' as string | undefined | null,
        newThoughtStrength: 50 as number | undefined | null,
      },
      followUpExperimentsIdeas: '' as string | undefined | null,
    };
  }, [therapistActivityState?.context?.filterClientId || locationClientId]);

  const [form] = Form.useForm<typeof initialValues>();

  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const type = useWatch('type', form);
  const hypothesisType = useWatch('hypothesisType', form);
  const activeSubtype = useWatch('activeSubtype', form);
  const observationalSubtype = useWatch('observationalSubtype', form);
  const hypothesisA = useWatch('hypothesisA', form);
  const hypothesisB = useWatch('hypothesisB', form);

  const outcome = useWatch('outcome', form);

  const [expreimentId, setExpreimentId] = useState(
    behaviorExperimentId as string | undefined,
  );

  useActivityTracking(
    expreimentId ? expreimentId : `${dbCols.behaviorExperiments}/create`,
  );

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const removeVariantValues = async (
    formValues: any,
    values: any,
    prefix = [] as string[],
  ) => {
    const keys = Object.keys(values);

    for (const key of keys) {
      // @ts-ignore
      if (values[key] !== undefined && isEqual(values[key], formValues[key])) {
        // @ts-ignore
        if (values[key] instanceof Array) {
          // @ts-ignore
        } else if (typeof values[key] === 'object') {
          await removeVariantValues(formValues[key], values[key], [
            ...prefix,
            key,
          ]);
          // @ts-ignore
        } else if (typeof values[key] === 'string') {
          // @ts-ignore

          form.setFieldValue([...prefix, key], '');
        }
      }
    }
    return;
  };

  const mimicUserTyping = async (
    formValues: any,
    values: any,
    prefix = [] as string[],
  ) => {
    const keys = Object.keys(values);

    for (const key of keys) {
      // @ts-ignore
      if (values[key] !== undefined && !isEqual(values[key], formValues[key])) {
        // @ts-ignore
        if (values[key] instanceof Array) {
          // @ts-ignore
        } else if (typeof values[key] === 'object') {
          await mimicUserTyping(formValues[key], values[key], [...prefix, key]);
          // @ts-ignore
        } else if (typeof values[key] === 'string') {
          // @ts-ignore
          for (let i = 0; i < values[key].length + 1; i++) {
            // @ts-ignore
            form.setFieldValue([...prefix, key], values[key].substring(0, i));
            await timeout(7);
          }
        }
      }
    }
  };

  const maybeRemoveVariantValues = async () => {
    if (generatedVariantValue !== undefined) {
      const {
        purpose,
        design,
        hypothesisType,
        hypothesisAStrength,
        hypothesisBStrength,
        hypothesisA,
        hypothesisB,
        type,
        activeSubtype,
        observationalSubtype,
        outcome,
        ...rest
      } = generatedVariants[generatedVariantValue];

      const { prediction, ...freshFormValues } = form.getFieldsValue();

      const { emotions, feelings, ...restPrediction } = prediction;

      let updatedValues = {} as any;

      if (
        freshFormValues.hypothesisA &&
        freshFormValues.hypothesisB &&
        hypothesisB
      ) {
        updatedValues.hypothesisB = hypothesisB;
      }
      await removeVariantValues(
        { ...freshFormValues, prediction: { ...restPrediction } },
        { ...updatedValues, ...rest },
      );
      form.setFieldValue('prediction.emotions', {
        ...emotions,
      });
      form.setFieldValue('prediction.feelings', {
        ...feelings,
      });
      return;
    }
  };
  const resetValuesFromVariant = async (variant: typeof initialValues) => {
    await maybeRemoveVariantValues();
    const {
      purpose,
      design,
      hypothesisType,
      hypothesisAStrength,
      hypothesisBStrength,
      hypothesisA,
      hypothesisB,
      type,
      activeSubtype,
      prediction,
      observationalSubtype,
      ...rest
    } = variant;

    const { emotions, feelings, ...restPrediction } = prediction;

    const freshFormValues = form.getFieldsValue();

    let updatedValues = {} as any;

    if (!freshFormValues.hypothesisA && hypothesisA) {
      updatedValues.hypothesisA = hypothesisA;
    }
    if (!freshFormValues.hypothesisB && hypothesisB) {
      updatedValues.hypothesisB = hypothesisB;
    }

    mimicUserTyping(freshFormValues, {
      ...updatedValues,
      ...rest,
      prediction: {
        ...restPrediction,
      },
    });
    setHasGenerated(true);
  };

  const {
    data: gptExperimentData,
    isLoading: gptExperimentLoading,
    refetch: refetchGptExperiment,
    error: gptExperimentError,
  } = useCustom<any>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'generateGptBehaviorExperiment',
      fields: ['response'],
      variables: {
        content: {
          name: 'content',
          type: 'String!',
          value: getContent(),
        },
        language: {
          name: 'language',
          type: 'String!',
          value: currentLocale,
        },
      },
    },
  });

  const [generateRetriedOnce, setGenerateRetriedOnce] = useState(false);

  const onGenerateHypothesis = async () => {
    await maybeRemoveVariantValues();
    const values = form.getFieldsValue();

    const translation = currentLocale?.startsWith('ru')
      ? translationRu
      : translationEn;
    let requestData: any = {
      purpose:
        translation.shared.behaviorExperiment.purposes[values.purpose || 0],
      design: translation.shared.behaviorExperiment.designs[values.design || 0],
      hypothesisType:
        translation.shared.behaviorExperiment.hypothesisTypeVariants[
          values.hypothesisType || 0
        ],
      hypothesisA: values.hypothesisA,
      hypothesisAConfirmationCriteria: values.hypothesisAConfirmationCriteria,
      hypothesisB: values.hypothesisB,
      hypothesisBConfirmationCriteria: values.hypothesisBConfirmationCriteria,
      type: translation.shared.behaviorExperiment.typeVariants[
        values.type || 0
      ],
      settings: {
        experiment: values.settings.experiment,
        time: values.settings.time,
        place: values.settings.place,
        people: values.settings.people,
        resources: values.settings.resources,
        survey: values.settings.survey,
      },
      prediction: {
        behaviors: values.prediction.behaviors,
        otherPredictions: values.prediction.otherPredictions,
      },
      safetyBehaviors: values.safetyBehaviors,
      helpfulCopingStrategies: values.helpfulCopingStrategies,
      followUpExperimentsIdeas: values.followUpExperimentsIdeas,
    };

    requestData =
      values.type === 0
        ? {
            ...requestData,
            activeSubtype:
              translation.shared.behaviorExperiment.activeSubtypes[
                values.activeSubtype || 0
              ],
          }
        : {
            ...requestData,

            observationalSubtype:
              translation.shared.behaviorExperiment.observationalSubtypes[
                values.observationalSubtype || 0
              ],
          };

    try {
      setIsGenerating(true);

      contentRef.current = JSON.stringify(requestData);

      await timeout(100);
      const res = await refetchGptExperiment();
      const response = res?.data?.data?.response || JSON.stringify({});
      let parsedResonse = {} as any;
      try {
        parsedResonse = JSON.parse(response);
      } catch (error) {
        Sentry.captureException(error);
      }
      if (!generateRetriedOnce && isEqual(parsedResonse, {})) {
        setGenerateRetriedOnce(true);
        await timeout(50);
        await onGenerateHypothesis();
        return;
      }
      setGenerateRetriedOnce(false);
      const lastMessage = parsedResonse?.choices?.[0]?.message;
      if (lastMessage) {
        const isAssistant = lastMessage?.role === 'assistant';
        if (isAssistant && lastMessage?.content) {
          let lastGeneratedExperiment = {} as unknown as typeof initialValues;
          try {
            lastGeneratedExperiment = JSON.parse(lastMessage.content);
          } catch (error) {
            Sentry.captureException(error);
          }
          const newGenratedVariants = [
            ...generatedVariants,
            lastGeneratedExperiment,
          ];
          setGeneratedVariants(newGenratedVariants);
          await resetValuesFromVariant(lastGeneratedExperiment);
          setGeneratedVariantValue(newGenratedVariants.length - 1);
        }
      }
    } catch (error) {
      message.error(t('shared.behaviorExperiment.generationError'));
      Sentry.captureException(error);
    } finally {
      setIsGenerating(false);
    }
  };

  const memoizedPurposeOptions = useMemo(() => {
    return t('shared.behaviorExperiment.purposes', {
      returnObjects: true,
      // @ts-ignore
    }).map((purpose: any, index: number) => ({
      label: purpose,
      value: index,
    }));
  }, [i18n.language]);

  const memoizedDesignsOptions = useMemo(() => {
    return t('shared.behaviorExperiment.designs', {
      returnObjects: true,
      // @ts-ignore
    }).map((design: any, index: number) => ({
      label: design,
      value: index,
    }));
  }, [i18n.language]);

  const memoizedHypotethisCountOptions = useMemo(() => {
    return t('shared.behaviorExperiment.hypothesisTypeVariants', {
      returnObjects: true,
      // @ts-ignore
    }).map((design: any, index: number) => ({
      label: design,
      value: index,
    }));
  }, [i18n.language]);

  const memoizedTypeVariantsOptions = useMemo(() => {
    return t('shared.behaviorExperiment.typeVariants', {
      returnObjects: true,
      // @ts-ignore
    }).map((design: any, index: number) => ({
      label: design,
      value: index,
    }));
  }, [i18n.language]);

  const memoizedActiveSubtypesOptions = useMemo(() => {
    return t('shared.behaviorExperiment.activeSubtypes', {
      returnObjects: true,
      // @ts-ignore
    }).map((design: any, index: number) => ({
      label: design,
      value: index,
    }));
  }, [i18n.language]);

  const memoizedObservationalSubtypesOptions = useMemo(() => {
    return t('shared.behaviorExperiment.observationalSubtypes', {
      returnObjects: true,
      // @ts-ignore
    }).map((design: any, index: number) => ({
      label: design,
      value: index,
    }));
  }, [i18n.language]);

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const {
    data: behaviorExperimentData,
    isLoading: behaviorExperimentLoading,
    refetch: refetchBehaviorExperiment,
    error: behaviorExperimentError,
  } = useCustom<BehaviorExperiment>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'behaviorExperiment',
      fields: [
        '_id',
        'userId',
        'purpose',
        'design',
        'hypothesisType',
        'hypothesisA',
        'hypothesisAStrength',
        'hypothesisAConfirmationCriteria',
        'hypothesisB',
        'hypothesisBStrength',
        'hypothesisBConfirmationCriteria',
        'type',
        'activeSubtype',
        'observationalSubtype',
        {
          settings: [
            'experiment',
            'time',
            'place',
            'people',
            'resources',
            'survey',
          ],
        },
        {
          prediction: [
            { emotions: ['id', 'strength'] },
            'feelings',
            'behaviors',
            'otherPredictions',
          ],
        },
        'safetyBehaviors',
        'helpfulCopingStrategies',
        'followUpExperimentsIdeas',
        {
          outcome: [
            'behaviors',
            { emotions: ['id', 'strength'] },
            'feelings',
            'patientReflections',
            'therapistReflections',
            'newLearnings',
            'meaningForOriginalBelief',
            'oldThoughtStrength',
            'newThought',
            'newThoughtStrength',
          ],
        },
      ],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: expreimentId,
        },
      },
    },
  });

  useEffect(() => {
    if (behaviorExperimentData?.data) {
      const { __typename, _id, _key, _rev, createdAt, updatedAt, ...rest } =
        behaviorExperimentData?.data;

      form?.setFieldsValue({
        ...rest,
      });
      setClientId(behaviorExperimentData?.data.userId);

      if (behaviorExperimentData?.data.userId) {
        therapistActivitySend({
          type: 'LOADED',
          resource: behaviorExperimentData?.data?._id,
          clientId: behaviorExperimentData?.data.userId,
        });
      }
    }
  }, [behaviorExperimentData]);

  const {
    mutate: upsertBehaviorExperiment,
    data: upsertBehaviorExperimentData,
    isLoading: upsertBehaviorExperimentLoading,
    error: upsertBehaviorExperimentError,
  } = useCustomMutation<{ behaviorExperiment: BehaviorExperiment }>();

  const saveData = useCallback(
    async (values?: typeof initialValues) => {
      const data = values || form?.getFieldsValue();
      const isReady =
        data.purpose !== undefined &&
        data.design !== undefined &&
        data.hypothesisType !== undefined &&
        data.type !== undefined &&
        (data.type === 0
          ? data.activeSubtype !== undefined
          : data.observationalSubtype !== undefined);

      if (!clientId || !isReady) {
        return;
      }

      const res = await upsertBehaviorExperiment({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertBehaviorExperiment',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'BehaviorExperimentInput',
              value: {
                data,
                where: {
                  clientId,
                  _id: expreimentId,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.behaviorExperiment.saveSuccess'));
      setWarnWhen(false);
    },
    [clientId, expreimentId, t],
  );

  useEffect(() => {
    // @ts-ignore
    if (upsertBehaviorExperimentData?.data?._id) {
      // @ts-ignore

      if (!expreimentId) {
        // @ts-ignore
        setExpreimentId(upsertBehaviorExperimentData?.data?._id);
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: upsertBehaviorExperimentData?.data?._id,
        });
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          resource: expreimentId,
        });
      }
    }
  }, [upsertBehaviorExperimentData]);

  const generateArea = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
        alignSelf: 'center',
      }}
    >
      <Button
        type="primary"
        onClick={onGenerateHypothesis}
        icon={<BulbOutlined />}
        disabled={
          isGenerating ||
          !(hypothesisA || hypothesisB) ||
          generatedVariants.length >= 3
        }
      >
        {t(
          generatedVariants.length === 0
            ? 'shared.behaviorExperiment.generateGptSuggestions'
            : 'shared.behaviorExperiment.generateGptSuggestionsMore',
        )}
      </Button>

      {isGenerating && (
        <Spin
          style={{
            marginTop: 20,
          }}
          size="large"
          tip={t('shared.behaviorExperiment.generating')}
        />
      )}

      {generatedVariants.length > 0 && (
        <Radio.Group
          style={{
            marginTop: 40,
          }}
          onChange={(e) => {
            const value = e.target.value;
            resetValuesFromVariant(generatedVariants[value]);
            setGeneratedVariantValue(value);
            setWarnWhen(true);
          }}
          options={generatedVariants.map((variant, index) => ({
            label: `${t('shared.behaviorExperiment.generatedVariant')} ${
              index + 1
            }`,
            value: index,
          }))}
          value={generatedVariantValue}
          optionType="button"
          buttonStyle="outline"
          defaultValue={0}
        />
      )}

      {hasGenerated && (
        <div
          style={{
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WarningOutlined
            style={{
              fontSize: 40,
              color: '#faad14',
            }}
          />
          {t('shared.behaviorExperiment.generatedWarning')}
        </div>
      )}
    </div>
  );

  return (
    <Create
      title={
        <>
          {t('shared.behaviorExperiment.title')}

          <Tooltip
            title={t('shared.behaviorExperiment.description')}
            overlayClassName="behavior-experiment--tooltip"
          >
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </>
      }
      isLoading={clientsLoading}
      saveButtonProps={{
        onClick: () => saveData(),
        disabled:
          !clientId ||
          isGenerating ||
          !(hypothesisA || hypothesisB) ||
          upsertBehaviorExperimentLoading ||
          behaviorExperimentLoading ||
          gptExperimentLoading,
      }}
    >
      <div className="behavior-experiment-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="behavior-experiment--client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
      </div>

      <Form<typeof initialValues>
        form={form}
        initialValues={{
          ...initialValues,
        }}
        onValuesChange={() => {
          setWarnWhen(true);
        }}
        onFinish={(values) => {}}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        labelWrap
      >
        <Form.Item
          name="purpose"
          label={t('shared.behaviorExperiment.purpose')}
          tooltip={t('shared.behaviorExperiment.purposesTip')}
          rules={[
            {
              required: true,
              message: t('shared.behaviorExperiment.purposeRequired'),
            },
          ]}
        >
          <Select placeholder={t('shared.behaviorExperiment.purpose')}>
            {memoizedPurposeOptions.map((option: any) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="design"
          label={t('shared.behaviorExperiment.design')}
          tooltip={t('shared.behaviorExperiment.designsTip')}
          rules={[
            {
              required: true,
              message: t('shared.behaviorExperiment.designRequired'),
            },
          ]}
        >
          <Radio.Group>
            {memoizedDesignsOptions.map((option: any) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="hypothesisType"
          label={t('shared.behaviorExperiment.hypothesisType')}
          tooltip={t('shared.behaviorExperiment.hypothesisTypeVariantsTip')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder={t('shared.behaviorExperiment.hypothesisType')}>
            {memoizedHypotethisCountOptions.map((option: any) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="type" label={t('shared.behaviorExperiment.type')}>
          <Select placeholder={t('shared.behaviorExperiment.type')}>
            {memoizedTypeVariantsOptions.map((option: any) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {type === undefined ? null : type === 0 ? (
          <Form.Item
            name="activeSubtype"
            label={t('shared.behaviorExperiment.activeSubtype')}
          >
            <Select placeholder={t('shared.behaviorExperiment.activeSubtype')}>
              {memoizedActiveSubtypesOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            name="observationalSubtype"
            label={t('shared.behaviorExperiment.observationalSubtype')}
          >
            <Select
              placeholder={t('shared.behaviorExperiment.observationalSubtype')}
            >
              {memoizedObservationalSubtypesOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Divider />
        {(activeSubtype !== undefined ||
          observationalSubtype !== undefined) && (
          <Title level={4}>{t('shared.behaviorExperiment.hypotheses')}</Title>
        )}
        {(activeSubtype !== undefined || observationalSubtype !== undefined) &&
          (hypothesisType === 0 || hypothesisType === 1) && (
            <>
              <Form.Item
                name="hypothesisA"
                label={t('shared.behaviorExperiment.hypothesisA')}
                tooltip={t('shared.behaviorExperiment.hypothesisATip')}
              >
                <Input.TextArea
                  placeholder={t(
                    'shared.behaviorExperiment.hypothesisAPlaceholder',
                  )}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              <Row>
                <Col span={13} push={2}>
                  <Form.Item
                    name="hypothesisAStrength"
                    label={t('shared.behaviorExperiment.hypothesisAStrength')}
                  >
                    <Slider min={1} max={100} step={1} />
                  </Form.Item>
                </Col>
                <Col span={1} offset={2}>
                  <Form.Item name="hypothesisAStrength">
                    <InputNumber min={1} max={100} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="hypothesisAConfirmationCriteria"
                label={t(
                  'shared.behaviorExperiment.hypothesisAConfirmationCriteria',
                )}
              >
                <Input.TextArea
                  placeholder={t(
                    'shared.behaviorExperiment.hypothesisAConfirmationCriteria',
                  )}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </>
          )}
        {(activeSubtype !== undefined || observationalSubtype !== undefined) &&
          (hypothesisType === 0 || hypothesisType === 1) &&
          generateArea}

        {(activeSubtype !== undefined || observationalSubtype !== undefined) &&
          (hypothesisType === 1 || hypothesisType === 2) && (
            <>
              <Form.Item
                name="hypothesisB"
                label={t('shared.behaviorExperiment.hypothesisB')}
                tooltip={t('shared.behaviorExperiment.hypothesisBTip')}
              >
                <Input.TextArea
                  placeholder={t(
                    'shared.behaviorExperiment.hypothesisBPlaceholder',
                  )}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              <Row>
                <Col span={13} push={2}>
                  <Form.Item
                    name="hypothesisBStrength"
                    label={t('shared.behaviorExperiment.hypothesisBStrength')}
                  >
                    <Slider min={1} max={100} step={1} />
                  </Form.Item>
                </Col>
                <Col span={1} offset={2}>
                  <Form.Item name="hypothesisBStrength">
                    <InputNumber min={1} max={100} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="hypothesisBConfirmationCriteria"
                label={t(
                  'shared.behaviorExperiment.hypothesisBConfirmationCriteria',
                )}
              >
                <Input.TextArea
                  placeholder={t(
                    'shared.behaviorExperiment.hypothesisBConfirmationCriteria',
                  )}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </>
          )}

        {(activeSubtype !== undefined || observationalSubtype !== undefined) &&
          hypothesisType === 2 &&
          generateArea}

        {(activeSubtype !== undefined ||
          observationalSubtype !== undefined) && (
          <>
            <Divider />
            <Title level={4}>{t('shared.behaviorExperiment.settings')}</Title>

            <Form.Item
              name={['settings', 'experiment']}
              label={t('shared.behaviorExperiment.experiment')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.expremientPlaceholder',
                )}
                autoSize={{ minRows: 4, maxRows: 8 }}
              />
            </Form.Item>

            <Form.Item
              name={['settings', 'time']}
              label={t('shared.behaviorExperiment.time')}
            >
              <Input.TextArea
                placeholder={t('shared.behaviorExperiment.time')}
                autoSize={{ minRows: 2, maxRows: 4 }}
              />
            </Form.Item>

            {(activeSubtype === 0 ||
              (observationalSubtype !== undefined &&
                observationalSubtype !== 2)) && (
              <Form.Item
                name={['settings', 'place']}
                label={t('shared.behaviorExperiment.place')}
              >
                <Input.TextArea
                  placeholder={t('shared.behaviorExperiment.place')}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              </Form.Item>
            )}

            <Form.Item
              name={['settings', 'people']}
              label={t('shared.behaviorExperiment.people')}
            >
              <Input.TextArea
                placeholder={t('shared.behaviorExperiment.peoplePlaceholder')}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['settings', 'resources']}
              label={t('shared.behaviorExperiment.resources')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.resourcesPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            {observationalSubtype === 1 && (
              <Form.Item
                name={['settings', 'survey']}
                label={t('shared.behaviorExperiment.survey')}
              >
                <Input.TextArea
                  placeholder={t('shared.behaviorExperiment.surveyPlaceholder')}
                  autoSize={{ minRows: 4, maxRows: 8 }}
                />
              </Form.Item>
            )}

            <Divider />
            <Title level={4}>
              {t('shared.behaviorExperiment.safetyAndCoping')}
            </Title>
            <Form.Item
              name="safetyBehaviors"
              label={t('shared.behaviorExperiment.safetyBehaviors')}
              tooltip={t('shared.behaviorExperiment.safetyBehaviorsTip')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.safetyBehaviorsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name="helpfulCopingStrategies"
              label={t('shared.behaviorExperiment.helpfulCopingStrategies')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.helpfulCopingStrategiesPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Divider />

            <Title level={4}>{t('shared.behaviorExperiment.prediction')}</Title>

            <Form.Item
              name={['prediction', 'behaviors']}
              label={t('shared.behaviorExperiment.behaviors')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.behaviorsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['prediction', 'emotions', 'id']}
              label={t('shared.behaviorExperiment.expectedEmotions')}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(emotionsIds) => {
                  const eventEmotions =
                    (emotionsIds as unknown as number[]).map((id) => {
                      const emotion = flattenedEmotions.find(
                        (e) => e.id === id,
                      );
                      if (emotion) {
                        const setEmotions = form.getFieldValue([
                          'prediction',
                          'emotions',
                        ]);
                        const existingEmotion = setEmotions.find(
                          (em: any) => em.id === id,
                        );
                        return {
                          id: emotion.id,
                          strength: !!existingEmotion
                            ? existingEmotion.strength
                            : 50,
                        };
                      }
                    }) || [];

                  form.setFieldValue(['prediction', 'emotions'], eventEmotions);
                }}
              >
                {baseEmotions.map((eg) => {
                  return (
                    <OptGroup label={eg.name} key={eg.name}>
                      {eg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <div
              style={{
                display: 'none',
              }}
            >
              <Form.List name={['prediction', 'emotions']}>
                {(fields, { add, remove }, errors) => {
                  const predictionEmotions = form.getFieldValue([
                    'prediction',
                    'emotions',
                  ]);
                  return (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Row key={field.key}>
                            <Col span={13} push={2}>
                              <Form.Item
                                {...field}
                                label={
                                  flattenedEmotions.find(
                                    (e) =>
                                      e.id === predictionEmotions[field.key].id,
                                  )?.name || ''
                                }
                                key={field.key}
                                name={[field.key, 'strength']}
                              >
                                <Slider min={1} max={100} />
                              </Form.Item>
                            </Col>
                            <Col span={1} offset={2}>
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key, 'strength']}
                              >
                                <InputNumber min={1} max={100} />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  );
                }}
              </Form.List>
            </div>

            <Form.Item
              label={t('shared.behaviorExperiment.expectedFeelings')}
              name={['prediction', 'feelings']}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {baseFeelings.map((bg) => {
                  return (
                    <OptGroup label={bg.name} key={bg.name}>
                      {bg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name={['prediction', 'otherPredictions']}
              label={t('shared.behaviorExperiment.otherPredictions')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.otherPredictionsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Divider />
            <Title level={4}>{t('shared.behaviorExperiment.outcome')}</Title>
            <Form.Item
              name={['outcome', 'behaviors']}
              label={t('shared.behaviorExperiment.actualBehavior')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.actualBehaviorPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['outcome', 'emotions', 'id']}
              label={t('shared.behaviorExperiment.actualEmotions')}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(emotionsIds) => {
                  const eventEmotions =
                    (emotionsIds as unknown as number[]).map((id) => {
                      const emotion = flattenedEmotions.find(
                        (e) => e.id === id,
                      );
                      if (emotion) {
                        const outcomeEmotions = form.getFieldValue([
                          'outcome',
                          'emotions',
                        ]);

                        const existingEmotion = outcomeEmotions.find(
                          (em: any) => em.id === id,
                        );
                        return {
                          id: emotion.id,
                          strength: !!existingEmotion
                            ? existingEmotion.strength
                            : 50,
                        };
                      }
                    }) || [];

                  form.setFieldValue(['outcome', 'emotions'], eventEmotions);
                }}
              >
                {baseEmotions.map((eg) => {
                  return (
                    <OptGroup label={eg.name} key={eg.name}>
                      {eg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            {!!outcome?.emotions?.length && (
              <Title level={5} style={{ textAlign }}>
                {t('createThought.rateEmotions')}:
              </Title>
            )}

            <Form.List name={['outcome', 'emotions']}>
              {(fields, { add, remove }, errors) => {
                const outcomeEmotions = form.getFieldValue([
                  'outcome',
                  'emotions',
                ]);
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Row key={field.key}>
                          <Col span={13} push={2}>
                            <Form.Item
                              {...field}
                              label={
                                flattenedEmotions.find(
                                  (e) => e.id === outcomeEmotions[field.key].id,
                                )?.name || ''
                              }
                              key={field.key}
                              name={[field.key, 'strength']}
                            >
                              <Slider min={1} max={100} />
                            </Form.Item>
                          </Col>
                          <Col span={1} offset={2}>
                            <Form.Item
                              {...field}
                              key={field.key}
                              name={[field.key, 'strength']}
                            >
                              <InputNumber min={1} max={100} />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                );
              }}
            </Form.List>

            <Form.Item
              label={t('shared.behaviorExperiment.actualFeelings')}
              name={['outcome', 'feelings']}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {baseFeelings.map((bg) => {
                  return (
                    <OptGroup label={bg.name} key={bg.name}>
                      {bg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name={['outcome', 'patientReflections']}
              label={t('shared.behaviorExperiment.patientReflections')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.patientReflectionsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['outcome', 'therapistReflections']}
              label={t('shared.behaviorExperiment.therapistReflections')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.therapistReflectionsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['outcome', 'newLearnings']}
              label={t('shared.behaviorExperiment.newLearnings')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.newLearningsPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              name={['outcome', 'meaningForOriginalBelief']}
              label={t('shared.behaviorExperiment.meaningForOriginalBelief')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.meaningForOriginalBeliefPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Row>
              <Col span={13} push={2}>
                <Form.Item
                  name={['outcome', 'oldThoughtStrength']}
                  label={t('shared.behaviorExperiment.oldThoughtStrength')}
                >
                  <Slider min={1} max={100} step={1} />
                </Form.Item>
              </Col>
              <Col span={1} offset={2}>
                <Form.Item name={['outcome', 'oldThoughtStrength']}>
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name={['outcome', 'newThought']}
              label={t('shared.behaviorExperiment.newThought')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.newThoughtPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Row>
              <Col span={13} push={2}>
                <Form.Item
                  name={['outcome', 'newThoughtStrength']}
                  label={t('shared.behaviorExperiment.newThoughtStrength')}
                >
                  <Slider min={1} max={100} step={1} />
                </Form.Item>
              </Col>
              <Col span={1} offset={2}>
                <Form.Item name={['outcome', 'newThoughtStrength']}>
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Title level={4}>{t('shared.behaviorExperiment.followUp')}</Title>
            <Form.Item
              name={'followUpExperimentsIdeas'}
              label={t('shared.behaviorExperiment.followUpExperimentsIdeas')}
            >
              <Input.TextArea
                placeholder={t(
                  'shared.behaviorExperiment.followUpExperimentsIdeasPlaceholder',
                )}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Create>
  );
};
