import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Create,
  Form,
  message,
  Select,
  Tabs,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { useActor } from '@xstate/react';
import enUSPicker from 'antd/es/date-picker/locale/en_US';
import ruPicker from 'antd/es/date-picker/locale/ru_RU';
import { useWatch } from 'antd/lib/form/Form';
import {
  endOfDay,
  formatDuration,
  intervalToDuration,
  parseISO,
  roundToNearestMinutes,
} from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { debounce } from 'lodash';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { TherapistActivityReactContext } from '../../App';
import { DateFnsDatePicker } from '../../components/Pickers';
import { useClientOptions } from '../../hooks/client-options';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { Session } from '../../schema/be-graphql-generated';
import { ProgressNote } from './create/ProgressNote';
import './styles.less';

const { Title } = Typography;

const { RangePicker } = DateFnsDatePicker;

export const SessionsCreate = () => {
  const { t, i18n } = useTranslation();
  const location = routerProvider.useLocation();

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const { breifClientInfo, clientsLoading, memoizedClienOptions } =
    useClientOptions();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { clientId: locationClientId, sessionsId: sessionIdFromLocation } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const { innerWidth } = useWindowSize();

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState.context.filterClientId ||
      (locationClientId as string),
  );

  const initialValues = useMemo(() => {
    return {
      placeOfServiceCode: '10',
      sessionRange: [] as [Date, Date] | [],
    };
  }, []);

  const [form] = Form.useForm<typeof initialValues>();

  const sessionRange = useWatch('sessionRange', form);
  const placeOfServiceCode = useWatch('placeOfServiceCode', form);

  const [sessionId, setSessionId] = useState(
    sessionIdFromLocation as string | undefined,
  );

  const {
    data: sessionData,
    isLoading: sessionLoading,
    refetch: refetchSession,
    error: sessionError,
  } = useCustom<Session>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'session',
      fields: [
        '_id',
        '_to',
        'createdAt',
        'updatedAt',
        'finishedAt',
        'placeOfServiceCode',
      ],
      variables: {
        id: {
          name: 'id',
          type: 'ID',
          value: sessionId,
        },
      },
    },
  });

  useEffect(() => {
    if (sessionData?.data) {
      const {
        __typename,
        _id,
        _key,
        _rev,
        createdAt,
        updatedAt,
        finishedAt,
        ...rest
      } = sessionData?.data;

      const sessionRange =
        createdAt && finishedAt
          ? [parseISO(createdAt), parseISO(finishedAt)]
          : createdAt
          ? [parseISO(createdAt)]
          : [];

      form?.setFieldsValue({
        placeOfServiceCode: rest.placeOfServiceCode || '10',
        sessionRange,
      });

      setClientId(sessionData?.data._to);
    }
  }, [sessionData]);

  const {
    mutate: upsertSession,
    data: upsertSessionData,
    isLoading: upsertSessionLoading,
    error: upsertSessionError,
  } = useCustomMutation<{ session: Session }>();

  const saveData = useCallback(
    async (values?: typeof initialValues) => {
      const { sessionRange, placeOfServiceCode } =
        values || form?.getFieldsValue();
      const isReady = true;

      if (!clientId || !isReady) {
        return;
      }

      const res = await upsertSession({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertSession',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'SessionInput',
              value: {
                data: {
                  placeOfServiceCode,
                  createdAt: sessionRange[0],
                  finishedAt: sessionRange[1],
                },
                where: {
                  _id: sessionId,
                  _to: clientId,
                },
              },
            },
          },
        },
      });
      message.success(t('pages.sessions.saveSuccess'));
    },
    [clientId, sessionId, t],
  );

  const onValuesChange = useCallback(
    debounce(
      (
        changedValues: Partial<typeof initialValues>,
        allValues: typeof initialValues,
      ) => {
        saveData(allValues);
      },
      1000,
    ),
    [saveData, sessionId],
  );

  useEffect(() => {
    // @ts-ignore
    if (upsertSessionData?.data?._id) {
      // @ts-ignore

      if (!sessionId) {
        // @ts-ignore
        setSessionId(upsertSessionData?.data?._id);
      }
    }
  }, [upsertSessionData]);

  const { diagnosesOnClient, preferredPronounsOnclient, accessLevel } =
    useMemo(() => {
      const clientInfo = breifClientInfo.find((c) => c._id === clientId);

      return {
        diagnosesOnClient: (clientInfo?.diagnoses || []).filter(
          (d) => !!d,
        ) as string[],
        preferredPronounsOnclient: clientInfo?.preferredPronouns,
        accessLevel: clientInfo?.accessLevel,
      };
    }, [breifClientInfo, clientId]);

  const generalInfomation = (
    <Form<typeof initialValues>
      form={form}
      initialValues={{
        ...initialValues,
      }}
      onValuesChange={(changes, allChanges) => {
        if (!sessionId && upsertSessionLoading) return;
        onValuesChange(changes, allChanges);
      }}
      onFinish={(values) => {}}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 24 }}
      labelWrap
    >
      <div className="sessions--mental-status">
        <Form.Item
          name="sessionRange"
          label={t('pages.sessions.sessionRange')}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="sessions--data-column--xxl"
        >
          <RangePicker
            locale={currentLocale?.startsWith('ru') ? ruPicker : enUSPicker}
            showTime={{
              format: currentLocale?.startsWith('ru') ? 'HH:mm' : 'hh:mm a',
            }}
            format={
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm'
                : 'yyyy-MM-dd hh:mm a'
            }
            disabledDate={(current) => {
              return current && current > endOfDay(new Date());
            }}
            minuteStep={5}
            onChange={(dates) => {
              const roundedStart = dates?.[0]
                ? roundToNearestMinutes(dates?.[0], {
                    nearestTo: 1,
                    roundingMethod: 'floor',
                  })
                : undefined;
              const roundedEnd = dates?.[1]
                ? roundToNearestMinutes(dates?.[1], {
                    nearestTo: 1,
                    roundingMethod: 'ceil',
                  })
                : undefined;
              form?.setFieldsValue({
                // @ts-ignore
                sessionRange: [roundedStart, roundedEnd],
              });
            }}
          />
        </Form.Item>

        <Form.Item
          name="sessionRange"
          label={t('pages.sessions.duration')}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="sessions--data-column--s"
        >
          <div className="duration--content">
            {sessionRange?.[0] && sessionRange?.[1]
              ? formatDuration(
                  intervalToDuration({
                    start: sessionRange[0],
                    end: sessionRange[1],
                  }),
                  {
                    format: ['days', 'hours', 'minutes'],

                    locale: currentLocale?.startsWith('ru') ? ru : enUS,
                  },
                )
              : t('common.na')}
          </div>
        </Form.Item>

        <Form.Item
          name="placeOfServiceCode"
          label={t('pages.sessions.placeOfServiceLabel')}
          tooltip={t('pages.sessions.placeOfServiceLabelTip')}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="sessions--data-column--xxxxl"
        >
          <Select
            options={(
              t('pages.sessions.placeOfServiceCodes', {
                returnObjects: true,
              }) as any[]
            ).map((format, idx) => ({
              label: format.code + ' - ' + format.name,
              value: format.code,
            }))}
          />
        </Form.Item>
      </div>
    </Form>
  );

  return (
    <Create
      title={t('pages.sessions.title')}
      isLoading={clientsLoading}
      saveButtonProps={{
        onClick: () => saveData(),
        disabled: !clientId || sessionLoading,
        style: { display: 'none' },
      }}
    >
      <div className="session-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="session-client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
      </div>

      <Tabs
        items={(t('pages.sessions.tabs', { returnObjects: true }) as any[]).map(
          (i, idx) => ({
            label: (
              <span>
                <b>{i}</b>
              </span>
            ),
            size: 'large',
            key: String(idx),
            children:
              idx === 0 ? (
                generalInfomation
              ) : idx === 1 ? (
                <ProgressNote
                  sessionId={sessionId || ''}
                  diagnosesOnClient={diagnosesOnClient}
                  preferredPronounsOnclient={preferredPronounsOnclient}
                  clientId={clientId}
                  placeOfServiceCode={placeOfServiceCode}
                  sessionRange={sessionRange}
                  accessLevel={accessLevel}
                />
              ) : (
                <div>In development...</div>
              ),
            disabled: [2, 3].includes(idx),
          }),
        )}
      />
    </Create>
  );
};
