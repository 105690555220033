import {
  useCustomMutation,
  useGetLocale,
  useWarnAboutChange,
} from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import routerProvider from '@pankod/refine-react-router-v6';
import qs from 'qs';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Create,
  Input,
  message,
  Select,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { useWindowSize } from 'rooks';
import Card from 'antd/lib/card/Card';
import { useActor, useMachine } from '@xstate/react';
import * as plantumlEncoder from 'plantuml-encoder';
import './styles.less';
import {
  createWorryTreeMachine,
  generateWorryTreeUmlText,
  WorryTreeContext,
} from '@cc/utils';
import { useClientOptions } from '../../hooks/client-options';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { WorryTree } from '../../schema/be-graphql-generated';
import { TherapistActivityReactContext } from '../../App';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { dbCols } from '@cc/schema';

const { Title } = Typography;

const worryMachine = createWorryTreeMachine({});

export const WorryTreeCreate = () => {
  const { t, i18n } = useTranslation();
  const locale = useGetLocale();
  const currentLocale = locale();
  const location = routerProvider.useLocation();
  const { setWarnWhen } = useWarnAboutChange();

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const { clientId: locationClientId, worryTreeId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const { innerWidth } = useWindowSize();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState.context.filterClientId ||
      (locationClientId as string),
  );

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const [worryTreeState, worryTreeSend, worryTreeService] =
    useMachine(worryMachine);

  const [encodedText, setEncodedText] = useState('');

  const {
    mutate: createWorryTree,
    data: createWorryTreeData,
    isLoading: createWorryTreeLoading,
    error: createWorryTreeError,
  } = useCustomMutation<{ supportingCycle: WorryTree }>();

  useActivityTracking(
    // @ts-ignore
    createWorryTreeData?.data?._id || `${dbCols.worryTrees}/create`,
  );

  useEffect(() => {
    // @ts-ignore
    if (createWorryTreeData?.data?._id) {
      if (!worryTreeId) {
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: createWorryTreeData?.data?._id,
        });
      }
    }
  }, [createWorryTreeData]);

  const saveData = async (data: WorryTreeContext) => {
    const res = await createWorryTree({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'createWorryTree',
        fields: ['_id'],
        variables: {
          input: {
            name: 'input',
            type: 'WorryTreeInput',
            value: {
              data,
              where: {
                clientId,
              },
            },
          },
        },
      },
    });
    message.success(t('shared.worryTree.saveSuccess'));
    setWarnWhen(false);
  };

  useEffect(() => {
    if (worryTreeState.matches('done')) {
      const umlText = generateWorryTreeUmlText(
        worryTreeState.context,
        // @ts-ignore
        t,
        currentLocale,
        (innerWidth || 0) < 850,
      );
      const encoded = plantumlEncoder.encode(umlText);
      setEncodedText(encoded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worryTreeState.matches('done'), i18n.language]);

  useEffect(() => {
    if (worryTreeState.matches('done')) {
      saveData(worryTreeState.context);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worryTreeState.matches('done')]);

  useEffect(() => {
    setWarnWhen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worryTreeState.matches('isReal')]);

  return (
    <Create
      title={t('shared.worryTree.title')}
      isLoading={clientsLoading}
      saveButtonProps={{
        style: {
          display: 'none',
        },
      }}
    >
      <div className="worry-tree-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="worry-tree--client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
        {clientId && worryTreeState.matches('worryAbout') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.worryAbout')}</Title>
            <Input.TextArea
              id="worryAbout"
              autoSize={{ minRows: 4, maxRows: 10 }}
              value={worryTreeState.context.worryAbout}
              onChange={(e) => {
                worryTreeSend({
                  type: 'WORRY_ABOUT_CHANGED',
                  data: e.target.value,
                });
              }}
              placeholder={t('shared.worryTree.worryAbout')}
              className="worry-tree--input"
            />
            <Button
              type="primary"
              size="large"
              disabled={worryTreeState.context.worryAbout.length < 6}
              onClick={() => {
                worryTreeSend('GO_NEXT');
              }}
            >
              {t('resourcesCommon.next')}
            </Button>
          </div>
        ) : null}

        {worryTreeState.matches('isReal') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.isReal')}</Title>
            <div className="worry-tree--buttons-container">
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend({
                    type: 'GO_NEXT',
                    decisionIdx: 0,
                  });
                }}
              >
                {t('resourcesCommon.yes')}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend('GO_NEXT');
                }}
              >
                {t('resourcesCommon.no')}
              </Button>
            </div>
          </div>
        ) : null}

        {worryTreeState.matches('isDoable') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.isDoable')}</Title>
            <div className="worry-tree--buttons-container">
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend({
                    type: 'GO_NEXT',
                    decisionIdx: 0,
                  });
                }}
              >
                {t('resourcesCommon.yes')}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend('GO_NEXT');
                }}
              >
                {t('resourcesCommon.no')}
              </Button>
            </div>
          </div>
        ) : null}

        {worryTreeState.matches('listOptions') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.listOptions')}</Title>
            <Input.TextArea
              id="worryAbout"
              autoSize={{ minRows: 4, maxRows: 10 }}
              value={worryTreeState.context.options}
              onChange={(e) => {
                worryTreeSend({
                  type: 'OPTIONS_CHANGED',
                  data: e.target.value,
                });
              }}
              placeholder={t('shared.worryTree.developPlan')}
              className="worry-tree--input"
            />
            <Button
              type="primary"
              size="large"
              disabled={worryTreeState.context.options.length < 6}
              onClick={() => {
                worryTreeSend('GO_NEXT');
              }}
            >
              {t('resourcesCommon.next')}
            </Button>
          </div>
        ) : null}

        {worryTreeState.matches('isDoableNow') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.isDoableNow')}</Title>
            <div className="worry-tree--buttons-container">
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend({
                    type: 'GO_NEXT',
                    decisionIdx: 0,
                  });
                }}
              >
                {t('resourcesCommon.yes')}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  worryTreeSend('GO_NEXT');
                }}
              >
                {t('resourcesCommon.no')}
              </Button>
            </div>
          </div>
        ) : null}

        {worryTreeState.matches('createPlan') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.developPlan')}</Title>
            <Input.TextArea
              id="worryAbout"
              autoSize={{ minRows: 4, maxRows: 10 }}
              value={worryTreeState.context.plan}
              onChange={(e) => {
                worryTreeSend({
                  type: 'PLAN_CHANGED',
                  data: e.target.value,
                });
              }}
              placeholder={t('shared.worryTree.developPlan')}
              className="worry-tree--input"
            />
            <Button
              type="primary"
              size="large"
              disabled={worryTreeState.context.plan.length < 6}
              onClick={() => {
                worryTreeSend('GO_NEXT');
              }}
            >
              {t('resourcesCommon.next')}
            </Button>
          </div>
        ) : null}

        {worryTreeState.matches('immediateAction') ? (
          <div className="fading-container">
            <Title level={4}>{t('shared.worryTree.immediateAction')}</Title>
            <Input.TextArea
              id="worryAbout"
              autoSize={{ minRows: 4, maxRows: 10 }}
              value={worryTreeState.context.immediateAction}
              onChange={(e) => {
                worryTreeSend({
                  type: 'IMMEDIATE_ACTION_CHANGED',
                  data: e.target.value,
                });
              }}
              placeholder={t('shared.worryTree.immediateAction')}
              className="worry-tree--input"
            />
            <Button
              type="primary"
              size="large"
              disabled={worryTreeState.context.immediateAction.length < 6}
              onClick={() => {
                worryTreeSend('GO_NEXT');
              }}
            >
              {t('resourcesCommon.finish')}
            </Button>
          </div>
        ) : null}

        {worryTreeState.matches('switchFocus') ||
        worryTreeState.matches('done') ? (
          <div className="fading-container">
            <Title level={4}>
              {t(
                worryTreeState.matches('done')
                  ? 'shared.worryTree.doneTitle'
                  : 'shared.worryTree.refocusTitle',
              )}
            </Title>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 15,
                justifyContent: 'center',
              }}
            >
              {(
                t('shared.worryTree.refocusIdeas', {
                  returnObjects: true,
                }) as {
                  title: string;
                  info: string;
                  mobileExtraInfo?: string;
                  link?: string;
                  browserLink?: string;
                  root?: string;
                }[]
              ).map((item, idx) => {
                return worryTreeState.matches('done') &&
                  worryTreeState.context.selectedActivityIdx !== idx ? null : (
                  <Card
                    key={item.title}
                    hoverable={true}
                    onClick={() => {
                      if (!worryTreeState.matches('done')) {
                        worryTreeSend({
                          type: 'GO_NEXT',
                          decisionIdx: idx,
                        });
                      }
                    }}
                    title={item.title}
                    bodyStyle={{
                      display:
                        worryTreeState.context.selectedActivityIdx !== idx
                          ? 'none'
                          : 'block',
                      maxWidth: (innerWidth || 0) > 400 ? 400 : '100%',
                    }}
                  >
                    <div>{item.info}</div>
                  </Card>
                );
              })}
            </div>
          </div>
        ) : null}
        {worryTreeState.matches('done') && encodedText && (
          <>
            <Title className="worry-tree--image-title" level={4}>
              {t('shared.worryTree.savedForReference')}
            </Title>
            <div
              style={{
                display: 'grid',
                maxWidth: '100%',
              }}
            >
              <img
                src={`http://www.plantuml.com/plantuml/img/${encodedText}`}
                alt="UML"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100vh',
                  margin: 'auto',
                }}
              />
            </div>
          </>
        )}
      </div>
    </Create>
  );
};
