import { dbCols, User } from '@cc/schema';
import { CreateButton, List, useTable, Table } from '@pankod/refine-antd';
import { useGetLocale, useList, useTranslate } from '@pankod/refine-core';
import { Tooltip } from 'antd';
import { format } from 'date-fns';
import { useWindowSize } from 'rooks';
import { useSubscriptionWarnings } from '../../hooks';
import { WorryTree } from '../../schema/be-graphql-generated';

import * as plantumlEncoder from 'plantuml-encoder';
import { generateWorryTreeUmlText } from '@cc/utils';
import { useContext, useEffect } from 'react';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';

export const WorryTreeList = () => {
  const translate = useTranslate();

  const locale = useGetLocale();

  const currentLocale = locale();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { innerWidth } = useWindowSize();

  // @ts-ignore
  const { tableProps, sorter, searchFormProps, filters, setFilters } =
    useTable<WorryTree>({
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
      initialFilter: therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      metaData: {
        operation: 'worryTrees',
        fields: [
          {
            data: [
              '_id',
              'userId',
              'createdAt',
              'worryAbout',
              'selectedActivityIdx',
              'isNotSolvable',
              'options',
              'plan',
              'immediateAction',
            ],
          },
          'count',
        ],
      },
    });

  useEffect(() => {
    setFilters(
      therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      'replace',
    );
  }, [therapistActivityState?.context?.filterClientId]);

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const getLocale = useGetLocale();

  useActivityTracking(dbCols.worryTrees);

  const expandedRowRender = (record: WorryTree) => {
    if (!record) {
      return null;
    }

    const umlText = generateWorryTreeUmlText(
      // @ts-ignore
      record,
      // @ts-ignore
      translate,
      getLocale(),
      (innerWidth || 0) < 850,
    );
    const encoded = plantumlEncoder.encode(umlText);

    return (
      <div
        style={{
          display: 'grid',
          maxWidth: '100%',
        }}
      >
        {encoded ? (
          <img
            src={`http://www.plantuml.com/plantuml/img/${encoded}`}
            alt="UML"
            style={{
              maxWidth: '100%',
              maxHeight: '100vh',
              margin: 'auto',
            }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <List
      title={translate('shared.worryTree.worryTreesTitle')}
      headerButtons={
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <CreateButton />
        </div>
      }
    >
      <Table
        {...tableProps}
        rowKey="_id"
        onChange={(pagination, filters, sorter, extra) => {
          // Note: The data we get from Ant.design filters does not match the
          // react refine one. Currently it works fine if I simply silence it the way below.
          // TOOD: Find a way to convert the data to match the react refine one and fix it better
          // @ts-ignore
          tableProps?.onChange?.(pagination, null, sorter, extra);
        }}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender,
        }}
      >
        <Table.Column
          key="_id"
          dataIndex="userId"
          title={translate('resourcesCommon.userId')}
          sorter
          filterSearch
          // @ts-ignore
          onFilter={(value: string, record: WorryTree) => {
            return record.userId === value;
          }}
          filters={
            // @ts-ignore
            clients?.data?.data?.map((c, idx) => {
              return {
                text: `${c._id} - ${c.firstName} ${c.lastName}`,
                value: c._id,
              };
            })
          }
          render={(value, record) => {
            // @ts-ignore
            const client = clients?.data?.data?.find?.(
              // @ts-ignore
              (c) => c._id === value,
            );

            return (
              <Tooltip
                title={client ? `${client.firstName} ${client.lastName}` : ''}
              >
                {`${value.replace('users/', '')} - ${(
                  client?.firstName || ''
                ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
              </Tooltip>
            );
          }}
        />
        <Table.Column
          key="worryAbout"
          dataIndex="worryAbout"
          title={translate('shared.worryTrees.worryAbout')}
        />
        <Table.Column<WorryTree>
          key="isNotSolvable"
          dataIndex="isNotSolvable"
          title={translate('shared.worryTrees.notSolvable')}
          render={(value, record) => {
            return translate('resourcesCommon.' + (value ? 'yes' : 'no'));
          }}
        />
        <Table.Column
          key="options"
          dataIndex="options"
          title={translate('shared.worryTrees.options')}
        />
        <Table.Column
          key="immediateAction"
          dataIndex="immediateAction"
          title={translate('shared.worryTrees.immediateAction')}
        />
        <Table.Column
          key="plan"
          dataIndex="plan"
          title={translate('shared.worryTrees.plan')}
        />
        <Table.Column<WorryTree>
          key="selectedActivityIdx"
          dataIndex="selectedActivityIdx"
          title={translate('shared.worryTrees.refocusActivity')}
          render={(value, record) => {
            const options = translate('shared.worryTree.refocusIdeas', {
              returnObjects: true,
            });

            const activity =
              record.selectedActivityIdx === null
                ? ''
                : // @ts-ignore
                  options[record.selectedActivityIdx || 0]?.title;

            return activity;
          }}
        />
        <Table.Column
          key="createdAt"
          dataIndex="createdAt"
          title={translate('resourcesCommon.createdAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss a',
            );
          }}
        />
      </Table>
    </List>
  );
};
