import {
  Button,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Input,
  List,
  SaveButton,
  Select,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';

import { User, UserRole } from '@cc/schema';
import { useCcEditableTable } from '../../helpers';
import { IUser } from '../../providers';

const metaData = {
  fields: [
    {
      data: ['_id', '_key', 'firstName', 'lastName', 'role', 'email'],
    },
    'count',
  ],
};

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { push } = useNavigation();

  const { data: identity, remove, refetch } = useGetIdentity<IUser>();

  const {
    table: { tableProps },

    form: { formProps },
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useCcEditableTable<User>(
    {
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: '_id',
          order: 'asc',
        },
      ],
      metaData,
    },
    {
      metaData: {
        fields: ['_id', '_key', 'firstName', 'lastName', 'role', 'email'],
      },
    },
  );

  return (
    <List
      title={translate('practiceUsers.practiceUsers')}
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton
              onClick={() => {
                push('/invites/create?type=2');
              }}
            >
              {translate('buttons.invite')}
            </CreateButton>
          </Space>
        ),
      }}
    >
      <Form {...formProps}>
        <Table {...tableProps} rowKey="_id">
          <Table.Column key="_key" dataIndex="_key" title="ID" />
          <Table.Column<User>
            key="firstName"
            dataIndex="firstName"
            title={translate('practiceUsers.fields.firstName')}
            sorter
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item name={['firstName']}>
                    <Input />
                  </Form.Item>
                );
              }
              return value;
            }}
          />
          <Table.Column<User>
            key="lastName"
            dataIndex="lastName"
            title={translate('practiceUsers.fields.lastName')}
            sorter
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item name={['lastName']}>
                    <Input />
                  </Form.Item>
                );
              }
              return value;
            }}
          />
          <Table.Column<User>
            key="role"
            dataIndex="role"
            title={translate('practiceUsers.fields.role')}
            render={(value, record) => {
              if (record._id !== identity?.db?._id && isEditing(record._id)) {
                return (
                  <Form.Item name={['role']}>
                    <Select
                      style={{ width: 250 }}
                      options={[
                        {
                          value: UserRole.THERAPIST_ADMIN,
                          label: translate(`roles.${UserRole.THERAPIST_ADMIN}`),
                        },
                        {
                          value: UserRole.THERAPIST,
                          label: translate(`roles.${UserRole.THERAPIST}`),
                        },
                      ]}
                    />
                  </Form.Item>
                );
              }
              return translate(`roles.${value}`);
            }}
            sorter
          />
          <Table.Column
            key="email"
            dataIndex="email"
            title={translate('practiceUsers.fields.email')}
          />
          <Table.Column<User>
            title={translate('resourcesCommon.actions')}
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record._id)) {
                return (
                  <Space>
                    <SaveButton {...saveButtonProps} size="small" />
                    <Button {...cancelButtonProps} size="small">
                      {translate('buttons.cancel')}
                    </Button>
                  </Space>
                );
              }

              return (
                <Space>
                  <EditButton
                    {...editButtonProps(record._id)}
                    hideText
                    size="small"
                  />
                  {/* <ShowButton hideText size="small" recordItemId={record._id} /> */}
                  {record._id !== identity?.db?._id && (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record._id}
                      metaData={{
                        fields: ['_id'],
                      }}
                    />
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
