import React from 'react';
import { TitleProps } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={'/logo-collapsed.svg'}
        alt="Refine"
        style={{
          width: '90px',
          height: '95px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px 24px',
        }}
      />
    ) : (
      <img
        src={'/logo.svg'}
        alt="CopingCard"
        style={{
          width: '200px',
          padding: '12px 24px',
        }}
      />
    )}
  </Link>
);
