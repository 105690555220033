import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useNavigation,
  useTranslate,
  useLogout,
  useCustomMutation,
  useList,
} from '@pankod/refine-core';
import {
  AntdLayout,
  Space,
  Menu,
  message,
  Modal,
  Button,
  Icons,
  Dropdown,
  Typography,
  useModal,
  Tooltip,
  Select,
  Divider,
  Form,
} from '@pankod/refine-antd';
import { resources } from '../../../i18n';
import { User, UserRole } from '@cc/schema';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IUser } from '../../../providers';
import { LOCAL_STORAGE_KEYS } from '../../../helpers';
import { firebaseAuth } from '../../../authProvider';
import { useSubscriptionWarnings } from '../../../hooks';
import './styles.less';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useActor } from '@xstate/react';
import { TherapistActivityReactContext } from '../../../App';
import { TherapistActivityService } from '../../../machines/therapistActivityMachine';
import { enUS, ru } from 'date-fns/locale';
import { DateFnsDatePicker } from '../../Pickers';
import enUSPicker from 'antd/es/date-picker/locale/en_US';
import ruPicker from 'antd/es/date-picker/locale/ru_RU';
import {
  intervalToDuration,
  formatDuration,
  roundToNearestMinutes,
  isSameDay,
} from 'date-fns';
import { Session } from '../../../schema/be-graphql-generated';

const { DownOutlined } = Icons;
const { Paragraph } = Typography;

export const HeaderInernal: React.FC = () => {
  const translate = useTranslate();
  const changeLanguage = useSetLocale();
  const locale = useGetLocale();

  const currentLocale = locale();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { data: identity, remove, refetch } = useGetIdentity<IUser>();

  const { replace } = useNavigation();

  const { modalProps, show, close } = useModal();
  const { mutate: logout } = useLogout();

  const { mutate } = useCustomMutation<User>();

  const [isSessionActive, setIsSessionActive] = useState(false);
  const [sessionStartTime, setSessionStartTime] = useState<Date | undefined>();
  const [sessionEndTime, setSessionEndTime] = useState<Date | undefined>();
  const [duration, setDuration] = useState<string | undefined>();

  const {
    mutate: upsertSession,
    data: upsertSessionData,
    isLoading: upsertSessionLoading,
    error: upsertSessionError,
  } = useCustomMutation<{ session: Session }>();

  const saveData = async () => {
    if (!therapistActivityState?.context.filterClientId || !sessionStartTime) {
      return;
    }

    const res = await upsertSession({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'upsertSession',
        fields: ['_id'],
        variables: {
          input: {
            name: 'input',
            type: 'SessionInput',
            value: {
              data: {
                createdAt: sessionStartTime,
                finishedAt:
                  sessionEndTime ||
                  roundToNearestMinutes(new Date(), {
                    nearestTo: 1,
                    roundingMethod: 'ceil',
                  }),
              },
              where: {
                _to: therapistActivityState?.context.filterClientId,
              },
            },
          },
        },
      },
    });
    message.success(translate('pages.sessions.saveSuccess'), 1);
  };

  useEffect(() => {
    const handle = setInterval(() => {
      if (isSessionActive && sessionStartTime) {
        setDuration(
          formatDuration(
            intervalToDuration({
              start: sessionStartTime,
              end: sessionEndTime || new Date(),
            }),
            {
              format: ['days', 'hours', 'minutes'],
              locale: currentLocale?.startsWith('ru') ? ru : enUS,
            },
          ),
        );
      } else {
        setDuration(undefined);
      }
    }, 1000);

    return () => clearInterval(handle);
  }, [isSessionActive, sessionStartTime, sessionEndTime, currentLocale]);

  const updateUser = async () => {
    await mutate({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'updateUserRole',
        fields: [{ user: ['_id'] }],
        variables: {
          role: {
            name: 'role',
            type: 'Int!',
            value: UserRole.THERAPIST_ADMIN,
          },
        },
      },
    });

    await remove();
    firebaseAuth.resetCachedDbUser();
    await refetch();
  };

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  useEffect(() => {
    // TODO: turn on when email verification is back?
    // Make sure Tamila is fine
    // if (identity?.fb && !identity?.fb?.emailVerified) {
    //   replace('/verify-email');
    // }
    const clientAccountWarningShown = localStorage.getItem(
      LOCAL_STORAGE_KEYS.clientAccountWarningShown,
    );

    if (
      identity?.db &&
      [UserRole.FREE, UserRole.PAID].includes(identity.db.role) &&
      !clientAccountWarningShown
    ) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.clientAccountWarningShown,
        'true',
      );
      show();
    }
  }, [identity?.db?.role]);

  useEffect(() => {
    if (identity?.db && !identity?.db?.practiceId) {
      // Note: it fails initially without a timeout
      const handle = setTimeout(() => replace('/initial-setup'), 50);
      return () => clearTimeout(handle);
    }
  }, [identity?.db?.practiceId]);

  const [selectedClientId, setSelectedClientId] = useState<
    string | undefined
  >();

  const clientsOptions = useMemo(() => {
    return (
      // @ts-ignore
      clients?.data?.data?.map((client: User) => ({
        label: `${client._id.replace('users/', '')} - ${client.firstName} ${
          client.lastName
        }`,
        value: client._id,
      })) || []
    );

    // @ts-ignore
  }, [clients?.data?.data]);

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {Object.keys(resources)
        .sort()
        .map((lang: string) => (
          <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
            {lang?.startsWith('en') ? 'English' : 'Русский'}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <AntdLayout.Header className="header">
      <div className="header-main-row">
        <div className="header-filter-client">
          <div className="header-filter-client--label">
            {translate('components.header.filterResults')}:
          </div>

          <Tooltip title={translate('components.header.filterTip')}>
            <QuestionCircleOutlined className="header-filter-client--tooltip" />
          </Tooltip>
          <Select
            allowClear
            loading={clientsLoading}
            options={clientsOptions}
            optionFilterProp="children"
            style={{ minWidth: 120, width: 240, marginRight: 10 }}
            mode="tags"
            tagRender={(props) => {
              // @ts-ignore
              const foundClient = (clients?.data?.data ?? []).find(
                (client: User) => client._id === props.value,
              );
              return foundClient ? (
                <Tooltip
                  title={`${foundClient.firstName} ${foundClient.lastName}`}
                >
                  {`${(selectedClientId || '').replace('users/', '')} - ${(
                    foundClient?.firstName || ''
                  ).charAt(0)}${(foundClient?.lastName || '').charAt(0)}`}
                </Tooltip>
              ) : (
                <></>
              );
            }}
            value={selectedClientId ? [selectedClientId] : []}
            placeholder={translate('components.header.selectClient')}
            filterOption={(input, option) =>
              ((option?.label ?? '') as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onClear={() => {
              therapistActivitySend('UNSET_FILTER_CLIENT_ID');
              setSelectedClientId(undefined);
            }}
            onChange={(value: string[]) => {
              const maybeNewValue = value?.pop();
              // @ts-ignore
              const foundClient = (clients?.data?.data ?? []).find(
                (client: User) => client._id === maybeNewValue,
              );
              if (foundClient) {
                therapistActivitySend({
                  type: 'SET_FILTER_CLIENT_ID',
                  clientId: foundClient._id,
                });
                setSelectedClientId(foundClient._id);
              }
            }}
            showSearch
          />
          {selectedClientId ? (
            <Button
              type={isSessionActive ? 'primary' : 'default'}
              disabled={isSessionActive ? !sessionStartTime : false}
              onClick={async () => {
                if (isSessionActive) {
                  await saveData();
                  setSessionStartTime(undefined);
                  setSessionEndTime(undefined);
                }
                setIsSessionActive(!isSessionActive);
              }}
              className="header-session-button"
            >
              {isSessionActive
                ? translate('components.header.finishSession')
                : translate('components.header.startSession')}
            </Button>
          ) : null}
        </div>
        <Dropdown overlay={menu}>
          <Button type="link">
            <Space>
              {currentLocale?.startsWith('en') ? 'English' : 'Русский'}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        {/* <Space style={{ marginLeft: '8px' }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space> */}
        <Modal
          {...modalProps}
          okText={translate('modals.mobileAppAccount.okText')}
          cancelText={translate('modals.mobileAppAccount.cancelText')}
          onOk={() => {
            updateUser();
            close();
          }}
          onCancel={() => {
            logout();
          }}
          maskClosable={false}
          closable={false}
          title={translate('modals.mobileAppAccount.title')}
        >
          <Paragraph>{translate('modals.mobileAppAccount.text1')}</Paragraph>
          <Paragraph>{translate('modals.mobileAppAccount.text2')}</Paragraph>
          <Paragraph>{translate('modals.mobileAppAccount.text3')}</Paragraph>
          <Paragraph>{translate('modals.mobileAppAccount.text4')}</Paragraph>
        </Modal>
      </div>
      {isSessionActive ? (
        <div>
          <Divider className="header-divider" />
          <div className="header-session-row">
            <Form.Item
              label={translate('components.header.startTime')}
              className="header-session-row--item"
            >
              <DateFnsDatePicker
                locale={currentLocale?.startsWith('ru') ? ruPicker : enUSPicker}
                showTime={{
                  format: currentLocale?.startsWith('ru') ? 'HH:mm' : 'hh:mm a',
                }}
                format={
                  currentLocale?.startsWith('ru')
                    ? 'yyyy-MM-dd HH:mm'
                    : 'yyyy-MM-dd hh:mm a'
                }
                minuteStep={5}
                value={sessionStartTime}
                onChange={(value) => {
                  if (value) {
                    setSessionStartTime(
                      roundToNearestMinutes(value, {
                        nearestTo: 1,
                        roundingMethod: 'floor',
                      }),
                    );
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={translate('components.header.endTime')}
              className="header-session-row--item"
            >
              <DateFnsDatePicker
                locale={currentLocale?.startsWith('ru') ? ruPicker : enUSPicker}
                showTime={{
                  format: currentLocale?.startsWith('ru') ? 'HH:mm' : 'hh:mm a',
                }}
                format={
                  currentLocale?.startsWith('ru')
                    ? 'yyyy-MM-dd HH:mm'
                    : 'yyyy-MM-dd hh:mm a'
                }
                minuteStep={5}
                value={sessionEndTime}
                disabledDate={(current) => {
                  return !!(
                    current &&
                    sessionStartTime &&
                    current < sessionStartTime
                  );
                }}
                // @ts-ignore
                disabledTime={(current) => {
                  if (current && sessionStartTime) {
                    if (isSameDay(current, sessionStartTime)) {
                      return {
                        disabledHours: () => {
                          return Array.from(
                            { length: sessionStartTime.getHours() },
                            (_, i) => i,
                          );
                        },
                        // Note: it looks like there occasionally is some bug I account fix now
                        // disabledMinutes: () => {
                        //   return Array.from(
                        //     { length: sessionStartTime.getMinutes() },
                        //     (_, i) => i,
                        //   );
                        // },
                      };
                    }
                  }
                }}
                onChange={(value) => {
                  if (value) {
                    setSessionEndTime(
                      roundToNearestMinutes(value, {
                        nearestTo: 1,
                        roundingMethod: 'ceil',
                      }),
                    );
                  }
                }}
              />
            </Form.Item>
            {duration && (
              <Form.Item
                label={translate('components.header.duration')}
                className="header-session-row--item"
              >
                {duration}
              </Form.Item>
            )}
          </div>
        </div>
      ) : null}
    </AntdLayout.Header>
  );
};

export const Header: React.FC = () => {
  const therapistActivityService = useContext(TherapistActivityReactContext);

  return therapistActivityService ? <HeaderInernal /> : null;
};
