import { format } from 'date-fns';

export const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const numberToOnesAndZerosArrayOfArrays = (
  width: number,
  length: number,
  n: number,
) => {
  const numberAsBinary = n.toString(2);
  const stringExpectedLength = width * length;
  const paddedNumber =
    numberAsBinary.length === stringExpectedLength
      ? numberAsBinary
      : stringExpectedLength < numberAsBinary.length
      ? numberAsBinary.slice(0, stringExpectedLength)
      : numberAsBinary.padStart(stringExpectedLength, '0');

  const arrayOfArrays = paddedNumber
    .split('')
    .map((s) => Number(s))
    .reduce(
      (acc, v) => {
        const isRowFull = acc[0].length === width;

        if (!isRowFull) {
          acc[0].unshift(v);
        } else {
          acc.unshift([v]);
        }
        return acc;
      },
      [[]] as number[][],
    );

  return arrayOfArrays;
};

export const onesAndZerosArrayOfArraysToNumber = (arr: number[][]) => {
  return Number.parseInt(
    [...arr]
      .reverse()
      .map((r) => r.reverse().join(''))
      .join(''),
    2,
  );
};

export const generateToken = () => {
  const preffix = format(new Date(), 'yyddHHmm');
  const suffix = Math.random().toString(36).substring(2, 10);
  const token = Array.from(preffix, (c, i) => c + (suffix?.[i] || '')).join('');
  return token;
};

export const deepOmit = (obj = {} as any, keysToOmit: string[]) => {
  // @ts-ignore
  const omit = (obj: any) =>
    Object.keys(obj)
      .filter((k) => !keysToOmit.includes(k))
      .reduce((acc, k) => {
        return {
          ...acc,
          [k]:
            typeof obj[k] === 'object' &&
            !Array.isArray(obj[k]) &&
            obj[k] !== null
              ? omit(obj[k])
              : Array.isArray(obj[k])
              ? obj[k].map((item: any) =>
                  typeof item === 'object' && obj[k] !== null
                    ? omit(item)
                    : item,
                )
              : obj[k],
        };
      }, {});

  return omit(obj);
};
