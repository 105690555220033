import { CopyOutlined } from '@ant-design/icons';
import { Message } from '@cc/schema';
import {
  Button,
  List,
  Modal,
  Space,
  Table,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import {
  useCustomMutation,
  useGetLocale,
  useTranslate,
} from '@pankod/refine-core';
import { format } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MutationResolvers } from '../../schema/be-graphql-generated';

const { Text, Title } = Typography;

export const MessageList = () => {
  const translate = useTranslate();

  const locale = useGetLocale();

  const currentLocale = locale();

  const [modalText, setModalText] = useState('');

  const { mutate } =
    // @ts-ignore
    useCustomMutation<MutationResolvers['updateMessageRead']>();

  const { tableProps, setFilters, filters, tableQueryResult } =
    useTable<Message>({
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'createdAt',
          order: 'asc',
        },
      ],
      metaData: {
        operation: 'therapistMessages',
        fields: [
          {
            data: [
              '_id',
              'createdAt',
              'readAt',
              'from',
              'to',
              'message',
              {
                fromUser: ['email', 'firstName', 'lastName'],
              },
            ],
          },
          'count',
        ],
      },
    });

  const updateMessage = async (id: string) => {
    await mutate({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'updateMessageRead',
        fields: ['success'],
        variables: {
          input: {
            name: 'input',
            type: 'MessageReadInput!',
            value: {
              where: { id_eq: id },
            },
          },
        },
      },
    });

    await tableQueryResult.refetch();
  };

  return (
    <List title={translate('messages.messages')}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Title level={5}>{translate('messages.messages')}:</Title>
        </div>
      </div>
      <Modal
        destroyOnClose
        cancelButtonProps={{ style: { display: 'none' } }}
        open={!!modalText}
        onCancel={() => setModalText('')}
        onOk={() => setModalText('')}
      >
        <div>{modalText}</div>
      </Modal>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          key="createdAt"
          dataIndex="createdAt"
          title={translate('messages.fields.createdAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss aaa',
            );
          }}
        />
        <Table.Column
          key="readAt"
          dataIndex="readAt"
          title={translate('messages.fields.readAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss aaa',
            );
          }}
        />
        <Table.Column<Message>
          key="email"
          title={translate('messages.fields.from')}
          render={(text, record, indx) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text>
                  {record.fromUser?.firstName} {record.fromUser?.lastName}
                </Text>
                <Link
                  to="#"
                  onClick={(e) => {
                    window.open(
                      `mailto:${record.fromUser?.email || ''}`,
                      '_blank',
                    );
                    e.preventDefault();
                  }}
                >
                  {record.fromUser?.email}
                </Link>
              </div>
            );
          }}
        />
        {/* <Table.Column
          key="to"
          dataIndex="to"
          title={translate('messages.fields.to')}
        /> */}
        <Table.Column
          title={translate('messages.fields.subject')}
          render={(_, record) => (
            <Text>{translate('messages.defaultSubject')}</Text>
          )}
        />
        <Table.Column<Message>
          key="message"
          dataIndex="message"
          title={translate('messages.fields.message')}
          render={(text, record, indx) => {
            return (
              <Link
                to=""
                onClick={() => {
                  setModalText(record.message);
                  if (!record.readAt) updateMessage(record._id);
                }}
              >
                {translate('messages.readMessage')}
              </Link>
            );
          }}
        />
        <Table.Column<Message>
          title={translate('resourcesCommon.actions')}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                size="small"
                icon={<CopyOutlined />}
                title={translate('message.fields.copyMessage')}
                onClick={() => {
                  navigator.clipboard.writeText(record.message);
                }}
              >
                {translate('messages.fields.copyMessage')}
              </Button>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
