import {
  Button,
  CreateButton,
  List,
  Space,
  Table,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useGetLocale,
  useList,
  useTranslate,
} from '@pankod/refine-core';

import { ArrowDownOutlined, EditOutlined } from '@ant-design/icons';
import { dbCols, User, UserRole } from '@cc/schema';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSubscriptionWarnings } from '../../hooks';
import { IUser } from '../../providers';
import { DownwardArrow } from '../../schema/be-graphql-generated';
import { colors } from '../../styles/colors';
import './styles.less';
import { useContext, useEffect } from 'react';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';

const { Title } = Typography;

export const DownwardArrowsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const locale = useGetLocale();

  const currentLocale = locale();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  // @ts-ignore
  const { tableProps, sorter, searchFormProps, filters, setFilters } =
    useTable<DownwardArrow>({
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
      initialFilter: therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      metaData: {
        operation: 'downwardArrows',
        fields: [
          {
            data: ['_id', 'userId', 'createdAt', 'updatedAt', 'beliefs'],
          },
          'count',
        ],
      },
    });

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  useEffect(() => {
    setFilters(
      therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      'replace',
    );
  }, [therapistActivityState?.context?.filterClientId]);

  useActivityTracking(dbCols.downwardArrows);

  const expandedRowRender = (record: DownwardArrow) => {
    const beliefs = (record?.beliefs || []).filter((b) => !!b);
    return (
      <>
        {beliefs.map((b, index) => {
          return (
            <div className="downward-arrows--thoughts-container">
              <div key={index} id={`field$-${index}`}>
                {b}
              </div>

              {beliefs?.length > 1 && index < beliefs?.length - 1 && (
                <div className="downward-arrows--question-container">
                  <ArrowDownOutlined
                    className="downward-arrows--arrow-icon"
                    color={colors['--triad-blue-0']}
                  />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <List
      title={translate('shared.downwardArrows.downwardArrows')}
      headerButtons={
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <CreateButton />
        </div>
      }
    >
      <Table
        {...tableProps}
        rowKey="_id"
        onChange={(pagination, filters, sorter, extra) => {
          // Note: The data we get from Ant.design filters does not match the
          // react refine one. Currently it works fine if I simply silence it the way below.
          // TOOD: Find a way to convert the data to match the react refine one and fix it better
          // @ts-ignore
          tableProps?.onChange?.(pagination, null, sorter, extra);
        }}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender,
        }}
      >
        <Table.Column
          key="_id"
          dataIndex="userId"
          title={translate('resourcesCommon.userId')}
          sorter
          filterSearch
          // @ts-ignore
          onFilter={(value: string, record: DownwardArrow) => {
            return record.userId === value;
          }}
          filters={
            // @ts-ignore
            clients?.data?.data?.map((c, idx) => {
              return {
                text: `${c._id} - ${c.firstName} ${c.lastName}`,
                value: c._id,
              };
            })
          }
          render={(value, record) => {
            // @ts-ignore
            const client = clients?.data?.data?.find?.(
              // @ts-ignore
              (c) => c._id === value,
            );

            return (
              <Tooltip
                title={client ? `${client.firstName} ${client.lastName}` : ''}
              >
                {`${value.replace('users/', '')} - ${(
                  client?.firstName || ''
                ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
              </Tooltip>
            );
          }}
        />
        <Table.Column<DownwardArrow>
          key="beleifs"
          dataIndex="beleifs"
          title={translate('shared.downwardArrows.initialThought')}
          render={(text, record, indx) => {
            return record?.beliefs?.[0] || '';
          }}
        />
        <Table.Column
          key="updatedAt"
          dataIndex="updatedAt"
          title={translate('resourcesCommon.updatedAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss aaa',
            );
          }}
        />
        <Table.Column<DownwardArrow>
          title={translate('resourcesCommon.actions')}
          dataIndex="actions"
          render={(_, record) => {
            // @ts-ignore
            const client: Client = // @ts-ignore
            ((clients?.data?.data as Client[]) || []).find(
              (c) => c._id === record.userId,
            );

            const hasPremiumAccess = client?.accessLevel;

            const canEdit =
              client?.access?.allowEdit ||
              (client?.access?.allowAdminEdit &&
                identity?.db?.role === UserRole.THERAPIST_ADMIN);

            return (
              <Space>
                {canEdit && (
                  <Tooltip
                    title={translate(
                      hasPremiumAccess
                        ? 'resourcesCommon.edit'
                        : 'clients.thoughtsTestDisabledTip',
                    )}
                  >
                    <Link
                      to={`/tools/downwardArrows/create?downwardArrowId=${record._id}`}
                    >
                      <Button
                        icon={<EditOutlined />}
                        disabled={!hasPremiumAccess || !canEdit}
                      />
                    </Link>
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
