export const baseEmotionsRu = [
  {
    name: 'Печаль',
    id: 1,
    // these are the children or 'sub items'
    children: [
      {
        name: 'горе',
        id: 100,
      },
      {
        name: 'страдание',
        id: 101,
      },
      {
        name: 'разочарование',
        id: 102,
      },
      {
        name: 'безнадежность',
        id: 103,
      },
      {
        name: 'одиночество',
        id: 104,
      },
      {
        name: 'боль',
        id: 105,
      },
      {
        name: 'сожаление',
        id: 106,
      },
      {
        name: 'уныние',
        id: 107,
      },
      {
        name: 'меланхолия',
        id: 108,
      },
      {
        name: 'подавленность',
        id: 109,
      },
      {
        name: 'грусть',
        id: 110,
      },
      {
        name: 'печаль',
        id: 111,
      },
    ],
  },
  {
    name: 'Страх',
    id: 2,
    // these are the children or 'sub items'
    children: [
      {
        name: 'тревога',
        id: 200,
      },
      {
        name: 'испуг',
        id: 201,
      },
      {
        name: 'паника',
        id: 202,
      },
      {
        name: 'беспокойство',
        id: 203,
      },
      {
        name: 'напряженность',
        id: 204,
      },
      {
        name: 'ужас',
        id: 205,
      },
      {
        name: 'шок',
        id: 206,
      },
      {
        name: 'встревоженность',
        id: 207,
      },
      {
        name: 'взволнованность',
        id: 209,
      },
      {
        name: 'трепет',
        id: 210,
      },
      {
        name: 'страх',
        id: 211,
      },
      {
        name: 'фобия',
        id: 213,
      },
      {
        name: 'опасение',
        id: 214,
      },
    ],
  },
  {
    name: 'Злость',
    id: 3,
    // these are the children or 'sub items'
    children: [
      {
        name: 'гнев',
        id: 300,
      },
      {
        name: 'раздражение',
        id: 301,
      },
      {
        name: 'горечь',
        id: 302,
      },
      {
        name: 'ворчливость',
        id: 304,
      },
      {
        name: 'сварливость',
        id: 305,
      },
      {
        name: 'ожесточение',
        id: 306,
      },
      {
        name: 'ярость',
        id: 307,
      },
      {
        name: 'фрустрация',
        id: 308,
      },
      {
        name: 'бешенство',
        id: 309,
      },
      {
        name: 'угрюмость',
        id: 310,
      },
      {
        name: 'враждебность',
        id: 311,
      },
      {
        name: 'возмущение',
        id: 312,
      },
      {
        name: 'неистовства',
        id: 313,
      },
    ],
  },
  {
    name: 'Отвращение',
    id: 4,
    // these are the children or 'sub items'
    children: [
      {
        name: 'ненависть',
        id: 400,
      },
      {
        name: 'антипатия',
        id: 401,
      },
      {
        name: 'презрение',
        id: 402,
      },
      {
        name: 'неприязнь',
        id: 403,
      },
      {
        name: 'осмеяние',
        id: 404,
      },
      {
        name: 'отталкивание',
        id: 405,
      },
      {
        name: 'брезгливость',
        id: 406,
      },
      {
        name: 'отрицание',
        id: 407,
      },
      {
        name: 'омерзение',
        id: 408,
      },
      {
        name: 'тошнота',
        id: 409,
      },
      {
        name: 'насмешка',
        id: 410,
      },
      {
        name: 'возмущение',
        id: 411,
      },
    ],
  },
  {
    name: 'Зависть',
    id: 5,
    // these are the children or 'sub items'
    children: [
      {
        name: 'алчность',
        id: 501,
      },
      {
        name: 'стремление',
        id: 502,
      },
      {
        name: 'пристрастие',
        id: 503,
      },
      {
        name: 'недовольство',
        id: 504,
      },
      {
        name: 'разочарование',
        id: 505,
      },
      {
        name: 'уныние',
        id: 506,
      },
      {
        name: 'корысть',
        id: 507,
      },
      {
        name: 'жадность',
        id: 508,
      },
      {
        name: 'страстное желание',
        id: 509,
      },
      {
        name: 'мелочность',
        id: 510,
      },
    ],
  },
  {
    name: 'Ревность',
    id: 6,
    // these are the children or 'sub items'
    children: [
      {
        name: 'недоверчивость',
        id: 600,
      },
      {
        name: 'собственничество',
        id: 601,
      },
      {
        name: 'осторожность',
        id: 602,
      },
      {
        name: 'страх потерять',
        id: 603,
      },
      {
        name: 'подозрительность',
        id: 604,
      },
      {
        name: 'настороженность',
        id: 605,
      },
      {
        name: 'бдительность',
        id: 606,
      },
    ],
  },
  {
    name: 'Стыд',
    id: 7,
    // these are the children or 'sub items'
    children: [
      {
        name: 'униженность',
        id: 700,
      },
      {
        name: 'раскаяние',
        id: 701,
      },
      {
        name: 'замешательство',
        id: 702,
      },
      {
        name: 'неловкость',
        id: 703,
      },
      {
        name: 'смущение',
        id: 704,
      },
    ],
  },
  {
    name: 'Вина',
    id: 8,
    // these are the children or 'sub items'
    children: [
      {
        name: 'сожаление',
        id: 800,
      },
      {
        name: 'угрызения совести',
        id: 801,
      },
      {
        name: 'раскаяние',
        id: 802,
      },
      {
        name: 'извинение',
        id: 803,
      },
      {
        name: 'вина',
        id: 804,
      },
    ],
  },
];

export const baseEmotionsEn = [
  {
    name: 'Sadness',
    id: 1,
    // these are the children or 'sub items'
    children: [
      {
        name: 'grief',
        id: 100,
      },
      {
        name: 'suffering',
        id: 101,
      },
      {
        name: 'disappointment',
        id: 102,
      },
      {
        name: 'hopelessness',
        id: 103,
      },
      {
        name: 'loneliness',
        id: 104,
      },
      {
        name: 'pain',
        id: 105,
      },
      {
        name: 'regret',
        id: 106,
      },
      {
        name: 'despondency',
        id: 107,
      },
      {
        name: 'melancholy',
        id: 108,
      },
      {
        name: 'dispiritedness',
        id: 109,
      },
      {
        name: 'sorrow',
        id: 110,
      },
      {
        name: 'saddness',
        id: 111,
      },
    ],
  },
  {
    name: 'Fear',
    id: 2,
    // these are the children or 'sub items'
    children: [
      {
        name: 'anxiety',
        id: 200,
      },
      {
        name: 'fright',
        id: 201,
      },
      {
        name: 'panic',
        id: 202,
      },
      {
        name: 'concern',
        id: 203,
      },
      {
        name: 'tension',
        id: 204,
      },
      {
        name: 'horror',
        id: 205,
      },
      {
        name: 'shock',
        id: 206,
      },
      {
        name: 'worry',
        id: 207,
      },
      {
        name: 'agitation',
        id: 209,
      },
      {
        name: 'awe',
        id: 210,
      },
      {
        name: 'fear',
        id: 211,
      },
      {
        name: 'phobia',
        id: 213,
      },
      {
        name: 'apprehension',
        id: 214,
      },
    ],
  },
  {
    name: 'Anger',
    id: 3,
    // these are the children or 'sub items'
    children: [
      {
        name: 'anger',
        id: 300,
      },
      {
        name: 'irritation',
        id: 301,
      },
      {
        name: 'bitterness',
        id: 302,
      },
      {
        name: 'grumpiness',
        id: 304,
      },
      {
        name: 'crossness',
        id: 305,
      },
      {
        name: 'aggravation',
        id: 306,
      },
      {
        name: 'rage',
        id: 307,
      },
      {
        name: 'frustration',
        id: 308,
      },
      {
        name: 'fury',
        id: 309,
      },
      {
        name: 'gloom',
        id: 310,
      },
      {
        name: 'hostility',
        id: 311,
      },
      {
        name: 'indignation',
        id: 312,
      },
      {
        name: 'frenzy',
        id: 313,
      },
    ],
  },
  {
    name: 'Disgust',
    id: 4,
    // these are the children or 'sub items'
    children: [
      {
        name: 'hatred',
        id: 400,
      },
      {
        name: 'antipathy',
        id: 401,
      },
      {
        name: 'contempt',
        id: 402,
      },
      {
        name: 'dislike',
        id: 403,
      },
      {
        name: 'ridicule',
        id: 404,
      },
      {
        name: 'repulsion',
        id: 405,
      },
      {
        name: 'disgust',
        id: 406,
      },
      {
        name: 'negation',
        id: 407,
      },
      {
        name: 'aversion',
        id: 408,
      },
      {
        name: 'nausea',
        id: 409,
      },
      {
        name: 'mockery',
        id: 410,
      },
      {
        name: 'disturbance',
        id: 411,
      },
    ],
  },
  {
    name: 'Envy',
    id: 5,
    // these are the children or 'sub items'
    children: [
      {
        name: 'avarice',
        id: 501,
      },
      {
        name: 'aspiration',
        id: 502,
      },
      {
        name: 'partiality',
        id: 503,
      },
      {
        name: 'discontent',
        id: 504,
      },
      {
        name: 'disappointment',
        id: 505,
      },
      {
        name: 'despondency',
        id: 506,
      },
      {
        name: 'self-interest',
        id: 507,
      },
      {
        name: 'greed',
        id: 508,
      },
      {
        name: 'desire',
        id: 509,
      },
      {
        name: 'pettiness',
        id: 510,
      },
    ],
  },
  {
    name: 'Jealousy',
    id: 6,
    // these are the children or 'sub items'
    children: [
      {
        name: 'distrust',
        id: 600,
      },
      {
        name: 'possessiveness',
        id: 601,
      },
      {
        name: 'caution',
        id: 602,
      },
      {
        name: 'fear of losing',
        id: 603,
      },
      {
        name: 'suspicion',
        id: 604,
      },
      {
        name: 'alertness',
        id: 605,
      },
      {
        name: 'vigilance',
        id: 606,
      },
    ],
  },
  {
    name: 'Shame',
    id: 7,
    // these are the children or 'sub items'
    children: [
      {
        name: 'humiliation',
        id: 700,
      },
      {
        name: 'repentance',
        id: 701,
      },
      {
        name: 'confusion',
        id: 702,
      },
      {
        name: 'awkwardness',
        id: 703,
      },
      {
        name: 'embarrassment',
        id: 704,
      },
    ],
  },
  {
    name: 'Guilt',
    id: 8,
    // these are the children or 'sub items'
    children: [
      {
        name: 'regret',
        id: 800,
      },
      {
        name: 'remorse',
        id: 801,
      },
      {
        name: 'repentance',
        id: 802,
      },
      {
        name: 'apology',
        id: 803,
      },
      {
        name: 'guilt',
        id: 804,
      },
    ],
  },
];

export const baseEmotionsRuHash = baseEmotionsRu
  .map((emotionsGroup) => emotionsGroup.children)
  .flat(1)
  .reduce((acc, emotion) => {
    acc[emotion.id] = emotion.name;
    return acc;
  }, {} as Record<string, string>);

export const baseEmotionsEnHash = baseEmotionsEn
  .map((emotionsGroup) => emotionsGroup.children)
  .flat(1)
  .reduce((acc, emotion) => {
    acc[emotion.id] = emotion.name;
    return acc;
  }, {} as Record<string, string>);
