import { translationEn } from './translationEn';

export const portalEn = {
  ...translationEn,
  pages: {
    main: {
      clients: 'Clients',
    },
    login: {
      chooseLanguage: 'Choose Your Language',
      googleSignIn: 'Sign in with Google',
      signin: 'Sign in',
      signup: 'Sign up',
      email: 'Email',
      password: 'Password',
      remember: 'Remember me',
      forgotPassword: 'Forgot password?',
      title: 'Sign in your account',
      noAccount: 'Don’t have an account?',
      providerPortal: 'Provider Portal',
      or: 'or',
      resetPassword: 'Reset Password',
      sentText:
        'We have sent you an email with a link to reset your password if you had a registered account with us.',
      copywrite: '© 2023 Coping Card, Inc. All rights reserved.',
    },
    copingCards: {
      title: 'Coping Cards',
      frontSide: 'Front Side',
      frontSideTip:
        'Could be anything, e.g. automatic negative thought, situation, old habit, etc.',
      backSide: 'Back Side',
      backSideTip:
        'Could be anything, e.g. realistic thought, coping strategy, new habit, etc.',
      timeBasedReminders: 'Time-based Reminders',
      timeBasedRemindersTip:
        'Only the Client can modify these reminders in the Mobile app',
      create: 'Create a Coping Card for a Client',
      backSideRequired: 'Back Side is required',
      createSuccess: 'Coping Card created successfully',
      day: 'Day',
      morning: 'Morning',
      evening: 'Evening',
    },
    thoughtList: {
      title: 'Dysfunctional Thoughts Test',
      activatingEvent: 'Activating Event',
      updatedAt: 'Updated At',
      automaticThought: 'Automatic Thought',
      userId: 'Client ID',
      thoughtsForAlternativeThoughts: 'Alternative explanation',
      thoughtsFor: 'Facts and Evidence supporting the Negative Thought',
      thoughtsAgainst: 'Facts and Evidence against the Negative Thought',
      realisticThoughts: 'Realistic Thoughts',
      thoughtEditLimitTip:
        'You can edit Thoughts only for Clients with a Premium account',
      thoughtEditNoPermissionTip:
        'You do not have permission to edit this Thought',
    },
    sessions: {
      title: 'Sessions',
      tabs: [
        'General Information',
        // 'Home Assignment (in development...)',
        'Progress Notes',
        // 'Therapy Notes (in development...)',
      ],
      duration: 'Duration',
      noFinishDate: 'No Finish Date',
      sessionRange: 'Sessions Start and End Date & Time',
      upgradeNeeded: 'Upgrade Needed',
      upgradeNeededDescription: `You need to upgrade your account to be able to use this feature. Please go to Subscription section to upgrade your account.`,
      gotToSubscription: 'Go to Subscription',

      saveSuccess: 'Session saved successfully',
      preferredPronounsLabel: 'Preferred Pronouns',
      diagnosesLabel: "Client's Diagnosis (up to 3)",
      selectOrSearch: 'Please Select (you can search)',
      diagnoses: [
        'Primary Diagnosis',
        'Secondary Diagnosis',
        'Tertiary Diagnosis',
      ],
      diagnosesLimit: 'You can select up to 3 diagnoses',
      icd10Hint: 'ICD-10 Codes, 2023 version',
      progressNotesFormatLabel: 'Progress Notes Format',
      progressNotesFormats: [
        {
          name: 'SOAP',
          nonAbbreviated: 'Subjective, Objective, Assessment, Plan',
        },
        {
          name: 'DAP',
          nonAbbreviated: 'Data, Assessment, Plan',
        },
        {
          name: 'GIRP',
          nonAbbreviated: 'Goal, Intervention, Response, Plan',
        },
        {
          name: 'BIRP',
          nonAbbreviated: 'Behavior, Intervention, Response, Plan',
        },
        {
          name: 'SIRP',
          nonAbbreviated: 'Situation, Intervention, Response, Progress',
        },
      ],
      placeOfServiceLabel: 'Place of Service',
      placeOfServiceLabelTip: 'CMS Place of Service Codes',
      placeOfServiceCodes: [
        {
          code: '02',
          name: 'Telehealth Provided Other than in Patient’s Home',
          description:
            'The location where health services and health related services are provided or received, through telecommunication technology. Patient is not located in their home when receiving health services or health related services through telecommunication technology.',
        },
        {
          code: '03',
          name: 'School',
          description: 'A facility whose primary purpose is education.',
        },
        {
          code: '10',
          name: 'Telehealth Provided in Patient’s Home',
          description:
            'The location where health services and health related services are provided or received, through telecommunication technology. Patient is located in their home (which is a location other than a hospital or other facility where the patient receives care in a private residence) when receiving health services or health related services through telecommunication technology.',
        },
        {
          code: '11',
          name: 'Office',
          description:
            'Location, other than a hospital, skilled nursing facility (SNF), military treatment facility, community health center, State or local public health clinic, or intermediate care facility (ICF), where the health professional routinely provides health examinations, diagnosis, and treatment of illness or injury on an ambulatory basis.',
        },
        {
          code: '12',
          name: 'Home',
          description:
            'Location, other than a hospital or other facility, where the patient receives care in a private residence.',
        },
        {
          code: '14',
          name: 'Group Home',
          description:
            'A residence, with shared living areas, where clients receive supervision and other services such as social and/or behavioral services, custodial service, and minimal services (e.g., medication administration).',
        },
        {
          code: '18',
          name: 'Place of Employment – Worksite',
          description:
            'A location, not described by any other POS code, owned or operated by a public or private entity where the patient is employed, and where a health professional provides on-going or episodic occupational medical, therapeutic or rehabilitative services to the individual.',
        },
        {
          code: '51',
          name: 'Inpatient Psychiatric Facility',
          description:
            'A facility that provides inpatient psychiatric services for the diagnosis and treatment of mental illness on a 24-hour basis, by or under the supervision of a physician.',
        },
        {
          code: '52',
          name: 'Psychiatric Facility - Partial Hospitalization',
          description:
            'A facility for the diagnosis and treatment of mental illness that provides a planned therapeutic program for patients who do not require full time hospitalization, but who need broader programs than are possible from outpatient visits to a hospital-based or hospital-affiliated facility.',
        },
        {
          code: '53',
          name: 'Community Mental Health Center',
          description: `A facility that provides the following services: outpatient services, including specialized outpatient services for children, the elderly, individuals who are chronically ill, and residents of the CMHC's mental health services area who have been discharged from inpatient treatment at a mental health facility; 24 hour a day emergency care services; day treatment, other partial hospitalization services, or psychosocial rehabilitation services; screening for patients being considered for admission to State mental health facilities to determine the appropriateness of such admission; and consultation and education services.`,
        },
        {
          code: '54',
          name: 'Intermediate Care Facility/ Individuals with Intellectual Disabilities',
          description:
            'A facility which primarily provides health-related care and services above the level of custodial care to individuals but does not provide the level of care or treatment available in a hospital or SNF.',
        },
        {
          code: '55',
          name: 'Residential Substance Abuse Treatment Facility',
          description:
            'A facility which provides treatment for substance (alcohol and drug) abuse to live-in residents who do not require acute medical care. Services include individual and group therapy and counseling, family counseling, laboratory tests, drugs and supplies, psychological testing, and room and board.',
        },
        {
          code: '56',
          name: 'Psychiatric Residential Treatment Center',
          description:
            'A facility or distinct part of a facility for psychiatric care which provides a total 24-hour therapeutically planned and professionally staffed group living and learning environment.',
        },
        {
          code: '57',
          name: 'Non-residential Substance Abuse Treatment Facility',
          description:
            'A location which provides treatment for substance (alcohol and drug) abuse on an ambulatory basis.  Services include individual and group therapy and counseling, family counseling, laboratory tests, drugs and supplies, and psychological testing.',
        },
        {
          code: '58',
          name: 'Non-residential Opioid Treatment Facility',
          description:
            'A location that provides treatment for opioid use disorder on an ambulatory basis. Services include methadone and other forms of Medication Assisted Treatment (MAT)',
        },
        {
          code: '99',
          name: 'Other Place of Service',
          description: 'Other place of service not identified above.',
        },
      ],
      sessionSettingsLabel: 'Session Setting',
      shortSummaryLabel: 'Quick Notes/Short Summary',
      shortSummaryPlaceholder:
        'You can add quick notes or additional information about the session. Examples of things you can mention are treatment plan goals, objectives, strategies, techniques and methods, client issues, medication, etc.',
      clientProgressLabel: 'Client Progress',
      clientProgressOptions: [
        `Improvement`,
        `No Substantial Change`,
        `Deterioration`,
      ],
      clientMentalStatus: 'Client Mental Status',
      clientMentalStatusTip: 'Select options that apply and are important',
      appearanceLabel: 'Appearance',
      appearance: [
        'Well-groomed',
        'Neatly dressed',
        'Casually dressed',
        'Poorly groomed',
        'Inappropriately dressed',
        'Disheveled',
      ],
      attitudeLabel: 'Attitude',
      attitude: [
        'Cooperative',
        'Friendly',
        'Engaged',
        'Resistant',
        'Guarded',
        'Hostile',
        'Apathetic',
      ],
      moodLabel: 'Mood',
      mood: [
        'Sad',
        'Anxious',
        'Angry',
        'Irritable',
        'Euthymic (stable)',
        'Elevated',
        'Dysphoric',
      ],
      affectLabel: 'Affect',
      affect: [
        'Appropriate',
        'Flat',
        'Blunted',
        'Restricted',
        'Labile',
        'Congruent',
        'Incongruent',
      ],
      speechLabel: 'Speech',
      speech: [
        'Normal rate',
        'Slow',
        'Rapid',
        'Pressured',
        'Soft',
        'Loud',
        'Slurred',
        'Thought Content',
      ],
      thoughtProcessLabel: 'Thought Process',
      thoughtProcess: [
        'Logical',
        'Coherent',
        'Goal-directed',
        'Disorganized',
        'Indirect',
        'Detailed',
        'Loose associations',
      ],
      perceptionLabel: 'Perception',
      perception: [
        'No hallucinations',
        'Auditory hallucinations',
        'Visual hallucinations',
        'Tactile hallucinations',
        'Olfactory hallucinations',
        'Illusions',
        'Depersonalization/derealization',
      ],
      orientationLabel: 'Orientation',
      orientation: [
        'Oriented to time, place, person & situation',
        'Disoriented to time',
        'Disoriented to place',
        'Disoriented to person',
        'Disoriented to situation',
      ],
      memoryLabel: 'Memory',
      memory: [
        'Intact short-term memory',
        'Intact long-term memory',
        'Impaired short-term memory',
        'Impaired long-term memory',
        'Confabulation',
      ],
      concentrationLabel: 'Concentration',
      concentration: [
        'Good concentration',
        'Mild difficulty concentrating',
        'Moderate difficulty concentrating',
        'Severe difficulty concentrating',
      ],
      insightLabel: 'Insight',
      insight: [
        'Good insight',
        'Partial insight',
        'Poor insight',
        'Lacks insight',
        'Denial of illness',
      ],
      detectedInterventionsTitle: 'Automatically Detected Interventions',
      detectedInterventionsLabel:
        'Automatically Detected Interventions. (The text is not copied to the final note, it is used as a hint for AI and is written in a manner to help AI).',
      detectedInterventionsHint:
        'We detected some of the interventions you have used during the session and created a short summary here. The summary can be used as a prompt for AI to generate a note with higher quality and level of detail.',
      interventionsLabel: 'Other Used Interventions',
      interventionsHint:
        'Select the interventions you have used. We have pre-selected the interventions you have used during this session in our Platform and we have been able to track.',
      interventionsEducationLabel: 'Education',
      interventionsEducation: [
        'Explaining medication and treatment options',
        'Providing information about mental health conditions',
        'Psychoeducation',
        'Review of treatment plan/progress',
      ],
      interventionsCognitiveLabel: 'Cognitive Techniques',
      interventionsCognitive: [
        'ABC model',
        'Behavioral experiment',
        'Cognitive restructuring',
        'Coping cards',
        'Dysfunctional thoughts test',
        'Downward arrow',
        'Thought records',
        'Vicious cycles',
        'Worry tree',
      ],
      interventionsBehavioralActivationLabel: 'Behavioral Activation',
      interventionsBehavioralActivation: [
        'Activity scheduling',
        'Goal setting',
        'Values clarification',
      ],
      interventionsExposuresLabel: 'Exposures',
      interventionsExposures: [
        'Exposure with response prevention',
        'Eye movement desensitization and reprocessing (EMDR)',
        'Graduated exposure',
        'Systematic desensitization',
      ],
      interventionsSkillsTrainingLabel: 'Skills Training',
      interventionsSkillsTraining: [
        'Acceptance',
        'Anger management',
        'Assertiveness training',
        'Communication skills training',
        'Distress tolerance',
        'Emotional regulation',
        'Problem-solving',
        'Skills generalization',
        'Social skills training',
      ],
      interventionsRelaxationLabel: 'Relaxation & Mindfulness',
      interventionsRelaxation: [
        'Autogenic training',
        'Body scan',
        'Deep breathing exercises',
        'Grounding techniques (5-4-3-2-1)',
        'Guided Visualization',
        'Mammalian diving reflex',
        'Meditation',
        'Progressive muscle relaxation',
      ],
      interventionsOtherLabel: 'Other',
      interventionsOtherLabelPlaceholder: 'Type other interventions',

      readyNote: 'Progress Note',
      readyNoteHint:
        'Use this are to review and edit AI-generated note or write your own note.',
      generateNote: 'Generate Note with AI',
      saveAsPdfButton: 'Save as PDF',
      confirmChange:
        'If you change the format you will lose the already generated or manually entered note. Are you sure you want to change the format?',
      confirmGenerateChange: `If you re-generate the content, you will lose the already generated or manually entered note. Are you sure you want to re-generate the content?`,
      warning: 'Warning',
      progressNoteSaveSuccess: 'Progress Note saved successfully',
      generationTips: [
        `The Note is being generated with the help of AI. It can take some time.`,
        `AI typically does a good job at generating the Note based on the provided data and data we collect.`,
        `But AI is not perfect and is not a replacement for you, it is just a powerful aid.`,
        `Please review the generated Note and make any necessary changes.`,
        `Make sure AI does not add anything that has not happened during the session, since very rarely this can happen and is known by the notion of "AI hallucinations".`,
      ],
      gptNotContentFilteredErrorTitle: 'Content Generation Error',
      gptNoteContentFilteredErrorDescription:
        'AI-generated content is filtered due the AI provider content policy that forbids the use of content related to hate, self-harm, sex or violence. We understand that such content can be relevant for mental health professionals and we are working with our provier to solve this issue. We apologize for the temporary inconvenience.',
    },
    signUp: {
      title: 'Sign up for an account',
      googleSignIn: 'Create a Free Account using Google',
      signup: 'Create a Free Account',
      email: 'Email',
      password: 'Password',
      retypePassword: 'Confirm Password',
      haveAccount: 'Already have an account?',
      or: 'or',
    },
    error: {
      'info':
        'You may have forgotten to add the {{action}} component to {{resource}} resource.',
      '404': 'Sorry, the page you visited does not exist.',
      'resource404': 'Are you sure you have created the {{resource}} resource.',
      'backHome': 'Back Home',
    },
    clientsShow: {
      generalTitle: 'General Info',
      baseStatsTitle: 'App Usage Info',
      usageGraphs: 'App Usage Graphs',
      reminderdsTitle: 'Notifications Usage Graphs',
      testsTitle: 'PHQ-9 and GAD-7 history',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      accountCreated: 'Account Created',
      copingCardsCount: 'Coping Cards Created',
      enabledDayRemindersCount: 'Enabled Time-based Reminders',
      enabledLocationRemindersCount: 'Enabled Geo-location Notifications',
      noData: 'There is no data to show',
      phq9: 'PHQ-9',
      phq9Description: 'PHQ-9 test results history',
      gad7: 'GAD-7',
      gad7Description: 'GAD-7 test results history',
      triggered: 'Notifications triggered',
      reacted: 'Cards viewed',
      crisisTitle: 'Crisis Section Usage',
      crisisDescription: 'Crisis Section Usage History',
      activityLog: 'Activity Log',
      clientActivityLog: 'Client Activity Log',
      therapistActivityLog: 'Therapist Activity Log',
      activityCalendar: 'Activity Calendar',
      daysEventsRecorded: 'days with data',
      eventsRecorded: 'events recorded',
      daysDataCollected: 'Days with data',
      clientActivity: 'Client Activity',
      therapistActivity: 'Therapist Activity',
      therapistTimeSpend: 'Therapist time spent',
      timeSpent: 'Time spent',
      rmtAlert:
        'Depending on the insurance companies you work with you might be able to issue claims for Remote Therapeutics Monitoring (RTM) if out App collects at least 16 days of data/activity in a given 30-day period for a client. Please check with the insurance companies your work with for RTM billing options or contact us at support@copingcard.com for additional information.',
      rmtInfo: 'RTM Info',
      eventTypes: {
        0: 'created',
        1: 'triggered',
        2: ', client reacted',
        3: 'coping-card viewed',
        4: 'used',
        5: 'updated',
        6: 'loaded',
        7: 'viewed',
        8: 'messaged',
        9: 'phoned',
        10: 'external link clicked',
        11: 'clicked',
        12: 'logged in',
      },
      featureFlags: {
        'featureFlags/1': 'cards search',
        'featureFlags/2': 'article',
        'featureFlags/3': 'stats view',
        'featureFlags/4': 'geolocation reminder',
        'featureFlags/5': 'time-based reminder',
        'featureFlags/6': 'PHQ-9 (Depression) Test',
        'featureFlags/7': 'GAD-7 (Anxiety) Test',
        'featureFlags/8': 'meditation',
        'featureFlags/9': 'worry tree',
        'featureFlags/10': 'crisis section',
        'featureFlags/11': 'downward arrow',
        'featureFlags/12': 'behavior experiment',
        'featureFlags/13': 'exposure',
        'featureFlags/14': 'exposure situation',
        'featureFlags/15': 'exposure situation reaction',
        'featureFlags/16': 'AI image',
        'featureFlags/17': 'video',
        'featureFlags/18': 'login',
      },
      entityNames: {
        abcModels_one: 'ABC model',
        abcModels_few: 'ABC models',
        behaviorExperiments_one: 'behavior experiment',
        behaviorExperiments_few: 'behavior experiments',
        conceptualizations_one: 'conceptualization',
        conceptualizations_few: 'conceptualizations',
        copingCards: 'coping cards',
        copingCards_one: 'coping card',
        copingCards_few: 'coping cards',
        dayReminders_one: 'time-based reminder',
        dayReminders_few: 'time-based reminders',
        locationsRelations_one: 'geolocation reminder',
        locationsRelations_few: 'geolocation reminders',
        downwardArrows_one: 'downward arrow',
        downwardArrows_few: 'downward arrows',
        exposures_one: 'exposure',
        exposures_few: 'exposures',
        exposureStimuli_one: 'exposure situation or stimulus',
        exposureStimuli_few: 'exposure situations or stimuli',
        supportingCycles_one: 'vicious cycle',
        supportingCycles_few: 'vicious cycles',
        tests_one: 'test',
        tests_few: 'tests',
        thoughts: 'dysfunctional thought tests',
        thoughts_one: 'dysfunctional thought test',
        thoughts_few: 'dysfunctional thought tests',
        worryTrees_one: 'worry tree',
        worryTrees_few: 'worry trees',
      },
      otherEvents: {
        privacyPolicyUpdated: 'privacy policy settings updated',
        taken: 'taken',
      },
      therapistActivityTypes: {
        '0_one': 'viewed',
        '0_other': 'viewed',
        '0_few': 'viewed',
        '1_one': 'created',
        '1_other': 'created',
        '1_few': 'created',
        '2_one': 'edited',
        '2_other': 'edited',
        '2_few': 'edited',
        '3_one': 'deleted',
        '3_other': 'deleted',
        '3_few': 'deleted',
      },
      therapistOtherResources: {
        clientInfo: {
          activityCalendar: 'activity calendar',
          activityLog: 'activity log',
          stats: 'stats',
        },
        portalCards: 'portalCards',
      },
      screen: 'screen',
      duration: 'duration',
      addActivityManually: 'Add Activity Manually',
      minutes: 'minutes',
      therapistCustomActivities: [
        'Phone Call',
        'Video/Telehealth Call',
        'Text Message/Chat',
        'Email',
      ],
      selectOrTypeActivity: 'Select or type activity',
      activityRange: 'Activity Start and End Date and Time',
      customActivity: 'Manually Entered Activity',
      showOffsessionData: 'Include Off-session Activities',
      showInSessionData: 'Include Activities during Sessions',
    },

    verifyEmail: {
      verifyEmail: 'Verify Email',
      sentText:
        'We have sent you an email with a link to verify your email to: ',
      contactSupport:
        'If you do not receive a confirmation email within 10 minutes or have issues verifying it, please contact Support at:',
    },
    resetEmail: {
      resetSuccess: 'Password Reset Successful',
      resetError: 'Reset Password Error',
    },
    initialSetup: {
      title: 'Initial Setup',
      nextStep: 'Next Step',
      previousStep: 'Previous Step',
      finish: 'Finish',
      selectOrType: 'Select or type',
      step0: {
        title: 'Privacy & Terms',
        description:
          'Please read and accept the following legal documents to be able to use the Provider Portal',
        title2: 'Optional Settings',
      },
      step1: {
        title: 'Personal Information',
        description: 'Please provide information about yourself',
        prefix: 'Prefix / Title',
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone',
        extension: 'Extension',
        country: 'Country',
        clientInviteToken: 'Client Invite Code/Token',
        clientInviteTokenPlaceholder:
          'If a client sent you a Client Invite Code/Token, please enter it here',
        practiceSize: 'Mental Health Professionals in your Practice',
      },
      step2: {
        saasAgreement: 'Provider Agreement',
        readSaasAgreement: 'Read and Accept Provider Agreement',
        title: 'Practice Information',
        description: 'Please provide information about your practice',
        isPracticeAdded:
          'Does your practice already have a CopingCard Portal account?',
        practiceAddedInfo: `
You need to provide an invite code/token for your practice so that we could connect your account to it.

If you do not have an invite token, please contact your practice administrator to get one.
`,
        practiceInviteToken: 'Pracice Invite Code/Token',
        practiceInviteTokenPlaceholder: '',
        npi: 'National Provider Identifier (NPI) Number',
        practiceName: 'Practice Name',
        practicePhone: 'Practice Phone',
        extension: 'Extension',
        practiceLogo: 'Practice Logo',
        practiceUrl: 'Practice Website',
        practiceEmail: 'Practice Email',
        practiceDescription: 'Practice Description',
        locationInfo:
          'If your practice has multiple locations, you will be able to later add them in the Practice -> Locations section.',
        practiceAddress1: 'Address 1',
        practiceAddress2: 'Address 2',
        practiceCity: 'City',
        practiceState: 'State/Region',
        practiceZip: 'Zip/Postal Code',
        practiceCountry: 'Country',
        acceptedInsurances: 'Accepted Insurances',
        acceptedInsurancesText:
          'Please select or type the insurances your practice accepts',
        tokenNotFound: 'The token is not found',
        tokenTryAgain:
          'Please make sure you entered the token correctly or ask for a new token and try again.',
        tokenSuccess:
          'We successfully found the token and added you to the practice.',
        practiceSuccess: 'We successfully added your practice.',
        redirect:
          'You will be automatically redirected to the clients page in 5 seconds.',
      },
    },
    conceptualizations: {
      conceptualizations: 'Conceptualizations',
      userId: 'Client ID',
    },

    conceptualization: {
      conceptualizations: 'Conceptualizations',
      title: 'Case Formulation / Conceptualization',
      clientProfile: 'Client Profile',
      piiTip: `
Information in this section is hidden by default to protect Personal Identifiable Information (PII), e.g. from leaking during a teletherapy session.

Information is automatically hidden again after 1 minute of unhiding it.
    `,

      showInfo: 'Show Client Personal Info',
      hideInfo: 'Hide Client Personal Info',
      saveSuccess: 'Case Formulation / Conceptualization saved successfully',
      saveError: 'Error saving Case Formulation / Conceptualization',
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        gender: 'Gender',
        dob: 'Date of Birth',
        education: 'Education',
        maritalStatus: 'Marital Status',
        clientProfileOther: 'Other Relevant Information',
        negativePastExperience: 'Negative Past Experience',
        triggerEvent: 'Trigger/Critical Event',
        geneticPredisposition: 'Physical and Genetic Predispositions',
        deepBeliefs: 'Core Beliefs',
        triggers: 'Triggers',
        problems: 'Problems',
        lifeRules: 'Underlying Assumptions (Life Rules)',
        goals: 'Goals',
        resources: 'Protective Factors, Resources',
      },
      genderOptions: ['Male', 'Female', 'Other'],
      maritalStatusOptions: [
        'Single',
        'Married',
        'Divorced',
        'Widowed',
        'Separated',
      ],
      educationOptions: [
        'High School',
        'Some College',
        'College Graduate',
        'Post Graduate',
      ],
      deepBeliefsPlaceholder:
        'Type Deep Beliefs or select Schemas from the select box',
      schemaSearch: 'Select Schemas',
      schemas: {
        'Disconnection / Rejection': {
          name: 'Disconnection / Rejection',
          tip: 'Disconnection / Rejection',
          children: [
            {
              name: 'Abandonment / Instability (AB)',
              tip: 'The feeling of insecurity in having dependable sources of support and connection. This encompasses the belief that those close to you may not be able to offer consistent emotional support, connection, or practical protection due to their own emotional instability and unpredictability, unreliability, or inconsistent presence. This can also stem from the fear of their imminent death or abandonment in favor of someone superior.',
              copings: [
                {
                  name: 'Surrender',
                  tip: 'Tends to choose partners and significant others who are unavailable or unpredictable.',
                },
                {
                  name: 'Avoidance',
                  tip: `
1. Avoids intimate relationships due to the fear of being deserted.
2. Terminates relationships proactively to prevent abandonment.
3. Retreats in response to even the slightest rejection or disconnection.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. Constantly monitors those close to them.
2. Maintains constant communication with loved ones.
3. Experiences anger and jealousy in response to even minor rejection or separation.                  `,
                },
              ],
            },
            {
              name: 'Mistrust / Abuse (MA)',
              tip: 'Anticipates that others will cause harm through abuse, degradation, deception, manipulation, or exploitation. Believes that the harm inflicted is deliberate or the result of unjustified and excessive carelessness. Can lead to feelings of being consistently taken advantage of.',
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Selects partners and significant others who lack trustworthiness.
2. Permanently displays excessive levels of caution and distrust towards others.
                        `,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. Avoids close interaction with others in both personal and professional life.
2. Never reveals oneself.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `Has a tendency to mistreat or take advantage of others.`,
                },
              ],
            },
            {
              name: 'Emotional Deprivation (ED)',
              tip: `
Anticipation that others will fail to fulfill one's reasonable need for emotional support. The three primary forms of emotional deprivation include:
A. Deprivation of Nurturance: A shortage of care, warmth, attention, or companionship.
B. Deprivation of Empathy: The absence of others understanding, actively listening, disclosing their own feelings, or sharing emotions.
C. Deprivation of Protection: The unavailability of direction, strength, or guidance from others.
                   `,
              copings: [
                {
                  name: 'Surrender',
                  tip: `Chooses cold, distant partners and significant others.`,
                },
                {
                  name: 'Avoidance',
                  tip: `Avoids close emotional relationships.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. Demands that others fulfill all their needs.
2. Falls in love quickly, but loses interest just as fast, feeling that they are never given enough."
                        `,
                },
              ],
            },
            {
              name: 'Defectiveness / Shame (DS)',
              tip: `A feeling of being flawed, inferior, or inadequate in important ways, or that one's imperfections would lead to being unloved by those who matter to you. This may include an excessive sensitivity to criticism, rejection, and blame, self-consciousness when around others, and a tendency to compare oneself to others, which can lead to insecurity. This can stem from perceived flaws, whether they be personal, such as selfishness or unwanted desires, or physical, such as unattractive appearance or social awkwardness.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Tends to attract relationships with individuals who are constantly critical.
2. Has a tendency to engage in self-deprecation.
                        `,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. Has difficulty expressing their thoughts and emotions openly.
2. Tends to be highly self-controlled and avoids acting impulsively.
3. Has difficulty forming intimate connections with others.
4. Has a tendency to change relationships, friendships, and social groups frequently.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. Has a tendency to judge or look down on others.
2. Has a strong desire to present a flawless image, and is overly concerned with their physical appearance.
3. Tends to strive for perfection in their actions, emotions, and thoughts.
                        `,
                },
              ],
            },
            {
              name: 'Social Isolation / Alienation   (SI)',
              tip: `The feeling of being disconnected from others, feeling unique and separate from the rest of society, and not belonging to any particular group or community.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `Joins a group but maintains a sense of detachment and solitude.`,
                },
                {
                  name: 'Avoidance',
                  tip: `Has a tendency to withdraw from social situations and prefers to spend a significant amount of time in solitude.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Attempts to fit in with others, but still experiences feelings of difference and disconnection.`,
                },
              ],
            },
          ],
        },
        'Impaired Autonomy and/or Performance': {
          name: 'Impaired Autonomy and/or Performance',
          tip: 'Impaired Autonomy and/or Performance',
          children: [
            {
              name: 'Dependence / Incompetence (DI)',
              tip: 'A feeling of inadequacy in the ability to effectively manage daily tasks and responsibilities without heavy reliance on others for support. This can take the form of a sense of helplessness.',
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Frequently requests assistance;
2. Seeks validation from others, lacks decisiveness;
3. Selects partners who tend to be overbearing and overly helpful, taking care of everything on his/her behalf.
`,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. Tends to delay making decisions.
2. Refrains from acting autonomously and assuming typical adult responsibilities.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `Demonstrates excessive self-confidence even when it would be normal and reasonable to ask for help from others.`,
                },
              ],
            },
            {
              name: 'Vulnerability to Harm or Illness (VH)',
              tip: 'An intense, unwarranted, and persistent belief that disaster is imminent and one is powerless to stop it. This can include anxiety about medical emergencies such as heart attacks or illnesses like AIDS, emotional breakdowns, and external events like elevator collapses, crime, plane crashes, or earthquakes.',
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Continuously experiences anxiety over potential disasters will happen to him/her.
2. Regularly asks others for reassurance and comfort.
                        `,
                },
                {
                  name: 'Avoidance',
                  tip: `Avoids situations that are perceived as risky or hazardous.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. Relies on magical thinking and rituals as a coping mechanism.
2. Acts impulsively and takes dangerous risks.`,
                },
              ],
            },
            {
              name: 'Enmeshment / Undeveloped Self (EM)',
              tip: `An intense emotional connection and dependency on one or more key individuals (often parents) that hinders the development of a distinct and separate identity and normal social growth. This often involves the belief that one of the involved individuals cannot function or be content without the constant presence of the other. It may also involve feelings of being overwhelmed or being merged with others, or a lack of individual identity. This can result in feelings of aimlessness and a sense of emptiness, and in severe cases, uncertainty about one's purpose in life.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Mirrors the behavior of an important person in their life.
2. Constantly seeks out the viewpoints of others, and often prefers for them to take charge of the situation.`,
                },
                {
                  name: 'Avoidance',
                  tip: `Avoids relationships with individuals who are unwilling to merge or form a close bond.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Establishes an overly independent lifestyle.`,
                },
              ],
            },
            {
              name: 'Failure to Achieve (FA)',
              tip: `The perception of oneself as having fallen short, being destined to failure, or possessing inherent flaws compared to one's peers in areas such as academic performance, professional pursuits, athletic abilities, and so on. This often includes thoughts of being unintelligent, unskilled, untalented, uninformed, and holding a lower status or level of success than others.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Underperforms despite having the potential to do better.
2. Self-sabotages their work achievements just as they are about to reach their goals.
3. Makes unfair comparisons between their achievements and those of others, often downplaying their own successes.`,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. Tends to postpone tasks, procrastinate, and put things off until the last minute.
2. Avoids taking on new or challenging tasks, preferring to stick to familiar and easy ones.
3. Tends to set career goals that are below their potential, and avoids aiming for higher-level positions or challenging opportunities.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. Demonstrates a need to work excessively.
2. Tends to downplay the accomplishments of others, often in comparison to their own.
`,
                },
              ],
            },
          ],
        },
        'Impaired Limits': {
          name: 'Impaired Limits',
          tip: 'Impaired Limits',
          children: [
            {
              name: 'Entitlement / Grandiosity (ET)',
              tip: `Refers to a belief in one's superiority to others, often leading to a sense of entitlement to special rights and privileges, and a disregard for the rules of social interaction. This mindset may involve insisting on having or doing whatever one wants, even if it is unrealistic, unreasonable, or harmful to others. Alternatively, it may involve an excessive focus on attaining superiority, such as by becoming highly successful, famous, or wealthy, in order to gain power and control, rather than for attention or approval. This type of thinking can sometimes manifest as excessive competitiveness or a desire to dominate others, including exerting power, forcing one's own views, or controlling others' behavior without regard for their needs or feelings.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. This behavior results in creating unequal and distant relationships with both partners and other important individuals.
2. They display selfish behavior, disregarding the needs and emotions of others.
3. They present themselves in an arrogant manner.
4. They are always competing and striving for dominance.
`,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. They tend to avoid situations where they are unable to surpass others or draw attention to themselves.
2. They selectively enter into relationships with individuals they perceive as inferior.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `They either give extravagant and costly gifts or donate more money to charitable causes than their budget permits.`,
                },
              ],
            },
            {
              name: 'Insufficient Self-Control / Self-Discipline (IS)',
              tip: 'This refers to a widespread difficulty or unwillingness to exercise the necessary self-control and frustration tolerance to achieve personal goals, or to restrain excessive emotional expression and impulses. In its less severe manifestation, the patient exhibits a heightened focus on avoiding discomfort: steering clear of pain, conflict, confrontation, responsibility, or overexertion--even if it means sacrificing personal fulfillment, commitment, or integrity.',
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. They perform tedious or unpleasant tasks carelessly.
2. They regularly struggle to manage their emotions and overindulge in food and alcohol.
3. They partake in gambling or drug use for the sole purpose of seeking pleasure.
                        `,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. They are either unemployed or have discontinued their education.
2. They lack long-term career goals.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `They put forth considerable, short-term effort to finish a project or demonstrate self-discipline.`,
                },
              ],
            },
          ],
        },
        'Other-Directedness': {
          name: 'Other-Directedness',
          tip: 'Other-Directedness',
          children: [
            {
              name: 'Subjugation (SB)',
              tip: `
Excessively giving up control to others due to feelings of coercion, often in order to avoid anger, retaliation, or abandonment. There are two main types of subjugation:
A) Subjugation of Needs: where one suppresses their own preferences, decisions, and desires.
B) Subjugation of Emotions: where one suppresses emotional expression, particularly anger.
This often involves feeling that one's desires, opinions, and feelings are not valid or important to others. This can lead to excessive compliance and feeling trapped, which can result in a buildup of anger and maladaptive symptoms such as passive-aggressive behavior, uncontrolled temper outbursts, psychosomatic symptoms, withdrawal of affection, "acting out," and substance abuse.
             `,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. They choose dominating, controlling partners and significant others and submit to their desires.
2. They do not express their desires directly and only imply them.
`,
                },
                {
                  name: 'Avoidance',
                  tip: `
1. They tend to avoid entering into relationships altogether.
2. They steer clear of situations where their wants and needs are at odds with those of others.
3. They do not express sympathy.
                        `,
                },
                {
                  name: 'Overcompensation',
                  tip: `
1. They exhibit passive-aggressive or rebellious behavior.
2. They experience sudden outbursts of uncontrolled rage.
3. They have a tendency to be late and procrastinate.
4. They agree to commitments but fail to follow through and use excuses to justify their behavior.`,
                },
              ],
            },
            {
              name: 'Self-Sacrifice (SS)',
              tip: `Refers to the excessive prioritization of meeting the needs of others over one's own needs, often resulting in a lack of personal gratification. This behavior is commonly driven by a desire to avoid causing pain to others, feelings of guilt or selfishness, or a need to maintain connections with perceived needy individuals. This behavior may stem from a heightened sensitivity to the pain of others, but can result in a sense of personal neglect and resentment towards those who are being cared for.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `They tend to engage in self-neglect by prioritizing others' needs over their own and often give more than they receive in return. `,
                },
                {
                  name: 'Avoidance',
                  tip: `They avoid close relationships.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `They become upset with those who are important to them for failing to reciprocate or show appreciation, leading them to decide to discontinue doing anything for them.`,
                },
              ],
            },
            {
              name: 'Approval-Seeking / Recognition-Seeking (AS)',
              tip: `A strong desire for approval, recognition, or attention from others at the cost of one's own authentic sense of self. Self-esteem is mainly dependent on external validation rather than internal values. This can result in an excessive focus on status, appearance, achievement, or social acceptance as a means of gaining approval or admiration. Often leads to decisions that are not genuine or fulfilling and an oversensitivity to rejection.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Draws others' attention to their accomplishments.
2. Prioritizes recognition over own needs when making important life decisions.
`,
                },
                {
                  name: 'Avoidance',
                  tip: `Refrains from forming relationships with individuals they respect due to a fear of not receiving their approval.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Engages in attention-seeking behavior by behaving scandalously.`,
                },
              ],
            },
          ],
        },
        'Overvigilance / Inhibition': {
          name: 'Overvigilance / Inhibition',
          tip: 'Overvigilance / Inhibition',
          children: [
            {
              name: 'Negativity / Pessimism (NP)',
              tip: `A persistent and pervasive tendency to focus on the negative aspects of life, such as pain, death, loss, disappointment, conflict, guilt, and resentment, while minimizing or neglecting the positive or optimistic aspects. It's common for those with this tendency to have an exaggerated expectation that things will inevitably go wrong in many areas of their lives, including work, finances, and personal relationships, and that even when things seem to be going well, they will ultimately fall apart. This often leads to an excessive fear of making mistakes that could result in financial ruin, embarrassment, humiliation, or being trapped in an undesirable situation. Due to this exaggerated fear of negative outcomes, individuals with this tendency may experience chronic worry, vigilance, complaining, or indecision.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `Tends to anticipate negative outcomes and makes provisions to deal with them.`,
                },
                {
                  name: 'Avoidance',
                  tip: `Does not set high expectations for themselves or others, often settling for less or underestimating what is possible.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Maintains an unrealistically positive outlook, displaying unshakable optimism and a tendency to find the bright side of any situation.`,
                },
              ],
            },
            {
              name: 'Emotional Inhibition (EI)',
              tip: `
Refers to the excessive restraint of natural and spontaneous actions, emotions, or communication, often due to a fear of disapproval or loss of control. This inhibition commonly manifests in various ways, including:
A) suppressing anger or aggressive behavior.
B) holding back positive impulses such as joy or affection.
C) difficulty expressing vulnerability and communicating openly about feelings and needs.
D) overemphasizing rationality while neglecting emotions.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. They approach situations with careful consideration and logical thinking.
2. They refrain from showing spontaneous emotional reactions, whether they are positive or negative.
`,
                },
                {
                  name: 'Avoidance',
                  tip: `They avoid activities that involve expressing their emotions (such as demonstrating love or fear) or behaving in an uninhibited manner (such as dancing).`,
                },
                {
                  name: 'Overcompensation',
                  tip: `They behave in a spontaneous and uninhibited manner, occasionally under the influence of substances like alcohol that have a calming effect.`,
                },
              ],
            },
            {
              name: 'Unrelenting Standards / Hypercriticalness (US)',
              tip: 'Refers to the belief that one must meet very high internal standards of behavior and performance to avoid criticism, leading to feelings of pressure and hypercriticalness toward oneself and others. This can result in impaired pleasure, relaxation, health, self-esteem, sense of accomplishment, and relationships. It may present as perfectionism, rigid rules and "shoulds," or a preoccupation with time and efficiency.',
              copings: [
                {
                  name: 'Surrender',
                  tip: `
1. Strives for perfection in their work or other areas of their life, often at the expense of other priorities.
2. Does not allow themselves to have fun or appreciate activities that are not directly related to success.
3. Is preoccupied with the rational use of time and personal growth.and comfort.
                        `,
                },
                {
                  name: 'Avoidance',
                  tip: `Engages in procrastination, delays tasks or actions.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Abandons high standards and settles for below-average results.`,
                },
              ],
            },
            {
              name: 'Punitiveness (PU)',
              tip: `Perfectionistic thinking that involves the belief that people should be severely punished for making mistakes, characterized by anger, intolerance, and a punitive and impatient attitude towards those who do not meet one's expectations. Often results in difficulty forgiving oneself or others and a lack of empathy for extenuating circumstances or human imperfections.`,
              copings: [
                {
                  name: 'Surrender',
                  tip: `Behaves overly harsh or rude towards significant others, displays anger, blames oneself and others.`,
                },
                {
                  name: 'Avoidance',
                  tip: `Avoids situations that involve evaluation due to the fear of being punished.`,
                },
                {
                  name: 'Overcompensation',
                  tip: `Acts overly magnanimous, while feeling angry and punitive inside.`,
                },
              ],
            },
          ],
        },
      },
    },
    supportingCycle: {
      supportingCycles: 'Vicious cycles',
      supportingCyclesShort: 'Vicious cycles',
      selectTemplate: 'Select template',
      cycleName: 'Name',
      thoughts: 'Thoughts, actions or some facts',
      relations: 'Leads To',
      influences: 'Influences',
      tempalteLabel: 'Start from a template (optional)',
      saveSuccess: 'Vicious cycle saved successfully',
      saveError: 'Error saving supporting cycle',
      addField: 'Add thought, action or fact',
      expandAll: 'Expand all',
    },
  },
  components: {
    layout: {},
    header: {
      selectClient: 'Select A Client',
      filterResults: 'Filter Results by Client',
      filterTip:
        'If you select a client here, you will see data only for this cleint in all the tools.',
      startSession: 'Start Session',
      finishSession: 'Finish Session',
      startTime: 'Start Time',
      endTime: 'End Time',
      duration: 'Duration',
    },
  },
  modals: {
    mobileAppAccount: {
      title: 'Mobile App Account Detected',
      text1:
        'We detected that you logged in with your client mobile app account.',
      text2: 'This Portal is for mental health providers only.',
      text3:
        'If you did not intend to create a provider account, please log out.',
      text4:
        'If you would like to start using your existing mobile app account as a provider account also, please continue.',
      okText: 'Continue',
      cancelText: 'Sign Out',
    },
  },
  actions: {
    list: 'List',
    create: 'Create',
    edit: 'Edit',
    show: 'Show',
  },
  buttons: {
    create: 'Create',
    save: 'Save',
    logout: 'Logout',
    delete: 'Delete',
    edit: 'Edit',
    cancel: 'Cancel',
    confirm: 'Are you sure?',
    filter: 'Filter',
    clear: 'Clear',
    refresh: 'Refresh',
    show: 'Show',
    undo: 'Undo',
    import: 'Import',
    export: 'Export',
    clone: 'Clone',
    notAccessTitle: "You don't have permission to access",
    invite: 'Invite',
  },
  warnWhenUnsavedChanges:
    'Are you sure you want to leave? You have unsaved changes.',
  notifications: {
    success: 'Successful',
    error: 'Error (status code: {{statusCode}})',
    undoable: 'You have {{seconds}} seconds to undo',
    createSuccess: 'Successfully created {{resource}}',
    createError:
      'There was an error creating {{resource}} (status code: {{statusCode}})',
    deleteSuccess: 'Successfully deleted {{resource}}',
    deleteError:
      'Error when deleting {{resource}} (status code: {{statusCode}})',
    editSuccess: 'Successfully edited {{resource}}',
    editError: 'Error when editing {{resource}} (status code: {{statusCode}})',
    importProgress: 'Importing: {{processed}}/{{total}}',
    unauthorized:
      'You are not uthorized to access this resource. Please contact Practice Admin for assistance.',
  },
  loading: 'Loading',
  tags: {
    clone: 'Clone',
  },
  dashboard: {
    title: 'Dashboard',
  },
  resourcesCommon: {
    actions: 'Actions',
    createdAt: 'Created At',
    duration: 'Duration',
    finishedAt: 'Finished At',
    updatedAt: 'Updated At',
    view: 'View',
    edit: 'Edit',
    userId: 'Client ID',
    yes: 'Yes',
    no: 'No',
    next: 'Next',
    finish: 'Finish',
  },
  clients: {
    clients: 'Clients',
    fields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      actions: 'Actions',
      access: 'Access',
      accessLevel: 'Account Type',
      createdAt: 'Created At',
    },
    inviteAppClient: 'Invite Client',
    thoughtsTest: 'Dysfunctional Thoughts Test',
    thoughtsTestDisabledTip:
      'The Client needs to have a Premium account to use this feature.',
    showInfo: 'Show Clients Personal Info',
    hideInfo: 'Hide Clients Personal Info',
    addNonAppClient: 'Add Non-App Client',
    nonAppClientDescription:
      'Add a client who does not (yet) use the mobile app. Can be useful for tracking clients who are not yet or will never be ready to use the mobile app. You will be able to invite them to use the app later.',
    free: 'Free',
    premium: 'Premium',
    premium_practice: 'Premium via Practice',
    premiumNonApp: 'Non-App Client',
    piiTip: `
Information on this page is hidden by default to protect Personal Identifiable Information (PII), e.g. from leaking during a teletherapy session.

Information is automatically hidden again after 1 minute of unhiding it.
    `,
    freeTip: `
Free: Client has limits on using Premium features of the Mobile app.
    `,
    premiumTip: `
Premium: Client has access to all Premium features of the Mobile app either by paying him/herself or getting it from another practice.
    `,
    premiumPracticeTip: `
Premium via Practice: Client has access to all Premium features of the Mobile app via your practice and is counted towards your practice subscription.
    `,
    nonAppClientTip: `Non-App Client: Client does not use the Mobile app. It is counted towards your practice subscription.`,
    freeDowngradeWarning: `
You are about to downgrade a client to Free version. This will remove access to Premium features from the client's Mobile app account and can result in deactivation of reminders, as well as limitations of the app usage. Are you sure?
`,
    upgradeWarning: `
You have reached the limit of clients your current Subscription supports. Please upgrade your subscription.
    `,
    limitReachedWarning: `
You have reached the limit of clients your current Subscription supports. Please upgrade your Subscription or downgrade some clients.
`,
    expiredWarning: `
Your subscription has expired. Please upgrade your subscription to be able to provide Premium access to your clients.
`,
    mergeTip: `Merge Non-Mible App and Moible-App Client Accounts`,
    mergeWarning: `Merging the accounts is not reversible. Current Non-Mobile client account will be deleted and all its data moved to the Mobile-App client account.`,
    mergeTipDetails: `You can merge this Non-App client with a Mobile-App client account. This Non-App account will be deleted and all its data moved to the client account with the Mobile app.
    If the Mobile App client account has non-empty Conceptualization data, we will use one from the Mobile cient account. If it is empty, we will use the one from this Non-Mobile Client account.
    All other data used from both accounts.
    `,
    selectMergeAppClient: `Select Mobile-App Client Account to Merge with`,
    mergeLabel: `Client with Mobile app to merge with`,
    deleteTip: `Delete Client`,
    deleteConfirmText: `Are you sure you want to delete this client and all its associated data? This action cannot be undone.`,
    deleteOkText: `Delete`,
    deleteCancelText: `Cancel`,
  },
  invites: {
    invites: 'Invites',
    createInvite: 'Create Invite',
    sendInvite: 'Send Invite',
    who: 'Who do you want to invite?',
    clientsStats: 'Clients Added / Subscription Clients',
    client: 'Client',
    practiceMember: 'Practice Member',
    practicionerRole: 'What role do you want to invite them as?',
    userTooltip: 'Users can manage and work with their own clients.',
    upgrade: 'Upgrade Subscription',
    adminTooltip:
      'Admins can manage other practice users, their permissions, work with all clients and their info. They can also manage information about the practice and manage payments.',
    messagePreview: 'Message Preview',
    copyToken: 'Copy Token',
    clientInviteHtml: `
            <p>Hi {{name}},</p>
            <p>{{practitioner}} invites you to download the <b>CopingCard</b> mobile app.</p>
            <br />
            <p><b>Step 1. Download the app and Create an account.</b></p>
            <p>If you are using an iPhone, please download our app from <b><a href="https://apps.apple.com/app/copingcard-cbt-mental-health/id1612621383?platform=iphone">App Store</a></b>. </p>
            <br />
            <p>If you are using an Android phone, please download our app from <b><a href="https://play.google.com/store/apps/details?id=com.copingcard">Google Play</a></b>. </p>
            <br />
            <p>Then sign up using a way that is convenient for you.</p>
            <br />
            <p><b>Step 2. Set up data sharing with {{practitioner}}</b></p>
            <p>Click <b><a href="{{link}}">this link</a></b> to accept the invitation and set up data sharing
            or use the following invite code to enter it manually in the Settings -> Data Sharing with Therapists section: <b>{{token}}</b></p>
            <br />
            <p>Best regards,</p>
            <p>CopingCard, Inc. Team</p>
    `,
    userInviteHtml: `
            <p>Hi {{name}},</p>
            <p>{{practitioner}} invites you to join the <b><a href="{{link}}">CopingCard</a>&nbsp;</b>platform
            as a {{role}}.</p>

            <br />
            <p>Click the link above to accept the invitation and create your account
            or use the following token if you are already registered: <b>{{token}}</b></p>
            <br />
            <p>Best regards,</p>
            <p>CopingCard, Inc. Team</p>
    `,
    permanentInviteLink: 'Permanent Client Invite Link',
    fields: {
      email: 'Email',
      createdAt: 'Created At',
      expiresAt: 'Expires At',
      from: 'From',
      type: 'Type',
      to: 'To',
      token: 'Token',
      firstName: 'First Name',
      lastName: 'Last Name',
      message: 'Message',
      copy: 'Copy Link',
    },
    types: {
      0: 'Permanent Invite Link',
      1: 'Client',
      2: 'Therapist',
      3: 'Practice User',
      4: 'Practice Admin',
    },
  },
  messages: {
    messages: 'Messages',
    fields: {
      from: 'From',
      to: 'To',
      message: 'Message',
      createdAt: 'Sent At',
      readAt: 'Read At',
      senderEmail: 'Sender Email',
      copyMessage: 'Copy Message',
      subject: 'Subject',
    },
    defaultSubject: 'Message from a Potential Client',
    readMessage: 'Read the Message',
  },
  portalThoughts: {
    portalThoughts: 'Thought Tests',
  },
  createThought: {
    create: 'Fill in a Dysfunctional Thought Test for a Client',
    selectClient: 'Select a Client',
    selectClientTip: 'You can select only clients with Premium accounts',
    eventTitle: 'Activating Event',
    emotionsTitle: 'Emotions',
    feelingsTitle: 'Feelings',
    automaticThoughtTitle: 'Automatic Thought',
    thoughtsForTitle: 'Facts and Evidence supporting the Negative Thought',
    thoughtsAgainstTitle: 'Facts and Evidence against the Negative Thought',
    realisticThoughtsTitle: 'Realistic and Helpful Ideas',
    resultsTitle: 'Assess Result',
    timeRemindersTitle: 'Time-based Reminders',
    locationRemindersTitle: 'Geolocation Notifications',
    eventLabel: 'Describe Activating Event',
    chooseEmotions: 'Choose Emotions',
    rateEmotions: 'Rate Emotions Strength',
    chooseFeelings: 'Choose General Feelings',
    feelingsInBody: 'Feelings in the Body',
    automaticThoughtBelief: 'Belief',
    addEvidenceAgainst: 'Add evidence against',
    addEvidenceFor: 'Add evidence',
    evidenceAgainst: 'Evidence against the Negative thought',
    evidenceFor: 'Evidence supporting the Negative Thought',
    alternativeThought: 'Alternative explanation for the evidence',
    alternativeThoughtTip:
      'What else could explain the thought evidence except for the automatic negative thought?',
    realisticThought: 'Realistic thought or idea',
    automaticThoughtAdd: 'Add thought',
    resultBelief: 'How strong is the initial negative thoughts?',
    resultEmotionStrength: 'How strong is the main initial negative emotion?',
    initialThoughtBelief: 'Initial Thought Belief',
    initialEmotionStrength: 'Initial Emotion Strength',
    day: 'Day reminder',
    morning: 'Morning reminder',
    evening: 'Evening reminder',
    createSuccess: 'You successfully created a coping card for your client',
    thoughtEvidenceRequired: 'Thought evidence is required',
    evidenceAgainstRequired: 'Evidence against is required',
    realisticThoughtRequired: 'Realistic thought is required',
    cognitiveDistortionsTile: 'Cognitive Distortions of the Automatic Thought',
    cognitiveDistortions: 'Cognitive Distortions',
    cognitiveDistortionsPlaceholder: 'Select Cognitive Distortions',
  },
  practice: {
    practice: 'Practice',
    practiceInfo: 'Practice Information',
  },
  portalSubscriptions: {
    title: 'Subscription',
    subscriptionOptions: ['Monthly', '6 Months', 'Yearly'],
    headline: 'Your Current Subscription',
    orderForm: '(Subscription Order Form)',
    customNumber: 'Enter the number of clients you need',
    month: 'month',
    halfYear: '6 months',
    year: 'year',
    free: 'Free',
    premium: 'Premium',
    clients: 'Clients',
    purchase: 'Purchase',
    paymentSuccess:
      'Congratulations! You have successfully finished the payment process and we updated your Subscription.',
    paymentFailed:
      'It looks like there was an issue with your payment. Please try again or contact us at support@copingcard.com and we will help you.',
    upgrade: 'Upgrade to Premium',
    manageSubscription: 'Manage your Subscription at Stripe',
    paidSupportOptions: [
      'Email Support',
      'Email, Video, Phone Support',
      'Priority Email, Video, Phone Support',
    ],
    freeFeatures: [
      '5 clients  (data viewing/management)',
      '5 client Mobile App Premium licenses for 3 months',
      'Free referrals',
      'Email Support',
    ],
    paidFeatures: [
      '{{clients}} clients (data viewing/management)',
      '{{clients}} client Mobile App Premium licenses for the length of your subscription',
      'Free referrals',
    ],
    subscriptionExpiringInDays: 'Your Subscription will expire in:',
    subscriptionExpired:
      'Your Subscription has expired. Your Clients will be downgraded in:',
    clientDowngradeTip: `
      Your clients will lose their Mobile App Premium features. 
      Notifications past Free limits will be turned off. 
      Various limits of the Free version will be enforced.
    `,
  },
  profile: {
    profile: 'Personal Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    description:
      'Please provide your credentials and information about yourself. This information will be visible in our Mobile App if you indicate you want to get referrals from us and we approve you.',
    wantsReferrals: 'I want to receive referrals',
    approvedForReferrals: 'Approved to get referrals',
    approvedForReferralsTip:
      'Your profile needs to be approved by our team before you can receive referrals. One of the requirements is to have 3 or more clients added to the portal and activated (used dysfuncitonal thought test, passed Depression and/or Anxiety tests, listened to a miditation).',
    selectOrType: 'Select or Type',
    formats: 'Therapy Formats',
    formatsOptions: ['Offline', 'Online'],
    phone: 'Phone',
    extension: 'Extension',
    professionalTitle: 'Title',
    professionalTitlePlaceholder: 'e.g. PhD',
    imageUrl: 'Your Photo',
    uploadPhoto: 'Upload',
    onlyImage: 'Only image files are allowed!',
    sizeLimit: 'File size limit is 5MB!',
    uploadSuccess: 'Upload success.',
    mentalHealthRole: 'Your Mental Health Role',
    mentalHealthRoles: [
      'Art Therapist',
      'Clinical Social Worker/Therapist',
      'Counselor',
      'Drug & Alcohol Counselor',
      'Licensed Professional Counselor',
      'Licensed Psychoanalyst',
      'Limited Licensed Psychologist',
      'Licensed Professional Counselor Intern',
      'Marriage & Family Therapist',
      'Marriage & Family Therapist Intern',
      'Marriage & Family Therapist Associate',
      'Occupational Therapist',
      'Pastoral Counselor',
      'Pre-Licensed Professional',
      'Psychiatric Nurse',
      'Psychiatric Nurse Practitioner',
      'Psychiatrist',
      'Psychological Associate',
      'Psychologist',
      'Registered Psychotherapist',
    ],
    licensedInStates: 'Licensed in State',
    state: 'State',
    licenseNumber: 'License Number',
    addState: 'Add State',
    about: 'About yourself',
    education: 'Education',
    addEducation: 'Add Education',
    acceptingClients: 'Accepting Clients',
    acceptingOptions: ['Yes', 'No', 'Waitlist'],
    yearsInPractice: 'Years of Practice',
    languages: 'Languages',
    langaugeHint: 'Please select or type the languages you speak',
    languagesList: [
      'English',
      'Spanish',
      'Chinese',
      'Vietnamese',
      'Arabic',
      'French',
      'Korean',
      'Russian',
      'German',
      'Ukrainian',
      'Belarusian',
    ],
    finances: 'Finances',
    costPerSessionFrom: 'Cost per Session From',
    fromPlaceholder: 'From',
    costPerSessionTo: 'To',
    slidingScale: 'Sliding Scale',
    slidingScaleTip: `Sliding scale fees are variable prices for products, services, or taxes based on a customer's ability to pay. Such fees are thereby reduced for those who have lower incomes, or alternatively, less money to spare after their personal expenses, regardless of income. Sliding scale fees are a form of price discrimination or differential pricing.`,
    freeInitialConsultation: 'Free Initial Consultation',
    currency: 'Currency',
    clients: 'Clients',
    otherInfo: 'Other Info',
    otherProfileLink: 'Link to another Profile',
    age: 'Age',
    clientAges: ['Adults', 'Children', 'Elders', 'Teens'],
    modality: 'Modality',
    modalities: ['Individuals', 'Family', 'Group'],
    issues: 'Issues',
    clientIssues: [
      'ADHD',
      'Abortion, Pregnancy Loss and Miscarriage',
      'Abuse',
      'Adjustment Disorder',
      'Adolescent Issues',
      'Adoption Issues',
      'Aging Concerns',
      'Alcohol Abuse and Alcohol Dependence',
      "Alzheimer's",
      'Anger Issues',
      'Anxiety',
      'Attachment',
      'Autism Spectrum Disorder',
      'Bipolar',
      'Body Image Issues',
      'Body-focused repetitive behavior (BFRB)',
      'Burnout',
      'Cancer',
      'Career Counseling',
      'Child Issues',
      'Chronic Pain or Illness',
      'Codependency',
      'Compassion Fatigue',
      'Coparenting',
      'Cultural and Systemic Oppression',
      'Depression',
      'Dissociative Disorders',
      'Divorce',
      'Domestic Violence or Intimate Partner Violence',
      'Drug Dependence / Abuse / Addiction',
      'Eating Disorders',
      'Family Caregiving Stress',
      'Family Conflict',
      'Foster Care Issues',
      'Gambling Addiction',
      'Gender Identity',
      'Grief or Loss',
      'Highly Sensitive Person',
      'Historical / Intergenerational Trauma',
      'Immigration / Acculturation',
      'Incarceration and Reentry',
      'Infertility',
      'Infidelity or Affairs',
      'Insomnia',
      'Intellectual Disability',
      'Internet Addiction',
      'Job Stress',
      'Kink / Sexual Outsiders',
      'LGBTQ Issues',
      'Learning Disorders',
      'Life transitions',
      "Men's Issues",
      'Obsessive Compulsive Disorder (OCD)',
      'Panic',
      'Parenting',
      'Personal Growth',
      'Personality Disorders',
      'Physical Disability',
      'Political Climate',
      'Polyamorous and Open Relationships',
      'Post Traumatic Stress Disorder (PTSD)',
      'Pregnancy and Postpartum',
      'Psychosis and Schizophrenia',
      'Racial Identity',
      'Relationship / Marriage Issues',
      'Self-Esteem',
      'Self-Harm',
      'Sexual Abuse or Assault',
      'Sexual Addiction',
      'Sexual Identity',
      'Sexual Problems',
      'Social Phobia',
      'Spiritual Abuse and Religious Trauma',
      'Spirituality',
      'Stress',
      'Suicidal Thoughts',
      'Therapists Who Treat Other Therapists',
      'Tourette Syndrome',
      'Toxic Masculinity',
      'Traumatic Brain Injury (TBI)',
      'Veterans / Military Service',
      "Women's Issues",
    ],
    approachLabel: 'Therapy Approaches',
    approaches: [
      'Accelerated Experiential Dynamic Psychotherapy (AEDP)',
      'Acceptance and Commitment Therapy (ACT)',
      'Addiction Counseling',
      'Anger Management Therapy',
      'Animal Assisted Therapy (AAT)',
      'Art Therapy',
      'Attachment Theory',
      'Biofeedback',
      'Brainspotting',
      'Clinical Neuropsychology',
      'Coaching',
      'Cognitive Behavioral Therapy (CBT)',
      'Collaborative Couple Therapy',
      'Contemplative Therapy',
      'Couples Counseling',
      'Culturally Sensitive Therapy',
      'Dance / Movement Therapy',
      'Depth Therapy',
      'Dialectical Behavior Therapy (DBT)',
      'Discernment Counseling',
      'Eye movement desensitization and reprocessing (EMDR)',
      'Eclectic Therapy',
      'EcoTherapy',
      'Emotionally Focused Therapy',
      'Energy Psychology',
      'Equine Assisted Psychotherapy (EAP)',
      'Existential Therapy',
      'Experiential Therapy',
      'Exposure Therapy',
      'Expressive Art Therapy',
      'Family Systems',
      'Family Therapy',
      'Feminist Therapy',
      'Gestalt Therapy',
      'Gottman Method',
      'Hakomi',
      'Harm Reduction Therapy',
      'Holistic Therapy',
      'Humanistic Therapy',
      'Hypnotherapy',
      'Imago Relationship Therapy',
      'Integral Therapy',
      'Integrative Therapy',
      'Internal Family Systems',
      'Interpersonal Neurobiology (IPNB)',
      'Interpersonal Therapy (IPT)',
      'Intervention',
      'Metacognitive Therapy (MCT)',
      'Mindfulness-based Therapy',
      'Motivational Interviewing',
      'Music Therapy',
      'Narrative Therapy',
      'NeuroAffective Relational Model (NARM)',
      'Neurofeedback',
      'Nonviolent Communication',
      'Past Life Regression',
      'Person-Centered (Rogerian)',
      'Play Therapy',
      'Premarital Counseling',
      'Psychoanalytic',
      'Psychobiological Approach to Couple Therapy (PACT)',
      'Psychodrama',
      'Psychodynamic Therapy',
      'Psychological Testing and Evaluation',
      'Relational Therapy',
      'Sand Tray Therapy',
      'Sandplay Therapy',
      'Schema Therapy',
      'Sensorimotor Psychotherapy',
      'Solution Focused Brief Therapy',
      'Somatic Therapy (Body Centered)',
      'Transpersonal Therapy',
      'Trauma Therapy',
      'Wilderness Therapy',
    ],
  },
  tools: {
    tools: 'Tools',
  },
  portalTests: {
    portalTests: 'Tests',
    fields: {
      type: 'Type',
      score: 'Score',
      severity: 'Severity',
      createdAt: 'Passed At',
    },
  },
  practiceUsers: {
    practiceUsers: 'Users',
    fields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      role: 'Role',
      actions: 'Actions',
    },
  },
  practiceInfo: {
    practiceInfo: 'Info',
    fields: {},
  },
  posts: {
    posts: 'Posts',
    fields: {
      id: 'Id',
      title: 'Title',
      category: {
        title: 'Category',
        filter: {
          placeholder: 'Select Category',
        },
      },
      status: {
        title: 'Status',
        published: 'Published',
        draft: 'Draft',
        rejected: 'Rejected',
      },
      content: 'Content',
      createdAt: 'Created At',
    },
    titles: {
      create: 'Create Post',
      edit: 'Edit Post',
      list: 'Posts',
      show: 'Show Post',
    },
  },
  table: {
    actions: 'Actions',
  },
  roles: {
    0: 'Client',
    1: 'Client with Premium Access',
    2: 'Practice User',
    3: 'Practice Admin',
    4: 'Admin',
  },
  // Note: we could move it to another namespace if it slows down portal loading too much
  icd10: [
    {
      value: 'F01-F09',
      title: 'F01-F09 Mental disorders due to known physiological conditions',
      children: [
        {
          value: 'F01',
          title: 'F01 Vascular dementia',
          children: [
            {
              value: 'F01.5',
              title: 'F01.5 Vascular dementia, unspecified severity',
              children: [
                {
                  value: 'F01.50',
                  title:
                    'F01.50 Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F01.51',
                  title:
                    'F01.51 Vascular dementia, unspecified severity, with behavioral disturbance',
                  children: [
                    {
                      value: 'F01.511',
                      title:
                        'F01.511 Vascular dementia, unspecified severity, with agitation',
                    },
                    {
                      value: 'F01.518',
                      title:
                        'F01.518 Vascular dementia, unspecified severity, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F01.52',
                  title:
                    'F01.52 Vascular dementia, unspecified severity, with psychotic disturbance',
                },
                {
                  value: 'F01.53',
                  title:
                    'F01.53 Vascular dementia, unspecified severity, with mood disturbance',
                },
                {
                  value: 'F01.54',
                  title:
                    'F01.54 Vascular dementia, unspecified severity, with anxiety',
                },
              ],
            },
            {
              value: 'F01.A',
              title: 'F01.A Vascular dementia, mild',
              children: [
                {
                  value: 'F01.A0',
                  title:
                    'F01.A0 Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F01.A1',
                  title:
                    'F01.A1 Vascular dementia, mild, with behavioral disturbance',
                  children: [
                    {
                      value: 'F01.A11',
                      title: 'F01.A11 Vascular dementia, mild, with agitation',
                    },
                    {
                      value: 'F01.A18',
                      title:
                        'F01.A18 Vascular dementia, mild, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F01.A2',
                  title:
                    'F01.A2 Vascular dementia, mild, with psychotic disturbance',
                },
                {
                  value: 'F01.A3',
                  title:
                    'F01.A3 Vascular dementia, mild, with mood disturbance',
                },
                {
                  value: 'F01.A4',
                  title: 'F01.A4 Vascular dementia, mild, with anxiety',
                },
              ],
            },
            {
              value: 'F01.B',
              title: 'F01.B Vascular dementia, moderate',
              children: [
                {
                  value: 'F01.B0',
                  title:
                    'F01.B0 Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F01.B1',
                  title:
                    'F01.B1 Vascular dementia, moderate, with behavioral disturbance',
                  children: [
                    {
                      value: 'F01.B11',
                      title:
                        'F01.B11 Vascular dementia, moderate, with agitation',
                    },
                    {
                      value: 'F01.B18',
                      title:
                        'F01.B18 Vascular dementia, moderate, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F01.B2',
                  title:
                    'F01.B2 Vascular dementia, moderate, with psychotic disturbance',
                },
                {
                  value: 'F01.B3',
                  title:
                    'F01.B3 Vascular dementia, moderate, with mood disturbance',
                },
                {
                  value: 'F01.B4',
                  title: 'F01.B4 Vascular dementia, moderate, with anxiety',
                },
              ],
            },
            {
              value: 'F01.C',
              title: 'F01.C Vascular dementia, severe',
              children: [
                {
                  value: 'F01.C0',
                  title:
                    'F01.C0 Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F01.C1',
                  title:
                    'F01.C1 Vascular dementia, severe, with behavioral disturbance',
                  children: [
                    {
                      value: 'F01.C11',
                      title:
                        'F01.C11 Vascular dementia, severe, with agitation',
                    },
                    {
                      value: 'F01.C18',
                      title:
                        'F01.C18 Vascular dementia, severe, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F01.C2',
                  title:
                    'F01.C2 Vascular dementia, severe, with psychotic disturbance',
                },
                {
                  value: 'F01.C3',
                  title:
                    'F01.C3 Vascular dementia, severe, with mood disturbance',
                },
                {
                  value: 'F01.C4',
                  title: 'F01.C4 Vascular dementia, severe, with anxiety',
                },
              ],
            },
          ],
        },
        {
          value: 'F02',
          title: 'F02 Dementia in other diseases classified elsewhere',
          children: [
            {
              value: 'F02.8',
              title:
                'F02.8 Dementia in other diseases classified elsewhere, unspecified severity',
              children: [
                {
                  value: 'F02.80',
                  title:
                    'F02.80 Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F02.81',
                  title:
                    'F02.81 Dementia in other diseases classified elsewhere, unspecified severity, with behavioral disturbance',
                  children: [
                    {
                      value: 'F02.811',
                      title:
                        'F02.811 Dementia in other diseases classified elsewhere, unspecified severity, with agitation',
                    },
                    {
                      value: 'F02.818',
                      title:
                        'F02.818 Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F02.82',
                  title:
                    'F02.82 Dementia in other diseases classified elsewhere, unspecified severity, with psychotic disturbance',
                },
                {
                  value: 'F02.83',
                  title:
                    'F02.83 Dementia in other diseases classified elsewhere, unspecified severity, with mood disturbance',
                },
                {
                  value: 'F02.84',
                  title:
                    'F02.84 Dementia in other diseases classified elsewhere, unspecified severity, with anxiety',
                },
              ],
            },
            {
              value: 'F02.A',
              title:
                'F02.A Dementia in other diseases classified elsewhere, mild',
              children: [
                {
                  value: 'F02.A0',
                  title:
                    'F02.A0 Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F02.A1',
                  title:
                    'F02.A1 Dementia in other diseases classified elsewhere, mild, with behavioral disturbance',
                  children: [
                    {
                      value: 'F02.A11',
                      title:
                        'F02.A11 Dementia in other diseases classified elsewhere, mild, with agitation',
                    },
                    {
                      value: 'F02.A18',
                      title:
                        'F02.A18 Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F02.A2',
                  title:
                    'F02.A2 Dementia in other diseases classified elsewhere, mild, with psychotic disturbance',
                },
                {
                  value: 'F02.A3',
                  title:
                    'F02.A3 Dementia in other diseases classified elsewhere, mild, with mood disturbance',
                },
                {
                  value: 'F02.A4',
                  title:
                    'F02.A4 Dementia in other diseases classified elsewhere, mild, with anxiety',
                },
              ],
            },
            {
              value: 'F02.B',
              title:
                'F02.B Dementia in other diseases classified elsewhere, moderate',
              children: [
                {
                  value: 'F02.B0',
                  title:
                    'F02.B0 Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F02.B1',
                  title:
                    'F02.B1 Dementia in other diseases classified elsewhere, moderate, with behavioral disturbance',
                  children: [
                    {
                      value: 'F02.B11',
                      title:
                        'F02.B11 Dementia in other diseases classified elsewhere, moderate, with agitation',
                    },
                    {
                      value: 'F02.B18',
                      title:
                        'F02.B18 Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F02.B2',
                  title:
                    'F02.B2 Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance',
                },
                {
                  value: 'F02.B3',
                  title:
                    'F02.B3 Dementia in other diseases classified elsewhere, moderate, with mood disturbance',
                },
                {
                  value: 'F02.B4',
                  title:
                    'F02.B4 Dementia in other diseases classified elsewhere, moderate, with anxiety',
                },
              ],
            },
            {
              value: 'F02.C',
              title:
                'F02.C Dementia in other diseases classified elsewhere, severe',
              children: [
                {
                  value: 'F02.C0',
                  title:
                    'F02.C0 Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F02.C1',
                  title:
                    'F02.C1 Dementia in other diseases classified elsewhere, severe, with behavioral disturbance',
                  children: [
                    {
                      value: 'F02.C11',
                      title:
                        'F02.C11 Dementia in other diseases classified elsewhere, severe, with agitation',
                    },
                    {
                      value: 'F02.C18',
                      title:
                        'F02.C18 Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F02.C2',
                  title:
                    'F02.C2 Dementia in other diseases classified elsewhere, severe, with psychotic disturbance',
                },
                {
                  value: 'F02.C3',
                  title:
                    'F02.C3 Dementia in other diseases classified elsewhere, severe, with mood disturbance',
                },
                {
                  value: 'F02.C4',
                  title:
                    'F02.C4 Dementia in other diseases classified elsewhere, severe, with anxiety',
                },
              ],
            },
          ],
        },
        {
          value: 'F03',
          title: 'F03 Unspecified dementia',
          children: [
            {
              value: 'F03.9',
              title: 'F03.9 Unspecified dementia, unspecified severity',
              children: [
                {
                  value: 'F03.90',
                  title:
                    'F03.90 Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F03.91',
                  title:
                    'F03.91 Unspecified dementia, unspecified severity, with behavioral disturbance',
                  children: [
                    {
                      value: 'F03.911',
                      title:
                        'F03.911 Unspecified dementia, unspecified severity, with agitation',
                    },
                    {
                      value: 'F03.918',
                      title:
                        'F03.918 Unspecified dementia, unspecified severity, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F03.92',
                  title:
                    'F03.92 Unspecified dementia, unspecified severity, with psychotic disturbance',
                },
                {
                  value: 'F03.93',
                  title:
                    'F03.93 Unspecified dementia, unspecified severity, with mood disturbance',
                },
                {
                  value: 'F03.94',
                  title:
                    'F03.94 Unspecified dementia, unspecified severity, with anxiety',
                },
              ],
            },
            {
              value: 'F03.A',
              title: 'F03.A Unspecified dementia, mild',
              children: [
                {
                  value: 'F03.A0',
                  title:
                    'F03.A0 Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F03.A1',
                  title:
                    'F03.A1 Unspecified dementia, mild, with behavioral disturbance',
                  children: [
                    {
                      value: 'F03.A11',
                      title:
                        'F03.A11 Unspecified dementia, mild, with agitation',
                    },
                    {
                      value: 'F03.A18',
                      title:
                        'F03.A18 Unspecified dementia, mild, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F03.A2',
                  title:
                    'F03.A2 Unspecified dementia, mild, with psychotic disturbance',
                },
                {
                  value: 'F03.A3',
                  title:
                    'F03.A3 Unspecified dementia, mild, with mood disturbance',
                },
                {
                  value: 'F03.A4',
                  title: 'F03.A4 Unspecified dementia, mild, with anxiety',
                },
              ],
            },
            {
              value: 'F03.B',
              title: 'F03.B Unspecified dementia, moderate',
              children: [
                {
                  value: 'F03.B0',
                  title:
                    'F03.B0 Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F03.B1',
                  title:
                    'F03.B1 Unspecified dementia, moderate, with behavioral disturbance',
                  children: [
                    {
                      value: 'F03.B11',
                      title:
                        'F03.B11 Unspecified dementia, moderate, with agitation',
                    },
                    {
                      value: 'F03.B18',
                      title:
                        'F03.B18 Unspecified dementia, moderate, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F03.B2',
                  title:
                    'F03.B2 Unspecified dementia, moderate, with psychotic disturbance',
                },
                {
                  value: 'F03.B3',
                  title:
                    'F03.B3 Unspecified dementia, moderate, with mood disturbance',
                },
                {
                  value: 'F03.B4',
                  title: 'F03.B4 Unspecified dementia, moderate, with anxiety',
                },
              ],
            },
            {
              value: 'F03.C',
              title: 'F03.C Unspecified dementia, severe',
              children: [
                {
                  value: 'F03.C0',
                  title:
                    'F03.C0 Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
                },
                {
                  value: 'F03.C1',
                  title:
                    'F03.C1 Unspecified dementia, severe, with behavioral disturbance',
                  children: [
                    {
                      value: 'F03.C11',
                      title:
                        'F03.C11 Unspecified dementia, severe, with agitation',
                    },
                    {
                      value: 'F03.C18',
                      title:
                        'F03.C18 Unspecified dementia, severe, with other behavioral disturbance',
                    },
                  ],
                },
                {
                  value: 'F03.C2',
                  title:
                    'F03.C2 Unspecified dementia, severe, with psychotic disturbance',
                },
                {
                  value: 'F03.C3',
                  title:
                    'F03.C3 Unspecified dementia, severe, with mood disturbance',
                },
                {
                  value: 'F03.C4',
                  title: 'F03.C4 Unspecified dementia, severe, with anxiety',
                },
              ],
            },
          ],
        },
        {
          value: 'F04',
          title: 'F04 Amnestic disorder due to known physiological condition',
        },
        {
          value: 'F05',
          title: 'F05 Delirium due to known physiological condition',
        },
        {
          value: 'F06',
          title:
            'F06 Other mental disorders due to known physiological condition',
          children: [
            {
              value: 'F06.0',
              title:
                'F06.0 Psychotic disorder with hallucinations due to known physiological condition',
            },
            {
              value: 'F06.1',
              title:
                'F06.1 Catatonic disorder due to known physiological condition',
            },
            {
              value: 'F06.2',
              title:
                'F06.2 Psychotic disorder with delusions due to known physiological condition',
            },
            {
              value: 'F06.3',
              title: 'F06.3 Mood disorder due to known physiological condition',
              children: [
                {
                  value: 'F06.30',
                  title:
                    'F06.30 Mood disorder due to known physiological condition, unspecified',
                },
                {
                  value: 'F06.31',
                  title:
                    'F06.31 Mood disorder due to known physiological condition with depressive features',
                },
                {
                  value: 'F06.32',
                  title:
                    'F06.32 Mood disorder due to known physiological condition with major depressive-like episode',
                },
                {
                  value: 'F06.33',
                  title:
                    'F06.33 Mood disorder due to known physiological condition with manic features',
                },
                {
                  value: 'F06.34',
                  title:
                    'F06.34 Mood disorder due to known physiological condition with mixed features',
                },
              ],
            },
            {
              value: 'F06.4',
              title:
                'F06.4 Anxiety disorder due to known physiological condition',
            },
            {
              value: 'F06.7',
              title:
                'F06.7 Mild neurocognitive disorder due to known physiological condition',
              children: [
                {
                  value: 'F06.70',
                  title:
                    'F06.70 Mild neurocognitive disorder due to known physiological condition without behavioral disturbance',
                },
                {
                  value: 'F06.71',
                  title:
                    'F06.71 Mild neurocognitive disorder due to known physiological condition with behavioral disturbance',
                },
              ],
            },
            {
              value: 'F06.8',
              title:
                'F06.8 Other specified mental disorders due to known physiological condition',
            },
          ],
        },
        {
          value: 'F07',
          title:
            'F07 Personality and behavioral disorders due to known physiological condition',
          children: [
            {
              value: 'F07.0',
              title:
                'F07.0 Personality change due to known physiological condition',
            },
            {
              value: 'F07.8',
              title:
                'F07.8 Other personality and behavioral disorders due to known physiological condition',
              children: [
                {
                  value: 'F07.81',
                  title: 'F07.81 Postconcussional syndrome',
                },
                {
                  value: 'F07.89',
                  title:
                    'F07.89 Other personality and behavioral disorders due to known physiological condition',
                },
              ],
            },
            {
              value: 'F07.9',
              title:
                'F07.9 Unspecified personality and behavioral disorder due to known physiological condition',
            },
          ],
        },
        {
          value: 'F09',
          title:
            'F09 Unspecified mental disorder due to known physiological condition',
        },
      ],
    },
    {
      value: 'F10-F19',
      title:
        'F10-F19 Mental and behavioral disorders due to psychoactive substance use',
      children: [
        {
          value: 'F10',
          title: 'F10 Alcohol related disorders',
          children: [
            {
              value: 'F10.1',
              title: 'F10.1 Alcohol abuse',
              children: [
                {
                  value: 'F10.10',
                  title: 'F10.10 Alcohol abuse, uncomplicated',
                },
                {
                  value: 'F10.11',
                  title: 'F10.11 Alcohol abuse, in remission',
                },
                {
                  value: 'F10.12',
                  title: 'F10.12 Alcohol abuse with intoxication',
                  children: [
                    {
                      value: 'F10.120',
                      title:
                        'F10.120 Alcohol abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F10.121',
                      title: 'F10.121 Alcohol abuse with intoxication delirium',
                    },
                    {
                      value: 'F10.129',
                      title:
                        'F10.129 Alcohol abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.13',
                  title: 'F10.13 Alcohol abuse, with withdrawal',
                  children: [
                    {
                      value: 'F10.130',
                      title:
                        'F10.130 Alcohol abuse with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F10.131',
                      title: 'F10.131 Alcohol abuse with withdrawal delirium',
                    },
                    {
                      value: 'F10.132',
                      title:
                        'F10.132 Alcohol abuse with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F10.139',
                      title:
                        'F10.139 Alcohol abuse with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.14',
                  title:
                    'F10.14 Alcohol abuse with alcohol-induced mood disorder',
                },
                {
                  value: 'F10.15',
                  title:
                    'F10.15 Alcohol abuse with alcohol-induced psychotic disorder',
                  children: [
                    {
                      value: 'F10.150',
                      title:
                        'F10.150 Alcohol abuse with alcohol-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F10.151',
                      title:
                        'F10.151 Alcohol abuse with alcohol-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F10.159',
                      title:
                        'F10.159 Alcohol abuse with alcohol-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.18',
                  title:
                    'F10.18 Alcohol abuse with other alcohol-induced disorders',
                  children: [
                    {
                      value: 'F10.180',
                      title:
                        'F10.180 Alcohol abuse with alcohol-induced anxiety disorder',
                    },
                    {
                      value: 'F10.181',
                      title:
                        'F10.181 Alcohol abuse with alcohol-induced sexual dysfunction',
                    },
                    {
                      value: 'F10.182',
                      title:
                        'F10.182 Alcohol abuse with alcohol-induced sleep disorder',
                    },
                    {
                      value: 'F10.188',
                      title:
                        'F10.188 Alcohol abuse with other alcohol-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F10.19',
                  title:
                    'F10.19 Alcohol abuse with unspecified alcohol-induced disorder',
                },
              ],
            },
            {
              value: 'F10.2',
              title: 'F10.2 Alcohol dependence',
              children: [
                {
                  value: 'F10.20',
                  title: 'F10.20 Alcohol dependence, uncomplicated',
                },
                {
                  value: 'F10.21',
                  title: 'F10.21 Alcohol dependence, in remission',
                },
                {
                  value: 'F10.22',
                  title: 'F10.22 Alcohol dependence with intoxication',
                  children: [
                    {
                      value: 'F10.220',
                      title:
                        'F10.220 Alcohol dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F10.221',
                      title:
                        'F10.221 Alcohol dependence with intoxication delirium',
                    },
                    {
                      value: 'F10.229',
                      title:
                        'F10.229 Alcohol dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.23',
                  title: 'F10.23 Alcohol dependence with withdrawal',
                  children: [
                    {
                      value: 'F10.230',
                      title:
                        'F10.230 Alcohol dependence with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F10.231',
                      title:
                        'F10.231 Alcohol dependence with withdrawal delirium',
                    },
                    {
                      value: 'F10.232',
                      title:
                        'F10.232 Alcohol dependence with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F10.239',
                      title:
                        'F10.239 Alcohol dependence with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.24',
                  title:
                    'F10.24 Alcohol dependence with alcohol-induced mood disorder',
                },
                {
                  value: 'F10.25',
                  title:
                    'F10.25 Alcohol dependence with alcohol-induced psychotic disorder',
                  children: [
                    {
                      value: 'F10.250',
                      title:
                        'F10.250 Alcohol dependence with alcohol-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F10.251',
                      title:
                        'F10.251 Alcohol dependence with alcohol-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F10.259',
                      title:
                        'F10.259 Alcohol dependence with alcohol-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.26',
                  title:
                    'F10.26 Alcohol dependence with alcohol-induced persisting amnestic disorder',
                },
                {
                  value: 'F10.27',
                  title:
                    'F10.27 Alcohol dependence with alcohol-induced persisting dementia',
                },
                {
                  value: 'F10.28',
                  title:
                    'F10.28 Alcohol dependence with other alcohol-induced disorders',
                  children: [
                    {
                      value: 'F10.280',
                      title:
                        'F10.280 Alcohol dependence with alcohol-induced anxiety disorder',
                    },
                    {
                      value: 'F10.281',
                      title:
                        'F10.281 Alcohol dependence with alcohol-induced sexual dysfunction',
                    },
                    {
                      value: 'F10.282',
                      title:
                        'F10.282 Alcohol dependence with alcohol-induced sleep disorder',
                    },
                    {
                      value: 'F10.288',
                      title:
                        'F10.288 Alcohol dependence with other alcohol-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F10.29',
                  title:
                    'F10.29 Alcohol dependence with unspecified alcohol-induced disorder',
                },
              ],
            },
            {
              value: 'F10.9',
              title: 'F10.9 Alcohol use, unspecified',
              children: [
                {
                  value: 'F10.90',
                  title: 'F10.90 Alcohol use, unspecified, uncomplicated',
                },
                {
                  value: 'F10.91',
                  title: 'F10.91 Alcohol use, unspecified, in remission',
                },
                {
                  value: 'F10.92',
                  title: 'F10.92 Alcohol use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F10.920',
                      title:
                        'F10.920 Alcohol use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F10.921',
                      title:
                        'F10.921 Alcohol use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F10.929',
                      title:
                        'F10.929 Alcohol use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.93',
                  title: 'F10.93 Alcohol use, unspecified with withdrawal',
                  children: [
                    {
                      value: 'F10.930',
                      title:
                        'F10.930 Alcohol use, unspecified with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F10.931',
                      title:
                        'F10.931 Alcohol use, unspecified with withdrawal delirium',
                    },
                    {
                      value: 'F10.932',
                      title:
                        'F10.932 Alcohol use, unspecified with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F10.939',
                      title:
                        'F10.939 Alcohol use, unspecified with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.94',
                  title:
                    'F10.94 Alcohol use, unspecified with alcohol-induced mood disorder',
                },
                {
                  value: 'F10.95',
                  title:
                    'F10.95 Alcohol use, unspecified with alcohol-induced psychotic disorder',
                  children: [
                    {
                      value: 'F10.950',
                      title:
                        'F10.950 Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F10.951',
                      title:
                        'F10.951 Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F10.959',
                      title:
                        'F10.959 Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F10.96',
                  title:
                    'F10.96 Alcohol use, unspecified with alcohol-induced persisting amnestic disorder',
                },
                {
                  value: 'F10.97',
                  title:
                    'F10.97 Alcohol use, unspecified with alcohol-induced persisting dementia',
                },
                {
                  value: 'F10.98',
                  title:
                    'F10.98 Alcohol use, unspecified with other alcohol-induced disorders',
                  children: [
                    {
                      value: 'F10.980',
                      title:
                        'F10.980 Alcohol use, unspecified with alcohol-induced anxiety disorder',
                    },
                    {
                      value: 'F10.981',
                      title:
                        'F10.981 Alcohol use, unspecified with alcohol-induced sexual dysfunction',
                    },
                    {
                      value: 'F10.982',
                      title:
                        'F10.982 Alcohol use, unspecified with alcohol-induced sleep disorder',
                    },
                    {
                      value: 'F10.988',
                      title:
                        'F10.988 Alcohol use, unspecified with other alcohol-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F10.99',
                  title:
                    'F10.99 Alcohol use, unspecified with unspecified alcohol-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F11',
          title: 'F11 Opioid related disorders',
          children: [
            {
              value: 'F11.1',
              title: 'F11.1 Opioid abuse',
              children: [
                {
                  value: 'F11.10',
                  title: 'F11.10 Opioid abuse, uncomplicated',
                },
                {
                  value: 'F11.11',
                  title: 'F11.11 Opioid abuse, in remission',
                },
                {
                  value: 'F11.12',
                  title: 'F11.12 Opioid abuse with intoxication',
                  children: [
                    {
                      value: 'F11.120',
                      title:
                        'F11.120 Opioid abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F11.121',
                      title: 'F11.121 Opioid abuse with intoxication delirium',
                    },
                    {
                      value: 'F11.122',
                      title:
                        'F11.122 Opioid abuse with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F11.129',
                      title:
                        'F11.129 Opioid abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.13',
                  title: 'F11.13 Opioid abuse with withdrawal',
                },
                {
                  value: 'F11.14',
                  title:
                    'F11.14 Opioid abuse with opioid-induced mood disorder',
                },
                {
                  value: 'F11.15',
                  title:
                    'F11.15 Opioid abuse with opioid-induced psychotic disorder',
                  children: [
                    {
                      value: 'F11.150',
                      title:
                        'F11.150 Opioid abuse with opioid-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F11.151',
                      title:
                        'F11.151 Opioid abuse with opioid-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F11.159',
                      title:
                        'F11.159 Opioid abuse with opioid-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.18',
                  title:
                    'F11.18 Opioid abuse with other opioid-induced disorder',
                  children: [
                    {
                      value: 'F11.181',
                      title:
                        'F11.181 Opioid abuse with opioid-induced sexual dysfunction',
                    },
                    {
                      value: 'F11.182',
                      title:
                        'F11.182 Opioid abuse with opioid-induced sleep disorder',
                    },
                    {
                      value: 'F11.188',
                      title:
                        'F11.188 Opioid abuse with other opioid-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F11.19',
                  title:
                    'F11.19 Opioid abuse with unspecified opioid-induced disorder',
                },
              ],
            },
            {
              value: 'F11.2',
              title: 'F11.2 Opioid dependence',
              children: [
                {
                  value: 'F11.20',
                  title: 'F11.20 Opioid dependence, uncomplicated',
                },
                {
                  value: 'F11.21',
                  title: 'F11.21 Opioid dependence, in remission',
                },
                {
                  value: 'F11.22',
                  title: 'F11.22 Opioid dependence with intoxication',
                  children: [
                    {
                      value: 'F11.220',
                      title:
                        'F11.220 Opioid dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F11.221',
                      title:
                        'F11.221 Opioid dependence with intoxication delirium',
                    },
                    {
                      value: 'F11.222',
                      title:
                        'F11.222 Opioid dependence with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F11.229',
                      title:
                        'F11.229 Opioid dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.23',
                  title: 'F11.23 Opioid dependence with withdrawal',
                },
                {
                  value: 'F11.24',
                  title:
                    'F11.24 Opioid dependence with opioid-induced mood disorder',
                },
                {
                  value: 'F11.25',
                  title:
                    'F11.25 Opioid dependence with opioid-induced psychotic disorder',
                  children: [
                    {
                      value: 'F11.250',
                      title:
                        'F11.250 Opioid dependence with opioid-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F11.251',
                      title:
                        'F11.251 Opioid dependence with opioid-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F11.259',
                      title:
                        'F11.259 Opioid dependence with opioid-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.28',
                  title:
                    'F11.28 Opioid dependence with other opioid-induced disorder',
                  children: [
                    {
                      value: 'F11.281',
                      title:
                        'F11.281 Opioid dependence with opioid-induced sexual dysfunction',
                    },
                    {
                      value: 'F11.282',
                      title:
                        'F11.282 Opioid dependence with opioid-induced sleep disorder',
                    },
                    {
                      value: 'F11.288',
                      title:
                        'F11.288 Opioid dependence with other opioid-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F11.29',
                  title:
                    'F11.29 Opioid dependence with unspecified opioid-induced disorder',
                },
              ],
            },
            {
              value: 'F11.9',
              title: 'F11.9 Opioid use, unspecified',
              children: [
                {
                  value: 'F11.90',
                  title: 'F11.90 Opioid use, unspecified, uncomplicated',
                },
                {
                  value: 'F11.91',
                  title: 'F11.91 Opioid use, unspecified, in remission',
                },
                {
                  value: 'F11.92',
                  title: 'F11.92 Opioid use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F11.920',
                      title:
                        'F11.920 Opioid use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F11.921',
                      title:
                        'F11.921 Opioid use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F11.922',
                      title:
                        'F11.922 Opioid use, unspecified with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F11.929',
                      title:
                        'F11.929 Opioid use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.93',
                  title: 'F11.93 Opioid use, unspecified with withdrawal',
                },
                {
                  value: 'F11.94',
                  title:
                    'F11.94 Opioid use, unspecified with opioid-induced mood disorder',
                },
                {
                  value: 'F11.95',
                  title:
                    'F11.95 Opioid use, unspecified with opioid-induced psychotic disorder',
                  children: [
                    {
                      value: 'F11.950',
                      title:
                        'F11.950 Opioid use, unspecified with opioid-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F11.951',
                      title:
                        'F11.951 Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F11.959',
                      title:
                        'F11.959 Opioid use, unspecified with opioid-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F11.98',
                  title:
                    'F11.98 Opioid use, unspecified with other specified opioid-induced disorder',
                  children: [
                    {
                      value: 'F11.981',
                      title:
                        'F11.981 Opioid use, unspecified with opioid-induced sexual dysfunction',
                    },
                    {
                      value: 'F11.982',
                      title:
                        'F11.982 Opioid use, unspecified with opioid-induced sleep disorder',
                    },
                    {
                      value: 'F11.988',
                      title:
                        'F11.988 Opioid use, unspecified with other opioid-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F11.99',
                  title:
                    'F11.99 Opioid use, unspecified with unspecified opioid-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F12',
          title: 'F12 Cannabis related disorders',
          children: [
            {
              value: 'F12.1',
              title: 'F12.1 Cannabis abuse',
              children: [
                {
                  value: 'F12.10',
                  title: 'F12.10 Cannabis abuse, uncomplicated',
                },
                {
                  value: 'F12.11',
                  title: 'F12.11 Cannabis abuse, in remission',
                },
                {
                  value: 'F12.12',
                  title: 'F12.12 Cannabis abuse with intoxication',
                  children: [
                    {
                      value: 'F12.120',
                      title:
                        'F12.120 Cannabis abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F12.121',
                      title:
                        'F12.121 Cannabis abuse with intoxication delirium',
                    },
                    {
                      value: 'F12.122',
                      title:
                        'F12.122 Cannabis abuse with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F12.129',
                      title:
                        'F12.129 Cannabis abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.13',
                  title: 'F12.13 Cannabis abuse with withdrawal',
                },
                {
                  value: 'F12.15',
                  title: 'F12.15 Cannabis abuse with psychotic disorder',
                  children: [
                    {
                      value: 'F12.150',
                      title:
                        'F12.150 Cannabis abuse with psychotic disorder with delusions',
                    },
                    {
                      value: 'F12.151',
                      title:
                        'F12.151 Cannabis abuse with psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F12.159',
                      title:
                        'F12.159 Cannabis abuse with psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.18',
                  title:
                    'F12.18 Cannabis abuse with other cannabis-induced disorder',
                  children: [
                    {
                      value: 'F12.180',
                      title:
                        'F12.180 Cannabis abuse with cannabis-induced anxiety disorder',
                    },
                    {
                      value: 'F12.188',
                      title:
                        'F12.188 Cannabis abuse with other cannabis-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F12.19',
                  title:
                    'F12.19 Cannabis abuse with unspecified cannabis-induced disorder',
                },
              ],
            },
            {
              value: 'F12.2',
              title: 'F12.2 Cannabis dependence',
              children: [
                {
                  value: 'F12.20',
                  title: 'F12.20 Cannabis dependence, uncomplicated',
                },
                {
                  value: 'F12.21',
                  title: 'F12.21 Cannabis dependence, in remission',
                },
                {
                  value: 'F12.22',
                  title: 'F12.22 Cannabis dependence with intoxication',
                  children: [
                    {
                      value: 'F12.220',
                      title:
                        'F12.220 Cannabis dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F12.221',
                      title:
                        'F12.221 Cannabis dependence with intoxication delirium',
                    },
                    {
                      value: 'F12.222',
                      title:
                        'F12.222 Cannabis dependence with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F12.229',
                      title:
                        'F12.229 Cannabis dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.23',
                  title: 'F12.23 Cannabis dependence with withdrawal',
                },
                {
                  value: 'F12.25',
                  title: 'F12.25 Cannabis dependence with psychotic disorder',
                  children: [
                    {
                      value: 'F12.250',
                      title:
                        'F12.250 Cannabis dependence with psychotic disorder with delusions',
                    },
                    {
                      value: 'F12.251',
                      title:
                        'F12.251 Cannabis dependence with psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F12.259',
                      title:
                        'F12.259 Cannabis dependence with psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.28',
                  title:
                    'F12.28 Cannabis dependence with other cannabis-induced disorder',
                  children: [
                    {
                      value: 'F12.280',
                      title:
                        'F12.280 Cannabis dependence with cannabis-induced anxiety disorder',
                    },
                    {
                      value: 'F12.288',
                      title:
                        'F12.288 Cannabis dependence with other cannabis-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F12.29',
                  title:
                    'F12.29 Cannabis dependence with unspecified cannabis-induced disorder',
                },
              ],
            },
            {
              value: 'F12.9',
              title: 'F12.9 Cannabis use, unspecified',
              children: [
                {
                  value: 'F12.90',
                  title: 'F12.90 Cannabis use, unspecified, uncomplicated',
                },
                {
                  value: 'F12.91',
                  title: 'F12.91 Cannabis use, unspecified, in remission',
                },
                {
                  value: 'F12.92',
                  title: 'F12.92 Cannabis use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F12.920',
                      title:
                        'F12.920 Cannabis use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F12.921',
                      title:
                        'F12.921 Cannabis use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F12.922',
                      title:
                        'F12.922 Cannabis use, unspecified with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F12.929',
                      title:
                        'F12.929 Cannabis use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.93',
                  title: 'F12.93 Cannabis use, unspecified with withdrawal',
                },
                {
                  value: 'F12.95',
                  title:
                    'F12.95 Cannabis use, unspecified with psychotic disorder',
                  children: [
                    {
                      value: 'F12.950',
                      title:
                        'F12.950 Cannabis use, unspecified with psychotic disorder with delusions',
                    },
                    {
                      value: 'F12.951',
                      title:
                        'F12.951 Cannabis use, unspecified with psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F12.959',
                      title:
                        'F12.959 Cannabis use, unspecified with psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F12.98',
                  title:
                    'F12.98 Cannabis use, unspecified with other cannabis-induced disorder',
                  children: [
                    {
                      value: 'F12.980',
                      title:
                        'F12.980 Cannabis use, unspecified with anxiety disorder',
                    },
                    {
                      value: 'F12.988',
                      title:
                        'F12.988 Cannabis use, unspecified with other cannabis-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F12.99',
                  title:
                    'F12.99 Cannabis use, unspecified with unspecified cannabis-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F13',
          title: 'F13 Sedative, hypnotic, or anxiolytic related disorders',
          children: [
            {
              value: 'F13.1',
              title: 'F13.1 Sedative, hypnotic or anxiolytic-related abuse',
              children: [
                {
                  value: 'F13.10',
                  title:
                    'F13.10 Sedative, hypnotic or anxiolytic abuse, uncomplicated',
                },
                {
                  value: 'F13.11',
                  title:
                    'F13.11 Sedative, hypnotic or anxiolytic abuse, in remission',
                },
                {
                  value: 'F13.12',
                  title:
                    'F13.12 Sedative, hypnotic or anxiolytic abuse with intoxication',
                  children: [
                    {
                      value: 'F13.120',
                      title:
                        'F13.120 Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F13.121',
                      title:
                        'F13.121 Sedative, hypnotic or anxiolytic abuse with intoxication delirium',
                    },
                    {
                      value: 'F13.129',
                      title:
                        'F13.129 Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.13',
                  title:
                    'F13.13 Sedative, hypnotic or anxiolytic abuse with withdrawal',
                  children: [
                    {
                      value: 'F13.130',
                      title:
                        'F13.130 Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F13.131',
                      title:
                        'F13.131 Sedative, hypnotic or anxiolytic abuse with withdrawal delirium',
                    },
                    {
                      value: 'F13.132',
                      title:
                        'F13.132 Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F13.139',
                      title:
                        'F13.139 Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.14',
                  title:
                    'F13.14 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder',
                },
                {
                  value: 'F13.15',
                  title:
                    'F13.15 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder',
                  children: [
                    {
                      value: 'F13.150',
                      title:
                        'F13.150 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F13.151',
                      title:
                        'F13.151 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F13.159',
                      title:
                        'F13.159 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.18',
                  title:
                    'F13.18 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorders',
                  children: [
                    {
                      value: 'F13.180',
                      title:
                        'F13.180 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder',
                    },
                    {
                      value: 'F13.181',
                      title:
                        'F13.181 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
                    },
                    {
                      value: 'F13.182',
                      title:
                        'F13.182 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder',
                    },
                    {
                      value: 'F13.188',
                      title:
                        'F13.188 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F13.19',
                  title:
                    'F13.19 Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder',
                },
              ],
            },
            {
              value: 'F13.2',
              title:
                'F13.2 Sedative, hypnotic or anxiolytic-related dependence',
              children: [
                {
                  value: 'F13.20',
                  title:
                    'F13.20 Sedative, hypnotic or anxiolytic dependence, uncomplicated',
                },
                {
                  value: 'F13.21',
                  title:
                    'F13.21 Sedative, hypnotic or anxiolytic dependence, in remission',
                },
                {
                  value: 'F13.22',
                  title:
                    'F13.22 Sedative, hypnotic or anxiolytic dependence with intoxication',
                  children: [
                    {
                      value: 'F13.220',
                      title:
                        'F13.220 Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F13.221',
                      title:
                        'F13.221 Sedative, hypnotic or anxiolytic dependence with intoxication delirium',
                    },
                    {
                      value: 'F13.229',
                      title:
                        'F13.229 Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.23',
                  title:
                    'F13.23 Sedative, hypnotic or anxiolytic dependence with withdrawal',
                  children: [
                    {
                      value: 'F13.230',
                      title:
                        'F13.230 Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F13.231',
                      title:
                        'F13.231 Sedative, hypnotic or anxiolytic dependence with withdrawal delirium',
                    },
                    {
                      value: 'F13.232',
                      title:
                        'F13.232 Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F13.239',
                      title:
                        'F13.239 Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.24',
                  title:
                    'F13.24 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder',
                },
                {
                  value: 'F13.25',
                  title:
                    'F13.25 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder',
                  children: [
                    {
                      value: 'F13.250',
                      title:
                        'F13.250 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F13.251',
                      title:
                        'F13.251 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F13.259',
                      title:
                        'F13.259 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.26',
                  title:
                    'F13.26 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
                },
                {
                  value: 'F13.27',
                  title:
                    'F13.27 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia',
                },
                {
                  value: 'F13.28',
                  title:
                    'F13.28 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorders',
                  children: [
                    {
                      value: 'F13.280',
                      title:
                        'F13.280 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder',
                    },
                    {
                      value: 'F13.281',
                      title:
                        'F13.281 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
                    },
                    {
                      value: 'F13.282',
                      title:
                        'F13.282 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder',
                    },
                    {
                      value: 'F13.288',
                      title:
                        'F13.288 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F13.29',
                  title:
                    'F13.29 Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder',
                },
              ],
            },
            {
              value: 'F13.9',
              title:
                'F13.9 Sedative, hypnotic or anxiolytic-related use, unspecified',
              children: [
                {
                  value: 'F13.90',
                  title:
                    'F13.90 Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated',
                },
                {
                  value: 'F13.91',
                  title:
                    'F13.91 Sedative, hypnotic or anxiolytic use, unspecified, in remission',
                },
                {
                  value: 'F13.92',
                  title:
                    'F13.92 Sedative, hypnotic or anxiolytic use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F13.920',
                      title:
                        'F13.920 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F13.921',
                      title:
                        'F13.921 Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F13.929',
                      title:
                        'F13.929 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.93',
                  title:
                    'F13.93 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal',
                  children: [
                    {
                      value: 'F13.930',
                      title:
                        'F13.930 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F13.931',
                      title:
                        'F13.931 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium',
                    },
                    {
                      value: 'F13.932',
                      title:
                        'F13.932 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances',
                    },
                    {
                      value: 'F13.939',
                      title:
                        'F13.939 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.94',
                  title:
                    'F13.94 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder',
                },
                {
                  value: 'F13.95',
                  title:
                    'F13.95 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder',
                  children: [
                    {
                      value: 'F13.950',
                      title:
                        'F13.950 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F13.951',
                      title:
                        'F13.951 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F13.959',
                      title:
                        'F13.959 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F13.96',
                  title:
                    'F13.96 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
                },
                {
                  value: 'F13.97',
                  title:
                    'F13.97 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia',
                },
                {
                  value: 'F13.98',
                  title:
                    'F13.98 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorders',
                  children: [
                    {
                      value: 'F13.980',
                      title:
                        'F13.980 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder',
                    },
                    {
                      value: 'F13.981',
                      title:
                        'F13.981 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
                    },
                    {
                      value: 'F13.982',
                      title:
                        'F13.982 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder',
                    },
                    {
                      value: 'F13.988',
                      title:
                        'F13.988 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F13.99',
                  title:
                    'F13.99 Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F14',
          title: 'F14 Cocaine related disorders',
          children: [
            {
              value: 'F14.1',
              title: 'F14.1 Cocaine abuse',
              children: [
                {
                  value: 'F14.10',
                  title: 'F14.10 Cocaine abuse, uncomplicated',
                },
                {
                  value: 'F14.11',
                  title: 'F14.11 Cocaine abuse, in remission',
                },
                {
                  value: 'F14.12',
                  title: 'F14.12 Cocaine abuse with intoxication',
                  children: [
                    {
                      value: 'F14.120',
                      title:
                        'F14.120 Cocaine abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F14.121',
                      title:
                        'F14.121 Cocaine abuse with intoxication with delirium',
                    },
                    {
                      value: 'F14.122',
                      title:
                        'F14.122 Cocaine abuse with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F14.129',
                      title:
                        'F14.129 Cocaine abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.13',
                  title: 'F14.13 Cocaine abuse, unspecified with withdrawal',
                },
                {
                  value: 'F14.14',
                  title:
                    'F14.14 Cocaine abuse with cocaine-induced mood disorder',
                },
                {
                  value: 'F14.15',
                  title:
                    'F14.15 Cocaine abuse with cocaine-induced psychotic disorder',
                  children: [
                    {
                      value: 'F14.150',
                      title:
                        'F14.150 Cocaine abuse with cocaine-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F14.151',
                      title:
                        'F14.151 Cocaine abuse with cocaine-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F14.159',
                      title:
                        'F14.159 Cocaine abuse with cocaine-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.18',
                  title:
                    'F14.18 Cocaine abuse with other cocaine-induced disorder',
                  children: [
                    {
                      value: 'F14.180',
                      title:
                        'F14.180 Cocaine abuse with cocaine-induced anxiety disorder',
                    },
                    {
                      value: 'F14.181',
                      title:
                        'F14.181 Cocaine abuse with cocaine-induced sexual dysfunction',
                    },
                    {
                      value: 'F14.182',
                      title:
                        'F14.182 Cocaine abuse with cocaine-induced sleep disorder',
                    },
                    {
                      value: 'F14.188',
                      title:
                        'F14.188 Cocaine abuse with other cocaine-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F14.19',
                  title:
                    'F14.19 Cocaine abuse with unspecified cocaine-induced disorder',
                },
              ],
            },
            {
              value: 'F14.2',
              title: 'F14.2 Cocaine dependence',
              children: [
                {
                  value: 'F14.20',
                  title: 'F14.20 Cocaine dependence, uncomplicated',
                },
                {
                  value: 'F14.21',
                  title: 'F14.21 Cocaine dependence, in remission',
                },
                {
                  value: 'F14.22',
                  title: 'F14.22 Cocaine dependence with intoxication',
                  children: [
                    {
                      value: 'F14.220',
                      title:
                        'F14.220 Cocaine dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F14.221',
                      title:
                        'F14.221 Cocaine dependence with intoxication delirium',
                    },
                    {
                      value: 'F14.222',
                      title:
                        'F14.222 Cocaine dependence with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F14.229',
                      title:
                        'F14.229 Cocaine dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.23',
                  title: 'F14.23 Cocaine dependence with withdrawal',
                },
                {
                  value: 'F14.24',
                  title:
                    'F14.24 Cocaine dependence with cocaine-induced mood disorder',
                },
                {
                  value: 'F14.25',
                  title:
                    'F14.25 Cocaine dependence with cocaine-induced psychotic disorder',
                  children: [
                    {
                      value: 'F14.250',
                      title:
                        'F14.250 Cocaine dependence with cocaine-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F14.251',
                      title:
                        'F14.251 Cocaine dependence with cocaine-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F14.259',
                      title:
                        'F14.259 Cocaine dependence with cocaine-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.28',
                  title:
                    'F14.28 Cocaine dependence with other cocaine-induced disorder',
                  children: [
                    {
                      value: 'F14.280',
                      title:
                        'F14.280 Cocaine dependence with cocaine-induced anxiety disorder',
                    },
                    {
                      value: 'F14.281',
                      title:
                        'F14.281 Cocaine dependence with cocaine-induced sexual dysfunction',
                    },
                    {
                      value: 'F14.282',
                      title:
                        'F14.282 Cocaine dependence with cocaine-induced sleep disorder',
                    },
                    {
                      value: 'F14.288',
                      title:
                        'F14.288 Cocaine dependence with other cocaine-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F14.29',
                  title:
                    'F14.29 Cocaine dependence with unspecified cocaine-induced disorder',
                },
              ],
            },
            {
              value: 'F14.9',
              title: 'F14.9 Cocaine use, unspecified',
              children: [
                {
                  value: 'F14.90',
                  title: 'F14.90 Cocaine use, unspecified, uncomplicated',
                },
                {
                  value: 'F14.91',
                  title: 'F14.91 Cocaine use, unspecified, in remission',
                },
                {
                  value: 'F14.92',
                  title: 'F14.92 Cocaine use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F14.920',
                      title:
                        'F14.920 Cocaine use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F14.921',
                      title:
                        'F14.921 Cocaine use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F14.922',
                      title:
                        'F14.922 Cocaine use, unspecified with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F14.929',
                      title:
                        'F14.929 Cocaine use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.93',
                  title: 'F14.93 Cocaine use, unspecified with withdrawal',
                },
                {
                  value: 'F14.94',
                  title:
                    'F14.94 Cocaine use, unspecified with cocaine-induced mood disorder',
                },
                {
                  value: 'F14.95',
                  title:
                    'F14.95 Cocaine use, unspecified with cocaine-induced psychotic disorder',
                  children: [
                    {
                      value: 'F14.950',
                      title:
                        'F14.950 Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F14.951',
                      title:
                        'F14.951 Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F14.959',
                      title:
                        'F14.959 Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F14.98',
                  title:
                    'F14.98 Cocaine use, unspecified with other specified cocaine-induced disorder',
                  children: [
                    {
                      value: 'F14.980',
                      title:
                        'F14.980 Cocaine use, unspecified with cocaine-induced anxiety disorder',
                    },
                    {
                      value: 'F14.981',
                      title:
                        'F14.981 Cocaine use, unspecified with cocaine-induced sexual dysfunction',
                    },
                    {
                      value: 'F14.982',
                      title:
                        'F14.982 Cocaine use, unspecified with cocaine-induced sleep disorder',
                    },
                    {
                      value: 'F14.988',
                      title:
                        'F14.988 Cocaine use, unspecified with other cocaine-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F14.99',
                  title:
                    'F14.99 Cocaine use, unspecified with unspecified cocaine-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F15',
          title: 'F15 Other stimulant related disorders',
          children: [
            {
              value: 'F15.1',
              title: 'F15.1 Other stimulant abuse',
              children: [
                {
                  value: 'F15.10',
                  title: 'F15.10 Other stimulant abuse, uncomplicated',
                },
                {
                  value: 'F15.11',
                  title: 'F15.11 Other stimulant abuse, in remission',
                },
                {
                  value: 'F15.12',
                  title: 'F15.12 Other stimulant abuse with intoxication',
                  children: [
                    {
                      value: 'F15.120',
                      title:
                        'F15.120 Other stimulant abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F15.121',
                      title:
                        'F15.121 Other stimulant abuse with intoxication delirium',
                    },
                    {
                      value: 'F15.122',
                      title:
                        'F15.122 Other stimulant abuse with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F15.129',
                      title:
                        'F15.129 Other stimulant abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.13',
                  title: 'F15.13 Other stimulant abuse with withdrawal',
                },
                {
                  value: 'F15.14',
                  title:
                    'F15.14 Other stimulant abuse with stimulant-induced mood disorder',
                },
                {
                  value: 'F15.15',
                  title:
                    'F15.15 Other stimulant abuse with stimulant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F15.150',
                      title:
                        'F15.150 Other stimulant abuse with stimulant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F15.151',
                      title:
                        'F15.151 Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F15.159',
                      title:
                        'F15.159 Other stimulant abuse with stimulant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.18',
                  title:
                    'F15.18 Other stimulant abuse with other stimulant-induced disorder',
                  children: [
                    {
                      value: 'F15.180',
                      title:
                        'F15.180 Other stimulant abuse with stimulant-induced anxiety disorder',
                    },
                    {
                      value: 'F15.181',
                      title:
                        'F15.181 Other stimulant abuse with stimulant-induced sexual dysfunction',
                    },
                    {
                      value: 'F15.182',
                      title:
                        'F15.182 Other stimulant abuse with stimulant-induced sleep disorder',
                    },
                    {
                      value: 'F15.188',
                      title:
                        'F15.188 Other stimulant abuse with other stimulant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F15.19',
                  title:
                    'F15.19 Other stimulant abuse with unspecified stimulant-induced disorder',
                },
              ],
            },
            {
              value: 'F15.2',
              title: 'F15.2 Other stimulant dependence',
              children: [
                {
                  value: 'F15.20',
                  title: 'F15.20 Other stimulant dependence, uncomplicated',
                },
                {
                  value: 'F15.21',
                  title: 'F15.21 Other stimulant dependence, in remission',
                },
                {
                  value: 'F15.22',
                  title: 'F15.22 Other stimulant dependence with intoxication',
                  children: [
                    {
                      value: 'F15.220',
                      title:
                        'F15.220 Other stimulant dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F15.221',
                      title:
                        'F15.221 Other stimulant dependence with intoxication delirium',
                    },
                    {
                      value: 'F15.222',
                      title:
                        'F15.222 Other stimulant dependence with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F15.229',
                      title:
                        'F15.229 Other stimulant dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.23',
                  title: 'F15.23 Other stimulant dependence with withdrawal',
                },
                {
                  value: 'F15.24',
                  title:
                    'F15.24 Other stimulant dependence with stimulant-induced mood disorder',
                },
                {
                  value: 'F15.25',
                  title:
                    'F15.25 Other stimulant dependence with stimulant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F15.250',
                      title:
                        'F15.250 Other stimulant dependence with stimulant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F15.251',
                      title:
                        'F15.251 Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F15.259',
                      title:
                        'F15.259 Other stimulant dependence with stimulant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.28',
                  title:
                    'F15.28 Other stimulant dependence with other stimulant-induced disorder',
                  children: [
                    {
                      value: 'F15.280',
                      title:
                        'F15.280 Other stimulant dependence with stimulant-induced anxiety disorder',
                    },
                    {
                      value: 'F15.281',
                      title:
                        'F15.281 Other stimulant dependence with stimulant-induced sexual dysfunction',
                    },
                    {
                      value: 'F15.282',
                      title:
                        'F15.282 Other stimulant dependence with stimulant-induced sleep disorder',
                    },
                    {
                      value: 'F15.288',
                      title:
                        'F15.288 Other stimulant dependence with other stimulant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F15.29',
                  title:
                    'F15.29 Other stimulant dependence with unspecified stimulant-induced disorder',
                },
              ],
            },
            {
              value: 'F15.9',
              title: 'F15.9 Other stimulant use, unspecified',
              children: [
                {
                  value: 'F15.90',
                  title:
                    'F15.90 Other stimulant use, unspecified, uncomplicated',
                },
                {
                  value: 'F15.91',
                  title:
                    'F15.91 Other stimulant use, unspecified, in remission',
                },
                {
                  value: 'F15.92',
                  title:
                    'F15.92 Other stimulant use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F15.920',
                      title:
                        'F15.920 Other stimulant use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F15.921',
                      title:
                        'F15.921 Other stimulant use, unspecified with intoxication delirium',
                    },
                    {
                      value: 'F15.922',
                      title:
                        'F15.922 Other stimulant use, unspecified with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F15.929',
                      title:
                        'F15.929 Other stimulant use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.93',
                  title:
                    'F15.93 Other stimulant use, unspecified with withdrawal',
                },
                {
                  value: 'F15.94',
                  title:
                    'F15.94 Other stimulant use, unspecified with stimulant-induced mood disorder',
                },
                {
                  value: 'F15.95',
                  title:
                    'F15.95 Other stimulant use, unspecified with stimulant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F15.950',
                      title:
                        'F15.950 Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F15.951',
                      title:
                        'F15.951 Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F15.959',
                      title:
                        'F15.959 Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F15.98',
                  title:
                    'F15.98 Other stimulant use, unspecified with other stimulant-induced disorder',
                  children: [
                    {
                      value: 'F15.980',
                      title:
                        'F15.980 Other stimulant use, unspecified with stimulant-induced anxiety disorder',
                    },
                    {
                      value: 'F15.981',
                      title:
                        'F15.981 Other stimulant use, unspecified with stimulant-induced sexual dysfunction',
                    },
                    {
                      value: 'F15.982',
                      title:
                        'F15.982 Other stimulant use, unspecified with stimulant-induced sleep disorder',
                    },
                    {
                      value: 'F15.988',
                      title:
                        'F15.988 Other stimulant use, unspecified with other stimulant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F15.99',
                  title:
                    'F15.99 Other stimulant use, unspecified with unspecified stimulant-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F16',
          title: 'F16 Hallucinogen related disorders',
          children: [
            {
              value: 'F16.1',
              title: 'F16.1 Hallucinogen abuse',
              children: [
                {
                  value: 'F16.10',
                  title: 'F16.10 Hallucinogen abuse, uncomplicated',
                },
                {
                  value: 'F16.11',
                  title: 'F16.11 Hallucinogen abuse, in remission',
                },
                {
                  value: 'F16.12',
                  title: 'F16.12 Hallucinogen abuse with intoxication',
                  children: [
                    {
                      value: 'F16.120',
                      title:
                        'F16.120 Hallucinogen abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F16.121',
                      title:
                        'F16.121 Hallucinogen abuse with intoxication with delirium',
                    },
                    {
                      value: 'F16.122',
                      title:
                        'F16.122 Hallucinogen abuse with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F16.129',
                      title:
                        'F16.129 Hallucinogen abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.14',
                  title:
                    'F16.14 Hallucinogen abuse with hallucinogen-induced mood disorder',
                },
                {
                  value: 'F16.15',
                  title:
                    'F16.15 Hallucinogen abuse with hallucinogen-induced psychotic disorder',
                  children: [
                    {
                      value: 'F16.150',
                      title:
                        'F16.150 Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F16.151',
                      title:
                        'F16.151 Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F16.159',
                      title:
                        'F16.159 Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.18',
                  title:
                    'F16.18 Hallucinogen abuse with other hallucinogen-induced disorder',
                  children: [
                    {
                      value: 'F16.180',
                      title:
                        'F16.180 Hallucinogen abuse with hallucinogen-induced anxiety disorder',
                    },
                    {
                      value: 'F16.183',
                      title:
                        'F16.183 Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)',
                    },
                    {
                      value: 'F16.188',
                      title:
                        'F16.188 Hallucinogen abuse with other hallucinogen-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F16.19',
                  title:
                    'F16.19 Hallucinogen abuse with unspecified hallucinogen-induced disorder',
                },
              ],
            },
            {
              value: 'F16.2',
              title: 'F16.2 Hallucinogen dependence',
              children: [
                {
                  value: 'F16.20',
                  title: 'F16.20 Hallucinogen dependence, uncomplicated',
                },
                {
                  value: 'F16.21',
                  title: 'F16.21 Hallucinogen dependence, in remission',
                },
                {
                  value: 'F16.22',
                  title: 'F16.22 Hallucinogen dependence with intoxication',
                  children: [
                    {
                      value: 'F16.220',
                      title:
                        'F16.220 Hallucinogen dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F16.221',
                      title:
                        'F16.221 Hallucinogen dependence with intoxication with delirium',
                    },
                    {
                      value: 'F16.229',
                      title:
                        'F16.229 Hallucinogen dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.24',
                  title:
                    'F16.24 Hallucinogen dependence with hallucinogen-induced mood disorder',
                },
                {
                  value: 'F16.25',
                  title:
                    'F16.25 Hallucinogen dependence with hallucinogen-induced psychotic disorder',
                  children: [
                    {
                      value: 'F16.250',
                      title:
                        'F16.250 Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F16.251',
                      title:
                        'F16.251 Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F16.259',
                      title:
                        'F16.259 Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.28',
                  title:
                    'F16.28 Hallucinogen dependence with other hallucinogen-induced disorder',
                  children: [
                    {
                      value: 'F16.280',
                      title:
                        'F16.280 Hallucinogen dependence with hallucinogen-induced anxiety disorder',
                    },
                    {
                      value: 'F16.283',
                      title:
                        'F16.283 Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)',
                    },
                    {
                      value: 'F16.288',
                      title:
                        'F16.288 Hallucinogen dependence with other hallucinogen-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F16.29',
                  title:
                    'F16.29 Hallucinogen dependence with unspecified hallucinogen-induced disorder',
                },
              ],
            },
            {
              value: 'F16.9',
              title: 'F16.9 Hallucinogen use, unspecified',
              children: [
                {
                  value: 'F16.90',
                  title: 'F16.90 Hallucinogen use, unspecified, uncomplicated',
                },
                {
                  value: 'F16.91',
                  title: 'F16.91 Hallucinogen use, unspecified, in remission',
                },
                {
                  value: 'F16.92',
                  title:
                    'F16.92 Hallucinogen use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F16.920',
                      title:
                        'F16.920 Hallucinogen use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F16.921',
                      title:
                        'F16.921 Hallucinogen use, unspecified with intoxication with delirium',
                    },
                    {
                      value: 'F16.929',
                      title:
                        'F16.929 Hallucinogen use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.94',
                  title:
                    'F16.94 Hallucinogen use, unspecified with hallucinogen-induced mood disorder',
                },
                {
                  value: 'F16.95',
                  title:
                    'F16.95 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder',
                  children: [
                    {
                      value: 'F16.950',
                      title:
                        'F16.950 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F16.951',
                      title:
                        'F16.951 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F16.959',
                      title:
                        'F16.959 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F16.98',
                  title:
                    'F16.98 Hallucinogen use, unspecified with other specified hallucinogen-induced disorder',
                  children: [
                    {
                      value: 'F16.980',
                      title:
                        'F16.980 Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder',
                    },
                    {
                      value: 'F16.983',
                      title:
                        'F16.983 Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)',
                    },
                    {
                      value: 'F16.988',
                      title:
                        'F16.988 Hallucinogen use, unspecified with other hallucinogen-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F16.99',
                  title:
                    'F16.99 Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F17',
          title: 'F17 Nicotine dependence',
          children: [
            {
              value: 'F17.2',
              title: 'F17.2 Nicotine dependence',
              children: [
                {
                  value: 'F17.20',
                  title: 'F17.20 Nicotine dependence, unspecified',
                  children: [
                    {
                      value: 'F17.200',
                      title:
                        'F17.200 Nicotine dependence, unspecified, uncomplicated',
                    },
                    {
                      value: 'F17.201',
                      title:
                        'F17.201 Nicotine dependence, unspecified, in remission',
                    },
                    {
                      value: 'F17.203',
                      title:
                        'F17.203 Nicotine dependence unspecified, with withdrawal',
                    },
                    {
                      value: 'F17.208',
                      title:
                        'F17.208 Nicotine dependence, unspecified, with other nicotine-induced disorders',
                    },
                    {
                      value: 'F17.209',
                      title:
                        'F17.209 Nicotine dependence, unspecified, with unspecified nicotine-induced disorders',
                    },
                  ],
                },
                {
                  value: 'F17.21',
                  title: 'F17.21 Nicotine dependence, cigarettes',
                  children: [
                    {
                      value: 'F17.210',
                      title:
                        'F17.210 Nicotine dependence, cigarettes, uncomplicated',
                    },
                    {
                      value: 'F17.211',
                      title:
                        'F17.211 Nicotine dependence, cigarettes, in remission',
                    },
                    {
                      value: 'F17.213',
                      title:
                        'F17.213 Nicotine dependence, cigarettes, with withdrawal',
                    },
                    {
                      value: 'F17.218',
                      title:
                        'F17.218 Nicotine dependence, cigarettes, with other nicotine-induced disorders',
                    },
                    {
                      value: 'F17.219',
                      title:
                        'F17.219 Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders',
                    },
                  ],
                },
                {
                  value: 'F17.22',
                  title: 'F17.22 Nicotine dependence, chewing tobacco',
                  children: [
                    {
                      value: 'F17.220',
                      title:
                        'F17.220 Nicotine dependence, chewing tobacco, uncomplicated',
                    },
                    {
                      value: 'F17.221',
                      title:
                        'F17.221 Nicotine dependence, chewing tobacco, in remission',
                    },
                    {
                      value: 'F17.223',
                      title:
                        'F17.223 Nicotine dependence, chewing tobacco, with withdrawal',
                    },
                    {
                      value: 'F17.228',
                      title:
                        'F17.228 Nicotine dependence, chewing tobacco, with other nicotine-induced disorders',
                    },
                    {
                      value: 'F17.229',
                      title:
                        'F17.229 Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders',
                    },
                  ],
                },
                {
                  value: 'F17.29',
                  title: 'F17.29 Nicotine dependence, other tobacco product',
                  children: [
                    {
                      value: 'F17.290',
                      title:
                        'F17.290 Nicotine dependence, other tobacco product, uncomplicated',
                    },
                    {
                      value: 'F17.291',
                      title:
                        'F17.291 Nicotine dependence, other tobacco product, in remission',
                    },
                    {
                      value: 'F17.293',
                      title:
                        'F17.293 Nicotine dependence, other tobacco product, with withdrawal',
                    },
                    {
                      value: 'F17.298',
                      title:
                        'F17.298 Nicotine dependence, other tobacco product, with other nicotine-induced disorders',
                    },
                    {
                      value: 'F17.299',
                      title:
                        'F17.299 Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          value: 'F18',
          title: 'F18 Inhalant related disorders',
          children: [
            {
              value: 'F18.1',
              title: 'F18.1 Inhalant abuse',
              children: [
                {
                  value: 'F18.10',
                  title: 'F18.10 Inhalant abuse, uncomplicated',
                },
                {
                  value: 'F18.11',
                  title: 'F18.11 Inhalant abuse, in remission',
                },
                {
                  value: 'F18.12',
                  title: 'F18.12 Inhalant abuse with intoxication',
                  children: [
                    {
                      value: 'F18.120',
                      title:
                        'F18.120 Inhalant abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F18.121',
                      title:
                        'F18.121 Inhalant abuse with intoxication delirium',
                    },
                    {
                      value: 'F18.129',
                      title:
                        'F18.129 Inhalant abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.14',
                  title:
                    'F18.14 Inhalant abuse with inhalant-induced mood disorder',
                },
                {
                  value: 'F18.15',
                  title:
                    'F18.15 Inhalant abuse with inhalant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F18.150',
                      title:
                        'F18.150 Inhalant abuse with inhalant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F18.151',
                      title:
                        'F18.151 Inhalant abuse with inhalant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F18.159',
                      title:
                        'F18.159 Inhalant abuse with inhalant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.17',
                  title: 'F18.17 Inhalant abuse with inhalant-induced dementia',
                },
                {
                  value: 'F18.18',
                  title:
                    'F18.18 Inhalant abuse with other inhalant-induced disorders',
                  children: [
                    {
                      value: 'F18.180',
                      title:
                        'F18.180 Inhalant abuse with inhalant-induced anxiety disorder',
                    },
                    {
                      value: 'F18.188',
                      title:
                        'F18.188 Inhalant abuse with other inhalant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F18.19',
                  title:
                    'F18.19 Inhalant abuse with unspecified inhalant-induced disorder',
                },
              ],
            },
            {
              value: 'F18.2',
              title: 'F18.2 Inhalant dependence',
              children: [
                {
                  value: 'F18.20',
                  title: 'F18.20 Inhalant dependence, uncomplicated',
                },
                {
                  value: 'F18.21',
                  title: 'F18.21 Inhalant dependence, in remission',
                },
                {
                  value: 'F18.22',
                  title: 'F18.22 Inhalant dependence with intoxication',
                  children: [
                    {
                      value: 'F18.220',
                      title:
                        'F18.220 Inhalant dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F18.221',
                      title:
                        'F18.221 Inhalant dependence with intoxication delirium',
                    },
                    {
                      value: 'F18.229',
                      title:
                        'F18.229 Inhalant dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.24',
                  title:
                    'F18.24 Inhalant dependence with inhalant-induced mood disorder',
                },
                {
                  value: 'F18.25',
                  title:
                    'F18.25 Inhalant dependence with inhalant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F18.250',
                      title:
                        'F18.250 Inhalant dependence with inhalant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F18.251',
                      title:
                        'F18.251 Inhalant dependence with inhalant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F18.259',
                      title:
                        'F18.259 Inhalant dependence with inhalant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.27',
                  title:
                    'F18.27 Inhalant dependence with inhalant-induced dementia',
                },
                {
                  value: 'F18.28',
                  title:
                    'F18.28 Inhalant dependence with other inhalant-induced disorders',
                  children: [
                    {
                      value: 'F18.280',
                      title:
                        'F18.280 Inhalant dependence with inhalant-induced anxiety disorder',
                    },
                    {
                      value: 'F18.288',
                      title:
                        'F18.288 Inhalant dependence with other inhalant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F18.29',
                  title:
                    'F18.29 Inhalant dependence with unspecified inhalant-induced disorder',
                },
              ],
            },
            {
              value: 'F18.9',
              title: 'F18.9 Inhalant use, unspecified',
              children: [
                {
                  value: 'F18.90',
                  title: 'F18.90 Inhalant use, unspecified, uncomplicated',
                },
                {
                  value: 'F18.91',
                  title: 'F18.91 Inhalant use, unspecified, in remission',
                },
                {
                  value: 'F18.92',
                  title: 'F18.92 Inhalant use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F18.920',
                      title:
                        'F18.920 Inhalant use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F18.921',
                      title:
                        'F18.921 Inhalant use, unspecified with intoxication with delirium',
                    },
                    {
                      value: 'F18.929',
                      title:
                        'F18.929 Inhalant use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.94',
                  title:
                    'F18.94 Inhalant use, unspecified with inhalant-induced mood disorder',
                },
                {
                  value: 'F18.95',
                  title:
                    'F18.95 Inhalant use, unspecified with inhalant-induced psychotic disorder',
                  children: [
                    {
                      value: 'F18.950',
                      title:
                        'F18.950 Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F18.951',
                      title:
                        'F18.951 Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F18.959',
                      title:
                        'F18.959 Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F18.97',
                  title:
                    'F18.97 Inhalant use, unspecified with inhalant-induced persisting dementia',
                },
                {
                  value: 'F18.98',
                  title:
                    'F18.98 Inhalant use, unspecified with other inhalant-induced disorders',
                  children: [
                    {
                      value: 'F18.980',
                      title:
                        'F18.980 Inhalant use, unspecified with inhalant-induced anxiety disorder',
                    },
                    {
                      value: 'F18.988',
                      title:
                        'F18.988 Inhalant use, unspecified with other inhalant-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F18.99',
                  title:
                    'F18.99 Inhalant use, unspecified with unspecified inhalant-induced disorder',
                },
              ],
            },
          ],
        },
        {
          value: 'F19',
          title: 'F19 Other psychoactive substance related disorders',
          children: [
            {
              value: 'F19.1',
              title: 'F19.1 Other psychoactive substance abuse',
              children: [
                {
                  value: 'F19.10',
                  title:
                    'F19.10 Other psychoactive substance abuse, uncomplicated',
                },
                {
                  value: 'F19.11',
                  title:
                    'F19.11 Other psychoactive substance abuse, in remission',
                },
                {
                  value: 'F19.12',
                  title:
                    'F19.12 Other psychoactive substance abuse with intoxication',
                  children: [
                    {
                      value: 'F19.120',
                      title:
                        'F19.120 Other psychoactive substance abuse with intoxication, uncomplicated',
                    },
                    {
                      value: 'F19.121',
                      title:
                        'F19.121 Other psychoactive substance abuse with intoxication delirium',
                    },
                    {
                      value: 'F19.122',
                      title:
                        'F19.122 Other psychoactive substance abuse with intoxication with perceptual disturbances',
                    },
                    {
                      value: 'F19.129',
                      title:
                        'F19.129 Other psychoactive substance abuse with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.13',
                  title:
                    'F19.13 Other psychoactive substance abuse with withdrawal',
                  children: [
                    {
                      value: 'F19.130',
                      title:
                        'F19.130 Other psychoactive substance abuse with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F19.131',
                      title:
                        'F19.131 Other psychoactive substance abuse with withdrawal delirium',
                    },
                    {
                      value: 'F19.132',
                      title:
                        'F19.132 Other psychoactive substance abuse with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F19.139',
                      title:
                        'F19.139 Other psychoactive substance abuse with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.14',
                  title:
                    'F19.14 Other psychoactive substance abuse with psychoactive substance-induced mood disorder',
                },
                {
                  value: 'F19.15',
                  title:
                    'F19.15 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder',
                  children: [
                    {
                      value: 'F19.150',
                      title:
                        'F19.150 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F19.151',
                      title:
                        'F19.151 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F19.159',
                      title:
                        'F19.159 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.16',
                  title:
                    'F19.16 Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder',
                },
                {
                  value: 'F19.17',
                  title:
                    'F19.17 Other psychoactive substance abuse with psychoactive substance-induced persisting dementia',
                },
                {
                  value: 'F19.18',
                  title:
                    'F19.18 Other psychoactive substance abuse with other psychoactive substance-induced disorders',
                  children: [
                    {
                      value: 'F19.180',
                      title:
                        'F19.180 Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder',
                    },
                    {
                      value: 'F19.181',
                      title:
                        'F19.181 Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction',
                    },
                    {
                      value: 'F19.182',
                      title:
                        'F19.182 Other psychoactive substance abuse with psychoactive substance-induced sleep disorder',
                    },
                    {
                      value: 'F19.188',
                      title:
                        'F19.188 Other psychoactive substance abuse with other psychoactive substance-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F19.19',
                  title:
                    'F19.19 Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder',
                },
              ],
            },
            {
              value: 'F19.2',
              title: 'F19.2 Other psychoactive substance dependence',
              children: [
                {
                  value: 'F19.20',
                  title:
                    'F19.20 Other psychoactive substance dependence, uncomplicated',
                },
                {
                  value: 'F19.21',
                  title:
                    'F19.21 Other psychoactive substance dependence, in remission',
                },
                {
                  value: 'F19.22',
                  title:
                    'F19.22 Other psychoactive substance dependence with intoxication',
                  children: [
                    {
                      value: 'F19.220',
                      title:
                        'F19.220 Other psychoactive substance dependence with intoxication, uncomplicated',
                    },
                    {
                      value: 'F19.221',
                      title:
                        'F19.221 Other psychoactive substance dependence with intoxication delirium',
                    },
                    {
                      value: 'F19.222',
                      title:
                        'F19.222 Other psychoactive substance dependence with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F19.229',
                      title:
                        'F19.229 Other psychoactive substance dependence with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.23',
                  title:
                    'F19.23 Other psychoactive substance dependence with withdrawal',
                  children: [
                    {
                      value: 'F19.230',
                      title:
                        'F19.230 Other psychoactive substance dependence with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F19.231',
                      title:
                        'F19.231 Other psychoactive substance dependence with withdrawal delirium',
                    },
                    {
                      value: 'F19.232',
                      title:
                        'F19.232 Other psychoactive substance dependence with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F19.239',
                      title:
                        'F19.239 Other psychoactive substance dependence with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.24',
                  title:
                    'F19.24 Other psychoactive substance dependence with psychoactive substance-induced mood disorder',
                },
                {
                  value: 'F19.25',
                  title:
                    'F19.25 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder',
                  children: [
                    {
                      value: 'F19.250',
                      title:
                        'F19.250 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F19.251',
                      title:
                        'F19.251 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F19.259',
                      title:
                        'F19.259 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.26',
                  title:
                    'F19.26 Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder',
                },
                {
                  value: 'F19.27',
                  title:
                    'F19.27 Other psychoactive substance dependence with psychoactive substance-induced persisting dementia',
                },
                {
                  value: 'F19.28',
                  title:
                    'F19.28 Other psychoactive substance dependence with other psychoactive substance-induced disorders',
                  children: [
                    {
                      value: 'F19.280',
                      title:
                        'F19.280 Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder',
                    },
                    {
                      value: 'F19.281',
                      title:
                        'F19.281 Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction',
                    },
                    {
                      value: 'F19.282',
                      title:
                        'F19.282 Other psychoactive substance dependence with psychoactive substance-induced sleep disorder',
                    },
                    {
                      value: 'F19.288',
                      title:
                        'F19.288 Other psychoactive substance dependence with other psychoactive substance-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F19.29',
                  title:
                    'F19.29 Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder',
                },
              ],
            },
            {
              value: 'F19.9',
              title: 'F19.9 Other psychoactive substance use, unspecified',
              children: [
                {
                  value: 'F19.90',
                  title:
                    'F19.90 Other psychoactive substance use, unspecified, uncomplicated',
                },
                {
                  value: 'F19.91',
                  title:
                    'F19.91 Other psychoactive substance use, unspecified, in remission',
                },
                {
                  value: 'F19.92',
                  title:
                    'F19.92 Other psychoactive substance use, unspecified with intoxication',
                  children: [
                    {
                      value: 'F19.920',
                      title:
                        'F19.920 Other psychoactive substance use, unspecified with intoxication, uncomplicated',
                    },
                    {
                      value: 'F19.921',
                      title:
                        'F19.921 Other psychoactive substance use, unspecified with intoxication with delirium',
                    },
                    {
                      value: 'F19.922',
                      title:
                        'F19.922 Other psychoactive substance use, unspecified with intoxication with perceptual disturbance',
                    },
                    {
                      value: 'F19.929',
                      title:
                        'F19.929 Other psychoactive substance use, unspecified with intoxication, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.93',
                  title:
                    'F19.93 Other psychoactive substance use, unspecified with withdrawal',
                  children: [
                    {
                      value: 'F19.930',
                      title:
                        'F19.930 Other psychoactive substance use, unspecified with withdrawal, uncomplicated',
                    },
                    {
                      value: 'F19.931',
                      title:
                        'F19.931 Other psychoactive substance use, unspecified with withdrawal delirium',
                    },
                    {
                      value: 'F19.932',
                      title:
                        'F19.932 Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance',
                    },
                    {
                      value: 'F19.939',
                      title:
                        'F19.939 Other psychoactive substance use, unspecified with withdrawal, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.94',
                  title:
                    'F19.94 Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder',
                },
                {
                  value: 'F19.95',
                  title:
                    'F19.95 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder',
                  children: [
                    {
                      value: 'F19.950',
                      title:
                        'F19.950 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions',
                    },
                    {
                      value: 'F19.951',
                      title:
                        'F19.951 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations',
                    },
                    {
                      value: 'F19.959',
                      title:
                        'F19.959 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified',
                    },
                  ],
                },
                {
                  value: 'F19.96',
                  title:
                    'F19.96 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder',
                },
                {
                  value: 'F19.97',
                  title:
                    'F19.97 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia',
                },
                {
                  value: 'F19.98',
                  title:
                    'F19.98 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorders',
                  children: [
                    {
                      value: 'F19.980',
                      title:
                        'F19.980 Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder',
                    },
                    {
                      value: 'F19.981',
                      title:
                        'F19.981 Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction',
                    },
                    {
                      value: 'F19.982',
                      title:
                        'F19.982 Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder',
                    },
                    {
                      value: 'F19.988',
                      title:
                        'F19.988 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder',
                    },
                  ],
                },
                {
                  value: 'F19.99',
                  title:
                    'F19.99 Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'F20-F29',
      title:
        'F20-F29 Schizophrenia, schizotypal, delusional, and other non-mood psychotic disorders',
      children: [
        {
          value: 'F20',
          title: 'F20 Schizophrenia',
          children: [
            {
              value: 'F20.0',
              title: 'F20.0 Paranoid schizophrenia',
            },
            {
              value: 'F20.1',
              title: 'F20.1 Disorganized schizophrenia',
            },
            {
              value: 'F20.2',
              title: 'F20.2 Catatonic schizophrenia',
            },
            {
              value: 'F20.3',
              title: 'F20.3 Undifferentiated schizophrenia',
            },
            {
              value: 'F20.5',
              title: 'F20.5 Residual schizophrenia',
            },
            {
              value: 'F20.8',
              title: 'F20.8 Other schizophrenia',
              children: [
                {
                  value: 'F20.81',
                  title: 'F20.81 Schizophreniform disorder',
                },
                {
                  value: 'F20.89',
                  title: 'F20.89 Other schizophrenia',
                },
              ],
            },
            {
              value: 'F20.9',
              title: 'F20.9 Schizophrenia, unspecified',
            },
          ],
        },
        {
          value: 'F21',
          title: 'F21 Schizotypal disorder',
        },
        {
          value: 'F22',
          title: 'F22 Delusional disorders',
        },
        {
          value: 'F23',
          title: 'F23 Brief psychotic disorder',
        },
        {
          value: 'F24',
          title: 'F24 Shared psychotic disorder',
        },
        {
          value: 'F25',
          title: 'F25 Schizoaffective disorders',
          children: [
            {
              value: 'F25.0',
              title: 'F25.0 Schizoaffective disorder, bipolar type',
            },
            {
              value: 'F25.1',
              title: 'F25.1 Schizoaffective disorder, depressive type',
            },
            {
              value: 'F25.8',
              title: 'F25.8 Other schizoaffective disorders',
            },
            {
              value: 'F25.9',
              title: 'F25.9 Schizoaffective disorder, unspecified',
            },
          ],
        },
        {
          value: 'F28',
          title:
            'F28 Other psychotic disorder not due to a substance or known physiological condition',
        },
        {
          value: 'F29',
          title:
            'F29 Unspecified psychosis not due to a substance or known physiological condition',
        },
      ],
    },
    {
      value: 'F30-F39',
      title: 'F30-F39 Mood [affective] disorders',
      children: [
        {
          value: 'F30',
          title: 'F30 Manic episode',
          children: [
            {
              value: 'F30.1',
              title: 'F30.1 Manic episode without psychotic symptoms',
              children: [
                {
                  value: 'F30.10',
                  title:
                    'F30.10 Manic episode without psychotic symptoms, unspecified',
                },
                {
                  value: 'F30.11',
                  title:
                    'F30.11 Manic episode without psychotic symptoms, mild',
                },
                {
                  value: 'F30.12',
                  title:
                    'F30.12 Manic episode without psychotic symptoms, moderate',
                },
                {
                  value: 'F30.13',
                  title:
                    'F30.13 Manic episode, severe, without psychotic symptoms',
                },
              ],
            },
            {
              value: 'F30.2',
              title: 'F30.2 Manic episode, severe with psychotic symptoms',
            },
            {
              value: 'F30.3',
              title: 'F30.3 Manic episode in partial remission',
            },
            {
              value: 'F30.4',
              title: 'F30.4 Manic episode in full remission',
            },
            {
              value: 'F30.8',
              title: 'F30.8 Other manic episodes',
            },
            {
              value: 'F30.9',
              title: 'F30.9 Manic episode, unspecified',
            },
          ],
        },
        {
          value: 'F31',
          title: 'F31 Bipolar disorder',
          children: [
            {
              value: 'F31.0',
              title: 'F31.0 Bipolar disorder, current episode hypomanic',
            },
            {
              value: 'F31.1',
              title:
                'F31.1 Bipolar disorder, current episode manic without psychotic features',
              children: [
                {
                  value: 'F31.10',
                  title:
                    'F31.10 Bipolar disorder, current episode manic without psychotic features, unspecified',
                },
                {
                  value: 'F31.11',
                  title:
                    'F31.11 Bipolar disorder, current episode manic without psychotic features, mild',
                },
                {
                  value: 'F31.12',
                  title:
                    'F31.12 Bipolar disorder, current episode manic without psychotic features, moderate',
                },
                {
                  value: 'F31.13',
                  title:
                    'F31.13 Bipolar disorder, current episode manic without psychotic features, severe',
                },
              ],
            },
            {
              value: 'F31.2',
              title:
                'F31.2 Bipolar disorder, current episode manic severe with psychotic features',
            },
            {
              value: 'F31.3',
              title:
                'F31.3 Bipolar disorder, current episode depressed, mild or moderate severity',
              children: [
                {
                  value: 'F31.30',
                  title:
                    'F31.30 Bipolar disorder, current episode depressed, mild or moderate severity, unspecified',
                },
                {
                  value: 'F31.31',
                  title:
                    'F31.31 Bipolar disorder, current episode depressed, mild',
                },
                {
                  value: 'F31.32',
                  title:
                    'F31.32 Bipolar disorder, current episode depressed, moderate',
                },
              ],
            },
            {
              value: 'F31.4',
              title:
                'F31.4 Bipolar disorder, current episode depressed, severe, without psychotic features',
            },
            {
              value: 'F31.5',
              title:
                'F31.5 Bipolar disorder, current episode depressed, severe, with psychotic features',
            },
            {
              value: 'F31.6',
              title: 'F31.6 Bipolar disorder, current episode mixed',
              children: [
                {
                  value: 'F31.60',
                  title:
                    'F31.60 Bipolar disorder, current episode mixed, unspecified',
                },
                {
                  value: 'F31.61',
                  title: 'F31.61 Bipolar disorder, current episode mixed, mild',
                },
                {
                  value: 'F31.62',
                  title:
                    'F31.62 Bipolar disorder, current episode mixed, moderate',
                },
                {
                  value: 'F31.63',
                  title:
                    'F31.63 Bipolar disorder, current episode mixed, severe, without psychotic features',
                },
                {
                  value: 'F31.64',
                  title:
                    'F31.64 Bipolar disorder, current episode mixed, severe, with psychotic features',
                },
              ],
            },
            {
              value: 'F31.7',
              title: 'F31.7 Bipolar disorder, currently in remission',
              children: [
                {
                  value: 'F31.70',
                  title:
                    'F31.70 Bipolar disorder, currently in remission, most recent episode unspecified',
                },
                {
                  value: 'F31.71',
                  title:
                    'F31.71 Bipolar disorder, in partial remission, most recent episode hypomanic',
                },
                {
                  value: 'F31.72',
                  title:
                    'F31.72 Bipolar disorder, in full remission, most recent episode hypomanic',
                },
                {
                  value: 'F31.73',
                  title:
                    'F31.73 Bipolar disorder, in partial remission, most recent episode manic',
                },
                {
                  value: 'F31.74',
                  title:
                    'F31.74 Bipolar disorder, in full remission, most recent episode manic',
                },
                {
                  value: 'F31.75',
                  title:
                    'F31.75 Bipolar disorder, in partial remission, most recent episode depressed',
                },
                {
                  value: 'F31.76',
                  title:
                    'F31.76 Bipolar disorder, in full remission, most recent episode depressed',
                },
                {
                  value: 'F31.77',
                  title:
                    'F31.77 Bipolar disorder, in partial remission, most recent episode mixed',
                },
                {
                  value: 'F31.78',
                  title:
                    'F31.78 Bipolar disorder, in full remission, most recent episode mixed',
                },
              ],
            },
            {
              value: 'F31.8',
              title: 'F31.8 Other bipolar disorders',
              children: [
                {
                  value: 'F31.81',
                  title: 'F31.81 Bipolar II disorder',
                },
                {
                  value: 'F31.89',
                  title: 'F31.89 Other bipolar disorder',
                },
              ],
            },
            {
              value: 'F31.9',
              title: 'F31.9 Bipolar disorder, unspecified',
            },
          ],
        },
        {
          value: 'F32',
          title: 'F32 Depressive episode',
          children: [
            {
              value: 'F32.0',
              title: 'F32.0 Major depressive disorder, single episode, mild',
            },
            {
              value: 'F32.1',
              title:
                'F32.1 Major depressive disorder, single episode, moderate',
            },
            {
              value: 'F32.2',
              title:
                'F32.2 Major depressive disorder, single episode, severe without psychotic features',
            },
            {
              value: 'F32.3',
              title:
                'F32.3 Major depressive disorder, single episode, severe with psychotic features',
            },
            {
              value: 'F32.4',
              title:
                'F32.4 Major depressive disorder, single episode, in partial remission',
            },
            {
              value: 'F32.5',
              title:
                'F32.5 Major depressive disorder, single episode, in full remission',
            },
            {
              value: 'F32.8',
              title: 'F32.8 Other depressive episodes',
              children: [
                {
                  value: 'F32.81',
                  title: 'F32.81 Premenstrual dysphoric disorder',
                },
                {
                  value: 'F32.89',
                  title: 'F32.89 Other specified depressive episodes',
                },
              ],
            },
            {
              value: 'F32.9',
              title:
                'F32.9 Major depressive disorder, single episode, unspecified',
            },
            {
              value: 'F32.A',
              title: 'F32.A Depression, unspecified',
            },
          ],
        },
        {
          value: 'F33',
          title: 'F33 Major depressive disorder, recurrent',
          children: [
            {
              value: 'F33.0',
              title: 'F33.0 Major depressive disorder, recurrent, mild',
            },
            {
              value: 'F33.1',
              title: 'F33.1 Major depressive disorder, recurrent, moderate',
            },
            {
              value: 'F33.2',
              title:
                'F33.2 Major depressive disorder, recurrent severe without psychotic features',
            },
            {
              value: 'F33.3',
              title:
                'F33.3 Major depressive disorder, recurrent, severe with psychotic symptoms',
            },
            {
              value: 'F33.4',
              title: 'F33.4 Major depressive disorder, recurrent, in remission',
              children: [
                {
                  value: 'F33.40',
                  title:
                    'F33.40 Major depressive disorder, recurrent, in remission, unspecified',
                },
                {
                  value: 'F33.41',
                  title:
                    'F33.41 Major depressive disorder, recurrent, in partial remission',
                },
                {
                  value: 'F33.42',
                  title:
                    'F33.42 Major depressive disorder, recurrent, in full remission',
                },
              ],
            },
            {
              value: 'F33.8',
              title: 'F33.8 Other recurrent depressive disorders',
            },
            {
              value: 'F33.9',
              title: 'F33.9 Major depressive disorder, recurrent, unspecified',
            },
          ],
        },
        {
          value: 'F34',
          title: 'F34 Persistent mood [affective] disorders',
          children: [
            {
              value: 'F34.0',
              title: 'F34.0 Cyclothymic disorder',
            },
            {
              value: 'F34.1',
              title: 'F34.1 Dysthymic disorder',
            },
            {
              value: 'F34.8',
              title: 'F34.8 Other persistent mood [affective] disorders',
              children: [
                {
                  value: 'F34.81',
                  title: 'F34.81 Disruptive mood dysregulation disorder',
                },
                {
                  value: 'F34.89',
                  title: 'F34.89 Other specified persistent mood disorders',
                },
              ],
            },
            {
              value: 'F34.9',
              title: 'F34.9 Persistent mood [affective] disorder, unspecified',
            },
          ],
        },
        {
          value: 'F39',
          title: 'F39 Unspecified mood [affective] disorder',
        },
      ],
    },
    {
      value: 'F40-F48',
      title:
        'F40-F48 Anxiety, dissociative, stress-related, somatoform and other nonpsychotic mental disorders',
      children: [
        {
          value: 'F40',
          title: 'F40 Phobic anxiety disorders',
          children: [
            {
              value: 'F40.0',
              title: 'F40.0 Agoraphobia',
              children: [
                {
                  value: 'F40.00',
                  title: 'F40.00 Agoraphobia, unspecified',
                },
                {
                  value: 'F40.01',
                  title: 'F40.01 Agoraphobia with panic disorder',
                },
                {
                  value: 'F40.02',
                  title: 'F40.02 Agoraphobia without panic disorder',
                },
              ],
            },
            {
              value: 'F40.1',
              title: 'F40.1 Social phobias',
              children: [
                {
                  value: 'F40.10',
                  title: 'F40.10 Social phobia, unspecified',
                },
                {
                  value: 'F40.11',
                  title: 'F40.11 Social phobia, generalized',
                },
              ],
            },
            {
              value: 'F40.2',
              title: 'F40.2 Specific (isolated) phobias',
              children: [
                {
                  value: 'F40.21',
                  title: 'F40.21 Animal type phobia',
                  children: [
                    {
                      value: 'F40.210',
                      title: 'F40.210 Arachnophobia',
                    },
                    {
                      value: 'F40.218',
                      title: 'F40.218 Other animal type phobia',
                    },
                  ],
                },
                {
                  value: 'F40.22',
                  title: 'F40.22 Natural environment type phobia',
                  children: [
                    {
                      value: 'F40.220',
                      title: 'F40.220 Fear of thunderstorms',
                    },
                    {
                      value: 'F40.228',
                      title: 'F40.228 Other natural environment type phobia',
                    },
                  ],
                },
                {
                  value: 'F40.23',
                  title: 'F40.23 Blood, injection, injury type phobia',
                  children: [
                    {
                      value: 'F40.230',
                      title: 'F40.230 Fear of blood',
                    },
                    {
                      value: 'F40.231',
                      title: 'F40.231 Fear of injections and transfusions',
                    },
                    {
                      value: 'F40.232',
                      title: 'F40.232 Fear of other medical care',
                    },
                    {
                      value: 'F40.233',
                      title: 'F40.233 Fear of injury',
                    },
                  ],
                },
                {
                  value: 'F40.24',
                  title: 'F40.24 Situational type phobia',
                  children: [
                    {
                      value: 'F40.240',
                      title: 'F40.240 Claustrophobia',
                    },
                    {
                      value: 'F40.241',
                      title: 'F40.241 Acrophobia',
                    },
                    {
                      value: 'F40.242',
                      title: 'F40.242 Fear of bridges',
                    },
                    {
                      value: 'F40.243',
                      title: 'F40.243 Fear of flying',
                    },
                    {
                      value: 'F40.248',
                      title: 'F40.248 Other situational type phobia',
                    },
                  ],
                },
                {
                  value: 'F40.29',
                  title: 'F40.29 Other specified phobia',
                  children: [
                    {
                      value: 'F40.290',
                      title: 'F40.290 Androphobia',
                    },
                    {
                      value: 'F40.291',
                      title: 'F40.291 Gynephobia',
                    },
                    {
                      value: 'F40.298',
                      title: 'F40.298 Other specified phobia',
                    },
                  ],
                },
              ],
            },
            {
              value: 'F40.8',
              title: 'F40.8 Other phobic anxiety disorders',
            },
            {
              value: 'F40.9',
              title: 'F40.9 Phobic anxiety disorder, unspecified',
            },
          ],
        },
        {
          value: 'F41',
          title: 'F41 Other anxiety disorders',
          children: [
            {
              value: 'F41.0',
              title: 'F41.0 Panic disorder [episodic paroxysmal anxiety]',
            },
            {
              value: 'F41.1',
              title: 'F41.1 Generalized anxiety disorder',
            },
            {
              value: 'F41.3',
              title: 'F41.3 Other mixed anxiety disorders',
            },
            {
              value: 'F41.8',
              title: 'F41.8 Other specified anxiety disorders',
            },
            {
              value: 'F41.9',
              title: 'F41.9 Anxiety disorder, unspecified',
            },
          ],
        },
        {
          value: 'F42',
          title: 'F42 Obsessive-compulsive disorder',
          children: [
            {
              value: 'F42.2',
              title: 'F42.2 Mixed obsessional thoughts and acts',
            },
            {
              value: 'F42.3',
              title: 'F42.3 Hoarding disorder',
            },
            {
              value: 'F42.4',
              title: 'F42.4 Excoriation (skin-picking) disorder',
            },
            {
              value: 'F42.8',
              title: 'F42.8 Other obsessive-compulsive disorder',
            },
            {
              value: 'F42.9',
              title: 'F42.9 Obsessive-compulsive disorder, unspecified',
            },
          ],
        },
        {
          value: 'F43',
          title: 'F43 Reaction to severe stress, and adjustment disorders',
          children: [
            {
              value: 'F43.0',
              title: 'F43.0 Acute stress reaction',
            },
            {
              value: 'F43.1',
              title: 'F43.1 Post-traumatic stress disorder (PTSD)',
              children: [
                {
                  value: 'F43.10',
                  title: 'F43.10 Post-traumatic stress disorder, unspecified',
                },
                {
                  value: 'F43.11',
                  title: 'F43.11 Post-traumatic stress disorder, acute',
                },
                {
                  value: 'F43.12',
                  title: 'F43.12 Post-traumatic stress disorder, chronic',
                },
              ],
            },
            {
              value: 'F43.2',
              title: 'F43.2 Adjustment disorders',
              children: [
                {
                  value: 'F43.20',
                  title: 'F43.20 Adjustment disorder, unspecified',
                },
                {
                  value: 'F43.21',
                  title: 'F43.21 Adjustment disorder with depressed mood',
                },
                {
                  value: 'F43.22',
                  title: 'F43.22 Adjustment disorder with anxiety',
                },
                {
                  value: 'F43.23',
                  title:
                    'F43.23 Adjustment disorder with mixed anxiety and depressed mood',
                },
                {
                  value: 'F43.24',
                  title:
                    'F43.24 Adjustment disorder with disturbance of conduct',
                },
                {
                  value: 'F43.25',
                  title:
                    'F43.25 Adjustment disorder with mixed disturbance of emotions and conduct',
                },
                {
                  value: 'F43.29',
                  title: 'F43.29 Adjustment disorder with other symptoms',
                },
              ],
            },
            {
              value: 'F43.8',
              title: 'F43.8 Other reactions to severe stress',
              children: [
                {
                  value: 'F43.81',
                  title: 'F43.81 Prolonged grief disorder',
                },
                {
                  value: 'F43.89',
                  title: 'F43.89 Other reactions to severe stress',
                },
              ],
            },
            {
              value: 'F43.9',
              title: 'F43.9 Reaction to severe stress, unspecified',
            },
          ],
        },
        {
          value: 'F44',
          title: 'F44 Dissociative and conversion disorders',
          children: [
            {
              value: 'F44.0',
              title: 'F44.0 Dissociative amnesia',
            },
            {
              value: 'F44.1',
              title: 'F44.1 Dissociative fugue',
            },
            {
              value: 'F44.2',
              title: 'F44.2 Dissociative stupor',
            },
            {
              value: 'F44.4',
              title: 'F44.4 Conversion disorder with motor symptom or deficit',
            },
            {
              value: 'F44.5',
              title: 'F44.5 Conversion disorder with seizures or convulsions',
            },
            {
              value: 'F44.6',
              title:
                'F44.6 Conversion disorder with sensory symptom or deficit',
            },
            {
              value: 'F44.7',
              title:
                'F44.7 Conversion disorder with mixed symptom presentation',
            },
            {
              value: 'F44.8',
              title: 'F44.8 Other dissociative and conversion disorders',
              children: [
                {
                  value: 'F44.81',
                  title: 'F44.81 Dissociative identity disorder',
                },
                {
                  value: 'F44.89',
                  title: 'F44.89 Other dissociative and conversion disorders',
                },
              ],
            },
            {
              value: 'F44.9',
              title: 'F44.9 Dissociative and conversion disorder, unspecified',
            },
          ],
        },
        {
          value: 'F45',
          title: 'F45 Somatoform disorders',
          children: [
            {
              value: 'F45.0',
              title: 'F45.0 Somatization disorder',
            },
            {
              value: 'F45.1',
              title: 'F45.1 Undifferentiated somatoform disorder',
            },
            {
              value: 'F45.2',
              title: 'F45.2 Hypochondriacal disorders',
              children: [
                {
                  value: 'F45.20',
                  title: 'F45.20 Hypochondriacal disorder, unspecified',
                },
                {
                  value: 'F45.21',
                  title: 'F45.21 Hypochondriasis',
                },
                {
                  value: 'F45.22',
                  title: 'F45.22 Body dysmorphic disorder',
                },
                {
                  value: 'F45.29',
                  title: 'F45.29 Other hypochondriacal disorders',
                },
              ],
            },
            {
              value: 'F45.4',
              title: 'F45.4 Pain disorders related to psychological factors',
              children: [
                {
                  value: 'F45.41',
                  title:
                    'F45.41 Pain disorder exclusively related to psychological factors',
                },
                {
                  value: 'F45.42',
                  title:
                    'F45.42 Pain disorder with related psychological factors',
                },
              ],
            },
            {
              value: 'F45.8',
              title: 'F45.8 Other somatoform disorders',
            },
            {
              value: 'F45.9',
              title: 'F45.9 Somatoform disorder, unspecified',
            },
          ],
        },
        {
          value: 'F48',
          title: 'F48 Other nonpsychotic mental disorders',
          children: [
            {
              value: 'F48.1',
              title: 'F48.1 Depersonalization-derealization syndrome',
            },
            {
              value: 'F48.2',
              title: 'F48.2 Pseudobulbar affect',
            },
            {
              value: 'F48.8',
              title: 'F48.8 Other specified nonpsychotic mental disorders',
            },
            {
              value: 'F48.9',
              title: 'F48.9 Nonpsychotic mental disorder, unspecified',
            },
          ],
        },
      ],
    },
    {
      value: 'F50-F59',
      title:
        'F50-F59 Behavioral syndromes associated with physiological disturbances and physical factors',
      children: [
        {
          value: 'F50',
          title: 'F50 Eating disorders',
          children: [
            {
              value: 'F50.0',
              title: 'F50.0 Anorexia nervosa',
              children: [
                {
                  value: 'F50.00',
                  title: 'F50.00 Anorexia nervosa, unspecified',
                },
                {
                  value: 'F50.01',
                  title: 'F50.01 Anorexia nervosa, restricting type',
                },
                {
                  value: 'F50.02',
                  title: 'F50.02 Anorexia nervosa, binge eating/purging type',
                },
              ],
            },
            {
              value: 'F50.2',
              title: 'F50.2 Bulimia nervosa',
            },
            {
              value: 'F50.8',
              title: 'F50.8 Other eating disorders',
              children: [
                {
                  value: 'F50.81',
                  title: 'F50.81 Binge eating disorder',
                },
                {
                  value: 'F50.82',
                  title: 'F50.82 Avoidant/restrictive food intake disorder',
                },
                {
                  value: 'F50.89',
                  title: 'F50.89 Other specified eating disorder',
                },
              ],
            },
            {
              value: 'F50.9',
              title: 'F50.9 Eating disorder, unspecified',
            },
          ],
        },
        {
          value: 'F51',
          title:
            'F51 Sleep disorders not due to a substance or known physiological condition',
          children: [
            {
              value: 'F51.0',
              title:
                'F51.0 Insomnia not due to a substance or known physiological condition',
              children: [
                {
                  value: 'F51.01',
                  title: 'F51.01 Primary insomnia',
                },
                {
                  value: 'F51.02',
                  title: 'F51.02 Adjustment insomnia',
                },
                {
                  value: 'F51.03',
                  title: 'F51.03 Paradoxical insomnia',
                },
                {
                  value: 'F51.04',
                  title: 'F51.04 Psychophysiologic insomnia',
                },
                {
                  value: 'F51.05',
                  title: 'F51.05 Insomnia due to other mental disorder',
                },
                {
                  value: 'F51.09',
                  title:
                    'F51.09 Other insomnia not due to a substance or known physiological condition',
                },
              ],
            },
            {
              value: 'F51.1',
              title:
                'F51.1 Hypersomnia not due to a substance or known physiological condition',
              children: [
                {
                  value: 'F51.11',
                  title: 'F51.11 Primary hypersomnia',
                },
                {
                  value: 'F51.12',
                  title: 'F51.12 Insufficient sleep syndrome',
                },
                {
                  value: 'F51.13',
                  title: 'F51.13 Hypersomnia due to other mental disorder',
                },
                {
                  value: 'F51.19',
                  title:
                    'F51.19 Other hypersomnia not due to a substance or known physiological condition',
                },
              ],
            },
            {
              value: 'F51.3',
              title: 'F51.3 Sleepwalking [somnambulism]',
            },
            {
              value: 'F51.4',
              title: 'F51.4 Sleep terrors [night terrors]',
            },
            {
              value: 'F51.5',
              title: 'F51.5 Nightmare disorder',
            },
            {
              value: 'F51.8',
              title:
                'F51.8 Other sleep disorders not due to a substance or known physiological condition',
            },
            {
              value: 'F51.9',
              title:
                'F51.9 Sleep disorder not due to a substance or known physiological condition, unspecified',
            },
          ],
        },
        {
          value: 'F52',
          title:
            'F52 Sexual dysfunction not due to a substance or known physiological condition',
          children: [
            {
              value: 'F52.0',
              title: 'F52.0 Hypoactive sexual desire disorder',
            },
            {
              value: 'F52.1',
              title: 'F52.1 Sexual aversion disorder',
            },
            {
              value: 'F52.2',
              title: 'F52.2 Sexual arousal disorders',
              children: [
                {
                  value: 'F52.21',
                  title: 'F52.21 Male erectile disorder',
                },
                {
                  value: 'F52.22',
                  title: 'F52.22 Female sexual arousal disorder',
                },
              ],
            },
            {
              value: 'F52.3',
              title: 'F52.3 Orgasmic disorder',
              children: [
                {
                  value: 'F52.31',
                  title: 'F52.31 Female orgasmic disorder',
                },
                {
                  value: 'F52.32',
                  title: 'F52.32 Male orgasmic disorder',
                },
              ],
            },
            {
              value: 'F52.4',
              title: 'F52.4 Premature ejaculation',
            },
            {
              value: 'F52.5',
              title:
                'F52.5 Vaginismus not due to a substance or known physiological condition',
            },
            {
              value: 'F52.6',
              title:
                'F52.6 Dyspareunia not due to a substance or known physiological condition',
            },
            {
              value: 'F52.8',
              title:
                'F52.8 Other sexual dysfunction not due to a substance or known physiological condition',
            },
            {
              value: 'F52.9',
              title:
                'F52.9 Unspecified sexual dysfunction not due to a substance or known physiological condition',
            },
          ],
        },
        {
          value: 'F53',
          title:
            'F53 Mental and behavioral disorders associated with the puerperium, not elsewhere classified',
          children: [
            {
              value: 'F53.0',
              title: 'F53.0 Postpartum depression',
            },
            {
              value: 'F53.1',
              title: 'F53.1 Puerperal psychosis',
            },
          ],
        },
        {
          value: 'F54',
          title:
            'F54 Psychological and behavioral factors associated with disorders or diseases classified elsewhere',
        },
        {
          value: 'F55',
          title: 'F55 Abuse of non-psychoactive substances',
          children: [
            {
              value: 'F55.0',
              title: 'F55.0 Abuse of antacids',
            },
            {
              value: 'F55.1',
              title: 'F55.1 Abuse of herbal or folk remedies',
            },
            {
              value: 'F55.2',
              title: 'F55.2 Abuse of laxatives',
            },
            {
              value: 'F55.3',
              title: 'F55.3 Abuse of steroids or hormones',
            },
            {
              value: 'F55.4',
              title: 'F55.4 Abuse of vitamins',
            },
            {
              value: 'F55.8',
              title: 'F55.8 Abuse of other non-psychoactive substances',
            },
          ],
        },
        {
          value: 'F59',
          title:
            'F59 Unspecified behavioral syndromes associated with physiological disturbances and physical factors',
        },
      ],
    },
    {
      value: 'F60-F69',
      title: 'F60-F69 Disorders of adult personality and behavior',
      children: [
        {
          value: 'F60',
          title: 'F60 Specific personality disorders',
          children: [
            {
              value: 'F60.0',
              title: 'F60.0 Paranoid personality disorder',
            },
            {
              value: 'F60.1',
              title: 'F60.1 Schizoid personality disorder',
            },
            {
              value: 'F60.2',
              title: 'F60.2 Antisocial personality disorder',
            },
            {
              value: 'F60.3',
              title: 'F60.3 Borderline personality disorder',
            },
            {
              value: 'F60.4',
              title: 'F60.4 Histrionic personality disorder',
            },
            {
              value: 'F60.5',
              title: 'F60.5 Obsessive-compulsive personality disorder',
            },
            {
              value: 'F60.6',
              title: 'F60.6 Avoidant personality disorder',
            },
            {
              value: 'F60.7',
              title: 'F60.7 Dependent personality disorder',
            },
            {
              value: 'F60.8',
              title: 'F60.8 Other specific personality disorders',
              children: [
                {
                  value: 'F60.81',
                  title: 'F60.81 Narcissistic personality disorder',
                },
                {
                  value: 'F60.89',
                  title: 'F60.89 Other specific personality disorders',
                },
              ],
            },
            {
              value: 'F60.9',
              title: 'F60.9 Personality disorder, unspecified',
            },
          ],
        },
        {
          value: 'F63',
          title: 'F63 Impulse disorders',
          children: [
            {
              value: 'F63.0',
              title: 'F63.0 Pathological gambling',
            },
            {
              value: 'F63.1',
              title: 'F63.1 Pyromania',
            },
            {
              value: 'F63.2',
              title: 'F63.2 Kleptomania',
            },
            {
              value: 'F63.3',
              title: 'F63.3 Trichotillomania',
            },
            {
              value: 'F63.8',
              title: 'F63.8 Other impulse disorders',
              children: [
                {
                  value: 'F63.81',
                  title: 'F63.81 Intermittent explosive disorder',
                },
                {
                  value: 'F63.89',
                  title: 'F63.89 Other impulse disorders',
                },
              ],
            },
            {
              value: 'F63.9',
              title: 'F63.9 Impulse disorder, unspecified',
            },
          ],
        },
        {
          value: 'F64',
          title: 'F64 Gender identity disorders',
          children: [
            {
              value: 'F64.0',
              title: 'F64.0 Transsexualism',
            },
            {
              value: 'F64.1',
              title: 'F64.1 Dual role transvestism',
            },
            {
              value: 'F64.2',
              title: 'F64.2 Gender identity disorder of childhood',
            },
            {
              value: 'F64.8',
              title: 'F64.8 Other gender identity disorders',
            },
            {
              value: 'F64.9',
              title: 'F64.9 Gender identity disorder, unspecified',
            },
          ],
        },
        {
          value: 'F65',
          title: 'F65 Paraphilias',
          children: [
            {
              value: 'F65.0',
              title: 'F65.0 Fetishism',
            },
            {
              value: 'F65.1',
              title: 'F65.1 Transvestic fetishism',
            },
            {
              value: 'F65.2',
              title: 'F65.2 Exhibitionism',
            },
            {
              value: 'F65.3',
              title: 'F65.3 Voyeurism',
            },
            {
              value: 'F65.4',
              title: 'F65.4 Pedophilia',
            },
            {
              value: 'F65.5',
              title: 'F65.5 Sadomasochism',
              children: [
                {
                  value: 'F65.50',
                  title: 'F65.50 Sadomasochism, unspecified',
                },
                {
                  value: 'F65.51',
                  title: 'F65.51 Sexual masochism',
                },
                {
                  value: 'F65.52',
                  title: 'F65.52 Sexual sadism',
                },
              ],
            },
            {
              value: 'F65.8',
              title: 'F65.8 Other paraphilias',
              children: [
                {
                  value: 'F65.81',
                  title: 'F65.81 Frotteurism',
                },
                {
                  value: 'F65.89',
                  title: 'F65.89 Other paraphilias',
                },
              ],
            },
            {
              value: 'F65.9',
              title: 'F65.9 Paraphilia, unspecified',
            },
          ],
        },
        {
          value: 'F66',
          title: 'F66 Other sexual disorders',
        },
        {
          value: 'F68',
          title: 'F68 Other disorders of adult personality and behavior',
          children: [
            {
              value: 'F68.1',
              title: 'F68.1 Factitious disorder imposed on self',
              children: [
                {
                  value: 'F68.10',
                  title:
                    'F68.10 Factitious disorder imposed on self, unspecified',
                },
                {
                  value: 'F68.11',
                  title:
                    'F68.11 Factitious disorder imposed on self, with predominantly psychological signs and symptoms',
                },
                {
                  value: 'F68.12',
                  title:
                    'F68.12 Factitious disorder imposed on self, with predominantly physical signs and symptoms',
                },
                {
                  value: 'F68.13',
                  title:
                    'F68.13 Factitious disorder imposed on self, with combined psychological and physical signs and symptoms',
                },
              ],
            },
            {
              value: 'F68.A',
              title: 'F68.A Factitious disorder imposed on another',
            },
            {
              value: 'F68.8',
              title:
                'F68.8 Other specified disorders of adult personality and behavior',
            },
          ],
        },
        {
          value: 'F69',
          title: 'F69 Unspecified disorder of adult personality and behavior',
        },
      ],
    },
    {
      value: 'F70-F79',
      title: 'F70-F79 Intellectual Disabilities',
      children: [
        {
          value: 'F70',
          title: 'F70 Mild intellectual disabilities',
        },
        {
          value: 'F71',
          title: 'F71 Moderate intellectual disabilities',
        },
        {
          value: 'F72',
          title: 'F72 Severe intellectual disabilities',
        },
        {
          value: 'F73',
          title: 'F73 Profound intellectual disabilities',
        },
        {
          value: 'F78',
          title: 'F78 Other intellectual disabilities',
          children: [
            {
              value: 'F78.A',
              title: 'F78.A Other genetic related intellectual disabilities',
              children: [
                {
                  value: 'F78.A1',
                  title: 'F78.A1 SYNGAP1-related intellectual disability',
                },
                {
                  value: 'F78.A9',
                  title: 'F78.A9 Other genetic related intellectual disability',
                },
              ],
            },
          ],
        },
        {
          value: 'F79',
          title: 'F79 Unspecified intellectual disabilities',
        },
      ],
    },
    {
      value: 'F80-F89',
      title: 'F80-F89 Pervasive and specific developmental disorders',
      children: [
        {
          value: 'F80',
          title: 'F80 Specific developmental disorders of speech and language',
          children: [
            {
              value: 'F80.0',
              title: 'F80.0 Phonological disorder',
            },
            {
              value: 'F80.1',
              title: 'F80.1 Expressive language disorder',
            },
            {
              value: 'F80.2',
              title: 'F80.2 Mixed receptive-expressive language disorder',
            },
            {
              value: 'F80.4',
              title:
                'F80.4 Speech and language development delay due to hearing loss',
            },
            {
              value: 'F80.8',
              title:
                'F80.8 Other developmental disorders of speech and language',
              children: [
                {
                  value: 'F80.81',
                  title: 'F80.81 Childhood onset fluency disorder',
                },
                {
                  value: 'F80.82',
                  title: 'F80.82 Social pragmatic communication disorder',
                },
                {
                  value: 'F80.89',
                  title:
                    'F80.89 Other developmental disorders of speech and language',
                },
              ],
            },
            {
              value: 'F80.9',
              title:
                'F80.9 Developmental disorder of speech and language, unspecified',
            },
          ],
        },
        {
          value: 'F81',
          title: 'F81 Specific developmental disorders of scholastic skills',
          children: [
            {
              value: 'F81.0',
              title: 'F81.0 Specific reading disorder',
            },
            {
              value: 'F81.2',
              title: 'F81.2 Mathematics disorder',
            },
            {
              value: 'F81.8',
              title: 'F81.8 Other developmental disorders of scholastic skills',
              children: [
                {
                  value: 'F81.81',
                  title: 'F81.81 Disorder of written expression',
                },
                {
                  value: 'F81.89',
                  title:
                    'F81.89 Other developmental disorders of scholastic skills',
                },
              ],
            },
            {
              value: 'F81.9',
              title:
                'F81.9 Developmental disorder of scholastic skills, unspecified',
            },
          ],
        },
        {
          value: 'F82',
          title: 'F82 Specific developmental disorder of motor function',
        },
        {
          value: 'F84',
          title: 'F84 Pervasive developmental disorders',
          children: [
            {
              value: 'F84.0',
              title: 'F84.0 Autistic disorder',
            },
            {
              value: 'F84.2',
              title: "F84.2 Rett's syndrome",
            },
            {
              value: 'F84.3',
              title: 'F84.3 Other childhood disintegrative disorder',
            },
            {
              value: 'F84.5',
              title: "F84.5 Asperger's syndrome",
            },
            {
              value: 'F84.8',
              title: 'F84.8 Other pervasive developmental disorders',
            },
            {
              value: 'F84.9',
              title: 'F84.9 Pervasive developmental disorder, unspecified',
            },
          ],
        },
        {
          value: 'F88',
          title: 'F88 Other disorders of psychological development',
        },
        {
          value: 'F89',
          title: 'F89 Unspecified disorder of psychological development',
        },
      ],
    },
    {
      value: 'F90-F98',
      title:
        'F90-F98 Behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
      children: [
        {
          value: 'F90.my',
          title: 'F90 Attention-deficit hyperactivity disorders',
          children: [
            {
              value: 'F90.0',
              title:
                'F90.0 Attention-deficit hyperactivity disorder, predominantly inattentive type',
            },
            {
              value: 'F90.1',
              title:
                'F90.1 Attention-deficit hyperactivity disorder, predominantly hyperactive type',
            },
            {
              value: 'F90.2',
              title:
                'F90.2 Attention-deficit hyperactivity disorder, combined type',
            },
            {
              value: 'F90.8',
              title:
                'F90.8 Attention-deficit hyperactivity disorder, other type',
            },
            {
              value: 'F90.9',
              title:
                'F90.9 Attention-deficit hyperactivity disorder, unspecified type',
            },
          ],
        },
        {
          value: 'F91',
          title: 'F91 Conduct disorders',
          children: [
            {
              value: 'F91.0',
              title: 'F91.0 Conduct disorder confined to family context',
            },
            {
              value: 'F91.1',
              title: 'F91.1 Conduct disorder, childhood-onset type',
            },
            {
              value: 'F91.2',
              title: 'F91.2 Conduct disorder, adolescent-onset type',
            },
            {
              value: 'F91.3',
              title: 'F91.3 Oppositional defiant disorder',
            },
            {
              value: 'F91.8',
              title: 'F91.8 Other conduct disorders',
            },
            {
              value: 'F91.9',
              title: 'F91.9 Conduct disorder, unspecified',
            },
          ],
        },
        {
          value: 'F93',
          title: 'F93 Emotional disorders with onset specific to childhood',
          children: [
            {
              value: 'F93.0',
              title: 'F93.0 Separation anxiety disorder of childhood',
            },
            {
              value: 'F93.8',
              title: 'F93.8 Other childhood emotional disorders',
            },
            {
              value: 'F93.9',
              title: 'F93.9 Childhood emotional disorder, unspecified',
            },
          ],
        },
        {
          value: 'F94',
          title:
            'F94 Disorders of social functioning with onset specific to childhood and adolescence',
          children: [
            {
              value: 'F94.0',
              title: 'F94.0 Selective mutism',
            },
            {
              value: 'F94.1',
              title: 'F94.1 Reactive attachment disorder of childhood',
            },
            {
              value: 'F94.2',
              title: 'F94.2 Disinhibited attachment disorder of childhood',
            },
            {
              value: 'F94.8',
              title: 'F94.8 Other childhood disorders of social functioning',
            },
            {
              value: 'F94.9',
              title:
                'F94.9 Childhood disorder of social functioning, unspecified',
            },
          ],
        },
        {
          value: 'F95',
          title: 'F95 Tic disorder',
          children: [
            {
              value: 'F95.0',
              title: 'F95.0 Transient tic disorder',
            },
            {
              value: 'F95.1',
              title: 'F95.1 Chronic motor or vocal tic disorder',
            },
            {
              value: 'F95.2',
              title: "F95.2 Tourette's disorder",
            },
            {
              value: 'F95.8',
              title: 'F95.8 Other tic disorders',
            },
            {
              value: 'F95.9',
              title: 'F95.9 Tic disorder, unspecified',
            },
          ],
        },
        {
          value: 'F98',
          title:
            'F98 Other behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
          children: [
            {
              value: 'F98.0',
              title:
                'F98.0 Enuresis not due to a substance or known physiological condition',
            },
            {
              value: 'F98.1',
              title:
                'F98.1 Encopresis not due to a substance or known physiological condition',
            },
            {
              value: 'F98.2',
              title: 'F98.2 Other feeding disorders of infancy and childhood',
              children: [
                {
                  value: 'F98.21',
                  title: 'F98.21 Rumination disorder of infancy',
                },
                {
                  value: 'F98.29',
                  title:
                    'F98.29 Other feeding disorders of infancy and early childhood',
                },
              ],
            },
            {
              value: 'F98.3',
              title: 'F98.3 Pica of infancy and childhood',
            },
            {
              value: 'F98.4',
              title: 'F98.4 Stereotyped movement disorders',
            },
            {
              value: 'F98.5',
              title: 'F98.5 Adult onset fluency disorder',
            },
            {
              value: 'F98.8',
              title:
                'F98.8 Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
            },
            {
              value: 'F98.9',
              title:
                'F98.9 Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
            },
          ],
        },
      ],
    },
    {
      value: 'F99',
      title: 'F99 Unspecified mental disorder',
      children: [
        {
          value: 'F99.0',
          title: 'F99 Mental disorder, not otherwise specified',
        },
      ],
    },
    {
      value: 'Z00-Z13',
      title: 'Z00-Z13 Persons encountering health services for examinations',
      children: [
        {
          value: 'Z00',
          title:
            'Z00 Encounter for general examination without complaint, suspected or reported diagnosis',
          children: [
            {
              value: 'Z00.0',
              title: 'Z00.0 Encounter for general adult medical examination',
              children: [
                {
                  value: 'Z00.00',
                  title:
                    'Z00.00 Encounter for general adult medical examination without abnormal findings',
                },
                {
                  value: 'Z00.01',
                  title:
                    'Z00.01 Encounter for general adult medical examination with abnormal findings',
                },
              ],
            },
            {
              value: 'Z00.1',
              title:
                'Z00.1 Encounter for newborn, infant and child health examinations',
              children: [
                {
                  value: 'Z00.11',
                  title: 'Z00.11 Newborn health examination',
                  children: [
                    {
                      value: 'Z00.110',
                      title:
                        'Z00.110 Health examination for newborn under 8 days old',
                    },
                    {
                      value: 'Z00.111',
                      title:
                        'Z00.111 Health examination for newborn 8 to 28 days old',
                    },
                  ],
                },
                {
                  value: 'Z00.12',
                  title:
                    'Z00.12 Encounter for routine child health examination',
                  children: [
                    {
                      value: 'Z00.121',
                      title:
                        'Z00.121 Encounter for routine child health examination with abnormal findings',
                    },
                    {
                      value: 'Z00.129',
                      title:
                        'Z00.129 Encounter for routine child health examination without abnormal findings',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z00.2',
              title:
                'Z00.2 Encounter for examination for period of rapid growth in childhood',
            },
            {
              value: 'Z00.3',
              title:
                'Z00.3 Encounter for examination for adolescent development state',
            },
            {
              value: 'Z00.5',
              title:
                'Z00.5 Encounter for examination of potential donor of organ and tissue',
            },
            {
              value: 'Z00.6',
              title:
                'Z00.6 Encounter for examination for normal comparison and control in clinical research program',
            },
            {
              value: 'Z00.7',
              title:
                'Z00.7 Encounter for examination for period of delayed growth in childhood',
              children: [
                {
                  value: 'Z00.70',
                  title:
                    'Z00.70 Encounter for examination for period of delayed growth in childhood without abnormal findings',
                },
                {
                  value: 'Z00.71',
                  title:
                    'Z00.71 Encounter for examination for period of delayed growth in childhood with abnormal findings',
                },
              ],
            },
            {
              value: 'Z00.8',
              title: 'Z00.8 Encounter for other general examination',
            },
          ],
        },
        {
          value: 'Z01',
          title:
            'Z01 Encounter for other special examination without complaint, suspected or reported diagnosis',
          children: [
            {
              value: 'Z01.0',
              title: 'Z01.0 Encounter for examination of eyes and vision',
              children: [
                {
                  value: 'Z01.00',
                  title:
                    'Z01.00 Encounter for examination of eyes and vision without abnormal findings',
                },
                {
                  value: 'Z01.01',
                  title:
                    'Z01.01 Encounter for examination of eyes and vision with abnormal findings',
                },
                {
                  value: 'Z01.02',
                  title:
                    'Z01.02 Encounter for examination of eyes and vision following failed vision screening',
                  children: [
                    {
                      value: 'Z01.020',
                      title:
                        'Z01.020 Encounter for examination of eyes and vision following failed vision screening without abnormal findings',
                    },
                    {
                      value: 'Z01.021',
                      title:
                        'Z01.021 Encounter for examination of eyes and vision following failed vision screening with abnormal findings',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z01.1',
              title: 'Z01.1 Encounter for examination of ears and hearing',
              children: [
                {
                  value: 'Z01.10',
                  title:
                    'Z01.10 Encounter for examination of ears and hearing without abnormal findings',
                },
                {
                  value: 'Z01.11',
                  title:
                    'Z01.11 Encounter for examination of ears and hearing with abnormal findings',
                  children: [
                    {
                      value: 'Z01.110',
                      title:
                        'Z01.110 Encounter for hearing examination following failed hearing screening',
                    },
                    {
                      value: 'Z01.118',
                      title:
                        'Z01.118 Encounter for examination of ears and hearing with other abnormal findings',
                    },
                  ],
                },
                {
                  value: 'Z01.12',
                  title:
                    'Z01.12 Encounter for hearing conservation and treatment',
                },
              ],
            },
            {
              value: 'Z01.2',
              title: 'Z01.2 Encounter for dental examination and cleaning',
              children: [
                {
                  value: 'Z01.20',
                  title:
                    'Z01.20 Encounter for dental examination and cleaning without abnormal findings',
                },
                {
                  value: 'Z01.21',
                  title:
                    'Z01.21 Encounter for dental examination and cleaning with abnormal findings',
                },
              ],
            },
            {
              value: 'Z01.3',
              title: 'Z01.3 Encounter for examination of blood pressure',
              children: [
                {
                  value: 'Z01.30',
                  title:
                    'Z01.30 Encounter for examination of blood pressure without abnormal findings',
                },
                {
                  value: 'Z01.31',
                  title:
                    'Z01.31 Encounter for examination of blood pressure with abnormal findings',
                },
              ],
            },
            {
              value: 'Z01.4',
              title: 'Z01.4 Encounter for gynecological examination',
              children: [
                {
                  value: 'Z01.41',
                  title:
                    'Z01.41 Encounter for routine gynecological examination',
                  children: [
                    {
                      value: 'Z01.411',
                      title:
                        'Z01.411 Encounter for gynecological examination (general) (routine) with abnormal findings',
                    },
                    {
                      value: 'Z01.419',
                      title:
                        'Z01.419 Encounter for gynecological examination (general) (routine) without abnormal findings',
                    },
                  ],
                },
                {
                  value: 'Z01.42',
                  title:
                    'Z01.42 Encounter for cervical smear to confirm findings of recent normal smear following initial abnormal smear',
                },
              ],
            },
            {
              value: 'Z01.8',
              title: 'Z01.8 Encounter for other specified special examinations',
              children: [
                {
                  value: 'Z01.81',
                  title: 'Z01.81 Encounter for preprocedural examinations',
                  children: [
                    {
                      value: 'Z01.810',
                      title:
                        'Z01.810 Encounter for preprocedural cardiovascular examination',
                    },
                    {
                      value: 'Z01.811',
                      title:
                        'Z01.811 Encounter for preprocedural respiratory examination',
                    },
                    {
                      value: 'Z01.812',
                      title:
                        'Z01.812 Encounter for preprocedural laboratory examination',
                    },
                    {
                      value: 'Z01.818',
                      title:
                        'Z01.818 Encounter for other preprocedural examination',
                    },
                  ],
                },
                {
                  value: 'Z01.82',
                  title: 'Z01.82 Encounter for allergy testing',
                },
                {
                  value: 'Z01.83',
                  title: 'Z01.83 Encounter for blood typing',
                },
                {
                  value: 'Z01.84',
                  title: 'Z01.84 Encounter for antibody response examination',
                },
                {
                  value: 'Z01.89',
                  title:
                    'Z01.89 Encounter for other specified special examinations',
                },
              ],
            },
          ],
        },
        {
          value: 'Z02',
          title: 'Z02 Encounter for administrative examination',
          children: [
            {
              value: 'Z02.0',
              title:
                'Z02.0 Encounter for examination for admission to educational institution',
            },
            {
              value: 'Z02.1',
              title: 'Z02.1 Encounter for pre-employment examination',
            },
            {
              value: 'Z02.2',
              title:
                'Z02.2 Encounter for examination for admission to residential institution',
            },
            {
              value: 'Z02.3',
              title:
                'Z02.3 Encounter for examination for recruitment to armed forces',
            },
            {
              value: 'Z02.4',
              title: 'Z02.4 Encounter for examination for driving license',
            },
            {
              value: 'Z02.5',
              title:
                'Z02.5 Encounter for examination for participation in sport',
            },
            {
              value: 'Z02.6',
              title: 'Z02.6 Encounter for examination for insurance purposes',
            },
            {
              value: 'Z02.7',
              title: 'Z02.7 Encounter for issue of medical certificate',
              children: [
                {
                  value: 'Z02.71',
                  title: 'Z02.71 Encounter for disability determination',
                },
                {
                  value: 'Z02.79',
                  title:
                    'Z02.79 Encounter for issue of other medical certificate',
                },
              ],
            },
            {
              value: 'Z02.8',
              title: 'Z02.8 Encounter for other administrative examinations',
              children: [
                {
                  value: 'Z02.81',
                  title: 'Z02.81 Encounter for paternity testing',
                },
                {
                  value: 'Z02.82',
                  title: 'Z02.82 Encounter for adoption services',
                },
                {
                  value: 'Z02.83',
                  title:
                    'Z02.83 Encounter for blood-alcohol and blood-drug test',
                },
                {
                  value: 'Z02.89',
                  title:
                    'Z02.89 Encounter for other administrative examinations',
                },
              ],
            },
            {
              value: 'Z02.9',
              title:
                'Z02.9 Encounter for administrative examinations, unspecified',
            },
          ],
        },
        {
          value: 'Z03',
          title:
            'Z03 Encounter for medical observation for suspected diseases and conditions ruled out',
          children: [
            {
              value: 'Z03.6',
              title:
                'Z03.6 Encounter for observation for suspected toxic effect from ingested substance ruled out',
            },
            {
              value: 'Z03.7',
              title:
                'Z03.7 Encounter for suspected maternal and fetal conditions ruled out',
              children: [
                {
                  value: 'Z03.71',
                  title:
                    'Z03.71 Encounter for suspected problem with amniotic cavity and membrane ruled out',
                },
                {
                  value: 'Z03.72',
                  title:
                    'Z03.72 Encounter for suspected placental problem ruled out',
                },
                {
                  value: 'Z03.73',
                  title:
                    'Z03.73 Encounter for suspected fetal anomaly ruled out',
                },
                {
                  value: 'Z03.74',
                  title:
                    'Z03.74 Encounter for suspected problem with fetal growth ruled out',
                },
                {
                  value: 'Z03.75',
                  title:
                    'Z03.75 Encounter for suspected cervical shortening ruled out',
                },
                {
                  value: 'Z03.79',
                  title:
                    'Z03.79 Encounter for other suspected maternal and fetal conditions ruled out',
                },
              ],
            },
            {
              value: 'Z03.8',
              title:
                'Z03.8 Encounter for observation for other suspected diseases and conditions ruled out',
              children: [
                {
                  value: 'Z03.81',
                  title:
                    'Z03.81 Encounter for observation for suspected exposure to biological agents ruled out',
                  children: [
                    {
                      value: 'Z03.810',
                      title:
                        'Z03.810 Encounter for observation for suspected exposure to anthrax ruled out',
                    },
                    {
                      value: 'Z03.818',
                      title:
                        'Z03.818 Encounter for observation for suspected exposure to other biological agents ruled out',
                    },
                  ],
                },
                {
                  value: 'Z03.82',
                  title:
                    'Z03.82 Encounter for observation for suspected foreign body ruled out',
                  children: [
                    {
                      value: 'Z03.821',
                      title:
                        'Z03.821 Encounter for observation for suspected ingested foreign body ruled out',
                    },
                    {
                      value: 'Z03.822',
                      title:
                        'Z03.822 Encounter for observation for suspected aspirated (inhaled) foreign body ruled out',
                    },
                    {
                      value: 'Z03.823',
                      title:
                        'Z03.823 Encounter for observation for suspected inserted (injected) foreign body ruled out',
                    },
                  ],
                },
                {
                  value: 'Z03.83',
                  title:
                    'Z03.83 Encounter for observation for suspected conditions related to home physiologic monitoring device ruled out',
                },
                {
                  value: 'Z03.89',
                  title:
                    'Z03.89 Encounter for observation for other suspected diseases and conditions ruled out',
                },
              ],
            },
          ],
        },
        {
          value: 'Z04',
          title:
            'Z04 Encounter for examination and observation for other reasons',
          children: [
            {
              value: 'Z04.1',
              title:
                'Z04.1 Encounter for examination and observation following transport accident',
            },
            {
              value: 'Z04.2',
              title:
                'Z04.2 Encounter for examination and observation following work accident',
            },
            {
              value: 'Z04.3',
              title:
                'Z04.3 Encounter for examination and observation following other accident',
            },
            {
              value: 'Z04.4',
              title:
                'Z04.4 Encounter for examination and observation following alleged rape',
              children: [
                {
                  value: 'Z04.41',
                  title:
                    'Z04.41 Encounter for examination and observation following alleged adult rape',
                },
                {
                  value: 'Z04.42',
                  title:
                    'Z04.42 Encounter for examination and observation following alleged child rape',
                },
              ],
            },
            {
              value: 'Z04.6',
              title:
                'Z04.6 Encounter for general psychiatric examination, requested by authority',
            },
            {
              value: 'Z04.7',
              title:
                'Z04.7 Encounter for examination and observation following alleged physical abuse',
              children: [
                {
                  value: 'Z04.71',
                  title:
                    'Z04.71 Encounter for examination and observation following alleged adult physical abuse',
                },
                {
                  value: 'Z04.72',
                  title:
                    'Z04.72 Encounter for examination and observation following alleged child physical abuse',
                },
              ],
            },
            {
              value: 'Z04.8',
              title:
                'Z04.8 Encounter for examination and observation for other specified reasons',
              children: [
                {
                  value: 'Z04.81',
                  title:
                    'Z04.81 Encounter for examination and observation of victim following forced sexual exploitation',
                },
                {
                  value: 'Z04.82',
                  title:
                    'Z04.82 Encounter for examination and observation of victim following forced labor exploitation',
                },
                {
                  value: 'Z04.89',
                  title:
                    'Z04.89 Encounter for examination and observation for other specified reasons',
                },
              ],
            },
            {
              value: 'Z04.9',
              title:
                'Z04.9 Encounter for examination and observation for unspecified reason',
            },
          ],
        },
        {
          value: 'Z05',
          title:
            'Z05 Encounter for observation and evaluation of newborn for suspected diseases and conditions ruled out',
          children: [
            {
              value: 'Z05.0',
              title:
                'Z05.0 Observation and evaluation of newborn for suspected cardiac condition ruled out',
            },
            {
              value: 'Z05.1',
              title:
                'Z05.1 Observation and evaluation of newborn for suspected infectious condition ruled out',
            },
            {
              value: 'Z05.2',
              title:
                'Z05.2 Observation and evaluation of newborn for suspected neurological condition ruled out',
            },
            {
              value: 'Z05.3',
              title:
                'Z05.3 Observation and evaluation of newborn for suspected respiratory condition ruled out',
            },
            {
              value: 'Z05.4',
              title:
                'Z05.4 Observation and evaluation of newborn for suspected genetic, metabolic or immunologic condition ruled out',
              children: [
                {
                  value: 'Z05.41',
                  title:
                    'Z05.41 Observation and evaluation of newborn for suspected genetic condition ruled out',
                },
                {
                  value: 'Z05.42',
                  title:
                    'Z05.42 Observation and evaluation of newborn for suspected metabolic condition ruled out',
                },
                {
                  value: 'Z05.43',
                  title:
                    'Z05.43 Observation and evaluation of newborn for suspected immunologic condition ruled out',
                },
              ],
            },
            {
              value: 'Z05.5',
              title:
                'Z05.5 Observation and evaluation of newborn for suspected gastrointestinal condition ruled out',
            },
            {
              value: 'Z05.6',
              title:
                'Z05.6 Observation and evaluation of newborn for suspected genitourinary condition ruled out',
            },
            {
              value: 'Z05.7',
              title:
                'Z05.7 Observation and evaluation of newborn for suspected skin, subcutaneous, musculoskeletal and connective tissue condition ruled out',
              children: [
                {
                  value: 'Z05.71',
                  title:
                    'Z05.71 Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition ruled out',
                },
                {
                  value: 'Z05.72',
                  title:
                    'Z05.72 Observation and evaluation of newborn for suspected musculoskeletal condition ruled out',
                },
                {
                  value: 'Z05.73',
                  title:
                    'Z05.73 Observation and evaluation of newborn for suspected connective tissue condition ruled out',
                },
              ],
            },
            {
              value: 'Z05.8',
              title:
                'Z05.8 Observation and evaluation of newborn for other specified suspected condition ruled out',
            },
            {
              value: 'Z05.9',
              title:
                'Z05.9 Observation and evaluation of newborn for unspecified suspected condition ruled out',
            },
          ],
        },
        {
          value: 'Z08',
          title:
            'Z08 Encounter for follow-up examination after completed treatment for malignant neoplasm',
        },
        {
          value: 'Z09',
          title:
            'Z09 Encounter for follow-up examination after completed treatment for conditions other than malignant neoplasm',
        },
        {
          value: 'Z11',
          title:
            'Z11 Encounter for screening for infectious and parasitic diseases',
          children: [
            {
              value: 'Z11.0',
              title:
                'Z11.0 Encounter for screening for intestinal infectious diseases',
            },
            {
              value: 'Z11.1',
              title:
                'Z11.1 Encounter for screening for respiratory tuberculosis',
            },
            {
              value: 'Z11.2',
              title:
                'Z11.2 Encounter for screening for other bacterial diseases',
            },
            {
              value: 'Z11.3',
              title:
                'Z11.3 Encounter for screening for infections with a predominantly sexual mode of transmission',
            },
            {
              value: 'Z11.4',
              title:
                'Z11.4 Encounter for screening for human immunodeficiency virus [HIV]',
            },
            {
              value: 'Z11.5',
              title: 'Z11.5 Encounter for screening for other viral diseases',
              children: [
                {
                  value: 'Z11.51',
                  title:
                    'Z11.51 Encounter for screening for human papillomavirus (HPV)',
                },
                {
                  value: 'Z11.52',
                  title: 'Z11.52 Encounter for screening for COVID-19',
                },
                {
                  value: 'Z11.59',
                  title:
                    'Z11.59 Encounter for screening for other viral diseases',
                },
              ],
            },
            {
              value: 'Z11.6',
              title:
                'Z11.6 Encounter for screening for other protozoal diseases and helminthiases',
            },
            {
              value: 'Z11.7',
              title:
                'Z11.7 Encounter for testing for latent tuberculosis infection',
            },
            {
              value: 'Z11.8',
              title:
                'Z11.8 Encounter for screening for other infectious and parasitic diseases',
            },
            {
              value: 'Z11.9',
              title:
                'Z11.9 Encounter for screening for infectious and parasitic diseases, unspecified',
            },
          ],
        },
        {
          value: 'Z12',
          title: 'Z12 Encounter for screening for malignant neoplasms',
          children: [
            {
              value: 'Z12.0',
              title:
                'Z12.0 Encounter for screening for malignant neoplasm of stomach',
            },
            {
              value: 'Z12.1',
              title:
                'Z12.1 Encounter for screening for malignant neoplasm of intestinal tract',
              children: [
                {
                  value: 'Z12.10',
                  title:
                    'Z12.10 Encounter for screening for malignant neoplasm of intestinal tract, unspecified',
                },
                {
                  value: 'Z12.11',
                  title:
                    'Z12.11 Encounter for screening for malignant neoplasm of colon',
                },
                {
                  value: 'Z12.12',
                  title:
                    'Z12.12 Encounter for screening for malignant neoplasm of rectum',
                },
                {
                  value: 'Z12.13',
                  title:
                    'Z12.13 Encounter for screening for malignant neoplasm of small intestine',
                },
              ],
            },
            {
              value: 'Z12.2',
              title:
                'Z12.2 Encounter for screening for malignant neoplasm of respiratory organs',
            },
            {
              value: 'Z12.3',
              title:
                'Z12.3 Encounter for screening for malignant neoplasm of breast',
              children: [
                {
                  value: 'Z12.31',
                  title:
                    'Z12.31 Encounter for screening mammogram for malignant neoplasm of breast',
                },
                {
                  value: 'Z12.39',
                  title:
                    'Z12.39 Encounter for other screening for malignant neoplasm of breast',
                },
              ],
            },
            {
              value: 'Z12.4',
              title:
                'Z12.4 Encounter for screening for malignant neoplasm of cervix',
            },
            {
              value: 'Z12.5',
              title:
                'Z12.5 Encounter for screening for malignant neoplasm of prostate',
            },
            {
              value: 'Z12.6',
              title:
                'Z12.6 Encounter for screening for malignant neoplasm of bladder',
            },
            {
              value: 'Z12.7',
              title:
                'Z12.7 Encounter for screening for malignant neoplasm of other genitourinary organs',
              children: [
                {
                  value: 'Z12.71',
                  title:
                    'Z12.71 Encounter for screening for malignant neoplasm of testis',
                },
                {
                  value: 'Z12.72',
                  title:
                    'Z12.72 Encounter for screening for malignant neoplasm of vagina',
                },
                {
                  value: 'Z12.73',
                  title:
                    'Z12.73 Encounter for screening for malignant neoplasm of ovary',
                },
                {
                  value: 'Z12.79',
                  title:
                    'Z12.79 Encounter for screening for malignant neoplasm of other genitourinary organs',
                },
              ],
            },
            {
              value: 'Z12.8',
              title:
                'Z12.8 Encounter for screening for malignant neoplasm of other sites',
              children: [
                {
                  value: 'Z12.81',
                  title:
                    'Z12.81 Encounter for screening for malignant neoplasm of oral cavity',
                },
                {
                  value: 'Z12.82',
                  title:
                    'Z12.82 Encounter for screening for malignant neoplasm of nervous system',
                },
                {
                  value: 'Z12.83',
                  title:
                    'Z12.83 Encounter for screening for malignant neoplasm of skin',
                },
                {
                  value: 'Z12.89',
                  title:
                    'Z12.89 Encounter for screening for malignant neoplasm of other sites',
                },
              ],
            },
            {
              value: 'Z12.9',
              title:
                'Z12.9 Encounter for screening for malignant neoplasm, site unspecified',
            },
          ],
        },
        {
          value: 'Z13',
          title: 'Z13 Encounter for screening for other diseases and disorders',
          children: [
            {
              value: 'Z13.0',
              title:
                'Z13.0 Encounter for screening for diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
            },
            {
              value: 'Z13.1',
              title: 'Z13.1 Encounter for screening for diabetes mellitus',
            },
            {
              value: 'Z13.2',
              title:
                'Z13.2 Encounter for screening for nutritional, metabolic and other endocrine disorders',
              children: [
                {
                  value: 'Z13.21',
                  title:
                    'Z13.21 Encounter for screening for nutritional disorder',
                },
                {
                  value: 'Z13.22',
                  title:
                    'Z13.22 Encounter for screening for metabolic disorder',
                  children: [
                    {
                      value: 'Z13.220',
                      title:
                        'Z13.220 Encounter for screening for lipoid disorders',
                    },
                    {
                      value: 'Z13.228',
                      title:
                        'Z13.228 Encounter for screening for other metabolic disorders',
                    },
                  ],
                },
                {
                  value: 'Z13.29',
                  title:
                    'Z13.29 Encounter for screening for other suspected endocrine disorder',
                },
              ],
            },
            {
              value: 'Z13.3',
              title:
                'Z13.3 Encounter for screening examination for mental health and behavioral disorders',
              children: [
                {
                  value: 'Z13.30',
                  title:
                    'Z13.30 Encounter for screening examination for mental health and behavioral disorders, unspecified',
                },
                {
                  value: 'Z13.31',
                  title: 'Z13.31 Encounter for screening for depression',
                },
                {
                  value: 'Z13.32',
                  title:
                    'Z13.32 Encounter for screening for maternal depression',
                },
                {
                  value: 'Z13.39',
                  title:
                    'Z13.39 Encounter for screening examination for other mental health and behavioral disorders',
                },
              ],
            },
            {
              value: 'Z13.4',
              title:
                'Z13.4 Encounter for screening for certain developmental disorders in childhood',
              children: [
                {
                  value: 'Z13.40',
                  title:
                    'Z13.40 Encounter for screening for unspecified developmental delays',
                },
                {
                  value: 'Z13.41',
                  title: 'Z13.41 Encounter for autism screening',
                },
                {
                  value: 'Z13.42',
                  title:
                    'Z13.42 Encounter for screening for global developmental delays (milestones)',
                },
                {
                  value: 'Z13.49',
                  title:
                    'Z13.49 Encounter for screening for other developmental delays',
                },
              ],
            },
            {
              value: 'Z13.5',
              title: 'Z13.5 Encounter for screening for eye and ear disorders',
            },
            {
              value: 'Z13.6',
              title:
                'Z13.6 Encounter for screening for cardiovascular disorders',
            },
            {
              value: 'Z13.7',
              title:
                'Z13.7 Encounter for screening for genetic and chromosomal anomalies',
              children: [
                {
                  value: 'Z13.71',
                  title:
                    'Z13.71 Encounter for nonprocreative screening for genetic disease carrier status',
                },
                {
                  value: 'Z13.79',
                  title:
                    'Z13.79 Encounter for other screening for genetic and chromosomal anomalies',
                },
              ],
            },
            {
              value: 'Z13.8',
              title:
                'Z13.8 Encounter for screening for other specified diseases and disorders',
              children: [
                {
                  value: 'Z13.81',
                  title:
                    'Z13.81 Encounter for screening for digestive system disorders',
                  children: [
                    {
                      value: 'Z13.810',
                      title:
                        'Z13.810 Encounter for screening for upper gastrointestinal disorder',
                    },
                    {
                      value: 'Z13.811',
                      title:
                        'Z13.811 Encounter for screening for lower gastrointestinal disorder',
                    },
                    {
                      value: 'Z13.818',
                      title:
                        'Z13.818 Encounter for screening for other digestive system disorders',
                    },
                  ],
                },
                {
                  value: 'Z13.82',
                  title:
                    'Z13.82 Encounter for screening for musculoskeletal disorder',
                  children: [
                    {
                      value: 'Z13.820',
                      title: 'Z13.820 Encounter for screening for osteoporosis',
                    },
                    {
                      value: 'Z13.828',
                      title:
                        'Z13.828 Encounter for screening for other musculoskeletal disorder',
                    },
                  ],
                },
                {
                  value: 'Z13.83',
                  title:
                    'Z13.83 Encounter for screening for respiratory disorder NEC',
                },
                {
                  value: 'Z13.84',
                  title: 'Z13.84 Encounter for screening for dental disorders',
                },
                {
                  value: 'Z13.85',
                  title:
                    'Z13.85 Encounter for screening for nervous system disorders',
                  children: [
                    {
                      value: 'Z13.850',
                      title:
                        'Z13.850 Encounter for screening for traumatic brain injury',
                    },
                    {
                      value: 'Z13.858',
                      title:
                        'Z13.858 Encounter for screening for other nervous system disorders',
                    },
                  ],
                },
                {
                  value: 'Z13.88',
                  title:
                    'Z13.88 Encounter for screening for disorder due to exposure to contaminants',
                },
                {
                  value: 'Z13.89',
                  title: 'Z13.89 Encounter for screening for other disorder',
                },
              ],
            },
            {
              value: 'Z13.9',
              title: 'Z13.9 Encounter for screening, unspecified',
            },
          ],
        },
      ],
    },
    {
      value: 'Z14-Z15',
      title: 'Z14-Z15 Genetic carrier and genetic susceptibility to disease',
      children: [
        {
          value: 'Z14',
          title: 'Z14 Genetic carrier',
          children: [
            {
              value: 'Z14.0',
              title: 'Z14.0 Hemophilia A carrier',
              children: [
                {
                  value: 'Z14.01',
                  title: 'Z14.01 Asymptomatic hemophilia A carrier',
                },
                {
                  value: 'Z14.02',
                  title: 'Z14.02 Symptomatic hemophilia A carrier',
                },
              ],
            },
            {
              value: 'Z14.1',
              title: 'Z14.1 Cystic fibrosis carrier',
            },
            {
              value: 'Z14.8',
              title: 'Z14.8 Genetic carrier of other disease',
            },
          ],
        },
        {
          value: 'Z15',
          title: 'Z15 Genetic susceptibility to disease',
          children: [
            {
              value: 'Z15.0',
              title: 'Z15.0 Genetic susceptibility to malignant neoplasm',
              children: [
                {
                  value: 'Z15.01',
                  title:
                    'Z15.01 Genetic susceptibility to malignant neoplasm of breast',
                },
                {
                  value: 'Z15.02',
                  title:
                    'Z15.02 Genetic susceptibility to malignant neoplasm of ovary',
                },
                {
                  value: 'Z15.03',
                  title:
                    'Z15.03 Genetic susceptibility to malignant neoplasm of prostate',
                },
                {
                  value: 'Z15.04',
                  title:
                    'Z15.04 Genetic susceptibility to malignant neoplasm of endometrium',
                },
                {
                  value: 'Z15.09',
                  title:
                    'Z15.09 Genetic susceptibility to other malignant neoplasm',
                },
              ],
            },
            {
              value: 'Z15.8',
              title: 'Z15.8 Genetic susceptibility to other disease',
              children: [
                {
                  value: 'Z15.81',
                  title:
                    'Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN]',
                },
                {
                  value: 'Z15.89',
                  title: 'Z15.89 Genetic susceptibility to other disease',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'Z16',
      title: 'Z16 Resistance to antimicrobial drugs',
      children: [
        {
          value: 'Z16.my',
          title: 'Z16 Resistance to antimicrobial drugs',
          children: [
            {
              value: 'Z16.1',
              title: 'Z16.1 Resistance to beta lactam antibiotics',
              children: [
                {
                  value: 'Z16.10',
                  title:
                    'Z16.10 Resistance to unspecified beta lactam antibiotics',
                },
                {
                  value: 'Z16.11',
                  title: 'Z16.11 Resistance to penicillins',
                },
                {
                  value: 'Z16.12',
                  title:
                    'Z16.12 Extended spectrum beta lactamase (ESBL) resistance',
                },
                {
                  value: 'Z16.19',
                  title:
                    'Z16.19 Resistance to other specified beta lactam antibiotics',
                },
              ],
            },
            {
              value: 'Z16.2',
              title: 'Z16.2 Resistance to other antibiotics',
              children: [
                {
                  value: 'Z16.20',
                  title: 'Z16.20 Resistance to unspecified antibiotic',
                },
                {
                  value: 'Z16.21',
                  title: 'Z16.21 Resistance to vancomycin',
                },
                {
                  value: 'Z16.22',
                  title: 'Z16.22 Resistance to vancomycin related antibiotics',
                },
                {
                  value: 'Z16.23',
                  title: 'Z16.23 Resistance to quinolones and fluoroquinolones',
                },
                {
                  value: 'Z16.24',
                  title: 'Z16.24 Resistance to multiple antibiotics',
                },
                {
                  value: 'Z16.29',
                  title:
                    'Z16.29 Resistance to other single specified antibiotic',
                },
              ],
            },
            {
              value: 'Z16.3',
              title: 'Z16.3 Resistance to other antimicrobial drugs',
              children: [
                {
                  value: 'Z16.30',
                  title: 'Z16.30 Resistance to unspecified antimicrobial drugs',
                },
                {
                  value: 'Z16.31',
                  title: 'Z16.31 Resistance to antiparasitic drug(s)',
                },
                {
                  value: 'Z16.32',
                  title: 'Z16.32 Resistance to antifungal drug(s)',
                },
                {
                  value: 'Z16.33',
                  title: 'Z16.33 Resistance to antiviral drug(s)',
                },
                {
                  value: 'Z16.34',
                  title: 'Z16.34 Resistance to antimycobacterial drug(s)',
                  children: [
                    {
                      value: 'Z16.341',
                      title:
                        'Z16.341 Resistance to single antimycobacterial drug',
                    },
                    {
                      value: 'Z16.342',
                      title:
                        'Z16.342 Resistance to multiple antimycobacterial drugs',
                    },
                  ],
                },
                {
                  value: 'Z16.35',
                  title: 'Z16.35 Resistance to multiple antimicrobial drugs',
                },
                {
                  value: 'Z16.39',
                  title:
                    'Z16.39 Resistance to other specified antimicrobial drug',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'Z17',
      title: 'Z17 Estrogen receptor status',
      children: [
        {
          value: 'Z17.my',
          title: 'Z17 Estrogen receptor status',
          children: [
            {
              value: 'Z17.0',
              title: 'Z17.0 Estrogen receptor positive status [ER+]',
            },
            {
              value: 'Z17.1',
              title: 'Z17.1 Estrogen receptor negative status [ER-]',
            },
          ],
        },
      ],
    },
    {
      value: 'Z18',
      title: 'Z18 Retained foreign body fragments',
      children: [
        {
          value: 'Z18.my',
          title: 'Z18 Retained foreign body fragments',
          children: [
            {
              value: 'Z18.0',
              title: 'Z18.0 Retained radioactive fragments',
              children: [
                {
                  value: 'Z18.01',
                  title: 'Z18.01 Retained depleted uranium fragments',
                },
                {
                  value: 'Z18.09',
                  title: 'Z18.09 Other retained radioactive fragments',
                },
              ],
            },
            {
              value: 'Z18.1',
              title: 'Z18.1 Retained metal fragments',
              children: [
                {
                  value: 'Z18.10',
                  title: 'Z18.10 Retained metal fragments, unspecified',
                },
                {
                  value: 'Z18.11',
                  title: 'Z18.11 Retained magnetic metal fragments',
                },
                {
                  value: 'Z18.12',
                  title: 'Z18.12 Retained nonmagnetic metal fragments',
                },
              ],
            },
            {
              value: 'Z18.2',
              title: 'Z18.2 Retained plastic fragments',
            },
            {
              value: 'Z18.3',
              title: 'Z18.3 Retained organic fragments',
              children: [
                {
                  value: 'Z18.31',
                  title: 'Z18.31 Retained animal quills or spines',
                },
                {
                  value: 'Z18.32',
                  title: 'Z18.32 Retained tooth',
                },
                {
                  value: 'Z18.33',
                  title: 'Z18.33 Retained wood fragments',
                },
                {
                  value: 'Z18.39',
                  title: 'Z18.39 Other retained organic fragments',
                },
              ],
            },
            {
              value: 'Z18.8',
              title: 'Z18.8 Other specified retained foreign body',
              children: [
                {
                  value: 'Z18.81',
                  title: 'Z18.81 Retained glass fragments',
                },
                {
                  value: 'Z18.83',
                  title: 'Z18.83 Retained stone or crystalline fragments',
                },
                {
                  value: 'Z18.89',
                  title:
                    'Z18.89 Other specified retained foreign body fragments',
                },
              ],
            },
            {
              value: 'Z18.9',
              title:
                'Z18.9 Retained foreign body fragments, unspecified material',
            },
          ],
        },
      ],
    },
    {
      value: 'Z19',
      title: 'Z19 Hormone sensitivity malignancy status',
      children: [
        {
          value: 'Z19.my',
          title: 'Z19 Hormone sensitivity malignancy status',
          children: [
            {
              value: 'Z19.1',
              title: 'Z19.1 Hormone sensitive malignancy status',
            },
            {
              value: 'Z19.2',
              title: 'Z19.2 Hormone resistant malignancy status',
            },
          ],
        },
      ],
    },
    {
      value: 'Z20-Z29',
      title:
        'Z20-Z29 Persons with potential health hazards related to communicable diseases',
      children: [
        {
          value: 'Z20',
          title:
            'Z20 Contact with and (suspected) exposure to communicable diseases',
          children: [
            {
              value: 'Z20.0',
              title:
                'Z20.0 Contact with and (suspected) exposure to intestinal infectious diseases',
              children: [
                {
                  value: 'Z20.01',
                  title:
                    'Z20.01 Contact with and (suspected) exposure to intestinal infectious diseases due to Escherichia coli (E. coli)',
                },
                {
                  value: 'Z20.09',
                  title:
                    'Z20.09 Contact with and (suspected) exposure to other intestinal infectious diseases',
                },
              ],
            },
            {
              value: 'Z20.1',
              title:
                'Z20.1 Contact with and (suspected) exposure to tuberculosis',
            },
            {
              value: 'Z20.2',
              title:
                'Z20.2 Contact with and (suspected) exposure to infections with a predominantly sexual mode of transmission',
            },
            {
              value: 'Z20.3',
              title: 'Z20.3 Contact with and (suspected) exposure to rabies',
            },
            {
              value: 'Z20.4',
              title: 'Z20.4 Contact with and (suspected) exposure to rubella',
            },
            {
              value: 'Z20.5',
              title:
                'Z20.5 Contact with and (suspected) exposure to viral hepatitis',
            },
            {
              value: 'Z20.6',
              title:
                'Z20.6 Contact with and (suspected) exposure to human immunodeficiency virus [HIV]',
            },
            {
              value: 'Z20.7',
              title:
                'Z20.7 Contact with and (suspected) exposure to pediculosis, acariasis and other infestations',
            },
            {
              value: 'Z20.8',
              title:
                'Z20.8 Contact with and (suspected) exposure to other communicable diseases',
              children: [
                {
                  value: 'Z20.81',
                  title:
                    'Z20.81 Contact with and (suspected) exposure to other bacterial communicable diseases',
                  children: [
                    {
                      value: 'Z20.810',
                      title:
                        'Z20.810 Contact with and (suspected) exposure to anthrax',
                    },
                    {
                      value: 'Z20.811',
                      title:
                        'Z20.811 Contact with and (suspected) exposure to meningococcus',
                    },
                    {
                      value: 'Z20.818',
                      title:
                        'Z20.818 Contact with and (suspected) exposure to other bacterial communicable diseases',
                    },
                  ],
                },
                {
                  value: 'Z20.82',
                  title:
                    'Z20.82 Contact with and (suspected) exposure to other viral communicable diseases',
                  children: [
                    {
                      value: 'Z20.820',
                      title:
                        'Z20.820 Contact with and (suspected) exposure to varicella',
                    },
                    {
                      value: 'Z20.821',
                      title:
                        'Z20.821 Contact with and (suspected) exposure to Zika virus',
                    },
                    {
                      value: 'Z20.822',
                      title:
                        'Z20.822 Contact with and (suspected) exposure to COVID-19',
                    },
                    {
                      value: 'Z20.828',
                      title:
                        'Z20.828 Contact with and (suspected) exposure to other viral communicable diseases',
                    },
                  ],
                },
                {
                  value: 'Z20.89',
                  title:
                    'Z20.89 Contact with and (suspected) exposure to other communicable diseases',
                },
              ],
            },
            {
              value: 'Z20.9',
              title:
                'Z20.9 Contact with and (suspected) exposure to unspecified communicable disease',
            },
          ],
        },
        {
          value: 'Z21',
          title:
            'Z21 Asymptomatic human immunodeficiency virus [HIV] infection status',
        },
        {
          value: 'Z22',
          title: 'Z22 Carrier of infectious disease',
          children: [
            {
              value: 'Z22.0',
              title: 'Z22.0 Carrier of typhoid',
            },
            {
              value: 'Z22.1',
              title: 'Z22.1 Carrier of other intestinal infectious diseases',
            },
            {
              value: 'Z22.2',
              title: 'Z22.2 Carrier of diphtheria',
            },
            {
              value: 'Z22.3',
              title: 'Z22.3 Carrier of other specified bacterial diseases',
              children: [
                {
                  value: 'Z22.31',
                  title:
                    'Z22.31 Carrier of bacterial disease due to meningococci',
                },
                {
                  value: 'Z22.32',
                  title:
                    'Z22.32 Carrier of bacterial disease due to staphylococci',
                  children: [
                    {
                      value: 'Z22.321',
                      title:
                        'Z22.321 Carrier or suspected carrier of Methicillin susceptible Staphylococcus aureus',
                    },
                    {
                      value: 'Z22.322',
                      title:
                        'Z22.322 Carrier or suspected carrier of Methicillin resistant Staphylococcus aureus',
                    },
                  ],
                },
                {
                  value: 'Z22.33',
                  title:
                    'Z22.33 Carrier of bacterial disease due to streptococci',
                  children: [
                    {
                      value: 'Z22.330',
                      title: 'Z22.330 Carrier of Group B streptococcus',
                    },
                    {
                      value: 'Z22.338',
                      title: 'Z22.338 Carrier of other streptococcus',
                    },
                  ],
                },
                {
                  value: 'Z22.39',
                  title: 'Z22.39 Carrier of other specified bacterial diseases',
                },
              ],
            },
            {
              value: 'Z22.4',
              title:
                'Z22.4 Carrier of infections with a predominantly sexual mode of transmission',
            },
            {
              value: 'Z22.6',
              title:
                'Z22.6 Carrier of human T-lymphotropic virus type-1 [HTLV-1] infection',
            },
            {
              value: 'Z22.7',
              title: 'Z22.7 Latent tuberculosis',
            },
            {
              value: 'Z22.8',
              title: 'Z22.8 Carrier of other infectious diseases',
            },
            {
              value: 'Z22.9',
              title: 'Z22.9 Carrier of infectious disease, unspecified',
            },
          ],
        },
        {
          value: 'Z23',
          title: 'Z23 Encounter for immunization',
        },
        {
          value: 'Z28',
          title:
            'Z28 Immunization not carried out and underimmunization status',
          children: [
            {
              value: 'Z28.0',
              title:
                'Z28.0 Immunization not carried out because of contraindication',
              children: [
                {
                  value: 'Z28.01',
                  title:
                    'Z28.01 Immunization not carried out because of acute illness of patient',
                },
                {
                  value: 'Z28.02',
                  title:
                    'Z28.02 Immunization not carried out because of chronic illness or condition of patient',
                },
                {
                  value: 'Z28.03',
                  title:
                    'Z28.03 Immunization not carried out because of immune compromised state of patient',
                },
                {
                  value: 'Z28.04',
                  title:
                    'Z28.04 Immunization not carried out because of patient allergy to vaccine or component',
                },
                {
                  value: 'Z28.09',
                  title:
                    'Z28.09 Immunization not carried out because of other contraindication',
                },
              ],
            },
            {
              value: 'Z28.1',
              title:
                'Z28.1 Immunization not carried out because of patient decision for reasons of belief or group pressure',
            },
            {
              value: 'Z28.2',
              title:
                'Z28.2 Immunization not carried out because of patient decision for other and unspecified reason',
              children: [
                {
                  value: 'Z28.20',
                  title:
                    'Z28.20 Immunization not carried out because of patient decision for unspecified reason',
                },
                {
                  value: 'Z28.21',
                  title:
                    'Z28.21 Immunization not carried out because of patient refusal',
                },
                {
                  value: 'Z28.29',
                  title:
                    'Z28.29 Immunization not carried out because of patient decision for other reason',
                },
              ],
            },
            {
              value: 'Z28.3',
              title: 'Z28.3 Underimmunization status',
              children: [
                {
                  value: 'Z28.31',
                  title: 'Z28.31 Underimmunization for COVID-19 status',
                  children: [
                    {
                      value: 'Z28.310',
                      title: 'Z28.310 Unvaccinated for COVID-19',
                    },
                    {
                      value: 'Z28.311',
                      title: 'Z28.311 Partially vaccinated for COVID-19',
                    },
                  ],
                },
                {
                  value: 'Z28.39',
                  title: 'Z28.39 Other underimmunization status',
                },
              ],
            },
            {
              value: 'Z28.8',
              title: 'Z28.8 Immunization not carried out for other reason',
              children: [
                {
                  value: 'Z28.81',
                  title:
                    'Z28.81 Immunization not carried out due to patient having had the disease',
                },
                {
                  value: 'Z28.82',
                  title:
                    'Z28.82 Immunization not carried out because of caregiver refusal',
                },
                {
                  value: 'Z28.83',
                  title:
                    'Z28.83 Immunization not carried out due to unavailability of vaccine',
                },
                {
                  value: 'Z28.89',
                  title: 'Z28.89 Immunization not carried out for other reason',
                },
              ],
            },
            {
              value: 'Z28.9',
              title:
                'Z28.9 Immunization not carried out for unspecified reason',
            },
          ],
        },
        {
          value: 'Z29',
          title: 'Z29 Encounter for other prophylactic measures',
          children: [
            {
              value: 'Z29.1',
              title: 'Z29.1 Encounter for prophylactic immunotherapy',
              children: [
                {
                  value: 'Z29.11',
                  title:
                    'Z29.11 Encounter for prophylactic immunotherapy for respiratory syncytial virus (RSV)',
                },
                {
                  value: 'Z29.12',
                  title: 'Z29.12 Encounter for prophylactic antivenin',
                },
                {
                  value: 'Z29.13',
                  title:
                    'Z29.13 Encounter for prophylactic Rho(D) immune globulin',
                },
                {
                  value: 'Z29.14',
                  title:
                    'Z29.14 Encounter for prophylactic rabies immune globin',
                },
              ],
            },
            {
              value: 'Z29.3',
              title: 'Z29.3 Encounter for prophylactic fluoride administration',
            },
            {
              value: 'Z29.8',
              title:
                'Z29.8 Encounter for other specified prophylactic measures',
            },
            {
              value: 'Z29.9',
              title: 'Z29.9 Encounter for prophylactic measures, unspecified',
            },
          ],
        },
      ],
    },
    {
      value: 'Z30-Z39',
      title:
        'Z30-Z39 Persons encountering health services in circumstances related to reproduction',
      children: [
        {
          value: 'Z30',
          title: 'Z30 Encounter for contraceptive management',
          children: [
            {
              value: 'Z30.0',
              title:
                'Z30.0 Encounter for general counseling and advice on contraception',
              children: [
                {
                  value: 'Z30.01',
                  title:
                    'Z30.01 Encounter for initial prescription of contraceptives',
                  children: [
                    {
                      value: 'Z30.011',
                      title:
                        'Z30.011 Encounter for initial prescription of contraceptive pills',
                    },
                    {
                      value: 'Z30.012',
                      title:
                        'Z30.012 Encounter for prescription of emergency contraception',
                    },
                    {
                      value: 'Z30.013',
                      title:
                        'Z30.013 Encounter for initial prescription of injectable contraceptive',
                    },
                    {
                      value: 'Z30.014',
                      title:
                        'Z30.014 Encounter for initial prescription of intrauterine contraceptive device',
                    },
                    {
                      value: 'Z30.015',
                      title:
                        'Z30.015 Encounter for initial prescription of vaginal ring hormonal contraceptive',
                    },
                    {
                      value: 'Z30.016',
                      title:
                        'Z30.016 Encounter for initial prescription of transdermal patch hormonal contraceptive device',
                    },
                    {
                      value: 'Z30.017',
                      title:
                        'Z30.017 Encounter for initial prescription of implantable subdermal contraceptive',
                    },
                    {
                      value: 'Z30.018',
                      title:
                        'Z30.018 Encounter for initial prescription of other contraceptives',
                    },
                    {
                      value: 'Z30.019',
                      title:
                        'Z30.019 Encounter for initial prescription of contraceptives, unspecified',
                    },
                  ],
                },
                {
                  value: 'Z30.02',
                  title:
                    'Z30.02 Counseling and instruction in natural family planning to avoid pregnancy',
                },
                {
                  value: 'Z30.09',
                  title:
                    'Z30.09 Encounter for other general counseling and advice on contraception',
                },
              ],
            },
            {
              value: 'Z30.2',
              title: 'Z30.2 Encounter for sterilization',
            },
            {
              value: 'Z30.4',
              title: 'Z30.4 Encounter for surveillance of contraceptives',
              children: [
                {
                  value: 'Z30.40',
                  title:
                    'Z30.40 Encounter for surveillance of contraceptives, unspecified',
                },
                {
                  value: 'Z30.41',
                  title:
                    'Z30.41 Encounter for surveillance of contraceptive pills',
                },
                {
                  value: 'Z30.42',
                  title:
                    'Z30.42 Encounter for surveillance of injectable contraceptive',
                },
                {
                  value: 'Z30.43',
                  title:
                    'Z30.43 Encounter for surveillance of intrauterine contraceptive device',
                  children: [
                    {
                      value: 'Z30.430',
                      title:
                        'Z30.430 Encounter for insertion of intrauterine contraceptive device',
                    },
                    {
                      value: 'Z30.431',
                      title:
                        'Z30.431 Encounter for routine checking of intrauterine contraceptive device',
                    },
                    {
                      value: 'Z30.432',
                      title:
                        'Z30.432 Encounter for removal of intrauterine contraceptive device',
                    },
                    {
                      value: 'Z30.433',
                      title:
                        'Z30.433 Encounter for removal and reinsertion of intrauterine contraceptive device',
                    },
                  ],
                },
                {
                  value: 'Z30.44',
                  title:
                    'Z30.44 Encounter for surveillance of vaginal ring hormonal contraceptive device',
                },
                {
                  value: 'Z30.45',
                  title:
                    'Z30.45 Encounter for surveillance of transdermal patch hormonal contraceptive device',
                },
                {
                  value: 'Z30.46',
                  title:
                    'Z30.46 Encounter for surveillance of implantable subdermal contraceptive',
                },
                {
                  value: 'Z30.49',
                  title:
                    'Z30.49 Encounter for surveillance of other contraceptives',
                },
              ],
            },
            {
              value: 'Z30.8',
              title: 'Z30.8 Encounter for other contraceptive management',
            },
            {
              value: 'Z30.9',
              title:
                'Z30.9 Encounter for contraceptive management, unspecified',
            },
          ],
        },
        {
          value: 'Z31',
          title: 'Z31 Encounter for procreative management',
          children: [
            {
              value: 'Z31.0',
              title: 'Z31.0 Encounter for reversal of previous sterilization',
            },
            {
              value: 'Z31.4',
              title:
                'Z31.4 Encounter for procreative investigation and testing',
              children: [
                {
                  value: 'Z31.41',
                  title: 'Z31.41 Encounter for fertility testing',
                },
                {
                  value: 'Z31.42',
                  title: 'Z31.42 Aftercare following sterilization reversal',
                },
                {
                  value: 'Z31.43',
                  title:
                    'Z31.43 Encounter for genetic testing of female for procreative management',
                  children: [
                    {
                      value: 'Z31.430',
                      title:
                        'Z31.430 Encounter of female for testing for genetic disease carrier status for procreative management',
                    },
                    {
                      value: 'Z31.438',
                      title:
                        'Z31.438 Encounter for other genetic testing of female for procreative management',
                    },
                  ],
                },
                {
                  value: 'Z31.44',
                  title:
                    'Z31.44 Encounter for genetic testing of male for procreative management',
                  children: [
                    {
                      value: 'Z31.440',
                      title:
                        'Z31.440 Encounter of male for testing for genetic disease carrier status for procreative management',
                    },
                    {
                      value: 'Z31.441',
                      title:
                        'Z31.441 Encounter for testing of male partner of patient with recurrent pregnancy loss',
                    },
                    {
                      value: 'Z31.448',
                      title:
                        'Z31.448 Encounter for other genetic testing of male for procreative management',
                    },
                  ],
                },
                {
                  value: 'Z31.49',
                  title:
                    'Z31.49 Encounter for other procreative investigation and testing',
                },
              ],
            },
            {
              value: 'Z31.5',
              title: 'Z31.5 Encounter for procreative genetic counseling',
            },
            {
              value: 'Z31.6',
              title:
                'Z31.6 Encounter for general counseling and advice on procreation',
              children: [
                {
                  value: 'Z31.61',
                  title:
                    'Z31.61 Procreative counseling and advice using natural family planning',
                },
                {
                  value: 'Z31.62',
                  title:
                    'Z31.62 Encounter for fertility preservation counseling',
                },
                {
                  value: 'Z31.69',
                  title:
                    'Z31.69 Encounter for other general counseling and advice on procreation',
                },
              ],
            },
            {
              value: 'Z31.7',
              title:
                'Z31.7 Encounter for procreative management and counseling for gestational carrier',
            },
            {
              value: 'Z31.8',
              title: 'Z31.8 Encounter for other procreative management',
              children: [
                {
                  value: 'Z31.81',
                  title:
                    'Z31.81 Encounter for male factor infertility in female patient',
                },
                {
                  value: 'Z31.82',
                  title: 'Z31.82 Encounter for Rh incompatibility status',
                },
                {
                  value: 'Z31.83',
                  title:
                    'Z31.83 Encounter for assisted reproductive fertility procedure cycle',
                },
                {
                  value: 'Z31.84',
                  title:
                    'Z31.84 Encounter for fertility preservation procedure',
                },
                {
                  value: 'Z31.89',
                  title: 'Z31.89 Encounter for other procreative management',
                },
              ],
            },
            {
              value: 'Z31.9',
              title: 'Z31.9 Encounter for procreative management, unspecified',
            },
          ],
        },
        {
          value: 'Z32',
          title:
            'Z32 Encounter for pregnancy test and childbirth and childcare instruction',
          children: [
            {
              value: 'Z32.0',
              title: 'Z32.0 Encounter for pregnancy test',
              children: [
                {
                  value: 'Z32.00',
                  title: 'Z32.00 Encounter for pregnancy test, result unknown',
                },
                {
                  value: 'Z32.01',
                  title: 'Z32.01 Encounter for pregnancy test, result positive',
                },
                {
                  value: 'Z32.02',
                  title: 'Z32.02 Encounter for pregnancy test, result negative',
                },
              ],
            },
            {
              value: 'Z32.2',
              title: 'Z32.2 Encounter for childbirth instruction',
            },
            {
              value: 'Z32.3',
              title: 'Z32.3 Encounter for childcare instruction',
            },
          ],
        },
        {
          value: 'Z33',
          title: 'Z33 Pregnant state',
          children: [
            {
              value: 'Z33.1',
              title: 'Z33.1 Pregnant state, incidental',
            },
            {
              value: 'Z33.2',
              title: 'Z33.2 Encounter for elective termination of pregnancy',
            },
            {
              value: 'Z33.3',
              title: 'Z33.3 Pregnant state, gestational carrier',
            },
          ],
        },
        {
          value: 'Z34',
          title: 'Z34 Encounter for supervision of normal pregnancy',
          children: [
            {
              value: 'Z34.0',
              title:
                'Z34.0 Encounter for supervision of normal first pregnancy',
              children: [
                {
                  value: 'Z34.00',
                  title:
                    'Z34.00 Encounter for supervision of normal first pregnancy, unspecified trimester',
                },
                {
                  value: 'Z34.01',
                  title:
                    'Z34.01 Encounter for supervision of normal first pregnancy, first trimester',
                },
                {
                  value: 'Z34.02',
                  title:
                    'Z34.02 Encounter for supervision of normal first pregnancy, second trimester',
                },
                {
                  value: 'Z34.03',
                  title:
                    'Z34.03 Encounter for supervision of normal first pregnancy, third trimester',
                },
              ],
            },
            {
              value: 'Z34.8',
              title:
                'Z34.8 Encounter for supervision of other normal pregnancy',
              children: [
                {
                  value: 'Z34.80',
                  title:
                    'Z34.80 Encounter for supervision of other normal pregnancy, unspecified trimester',
                },
                {
                  value: 'Z34.81',
                  title:
                    'Z34.81 Encounter for supervision of other normal pregnancy, first trimester',
                },
                {
                  value: 'Z34.82',
                  title:
                    'Z34.82 Encounter for supervision of other normal pregnancy, second trimester',
                },
                {
                  value: 'Z34.83',
                  title:
                    'Z34.83 Encounter for supervision of other normal pregnancy, third trimester',
                },
              ],
            },
            {
              value: 'Z34.9',
              title:
                'Z34.9 Encounter for supervision of normal pregnancy, unspecified',
              children: [
                {
                  value: 'Z34.90',
                  title:
                    'Z34.90 Encounter for supervision of normal pregnancy, unspecified, unspecified trimester',
                },
                {
                  value: 'Z34.91',
                  title:
                    'Z34.91 Encounter for supervision of normal pregnancy, unspecified, first trimester',
                },
                {
                  value: 'Z34.92',
                  title:
                    'Z34.92 Encounter for supervision of normal pregnancy, unspecified, second trimester',
                },
                {
                  value: 'Z34.93',
                  title:
                    'Z34.93 Encounter for supervision of normal pregnancy, unspecified, third trimester',
                },
              ],
            },
          ],
        },
        {
          value: 'Z36',
          title: 'Z36 Encounter for antenatal screening of mother',
          children: [
            {
              value: 'Z36.0',
              title:
                'Z36.0 Encounter for antenatal screening for chromosomal anomalies',
            },
            {
              value: 'Z36.1',
              title:
                'Z36.1 Encounter for antenatal screening for raised alphafetoprotein level',
            },
            {
              value: 'Z36.2',
              title: 'Z36.2 Encounter for other antenatal screening follow-up',
            },
            {
              value: 'Z36.3',
              title:
                'Z36.3 Encounter for antenatal screening for malformations',
            },
            {
              value: 'Z36.4',
              title:
                'Z36.4 Encounter for antenatal screening for fetal growth retardation',
            },
            {
              value: 'Z36.5',
              title:
                'Z36.5 Encounter for antenatal screening for isoimmunization',
            },
            {
              value: 'Z36.8',
              title: 'Z36.8 Encounter for other antenatal screening',
              children: [
                {
                  value: 'Z36.81',
                  title:
                    'Z36.81 Encounter for antenatal screening for hydrops fetalis',
                },
                {
                  value: 'Z36.82',
                  title:
                    'Z36.82 Encounter for antenatal screening for nuchal translucency',
                },
                {
                  value: 'Z36.83',
                  title:
                    'Z36.83 Encounter for fetal screening for congenital cardiac abnormalities',
                },
                {
                  value: 'Z36.84',
                  title:
                    'Z36.84 Encounter for antenatal screening for fetal lung maturity',
                },
                {
                  value: 'Z36.85',
                  title:
                    'Z36.85 Encounter for antenatal screening for Streptococcus B',
                },
                {
                  value: 'Z36.86',
                  title:
                    'Z36.86 Encounter for antenatal screening for cervical length',
                },
                {
                  value: 'Z36.87',
                  title:
                    'Z36.87 Encounter for antenatal screening for uncertain dates',
                },
                {
                  value: 'Z36.88',
                  title:
                    'Z36.88 Encounter for antenatal screening for fetal macrosomia',
                },
                {
                  value: 'Z36.89',
                  title:
                    'Z36.89 Encounter for other specified antenatal screening',
                },
                {
                  value: 'Z36.8A',
                  title:
                    'Z36.8A Encounter for antenatal screening for other genetic defects',
                },
              ],
            },
            {
              value: 'Z36.9',
              title: 'Z36.9 Encounter for antenatal screening, unspecified',
            },
          ],
        },
        {
          value: 'Z3A',
          title: 'Z3A Weeks of gestation',
          children: [
            {
              value: 'Z3A.0',
              title:
                'Z3A.0 Weeks of gestation of pregnancy, unspecified or less than 10 weeks',
              children: [
                {
                  value: 'Z3A.00',
                  title: 'Z3A.00 Weeks of gestation of pregnancy not specified',
                },
                {
                  value: 'Z3A.01',
                  title: 'Z3A.01 Less than 8 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.08',
                  title: 'Z3A.08 8 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.09',
                  title: 'Z3A.09 9 weeks gestation of pregnancy',
                },
              ],
            },
            {
              value: 'Z3A.1',
              title: 'Z3A.1 Weeks of gestation of pregnancy, weeks 10-19',
              children: [
                {
                  value: 'Z3A.10',
                  title: 'Z3A.10 10 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.11',
                  title: 'Z3A.11 11 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.12',
                  title: 'Z3A.12 12 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.13',
                  title: 'Z3A.13 13 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.14',
                  title: 'Z3A.14 14 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.15',
                  title: 'Z3A.15 15 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.16',
                  title: 'Z3A.16 16 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.17',
                  title: 'Z3A.17 17 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.18',
                  title: 'Z3A.18 18 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.19',
                  title: 'Z3A.19 19 weeks gestation of pregnancy',
                },
              ],
            },
            {
              value: 'Z3A.2',
              title: 'Z3A.2 Weeks of gestation of pregnancy, weeks 20-29',
              children: [
                {
                  value: 'Z3A.20',
                  title: 'Z3A.20 20 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.21',
                  title: 'Z3A.21 21 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.22',
                  title: 'Z3A.22 22 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.23',
                  title: 'Z3A.23 23 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.24',
                  title: 'Z3A.24 24 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.25',
                  title: 'Z3A.25 25 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.26',
                  title: 'Z3A.26 26 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.27',
                  title: 'Z3A.27 27 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.28',
                  title: 'Z3A.28 28 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.29',
                  title: 'Z3A.29 29 weeks gestation of pregnancy',
                },
              ],
            },
            {
              value: 'Z3A.3',
              title: 'Z3A.3 Weeks of gestation of pregnancy, weeks 30-39',
              children: [
                {
                  value: 'Z3A.30',
                  title: 'Z3A.30 30 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.31',
                  title: 'Z3A.31 31 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.32',
                  title: 'Z3A.32 32 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.33',
                  title: 'Z3A.33 33 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.34',
                  title: 'Z3A.34 34 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.35',
                  title: 'Z3A.35 35 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.36',
                  title: 'Z3A.36 36 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.37',
                  title: 'Z3A.37 37 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.38',
                  title: 'Z3A.38 38 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.39',
                  title: 'Z3A.39 39 weeks gestation of pregnancy',
                },
              ],
            },
            {
              value: 'Z3A.4',
              title:
                'Z3A.4 Weeks of gestation of pregnancy, weeks 40 or greater',
              children: [
                {
                  value: 'Z3A.40',
                  title: 'Z3A.40 40 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.41',
                  title: 'Z3A.41 41 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.42',
                  title: 'Z3A.42 42 weeks gestation of pregnancy',
                },
                {
                  value: 'Z3A.49',
                  title: 'Z3A.49 Greater than 42 weeks gestation of pregnancy',
                },
              ],
            },
          ],
        },
        {
          value: 'Z37',
          title: 'Z37 Outcome of delivery',
          children: [
            {
              value: 'Z37.0',
              title: 'Z37.0 Single live birth',
            },
            {
              value: 'Z37.1',
              title: 'Z37.1 Single stillbirth',
            },
            {
              value: 'Z37.2',
              title: 'Z37.2 Twins, both liveborn',
            },
            {
              value: 'Z37.3',
              title: 'Z37.3 Twins, one liveborn and one stillborn',
            },
            {
              value: 'Z37.4',
              title: 'Z37.4 Twins, both stillborn',
            },
            {
              value: 'Z37.5',
              title: 'Z37.5 Other multiple births, all liveborn',
              children: [
                {
                  value: 'Z37.50',
                  title: 'Z37.50 Multiple births, unspecified, all liveborn',
                },
                {
                  value: 'Z37.51',
                  title: 'Z37.51 Triplets, all liveborn',
                },
                {
                  value: 'Z37.52',
                  title: 'Z37.52 Quadruplets, all liveborn',
                },
                {
                  value: 'Z37.53',
                  title: 'Z37.53 Quintuplets, all liveborn',
                },
                {
                  value: 'Z37.54',
                  title: 'Z37.54 Sextuplets, all liveborn',
                },
                {
                  value: 'Z37.59',
                  title: 'Z37.59 Other multiple births, all liveborn',
                },
              ],
            },
            {
              value: 'Z37.6',
              title: 'Z37.6 Other multiple births, some liveborn',
              children: [
                {
                  value: 'Z37.60',
                  title: 'Z37.60 Multiple births, unspecified, some liveborn',
                },
                {
                  value: 'Z37.61',
                  title: 'Z37.61 Triplets, some liveborn',
                },
                {
                  value: 'Z37.62',
                  title: 'Z37.62 Quadruplets, some liveborn',
                },
                {
                  value: 'Z37.63',
                  title: 'Z37.63 Quintuplets, some liveborn',
                },
                {
                  value: 'Z37.64',
                  title: 'Z37.64 Sextuplets, some liveborn',
                },
                {
                  value: 'Z37.69',
                  title: 'Z37.69 Other multiple births, some liveborn',
                },
              ],
            },
            {
              value: 'Z37.7',
              title: 'Z37.7 Other multiple births, all stillborn',
            },
            {
              value: 'Z37.9',
              title: 'Z37.9 Outcome of delivery, unspecified',
            },
          ],
        },
        {
          value: 'Z38',
          title:
            'Z38 Liveborn infants according to place of birth and type of delivery',
          children: [
            {
              value: 'Z38.0',
              title: 'Z38.0 Single liveborn infant, born in hospital',
              children: [
                {
                  value: 'Z38.00',
                  title: 'Z38.00 Single liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.01',
                  title: 'Z38.01 Single liveborn infant, delivered by cesarean',
                },
              ],
            },
            {
              value: 'Z38.1',
              title: 'Z38.1 Single liveborn infant, born outside hospital',
            },
            {
              value: 'Z38.2',
              title:
                'Z38.2 Single liveborn infant, unspecified as to place of birth',
            },
            {
              value: 'Z38.3',
              title: 'Z38.3 Twin liveborn infant, born in hospital',
              children: [
                {
                  value: 'Z38.30',
                  title: 'Z38.30 Twin liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.31',
                  title: 'Z38.31 Twin liveborn infant, delivered by cesarean',
                },
              ],
            },
            {
              value: 'Z38.4',
              title: 'Z38.4 Twin liveborn infant, born outside hospital',
            },
            {
              value: 'Z38.5',
              title:
                'Z38.5 Twin liveborn infant, unspecified as to place of birth',
            },
            {
              value: 'Z38.6',
              title: 'Z38.6 Other multiple liveborn infant, born in hospital',
              children: [
                {
                  value: 'Z38.61',
                  title: 'Z38.61 Triplet liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.62',
                  title:
                    'Z38.62 Triplet liveborn infant, delivered by cesarean',
                },
                {
                  value: 'Z38.63',
                  title:
                    'Z38.63 Quadruplet liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.64',
                  title:
                    'Z38.64 Quadruplet liveborn infant, delivered by cesarean',
                },
                {
                  value: 'Z38.65',
                  title:
                    'Z38.65 Quintuplet liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.66',
                  title:
                    'Z38.66 Quintuplet liveborn infant, delivered by cesarean',
                },
                {
                  value: 'Z38.68',
                  title:
                    'Z38.68 Other multiple liveborn infant, delivered vaginally',
                },
                {
                  value: 'Z38.69',
                  title:
                    'Z38.69 Other multiple liveborn infant, delivered by cesarean',
                },
              ],
            },
            {
              value: 'Z38.7',
              title:
                'Z38.7 Other multiple liveborn infant, born outside hospital',
            },
            {
              value: 'Z38.8',
              title:
                'Z38.8 Other multiple liveborn infant, unspecified as to place of birth',
            },
          ],
        },
        {
          value: 'Z39',
          title: 'Z39 Encounter for maternal postpartum care and examination',
          children: [
            {
              value: 'Z39.0',
              title:
                'Z39.0 Encounter for care and examination of mother immediately after delivery',
            },
            {
              value: 'Z39.1',
              title:
                'Z39.1 Encounter for care and examination of lactating mother',
            },
            {
              value: 'Z39.2',
              title: 'Z39.2 Encounter for routine postpartum follow-up',
            },
          ],
        },
      ],
    },
    {
      value: 'Z40-Z53',
      title: 'Z40-Z53 Encounters for other specific health care',
      children: [
        {
          value: 'Z40',
          title: 'Z40 Encounter for prophylactic surgery',
          children: [
            {
              value: 'Z40.0',
              title:
                'Z40.0 Encounter for prophylactic surgery for risk factors related to malignant neoplasms',
              children: [
                {
                  value: 'Z40.00',
                  title:
                    'Z40.00 Encounter for prophylactic removal of unspecified organ',
                },
                {
                  value: 'Z40.01',
                  title: 'Z40.01 Encounter for prophylactic removal of breast',
                },
                {
                  value: 'Z40.02',
                  title:
                    'Z40.02 Encounter for prophylactic removal of ovary(s)',
                },
                {
                  value: 'Z40.03',
                  title:
                    'Z40.03 Encounter for prophylactic removal of fallopian tube(s)',
                },
                {
                  value: 'Z40.09',
                  title:
                    'Z40.09 Encounter for prophylactic removal of other organ',
                },
              ],
            },
            {
              value: 'Z40.8',
              title: 'Z40.8 Encounter for other prophylactic surgery',
            },
            {
              value: 'Z40.9',
              title: 'Z40.9 Encounter for prophylactic surgery, unspecified',
            },
          ],
        },
        {
          value: 'Z41',
          title:
            'Z41 Encounter for procedures for purposes other than remedying health state',
          children: [
            {
              value: 'Z41.1',
              title: 'Z41.1 Encounter for cosmetic surgery',
            },
            {
              value: 'Z41.2',
              title: 'Z41.2 Encounter for routine and ritual male circumcision',
            },
            {
              value: 'Z41.3',
              title: 'Z41.3 Encounter for ear piercing',
            },
            {
              value: 'Z41.8',
              title:
                'Z41.8 Encounter for other procedures for purposes other than remedying health state',
            },
            {
              value: 'Z41.9',
              title:
                'Z41.9 Encounter for procedure for purposes other than remedying health state, unspecified',
            },
          ],
        },
        {
          value: 'Z42',
          title:
            'Z42 Encounter for plastic and reconstructive surgery following medical procedure or healed injury',
          children: [
            {
              value: 'Z42.1',
              title:
                'Z42.1 Encounter for breast reconstruction following mastectomy',
            },
            {
              value: 'Z42.8',
              title:
                'Z42.8 Encounter for other plastic and reconstructive surgery following medical procedure or healed injury',
            },
          ],
        },
        {
          value: 'Z43',
          title: 'Z43 Encounter for attention to artificial openings',
          children: [
            {
              value: 'Z43.0',
              title: 'Z43.0 Encounter for attention to tracheostomy',
            },
            {
              value: 'Z43.1',
              title: 'Z43.1 Encounter for attention to gastrostomy',
            },
            {
              value: 'Z43.2',
              title: 'Z43.2 Encounter for attention to ileostomy',
            },
            {
              value: 'Z43.3',
              title: 'Z43.3 Encounter for attention to colostomy',
            },
            {
              value: 'Z43.4',
              title:
                'Z43.4 Encounter for attention to other artificial openings of digestive tract',
            },
            {
              value: 'Z43.5',
              title: 'Z43.5 Encounter for attention to cystostomy',
            },
            {
              value: 'Z43.6',
              title:
                'Z43.6 Encounter for attention to other artificial openings of urinary tract',
            },
            {
              value: 'Z43.7',
              title: 'Z43.7 Encounter for attention to artificial vagina',
            },
            {
              value: 'Z43.8',
              title:
                'Z43.8 Encounter for attention to other artificial openings',
            },
            {
              value: 'Z43.9',
              title:
                'Z43.9 Encounter for attention to unspecified artificial opening',
            },
          ],
        },
        {
          value: 'Z44',
          title:
            'Z44 Encounter for fitting and adjustment of external prosthetic device',
          children: [
            {
              value: 'Z44.0',
              title:
                'Z44.0 Encounter for fitting and adjustment of artificial arm',
              children: [
                {
                  value: 'Z44.00',
                  title:
                    'Z44.00 Encounter for fitting and adjustment of unspecified artificial arm',
                  children: [
                    {
                      value: 'Z44.001',
                      title:
                        'Z44.001 Encounter for fitting and adjustment of unspecified right artificial arm',
                    },
                    {
                      value: 'Z44.002',
                      title:
                        'Z44.002 Encounter for fitting and adjustment of unspecified left artificial arm',
                    },
                    {
                      value: 'Z44.009',
                      title:
                        'Z44.009 Encounter for fitting and adjustment of unspecified artificial arm, unspecified arm',
                    },
                  ],
                },
                {
                  value: 'Z44.01',
                  title:
                    'Z44.01 Encounter for fitting and adjustment of complete artificial arm',
                  children: [
                    {
                      value: 'Z44.011',
                      title:
                        'Z44.011 Encounter for fitting and adjustment of complete right artificial arm',
                    },
                    {
                      value: 'Z44.012',
                      title:
                        'Z44.012 Encounter for fitting and adjustment of complete left artificial arm',
                    },
                    {
                      value: 'Z44.019',
                      title:
                        'Z44.019 Encounter for fitting and adjustment of complete artificial arm, unspecified arm',
                    },
                  ],
                },
                {
                  value: 'Z44.02',
                  title:
                    'Z44.02 Encounter for fitting and adjustment of partial artificial arm',
                  children: [
                    {
                      value: 'Z44.021',
                      title:
                        'Z44.021 Encounter for fitting and adjustment of partial artificial right arm',
                    },
                    {
                      value: 'Z44.022',
                      title:
                        'Z44.022 Encounter for fitting and adjustment of partial artificial left arm',
                    },
                    {
                      value: 'Z44.029',
                      title:
                        'Z44.029 Encounter for fitting and adjustment of partial artificial arm, unspecified arm',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z44.1',
              title:
                'Z44.1 Encounter for fitting and adjustment of artificial leg',
              children: [
                {
                  value: 'Z44.10',
                  title:
                    'Z44.10 Encounter for fitting and adjustment of unspecified artificial leg',
                  children: [
                    {
                      value: 'Z44.101',
                      title:
                        'Z44.101 Encounter for fitting and adjustment of unspecified right artificial leg',
                    },
                    {
                      value: 'Z44.102',
                      title:
                        'Z44.102 Encounter for fitting and adjustment of unspecified left artificial leg',
                    },
                    {
                      value: 'Z44.109',
                      title:
                        'Z44.109 Encounter for fitting and adjustment of unspecified artificial leg, unspecified leg',
                    },
                  ],
                },
                {
                  value: 'Z44.11',
                  title:
                    'Z44.11 Encounter for fitting and adjustment of complete artificial leg',
                  children: [
                    {
                      value: 'Z44.111',
                      title:
                        'Z44.111 Encounter for fitting and adjustment of complete right artificial leg',
                    },
                    {
                      value: 'Z44.112',
                      title:
                        'Z44.112 Encounter for fitting and adjustment of complete left artificial leg',
                    },
                    {
                      value: 'Z44.119',
                      title:
                        'Z44.119 Encounter for fitting and adjustment of complete artificial leg, unspecified leg',
                    },
                  ],
                },
                {
                  value: 'Z44.12',
                  title:
                    'Z44.12 Encounter for fitting and adjustment of partial artificial leg',
                  children: [
                    {
                      value: 'Z44.121',
                      title:
                        'Z44.121 Encounter for fitting and adjustment of partial artificial right leg',
                    },
                    {
                      value: 'Z44.122',
                      title:
                        'Z44.122 Encounter for fitting and adjustment of partial artificial left leg',
                    },
                    {
                      value: 'Z44.129',
                      title:
                        'Z44.129 Encounter for fitting and adjustment of partial artificial leg, unspecified leg',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z44.2',
              title:
                'Z44.2 Encounter for fitting and adjustment of artificial eye',
              children: [
                {
                  value: 'Z44.20',
                  title:
                    'Z44.20 Encounter for fitting and adjustment of artificial eye, unspecified',
                },
                {
                  value: 'Z44.21',
                  title:
                    'Z44.21 Encounter for fitting and adjustment of artificial right eye',
                },
                {
                  value: 'Z44.22',
                  title:
                    'Z44.22 Encounter for fitting and adjustment of artificial left eye',
                },
              ],
            },
            {
              value: 'Z44.3',
              title:
                'Z44.3 Encounter for fitting and adjustment of external breast prosthesis',
              children: [
                {
                  value: 'Z44.30',
                  title:
                    'Z44.30 Encounter for fitting and adjustment of external breast prosthesis, unspecified breast',
                },
                {
                  value: 'Z44.31',
                  title:
                    'Z44.31 Encounter for fitting and adjustment of external right breast prosthesis',
                },
                {
                  value: 'Z44.32',
                  title:
                    'Z44.32 Encounter for fitting and adjustment of external left breast prosthesis',
                },
              ],
            },
            {
              value: 'Z44.8',
              title:
                'Z44.8 Encounter for fitting and adjustment of other external prosthetic devices',
            },
            {
              value: 'Z44.9',
              title:
                'Z44.9 Encounter for fitting and adjustment of unspecified external prosthetic device',
            },
          ],
        },
        {
          value: 'Z45',
          title:
            'Z45 Encounter for adjustment and management of implanted device',
          children: [
            {
              value: 'Z45.0',
              title:
                'Z45.0 Encounter for adjustment and management of cardiac device',
              children: [
                {
                  value: 'Z45.01',
                  title:
                    'Z45.01 Encounter for adjustment and management of cardiac pacemaker',
                  children: [
                    {
                      value: 'Z45.010',
                      title:
                        'Z45.010 Encounter for checking and testing of cardiac pacemaker pulse generator [battery]',
                    },
                    {
                      value: 'Z45.018',
                      title:
                        'Z45.018 Encounter for adjustment and management of other part of cardiac pacemaker',
                    },
                  ],
                },
                {
                  value: 'Z45.02',
                  title:
                    'Z45.02 Encounter for adjustment and management of automatic implantable cardiac defibrillator',
                },
                {
                  value: 'Z45.09',
                  title:
                    'Z45.09 Encounter for adjustment and management of other cardiac device',
                },
              ],
            },
            {
              value: 'Z45.1',
              title:
                'Z45.1 Encounter for adjustment and management of infusion pump',
            },
            {
              value: 'Z45.2',
              title:
                'Z45.2 Encounter for adjustment and management of vascular access device',
            },
            {
              value: 'Z45.3',
              title:
                'Z45.3 Encounter for adjustment and management of implanted devices of the special senses',
              children: [
                {
                  value: 'Z45.31',
                  title:
                    'Z45.31 Encounter for adjustment and management of implanted visual substitution device',
                },
                {
                  value: 'Z45.32',
                  title:
                    'Z45.32 Encounter for adjustment and management of implanted hearing device',
                  children: [
                    {
                      value: 'Z45.320',
                      title:
                        'Z45.320 Encounter for adjustment and management of bone conduction device',
                    },
                    {
                      value: 'Z45.321',
                      title:
                        'Z45.321 Encounter for adjustment and management of cochlear device',
                    },
                    {
                      value: 'Z45.328',
                      title:
                        'Z45.328 Encounter for adjustment and management of other implanted hearing device',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z45.4',
              title:
                'Z45.4 Encounter for adjustment and management of implanted nervous system device',
              children: [
                {
                  value: 'Z45.41',
                  title:
                    'Z45.41 Encounter for adjustment and management of cerebrospinal fluid drainage device',
                },
                {
                  value: 'Z45.42',
                  title:
                    'Z45.42 Encounter for adjustment and management of neurostimulator',
                },
                {
                  value: 'Z45.49',
                  title:
                    'Z45.49 Encounter for adjustment and management of other implanted nervous system device',
                },
              ],
            },
            {
              value: 'Z45.8',
              title:
                'Z45.8 Encounter for adjustment and management of other implanted devices',
              children: [
                {
                  value: 'Z45.81',
                  title:
                    'Z45.81 Encounter for adjustment or removal of breast implant',
                  children: [
                    {
                      value: 'Z45.811',
                      title:
                        'Z45.811 Encounter for adjustment or removal of right breast implant',
                    },
                    {
                      value: 'Z45.812',
                      title:
                        'Z45.812 Encounter for adjustment or removal of left breast implant',
                    },
                    {
                      value: 'Z45.819',
                      title:
                        'Z45.819 Encounter for adjustment or removal of unspecified breast implant',
                    },
                  ],
                },
                {
                  value: 'Z45.82',
                  title:
                    'Z45.82 Encounter for adjustment or removal of myringotomy device (stent) (tube)',
                },
                {
                  value: 'Z45.89',
                  title:
                    'Z45.89 Encounter for adjustment and management of other implanted devices',
                },
              ],
            },
            {
              value: 'Z45.9',
              title:
                'Z45.9 Encounter for adjustment and management of unspecified implanted device',
            },
          ],
        },
        {
          value: 'Z46',
          title: 'Z46 Encounter for fitting and adjustment of other devices',
          children: [
            {
              value: 'Z46.0',
              title:
                'Z46.0 Encounter for fitting and adjustment of spectacles and contact lenses',
            },
            {
              value: 'Z46.1',
              title:
                'Z46.1 Encounter for fitting and adjustment of hearing aid',
            },
            {
              value: 'Z46.2',
              title:
                'Z46.2 Encounter for fitting and adjustment of other devices related to nervous system and special senses',
            },
            {
              value: 'Z46.3',
              title:
                'Z46.3 Encounter for fitting and adjustment of dental prosthetic device',
            },
            {
              value: 'Z46.4',
              title:
                'Z46.4 Encounter for fitting and adjustment of orthodontic device',
            },
            {
              value: 'Z46.5',
              title:
                'Z46.5 Encounter for fitting and adjustment of other gastrointestinal appliance and device',
              children: [
                {
                  value: 'Z46.51',
                  title:
                    'Z46.51 Encounter for fitting and adjustment of gastric lap band',
                },
                {
                  value: 'Z46.59',
                  title:
                    'Z46.59 Encounter for fitting and adjustment of other gastrointestinal appliance and device',
                },
              ],
            },
            {
              value: 'Z46.6',
              title:
                'Z46.6 Encounter for fitting and adjustment of urinary device',
            },
            {
              value: 'Z46.8',
              title:
                'Z46.8 Encounter for fitting and adjustment of other specified devices',
              children: [
                {
                  value: 'Z46.81',
                  title:
                    'Z46.81 Encounter for fitting and adjustment of insulin pump',
                },
                {
                  value: 'Z46.82',
                  title:
                    'Z46.82 Encounter for fitting and adjustment of non-vascular catheter',
                },
                {
                  value: 'Z46.89',
                  title:
                    'Z46.89 Encounter for fitting and adjustment of other specified devices',
                },
              ],
            },
            {
              value: 'Z46.9',
              title:
                'Z46.9 Encounter for fitting and adjustment of unspecified device',
            },
          ],
        },
        {
          value: 'Z47',
          title: 'Z47 Orthopedic aftercare',
          children: [
            {
              value: 'Z47.1',
              title: 'Z47.1 Aftercare following joint replacement surgery',
            },
            {
              value: 'Z47.2',
              title: 'Z47.2 Encounter for removal of internal fixation device',
            },
            {
              value: 'Z47.3',
              title:
                'Z47.3 Aftercare following explantation of joint prosthesis',
              children: [
                {
                  value: 'Z47.31',
                  title:
                    'Z47.31 Aftercare following explantation of shoulder joint prosthesis',
                },
                {
                  value: 'Z47.32',
                  title:
                    'Z47.32 Aftercare following explantation of hip joint prosthesis',
                },
                {
                  value: 'Z47.33',
                  title:
                    'Z47.33 Aftercare following explantation of knee joint prosthesis',
                },
              ],
            },
            {
              value: 'Z47.8',
              title: 'Z47.8 Encounter for other orthopedic aftercare',
              children: [
                {
                  value: 'Z47.81',
                  title:
                    'Z47.81 Encounter for orthopedic aftercare following surgical amputation',
                },
                {
                  value: 'Z47.82',
                  title:
                    'Z47.82 Encounter for orthopedic aftercare following scoliosis surgery',
                },
                {
                  value: 'Z47.89',
                  title: 'Z47.89 Encounter for other orthopedic aftercare',
                },
              ],
            },
          ],
        },
        {
          value: 'Z48',
          title: 'Z48 Encounter for other postprocedural aftercare',
          children: [
            {
              value: 'Z48.0',
              title:
                'Z48.0 Encounter for attention to dressings, sutures and drains',
              children: [
                {
                  value: 'Z48.00',
                  title:
                    'Z48.00 Encounter for change or removal of nonsurgical wound dressing',
                },
                {
                  value: 'Z48.01',
                  title:
                    'Z48.01 Encounter for change or removal of surgical wound dressing',
                },
                {
                  value: 'Z48.02',
                  title: 'Z48.02 Encounter for removal of sutures',
                },
                {
                  value: 'Z48.03',
                  title: 'Z48.03 Encounter for change or removal of drains',
                },
              ],
            },
            {
              value: 'Z48.1',
              title: 'Z48.1 Encounter for planned postprocedural wound closure',
            },
            {
              value: 'Z48.2',
              title: 'Z48.2 Encounter for aftercare following organ transplant',
              children: [
                {
                  value: 'Z48.21',
                  title:
                    'Z48.21 Encounter for aftercare following heart transplant',
                },
                {
                  value: 'Z48.22',
                  title:
                    'Z48.22 Encounter for aftercare following kidney transplant',
                },
                {
                  value: 'Z48.23',
                  title:
                    'Z48.23 Encounter for aftercare following liver transplant',
                },
                {
                  value: 'Z48.24',
                  title:
                    'Z48.24 Encounter for aftercare following lung transplant',
                },
                {
                  value: 'Z48.28',
                  title:
                    'Z48.28 Encounter for aftercare following multiple organ transplant',
                  children: [
                    {
                      value: 'Z48.280',
                      title:
                        'Z48.280 Encounter for aftercare following heart-lung transplant',
                    },
                    {
                      value: 'Z48.288',
                      title:
                        'Z48.288 Encounter for aftercare following multiple organ transplant',
                    },
                  ],
                },
                {
                  value: 'Z48.29',
                  title:
                    'Z48.29 Encounter for aftercare following other organ transplant',
                  children: [
                    {
                      value: 'Z48.290',
                      title:
                        'Z48.290 Encounter for aftercare following bone marrow transplant',
                    },
                    {
                      value: 'Z48.298',
                      title:
                        'Z48.298 Encounter for aftercare following other organ transplant',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z48.3',
              title: 'Z48.3 Aftercare following surgery for neoplasm',
            },
            {
              value: 'Z48.8',
              title:
                'Z48.8 Encounter for other specified postprocedural aftercare',
              children: [
                {
                  value: 'Z48.81',
                  title:
                    'Z48.81 Encounter for surgical aftercare following surgery on specified body systems',
                  children: [
                    {
                      value: 'Z48.810',
                      title:
                        'Z48.810 Encounter for surgical aftercare following surgery on the sense organs',
                    },
                    {
                      value: 'Z48.811',
                      title:
                        'Z48.811 Encounter for surgical aftercare following surgery on the nervous system',
                    },
                    {
                      value: 'Z48.812',
                      title:
                        'Z48.812 Encounter for surgical aftercare following surgery on the circulatory system',
                    },
                    {
                      value: 'Z48.813',
                      title:
                        'Z48.813 Encounter for surgical aftercare following surgery on the respiratory system',
                    },
                    {
                      value: 'Z48.814',
                      title:
                        'Z48.814 Encounter for surgical aftercare following surgery on the teeth or oral cavity',
                    },
                    {
                      value: 'Z48.815',
                      title:
                        'Z48.815 Encounter for surgical aftercare following surgery on the digestive system',
                    },
                    {
                      value: 'Z48.816',
                      title:
                        'Z48.816 Encounter for surgical aftercare following surgery on the genitourinary system',
                    },
                    {
                      value: 'Z48.817',
                      title:
                        'Z48.817 Encounter for surgical aftercare following surgery on the skin and subcutaneous tissue',
                    },
                  ],
                },
                {
                  value: 'Z48.89',
                  title:
                    'Z48.89 Encounter for other specified surgical aftercare',
                },
              ],
            },
          ],
        },
        {
          value: 'Z49',
          title: 'Z49 Encounter for care involving renal dialysis',
          children: [
            {
              value: 'Z49.0',
              title: 'Z49.0 Preparatory care for renal dialysis',
              children: [
                {
                  value: 'Z49.01',
                  title:
                    'Z49.01 Encounter for fitting and adjustment of extracorporeal dialysis catheter',
                },
                {
                  value: 'Z49.02',
                  title:
                    'Z49.02 Encounter for fitting and adjustment of peritoneal dialysis catheter',
                },
              ],
            },
            {
              value: 'Z49.3',
              title: 'Z49.3 Encounter for adequacy testing for dialysis',
              children: [
                {
                  value: 'Z49.31',
                  title:
                    'Z49.31 Encounter for adequacy testing for hemodialysis',
                },
                {
                  value: 'Z49.32',
                  title:
                    'Z49.32 Encounter for adequacy testing for peritoneal dialysis',
                },
              ],
            },
          ],
        },
        {
          value: 'Z51',
          title: 'Z51 Encounter for other aftercare and medical care',
          children: [
            {
              value: 'Z51.0',
              title: 'Z51.0 Encounter for antineoplastic radiation therapy',
            },
            {
              value: 'Z51.1',
              title:
                'Z51.1 Encounter for antineoplastic chemotherapy and immunotherapy',
              children: [
                {
                  value: 'Z51.11',
                  title: 'Z51.11 Encounter for antineoplastic chemotherapy',
                },
                {
                  value: 'Z51.12',
                  title: 'Z51.12 Encounter for antineoplastic immunotherapy',
                },
              ],
            },
            {
              value: 'Z51.5',
              title: 'Z51.5 Encounter for palliative care',
            },
            {
              value: 'Z51.6',
              title: 'Z51.6 Encounter for desensitization to allergens',
            },
            {
              value: 'Z51.8',
              title: 'Z51.8 Encounter for other specified aftercare',
              children: [
                {
                  value: 'Z51.81',
                  title:
                    'Z51.81 Encounter for therapeutic drug level monitoring',
                },
                {
                  value: 'Z51.89',
                  title: 'Z51.89 Encounter for other specified aftercare',
                },
              ],
            },
          ],
        },
        {
          value: 'Z52',
          title: 'Z52 Donors of organs and tissues',
          children: [
            {
              value: 'Z52.0',
              title: 'Z52.0 Blood donor',
              children: [
                {
                  value: 'Z52.00',
                  title: 'Z52.00 Unspecified blood donor',
                  children: [
                    {
                      value: 'Z52.000',
                      title: 'Z52.000 Unspecified donor, whole blood',
                    },
                    {
                      value: 'Z52.001',
                      title: 'Z52.001 Unspecified donor, stem cells',
                    },
                    {
                      value: 'Z52.008',
                      title: 'Z52.008 Unspecified donor, other blood',
                    },
                  ],
                },
                {
                  value: 'Z52.01',
                  title: 'Z52.01 Autologous blood donor',
                  children: [
                    {
                      value: 'Z52.010',
                      title: 'Z52.010 Autologous donor, whole blood',
                    },
                    {
                      value: 'Z52.011',
                      title: 'Z52.011 Autologous donor, stem cells',
                    },
                    {
                      value: 'Z52.018',
                      title: 'Z52.018 Autologous donor, other blood',
                    },
                  ],
                },
                {
                  value: 'Z52.09',
                  title: 'Z52.09 Other blood donor',
                  children: [
                    {
                      value: 'Z52.090',
                      title: 'Z52.090 Other blood donor, whole blood',
                    },
                    {
                      value: 'Z52.091',
                      title: 'Z52.091 Other blood donor, stem cells',
                    },
                    {
                      value: 'Z52.098',
                      title: 'Z52.098 Other blood donor, other blood',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z52.1',
              title: 'Z52.1 Skin donor',
              children: [
                {
                  value: 'Z52.10',
                  title: 'Z52.10 Skin donor, unspecified',
                },
                {
                  value: 'Z52.11',
                  title: 'Z52.11 Skin donor, autologous',
                },
                {
                  value: 'Z52.19',
                  title: 'Z52.19 Skin donor, other',
                },
              ],
            },
            {
              value: 'Z52.2',
              title: 'Z52.2 Bone donor',
              children: [
                {
                  value: 'Z52.20',
                  title: 'Z52.20 Bone donor, unspecified',
                },
                {
                  value: 'Z52.21',
                  title: 'Z52.21 Bone donor, autologous',
                },
                {
                  value: 'Z52.29',
                  title: 'Z52.29 Bone donor, other',
                },
              ],
            },
            {
              value: 'Z52.3',
              title: 'Z52.3 Bone marrow donor',
            },
            {
              value: 'Z52.4',
              title: 'Z52.4 Kidney donor',
            },
            {
              value: 'Z52.5',
              title: 'Z52.5 Cornea donor',
            },
            {
              value: 'Z52.6',
              title: 'Z52.6 Liver donor',
            },
            {
              value: 'Z52.8',
              title: 'Z52.8 Donor of other specified organs or tissues',
              children: [
                {
                  value: 'Z52.81',
                  title: 'Z52.81 Egg (Oocyte) donor',
                  children: [
                    {
                      value: 'Z52.810',
                      title:
                        'Z52.810 Egg (Oocyte) donor under age 35, anonymous recipient',
                    },
                    {
                      value: 'Z52.811',
                      title:
                        'Z52.811 Egg (Oocyte) donor under age 35, designated recipient',
                    },
                    {
                      value: 'Z52.812',
                      title:
                        'Z52.812 Egg (Oocyte) donor age 35 and over, anonymous recipient',
                    },
                    {
                      value: 'Z52.813',
                      title:
                        'Z52.813 Egg (Oocyte) donor age 35 and over, designated recipient',
                    },
                    {
                      value: 'Z52.819',
                      title: 'Z52.819 Egg (Oocyte) donor, unspecified',
                    },
                  ],
                },
                {
                  value: 'Z52.89',
                  title: 'Z52.89 Donor of other specified organs or tissues',
                },
              ],
            },
            {
              value: 'Z52.9',
              title: 'Z52.9 Donor of unspecified organ or tissue',
            },
          ],
        },
        {
          value: 'Z53',
          title:
            'Z53 Persons encountering health services for specific procedures and treatment, not carried out',
          children: [
            {
              value: 'Z53.0',
              title:
                'Z53.0 Procedure and treatment not carried out because of contraindication',
              children: [
                {
                  value: 'Z53.01',
                  title:
                    'Z53.01 Procedure and treatment not carried out due to patient smoking',
                },
                {
                  value: 'Z53.09',
                  title:
                    'Z53.09 Procedure and treatment not carried out because of other contraindication',
                },
              ],
            },
            {
              value: 'Z53.1',
              title:
                "Z53.1 Procedure and treatment not carried out because of patient's decision for reasons of belief and group pressure",
            },
            {
              value: 'Z53.2',
              title:
                "Z53.2 Procedure and treatment not carried out because of patient's decision for other and unspecified reasons",
              children: [
                {
                  value: 'Z53.20',
                  title:
                    "Z53.20 Procedure and treatment not carried out because of patient's decision for unspecified reasons",
                },
                {
                  value: 'Z53.21',
                  title:
                    'Z53.21 Procedure and treatment not carried out due to patient leaving prior to being seen by health care provider',
                },
                {
                  value: 'Z53.29',
                  title:
                    "Z53.29 Procedure and treatment not carried out because of patient's decision for other reasons",
                },
              ],
            },
            {
              value: 'Z53.3',
              title: 'Z53.3 Procedure converted to open procedure',
              children: [
                {
                  value: 'Z53.31',
                  title:
                    'Z53.31 Laparoscopic surgical procedure converted to open procedure',
                },
                {
                  value: 'Z53.32',
                  title:
                    'Z53.32 Thoracoscopic surgical procedure converted to open procedure',
                },
                {
                  value: 'Z53.33',
                  title:
                    'Z53.33 Arthroscopic surgical procedure converted to open procedure',
                },
                {
                  value: 'Z53.39',
                  title:
                    'Z53.39 Other specified procedure converted to open procedure',
                },
              ],
            },
            {
              value: 'Z53.8',
              title:
                'Z53.8 Procedure and treatment not carried out for other reasons',
            },
            {
              value: 'Z53.9',
              title:
                'Z53.9 Procedure and treatment not carried out, unspecified reason',
            },
          ],
        },
      ],
    },
    {
      value: 'Z55-Z65',
      title:
        'Z55-Z65 Persons with potential health hazards related to socioeconomic and psychosocial circumstances',
      children: [
        {
          value: 'Z55',
          title: 'Z55 Problems related to education and literacy',
          children: [
            {
              value: 'Z55.0',
              title: 'Z55.0 Illiteracy and low-level literacy',
            },
            {
              value: 'Z55.1',
              title: 'Z55.1 Schooling unavailable and unattainable',
            },
            {
              value: 'Z55.2',
              title: 'Z55.2 Failed school examinations',
            },
            {
              value: 'Z55.3',
              title: 'Z55.3 Underachievement in school',
            },
            {
              value: 'Z55.4',
              title:
                'Z55.4 Educational maladjustment and discord with teachers and classmates',
            },
            {
              value: 'Z55.5',
              title: 'Z55.5 Less than a high school diploma',
            },
            {
              value: 'Z55.6',
              title: 'Z55.6 Problems related to health literacy',
            },
            {
              value: 'Z55.8',
              title: 'Z55.8 Other problems related to education and literacy',
            },
            {
              value: 'Z55.9',
              title:
                'Z55.9 Problems related to education and literacy, unspecified',
            },
          ],
        },
        {
          value: 'Z56',
          title: 'Z56 Problems related to employment and unemployment',
          children: [
            {
              value: 'Z56.0',
              title: 'Z56.0 Unemployment, unspecified',
            },
            {
              value: 'Z56.1',
              title: 'Z56.1 Change of job',
            },
            {
              value: 'Z56.2',
              title: 'Z56.2 Threat of job loss',
            },
            {
              value: 'Z56.3',
              title: 'Z56.3 Stressful work schedule',
            },
            {
              value: 'Z56.4',
              title: 'Z56.4 Discord with boss and workmates',
            },
            {
              value: 'Z56.5',
              title: 'Z56.5 Uncongenial work environment',
            },
            {
              value: 'Z56.6',
              title: 'Z56.6 Other physical and mental strain related to work',
            },
            {
              value: 'Z56.8',
              title: 'Z56.8 Other problems related to employment',
              children: [
                {
                  value: 'Z56.81',
                  title: 'Z56.81 Sexual harassment on the job',
                },
                {
                  value: 'Z56.82',
                  title: 'Z56.82 Military deployment status',
                },
                {
                  value: 'Z56.89',
                  title: 'Z56.89 Other problems related to employment',
                },
              ],
            },
            {
              value: 'Z56.9',
              title: 'Z56.9 Unspecified problems related to employment',
            },
          ],
        },
        {
          value: 'Z57',
          title: 'Z57 Occupational exposure to risk factors',
          children: [
            {
              value: 'Z57.0',
              title: 'Z57.0 Occupational exposure to noise',
            },
            {
              value: 'Z57.1',
              title: 'Z57.1 Occupational exposure to radiation',
            },
            {
              value: 'Z57.2',
              title: 'Z57.2 Occupational exposure to dust',
            },
            {
              value: 'Z57.3',
              title: 'Z57.3 Occupational exposure to other air contaminants',
              children: [
                {
                  value: 'Z57.31',
                  title:
                    'Z57.31 Occupational exposure to environmental tobacco smoke',
                },
                {
                  value: 'Z57.39',
                  title:
                    'Z57.39 Occupational exposure to other air contaminants',
                },
              ],
            },
            {
              value: 'Z57.4',
              title:
                'Z57.4 Occupational exposure to toxic agents in agriculture',
            },
            {
              value: 'Z57.5',
              title:
                'Z57.5 Occupational exposure to toxic agents in other industries',
            },
            {
              value: 'Z57.6',
              title: 'Z57.6 Occupational exposure to extreme temperature',
            },
            {
              value: 'Z57.7',
              title: 'Z57.7 Occupational exposure to vibration',
            },
            {
              value: 'Z57.8',
              title: 'Z57.8 Occupational exposure to other risk factors',
            },
            {
              value: 'Z57.9',
              title: 'Z57.9 Occupational exposure to unspecified risk factor',
            },
          ],
        },
        {
          value: 'Z58',
          title: 'Z58 Problems related to physical environment',
          children: [
            {
              value: 'Z58.6',
              title: 'Z58.6 Inadequate drinking-water supply',
            },
            {
              value: 'Z58.8',
              title: 'Z58.8 Other problems related to physical environment',
              children: [
                {
                  value: 'Z58.81',
                  title:
                    'Z58.81 Basic services unavailable in physical environment',
                },
                {
                  value: 'Z58.89',
                  title:
                    'Z58.89 Other problems related to physical environment',
                },
              ],
            },
          ],
        },
        {
          value: 'Z59',
          title: 'Z59 Problems related to housing and economic circumstances',
          children: [
            {
              value: 'Z59.0',
              title: 'Z59.0 Homelessness',
              children: [
                {
                  value: 'Z59.00',
                  title: 'Z59.00 Homelessness unspecified',
                },
                {
                  value: 'Z59.01',
                  title: 'Z59.01 Sheltered homelessness',
                },
                {
                  value: 'Z59.02',
                  title: 'Z59.02 Unsheltered homelessness',
                },
              ],
            },
            {
              value: 'Z59.1',
              title: 'Z59.1 Inadequate housing',
              children: [
                {
                  value: 'Z59.10',
                  title: 'Z59.10 Inadequate housing, unspecified',
                },
                {
                  value: 'Z59.11',
                  title: 'Z59.11 Inadequate housing environmental temperature',
                },
                {
                  value: 'Z59.12',
                  title: 'Z59.12 Inadequate housing utilities',
                },
                {
                  value: 'Z59.19',
                  title: 'Z59.19 Other inadequate housing',
                },
              ],
            },
            {
              value: 'Z59.2',
              title: 'Z59.2 Discord with neighbors, lodgers and landlord',
            },
            {
              value: 'Z59.3',
              title:
                'Z59.3 Problems related to living in residential institution',
            },
            {
              value: 'Z59.4',
              title: 'Z59.4 Lack of adequate food',
              children: [
                {
                  value: 'Z59.41',
                  title: 'Z59.41 Food insecurity',
                },
                {
                  value: 'Z59.48',
                  title: 'Z59.48 Other specified lack of adequate food',
                },
              ],
            },
            {
              value: 'Z59.5',
              title: 'Z59.5 Extreme poverty',
            },
            {
              value: 'Z59.6',
              title: 'Z59.6 Low income',
            },
            {
              value: 'Z59.7',
              title: 'Z59.7 Insufficient social insurance and welfare support',
            },
            {
              value: 'Z59.8',
              title:
                'Z59.8 Other problems related to housing and economic circumstances',
              children: [
                {
                  value: 'Z59.81',
                  title: 'Z59.81 Housing instability, housed',
                  children: [
                    {
                      value: 'Z59.811',
                      title:
                        'Z59.811 Housing instability, housed, with risk of homelessness',
                    },
                    {
                      value: 'Z59.812',
                      title:
                        'Z59.812 Housing instability, housed, homelessness in past 12 months',
                    },
                    {
                      value: 'Z59.819',
                      title: 'Z59.819 Housing instability, housed unspecified',
                    },
                  ],
                },
                {
                  value: 'Z59.82',
                  title: 'Z59.82 Transportation insecurity',
                },
                {
                  value: 'Z59.86',
                  title: 'Z59.86 Financial insecurity',
                },
                {
                  value: 'Z59.87',
                  title:
                    'Z59.87 Material hardship due to limited financial resources, not elsewhere classified',
                },
                {
                  value: 'Z59.89',
                  title:
                    'Z59.89 Other problems related to housing and economic circumstances',
                },
              ],
            },
            {
              value: 'Z59.9',
              title:
                'Z59.9 Problem related to housing and economic circumstances, unspecified',
            },
          ],
        },
        {
          value: 'Z60',
          title: 'Z60 Problems related to social environment',
          children: [
            {
              value: 'Z60.0',
              title: 'Z60.0 Problems of adjustment to life-cycle transitions',
            },
            {
              value: 'Z60.2',
              title: 'Z60.2 Problems related to living alone',
            },
            {
              value: 'Z60.3',
              title: 'Z60.3 Acculturation difficulty',
            },
            {
              value: 'Z60.4',
              title: 'Z60.4 Social exclusion and rejection',
            },
            {
              value: 'Z60.5',
              title:
                'Z60.5 Target of (perceived) adverse discrimination and persecution',
            },
            {
              value: 'Z60.8',
              title: 'Z60.8 Other problems related to social environment',
            },
            {
              value: 'Z60.9',
              title: 'Z60.9 Problem related to social environment, unspecified',
            },
          ],
        },
        {
          value: 'Z62',
          title: 'Z62 Problems related to upbringing',
          children: [
            {
              value: 'Z62.0',
              title: 'Z62.0 Inadequate parental supervision and control',
            },
            {
              value: 'Z62.1',
              title: 'Z62.1 Parental overprotection',
            },
            {
              value: 'Z62.2',
              title: 'Z62.2 Upbringing away from parents',
              children: [
                {
                  value: 'Z62.21',
                  title: 'Z62.21 Child in welfare custody',
                },
                {
                  value: 'Z62.22',
                  title: 'Z62.22 Institutional upbringing',
                },
                {
                  value: 'Z62.29',
                  title: 'Z62.29 Other upbringing away from parents',
                },
              ],
            },
            {
              value: 'Z62.3',
              title: 'Z62.3 Hostility towards and scapegoating of child',
            },
            {
              value: 'Z62.6',
              title: 'Z62.6 Inappropriate (excessive) parental pressure',
            },
            {
              value: 'Z62.8',
              title: 'Z62.8 Other specified problems related to upbringing',
              children: [
                {
                  value: 'Z62.81',
                  title: 'Z62.81 Personal history of abuse in childhood',
                  children: [
                    {
                      value: 'Z62.810',
                      title:
                        'Z62.810 Personal history of physical and sexual abuse in childhood',
                    },
                    {
                      value: 'Z62.811',
                      title:
                        'Z62.811 Personal history of psychological abuse in childhood',
                    },
                    {
                      value: 'Z62.812',
                      title: 'Z62.812 Personal history of neglect in childhood',
                    },
                    {
                      value: 'Z62.813',
                      title:
                        'Z62.813 Personal history of forced labor or sexual exploitation in childhood',
                    },
                    {
                      value: 'Z62.814',
                      title:
                        'Z62.814 Personal history of child financial abuse',
                    },
                    {
                      value: 'Z62.815',
                      title:
                        'Z62.815 Personal history of intimate partner abuse in childhood',
                    },
                    {
                      value: 'Z62.819',
                      title:
                        'Z62.819 Personal history of unspecified abuse in childhood',
                    },
                  ],
                },
                {
                  value: 'Z62.82',
                  title: 'Z62.82 Parent-child conflict',
                  children: [
                    {
                      value: 'Z62.820',
                      title: 'Z62.820 Parent-biological child conflict',
                    },
                    {
                      value: 'Z62.821',
                      title: 'Z62.821 Parent-adopted child conflict',
                    },
                    {
                      value: 'Z62.822',
                      title: 'Z62.822 Parent-foster child conflict',
                    },
                  ],
                },
                {
                  value: 'Z62.89',
                  title:
                    'Z62.89 Other specified problems related to upbringing',
                  children: [
                    {
                      value: 'Z62.890',
                      title: 'Z62.890 Parent-child estrangement NEC',
                    },
                    {
                      value: 'Z62.891',
                      title: 'Z62.891 Sibling rivalry',
                    },
                    {
                      value: 'Z62.898',
                      title:
                        'Z62.898 Other specified problems related to upbringing',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z62.9',
              title: 'Z62.9 Problem related to upbringing, unspecified',
            },
          ],
        },
        {
          value: 'Z63',
          title:
            'Z63 Other problems related to primary support group, including family circumstances',
          children: [
            {
              value: 'Z63.0',
              title: 'Z63.0 Problems in relationship with spouse or partner',
            },
            {
              value: 'Z63.1',
              title: 'Z63.1 Problems in relationship with in-laws',
            },
            {
              value: 'Z63.3',
              title: 'Z63.3 Absence of family member',
              children: [
                {
                  value: 'Z63.31',
                  title:
                    'Z63.31 Absence of family member due to military deployment',
                },
                {
                  value: 'Z63.32',
                  title: 'Z63.32 Other absence of family member',
                },
              ],
            },
            {
              value: 'Z63.4',
              title: 'Z63.4 Disappearance and death of family member',
            },
            {
              value: 'Z63.5',
              title: 'Z63.5 Disruption of family by separation and divorce',
            },
            {
              value: 'Z63.6',
              title: 'Z63.6 Dependent relative needing care at home',
            },
            {
              value: 'Z63.7',
              title:
                'Z63.7 Other stressful life events affecting family and household',
              children: [
                {
                  value: 'Z63.71',
                  title:
                    'Z63.71 Stress on family due to return of family member from military deployment',
                },
                {
                  value: 'Z63.72',
                  title: 'Z63.72 Alcoholism and drug addiction in family',
                },
                {
                  value: 'Z63.79',
                  title:
                    'Z63.79 Other stressful life events affecting family and household',
                },
              ],
            },
            {
              value: 'Z63.8',
              title:
                'Z63.8 Other specified problems related to primary support group',
            },
            {
              value: 'Z63.9',
              title:
                'Z63.9 Problem related to primary support group, unspecified',
            },
          ],
        },
        {
          value: 'Z64',
          title: 'Z64 Problems related to certain psychosocial circumstances',
          children: [
            {
              value: 'Z64.0',
              title: 'Z64.0 Problems related to unwanted pregnancy',
            },
            {
              value: 'Z64.1',
              title: 'Z64.1 Problems related to multiparity',
            },
            {
              value: 'Z64.4',
              title: 'Z64.4 Discord with counselors',
            },
          ],
        },
        {
          value: 'Z65',
          title: 'Z65 Problems related to other psychosocial circumstances',
          children: [
            {
              value: 'Z65.0',
              title:
                'Z65.0 Conviction in civil and criminal proceedings without imprisonment',
            },
            {
              value: 'Z65.1',
              title: 'Z65.1 Imprisonment and other incarceration',
            },
            {
              value: 'Z65.2',
              title: 'Z65.2 Problems related to release from prison',
            },
            {
              value: 'Z65.3',
              title: 'Z65.3 Problems related to other legal circumstances',
            },
            {
              value: 'Z65.4',
              title: 'Z65.4 Victim of crime and terrorism',
            },
            {
              value: 'Z65.5',
              title: 'Z65.5 Exposure to disaster, war and other hostilities',
            },
            {
              value: 'Z65.8',
              title:
                'Z65.8 Other specified problems related to psychosocial circumstances',
            },
            {
              value: 'Z65.9',
              title:
                'Z65.9 Problem related to unspecified psychosocial circumstances',
            },
          ],
        },
      ],
    },
    {
      value: 'Z66',
      title: 'Z66 Do not resuscitate status',
      children: [
        {
          value: 'Z66.my',
          title: 'Z66 Do not resuscitate',
        },
      ],
    },
    {
      value: 'Z67',
      title: 'Z67 Blood type',
      children: [
        {
          value: 'Z67.my',
          title: 'Z67 Blood type',
          children: [
            {
              value: 'Z67.1',
              title: 'Z67.1 Type A blood',
              children: [
                {
                  value: 'Z67.10',
                  title: 'Z67.10 Type A blood, Rh positive',
                },
                {
                  value: 'Z67.11',
                  title: 'Z67.11 Type A blood, Rh negative',
                },
              ],
            },
            {
              value: 'Z67.2',
              title: 'Z67.2 Type B blood',
              children: [
                {
                  value: 'Z67.20',
                  title: 'Z67.20 Type B blood, Rh positive',
                },
                {
                  value: 'Z67.21',
                  title: 'Z67.21 Type B blood, Rh negative',
                },
              ],
            },
            {
              value: 'Z67.3',
              title: 'Z67.3 Type AB blood',
              children: [
                {
                  value: 'Z67.30',
                  title: 'Z67.30 Type AB blood, Rh positive',
                },
                {
                  value: 'Z67.31',
                  title: 'Z67.31 Type AB blood, Rh negative',
                },
              ],
            },
            {
              value: 'Z67.4',
              title: 'Z67.4 Type O blood',
              children: [
                {
                  value: 'Z67.40',
                  title: 'Z67.40 Type O blood, Rh positive',
                },
                {
                  value: 'Z67.41',
                  title: 'Z67.41 Type O blood, Rh negative',
                },
              ],
            },
            {
              value: 'Z67.9',
              title: 'Z67.9 Unspecified blood type',
              children: [
                {
                  value: 'Z67.90',
                  title: 'Z67.90 Unspecified blood type, Rh positive',
                },
                {
                  value: 'Z67.91',
                  title: 'Z67.91 Unspecified blood type, Rh negative',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'Z68',
      title: 'Z68 Body mass index [BMI]',
      children: [
        {
          value: 'Z68.my',
          title: 'Z68 Body mass index [BMI]',
          children: [
            {
              value: 'Z68.1',
              title: 'Z68.1 Body mass index [BMI] 19.9 or less, adult',
            },
            {
              value: 'Z68.2',
              title: 'Z68.2 Body mass index [BMI] 20-29, adult',
              children: [
                {
                  value: 'Z68.20',
                  title: 'Z68.20 Body mass index [BMI] 20.0-20.9, adult',
                },
                {
                  value: 'Z68.21',
                  title: 'Z68.21 Body mass index [BMI] 21.0-21.9, adult',
                },
                {
                  value: 'Z68.22',
                  title: 'Z68.22 Body mass index [BMI] 22.0-22.9, adult',
                },
                {
                  value: 'Z68.23',
                  title: 'Z68.23 Body mass index [BMI] 23.0-23.9, adult',
                },
                {
                  value: 'Z68.24',
                  title: 'Z68.24 Body mass index [BMI] 24.0-24.9, adult',
                },
                {
                  value: 'Z68.25',
                  title: 'Z68.25 Body mass index [BMI] 25.0-25.9, adult',
                },
                {
                  value: 'Z68.26',
                  title: 'Z68.26 Body mass index [BMI] 26.0-26.9, adult',
                },
                {
                  value: 'Z68.27',
                  title: 'Z68.27 Body mass index [BMI] 27.0-27.9, adult',
                },
                {
                  value: 'Z68.28',
                  title: 'Z68.28 Body mass index [BMI] 28.0-28.9, adult',
                },
                {
                  value: 'Z68.29',
                  title: 'Z68.29 Body mass index [BMI] 29.0-29.9, adult',
                },
              ],
            },
            {
              value: 'Z68.3',
              title: 'Z68.3 Body mass index [BMI] 30-39, adult',
              children: [
                {
                  value: 'Z68.30',
                  title: 'Z68.30 Body mass index [BMI] 30.0-30.9, adult',
                },
                {
                  value: 'Z68.31',
                  title: 'Z68.31 Body mass index [BMI] 31.0-31.9, adult',
                },
                {
                  value: 'Z68.32',
                  title: 'Z68.32 Body mass index [BMI] 32.0-32.9, adult',
                },
                {
                  value: 'Z68.33',
                  title: 'Z68.33 Body mass index [BMI] 33.0-33.9, adult',
                },
                {
                  value: 'Z68.34',
                  title: 'Z68.34 Body mass index [BMI] 34.0-34.9, adult',
                },
                {
                  value: 'Z68.35',
                  title: 'Z68.35 Body mass index [BMI] 35.0-35.9, adult',
                },
                {
                  value: 'Z68.36',
                  title: 'Z68.36 Body mass index [BMI] 36.0-36.9, adult',
                },
                {
                  value: 'Z68.37',
                  title: 'Z68.37 Body mass index [BMI] 37.0-37.9, adult',
                },
                {
                  value: 'Z68.38',
                  title: 'Z68.38 Body mass index [BMI] 38.0-38.9, adult',
                },
                {
                  value: 'Z68.39',
                  title: 'Z68.39 Body mass index [BMI] 39.0-39.9, adult',
                },
              ],
            },
            {
              value: 'Z68.4',
              title: 'Z68.4 Body mass index [BMI] 40 or greater, adult',
              children: [
                {
                  value: 'Z68.41',
                  title: 'Z68.41 Body mass index [BMI] 40.0-44.9, adult',
                },
                {
                  value: 'Z68.42',
                  title: 'Z68.42 Body mass index [BMI] 45.0-49.9, adult',
                },
                {
                  value: 'Z68.43',
                  title: 'Z68.43 Body mass index [BMI] 50.0-59.9, adult',
                },
                {
                  value: 'Z68.44',
                  title: 'Z68.44 Body mass index [BMI] 60.0-69.9, adult',
                },
                {
                  value: 'Z68.45',
                  title: 'Z68.45 Body mass index [BMI] 70 or greater, adult',
                },
              ],
            },
            {
              value: 'Z68.5',
              title: 'Z68.5 Body mass index [BMI] pediatric',
              children: [
                {
                  value: 'Z68.51',
                  title:
                    'Z68.51 Body mass index [BMI] pediatric, less than 5th percentile for age',
                },
                {
                  value: 'Z68.52',
                  title:
                    'Z68.52 Body mass index [BMI] pediatric, 5th percentile to less than 85th percentile for age',
                },
                {
                  value: 'Z68.53',
                  title:
                    'Z68.53 Body mass index [BMI] pediatric, 85th percentile to less than 95th percentile for age',
                },
                {
                  value: 'Z68.54',
                  title:
                    'Z68.54 Body mass index [BMI] pediatric, greater than or equal to 95th percentile for age',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'Z69-Z76',
      title:
        'Z69-Z76 Persons encountering health services in other circumstances',
      children: [
        {
          value: 'Z69',
          title:
            'Z69 Encounter for mental health services for victim and perpetrator of abuse',
          children: [
            {
              value: 'Z69.0',
              title:
                'Z69.0 Encounter for mental health services for child abuse problems',
              children: [
                {
                  value: 'Z69.01',
                  title:
                    'Z69.01 Encounter for mental health services for parental child abuse',
                  children: [
                    {
                      value: 'Z69.010',
                      title:
                        'Z69.010 Encounter for mental health services for victim of parental child abuse',
                    },
                    {
                      value: 'Z69.011',
                      title:
                        'Z69.011 Encounter for mental health services for perpetrator of parental child abuse',
                    },
                  ],
                },
                {
                  value: 'Z69.02',
                  title:
                    'Z69.02 Encounter for mental health services for non-parental child abuse',
                  children: [
                    {
                      value: 'Z69.020',
                      title:
                        'Z69.020 Encounter for mental health services for victim of non-parental child abuse',
                    },
                    {
                      value: 'Z69.021',
                      title:
                        'Z69.021 Encounter for mental health services for perpetrator of non-parental child abuse',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z69.1',
              title:
                'Z69.1 Encounter for mental health services for spousal or partner abuse problems',
              children: [
                {
                  value: 'Z69.11',
                  title:
                    'Z69.11 Encounter for mental health services for victim of spousal or partner abuse',
                },
                {
                  value: 'Z69.12',
                  title:
                    'Z69.12 Encounter for mental health services for perpetrator of spousal or partner abuse',
                },
              ],
            },
            {
              value: 'Z69.8',
              title:
                'Z69.8 Encounter for mental health services for victim or perpetrator of other abuse',
              children: [
                {
                  value: 'Z69.81',
                  title:
                    'Z69.81 Encounter for mental health services for victim of other abuse',
                },
                {
                  value: 'Z69.82',
                  title:
                    'Z69.82 Encounter for mental health services for perpetrator of other abuse',
                },
              ],
            },
          ],
        },
        {
          value: 'Z70',
          title:
            'Z70 Counseling related to sexual attitude, behavior and orientation',
          children: [
            {
              value: 'Z70.0',
              title: 'Z70.0 Counseling related to sexual attitude',
            },
            {
              value: 'Z70.1',
              title:
                "Z70.1 Counseling related to patient's sexual behavior and orientation",
            },
            {
              value: 'Z70.2',
              title:
                'Z70.2 Counseling related to sexual behavior and orientation of third party',
            },
            {
              value: 'Z70.3',
              title:
                'Z70.3 Counseling related to combined concerns regarding sexual attitude, behavior and orientation',
            },
            {
              value: 'Z70.8',
              title: 'Z70.8 Other sex counseling',
            },
            {
              value: 'Z70.9',
              title: 'Z70.9 Sex counseling, unspecified',
            },
          ],
        },
        {
          value: 'Z71',
          title:
            'Z71 Persons encountering health services for other counseling and medical advice, not elsewhere classified',
          children: [
            {
              value: 'Z71.0',
              title:
                'Z71.0 Person encountering health services to consult on behalf of another person',
            },
            {
              value: 'Z71.1',
              title:
                'Z71.1 Person with feared health complaint in whom no diagnosis is made',
            },
            {
              value: 'Z71.2',
              title:
                'Z71.2 Person consulting for explanation of examination or test findings',
            },
            {
              value: 'Z71.3',
              title: 'Z71.3 Dietary counseling and surveillance',
            },
            {
              value: 'Z71.4',
              title: 'Z71.4 Alcohol abuse counseling and surveillance',
              children: [
                {
                  value: 'Z71.41',
                  title:
                    'Z71.41 Alcohol abuse counseling and surveillance of alcoholic',
                },
                {
                  value: 'Z71.42',
                  title: 'Z71.42 Counseling for family member of alcoholic',
                },
              ],
            },
            {
              value: 'Z71.5',
              title: 'Z71.5 Drug abuse counseling and surveillance',
              children: [
                {
                  value: 'Z71.51',
                  title:
                    'Z71.51 Drug abuse counseling and surveillance of drug abuser',
                },
                {
                  value: 'Z71.52',
                  title: 'Z71.52 Counseling for family member of drug abuser',
                },
              ],
            },
            {
              value: 'Z71.6',
              title: 'Z71.6 Tobacco abuse counseling',
            },
            {
              value: 'Z71.7',
              title: 'Z71.7 Human immunodeficiency virus [HIV] counseling',
            },
            {
              value: 'Z71.8',
              title: 'Z71.8 Other specified counseling',
              children: [
                {
                  value: 'Z71.81',
                  title: 'Z71.81 Spiritual or religious counseling',
                },
                {
                  value: 'Z71.82',
                  title: 'Z71.82 Exercise counseling',
                },
                {
                  value: 'Z71.83',
                  title:
                    'Z71.83 Encounter for nonprocreative genetic counseling',
                },
                {
                  value: 'Z71.84',
                  title:
                    'Z71.84 Encounter for health counseling related to travel',
                },
                {
                  value: 'Z71.85',
                  title: 'Z71.85 Encounter for immunization safety counseling',
                },
                {
                  value: 'Z71.87',
                  title:
                    'Z71.87 Encounter for pediatric-to-adult transition counseling',
                },
                {
                  value: 'Z71.88',
                  title:
                    'Z71.88 Encounter for counseling for socioeconomic factors',
                },
                {
                  value: 'Z71.89',
                  title: 'Z71.89 Other specified counseling',
                },
              ],
            },
            {
              value: 'Z71.9',
              title: 'Z71.9 Counseling, unspecified',
            },
          ],
        },
        {
          value: 'Z72',
          title: 'Z72 Problems related to lifestyle',
          children: [
            {
              value: 'Z72.0',
              title: 'Z72.0 Tobacco use',
            },
            {
              value: 'Z72.3',
              title: 'Z72.3 Lack of physical exercise',
            },
            {
              value: 'Z72.4',
              title: 'Z72.4 Inappropriate diet and eating habits',
            },
            {
              value: 'Z72.5',
              title: 'Z72.5 High risk sexual behavior',
              children: [
                {
                  value: 'Z72.51',
                  title: 'Z72.51 High risk heterosexual behavior',
                },
                {
                  value: 'Z72.52',
                  title: 'Z72.52 High risk homosexual behavior',
                },
                {
                  value: 'Z72.53',
                  title: 'Z72.53 High risk bisexual behavior',
                },
              ],
            },
            {
              value: 'Z72.6',
              title: 'Z72.6 Gambling and betting',
            },
            {
              value: 'Z72.8',
              title: 'Z72.8 Other problems related to lifestyle',
              children: [
                {
                  value: 'Z72.81',
                  title: 'Z72.81 Antisocial behavior',
                  children: [
                    {
                      value: 'Z72.810',
                      title: 'Z72.810 Child and adolescent antisocial behavior',
                    },
                    {
                      value: 'Z72.811',
                      title: 'Z72.811 Adult antisocial behavior',
                    },
                  ],
                },
                {
                  value: 'Z72.82',
                  title: 'Z72.82 Problems related to sleep',
                  children: [
                    {
                      value: 'Z72.820',
                      title: 'Z72.820 Sleep deprivation',
                    },
                    {
                      value: 'Z72.821',
                      title: 'Z72.821 Inadequate sleep hygiene',
                    },
                    {
                      value: 'Z72.823',
                      title:
                        'Z72.823 Risk of suffocation (smothering) under another while sleeping',
                    },
                  ],
                },
                {
                  value: 'Z72.89',
                  title: 'Z72.89 Other problems related to lifestyle',
                },
              ],
            },
            {
              value: 'Z72.9',
              title: 'Z72.9 Problem related to lifestyle, unspecified',
            },
          ],
        },
        {
          value: 'Z73',
          title: 'Z73 Problems related to life management difficulty',
          children: [
            {
              value: 'Z73.0',
              title: 'Z73.0 Burn-out',
            },
            {
              value: 'Z73.1',
              title: 'Z73.1 Type A behavior pattern',
            },
            {
              value: 'Z73.2',
              title: 'Z73.2 Lack of relaxation and leisure',
            },
            {
              value: 'Z73.3',
              title: 'Z73.3 Stress, not elsewhere classified',
            },
            {
              value: 'Z73.4',
              title: 'Z73.4 Inadequate social skills, not elsewhere classified',
            },
            {
              value: 'Z73.5',
              title: 'Z73.5 Social role conflict, not elsewhere classified',
            },
            {
              value: 'Z73.6',
              title: 'Z73.6 Limitation of activities due to disability',
            },
            {
              value: 'Z73.8',
              title:
                'Z73.8 Other problems related to life management difficulty',
              children: [
                {
                  value: 'Z73.81',
                  title: 'Z73.81 Behavioral insomnia of childhood',
                  children: [
                    {
                      value: 'Z73.810',
                      title:
                        'Z73.810 Behavioral insomnia of childhood, sleep-onset association type',
                    },
                    {
                      value: 'Z73.811',
                      title:
                        'Z73.811 Behavioral insomnia of childhood, limit setting type',
                    },
                    {
                      value: 'Z73.812',
                      title:
                        'Z73.812 Behavioral insomnia of childhood, combined type',
                    },
                    {
                      value: 'Z73.819',
                      title:
                        'Z73.819 Behavioral insomnia of childhood, unspecified type',
                    },
                  ],
                },
                {
                  value: 'Z73.82',
                  title: 'Z73.82 Dual sensory impairment',
                },
                {
                  value: 'Z73.89',
                  title:
                    'Z73.89 Other problems related to life management difficulty',
                },
              ],
            },
            {
              value: 'Z73.9',
              title:
                'Z73.9 Problem related to life management difficulty, unspecified',
            },
          ],
        },
        {
          value: 'Z74',
          title: 'Z74 Problems related to care provider dependency',
          children: [
            {
              value: 'Z74.0',
              title: 'Z74.0 Reduced mobility',
              children: [
                {
                  value: 'Z74.01',
                  title: 'Z74.01 Bed confinement status',
                },
                {
                  value: 'Z74.09',
                  title: 'Z74.09 Other reduced mobility',
                },
              ],
            },
            {
              value: 'Z74.1',
              title: 'Z74.1 Need for assistance with personal care',
            },
            {
              value: 'Z74.2',
              title:
                'Z74.2 Need for assistance at home and no other household member able to render care',
            },
            {
              value: 'Z74.3',
              title: 'Z74.3 Need for continuous supervision',
            },
            {
              value: 'Z74.8',
              title: 'Z74.8 Other problems related to care provider dependency',
            },
            {
              value: 'Z74.9',
              title:
                'Z74.9 Problem related to care provider dependency, unspecified',
            },
          ],
        },
        {
          value: 'Z75',
          title:
            'Z75 Problems related to medical facilities and other health care',
          children: [
            {
              value: 'Z75.0',
              title: 'Z75.0 Medical services not available in home',
            },
            {
              value: 'Z75.1',
              title:
                'Z75.1 Person awaiting admission to adequate facility elsewhere',
            },
            {
              value: 'Z75.2',
              title:
                'Z75.2 Other waiting period for investigation and treatment',
            },
            {
              value: 'Z75.3',
              title:
                'Z75.3 Unavailability and inaccessibility of health-care facilities',
            },
            {
              value: 'Z75.4',
              title:
                'Z75.4 Unavailability and inaccessibility of other helping agencies',
            },
            {
              value: 'Z75.5',
              title: 'Z75.5 Holiday relief care',
            },
            {
              value: 'Z75.8',
              title:
                'Z75.8 Other problems related to medical facilities and other health care',
            },
            {
              value: 'Z75.9',
              title:
                'Z75.9 Unspecified problem related to medical facilities and other health care',
            },
          ],
        },
        {
          value: 'Z76',
          title:
            'Z76 Persons encountering health services in other circumstances',
          children: [
            {
              value: 'Z76.0',
              title: 'Z76.0 Encounter for issue of repeat prescription',
            },
            {
              value: 'Z76.1',
              title:
                'Z76.1 Encounter for health supervision and care of foundling',
            },
            {
              value: 'Z76.2',
              title:
                'Z76.2 Encounter for health supervision and care of other healthy infant and child',
            },
            {
              value: 'Z76.3',
              title: 'Z76.3 Healthy person accompanying sick person',
            },
            {
              value: 'Z76.4',
              title: 'Z76.4 Other boarder to healthcare facility',
            },
            {
              value: 'Z76.5',
              title: 'Z76.5 Malingerer [conscious simulation]',
            },
            {
              value: 'Z76.8',
              title:
                'Z76.8 Persons encountering health services in other specified circumstances',
              children: [
                {
                  value: 'Z76.81',
                  title:
                    'Z76.81 Expectant parent(s) prebirth pediatrician visit',
                },
                {
                  value: 'Z76.82',
                  title: 'Z76.82 Awaiting organ transplant status',
                },
                {
                  value: 'Z76.89',
                  title:
                    'Z76.89 Persons encountering health services in other specified circumstances',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'Z77-Z99',
      title:
        'Z77-Z99 Persons with potential health hazards related to family and personal history and certain conditions influencing health status',
      children: [
        {
          value: 'Z77',
          title:
            'Z77 Other contact with and (suspected) exposures hazardous to health',
          children: [
            {
              value: 'Z77.0',
              title:
                'Z77.0 Contact with and (suspected) exposure to hazardous, chiefly nonmedicinal, chemicals',
              children: [
                {
                  value: 'Z77.01',
                  title:
                    'Z77.01 Contact with and (suspected) exposure to hazardous metals',
                  children: [
                    {
                      value: 'Z77.010',
                      title:
                        'Z77.010 Contact with and (suspected) exposure to arsenic',
                    },
                    {
                      value: 'Z77.011',
                      title:
                        'Z77.011 Contact with and (suspected) exposure to lead',
                    },
                    {
                      value: 'Z77.012',
                      title:
                        'Z77.012 Contact with and (suspected) exposure to uranium',
                    },
                    {
                      value: 'Z77.018',
                      title:
                        'Z77.018 Contact with and (suspected) exposure to other hazardous metals',
                    },
                  ],
                },
                {
                  value: 'Z77.02',
                  title:
                    'Z77.02 Contact with and (suspected) exposure to hazardous aromatic compounds',
                  children: [
                    {
                      value: 'Z77.020',
                      title:
                        'Z77.020 Contact with and (suspected) exposure to aromatic amines',
                    },
                    {
                      value: 'Z77.021',
                      title:
                        'Z77.021 Contact with and (suspected) exposure to benzene',
                    },
                    {
                      value: 'Z77.028',
                      title:
                        'Z77.028 Contact with and (suspected) exposure to other hazardous aromatic compounds',
                    },
                  ],
                },
                {
                  value: 'Z77.09',
                  title:
                    'Z77.09 Contact with and (suspected) exposure to other hazardous, chiefly nonmedicinal, chemicals',
                  children: [
                    {
                      value: 'Z77.090',
                      title:
                        'Z77.090 Contact with and (suspected) exposure to asbestos',
                    },
                    {
                      value: 'Z77.098',
                      title:
                        'Z77.098 Contact with and (suspected) exposure to other hazardous, chiefly nonmedicinal, chemicals',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z77.1',
              title:
                'Z77.1 Contact with and (suspected) exposure to environmental pollution and hazards in the physical environment',
              children: [
                {
                  value: 'Z77.11',
                  title:
                    'Z77.11 Contact with and (suspected) exposure to environmental pollution',
                  children: [
                    {
                      value: 'Z77.110',
                      title:
                        'Z77.110 Contact with and (suspected) exposure to air pollution',
                    },
                    {
                      value: 'Z77.111',
                      title:
                        'Z77.111 Contact with and (suspected) exposure to water pollution',
                    },
                    {
                      value: 'Z77.112',
                      title:
                        'Z77.112 Contact with and (suspected) exposure to soil pollution',
                    },
                    {
                      value: 'Z77.118',
                      title:
                        'Z77.118 Contact with and (suspected) exposure to other environmental pollution',
                    },
                  ],
                },
                {
                  value: 'Z77.12',
                  title:
                    'Z77.12 Contact with and (suspected) exposure to hazards in the physical environment',
                  children: [
                    {
                      value: 'Z77.120',
                      title:
                        'Z77.120 Contact with and (suspected) exposure to mold (toxic)',
                    },
                    {
                      value: 'Z77.121',
                      title:
                        'Z77.121 Contact with and (suspected) exposure to harmful algae and algae toxins',
                    },
                    {
                      value: 'Z77.122',
                      title:
                        'Z77.122 Contact with and (suspected) exposure to noise',
                    },
                    {
                      value: 'Z77.123',
                      title:
                        'Z77.123 Contact with and (suspected) exposure to radon and other naturally occurring radiation',
                    },
                    {
                      value: 'Z77.128',
                      title:
                        'Z77.128 Contact with and (suspected) exposure to other hazards in the physical environment',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z77.2',
              title:
                'Z77.2 Contact with and (suspected) exposure to other hazardous substances',
              children: [
                {
                  value: 'Z77.21',
                  title:
                    'Z77.21 Contact with and (suspected) exposure to potentially hazardous body fluids',
                },
                {
                  value: 'Z77.22',
                  title:
                    'Z77.22 Contact with and (suspected) exposure to environmental tobacco smoke (acute) (chronic)',
                },
                {
                  value: 'Z77.29',
                  title:
                    'Z77.29 Contact with and (suspected) exposure to other hazardous substances',
                },
              ],
            },
            {
              value: 'Z77.9',
              title:
                'Z77.9 Other contact with and (suspected) exposures hazardous to health',
            },
          ],
        },
        {
          value: 'Z78',
          title: 'Z78 Other specified health status',
          children: [
            {
              value: 'Z78.0',
              title: 'Z78.0 Asymptomatic menopausal state',
            },
            {
              value: 'Z78.1',
              title: 'Z78.1 Physical restraint status',
            },
            {
              value: 'Z78.9',
              title: 'Z78.9 Other specified health status',
            },
          ],
        },
        {
          value: 'Z79',
          title: 'Z79 Long term (current) drug therapy',
          children: [
            {
              value: 'Z79.0',
              title:
                'Z79.0 Long term (current) use of anticoagulants and antithrombotics/antiplatelets',
              children: [
                {
                  value: 'Z79.01',
                  title: 'Z79.01 Long term (current) use of anticoagulants',
                },
                {
                  value: 'Z79.02',
                  title:
                    'Z79.02 Long term (current) use of antithrombotics/antiplatelets',
                },
              ],
            },
            {
              value: 'Z79.1',
              title:
                'Z79.1 Long term (current) use of non-steroidal anti-inflammatories (NSAID)',
            },
            {
              value: 'Z79.2',
              title: 'Z79.2 Long term (current) use of antibiotics',
            },
            {
              value: 'Z79.3',
              title: 'Z79.3 Long term (current) use of hormonal contraceptives',
            },
            {
              value: 'Z79.4',
              title: 'Z79.4 Long term (current) use of insulin',
            },
            {
              value: 'Z79.5',
              title: 'Z79.5 Long term (current) use of steroids',
              children: [
                {
                  value: 'Z79.51',
                  title: 'Z79.51 Long term (current) use of inhaled steroids',
                },
                {
                  value: 'Z79.52',
                  title: 'Z79.52 Long term (current) use of systemic steroids',
                },
              ],
            },
            {
              value: 'Z79.6',
              title:
                'Z79.6 Long term (current) use of immunomodulators and immunosuppressants',
              children: [
                {
                  value: 'Z79.60',
                  title:
                    'Z79.60 Long term (current) use of unspecified immunomodulators and immunosuppressants',
                },
                {
                  value: 'Z79.61',
                  title: 'Z79.61 Long term (current) use of immunomodulator',
                },
                {
                  value: 'Z79.62',
                  title: 'Z79.62 Long term (current) use of immunosuppressant',
                  children: [
                    {
                      value: 'Z79.620',
                      title:
                        'Z79.620 Long term (current) use of immunosuppressive biologic',
                    },
                    {
                      value: 'Z79.621',
                      title:
                        'Z79.621 Long term (current) use of calcineurin inhibitor',
                    },
                    {
                      value: 'Z79.622',
                      title:
                        'Z79.622 Long term (current) use of Janus kinase inhibitor',
                    },
                    {
                      value: 'Z79.623',
                      title:
                        'Z79.623 Long term (current) use of mammalian target of rapamycin (mTOR) inhibitor',
                    },
                    {
                      value: 'Z79.624',
                      title:
                        'Z79.624 Long term (current) use of inhibitors of nucleotide synthesis',
                    },
                  ],
                },
                {
                  value: 'Z79.63',
                  title:
                    'Z79.63 Long term (current) use of chemotherapeutic agent',
                  children: [
                    {
                      value: 'Z79.630',
                      title:
                        'Z79.630 Long term (current) use of alkylating agent',
                    },
                    {
                      value: 'Z79.631',
                      title:
                        'Z79.631 Long term (current) use of antimetabolite agent',
                    },
                    {
                      value: 'Z79.632',
                      title:
                        'Z79.632 Long term (current) use of antitumor antibiotic',
                    },
                    {
                      value: 'Z79.633',
                      title:
                        'Z79.633 Long term (current) use of mitotic inhibitor',
                    },
                    {
                      value: 'Z79.634',
                      title:
                        'Z79.634 Long term (current) use of topoisomerase inhibitor',
                    },
                  ],
                },
                {
                  value: 'Z79.64',
                  title:
                    'Z79.64 Long term (current) use of myelosuppressive agent',
                },
                {
                  value: 'Z79.69',
                  title:
                    'Z79.69 Long term (current) use of other immunomodulators and immunosuppressants',
                },
              ],
            },
            {
              value: 'Z79.8',
              title: 'Z79.8 Other long term (current) drug therapy',
              children: [
                {
                  value: 'Z79.81',
                  title:
                    'Z79.81 Long term (current) use of agents affecting estrogen receptors and estrogen levels',
                  children: [
                    {
                      value: 'Z79.810',
                      title:
                        'Z79.810 Long term (current) use of selective estrogen receptor modulators (SERMs)',
                    },
                    {
                      value: 'Z79.811',
                      title:
                        'Z79.811 Long term (current) use of aromatase inhibitors',
                    },
                    {
                      value: 'Z79.818',
                      title:
                        'Z79.818 Long term (current) use of other agents affecting estrogen receptors and estrogen levels',
                    },
                  ],
                },
                {
                  value: 'Z79.82',
                  title: 'Z79.82 Long term (current) use of aspirin',
                },
                {
                  value: 'Z79.83',
                  title: 'Z79.83 Long term (current) use of bisphosphonates',
                },
                {
                  value: 'Z79.84',
                  title:
                    'Z79.84 Long term (current) use of oral hypoglycemic drugs',
                },
                {
                  value: 'Z79.85',
                  title:
                    'Z79.85 Long-term (current) use of injectable non-insulin antidiabetic drugs',
                },
                {
                  value: 'Z79.89',
                  title: 'Z79.89 Other long term (current) drug therapy',
                  children: [
                    {
                      value: 'Z79.890',
                      title: 'Z79.890 Hormone replacement therapy',
                    },
                    {
                      value: 'Z79.891',
                      title:
                        'Z79.891 Long term (current) use of opiate analgesic',
                    },
                    {
                      value: 'Z79.899',
                      title: 'Z79.899 Other long term (current) drug therapy',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          value: 'Z80',
          title: 'Z80 Family history of primary malignant neoplasm',
          children: [
            {
              value: 'Z80.0',
              title:
                'Z80.0 Family history of malignant neoplasm of digestive organs',
            },
            {
              value: 'Z80.1',
              title:
                'Z80.1 Family history of malignant neoplasm of trachea, bronchus and lung',
            },
            {
              value: 'Z80.2',
              title:
                'Z80.2 Family history of malignant neoplasm of other respiratory and intrathoracic organs',
            },
            {
              value: 'Z80.3',
              title: 'Z80.3 Family history of malignant neoplasm of breast',
            },
            {
              value: 'Z80.4',
              title:
                'Z80.4 Family history of malignant neoplasm of genital organs',
              children: [
                {
                  value: 'Z80.41',
                  title: 'Z80.41 Family history of malignant neoplasm of ovary',
                },
                {
                  value: 'Z80.42',
                  title:
                    'Z80.42 Family history of malignant neoplasm of prostate',
                },
                {
                  value: 'Z80.43',
                  title:
                    'Z80.43 Family history of malignant neoplasm of testis',
                },
                {
                  value: 'Z80.49',
                  title:
                    'Z80.49 Family history of malignant neoplasm of other genital organs',
                },
              ],
            },
            {
              value: 'Z80.5',
              title:
                'Z80.5 Family history of malignant neoplasm of urinary tract',
              children: [
                {
                  value: 'Z80.51',
                  title:
                    'Z80.51 Family history of malignant neoplasm of kidney',
                },
                {
                  value: 'Z80.52',
                  title:
                    'Z80.52 Family history of malignant neoplasm of bladder',
                },
                {
                  value: 'Z80.59',
                  title:
                    'Z80.59 Family history of malignant neoplasm of other urinary tract organ',
                },
              ],
            },
            {
              value: 'Z80.6',
              title: 'Z80.6 Family history of leukemia',
            },
            {
              value: 'Z80.7',
              title:
                'Z80.7 Family history of other malignant neoplasms of lymphoid, hematopoietic and related tissues',
            },
            {
              value: 'Z80.8',
              title:
                'Z80.8 Family history of malignant neoplasm of other organs or systems',
            },
            {
              value: 'Z80.9',
              title: 'Z80.9 Family history of malignant neoplasm, unspecified',
            },
          ],
        },
        {
          value: 'Z81',
          title: 'Z81 Family history of mental and behavioral disorders',
          children: [
            {
              value: 'Z81.0',
              title: 'Z81.0 Family history of intellectual disabilities',
            },
            {
              value: 'Z81.1',
              title: 'Z81.1 Family history of alcohol abuse and dependence',
            },
            {
              value: 'Z81.2',
              title: 'Z81.2 Family history of tobacco abuse and dependence',
            },
            {
              value: 'Z81.3',
              title:
                'Z81.3 Family history of other psychoactive substance abuse and dependence',
            },
            {
              value: 'Z81.4',
              title:
                'Z81.4 Family history of other substance abuse and dependence',
            },
            {
              value: 'Z81.8',
              title:
                'Z81.8 Family history of other mental and behavioral disorders',
            },
          ],
        },
        {
          value: 'Z82',
          title:
            'Z82 Family history of certain disabilities and chronic diseases (leading to disablement)',
          children: [
            {
              value: 'Z82.0',
              title:
                'Z82.0 Family history of epilepsy and other diseases of the nervous system',
            },
            {
              value: 'Z82.1',
              title: 'Z82.1 Family history of blindness and visual loss',
            },
            {
              value: 'Z82.2',
              title: 'Z82.2 Family history of deafness and hearing loss',
            },
            {
              value: 'Z82.3',
              title: 'Z82.3 Family history of stroke',
            },
            {
              value: 'Z82.4',
              title:
                'Z82.4 Family history of ischemic heart disease and other diseases of the circulatory system',
              children: [
                {
                  value: 'Z82.41',
                  title: 'Z82.41 Family history of sudden cardiac death',
                },
                {
                  value: 'Z82.49',
                  title:
                    'Z82.49 Family history of ischemic heart disease and other diseases of the circulatory system',
                },
              ],
            },
            {
              value: 'Z82.5',
              title:
                'Z82.5 Family history of asthma and other chronic lower respiratory diseases',
            },
            {
              value: 'Z82.6',
              title:
                'Z82.6 Family history of arthritis and other diseases of the musculoskeletal system and connective tissue',
              children: [
                {
                  value: 'Z82.61',
                  title: 'Z82.61 Family history of arthritis',
                },
                {
                  value: 'Z82.62',
                  title: 'Z82.62 Family history of osteoporosis',
                },
                {
                  value: 'Z82.69',
                  title:
                    'Z82.69 Family history of other diseases of the musculoskeletal system and connective tissue',
                },
              ],
            },
            {
              value: 'Z82.7',
              title:
                'Z82.7 Family history of congenital malformations, deformations and chromosomal abnormalities',
              children: [
                {
                  value: 'Z82.71',
                  title: 'Z82.71 Family history of polycystic kidney',
                },
                {
                  value: 'Z82.79',
                  title:
                    'Z82.79 Family history of other congenital malformations, deformations and chromosomal abnormalities',
                },
              ],
            },
            {
              value: 'Z82.8',
              title:
                'Z82.8 Family history of other disabilities and chronic diseases leading to disablement, not elsewhere classified',
            },
          ],
        },
        {
          value: 'Z83',
          title: 'Z83 Family history of other specific disorders',
          children: [
            {
              value: 'Z83.0',
              title:
                'Z83.0 Family history of human immunodeficiency virus [HIV] disease',
            },
            {
              value: 'Z83.1',
              title:
                'Z83.1 Family history of other infectious and parasitic diseases',
            },
            {
              value: 'Z83.2',
              title:
                'Z83.2 Family history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
            },
            {
              value: 'Z83.3',
              title: 'Z83.3 Family history of diabetes mellitus',
            },
            {
              value: 'Z83.4',
              title:
                'Z83.4 Family history of other endocrine, nutritional and metabolic diseases',
              children: [
                {
                  value: 'Z83.41',
                  title:
                    'Z83.41 Family history of multiple endocrine neoplasia [MEN] syndrome',
                },
                {
                  value: 'Z83.42',
                  title:
                    'Z83.42 Family history of familial hypercholesterolemia',
                },
                {
                  value: 'Z83.43',
                  title:
                    'Z83.43 Family history of other disorder of lipoprotein metabolism and other lipidemias',
                  children: [
                    {
                      value: 'Z83.430',
                      title:
                        'Z83.430 Family history of elevated lipoprotein(a)',
                    },
                    {
                      value: 'Z83.438',
                      title:
                        'Z83.438 Family history of other disorder of lipoprotein metabolism and other lipidemia',
                    },
                  ],
                },
                {
                  value: 'Z83.49',
                  title:
                    'Z83.49 Family history of other endocrine, nutritional and metabolic diseases',
                },
              ],
            },
            {
              value: 'Z83.5',
              title: 'Z83.5 Family history of eye and ear disorders',
              children: [
                {
                  value: 'Z83.51',
                  title: 'Z83.51 Family history of eye disorders',
                  children: [
                    {
                      value: 'Z83.511',
                      title: 'Z83.511 Family history of glaucoma',
                    },
                    {
                      value: 'Z83.518',
                      title:
                        'Z83.518 Family history of other specified eye disorder',
                    },
                  ],
                },
                {
                  value: 'Z83.52',
                  title: 'Z83.52 Family history of ear disorders',
                },
              ],
            },
            {
              value: 'Z83.6',
              title:
                'Z83.6 Family history of other diseases of the respiratory system',
            },
            {
              value: 'Z83.7',
              title: 'Z83.7 Family history of diseases of the digestive system',
              children: [
                {
                  value: 'Z83.71',
                  title: 'Z83.71 Family history of colonic polyps',
                },
                {
                  value: 'Z83.79',
                  title:
                    'Z83.79 Family history of other diseases of the digestive system',
                },
              ],
            },
          ],
        },
        {
          value: 'Z84',
          title: 'Z84 Family history of other conditions',
          children: [
            {
              value: 'Z84.0',
              title:
                'Z84.0 Family history of diseases of the skin and subcutaneous tissue',
            },
            {
              value: 'Z84.1',
              title: 'Z84.1 Family history of disorders of kidney and ureter',
            },
            {
              value: 'Z84.2',
              title:
                'Z84.2 Family history of other diseases of the genitourinary system',
            },
            {
              value: 'Z84.3',
              title: 'Z84.3 Family history of consanguinity',
            },
            {
              value: 'Z84.8',
              title: 'Z84.8 Family history of other specified conditions',
              children: [
                {
                  value: 'Z84.81',
                  title: 'Z84.81 Family history of carrier of genetic disease',
                },
                {
                  value: 'Z84.82',
                  title:
                    'Z84.82 Family history of sudden infant death syndrome',
                },
                {
                  value: 'Z84.89',
                  title: 'Z84.89 Family history of other specified conditions',
                },
              ],
            },
          ],
        },
        {
          value: 'Z85',
          title: 'Z85 Personal history of malignant neoplasm',
          children: [
            {
              value: 'Z85.0',
              title:
                'Z85.0 Personal history of malignant neoplasm of digestive organs',
              children: [
                {
                  value: 'Z85.00',
                  title:
                    'Z85.00 Personal history of malignant neoplasm of unspecified digestive organ',
                },
                {
                  value: 'Z85.01',
                  title:
                    'Z85.01 Personal history of malignant neoplasm of esophagus',
                },
                {
                  value: 'Z85.02',
                  title:
                    'Z85.02 Personal history of malignant neoplasm of stomach',
                  children: [
                    {
                      value: 'Z85.020',
                      title:
                        'Z85.020 Personal history of malignant carcinoid tumor of stomach',
                    },
                    {
                      value: 'Z85.028',
                      title:
                        'Z85.028 Personal history of other malignant neoplasm of stomach',
                    },
                  ],
                },
                {
                  value: 'Z85.03',
                  title:
                    'Z85.03 Personal history of malignant neoplasm of large intestine',
                  children: [
                    {
                      value: 'Z85.030',
                      title:
                        'Z85.030 Personal history of malignant carcinoid tumor of large intestine',
                    },
                    {
                      value: 'Z85.038',
                      title:
                        'Z85.038 Personal history of other malignant neoplasm of large intestine',
                    },
                  ],
                },
                {
                  value: 'Z85.04',
                  title:
                    'Z85.04 Personal history of malignant neoplasm of rectum, rectosigmoid junction, and anus',
                  children: [
                    {
                      value: 'Z85.040',
                      title:
                        'Z85.040 Personal history of malignant carcinoid tumor of rectum',
                    },
                    {
                      value: 'Z85.048',
                      title:
                        'Z85.048 Personal history of other malignant neoplasm of rectum, rectosigmoid junction, and anus',
                    },
                  ],
                },
                {
                  value: 'Z85.05',
                  title:
                    'Z85.05 Personal history of malignant neoplasm of liver',
                },
                {
                  value: 'Z85.06',
                  title:
                    'Z85.06 Personal history of malignant neoplasm of small intestine',
                  children: [
                    {
                      value: 'Z85.060',
                      title:
                        'Z85.060 Personal history of malignant carcinoid tumor of small intestine',
                    },
                    {
                      value: 'Z85.068',
                      title:
                        'Z85.068 Personal history of other malignant neoplasm of small intestine',
                    },
                  ],
                },
                {
                  value: 'Z85.07',
                  title:
                    'Z85.07 Personal history of malignant neoplasm of pancreas',
                },
                {
                  value: 'Z85.09',
                  title:
                    'Z85.09 Personal history of malignant neoplasm of other digestive organs',
                },
              ],
            },
            {
              value: 'Z85.1',
              title:
                'Z85.1 Personal history of malignant neoplasm of trachea, bronchus and lung',
              children: [
                {
                  value: 'Z85.11',
                  title:
                    'Z85.11 Personal history of malignant neoplasm of bronchus and lung',
                  children: [
                    {
                      value: 'Z85.110',
                      title:
                        'Z85.110 Personal history of malignant carcinoid tumor of bronchus and lung',
                    },
                    {
                      value: 'Z85.118',
                      title:
                        'Z85.118 Personal history of other malignant neoplasm of bronchus and lung',
                    },
                  ],
                },
                {
                  value: 'Z85.12',
                  title:
                    'Z85.12 Personal history of malignant neoplasm of trachea',
                },
              ],
            },
            {
              value: 'Z85.2',
              title:
                'Z85.2 Personal history of malignant neoplasm of other respiratory and intrathoracic organs',
              children: [
                {
                  value: 'Z85.20',
                  title:
                    'Z85.20 Personal history of malignant neoplasm of unspecified respiratory organ',
                },
                {
                  value: 'Z85.21',
                  title:
                    'Z85.21 Personal history of malignant neoplasm of larynx',
                },
                {
                  value: 'Z85.22',
                  title:
                    'Z85.22 Personal history of malignant neoplasm of nasal cavities, middle ear, and accessory sinuses',
                },
                {
                  value: 'Z85.23',
                  title:
                    'Z85.23 Personal history of malignant neoplasm of thymus',
                  children: [
                    {
                      value: 'Z85.230',
                      title:
                        'Z85.230 Personal history of malignant carcinoid tumor of thymus',
                    },
                    {
                      value: 'Z85.238',
                      title:
                        'Z85.238 Personal history of other malignant neoplasm of thymus',
                    },
                  ],
                },
                {
                  value: 'Z85.29',
                  title:
                    'Z85.29 Personal history of malignant neoplasm of other respiratory and intrathoracic organs',
                },
              ],
            },
            {
              value: 'Z85.3',
              title: 'Z85.3 Personal history of malignant neoplasm of breast',
            },
            {
              value: 'Z85.4',
              title:
                'Z85.4 Personal history of malignant neoplasm of genital organs',
              children: [
                {
                  value: 'Z85.40',
                  title:
                    'Z85.40 Personal history of malignant neoplasm of unspecified female genital organ',
                },
                {
                  value: 'Z85.41',
                  title:
                    'Z85.41 Personal history of malignant neoplasm of cervix uteri',
                },
                {
                  value: 'Z85.42',
                  title:
                    'Z85.42 Personal history of malignant neoplasm of other parts of uterus',
                },
                {
                  value: 'Z85.43',
                  title:
                    'Z85.43 Personal history of malignant neoplasm of ovary',
                },
                {
                  value: 'Z85.44',
                  title:
                    'Z85.44 Personal history of malignant neoplasm of other female genital organs',
                },
                {
                  value: 'Z85.45',
                  title:
                    'Z85.45 Personal history of malignant neoplasm of unspecified male genital organ',
                },
                {
                  value: 'Z85.46',
                  title:
                    'Z85.46 Personal history of malignant neoplasm of prostate',
                },
                {
                  value: 'Z85.47',
                  title:
                    'Z85.47 Personal history of malignant neoplasm of testis',
                },
                {
                  value: 'Z85.48',
                  title:
                    'Z85.48 Personal history of malignant neoplasm of epididymis',
                },
                {
                  value: 'Z85.49',
                  title:
                    'Z85.49 Personal history of malignant neoplasm of other male genital organs',
                },
              ],
            },
            {
              value: 'Z85.5',
              title:
                'Z85.5 Personal history of malignant neoplasm of urinary tract',
              children: [
                {
                  value: 'Z85.50',
                  title:
                    'Z85.50 Personal history of malignant neoplasm of unspecified urinary tract organ',
                },
                {
                  value: 'Z85.51',
                  title:
                    'Z85.51 Personal history of malignant neoplasm of bladder',
                },
                {
                  value: 'Z85.52',
                  title:
                    'Z85.52 Personal history of malignant neoplasm of kidney',
                  children: [
                    {
                      value: 'Z85.520',
                      title:
                        'Z85.520 Personal history of malignant carcinoid tumor of kidney',
                    },
                    {
                      value: 'Z85.528',
                      title:
                        'Z85.528 Personal history of other malignant neoplasm of kidney',
                    },
                  ],
                },
                {
                  value: 'Z85.53',
                  title:
                    'Z85.53 Personal history of malignant neoplasm of renal pelvis',
                },
                {
                  value: 'Z85.54',
                  title:
                    'Z85.54 Personal history of malignant neoplasm of ureter',
                },
                {
                  value: 'Z85.59',
                  title:
                    'Z85.59 Personal history of malignant neoplasm of other urinary tract organ',
                },
              ],
            },
            {
              value: 'Z85.6',
              title: 'Z85.6 Personal history of leukemia',
            },
            {
              value: 'Z85.7',
              title:
                'Z85.7 Personal history of other malignant neoplasms of lymphoid, hematopoietic and related tissues',
              children: [
                {
                  value: 'Z85.71',
                  title: 'Z85.71 Personal history of Hodgkin lymphoma',
                },
                {
                  value: 'Z85.72',
                  title: 'Z85.72 Personal history of non-Hodgkin lymphomas',
                },
                {
                  value: 'Z85.79',
                  title:
                    'Z85.79 Personal history of other malignant neoplasms of lymphoid, hematopoietic and related tissues',
                },
              ],
            },
            {
              value: 'Z85.8',
              title:
                'Z85.8 Personal history of malignant neoplasms of other organs and systems',
              children: [
                {
                  value: 'Z85.81',
                  title:
                    'Z85.81 Personal history of malignant neoplasm of lip, oral cavity, and pharynx',
                  children: [
                    {
                      value: 'Z85.810',
                      title:
                        'Z85.810 Personal history of malignant neoplasm of tongue',
                    },
                    {
                      value: 'Z85.818',
                      title:
                        'Z85.818 Personal history of malignant neoplasm of other sites of lip, oral cavity, and pharynx',
                    },
                    {
                      value: 'Z85.819',
                      title:
                        'Z85.819 Personal history of malignant neoplasm of unspecified site of lip, oral cavity, and pharynx',
                    },
                  ],
                },
                {
                  value: 'Z85.82',
                  title:
                    'Z85.82 Personal history of malignant neoplasm of skin',
                  children: [
                    {
                      value: 'Z85.820',
                      title:
                        'Z85.820 Personal history of malignant melanoma of skin',
                    },
                    {
                      value: 'Z85.821',
                      title:
                        'Z85.821 Personal history of Merkel cell carcinoma',
                    },
                    {
                      value: 'Z85.828',
                      title:
                        'Z85.828 Personal history of other malignant neoplasm of skin',
                    },
                  ],
                },
                {
                  value: 'Z85.83',
                  title:
                    'Z85.83 Personal history of malignant neoplasm of bone and soft tissue',
                  children: [
                    {
                      value: 'Z85.830',
                      title:
                        'Z85.830 Personal history of malignant neoplasm of bone',
                    },
                    {
                      value: 'Z85.831',
                      title:
                        'Z85.831 Personal history of malignant neoplasm of soft tissue',
                    },
                  ],
                },
                {
                  value: 'Z85.84',
                  title:
                    'Z85.84 Personal history of malignant neoplasm of eye and nervous tissue',
                  children: [
                    {
                      value: 'Z85.840',
                      title:
                        'Z85.840 Personal history of malignant neoplasm of eye',
                    },
                    {
                      value: 'Z85.841',
                      title:
                        'Z85.841 Personal history of malignant neoplasm of brain',
                    },
                    {
                      value: 'Z85.848',
                      title:
                        'Z85.848 Personal history of malignant neoplasm of other parts of nervous tissue',
                    },
                  ],
                },
                {
                  value: 'Z85.85',
                  title:
                    'Z85.85 Personal history of malignant neoplasm of endocrine glands',
                  children: [
                    {
                      value: 'Z85.850',
                      title:
                        'Z85.850 Personal history of malignant neoplasm of thyroid',
                    },
                    {
                      value: 'Z85.858',
                      title:
                        'Z85.858 Personal history of malignant neoplasm of other endocrine glands',
                    },
                  ],
                },
                {
                  value: 'Z85.89',
                  title:
                    'Z85.89 Personal history of malignant neoplasm of other organs and systems',
                },
              ],
            },
            {
              value: 'Z85.9',
              title:
                'Z85.9 Personal history of malignant neoplasm, unspecified',
            },
          ],
        },
        {
          value: 'Z86',
          title: 'Z86 Personal history of certain other diseases',
          children: [
            {
              value: 'Z86.0',
              title:
                'Z86.0 Personal history of in-situ and benign neoplasms and neoplasms of uncertain behavior',
              children: [
                {
                  value: 'Z86.00',
                  title: 'Z86.00 Personal history of in-situ neoplasm',
                  children: [
                    {
                      value: 'Z86.000',
                      title:
                        'Z86.000 Personal history of in-situ neoplasm of breast',
                    },
                    {
                      value: 'Z86.001',
                      title:
                        'Z86.001 Personal history of in-situ neoplasm of cervix uteri',
                    },
                    {
                      value: 'Z86.002',
                      title:
                        'Z86.002 Personal history of in-situ neoplasm of other and unspecified genital organs',
                    },
                    {
                      value: 'Z86.003',
                      title:
                        'Z86.003 Personal history of in-situ neoplasm of oral cavity, esophagus and stomach',
                    },
                    {
                      value: 'Z86.004',
                      title:
                        'Z86.004 Personal history of in-situ neoplasm of other and unspecified digestive organs',
                    },
                    {
                      value: 'Z86.005',
                      title:
                        'Z86.005 Personal history of in-situ neoplasm of middle ear and respiratory system',
                    },
                    {
                      value: 'Z86.006',
                      title: 'Z86.006 Personal history of melanoma in-situ',
                    },
                    {
                      value: 'Z86.007',
                      title:
                        'Z86.007 Personal history of in-situ neoplasm of skin',
                    },
                    {
                      value: 'Z86.008',
                      title:
                        'Z86.008 Personal history of in-situ neoplasm of other site',
                    },
                  ],
                },
                {
                  value: 'Z86.01',
                  title: 'Z86.01 Personal history of benign neoplasm',
                  children: [
                    {
                      value: 'Z86.010',
                      title: 'Z86.010 Personal history of colonic polyps',
                    },
                    {
                      value: 'Z86.011',
                      title:
                        'Z86.011 Personal history of benign neoplasm of the brain',
                    },
                    {
                      value: 'Z86.012',
                      title:
                        'Z86.012 Personal history of benign carcinoid tumor',
                    },
                    {
                      value: 'Z86.018',
                      title:
                        'Z86.018 Personal history of other benign neoplasm',
                    },
                  ],
                },
                {
                  value: 'Z86.03',
                  title:
                    'Z86.03 Personal history of neoplasm of uncertain behavior',
                },
              ],
            },
            {
              value: 'Z86.1',
              title:
                'Z86.1 Personal history of infectious and parasitic diseases',
              children: [
                {
                  value: 'Z86.11',
                  title: 'Z86.11 Personal history of tuberculosis',
                },
                {
                  value: 'Z86.12',
                  title: 'Z86.12 Personal history of poliomyelitis',
                },
                {
                  value: 'Z86.13',
                  title: 'Z86.13 Personal history of malaria',
                },
                {
                  value: 'Z86.14',
                  title:
                    'Z86.14 Personal history of Methicillin resistant Staphylococcus aureus infection',
                },
                {
                  value: 'Z86.15',
                  title:
                    'Z86.15 Personal history of latent tuberculosis infection',
                },
                {
                  value: 'Z86.16',
                  title: 'Z86.16 Personal history of COVID-19',
                },
                {
                  value: 'Z86.19',
                  title:
                    'Z86.19 Personal history of other infectious and parasitic diseases',
                },
              ],
            },
            {
              value: 'Z86.2',
              title:
                'Z86.2 Personal history of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
            },
            {
              value: 'Z86.3',
              title:
                'Z86.3 Personal history of endocrine, nutritional and metabolic diseases',
              children: [
                {
                  value: 'Z86.31',
                  title: 'Z86.31 Personal history of diabetic foot ulcer',
                },
                {
                  value: 'Z86.32',
                  title: 'Z86.32 Personal history of gestational diabetes',
                },
                {
                  value: 'Z86.39',
                  title:
                    'Z86.39 Personal history of other endocrine, nutritional and metabolic disease',
                },
              ],
            },
            {
              value: 'Z86.5',
              title:
                'Z86.5 Personal history of mental and behavioral disorders',
              children: [
                {
                  value: 'Z86.51',
                  title:
                    'Z86.51 Personal history of combat and operational stress reaction',
                },
                {
                  value: 'Z86.59',
                  title:
                    'Z86.59 Personal history of other mental and behavioral disorders',
                },
              ],
            },
            {
              value: 'Z86.6',
              title:
                'Z86.6 Personal history of diseases of the nervous system and sense organs',
              children: [
                {
                  value: 'Z86.61',
                  title:
                    'Z86.61 Personal history of infections of the central nervous system',
                },
                {
                  value: 'Z86.69',
                  title:
                    'Z86.69 Personal history of other diseases of the nervous system and sense organs',
                },
              ],
            },
            {
              value: 'Z86.7',
              title:
                'Z86.7 Personal history of diseases of the circulatory system',
              children: [
                {
                  value: 'Z86.71',
                  title:
                    'Z86.71 Personal history of venous thrombosis and embolism',
                  children: [
                    {
                      value: 'Z86.711',
                      title: 'Z86.711 Personal history of pulmonary embolism',
                    },
                    {
                      value: 'Z86.718',
                      title:
                        'Z86.718 Personal history of other venous thrombosis and embolism',
                    },
                  ],
                },
                {
                  value: 'Z86.72',
                  title: 'Z86.72 Personal history of thrombophlebitis',
                },
                {
                  value: 'Z86.73',
                  title:
                    'Z86.73 Personal history of transient ischemic attack (TIA), and cerebral infarction without residual deficits',
                },
                {
                  value: 'Z86.74',
                  title: 'Z86.74 Personal history of sudden cardiac arrest',
                },
                {
                  value: 'Z86.79',
                  title:
                    'Z86.79 Personal history of other diseases of the circulatory system',
                },
              ],
            },
          ],
        },
        {
          value: 'Z87',
          title: 'Z87 Personal history of other diseases and conditions',
          children: [
            {
              value: 'Z87.0',
              title:
                'Z87.0 Personal history of diseases of the respiratory system',
              children: [
                {
                  value: 'Z87.01',
                  title: 'Z87.01 Personal history of pneumonia (recurrent)',
                },
                {
                  value: 'Z87.09',
                  title:
                    'Z87.09 Personal history of other diseases of the respiratory system',
                },
              ],
            },
            {
              value: 'Z87.1',
              title:
                'Z87.1 Personal history of diseases of the digestive system',
              children: [
                {
                  value: 'Z87.11',
                  title: 'Z87.11 Personal history of peptic ulcer disease',
                },
                {
                  value: 'Z87.19',
                  title:
                    'Z87.19 Personal history of other diseases of the digestive system',
                },
              ],
            },
            {
              value: 'Z87.2',
              title:
                'Z87.2 Personal history of diseases of the skin and subcutaneous tissue',
            },
            {
              value: 'Z87.3',
              title:
                'Z87.3 Personal history of diseases of the musculoskeletal system and connective tissue',
              children: [
                {
                  value: 'Z87.31',
                  title:
                    'Z87.31 Personal history of (healed) nontraumatic fracture',
                  children: [
                    {
                      value: 'Z87.310',
                      title:
                        'Z87.310 Personal history of (healed) osteoporosis fracture',
                    },
                    {
                      value: 'Z87.311',
                      title:
                        'Z87.311 Personal history of (healed) other pathological fracture',
                    },
                    {
                      value: 'Z87.312',
                      title:
                        'Z87.312 Personal history of (healed) stress fracture',
                    },
                  ],
                },
                {
                  value: 'Z87.39',
                  title:
                    'Z87.39 Personal history of other diseases of the musculoskeletal system and connective tissue',
                },
              ],
            },
            {
              value: 'Z87.4',
              title:
                'Z87.4 Personal history of diseases of genitourinary system',
              children: [
                {
                  value: 'Z87.41',
                  title:
                    'Z87.41 Personal history of dysplasia of the female genital tract',
                  children: [
                    {
                      value: 'Z87.410',
                      title: 'Z87.410 Personal history of cervical dysplasia',
                    },
                    {
                      value: 'Z87.411',
                      title: 'Z87.411 Personal history of vaginal dysplasia',
                    },
                    {
                      value: 'Z87.412',
                      title: 'Z87.412 Personal history of vulvar dysplasia',
                    },
                  ],
                },
                {
                  value: 'Z87.42',
                  title:
                    'Z87.42 Personal history of other diseases of the female genital tract',
                },
                {
                  value: 'Z87.43',
                  title:
                    'Z87.43 Personal history of diseases of male genital organs',
                  children: [
                    {
                      value: 'Z87.430',
                      title: 'Z87.430 Personal history of prostatic dysplasia',
                    },
                    {
                      value: 'Z87.438',
                      title:
                        'Z87.438 Personal history of other diseases of male genital organs',
                    },
                  ],
                },
                {
                  value: 'Z87.44',
                  title:
                    'Z87.44 Personal history of diseases of urinary system',
                  children: [
                    {
                      value: 'Z87.440',
                      title:
                        'Z87.440 Personal history of urinary (tract) infections',
                    },
                    {
                      value: 'Z87.441',
                      title: 'Z87.441 Personal history of nephrotic syndrome',
                    },
                    {
                      value: 'Z87.442',
                      title: 'Z87.442 Personal history of urinary calculi',
                    },
                    {
                      value: 'Z87.448',
                      title:
                        'Z87.448 Personal history of other diseases of urinary system',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z87.5',
              title:
                'Z87.5 Personal history of complications of pregnancy, childbirth and the puerperium',
              children: [
                {
                  value: 'Z87.51',
                  title: 'Z87.51 Personal history of pre-term labor',
                },
                {
                  value: 'Z87.59',
                  title:
                    'Z87.59 Personal history of other complications of pregnancy, childbirth and the puerperium',
                },
              ],
            },
            {
              value: 'Z87.6',
              title:
                'Z87.6 Personal history of certain (corrected) conditions arising in the perinatal period',
              children: [
                {
                  value: 'Z87.61',
                  title:
                    'Z87.61 Personal history of (corrected) necrotizing enterocolitis of newborn',
                },
                {
                  value: 'Z87.68',
                  title:
                    'Z87.68 Personal history of other (corrected) conditions arising in the perinatal period',
                },
              ],
            },
            {
              value: 'Z87.7',
              title:
                'Z87.7 Personal history of (corrected) congenital malformations',
              children: [
                {
                  value: 'Z87.71',
                  title:
                    'Z87.71 Personal history of (corrected) congenital malformations of genitourinary system',
                  children: [
                    {
                      value: 'Z87.710',
                      title:
                        'Z87.710 Personal history of (corrected) hypospadias',
                    },
                    {
                      value: 'Z87.718',
                      title:
                        'Z87.718 Personal history of other specified (corrected) congenital malformations of genitourinary system',
                    },
                  ],
                },
                {
                  value: 'Z87.72',
                  title:
                    'Z87.72 Personal history of (corrected) congenital malformations of nervous system and sense organs',
                  children: [
                    {
                      value: 'Z87.720',
                      title:
                        'Z87.720 Personal history of (corrected) congenital malformations of eye',
                    },
                    {
                      value: 'Z87.721',
                      title:
                        'Z87.721 Personal history of (corrected) congenital malformations of ear',
                    },
                    {
                      value: 'Z87.728',
                      title:
                        'Z87.728 Personal history of other specified (corrected) congenital malformations of nervous system and sense organs',
                    },
                  ],
                },
                {
                  value: 'Z87.73',
                  title:
                    'Z87.73 Personal history of (corrected) congenital malformations of digestive system',
                  children: [
                    {
                      value: 'Z87.730',
                      title:
                        'Z87.730 Personal history of (corrected) cleft lip and palate',
                    },
                    {
                      value: 'Z87.731',
                      title:
                        'Z87.731 Personal history of (corrected) tracheoesophageal fistula or atresia',
                    },
                    {
                      value: 'Z87.732',
                      title:
                        'Z87.732 Personal history of (corrected) persistent cloaca or cloacal malformations',
                    },
                    {
                      value: 'Z87.738',
                      title:
                        'Z87.738 Personal history of other specified (corrected) congenital malformations of digestive system',
                    },
                  ],
                },
                {
                  value: 'Z87.74',
                  title:
                    'Z87.74 Personal history of (corrected) congenital malformations of heart and circulatory system',
                },
                {
                  value: 'Z87.75',
                  title:
                    'Z87.75 Personal history of (corrected) congenital malformations of respiratory system',
                },
                {
                  value: 'Z87.76',
                  title:
                    'Z87.76 Personal history of (corrected) congenital malformations of integument, limbs and musculoskeletal system',
                  children: [
                    {
                      value: 'Z87.760',
                      title:
                        'Z87.760 Personal history of (corrected) congenital diaphragmatic hernia or other congenital diaphragm malformations',
                    },
                    {
                      value: 'Z87.761',
                      title:
                        'Z87.761 Personal history of (corrected) gastroschisis',
                    },
                    {
                      value: 'Z87.762',
                      title:
                        'Z87.762 Personal history of (corrected) prune belly malformation',
                    },
                    {
                      value: 'Z87.763',
                      title:
                        'Z87.763 Personal history of other (corrected) congenital abdominal wall malformations',
                    },
                    {
                      value: 'Z87.768',
                      title:
                        'Z87.768 Personal history of other specified (corrected) congenital malformations of integument, limbs and musculoskeletal system',
                    },
                  ],
                },
                {
                  value: 'Z87.79',
                  title:
                    'Z87.79 Personal history of other (corrected) congenital malformations',
                  children: [
                    {
                      value: 'Z87.790',
                      title:
                        'Z87.790 Personal history of (corrected) congenital malformations of face and neck',
                    },
                    {
                      value: 'Z87.798',
                      title:
                        'Z87.798 Personal history of other (corrected) congenital malformations',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z87.8',
              title: 'Z87.8 Personal history of other specified conditions',
              children: [
                {
                  value: 'Z87.81',
                  title:
                    'Z87.81 Personal history of (healed) traumatic fracture',
                },
                {
                  value: 'Z87.82',
                  title:
                    'Z87.82 Personal history of other (healed) physical injury and trauma',
                  children: [
                    {
                      value: 'Z87.820',
                      title:
                        'Z87.820 Personal history of traumatic brain injury',
                    },
                    {
                      value: 'Z87.821',
                      title:
                        'Z87.821 Personal history of retained foreign body fully removed',
                    },
                    {
                      value: 'Z87.828',
                      title:
                        'Z87.828 Personal history of other (healed) physical injury and trauma',
                    },
                  ],
                },
                {
                  value: 'Z87.89',
                  title:
                    'Z87.89 Personal history of other specified conditions',
                  children: [
                    {
                      value: 'Z87.890',
                      title: 'Z87.890 Personal history of sex reassignment',
                    },
                    {
                      value: 'Z87.891',
                      title: 'Z87.891 Personal history of nicotine dependence',
                    },
                    {
                      value: 'Z87.892',
                      title: 'Z87.892 Personal history of anaphylaxis',
                    },
                    {
                      value: 'Z87.898',
                      title:
                        'Z87.898 Personal history of other specified conditions',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          value: 'Z88',
          title:
            'Z88 Allergy status to drugs, medicaments and biological substances',
          children: [
            {
              value: 'Z88.0',
              title: 'Z88.0 Allergy status to penicillin',
            },
            {
              value: 'Z88.1',
              title: 'Z88.1 Allergy status to other antibiotic agents',
            },
            {
              value: 'Z88.2',
              title: 'Z88.2 Allergy status to sulfonamides',
            },
            {
              value: 'Z88.3',
              title: 'Z88.3 Allergy status to other anti-infective agents',
            },
            {
              value: 'Z88.4',
              title: 'Z88.4 Allergy status to anesthetic agent',
            },
            {
              value: 'Z88.5',
              title: 'Z88.5 Allergy status to narcotic agent',
            },
            {
              value: 'Z88.6',
              title: 'Z88.6 Allergy status to analgesic agent',
            },
            {
              value: 'Z88.7',
              title: 'Z88.7 Allergy status to serum and vaccine',
            },
            {
              value: 'Z88.8',
              title:
                'Z88.8 Allergy status to other drugs, medicaments and biological substances',
            },
            {
              value: 'Z88.9',
              title:
                'Z88.9 Allergy status to unspecified drugs, medicaments and biological substances',
            },
          ],
        },
        {
          value: 'Z89',
          title: 'Z89 Acquired absence of limb',
          children: [
            {
              value: 'Z89.0',
              title: 'Z89.0 Acquired absence of thumb and other finger(s)',
              children: [
                {
                  value: 'Z89.01',
                  title: 'Z89.01 Acquired absence of thumb',
                  children: [
                    {
                      value: 'Z89.011',
                      title: 'Z89.011 Acquired absence of right thumb',
                    },
                    {
                      value: 'Z89.012',
                      title: 'Z89.012 Acquired absence of left thumb',
                    },
                    {
                      value: 'Z89.019',
                      title: 'Z89.019 Acquired absence of unspecified thumb',
                    },
                  ],
                },
                {
                  value: 'Z89.02',
                  title: 'Z89.02 Acquired absence of other finger(s)',
                  children: [
                    {
                      value: 'Z89.021',
                      title: 'Z89.021 Acquired absence of right finger(s)',
                    },
                    {
                      value: 'Z89.022',
                      title: 'Z89.022 Acquired absence of left finger(s)',
                    },
                    {
                      value: 'Z89.029',
                      title:
                        'Z89.029 Acquired absence of unspecified finger(s)',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.1',
              title: 'Z89.1 Acquired absence of hand and wrist',
              children: [
                {
                  value: 'Z89.11',
                  title: 'Z89.11 Acquired absence of hand',
                  children: [
                    {
                      value: 'Z89.111',
                      title: 'Z89.111 Acquired absence of right hand',
                    },
                    {
                      value: 'Z89.112',
                      title: 'Z89.112 Acquired absence of left hand',
                    },
                    {
                      value: 'Z89.119',
                      title: 'Z89.119 Acquired absence of unspecified hand',
                    },
                  ],
                },
                {
                  value: 'Z89.12',
                  title: 'Z89.12 Acquired absence of wrist',
                  children: [
                    {
                      value: 'Z89.121',
                      title: 'Z89.121 Acquired absence of right wrist',
                    },
                    {
                      value: 'Z89.122',
                      title: 'Z89.122 Acquired absence of left wrist',
                    },
                    {
                      value: 'Z89.129',
                      title: 'Z89.129 Acquired absence of unspecified wrist',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.2',
              title: 'Z89.2 Acquired absence of upper limb above wrist',
              children: [
                {
                  value: 'Z89.20',
                  title:
                    'Z89.20 Acquired absence of upper limb, unspecified level',
                  children: [
                    {
                      value: 'Z89.201',
                      title:
                        'Z89.201 Acquired absence of right upper limb, unspecified level',
                    },
                    {
                      value: 'Z89.202',
                      title:
                        'Z89.202 Acquired absence of left upper limb, unspecified level',
                    },
                    {
                      value: 'Z89.209',
                      title:
                        'Z89.209 Acquired absence of unspecified upper limb, unspecified level',
                    },
                  ],
                },
                {
                  value: 'Z89.21',
                  title: 'Z89.21 Acquired absence of upper limb below elbow',
                  children: [
                    {
                      value: 'Z89.211',
                      title:
                        'Z89.211 Acquired absence of right upper limb below elbow',
                    },
                    {
                      value: 'Z89.212',
                      title:
                        'Z89.212 Acquired absence of left upper limb below elbow',
                    },
                    {
                      value: 'Z89.219',
                      title:
                        'Z89.219 Acquired absence of unspecified upper limb below elbow',
                    },
                  ],
                },
                {
                  value: 'Z89.22',
                  title: 'Z89.22 Acquired absence of upper limb above elbow',
                  children: [
                    {
                      value: 'Z89.221',
                      title:
                        'Z89.221 Acquired absence of right upper limb above elbow',
                    },
                    {
                      value: 'Z89.222',
                      title:
                        'Z89.222 Acquired absence of left upper limb above elbow',
                    },
                    {
                      value: 'Z89.229',
                      title:
                        'Z89.229 Acquired absence of unspecified upper limb above elbow',
                    },
                  ],
                },
                {
                  value: 'Z89.23',
                  title: 'Z89.23 Acquired absence of shoulder',
                  children: [
                    {
                      value: 'Z89.231',
                      title: 'Z89.231 Acquired absence of right shoulder',
                    },
                    {
                      value: 'Z89.232',
                      title: 'Z89.232 Acquired absence of left shoulder',
                    },
                    {
                      value: 'Z89.239',
                      title: 'Z89.239 Acquired absence of unspecified shoulder',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.4',
              title: 'Z89.4 Acquired absence of toe(s), foot, and ankle',
              children: [
                {
                  value: 'Z89.41',
                  title: 'Z89.41 Acquired absence of great toe',
                  children: [
                    {
                      value: 'Z89.411',
                      title: 'Z89.411 Acquired absence of right great toe',
                    },
                    {
                      value: 'Z89.412',
                      title: 'Z89.412 Acquired absence of left great toe',
                    },
                    {
                      value: 'Z89.419',
                      title:
                        'Z89.419 Acquired absence of unspecified great toe',
                    },
                  ],
                },
                {
                  value: 'Z89.42',
                  title: 'Z89.42 Acquired absence of other toe(s)',
                  children: [
                    {
                      value: 'Z89.421',
                      title: 'Z89.421 Acquired absence of other right toe(s)',
                    },
                    {
                      value: 'Z89.422',
                      title: 'Z89.422 Acquired absence of other left toe(s)',
                    },
                    {
                      value: 'Z89.429',
                      title:
                        'Z89.429 Acquired absence of other toe(s), unspecified side',
                    },
                  ],
                },
                {
                  value: 'Z89.43',
                  title: 'Z89.43 Acquired absence of foot',
                  children: [
                    {
                      value: 'Z89.431',
                      title: 'Z89.431 Acquired absence of right foot',
                    },
                    {
                      value: 'Z89.432',
                      title: 'Z89.432 Acquired absence of left foot',
                    },
                    {
                      value: 'Z89.439',
                      title: 'Z89.439 Acquired absence of unspecified foot',
                    },
                  ],
                },
                {
                  value: 'Z89.44',
                  title: 'Z89.44 Acquired absence of ankle',
                  children: [
                    {
                      value: 'Z89.441',
                      title: 'Z89.441 Acquired absence of right ankle',
                    },
                    {
                      value: 'Z89.442',
                      title: 'Z89.442 Acquired absence of left ankle',
                    },
                    {
                      value: 'Z89.449',
                      title: 'Z89.449 Acquired absence of unspecified ankle',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.5',
              title: 'Z89.5 Acquired absence of leg below knee',
              children: [
                {
                  value: 'Z89.51',
                  title: 'Z89.51 Acquired absence of leg below knee',
                  children: [
                    {
                      value: 'Z89.511',
                      title: 'Z89.511 Acquired absence of right leg below knee',
                    },
                    {
                      value: 'Z89.512',
                      title: 'Z89.512 Acquired absence of left leg below knee',
                    },
                    {
                      value: 'Z89.519',
                      title:
                        'Z89.519 Acquired absence of unspecified leg below knee',
                    },
                  ],
                },
                {
                  value: 'Z89.52',
                  title: 'Z89.52 Acquired absence of knee',
                  children: [
                    {
                      value: 'Z89.521',
                      title: 'Z89.521 Acquired absence of right knee',
                    },
                    {
                      value: 'Z89.522',
                      title: 'Z89.522 Acquired absence of left knee',
                    },
                    {
                      value: 'Z89.529',
                      title: 'Z89.529 Acquired absence of unspecified knee',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.6',
              title: 'Z89.6 Acquired absence of leg above knee',
              children: [
                {
                  value: 'Z89.61',
                  title: 'Z89.61 Acquired absence of leg above knee',
                  children: [
                    {
                      value: 'Z89.611',
                      title: 'Z89.611 Acquired absence of right leg above knee',
                    },
                    {
                      value: 'Z89.612',
                      title: 'Z89.612 Acquired absence of left leg above knee',
                    },
                    {
                      value: 'Z89.619',
                      title:
                        'Z89.619 Acquired absence of unspecified leg above knee',
                    },
                  ],
                },
                {
                  value: 'Z89.62',
                  title: 'Z89.62 Acquired absence of hip',
                  children: [
                    {
                      value: 'Z89.621',
                      title: 'Z89.621 Acquired absence of right hip joint',
                    },
                    {
                      value: 'Z89.622',
                      title: 'Z89.622 Acquired absence of left hip joint',
                    },
                    {
                      value: 'Z89.629',
                      title:
                        'Z89.629 Acquired absence of unspecified hip joint',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z89.9',
              title: 'Z89.9 Acquired absence of limb, unspecified',
            },
          ],
        },
        {
          value: 'Z90',
          title: 'Z90 Acquired absence of organs, not elsewhere classified',
          children: [
            {
              value: 'Z90.0',
              title: 'Z90.0 Acquired absence of part of head and neck',
              children: [
                {
                  value: 'Z90.01',
                  title: 'Z90.01 Acquired absence of eye',
                },
                {
                  value: 'Z90.02',
                  title: 'Z90.02 Acquired absence of larynx',
                },
                {
                  value: 'Z90.09',
                  title:
                    'Z90.09 Acquired absence of other part of head and neck',
                },
              ],
            },
            {
              value: 'Z90.1',
              title: 'Z90.1 Acquired absence of breast and nipple',
              children: [
                {
                  value: 'Z90.10',
                  title:
                    'Z90.10 Acquired absence of unspecified breast and nipple',
                },
                {
                  value: 'Z90.11',
                  title: 'Z90.11 Acquired absence of right breast and nipple',
                },
                {
                  value: 'Z90.12',
                  title: 'Z90.12 Acquired absence of left breast and nipple',
                },
                {
                  value: 'Z90.13',
                  title:
                    'Z90.13 Acquired absence of bilateral breasts and nipples',
                },
              ],
            },
            {
              value: 'Z90.2',
              title: 'Z90.2 Acquired absence of lung [part of]',
            },
            {
              value: 'Z90.3',
              title: 'Z90.3 Acquired absence of stomach [part of]',
            },
            {
              value: 'Z90.4',
              title:
                'Z90.4 Acquired absence of other specified parts of digestive tract',
              children: [
                {
                  value: 'Z90.41',
                  title: 'Z90.41 Acquired absence of pancreas',
                  children: [
                    {
                      value: 'Z90.410',
                      title: 'Z90.410 Acquired total absence of pancreas',
                    },
                    {
                      value: 'Z90.411',
                      title: 'Z90.411 Acquired partial absence of pancreas',
                    },
                  ],
                },
                {
                  value: 'Z90.49',
                  title:
                    'Z90.49 Acquired absence of other specified parts of digestive tract',
                },
              ],
            },
            {
              value: 'Z90.5',
              title: 'Z90.5 Acquired absence of kidney',
            },
            {
              value: 'Z90.6',
              title: 'Z90.6 Acquired absence of other parts of urinary tract',
            },
            {
              value: 'Z90.7',
              title: 'Z90.7 Acquired absence of genital organ(s)',
              children: [
                {
                  value: 'Z90.71',
                  title: 'Z90.71 Acquired absence of cervix and uterus',
                  children: [
                    {
                      value: 'Z90.710',
                      title:
                        'Z90.710 Acquired absence of both cervix and uterus',
                    },
                    {
                      value: 'Z90.711',
                      title:
                        'Z90.711 Acquired absence of uterus with remaining cervical stump',
                    },
                    {
                      value: 'Z90.712',
                      title:
                        'Z90.712 Acquired absence of cervix with remaining uterus',
                    },
                  ],
                },
                {
                  value: 'Z90.72',
                  title: 'Z90.72 Acquired absence of ovaries',
                  children: [
                    {
                      value: 'Z90.721',
                      title: 'Z90.721 Acquired absence of ovaries, unilateral',
                    },
                    {
                      value: 'Z90.722',
                      title: 'Z90.722 Acquired absence of ovaries, bilateral',
                    },
                  ],
                },
                {
                  value: 'Z90.79',
                  title: 'Z90.79 Acquired absence of other genital organ(s)',
                },
              ],
            },
            {
              value: 'Z90.8',
              title: 'Z90.8 Acquired absence of other organs',
              children: [
                {
                  value: 'Z90.81',
                  title: 'Z90.81 Acquired absence of spleen',
                },
                {
                  value: 'Z90.89',
                  title: 'Z90.89 Acquired absence of other organs',
                },
              ],
            },
          ],
        },
        {
          value: 'Z91',
          title: 'Z91 Personal risk factors, not elsewhere classified',
          children: [
            {
              value: 'Z91.0',
              title:
                'Z91.0 Allergy status, other than to drugs and biological substances',
              children: [
                {
                  value: 'Z91.01',
                  title: 'Z91.01 Food allergy status',
                  children: [
                    {
                      value: 'Z91.010',
                      title: 'Z91.010 Allergy to peanuts',
                    },
                    {
                      value: 'Z91.011',
                      title: 'Z91.011 Allergy to milk products',
                    },
                    {
                      value: 'Z91.012',
                      title: 'Z91.012 Allergy to eggs',
                    },
                    {
                      value: 'Z91.013',
                      title: 'Z91.013 Allergy to seafood',
                    },
                    {
                      value: 'Z91.014',
                      title: 'Z91.014 Allergy to mammalian meats',
                    },
                    {
                      value: 'Z91.018',
                      title: 'Z91.018 Allergy to other foods',
                    },
                  ],
                },
                {
                  value: 'Z91.02',
                  title: 'Z91.02 Food additives allergy status',
                },
                {
                  value: 'Z91.03',
                  title: 'Z91.03 Insect allergy status',
                  children: [
                    {
                      value: 'Z91.030',
                      title: 'Z91.030 Bee allergy status',
                    },
                    {
                      value: 'Z91.038',
                      title: 'Z91.038 Other insect allergy status',
                    },
                  ],
                },
                {
                  value: 'Z91.04',
                  title: 'Z91.04 Nonmedicinal substance allergy status',
                  children: [
                    {
                      value: 'Z91.040',
                      title: 'Z91.040 Latex allergy status',
                    },
                    {
                      value: 'Z91.041',
                      title: 'Z91.041 Radiographic dye allergy status',
                    },
                    {
                      value: 'Z91.048',
                      title:
                        'Z91.048 Other nonmedicinal substance allergy status',
                    },
                  ],
                },
                {
                  value: 'Z91.09',
                  title:
                    'Z91.09 Other allergy status, other than to drugs and biological substances',
                },
              ],
            },
            {
              value: 'Z91.1',
              title:
                "Z91.1 Patient's noncompliance with medical treatment and regimen",
              children: [
                {
                  value: 'Z91.11',
                  title: "Z91.11 Patient's noncompliance with dietary regimen",
                  children: [
                    {
                      value: 'Z91.110',
                      title:
                        "Z91.110 Patient's noncompliance with dietary regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.118',
                      title:
                        "Z91.118 Patient's noncompliance with dietary regimen for other reason",
                    },
                    {
                      value: 'Z91.119',
                      title:
                        "Z91.119 Patient's noncompliance with dietary regimen due to unspecified reason",
                    },
                  ],
                },
                {
                  value: 'Z91.12',
                  title:
                    "Z91.12 Patient's intentional underdosing of medication regimen",
                  children: [
                    {
                      value: 'Z91.120',
                      title:
                        "Z91.120 Patient's intentional underdosing of medication regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.128',
                      title:
                        "Z91.128 Patient's intentional underdosing of medication regimen for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.13',
                  title:
                    "Z91.13 Patient's unintentional underdosing of medication regimen",
                  children: [
                    {
                      value: 'Z91.130',
                      title:
                        "Z91.130 Patient's unintentional underdosing of medication regimen due to age-related debility",
                    },
                    {
                      value: 'Z91.138',
                      title:
                        "Z91.138 Patient's unintentional underdosing of medication regimen for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.14',
                  title:
                    "Z91.14 Patient's other noncompliance with medication regimen",
                  children: [
                    {
                      value: 'Z91.141',
                      title:
                        "Z91.141 Patient's other noncompliance with medication regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.148',
                      title:
                        "Z91.148 Patient's other noncompliance with medication regimen for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.15',
                  title: "Z91.15 Patient's noncompliance with renal dialysis",
                  children: [
                    {
                      value: 'Z91.151',
                      title:
                        "Z91.151 Patient's noncompliance with renal dialysis due to financial hardship",
                    },
                    {
                      value: 'Z91.158',
                      title:
                        "Z91.158 Patient's noncompliance with renal dialysis for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.19',
                  title:
                    "Z91.19 Patient's noncompliance with other medical treatment and regimen",
                  children: [
                    {
                      value: 'Z91.190',
                      title:
                        "Z91.190 Patient's noncompliance with other medical treatment and regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.198',
                      title:
                        "Z91.198 Patient's noncompliance with other medical treatment and regimen for other reason",
                    },
                    {
                      value: 'Z91.199',
                      title:
                        "Z91.199 Patient's noncompliance with other medical treatment and regimen due to unspecified reason",
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z91.A',
              title:
                "Z91.A Caregiver's noncompliance with patient's medical treatment and regimen",
              children: [
                {
                  value: 'Z91.A1',
                  title:
                    "Z91.A1 Caregiver's noncompliance with patient's dietary regimen",
                  children: [
                    {
                      value: 'Z91.A10',
                      title:
                        "Z91.A10 Caregiver's noncompliance with patient's dietary regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.A18',
                      title:
                        "Z91.A18 Caregiver's noncompliance with patient's dietary regimen for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.A2',
                  title:
                    "Z91.A2 Caregiver's intentional underdosing of patient's medication regimen",
                  children: [
                    {
                      value: 'Z91.A20',
                      title:
                        "Z91.A20 Caregiver's intentional underdosing of patient's medication regimen due to financial hardship",
                    },
                    {
                      value: 'Z91.A28',
                      title:
                        "Z91.A28 Caregiver's intentional underdosing of medication regimen for other reason",
                    },
                  ],
                },
                {
                  value: 'Z91.A3',
                  title:
                    "Z91.A3 Caregiver's unintentional underdosing of patient's medication regimen",
                },
                {
                  value: 'Z91.A4',
                  title:
                    "Z91.A4 Caregiver's other noncompliance with patient's medication regimen",
                },
                {
                  value: 'Z91.A5',
                  title:
                    "Z91.A5 Caregiver's noncompliance with patient's renal dialysis",
                },
                {
                  value: 'Z91.A9',
                  title:
                    "Z91.A9 Caregiver's noncompliance with patient's other medical treatment and regimen",
                },
              ],
            },
            {
              value: 'Z91.4',
              title:
                'Z91.4 Personal history of psychological trauma, not elsewhere classified',
              children: [
                {
                  value: 'Z91.41',
                  title: 'Z91.41 Personal history of adult abuse',
                  children: [
                    {
                      value: 'Z91.410',
                      title:
                        'Z91.410 Personal history of adult physical and sexual abuse',
                    },
                    {
                      value: 'Z91.411',
                      title:
                        'Z91.411 Personal history of adult psychological abuse',
                    },
                    {
                      value: 'Z91.412',
                      title: 'Z91.412 Personal history of adult neglect',
                    },
                    {
                      value: 'Z91.413',
                      title:
                        'Z91.413 Personal history of adult financial abuse',
                    },
                    {
                      value: 'Z91.414',
                      title:
                        'Z91.414 Personal history of adult intimate partner abuse',
                    },
                    {
                      value: 'Z91.419',
                      title:
                        'Z91.419 Personal history of unspecified adult abuse',
                    },
                  ],
                },
                {
                  value: 'Z91.42',
                  title:
                    'Z91.42 Personal history of forced labor or sexual exploitation',
                },
                {
                  value: 'Z91.49',
                  title:
                    'Z91.49 Other personal history of psychological trauma, not elsewhere classified',
                },
              ],
            },
            {
              value: 'Z91.5',
              title: 'Z91.5 Personal history of self-harm',
              children: [
                {
                  value: 'Z91.51',
                  title: 'Z91.51 Personal history of suicidal behavior',
                },
                {
                  value: 'Z91.52',
                  title: 'Z91.52 Personal history of nonsuicidal self-harm',
                },
              ],
            },
            {
              value: 'Z91.8',
              title:
                'Z91.8 Other specified personal risk factors, not elsewhere classified',
              children: [
                {
                  value: 'Z91.81',
                  title: 'Z91.81 History of falling',
                },
                {
                  value: 'Z91.82',
                  title: 'Z91.82 Personal history of military deployment',
                },
                {
                  value: 'Z91.83',
                  title: 'Z91.83 Wandering in diseases classified elsewhere',
                },
                {
                  value: 'Z91.84',
                  title: 'Z91.84 Oral health risk factors',
                  children: [
                    {
                      value: 'Z91.841',
                      title: 'Z91.841 Risk for dental caries, low',
                    },
                    {
                      value: 'Z91.842',
                      title: 'Z91.842 Risk for dental caries, moderate',
                    },
                    {
                      value: 'Z91.843',
                      title: 'Z91.843 Risk for dental caries, high',
                    },
                    {
                      value: 'Z91.849',
                      title: 'Z91.849 Unspecified risk for dental caries',
                    },
                  ],
                },
                {
                  value: 'Z91.89',
                  title:
                    'Z91.89 Other specified personal risk factors, not elsewhere classified',
                },
              ],
            },
          ],
        },
        {
          value: 'Z92',
          title: 'Z92 Personal history of medical treatment',
          children: [
            {
              value: 'Z92.0',
              title: 'Z92.0 Personal history of contraception',
            },
            {
              value: 'Z92.2',
              title: 'Z92.2 Personal history of drug therapy',
              children: [
                {
                  value: 'Z92.21',
                  title:
                    'Z92.21 Personal history of antineoplastic chemotherapy',
                },
                {
                  value: 'Z92.22',
                  title: 'Z92.22 Personal history of monoclonal drug therapy',
                },
                {
                  value: 'Z92.23',
                  title: 'Z92.23 Personal history of estrogen therapy',
                },
                {
                  value: 'Z92.24',
                  title: 'Z92.24 Personal history of steroid therapy',
                  children: [
                    {
                      value: 'Z92.240',
                      title:
                        'Z92.240 Personal history of inhaled steroid therapy',
                    },
                    {
                      value: 'Z92.241',
                      title:
                        'Z92.241 Personal history of systemic steroid therapy',
                    },
                  ],
                },
                {
                  value: 'Z92.25',
                  title: 'Z92.25 Personal history of immunosuppression therapy',
                },
                {
                  value: 'Z92.29',
                  title: 'Z92.29 Personal history of other drug therapy',
                },
              ],
            },
            {
              value: 'Z92.3',
              title: 'Z92.3 Personal history of irradiation',
            },
            {
              value: 'Z92.8',
              title: 'Z92.8 Personal history of other medical treatment',
              children: [
                {
                  value: 'Z92.81',
                  title:
                    'Z92.81 Personal history of extracorporeal membrane oxygenation (ECMO)',
                },
                {
                  value: 'Z92.82',
                  title:
                    'Z92.82 Status post administration of tPA (rtPA) in a different facility within the last 24 hours prior to admission to current facility',
                },
                {
                  value: 'Z92.83',
                  title: 'Z92.83 Personal history of failed moderate sedation',
                },
                {
                  value: 'Z92.84',
                  title:
                    'Z92.84 Personal history of unintended awareness under general anesthesia',
                },
                {
                  value: 'Z92.85',
                  title: 'Z92.85 Personal history of cellular therapy',
                  children: [
                    {
                      value: 'Z92.850',
                      title:
                        'Z92.850 Personal history of Chimeric Antigen Receptor T-cell therapy',
                    },
                    {
                      value: 'Z92.858',
                      title:
                        'Z92.858 Personal history of other cellular therapy',
                    },
                    {
                      value: 'Z92.859',
                      title:
                        'Z92.859 Personal history of cellular therapy, unspecified',
                    },
                  ],
                },
                {
                  value: 'Z92.86',
                  title: 'Z92.86 Personal history of gene therapy',
                },
                {
                  value: 'Z92.89',
                  title: 'Z92.89 Personal history of other medical treatment',
                },
              ],
            },
          ],
        },
        {
          value: 'Z93',
          title: 'Z93 Artificial opening status',
          children: [
            {
              value: 'Z93.0',
              title: 'Z93.0 Tracheostomy status',
            },
            {
              value: 'Z93.1',
              title: 'Z93.1 Gastrostomy status',
            },
            {
              value: 'Z93.2',
              title: 'Z93.2 Ileostomy status',
            },
            {
              value: 'Z93.3',
              title: 'Z93.3 Colostomy status',
            },
            {
              value: 'Z93.4',
              title:
                'Z93.4 Other artificial openings of gastrointestinal tract status',
            },
            {
              value: 'Z93.5',
              title: 'Z93.5 Cystostomy status',
              children: [
                {
                  value: 'Z93.50',
                  title: 'Z93.50 Unspecified cystostomy status',
                },
                {
                  value: 'Z93.51',
                  title: 'Z93.51 Cutaneous-vesicostomy status',
                },
                {
                  value: 'Z93.52',
                  title: 'Z93.52 Appendico-vesicostomy status',
                },
                {
                  value: 'Z93.59',
                  title: 'Z93.59 Other cystostomy status',
                },
              ],
            },
            {
              value: 'Z93.6',
              title: 'Z93.6 Other artificial openings of urinary tract status',
            },
            {
              value: 'Z93.8',
              title: 'Z93.8 Other artificial opening status',
            },
            {
              value: 'Z93.9',
              title: 'Z93.9 Artificial opening status, unspecified',
            },
          ],
        },
        {
          value: 'Z94',
          title: 'Z94 Transplanted organ and tissue status',
          children: [
            {
              value: 'Z94.0',
              title: 'Z94.0 Kidney transplant status',
            },
            {
              value: 'Z94.1',
              title: 'Z94.1 Heart transplant status',
            },
            {
              value: 'Z94.2',
              title: 'Z94.2 Lung transplant status',
            },
            {
              value: 'Z94.3',
              title: 'Z94.3 Heart and lungs transplant status',
            },
            {
              value: 'Z94.4',
              title: 'Z94.4 Liver transplant status',
            },
            {
              value: 'Z94.5',
              title: 'Z94.5 Skin transplant status',
            },
            {
              value: 'Z94.6',
              title: 'Z94.6 Bone transplant status',
            },
            {
              value: 'Z94.7',
              title: 'Z94.7 Corneal transplant status',
            },
            {
              value: 'Z94.8',
              title: 'Z94.8 Other transplanted organ and tissue status',
              children: [
                {
                  value: 'Z94.81',
                  title: 'Z94.81 Bone marrow transplant status',
                },
                {
                  value: 'Z94.82',
                  title: 'Z94.82 Intestine transplant status',
                },
                {
                  value: 'Z94.83',
                  title: 'Z94.83 Pancreas transplant status',
                },
                {
                  value: 'Z94.84',
                  title: 'Z94.84 Stem cells transplant status',
                },
                {
                  value: 'Z94.89',
                  title: 'Z94.89 Other transplanted organ and tissue status',
                },
              ],
            },
            {
              value: 'Z94.9',
              title: 'Z94.9 Transplanted organ and tissue status, unspecified',
            },
          ],
        },
        {
          value: 'Z95',
          title: 'Z95 Presence of cardiac and vascular implants and grafts',
          children: [
            {
              value: 'Z95.0',
              title: 'Z95.0 Presence of cardiac pacemaker',
            },
            {
              value: 'Z95.1',
              title: 'Z95.1 Presence of aortocoronary bypass graft',
            },
            {
              value: 'Z95.2',
              title: 'Z95.2 Presence of prosthetic heart valve',
            },
            {
              value: 'Z95.3',
              title: 'Z95.3 Presence of xenogenic heart valve',
            },
            {
              value: 'Z95.4',
              title: 'Z95.4 Presence of other heart-valve replacement',
            },
            {
              value: 'Z95.5',
              title: 'Z95.5 Presence of coronary angioplasty implant and graft',
            },
            {
              value: 'Z95.8',
              title:
                'Z95.8 Presence of other cardiac and vascular implants and grafts',
              children: [
                {
                  value: 'Z95.81',
                  title: 'Z95.81 Presence of other cardiac implants and grafts',
                  children: [
                    {
                      value: 'Z95.810',
                      title:
                        'Z95.810 Presence of automatic (implantable) cardiac defibrillator',
                    },
                    {
                      value: 'Z95.811',
                      title: 'Z95.811 Presence of heart assist device',
                    },
                    {
                      value: 'Z95.812',
                      title:
                        'Z95.812 Presence of fully implantable artificial heart',
                    },
                    {
                      value: 'Z95.818',
                      title:
                        'Z95.818 Presence of other cardiac implants and grafts',
                    },
                  ],
                },
                {
                  value: 'Z95.82',
                  title:
                    'Z95.82 Presence of other vascular implants and grafts',
                  children: [
                    {
                      value: 'Z95.820',
                      title:
                        'Z95.820 Peripheral vascular angioplasty status with implants and grafts',
                    },
                    {
                      value: 'Z95.828',
                      title:
                        'Z95.828 Presence of other vascular implants and grafts',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z95.9',
              title:
                'Z95.9 Presence of cardiac and vascular implant and graft, unspecified',
            },
          ],
        },
        {
          value: 'Z96',
          title: 'Z96 Presence of other functional implants',
          children: [
            {
              value: 'Z96.0',
              title: 'Z96.0 Presence of urogenital implants',
            },
            {
              value: 'Z96.1',
              title: 'Z96.1 Presence of intraocular lens',
            },
            {
              value: 'Z96.2',
              title: 'Z96.2 Presence of otological and audiological implants',
              children: [
                {
                  value: 'Z96.20',
                  title:
                    'Z96.20 Presence of otological and audiological implant, unspecified',
                },
                {
                  value: 'Z96.21',
                  title: 'Z96.21 Cochlear implant status',
                },
                {
                  value: 'Z96.22',
                  title: 'Z96.22 Myringotomy tube(s) status',
                },
                {
                  value: 'Z96.29',
                  title:
                    'Z96.29 Presence of other otological and audiological implants',
                },
              ],
            },
            {
              value: 'Z96.3',
              title: 'Z96.3 Presence of artificial larynx',
            },
            {
              value: 'Z96.4',
              title: 'Z96.4 Presence of endocrine implants',
              children: [
                {
                  value: 'Z96.41',
                  title:
                    'Z96.41 Presence of insulin pump (external) (internal)',
                },
                {
                  value: 'Z96.49',
                  title: 'Z96.49 Presence of other endocrine implants',
                },
              ],
            },
            {
              value: 'Z96.5',
              title: 'Z96.5 Presence of tooth-root and mandibular implants',
            },
            {
              value: 'Z96.6',
              title: 'Z96.6 Presence of orthopedic joint implants',
              children: [
                {
                  value: 'Z96.60',
                  title:
                    'Z96.60 Presence of unspecified orthopedic joint implant',
                },
                {
                  value: 'Z96.61',
                  title: 'Z96.61 Presence of artificial shoulder joint',
                  children: [
                    {
                      value: 'Z96.611',
                      title:
                        'Z96.611 Presence of right artificial shoulder joint',
                    },
                    {
                      value: 'Z96.612',
                      title:
                        'Z96.612 Presence of left artificial shoulder joint',
                    },
                    {
                      value: 'Z96.619',
                      title:
                        'Z96.619 Presence of unspecified artificial shoulder joint',
                    },
                  ],
                },
                {
                  value: 'Z96.62',
                  title: 'Z96.62 Presence of artificial elbow joint',
                  children: [
                    {
                      value: 'Z96.621',
                      title: 'Z96.621 Presence of right artificial elbow joint',
                    },
                    {
                      value: 'Z96.622',
                      title: 'Z96.622 Presence of left artificial elbow joint',
                    },
                    {
                      value: 'Z96.629',
                      title:
                        'Z96.629 Presence of unspecified artificial elbow joint',
                    },
                  ],
                },
                {
                  value: 'Z96.63',
                  title: 'Z96.63 Presence of artificial wrist joint',
                  children: [
                    {
                      value: 'Z96.631',
                      title: 'Z96.631 Presence of right artificial wrist joint',
                    },
                    {
                      value: 'Z96.632',
                      title: 'Z96.632 Presence of left artificial wrist joint',
                    },
                    {
                      value: 'Z96.639',
                      title:
                        'Z96.639 Presence of unspecified artificial wrist joint',
                    },
                  ],
                },
                {
                  value: 'Z96.64',
                  title: 'Z96.64 Presence of artificial hip joint',
                  children: [
                    {
                      value: 'Z96.641',
                      title: 'Z96.641 Presence of right artificial hip joint',
                    },
                    {
                      value: 'Z96.642',
                      title: 'Z96.642 Presence of left artificial hip joint',
                    },
                    {
                      value: 'Z96.643',
                      title:
                        'Z96.643 Presence of artificial hip joint, bilateral',
                    },
                    {
                      value: 'Z96.649',
                      title:
                        'Z96.649 Presence of unspecified artificial hip joint',
                    },
                  ],
                },
                {
                  value: 'Z96.65',
                  title: 'Z96.65 Presence of artificial knee joint',
                  children: [
                    {
                      value: 'Z96.651',
                      title: 'Z96.651 Presence of right artificial knee joint',
                    },
                    {
                      value: 'Z96.652',
                      title: 'Z96.652 Presence of left artificial knee joint',
                    },
                    {
                      value: 'Z96.653',
                      title:
                        'Z96.653 Presence of artificial knee joint, bilateral',
                    },
                    {
                      value: 'Z96.659',
                      title:
                        'Z96.659 Presence of unspecified artificial knee joint',
                    },
                  ],
                },
                {
                  value: 'Z96.66',
                  title: 'Z96.66 Presence of artificial ankle joint',
                  children: [
                    {
                      value: 'Z96.661',
                      title: 'Z96.661 Presence of right artificial ankle joint',
                    },
                    {
                      value: 'Z96.662',
                      title: 'Z96.662 Presence of left artificial ankle joint',
                    },
                    {
                      value: 'Z96.669',
                      title:
                        'Z96.669 Presence of unspecified artificial ankle joint',
                    },
                  ],
                },
                {
                  value: 'Z96.69',
                  title: 'Z96.69 Presence of other orthopedic joint implants',
                  children: [
                    {
                      value: 'Z96.691',
                      title: 'Z96.691 Finger-joint replacement of right hand',
                    },
                    {
                      value: 'Z96.692',
                      title: 'Z96.692 Finger-joint replacement of left hand',
                    },
                    {
                      value: 'Z96.693',
                      title: 'Z96.693 Finger-joint replacement, bilateral',
                    },
                    {
                      value: 'Z96.698',
                      title:
                        'Z96.698 Presence of other orthopedic joint implants',
                    },
                  ],
                },
              ],
            },
            {
              value: 'Z96.7',
              title: 'Z96.7 Presence of other bone and tendon implants',
            },
            {
              value: 'Z96.8',
              title: 'Z96.8 Presence of other specified functional implants',
              children: [
                {
                  value: 'Z96.81',
                  title: 'Z96.81 Presence of artificial skin',
                },
                {
                  value: 'Z96.82',
                  title: 'Z96.82 Presence of neurostimulator',
                },
                {
                  value: 'Z96.89',
                  title:
                    'Z96.89 Presence of other specified functional implants',
                },
              ],
            },
            {
              value: 'Z96.9',
              title: 'Z96.9 Presence of functional implant, unspecified',
            },
          ],
        },
        {
          value: 'Z97',
          title: 'Z97 Presence of other devices',
          children: [
            {
              value: 'Z97.0',
              title: 'Z97.0 Presence of artificial eye',
            },
            {
              value: 'Z97.1',
              title: 'Z97.1 Presence of artificial limb (complete) (partial)',
              children: [
                {
                  value: 'Z97.10',
                  title:
                    'Z97.10 Presence of artificial limb (complete) (partial), unspecified',
                },
                {
                  value: 'Z97.11',
                  title:
                    'Z97.11 Presence of artificial right arm (complete) (partial)',
                },
                {
                  value: 'Z97.12',
                  title:
                    'Z97.12 Presence of artificial left arm (complete) (partial)',
                },
                {
                  value: 'Z97.13',
                  title:
                    'Z97.13 Presence of artificial right leg (complete) (partial)',
                },
                {
                  value: 'Z97.14',
                  title:
                    'Z97.14 Presence of artificial left leg (complete) (partial)',
                },
                {
                  value: 'Z97.15',
                  title:
                    'Z97.15 Presence of artificial arms, bilateral (complete) (partial)',
                },
                {
                  value: 'Z97.16',
                  title:
                    'Z97.16 Presence of artificial legs, bilateral (complete) (partial)',
                },
              ],
            },
            {
              value: 'Z97.2',
              title:
                'Z97.2 Presence of dental prosthetic device (complete) (partial)',
            },
            {
              value: 'Z97.3',
              title: 'Z97.3 Presence of spectacles and contact lenses',
            },
            {
              value: 'Z97.4',
              title: 'Z97.4 Presence of external hearing-aid',
            },
            {
              value: 'Z97.5',
              title: 'Z97.5 Presence of (intrauterine) contraceptive device',
            },
            {
              value: 'Z97.8',
              title: 'Z97.8 Presence of other specified devices',
            },
          ],
        },
        {
          value: 'Z98',
          title: 'Z98 Other postprocedural states',
          children: [
            {
              value: 'Z98.0',
              title: 'Z98.0 Intestinal bypass and anastomosis status',
            },
            {
              value: 'Z98.1',
              title: 'Z98.1 Arthrodesis status',
            },
            {
              value: 'Z98.2',
              title: 'Z98.2 Presence of cerebrospinal fluid drainage device',
            },
            {
              value: 'Z98.3',
              title: 'Z98.3 Post therapeutic collapse of lung status',
            },
            {
              value: 'Z98.4',
              title: 'Z98.4 Cataract extraction status',
              children: [
                {
                  value: 'Z98.41',
                  title: 'Z98.41 Cataract extraction status, right eye',
                },
                {
                  value: 'Z98.42',
                  title: 'Z98.42 Cataract extraction status, left eye',
                },
                {
                  value: 'Z98.49',
                  title: 'Z98.49 Cataract extraction status, unspecified eye',
                },
              ],
            },
            {
              value: 'Z98.5',
              title: 'Z98.5 Sterilization status',
              children: [
                {
                  value: 'Z98.51',
                  title: 'Z98.51 Tubal ligation status',
                },
                {
                  value: 'Z98.52',
                  title: 'Z98.52 Vasectomy status',
                },
              ],
            },
            {
              value: 'Z98.6',
              title: 'Z98.6 Angioplasty status',
              children: [
                {
                  value: 'Z98.61',
                  title: 'Z98.61 Coronary angioplasty status',
                },
                {
                  value: 'Z98.62',
                  title: 'Z98.62 Peripheral vascular angioplasty status',
                },
              ],
            },
            {
              value: 'Z98.8',
              title: 'Z98.8 Other specified postprocedural states',
              children: [
                {
                  value: 'Z98.81',
                  title: 'Z98.81 Dental procedure status',
                  children: [
                    {
                      value: 'Z98.810',
                      title: 'Z98.810 Dental sealant status',
                    },
                    {
                      value: 'Z98.811',
                      title: 'Z98.811 Dental restoration status',
                    },
                    {
                      value: 'Z98.818',
                      title: 'Z98.818 Other dental procedure status',
                    },
                  ],
                },
                {
                  value: 'Z98.82',
                  title: 'Z98.82 Breast implant status',
                },
                {
                  value: 'Z98.83',
                  title:
                    'Z98.83 Filtering (vitreous) bleb after glaucoma surgery status',
                },
                {
                  value: 'Z98.84',
                  title: 'Z98.84 Bariatric surgery status',
                },
                {
                  value: 'Z98.85',
                  title: 'Z98.85 Transplanted organ removal status',
                },
                {
                  value: 'Z98.86',
                  title: 'Z98.86 Personal history of breast implant removal',
                },
                {
                  value: 'Z98.87',
                  title: 'Z98.87 Personal history of in utero procedure',
                  children: [
                    {
                      value: 'Z98.870',
                      title:
                        'Z98.870 Personal history of in utero procedure during pregnancy',
                    },
                    {
                      value: 'Z98.871',
                      title:
                        'Z98.871 Personal history of in utero procedure while a fetus',
                    },
                  ],
                },
                {
                  value: 'Z98.89',
                  title: 'Z98.89 Other specified postprocedural states',
                  children: [
                    {
                      value: 'Z98.890',
                      title: 'Z98.890 Other specified postprocedural states',
                    },
                    {
                      value: 'Z98.891',
                      title:
                        'Z98.891 History of uterine scar from previous surgery',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          value: 'Z99',
          title:
            'Z99 Dependence on enabling machines and devices, not elsewhere classified',
          children: [
            {
              value: 'Z99.0',
              title: 'Z99.0 Dependence on aspirator',
            },
            {
              value: 'Z99.1',
              title: 'Z99.1 Dependence on respirator',
              children: [
                {
                  value: 'Z99.11',
                  title: 'Z99.11 Dependence on respirator [ventilator] status',
                },
                {
                  value: 'Z99.12',
                  title:
                    'Z99.12 Encounter for respirator [ventilator] dependence during power failure',
                },
              ],
            },
            {
              value: 'Z99.2',
              title: 'Z99.2 Dependence on renal dialysis',
            },
            {
              value: 'Z99.3',
              title: 'Z99.3 Dependence on wheelchair',
            },
            {
              value: 'Z99.8',
              title: 'Z99.8 Dependence on other enabling machines and devices',
              children: [
                {
                  value: 'Z99.81',
                  title: 'Z99.81 Dependence on supplemental oxygen',
                },
                {
                  value: 'Z99.89',
                  title:
                    'Z99.89 Dependence on other enabling machines and devices',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'U00-U49',
      title:
        'U00-U49 Provisional assignment of new diseases of uncertain etiology or emergency use',
      children: [
        {
          value: 'U07',
          title: 'U07 Emergency use of U07',
          children: [
            {
              value: 'U07.0',
              title: 'U07.0 Vaping-related disorder',
            },
            {
              value: 'U07.1',
              title: 'U07.1 COVID-19',
            },
          ],
        },
        {
          value: 'U09',
          title: 'U09 Post COVID-19 condition',
          children: [
            {
              value: 'U09.9',
              title: 'U09.9 Post COVID-19 condition, unspecified',
            },
          ],
        },
      ],
    },
  ],
};
