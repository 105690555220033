import { Rule, RuleObject, RuleRender } from 'antd/lib/form';

// Note: for some reason the Rule from rc-form that is bundled with Ant.d is not working properly.
export const getYupSyncValidator = (t: any, schema: any): RuleRender => {
  return ({ getFieldsValue, ...rest }) => {
    return {
      validator(rule: Rule, value: any, callback: any) {
        try {
          // @ts-ignore
          const { field } = rule;
          schema.validateSyncAt(field, { [field]: value });
          callback();
        } catch (err) {
          // @ts-ignore
          if (err?.message?.key) {
            // @ts-ignore
            const res = t(err?.message?.key, err?.message?.values);
            callback(res);
            return;
          }

          // @ts-ignore
          const res = t(err?.message);
          callback(res);
        }
      },
    };
  };
};
