import {
  portalEn,
  STRIPE_PRODUCT_META_NAMES,
  getSaasAgreementEn,
  getSaasAgreementRu,
  privacyPolicyEn,
  privacyPolicyRu,
  termsEn,
  termsRu,
} from '@cc/schema';
import {
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
  useNotification,
  useTranslate,
} from '@pankod/refine-core';
import {
  Button,
  Card,
  Col,
  Empty,
  InputNumber,
  List,
  Modal,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
} from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Practice,
  StripePortalConfigurationData,
} from '../../schema/be-graphql-generated';
import { colors } from '../../styles/colors';
import * as Sentry from '@sentry/react';
import './styles.less';
import { IUser } from '../../providers';
import Title from 'antd/lib/typography/Title';
import {
  BorderOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import routerProvider from '@pankod/refine-react-router-v6';
import qs from 'qs';
import { useModal } from '@pankod/refine-antd';
import { useWindowSize } from 'rooks';
import ReactMarkdown from 'react-markdown';

import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { format } from 'date-fns';
import { useSubscriptionWarnings } from '../../hooks';
import { ClientsLimitReached } from '../../components/ClientsLimitReached';
import { SubscriptionExpiringSoon } from '../../components/SubscriptionExpiringSoon';
import { SubscriptionExpired } from '../../components/SubscriptionExpired';

const DEFAULT_TIER = 0;
const DEFAULT_CUSTOM_NUMBER = 300;

export const PortalSubscriptionList = () => {
  const translate = useTranslate();
  const { i18n } = useTranslation();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const {
    currentProductId,
    currentPriceId,
    quantity: currentQuantity,
  } = useMemo(() => {
    return identity?.db?.practice?.subscription || {};
  }, [JSON.stringify(identity?.db?.practice?.subscription)]);

  const [selectedCard, setSelectedCard] = useState(currentProductId ? 1 : 0);

  useEffect(() => {
    if (currentProductId) {
      setSelectedCard(1);
    }
  }, [currentProductId]);

  // 1 for monthly, 6 for half-yearly, 12 for yearly
  const [paymentPeriod, setPaymentPeriod] = useState(1);

  const { modalProps, show, close } = useModal();

  const [docToShow, setDocToShow] = useState<'privacy' | 'terms' | 'saas'>();

  const { innerWidth, innerHeight } = useWindowSize();

  const privacyPolicy = i18n.languages[0]?.startsWith('ru')
    ? privacyPolicyRu
    : privacyPolicyEn;

  const terms = i18n.languages[0]?.startsWith('ru') ? termsRu : termsEn;

  const {
    data: practiceData,
    isLoading: practiceLoading,
    refetch: refetchPractice,
    error: practiceError,
  } = useCustom<Practice>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'practice',
      fields: [
        'practiceName',
        {
          practiceLocations: [
            {
              data: [
                'practiceAddress1',
                'practiceAddress2',
                'practiceCity',
                'practiceState',
                'practiceZip',
                'practiceCountry',
              ],
            },
          ],
        },
      ],
    },
  });

  const saasOpts = {
    date: format(new Date(), 'dd MMMM, yyyy'),
    name: practiceData?.data?.practiceName || '',
    address: [
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceAddress1 || '',
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceAddress2 || '',
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceCity || '',
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceState || '',
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceZip || '',
      practiceData?.data?.practiceLocations?.data?.[0]?.practiceCountry || '',
    ]
      .filter((a) => !!a)
      .join(', '),
  };
  const saas = i18n.languages[0]?.startsWith('ru')
    ? getSaasAgreementRu(saasOpts)
    : getSaasAgreementEn(saasOpts);

  const { modalTitle, modalDoc } = useMemo(
    () =>
      docToShow === 'privacy'
        ? { modalDoc: privacyPolicy, modalTitle: translate('screens.privacy') }
        : docToShow === 'terms'
        ? { modalDoc: terms, modalTitle: translate('screens.terms') }
        : { modalDoc: saas, modalTitle: translate('screens.saas') },
    [docToShow, privacyPolicy, terms],
  );

  const {
    data: portalConfigData,
    isLoading: portalConfigLoading,
    refetch: refetchPortalConfig,
    error: portalConfigError,
  } = useCustom<StripePortalConfigurationData>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'stripePortalConfigurationProductsAndPrices',
      fields: [
        {
          products: [
            {
              product: [
                'id',
                'name',
                'description',
                { metadata: ['locale', 'tier', 'metaName'] },
              ],
            },
            {
              prices: [
                'id',
                'currency',
                'unitAmount',
                'interval',
                'intervalCount',
                'billingScheme',
                'lookupKey',
                { metadata: ['locale', 'tier'] },
                {
                  tiers: ['unitAmount', 'flatAmount', 'upTo'],
                },
              ],
            },
          ],
        },
      ],
      variables: {
        locale: i18n.languages[0],
      },
    },
  });

  const handlePaymentPeriodChange = (value: SegmentedValue) => {
    const valueToSet = value === 0 ? 1 : value === 2 ? 12 : 6;
    setPaymentPeriod(Number(valueToSet));
  };

  const [selectedSupportOption, setSelectedSupportOption] = useState(0);

  useEffect(() => {
    if (!portalConfigData?.data?.products?.length) {
      return;
    }

    const { interval, intervalCount, tiers } =
      (portalConfigData?.data?.products || [])
        .find((p) => p?.prices?.find((price) => price?.id === currentPriceId))
        ?.prices?.find((price) => price?.id === currentPriceId) || {};

    if (interval) {
      switch (interval) {
        case 'month':
          setPaymentPeriod(intervalCount || 1);
          break;
        case 'year':
          setPaymentPeriod(12);
      }
    }

    const productMeta = (portalConfigData?.data?.products || []).find(
      (p) => p?.product?.id === currentProductId,
    )?.product?.metadata?.metaName;

    if (productMeta) {
      switch (productMeta) {
        case STRIPE_PRODUCT_META_NAMES.client_mobile_apps:
          setSelectedSupportOption(0);
          break;
        case STRIPE_PRODUCT_META_NAMES.phone_support:
          setSelectedSupportOption(1);
          break;
        case STRIPE_PRODUCT_META_NAMES.priority_support:
          setSelectedSupportOption(2);
          break;
      }
    }

    if (currentQuantity) {
      const closestTier = tiers?.find((tier) => {
        if (tier?.upTo === null) {
          return true;
        }

        if (currentQuantity <= (tier?.upTo || 0)) {
          return true;
        }
      })?.upTo;

      setSelectedTier(closestTier || 201);

      if (!closestTier && currentQuantity > 200) {
        setCustomClientsNumber(currentQuantity);
      }

      if (selectedCard) {
        setSelectedCard(1);
      }
    }
  }, [portalConfigData, currentPriceId, currentProductId, currentQuantity]);

  const periodTiers = useMemo(() => {
    if (!portalConfigData?.data?.products?.length) {
      return [];
    }

    const metaName =
      selectedSupportOption === 0
        ? STRIPE_PRODUCT_META_NAMES.client_mobile_apps
        : selectedSupportOption === 1
        ? STRIPE_PRODUCT_META_NAMES.phone_support
        : STRIPE_PRODUCT_META_NAMES.priority_support;

    const appsProduct = portalConfigData?.data?.products?.find(
      (product) => product?.product?.metadata?.metaName === metaName,
    );

    const periodPrice = appsProduct?.prices?.find((price) => {
      if (price?.interval === 'year' && paymentPeriod === 12) {
        return true;
      }

      if (
        price?.interval === 'month' &&
        price?.intervalCount === paymentPeriod
      ) {
        return true;
      }
    });

    const periodTiersUpdate = (periodPrice?.tiers || []).map((tier) => ({
      count: tier?.upTo === null ? 201 : tier?.upTo,
      flat: (tier?.flatAmount || 0) / 100,
      unit: (tier?.unitAmount || 0) / 100,
      label: `${tier?.upTo === null ? '200+' : tier?.upTo} ${translate(
        'portalSubscriptions.clients',
      )}`,
    }));

    return periodTiersUpdate;
  }, [
    portalConfigData,
    paymentPeriod,
    i18n.languages[0],
    selectedSupportOption,
  ]);

  const [selectedTier, setSelectedTier] = useState(
    periodTiers[DEFAULT_TIER]?.count || 10,
  );

  const [customClientsNumber, setCustomClientsNumber] = useState(
    DEFAULT_CUSTOM_NUMBER,
  );

  const period = useMemo(() => {
    if (paymentPeriod === 1) {
      return translate('portalSubscriptions.month');
    }
    if (paymentPeriod === 6) {
      return translate('portalSubscriptions.halfYear');
    }

    return translate('portalSubscriptions.year');
  }, [paymentPeriod, i18n.languages[0]]);

  const supportOptions = useMemo(() => {
    return translate(`portalSubscriptions.paidSupportOptions`, {
      returnObjects: true,
    }) as unknown as string[];
  }, [i18n.languages[0]]);

  const price = useMemo(() => {
    const selectedTierObj = periodTiers.find(
      (tier) => tier?.count === selectedTier,
    );

    if (selectedTierObj) {
      return (
        Math.round(
          (selectedTierObj.flat + selectedTierObj.unit * customClientsNumber) *
            100,
        ) / 100
      );
    }

    return 0;
  }, [selectedTier, periodTiers, customClientsNumber]);

  const lineItems = useMemo(() => {
    if (selectedCard === 0) {
      return [];
    }

    const metaName =
      selectedSupportOption === 0
        ? STRIPE_PRODUCT_META_NAMES.client_mobile_apps
        : selectedSupportOption === 1
        ? STRIPE_PRODUCT_META_NAMES.phone_support
        : STRIPE_PRODUCT_META_NAMES.priority_support;

    const appsProduct = portalConfigData?.data?.products?.find(
      (product) => product?.product?.metadata?.metaName === metaName,
    );

    const appsPeriodPrice = appsProduct?.prices?.find((price) => {
      if (price?.interval === 'year' && paymentPeriod === 12) {
        return true;
      }

      if (
        price?.interval === 'month' &&
        price?.intervalCount === paymentPeriod
      ) {
        return true;
      }
    });

    const quantity = selectedTier === 201 ? customClientsNumber : selectedTier;

    const lineItems = [
      {
        price: appsPeriodPrice?.id,
        quantity,
      },
    ];

    return lineItems;
  }, [
    selectedCard,
    selectedTier,
    customClientsNumber,
    selectedSupportOption,
    paymentPeriod,
    portalConfigData?.data?.products,
  ]);

  const {
    mutate: createStripeSession,
    data: createStripeSessionData,
    isLoading: createStripeSessionLoading,
    error: createStripeSessionError,
  } = useCustomMutation<{ url?: string | null }>();

  const {
    mutate: createStripePortalSession,
    data: createStripePortalSessionData,
    isLoading: createStripePortalSessionLoading,
    error: createStripePortalSessionError,
  } = useCustomMutation<{ url?: string | null }>();

  const location = routerProvider.useLocation();

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  const { replace } = useNavigation();

  const { open: openNotification } = useNotification();

  const subWarnings = useSubscriptionWarnings();

  useEffect(() => {
    if (parsedSearch?.portalReturn) {
      replace('/portalSubscriptions');
    }
    if (parsedSearch?.success === 'false') {
      openNotification?.({
        type: 'error',
        message: '',
        description: translate('portalSubscriptions.paymentFailed'),
        key: 'payment-success',
      });
      replace('/portalSubscriptions');
    }
    if (parsedSearch?.success === 'true') {
      openNotification?.({
        type: 'success',
        message: '',
        description: translate('portalSubscriptions.paymentSuccess'),
        key: 'payment-success',
      });
      replace('/portalSubscriptions');
    }
    const handle = setTimeout(async () => {
      await refetchIdentity();
    }, 3000);

    return () => clearTimeout(handle);
  }, [parsedSearch]);

  const goToStripeCheckout = async () => {
    try {
      const res = await createStripeSession({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'createStripeSession',
          fields: ['url'],
          variables: {
            input: {
              name: 'input',
              type: 'StripeSessionCreateInput',
              value: {
                data: {
                  lineItems,
                  locale: i18n.languages[0],
                },
              },
            },
          },
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const goToStripePortal = async () => {
    try {
      const res = await createStripePortalSession({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'createStripePortalSession',
          fields: ['url'],
          variables: {
            input: {
              name: 'input',
              type: 'StripePortalSessionCreateInput',
              value: {
                data: {
                  locale: i18n.languages[0],
                },
              },
            },
          },
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (createStripeSessionData?.data.url) {
      window.location.href = createStripeSessionData?.data.url;
    }
  }, [createStripeSessionData]);

  useEffect(() => {
    if (createStripePortalSessionData?.data.url) {
      window.location.href = createStripePortalSessionData?.data.url;
    }
  }, [createStripePortalSessionData]);

  return portalConfigLoading ? (
    <Spin size="large" />
  ) : !portalConfigData?.data ? (
    <Empty />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Title className="title">
        {translate(`portalSubscriptions.headline`)}
      </Title>
      <Title
        className="title"
        level={4}
        style={{ marginTop: -15, marginBottom: 30 }}
      >
        {translate(`portalSubscriptions.orderForm`)}
      </Title>
      <Row gutter={24} style={{ marginBottom: 15 }}>
        <Col>
          <ClientsLimitReached hideUpgradeButton />
        </Col>
        <Col>
          <SubscriptionExpiringSoon hideUpgradeButton />
        </Col>
        <Col>
          <SubscriptionExpired hideUpgradeButton />
        </Col>
      </Row>
      <Segmented
        onChange={handlePaymentPeriodChange}
        options={portalEn.portalSubscriptions.subscriptionOptions.map(
          (i: string, idx: number) => ({
            label: translate(`portalSubscriptions.subscriptionOptions`, {
              returnObjects: true,
            })[idx],
            value: idx,
          }),
        )}
        size="large"
        value={paymentPeriod === 1 ? 0 : paymentPeriod === 6 ? 1 : 2}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          margin: 10,
          marginBottom: 20,
        }}
      >
        <div style={{ width: 400, margin: 20 }}>
          <Card
            hoverable
            title={
              <div className="card-title">
                {selectedCard === 0 ? (
                  <CheckSquareOutlined className="selected-plan-checkbox" />
                ) : (
                  <BorderOutlined className="unselected-plan-checkbox" />
                )}
                {translate('portalSubscriptions.free')}
              </div>
            }
            actions={[
              <div className={selectedCard === 0 ? 'action-row' : ''}>
                <Statistic
                  prefix="$"
                  value={0}
                  suffix={`/ ${period}`}
                  precision={2}
                  valueStyle={{
                    color:
                      selectedCard === 0
                        ? colors['--triad-blue-0']
                        : colors['--triad-blue-1'],
                  }}
                />
              </div>,
            ]}
            bodyStyle={{ height: 350 }}
            className="price-card"
            style={
              selectedCard === 0
                ? {
                    border: `2px solid ${colors['--triad-blue-0']}`,
                    boxShadow: `5px 5px 5px ${colors['--triad-blue-0']}`,
                  }
                : {}
            }
            onClick={() => setSelectedCard(0)}
          >
            <List
              size="small"
              dataSource={
                translate(`portalSubscriptions.freeFeatures`, {
                  returnObjects: true,
                }) as unknown as string[]
              }
              split={false}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item}
                    avatar={<CheckCircleOutlined className="check-icon" />}
                  />
                </List.Item>
              )}
            />
          </Card>
        </div>
        <div style={{ width: 400, margin: 20 }}>
          <Card
            hoverable
            className="price-card"
            title={
              <div className="card-title">
                {selectedCard === 1 ? (
                  <CheckSquareOutlined className="selected-plan-checkbox" />
                ) : (
                  <BorderOutlined className="unselected-plan-checkbox" />
                )}
                {translate(`portalSubscriptions.premium`)}
                <Select
                  style={{
                    width: 160,
                    marginLeft: 70,
                  }}
                  defaultValue={periodTiers[DEFAULT_TIER]?.count}
                  value={selectedTier}
                  options={periodTiers.map((tier) => ({
                    label: tier.label,
                    value: tier?.count,
                  }))}
                  onChange={(value) => {
                    setSelectedTier(value);
                    return value;
                  }}
                />
              </div>
            }
            actions={[
              <div className={selectedCard === 1 ? 'action-row' : ''}>
                <Statistic
                  prefix="$"
                  value={price}
                  suffix={`/ ${period}`}
                  precision={2}
                  valueStyle={{
                    color:
                      selectedCard === 1
                        ? colors['--triad-blue-0']
                        : colors['--triad-blue-1'],
                  }}
                />
              </div>,
            ]}
            bodyStyle={{ height: 350 }}
            onClick={() => setSelectedCard(1)}
            style={
              selectedCard === 1
                ? {
                    border: `2px solid ${colors['--triad-blue-0']}`,
                    boxShadow: `5px 5px 5px ${colors['--triad-blue-0']}`,
                  }
                : {}
            }
          >
            {selectedTier === 201 && (
              <div className="clients-number">
                <div>{translate('portalSubscriptions.customNumber')}</div>
                <InputNumber
                  className="clients-number-input"
                  min={250}
                  defaultValue={customClientsNumber}
                  value={customClientsNumber}
                  step={100}
                  onChange={(v) => {
                    setCustomClientsNumber(v || DEFAULT_CUSTOM_NUMBER);
                  }}
                />
              </div>
            )}

            <List
              size="small"
              dataSource={
                translate(`portalSubscriptions.paidFeatures`, {
                  returnObjects: true,
                  clients:
                    selectedTier === 201 ? customClientsNumber : selectedTier,
                }) as unknown as string[]
              }
              split={false}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item}
                    avatar={<CheckCircleOutlined className="check-icon" />}
                  />
                </List.Item>
              )}
            />
            <Select
              style={{
                width: '100%',
                fontSize: i18n.languages[0]?.startsWith('ru') ? 11 : 14,
              }}
              popupClassName={`support-dropdown-${i18n.languages[0]}`}
              defaultValue={supportOptions[0]}
              value={supportOptions[selectedSupportOption]}
              options={supportOptions.map((o, idx) => ({
                label: o,
                value: idx,
              }))}
              onChange={(value) => {
                // @ts-ignore
                setSelectedSupportOption(value);
                return value;
              }}
            />
          </Card>
        </div>
      </div>

      {['active', 'trialing', 'past_due'].includes(
        identity?.db?.practice?.subscription?.status || '',
      ) ? (
        <Button type="primary" onClick={goToStripePortal}>
          {translate(`portalSubscriptions.manageSubscription`)}
        </Button>
      ) : (
        <Button
          disabled={lineItems.length === 0}
          type="primary"
          onClick={goToStripeCheckout}
        >
          {translate(`portalSubscriptions.upgrade`)}
        </Button>
      )}
      <div className="legal-links">
        <Button
          type="link"
          onClick={() => {
            setDocToShow('privacy');
            show();
          }}
        >
          {translate('screens.privacy')}
        </Button>
        <Button
          type="link"
          onClick={() => {
            setDocToShow('terms');
            show();
          }}
        >
          {translate('screens.terms')}
        </Button>
        <Button
          type="link"
          onClick={() => {
            setDocToShow('saas');
            show();
          }}
        >
          {translate('pages.initialSetup.step2.saasAgreement')}
        </Button>
      </div>

      <Modal
        {...modalProps}
        cancelText={''}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          close();
        }}
        maskClosable={false}
        title={modalTitle}
        width={innerWidth ? (innerWidth > 1000 ? 1000 : 400) : 400}
        afterClose={() => {
          setDocToShow(undefined);
        }}
      >
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: innerHeight
              ? innerHeight > 600
                ? '600px'
                : `${innerHeight - 50}px`
              : '600px',
          }}
        >
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          >
            {modalDoc}
          </ReactMarkdown>
        </div>
      </Modal>
    </div>
  );
};
