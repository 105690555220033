import {
  privacyPolicyEn,
  privacyPolicyRu,
  PrivacySettings,
  termsEn,
  termsRu,
  User,
} from '@cc/schema';
import {
  Button,
  Checkbox,
  Modal,
  Typography,
  useModal,
} from '@pankod/refine-antd';
import {
  useCustomMutation,
  useGetIdentity,
  useTranslate,
} from '@pankod/refine-core';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useWindowSize } from 'rooks';
import { firebaseAuth } from '../../../authProvider';
import { IUser } from '../../../providers';
import { button, buttonContainer, requiredMark } from '../styles';
import { checkboxStyle, linkStyle } from './styles';

const { Link, Paragraph, Title } = Typography;

export type StepProps = {
  currentStep: number;
  stepsLenght: number;
  setCurrentStep: (value: number) => void;
  setIsNextDisabled: (value: boolean) => void;
};

export const Step0 = ({
  currentStep,
  stepsLenght,
  setCurrentStep,
  setIsNextDisabled,
}: StepProps) => {
  const translate = useTranslate();

  const { i18n } = useTranslation();

  const { data: identity, remove, refetch } = useGetIdentity<IUser>();

  const [docToShow, setDocToShow] = useState<'privacy' | 'terms'>();

  const privacySettings: PrivacySettings = {
    isPrivacyAccepted:
      identity?.db?.privacySettings?.isPrivacyAccepted || false,
    isTermsAccepted: identity?.db?.privacySettings?.isTermsAccepted || false,
    isProductEmailNotifyOn:
      identity?.db?.privacySettings?.isProductEmailNotifyOn || false,
    isProductPushNotifyOn:
      identity?.db?.privacySettings?.isProductPushNotifyOn || false,
    isErrorInfoCollectionOn:
      identity?.db?.privacySettings?.isErrorInfoCollectionOn || false,
    isGAUsageCollectionOn:
      identity?.db?.privacySettings?.isGAUsageCollectionOn || false,
    isKeepLoggedInOn: identity?.db?.privacySettings?.isKeepLoggedInOn || false,
  };

  const [settings, setSettings] = useState<PrivacySettings>(privacySettings);

  useEffect(() => {
    setSettings(privacySettings);
  }, [JSON.stringify(privacySettings)]);

  const toggleCheckbox = (
    key: keyof typeof privacySettings,
    value?: boolean,
  ) => {
    setSettings({
      ...settings,
      [key]: value !== undefined ? value : !settings[key],
    });
  };

  const canContinue = settings.isPrivacyAccepted && settings.isTermsAccepted;

  useEffect(() => {
    setIsNextDisabled(!canContinue);
  }, [canContinue]);

  const { modalProps, show, close } = useModal();

  const { innerWidth, innerHeight } = useWindowSize();

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const privacyPolicy = i18n.languages[0]?.startsWith('ru')
    ? privacyPolicyRu
    : privacyPolicyEn;

  const terms = i18n.languages[0]?.startsWith('ru') ? termsRu : termsEn;

  const { modalTitle, modalDoc } = useMemo(
    () =>
      docToShow === 'privacy'
        ? { modalDoc: privacyPolicy, modalTitle: translate('screens.privacy') }
        : { modalDoc: terms, modalTitle: translate('screens.terms') },
    [docToShow, privacyPolicy, terms],
  );

  const { mutate } = useCustomMutation<User>();

  const updateUserPrivacy = async () => {
    if (isEqual(settings, identity?.db?.privacySettings)) {
      return;
    }
    await mutate({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'updateUserPrivacy',
        fields: [{ user: ['_id'] }],
        variables: {
          privacySettings: {
            name: 'privacySettings',
            type: 'UserPrivacySettingsInput!',
            value: {
              ...(identity?.db?.privacySettings || {}),
              ...settings,
            },
          },
        },
      },
    });

    await remove();
    firebaseAuth.resetCachedDbUser();
    await refetch();
  };

  return (
    <div>
      <Title level={4} style={{ textAlign }}>
        {translate('pages.initialSetup.step0.title')}
      </Title>
      <Paragraph>
        {translate('pages.initialSetup.step0.description')}.
      </Paragraph>
      <Paragraph>{translate('privacySettings.iAgree')}: </Paragraph>
      <Paragraph>
        <span style={requiredMark}>*</span>
        <Checkbox
          style={checkboxStyle}
          checked={!!settings.isPrivacyAccepted}
          onChange={() => toggleCheckbox('isPrivacyAccepted')}
        />
        <Link
          style={linkStyle}
          strong
          onClick={() => {
            setDocToShow('privacy');
            show();
          }}
        >
          {translate('screens.privacy')}
        </Link>
      </Paragraph>
      <Paragraph>
        <span style={requiredMark}>*</span>
        <Checkbox
          style={checkboxStyle}
          checked={!!settings.isTermsAccepted}
          onChange={() => toggleCheckbox('isTermsAccepted')}
        />

        <Link
          style={linkStyle}
          strong
          onClick={() => {
            setDocToShow('terms');
            show();
          }}
        >
          {translate('screens.terms')}
        </Link>
      </Paragraph>
      <Title level={4} style={{ textAlign }}>
        {translate('pages.initialSetup.step0.title2')}
      </Title>
      <Paragraph>{translate('privacySettings.iAgree')}: </Paragraph>
      <Paragraph>
        <Checkbox
          style={checkboxStyle}
          checked={!!settings.isProductEmailNotifyOn}
          onChange={() => toggleCheckbox('isProductEmailNotifyOn')}
        />

        {translate('privacySettings.productEmailNotify')}
      </Paragraph>
      <Paragraph>
        <Checkbox
          style={checkboxStyle}
          checked={!!settings.isErrorInfoCollectionOn}
          onChange={() => toggleCheckbox('isErrorInfoCollectionOn')}
        />

        {translate('privacySettings.errorsTracking')}
      </Paragraph>
      <Paragraph>
        <Checkbox
          style={checkboxStyle}
          checked={!!settings.isGAUsageCollectionOn}
          onChange={() => toggleCheckbox('isGAUsageCollectionOn')}
        />

        {translate('privacySettings.usageTracking')}
      </Paragraph>

      <Modal
        {...modalProps}
        okText={translate('privacySettings.accept')}
        onOk={() => {
          if (docToShow === 'privacy') {
            toggleCheckbox('isPrivacyAccepted', true);
          }
          if (docToShow === 'terms') {
            toggleCheckbox('isTermsAccepted', true);
          }
          close();
        }}
        maskClosable={false}
        title={modalTitle}
        width={innerWidth ? (innerWidth > 1000 ? 1000 : 400) : 400}
        afterClose={() => {
          setDocToShow(undefined);
        }}
      >
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: innerHeight
              ? innerHeight > 600
                ? '600px'
                : `${innerHeight - 50}px`
              : '600px',
          }}
        >
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          >
            {modalDoc}
          </ReactMarkdown>
        </div>
      </Modal>

      <div style={buttonContainer}>
        {currentStep !== 0 && (
          <Button
            onClick={() => setCurrentStep(currentStep - 1)}
            style={button}
          >
            {translate('pages.initialSetup.previousStep')}
          </Button>
        )}
        <Button
          onClick={() => {
            updateUserPrivacy();
            setCurrentStep(currentStep + 1);
          }}
          style={button}
          disabled={!canContinue}
          type="primary"
        >
          {currentStep === stepsLenght - 1
            ? translate('pages.initialSetup.finish')
            : translate('pages.initialSetup.nextStep')}
        </Button>
      </div>
    </div>
  );
};
