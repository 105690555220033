export const RU_RU_LOCALE = {
  locale: 'ru_RU',

  // General
  general: {
    increase: 'Увеличить',
    decrease: 'Уменьшить',
    root: 'Основа',
  },

  // Plot Components
  /** statistic text component */
  statistic: {
    total: 'Всего',
  },
  /** conversionTag component */
  conversionTag: {
    label: 'Коэффициент',
  },
  legend: {},
  tooltip: {},
  slider: {},
  scrollbar: {},

  // Plots
  waterfall: {
    total: 'Всего',
  },
};
