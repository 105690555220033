import {
  Button,
  CreateButton,
  List,
  Modal,
  Space,
  Table,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useGetLocale,
  useList,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';

import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { User, UserRole } from '@cc/schema';
import { useActor } from '@xstate/react';
import { format, formatDistance } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TherapistActivityReactContext } from '../../App';
import { useSubscriptionWarnings } from '../../hooks';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { IUser } from '../../providers';
import { Session } from '../../schema/be-graphql-generated';
import './styles.less';
import { colors } from '../../styles/colors';

export const SessionsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const locale = useGetLocale();

  const currentLocale = locale();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  // @ts-ignore
  const { tableProps, sorter, searchFormProps, filters, setFilters } =
    useTable<Session>({
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],
      initialFilter: therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      metaData: {
        operation: 'sessions',
        fields: [
          {
            data: ['_id', '_to', 'createdAt', 'updatedAt', 'finishedAt'],
          },
          'count',
        ],
      },
    });

  useEffect(() => {
    setFilters(
      therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      'replace',
    );
  }, [therapistActivityState?.context?.filterClientId]);

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const { push } = useNavigation();

  const isCreateButtonDisabled =
    subWarnings?.limitReached || subWarnings?.subscriptionExpired;

  const [showNeedUpgrade, setShowNeedUpgrade] = useState(false);

  return (
    <List
      title={translate('pages.sessions.title')}
      headerButtons={
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <CreateButton
            onClick={() => {
              isCreateButtonDisabled
                ? setShowNeedUpgrade(true)
                : push('/sessions/create');
            }}
          />
        </div>
      }
    >
      <Modal
        title={
          <>
            <ExclamationCircleOutlined
              style={{ color: colors['--primary-0'] }}
            />{' '}
            <span>{translate('pages.sessions.upgradeNeeded')}</span>
          </>
        }
        cancelText={translate('common.cancel')}
        okText={translate('pages.sessions.gotToSubscription')}
        open={showNeedUpgrade}
        onOk={async () => {
          setShowNeedUpgrade(false);
          push('/portalSubscriptions');
        }}
        onCancel={() => {
          setShowNeedUpgrade(false);
        }}
      >
        {' '}
        {translate('pages.sessions.upgradeNeededDescription')}
      </Modal>

      <Table
        {...tableProps}
        rowKey="_id"
        onChange={(pagination, filters, sorter, extra) => {
          // Note: The data we get from Ant.design filters does not match the
          // react refine one. Currently it works fine if I simply silence it the way below.
          // TOOD: Find a way to convert the data to match the react refine one and fix it better
          // @ts-ignore
          tableProps?.onChange?.(pagination, null, sorter, extra);
        }}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          key="_to"
          dataIndex="_to"
          title={translate('resourcesCommon.userId')}
          sorter
          filterSearch
          // @ts-ignore
          onFilter={(value: string, record: DownwardArrow) => {
            return record._to === value;
          }}
          filters={
            // @ts-ignore
            clients?.data?.data?.map((c, idx) => {
              return {
                text: `${c._id} - ${c.firstName} ${c.lastName}`,
                value: c._id,
              };
            })
          }
          render={(value, record) => {
            // @ts-ignore
            const client = clients?.data?.data?.find?.(
              // @ts-ignore
              (c) => c._id === value,
            );

            return (
              <Tooltip
                title={client ? `${client.firstName} ${client.lastName}` : ''}
              >
                {`${value.replace('users/', '')} - ${(
                  client?.firstName || ''
                ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
              </Tooltip>
            );
          }}
        />
        <Table.Column
          key="createdAt"
          dataIndex="createdAt"
          title={translate('resourcesCommon.createdAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm'
                : 'yyyy-MM-dd hh:mm aaa',
            );
          }}
        />
        <Table.Column
          key="finishedAt"
          dataIndex="finishedAt"
          title={translate('resourcesCommon.finishedAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm'
                : 'yyyy-MM-dd hh:mm aaa',
            );
          }}
        />
        <Table.Column<Session>
          key="createdAt"
          dataIndex="createdAt"
          title={translate('resourcesCommon.duration')}
          sorter
          render={(text, record, indx) => {
            return record.finishedAt
              ? formatDistance(
                  new Date(record.createdAt),
                  new Date(record.finishedAt),
                )
              : translate('pages.sessions.noFinishDate');
          }}
        />
        <Table.Column<Session>
          title={translate('resourcesCommon.actions')}
          dataIndex="actions"
          render={(_, record) => {
            // @ts-ignore
            const client: Client = // @ts-ignore
              ((clients?.data?.data as Client[]) || []).find(
                (c) => c._id === record._to,
              );

            const hasPremiumAccess = client?.accessLevel;

            const canEdit =
              client?.access?.allowEdit ||
              (client?.access?.allowAdminEdit &&
                identity?.db?.role === UserRole.THERAPIST_ADMIN);

            return (
              <Space>
                {canEdit && (
                  <Tooltip
                    title={translate(
                      hasPremiumAccess
                        ? 'resourcesCommon.edit'
                        : 'clients.thoughtsTestDisabledTip',
                    )}
                  >
                    <Link to={`/sessions/create?sessionsId=${record._id}`}>
                      <Button
                        icon={<EditOutlined />}
                        disabled={!hasPremiumAccess || !canEdit}
                      />
                    </Link>
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
