import {
  ButtonProps,
  useTableProps,
  useTableReturnType,
  useTable,
  useForm,
} from '@pankod/refine-antd';
import {
  UseFormProps,
  UseFormReturnType,
} from '@pankod/refine-antd/dist/hooks/form/useForm';
import { BaseKey, BaseRecord, HttpError } from '@pankod/refine-core';

// NOTE: This is a custom component based on the original refine-antd useEditableTable component
// to allow for specifying more details for the form in edit mode

export type useCcEditableTableReturnType<
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
  TSearchVariables = unknown,
> = {
  table: useTableReturnType<TData, TSearchVariables>;
  form: UseFormReturnType<TData, TError, TVariables>;
  saveButtonProps: ButtonProps & {
    onClick: () => void;
  };
  cancelButtonProps: ButtonProps & {
    onClick: () => void;
  };
  editButtonProps: (id: BaseKey) => ButtonProps & {
    onClick: () => void;
  };
  isEditing: (id: BaseKey) => boolean;
};

// Note: original Description/Links is below

/**
 * `useEditeableTable` allows you to implement edit feature on the table with ease,
 * on top of all the features that {@link https://refine.dev/docs/api-references/hooks/table/useTable `useTable`} provides.
 * `useEditableTable` return properties that can be used on Ant Design's {@link https://ant.design/components/table/ `<Table>`}
 * and {@link https://ant.design/components/form/ `<Form>`} components.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/hooks/table/useEditableTable} for more details.
 */

export const useCcEditableTable = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TVariables = {},
  TSearchVariables = unknown,
>(
  tableProps: useTableProps<TData, TError, TSearchVariables> = {},
  formProps: UseFormProps<TData, TError, TVariables> = {},
): useCcEditableTableReturnType<
  TData,
  TError,
  TVariables,
  TSearchVariables
> => {
  const table = useTable<TData, TError, TSearchVariables>({ ...tableProps });
  const form = useForm<TData, TError, TVariables>({
    ...formProps,
    action: 'edit',
    redirect: false,
  });

  const { id: editId, setId, saveButtonProps } = form;

  const cancelButtonProps = {
    onClick: () => {
      setId(undefined);
    },
  };

  const editButtonProps = (id: BaseKey) => {
    return {
      onClick: () => setId(id),
    };
  };

  const isEditing = (id: BaseKey) => id === editId;

  return {
    table,
    form,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    isEditing,
  };
};
