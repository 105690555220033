export const emptySud = {
  sud: 50 as number | null, // SUBJECTIVE UNITS OF DISTRESS, 0 - 100
  reactedAt: '',
};

export const emptyExposition = {
  createdAt: null as string | null,
  suds: [] as typeof emptySud[],
  anticipatedSud: 50 as number | null,
  reactionNotes: '', // What happened?
};

export const situationInitialValues = {
  title: '' as string | null,
  description: '' as string | null,
  imageUrls: [] as string[] | null,
  imagePrompt: '' as string | null,
  sud: 50 as number | null,
  avoidance: null as number | null,
  expositions: [] as typeof emptyExposition[],
  worstThingThatCouldHappen: '' as string | null,
};

export const exposureInitialValues = {
  type: null as number | null,
  fear: '' as string | null,
  obsession: '' as string | null,
  safetyBehaviorsToAvoid: '' as string | null,
};
