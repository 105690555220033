import { GraphQLClient } from '@pankod/refine-graphql';
import { initializeApp } from 'firebase/app';
import { Auth, User } from 'firebase/auth';
import { FirebaseAuth } from './providers';

const firebaseConfig = {
  apiKey: 'AIzaSyAC1ku4BEamHNxUrL8oWkeq-hSATLgxnfE',
  authDomain: 'copingcard.firebaseapp.com',
  // authDomain: 'localhost',
  projectId: 'copingcard',
  storageBucket: 'copingcard.appspot.com',
  messagingSenderId: '783662036013',
  appId: '1:783662036013:web:093d8dd01ca1e47912b4a4',
  measurementId: 'G-ZXTDZMXEC6',
};

export const firebaseApp = initializeApp(firebaseConfig);

const host = process.env.REACT_APP_CC_API_HOST || '';
const path = process.env.REACT_APP_CC_API_PATH || '';
const API_URL = `${host}${path}`;

export const httpClient = new GraphQLClient(API_URL, {
  headers: {
    'Authentication': `Bearer `,
    'Accept-Language': 'en',
  },
});

// TODO: they might be not needed, but might help with stale token
const onLogin = async (user: User) => {
  const token = await user.getIdToken();
  httpClient.setHeader('Authentication', `Bearer ${token}`);
};

const onLogout = (auth: Auth) => {
  httpClient.setHeader('Authentication', `Bearer `);
};

export const firebaseAuth = new FirebaseAuth(
  { onLogin, onLogout },
  firebaseApp,
);
