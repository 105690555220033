export const PAYWALLS = {
  paywall_downgrade: 'paywall_downgrade',
  paywall_geo_notifications: 'paywall_geo_notifications',
  paywall_settings: 'paywall_settings',
  paywall_stats: 'paywall_stats',
  paywall_time_notifications: 'paywall_time_notifications',
  paywall_tests_phq_9: 'paywall_tests_phq_9',
  paywall_tests_gad_7: 'paywall_tests_gad_7',
  paywall_meditations: 'paywall_meditations',
  paywall_worry_trees: 'paywall_worry_trees',
  paywall_downward_arrows: 'paywall_downward_arrows',
  paywall_behavior_experiments: 'paywall_behavior_experiments',
  paywall_exposures: 'paywall_exposures',
  paywall_ai_image_generation: 'paywall_ai_image_generation',
};

export const PRODUCTS = {
  client_year_999: 'client_year_999',
  client_6months_999: 'client_6months_999',
  client_monthly_999: 'client_monthly_999',
};

// Note: undefined is free
export const ACCESS_LEVELS = {
  premium: 'premium',
  premium_practice: 'premium_practice',
} as const;

// Note: for some reason we are not getting them for Android
// and overall it seems to be unerliable, so adding a manual mapping
export const subscriptionPeiods = {
  [PRODUCTS.client_monthly_999]: {
    unit: 'month',
    numberOfUnits: 1,
  },

  [PRODUCTS.client_6months_999]: {
    unit: 'month',
    numberOfUnits: 6,
  },

  [PRODUCTS.client_year_999]: {
    unit: 'year',
    numberOfUnits: 1,
  },
};

export const FREE_SUBSCRIPTION_APPS = 5;
