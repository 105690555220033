import { getValidators } from '@cc/schema';
import {
  Edit,
  Form,
  Input,
  RefreshButton,
  Select,
  useForm,
} from '@pankod/refine-antd';
import { useFormSubmitDisabledAnyTouched } from '../../hooks';
import { useGetIdentity, useTranslate } from '@pankod/refine-core';
import { RuleObject } from 'antd/lib/form';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getYupSyncValidator } from '../../helpers';
import { IUser } from '../../providers';
import {
  IPracticeForm,
  praticeSizeOptions,
  urlRegExp,
} from '../initialSetup/step2';

const metaData = {
  fields: [
    'practiceSize',
    'npi',
    'practiceName',
    'practicePhone',
    'extension',
    'practiceUrl',
    'practiceEmail',
    'practiceDescription',
    'acceptedInsurances',
  ],
};
export const PracticeEdit = () => {
  const translate = useTranslate();

  const {
    data: identity,
    remove,
    refetch,
    isLoading,
  } = useGetIdentity<IUser>();

  const { formProps, saveButtonProps } = useForm<IPracticeForm>({
    warnWhenUnsavedChanges: true,
    resource: 'practice',
    action: 'edit',
    id: identity?.db?.practiceId || -1,
    metaData,
    redirect: false,
  });

  const { i18n } = useTranslation();

  const { practiceValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const validationRules = getYupSyncValidator(
    translate,
    practiceValidator,
  ) as RuleObject;

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabledAnyTouched(formProps.form!);

  return (
    <Edit
      title={translate('practice.practiceInfo')}
      saveButtonProps={{ ...saveButtonProps, disabled: isSubmitDisabled }}
      resource="practice"
      recordItemId={identity?.db?.practiceId || -1}
      headerButtons={
        <RefreshButton
          {...(isLoading ? { disabled: true } : {})}
          resourceNameOrRouteName={'practice'}
          recordItemId={identity?.db?.practiceId || -1}
          metaData={metaData}
        />
      }
    >
      <Form
        {...formProps}
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          name="practiceSize"
          label={translate('pages.initialSetup.step1.practiceSize')}
          required
          rules={[validationRules]}
        >
          <Select size="large">
            {praticeSizeOptions.map((o, idx) => (
              <Select.Option value={o} key={o}>
                {o}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {true && (
          <Form.Item
            name="npi"
            label={translate('pages.initialSetup.step2.npi')}
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate('pages.initialSetup.step2.npi')}
            />
          </Form.Item>
        )}
        <Form.Item
          name="practiceName"
          label={translate('pages.initialSetup.step2.practiceName')}
          required
          rules={[validationRules]}
        >
          <Input
            type="text"
            size="large"
            placeholder={translate('pages.initialSetup.step2.practiceName')}
          />
        </Form.Item>
        <Form.Item
          name="practiceEmail"
          label={translate('pages.initialSetup.step2.practiceEmail')}
          required
          rules={[validationRules]}
        >
          <Input
            type="email"
            size="large"
            placeholder={translate('pages.initialSetup.step2.practiceEmail')}
          />
        </Form.Item>
        <Form.Item
          name="practiceUrl"
          label={translate('pages.initialSetup.step2.practiceUrl')}
          rules={[
            {
              pattern: urlRegExp,
              message: translate('validators.url'),
            },
          ]}
        >
          <Input
            type="url"
            size="large"
            addonBefore="https://"
            placeholder={translate('pages.initialSetup.step2.practiceUrl')}
          />
        </Form.Item>

        <Form.Item
          name="practicePhone"
          label={translate('pages.initialSetup.step2.practicePhone')}
          required
          rules={[validationRules]}
        >
          <Input
            type="tel"
            size="large"
            placeholder={translate('pages.initialSetup.step2.practicePhone')}
          />
        </Form.Item>

        <Form.Item
          name="extension"
          label={translate('pages.initialSetup.step2.extension')}
          rules={[validationRules]}
        >
          <Input
            size="large"
            placeholder={translate('pages.initialSetup.step2.extension')}
          />
        </Form.Item>

        <Form.Item
          name="practiceDescription"
          label={translate('pages.initialSetup.step2.practiceDescription')}
          required
          rules={[validationRules]}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 8 }}
            size="large"
            placeholder={translate(
              'pages.initialSetup.step2.practiceDescription',
            )}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
