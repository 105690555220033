import {
  Button,
  Checkbox,
  Col,
  CreateButton,
  EditButton,
  Form,
  Input,
  List,
  Row,
  SaveButton,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useGetLocale,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import { useCcEditableTable } from '../../helpers';
import { getYupSyncValidator } from '../../helpers/validation';

import {
  baseEmotionsEn,
  baseEmotionsRu,
  baseFeelingsEn,
  baseFeelingsRu,
  basicFeelingsEn,
  basicFeelingsRu,
  bodyPartsEn,
  bodyPartsRu,
  dbCols,
  getValidators,
  Thought,
  translationEn,
  User,
  UserRole,
} from '@cc/schema';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { RuleObject } from 'antd/lib/form';
import {
  useFormSubmitDisabledAnyTouched,
  useSubscriptionWarnings,
} from '../../hooks';
import { colors } from '../../styles/colors';
import { numberToOnesAndZerosArrayOfArrays } from '@cc/utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IUser } from '../../providers';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';

export const ThoughtList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { t, i18n } = useTranslation();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const locale = useGetLocale();

  const currentLocale = locale();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const { portalThoughtUpdateValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const validationRules = getYupSyncValidator(
    translate,
    portalThoughtUpdateValidator,
  ) as RuleObject;

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const {
    table: { tableProps, setFilters },
    form: { formProps },
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useCcEditableTable<Thought>(
    {
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],

      initialFilter: therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
            // Note: it's a workaround for Refine not sending nulls
            {
              field: 'activatingEventId',
              operator: 'ne',
              value: 'null',
            },
          ]
        : [
            {
              field: 'activatingEventId',
              operator: 'ne',
              value: 'null',
            },
          ],
      metaData: {
        fields: [
          {
            data: [
              '_id',
              'userId',
              'automaticThought',
              'thoughtBelief',
              'thoughtsFor',
              'thoughtsForAlternativeThoughts',
              'thoughtsAgainst',
              'cognitiveDistortions',
              { realisticThoughts: ['thought', 'thoughtBelief'] },
              {
                result: [
                  'initialThoughtBelief',
                  'initialNegativeEmotionStrenght',
                ],
              },
              'updatedAt',
              {
                activatingEvent: [
                  'description',
                  { emotions: ['id', 'strength'] },
                  'feelings',
                  'bodyPartsFeelings',
                  'behaviors',
                ],
              },
            ],
          },
          'count',
        ],
      },
    },
    {
      metaData: {
        fields: [
          '_id',
          'userId',
          'automaticThought',
          'thoughtBelief',
          'thoughtsFor',
          'thoughtsForAlternativeThoughts',
          'thoughtsAgainst',
          { realisticThoughts: ['thought', 'thoughtBelief'] },
          {
            result: ['initialThoughtBelief', 'initialNegativeEmotionStrenght'],
          },
          'updatedAt',
          {
            activatingEvent: [
              'description',
              { emotions: ['id', 'strength'] },
              'feelings',
              'bodyPartsFeelings',
              'behaviors',
            ],
          },
        ],
      },
    },
  );

  useEffect(() => {
    setFilters(
      therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
            {
              field: 'activatingEventId',
              operator: 'ne',
              value: 'null',
            },
          ]
        : [
            {
              field: 'activatingEventId',
              operator: 'ne',
              value: 'null',
            },
          ],
      'replace',
    );
  }, [therapistActivityState?.context?.filterClientId]);

  const {
    handleFormChange,
    isDisabled: isSubmitDisabled,
    fieldsCount,
  } = useFormSubmitDisabledAnyTouched(formProps?.form!);

  const baseEmotions = useMemo(() => {
    const baseEmotions = i18n.languages[0]?.startsWith('ru')
      ? baseEmotionsRu
      : baseEmotionsEn;

    return baseEmotions.map((e) => ({
      ...e,
      children: e.children.map((ch) => ({ ...ch, strength: 50 })),
    }));
  }, [i18n.languages[0]]);

  const flattenedEmotions = useMemo(() => {
    return baseEmotions.reduce(
      (acc, val) => [...acc, ...val.children],
      [] as typeof baseEmotionsEn[0]['children'],
    );
  }, [baseEmotions]);

  const baseFeelings = i18n.languages[0]?.startsWith('ru')
    ? baseFeelingsRu
    : baseFeelingsEn;

  const flattenedFeelings = useMemo(() => {
    return baseFeelings.reduce(
      (acc, val) => [...acc, ...val.children],
      [] as typeof baseFeelings[0]['children'],
    );
  }, [baseFeelings]);

  const headerFeelings = i18n.languages[0]?.startsWith('ru')
    ? basicFeelingsRu
    : basicFeelingsEn;

  const bodyParts = i18n.languages[0]?.startsWith('ru')
    ? bodyPartsRu
    : bodyPartsEn;

  const feelingsColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'feelingName',
      },
      ...headerFeelings.map((feeling) => {
        return {
          title: feeling,
          dataIndex: feeling,
          render: (value: any, record: any) => {
            return <Checkbox checked={!!value} disabled />;
          },
        };
      }),
    ];
  }, [headerFeelings]);

  useActivityTracking(dbCols.thoughts);

  const expandedRowRender = (record: Thought) => {
    const bodyPartsAsArray = numberToOnesAndZerosArrayOfArrays(
      headerFeelings.length,
      bodyParts.length,
      // @ts-ignore
      record.activatingEvent.bodyPartsFeelings,
    );

    const feelingsData = bodyPartsAsArray.map((row, i) => {
      return Object.assign(
        {},
        ...[
          { key: i },
          {
            feelingName: bodyParts[i],
          },
          ...row.map((col, j) => {
            return {
              [headerFeelings[j]]: col,
            };
          }),
        ],
      );
    });

    return (
      <div>
        <Row>
          <Col span={12}>
            <span style={{ marginRight: 10 }}>
              {translate('createThought.emotionsTitle')}:{' '}
            </span>
            {
              // @ts-ignore
              record.activatingEvent.emotions
                // @ts-ignore
                .sort((a, b) => b.strength - a.strength)
                // @ts-ignore
                .map((e) => {
                  let color =
                    e.strength < 20
                      ? colors['--triad-green-0']
                      : e.strength < 40
                      ? colors['--triad-green-1']
                      : e.strength < 60
                      ? colors['--primary-1']
                      : e.strength < 80
                      ? colors['--primary-2']
                      : colors['--primary-0'];

                  return (
                    <Tag color={color} key={e.id}>
                      {`${
                        flattenedEmotions.find((fe) => fe.id === e.id)?.name
                      } - ${e.strength}`}
                    </Tag>
                  );
                })
            }
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <span style={{ marginRight: 10 }}>
                {translate('createThought.feelingsTitle')}:{' '}
              </span>
              {
                // @ts-ignore
                record.activatingEvent.feelings.map((f) => {
                  const maybeFeelingName = flattenedFeelings.find(
                    (bf) => bf.id === f,
                  )?.name;
                  if (!maybeFeelingName) return null;

                  return <Tag key={f}>{maybeFeelingName}</Tag>;
                })
              }
            </div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <span style={{ marginRight: 10 }}>
                {translate('shared.abcModel.behaviors')}:{' '}
              </span>
              {
                // @ts-ignore
                record.activatingEvent.behaviors
              }
            </div>
          </Col>
          <Col span={12}>
            <div>
              {translate('createThought.feelingsInBody')}:{' '}
              <Table
                columns={feelingsColumns}
                dataSource={feelingsData}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <List
      title={translate('pages.thoughtList.title')}
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...formProps} onFieldsChange={handleFormChange}>
        <Table
          {...tableProps}
          rowKey="_id"
          expandable={{
            expandedRowRender,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            // Note: The data we get from Ant.design filters does not match the
            // react refine one. Currently it works fine if I simply silence it the way below.
            // TOOD: Find a way to convert the data to match the react refine one and fix it better
            // @ts-ignore
            tableProps?.onChange?.(pagination, null, sorter, extra);
          }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            key="_id"
            dataIndex="userId"
            sorter={{ multiple: 2 }}
            title={translate('pages.thoughtList.userId')}
            filterSearch
            // @ts-ignore
            onFilter={(value: string, record: Thought) => {
              return record.userId === value;
            }}
            filters={
              // @ts-ignore
              clients?.data?.data?.map((c, idx) => {
                return {
                  text: `${c._id} - ${c.firstName} ${c.lastName}`,
                  value: c._id,
                };
              })
            }
            render={(value, record) => {
              // @ts-ignore
              const client = clients?.data?.data?.find?.(
                // @ts-ignore
                (c) => c._id === value,
              );

              return (
                <Tooltip
                  title={client ? `${client.firstName} ${client.lastName}` : ''}
                >
                  {`${value.replace('users/', '')} - ${(
                    client?.firstName || ''
                  ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
                </Tooltip>
              );
            }}
          />
          <Table.Column<Thought>
            key="activatingEvent.description"
            dataIndex={['activatingEvent', 'description']}
            title={translate('pages.thoughtList.activatingEvent')}
          />
          <Table.Column<Thought>
            key="automaticThought"
            dataIndex="automaticThought"
            title={translate('pages.thoughtList.automaticThought')}
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item
                    name={['automaticThought']}
                    style={{ margin: 0, minWidth: 120 }}
                    rules={[validationRules]}
                  >
                    <Input.TextArea autoSize={{ minRows: 4 }} />
                  </Form.Item>
                );
              }
              return value;
            }}
          />
          <Table.Column<Thought>
            key="thoughtsFor"
            dataIndex="thoughtsFor"
            title={translate('pages.thoughtList.thoughtsFor')}
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.List name={['thoughtsFor']}>
                    {(fields, { add, remove }, errors) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key]}
                                style={{ margin: 0, minWidth: 120 }}
                                rules={[
                                  {
                                    message: translate(
                                      'createThought.thoughtEvidenceRequired',
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea autoSize={{ minRows: 2 }} />
                              </Form.Item>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                );
              }
              // @ts-ignore
              return (value || []).map((v, idx) => (
                <div key={idx}>
                  {value?.length > 1 ? `${idx + 1}. ${v}` : v}
                </div>
              ));
            }}
          />
          <Table.Column<Thought>
            key="thoughtsForAlternativeThoughts"
            dataIndex="thoughtsForAlternativeThoughts"
            title={translate(
              'pages.thoughtList.thoughtsForAlternativeThoughts',
            )}
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.List name={['thoughtsForAlternativeThoughts']}>
                    {(fields, { add, remove }, errors) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key]}
                                style={{ margin: 0, minWidth: 120 }}
                              >
                                <Input.TextArea autoSize={{ minRows: 2 }} />
                              </Form.Item>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                );
              }
              // @ts-ignore
              return (value || []).map((v, idx) => (
                <div key={idx}>
                  {value?.length > 1 ? `${idx + 1}. ${v}` : v}
                </div>
              ));
            }}
          />
          <Table.Column<Thought>
            key="thoughtsAgainst"
            dataIndex="thoughtsAgainst"
            title={translate('pages.thoughtList.thoughtsAgainst')}
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.List name={['thoughtsAgainst']}>
                    {(fields, { add, remove }, errors) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key]}
                                style={{ margin: 0, minWidth: 120 }}
                                rules={[
                                  {
                                    message: translate(
                                      'createThought.evidenceAgainstRequired',
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea autoSize={{ minRows: 2 }} />
                              </Form.Item>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                );
              }
              return value;
            }}
          />
          <Table.Column<Thought>
            key="cognitiveDistortions"
            dataIndex={['cognitiveDistortions']}
            title={translate('createThought.cognitiveDistortions')}
            render={(distortionsKeys, record, indx) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item
                    name={'cognitiveDistortions'}
                    style={{ margin: 0, minWidth: 140 }}
                    rules={[validationRules]}
                  >
                    <Select
                      placeholder={translate(
                        'createThought.cognitiveDistortionsPlaceholder',
                      )}
                      defaultValue={distortionsKeys || []}
                      optionFilterProp="children"
                      loading={clientsLoading}
                      mode="multiple"
                      allowClear
                      style={{ width: 400 }}
                    >
                      {Object.keys(translationEn.cognitiveDistorions).map(
                        (key) => (
                          <Select.Option key={key} value={key}>
                            {translate(`cognitiveDistorions.${key}`)}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                );
              }
              return (
                <div>
                  {(distortionsKeys || []).map((key: string, idx: number) => (
                    <div key={idx}>{`${idx + 1}. ${translate(
                      `cognitiveDistorions.${key}`,
                    )}`}</div>
                  ))}
                </div>
              );
            }}
          />
          <Table.Column<Thought>
            key="realisticThoughts"
            dataIndex={['realisticThoughts']}
            title={translate('pages.thoughtList.realisticThoughts')}
            render={(thoughts, record, indx) => {
              if (isEditing(record._id)) {
                return (
                  <Form.List name={['realisticThoughts']}>
                    {(fields, { add, remove }, errors) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key, 'thought']}
                                style={{ margin: 0, minWidth: 140 }}
                                rules={[
                                  {
                                    required: true,
                                    message: translate(
                                      'createThought.realisticThoughtRequired',
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea autoSize={{ minRows: 3 }} />
                              </Form.Item>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                );
              }
              return (
                <div>
                  {
                    // @ts-ignore
                    thoughts.map((t, i) => {
                      const prefix = i === 0 ? '' : `${i + 1}. `;
                      return <div key={i}>{`${prefix}${t.thought}`}</div>;
                    })
                  }
                </div>
              );
            }}
          />
          <Table.Column
            key="updatedAt"
            dataIndex="updatedAt"
            title={translate('pages.thoughtList.updatedAt')}
            sorter={{ multiple: 1 }}
            render={(text, record, indx) => {
              const date = new Date(text);
              return format(
                date,
                currentLocale?.startsWith('ru')
                  ? 'yyyy-MM-dd HH:mm:ss'
                  : 'yyyy-MM-dd hh:mm:ss aaa',
              );
            }}
          />
          <Table.Column<Thought>
            title={translate('resourcesCommon.actions')}
            dataIndex="actions"
            render={(_, record) => {
              // @ts-ignore
              const client: Client = ( // @ts-ignore
                (clients?.data?.data as Client[]) || []
              ).find((c) => c._id === record.userId);

              const hasPremiumAccess = client?.accessLevel;

              const canEdit =
                client?.access?.allowEdit ||
                (client?.access?.allowAdminEdit &&
                  identity?.db?.role === UserRole.THERAPIST_ADMIN);

              if (isEditing(record._id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      onClick={() => {
                        therapistActivitySend({
                          type: 'UPDATED',
                          resource: record._id,
                        });
                        saveButtonProps.onClick?.();

                        therapistActivitySend({
                          type: 'LOADED',
                          resource: dbCols.thoughts,
                        });
                      }}
                      disabled={isSubmitDisabled}
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                      onClick={() => {
                        therapistActivitySend({
                          type: 'TIMED_OUT',
                          resource: record._id,
                        });
                        cancelButtonProps.onClick?.();
                        therapistActivitySend({
                          type: 'LOADED',
                          resource: dbCols.thoughts,
                        });
                      }}
                    >
                      {translate('buttons.cancel')}
                    </Button>
                  </Space>
                );
              }
              return (
                <Space>
                  {
                    <>
                      <EditButton
                        {...editButtonProps(record._id)}
                        onClick={() => {
                          therapistActivitySend({
                            type: 'TIMED_OUT',
                            resource: dbCols.thoughts,
                          });
                          therapistActivitySend({
                            type: 'LOADED',
                            resource: record._id,
                            clientId: record.userId,
                          });
                          editButtonProps(record._id).onClick?.();
                        }}
                        hideText
                        size="small"
                        disabled={!hasPremiumAccess || !canEdit}
                      />
                      {(!hasPremiumAccess || !canEdit) && (
                        <Tooltip
                          title={translate(
                            !hasPremiumAccess
                              ? 'pages.thoughtList.thoughtEditLimitTip'
                              : 'pages.thoughtList.thoughtEditNoPermissionTip',
                          )}
                        >
                          <QuestionCircleOutlined
                            style={{ marginLeft: '10px' }}
                          />
                        </Tooltip>
                      )}
                    </>
                  }

                  {/* <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} /> */}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
