import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { portalEn, portalRu } from '@cc/schema';

export const resources: Record<string, { translation: typeof portalEn }> = {
  en: {
    translation: portalEn,
  },
  ru: {
    translation: portalRu,
  },
} as const;

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'be': ['ru'],
      'be-BY': ['ru'],
      'ru-RU': ['ru'],
      'uk': ['ru'],
      'uk-UA': ['ru'],
      'en-US': ['en'],
      'en-UK': ['en'],
      'default': ['en'],
    },
    supportedLngs: ['en', 'ru'],
    resources,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
  });

export default i18n;
