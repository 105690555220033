export const LOCAL_STORAGE_KEYS = {
  inviteToken: 'inviteToken',
  clientAccountWarningShown: 'clientAccountWarningShown',
  utmData: 'utmData',
};

export const xarrowProps = {
  color: 'rgba(43, 120, 149, 0.4)',
  path: 'smooth' as const,
  strokeWidth: 1.5,
};
