import { MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  DayReminders,
  dbCols,
  getValidators,
  Thought,
  User,
  UserRole,
} from '@cc/schema';
import {
  Card,
  Checkbox,
  Create,
  Divider,
  Form,
  Input,
  Result,
  Select,
  Table,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustomMutation,
  useGetIdentity,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import * as Sentry from '@sentry/react';
import { useActor } from '@xstate/react';
import { RuleObject } from 'antd/lib/form';
import { useWatch } from 'antd/lib/form/Form';
import { set } from 'date-fns';
import qs from 'qs';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { TherapistActivityReactContext } from '../../App';
import { DateFnsTimePicker } from '../../components/Pickers';
import { getYupSyncValidator } from '../../helpers';
import {
  useFormSubmitDisabledAnyTouched,
  useSubscriptionWarnings,
} from '../../hooks';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { IUser } from '../../providers';

const { Text } = Typography;

export const emptyBasicThought = {
  thought: '',
  thoughtBelief: 50,
};

type CreateCopingCardForm = {
  clientId: string;

  // Thought
  automaticThought: string;
  realisticThoughts: (typeof emptyBasicThought)[];
  // Reminders
  dayReminders?: DayReminders;
};

export const CreateCopingCard: React.FC<IResourceComponentsProps> = () => {
  const [form] = Form.useForm<CreateCopingCardForm>();

  const { t, i18n } = useTranslation();
  const { data: user } = useGetIdentity<IUser>();
  const location = routerProvider.useLocation();
  const translate = useTranslate();

  const { portalDysThoughtTestValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const validationRules = getYupSyncValidator(
    translate,
    portalDysThoughtTestValidator,
  ) as RuleObject;

  const [isCardFlipped, setIsCardFlipped] = useState(false);

  // Note: as RuleObject is silenciing Antd since it seems to have wrong types

  const {
    handleFormChange,
    isDisabled: isSubmitDisabled,
    fieldsCount,
  } = useFormSubmitDisabledAnyTouched(form);

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<User>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, //Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const {
    mutate,
    data: createData,
    isLoading: createLoading,
    error: createError,
  } = useCustomMutation<{ thought: Thought }>();

  const dayReminders = useWatch('dayReminders', form);

  const dayRemindersColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'period',
        render: (period: string) => {
          return <Text strong>{translate(`createThought.${period}`)}</Text>;
        },
      },
      {
        title: '',
        dataIndex: 'time',
        render: (
          time: DayReminders['day']['time'],
          record: DayReminders['day'],
        ) => {
          return (
            <DateFnsTimePicker
              defaultValue={set(new Date(), {
                hours: time.hours,
                minutes: time.minutes,
              })}
              format={i18n.languages[0]?.startsWith('ru') ? 'HH:mm' : 'hh:mm a'}
              onChange={(date) => {
                const hours = date?.getHours() || 0;
                const minutes = date?.getMinutes() || 0;

                const copiedDayReminders = {
                  ...form.getFieldValue('dayReminders'),
                };
                copiedDayReminders[
                  // @ts-ignore
                  record.key
                ].time = {
                  hours,
                  minutes,
                };
                form.setFieldValue('dayReminders', copiedDayReminders);
              }}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'enabled',
        render: (enabled: boolean, record: DayReminders['day']) => {
          return (
            <Checkbox
              checked={enabled}
              onChange={(e) => {
                const checked = e.target.checked;

                const copiedDayReminders = {
                  ...form.getFieldValue('dayReminders'),
                };
                copiedDayReminders[
                  // @ts-ignore
                  record.key
                ].enabled = checked;
                form.setFieldValue('dayReminders', copiedDayReminders);
              }}
            />
          );
        },
      },
    ];
  }, [dayReminders]);

  const dayRemindersData = useMemo(() => {
    return Object.keys(dayReminders || {}).map((period) => {
      return {
        key: period,
        period,
        // @ts-ignore
        ...(dayReminders?.[period] || {}),
      };
    });
  }, [dayReminders]);

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  const { innerWidth } = useWindowSize();
  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const initialValues = useMemo(() => {
    return {
      clientId:
        therapistActivityState.context.filterClientId ||
        parsedSearch.clientId ||
        '',
      realisticThoughts: [{ ...emptyBasicThought }],
      dayReminders: {
        morning: {
          time: { hours: 9, minutes: 0 },
          enabled: true,
        },
        day: {
          time: { hours: 14, minutes: 0 },
          enabled: true,
        },
        evening: {
          time: { hours: 21, minutes: 0 },
          enabled: true,
        },
      },
    };
  }, [parsedSearch.clientId, therapistActivityState.context.filterClientId]);

  const clientId = useWatch('clientId', form);

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const onSave = async () => {
    try {
      const res = await mutate({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'createPortalCard',
          fields: [{ thought: ['_id, updatedAt'] }],
          variables: {
            input: {
              name: 'input',
              type: 'PortalCardCreateInput',
              value: form.getFieldsValue(),
            },
          },
        },
      });
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (createData?.data?.thought?.updatedAt) {
      const handle1 = setTimeout(() => {
        setIsCardFlipped(true);
      }, 1500);

      const handle2 = setTimeout(() => {
        setIsCardFlipped(false);
      }, 4500);

      return () => {
        clearTimeout(handle1);
        clearTimeout(handle2);
      };
    }
  }, [createData?.data?.thought?.updatedAt]);

  useEffect(() => {
    if (createData?.data?.thought?._id) {
      const resource =
        t('pages.clientsShow.therapistOtherResources.portalCards') +
        '/' +
        (createData?.data?.thought._id.split('/')[1] || '');
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
      therapistActivitySend({
        type: 'CREATED',
        resource,
      });
    }
  }, [createData?.data?.thought?._id]);

  useActivityTracking(
    createData?.data?.thought?._id
      ? t('pages.clientsShow.therapistOtherResources.portalCards') +
          '/' +
          (createData?.data?.thought._id.split('/')[1] || '/create')
      : `${t('pages.clientsShow.therapistOtherResources.portalCards')}/create`,
  );

  return (
    <Create
      title={translate('pages.copingCards.create')}
      saveButtonProps={{
        onClick: onSave,
        disabled:
          isSubmitDisabled || fieldsCount < 2 || createData?.data?.thought._id,
      }}
    >
      <Form<CreateCopingCardForm>
        form={form}
        initialValues={initialValues}
        onFinish={(values) => {
          console.log('values', values);
        }}
        onFieldsChange={handleFormChange}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
        labelWrap
      >
        <Form.Item
          label={
            <>
              {translate('createThought.selectClient')}
              <Tooltip title={translate('createThought.selectClientTip')}>
                <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            </>
          }
          name="clientId"
          required
          rules={[validationRules]}
        >
          <Select
            showSearch
            placeholder={translate('createThought.selectClient')}
            optionFilterProp="children"
            loading={clientsLoading}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {
              // @ts-ignore
              ((clients?.data?.data as Client[]) || [])
                .filter((c) => {
                  return !!c.accessLevel;
                })
                .filter(
                  (c) =>
                    c.access?.allowEdit ||
                    (user?.db?.role === UserRole.THERAPIST_ADMIN &&
                      c.access?.allowAdminEdit),
                )
                .map((client) => (
                  <Select.Option key={client._id} value={client._id}>
                    {client.firstName +
                      ' ' +
                      client.lastName +
                      ' - (' +
                      client._id +
                      ')'}
                  </Select.Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label={translate('pages.copingCards.frontSide')}
          name="automaticThought"
          required
          rules={[validationRules]}
          tooltip={translate('pages.copingCards.frontSideTip')}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 8 }}
            placeholder={translate('pages.copingCards.frontSide')}
          />
        </Form.Item>

        <Form.List
          name="realisticThoughts"
          // @ts-ignore
          rules={[validationRules]}
        >
          {(fields, { add, remove }, errors) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      label={
                        index === 0
                          ? translate('pages.copingCards.backSide')
                          : ''
                      }
                      wrapperCol={{
                        offset: index === 0 ? 0 : 5,
                        span: 10,
                      }}
                      key={field.key}
                      required={index === 0}
                      tooltip={translate('pages.copingCards.backSideTip')}
                    >
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          {...field}
                          style={{ width: '100%' }}
                          name={[field.key, 'thought']}
                          rules={[
                            {
                              required: true,
                              message: translate(
                                'pages.copingCards.backSideRequired',
                              ),
                            },
                          ]}
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 8 }}
                            placeholder={translate(
                              'pages.copingCards.backSideTip',
                            )}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ margin: '5px 0 0 10px' }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  );
                })}
              </>
            );
          }}
        </Form.List>

        <Form.Item
          name="dayReminders"
          label={translate('createThought.timeRemindersTitle')}
          rules={[validationRules]}
        >
          <Table
            showHeader={false}
            columns={dayRemindersColumns}
            dataSource={dayRemindersData}
            pagination={false}
          />
        </Form.Item>

        {!createLoading &&
          (createData?.data?.thought?.updatedAt || !!createError) && (
            <Result
              status={createError ? 'error' : 'success'}
              title={
                createError
                  ? createError.message
                  : translate('pages.copingCards.createSuccess')
              }
              extra={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ width: 400 }}>
                    <ReactCardFlip
                      isFlipped={isCardFlipped}
                      flipDirection="horizontal"
                      flipSpeedBackToFront={2}
                      flipSpeedFrontToBack={2}
                    >
                      <Card
                        style={{ width: 400 }}
                        onClick={() => setIsCardFlipped(!isCardFlipped)}
                        bordered
                        hoverable
                        bodyStyle={{
                          minHeight: 200,
                          boxShadow: '0 0 10px #ccc',
                          background: '#45403e',
                          color: '#f5f1f1',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 10,
                        }}
                      >
                        {form.getFieldValue('automaticThought')}
                      </Card>

                      <Card
                        style={{ width: 400 }}
                        onClick={() => setIsCardFlipped(!isCardFlipped)}
                        bordered
                        hoverable
                        bodyStyle={{
                          minHeight: 200,
                          boxShadow: '0 0 10px #ccc',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 10,
                        }}
                      >
                        {form
                          .getFieldValue('realisticThoughts')
                          ?.map((t: any, idx: number) => {
                            return <p key={idx}>{t.thought}</p>;
                          })}
                      </Card>
                    </ReactCardFlip>
                  </div>
                </div>
              }
            />
          )}
        <Divider />
      </Form>
    </Create>
  );
};
