import { ArrowDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  ActivatingEvent,
  baseEmotionsEn,
  baseEmotionsRu,
  baseFeelingsEn,
  baseFeelingsRu,
  dbCols,
} from '@cc/schema';
import {
  Col,
  Create,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Slider,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { useActor } from '@xstate/react';
import Card from 'antd/lib/card/Card';
import { useWatch } from 'antd/lib/form/Form';
import { debounce } from 'lodash';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TherapistActivityReactContext } from '../../App';
import { useClientOptions } from '../../hooks/client-options';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { AbcModel } from '../../schema/be-graphql-generated';
import { colors } from '../../styles/colors';
import './styles.less';

const { Title } = Typography;

const { OptGroup, Option } = Select;

export const AbcModelCreate = () => {
  const { t, i18n } = useTranslation();
  const location = routerProvider.useLocation();

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const baseEmotions = useMemo(() => {
    const baseEmotions = currentLocale?.startsWith('ru')
      ? baseEmotionsRu
      : baseEmotionsEn;

    return baseEmotions.map((e) => ({
      ...e,
      children: e.children.map((ch) => ({ ...ch, strength: 50 })),
    }));
  }, [currentLocale]);

  const flattenedEmotions = useMemo(() => {
    return baseEmotions.reduce(
      (acc, val) => [...acc, ...val.children],
      [] as typeof baseEmotionsEn[0]['children'],
    );
  }, [baseEmotions]);

  const baseFeelings = currentLocale?.startsWith('ru')
    ? baseFeelingsRu
    : baseFeelingsEn;

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const { clientId: locationClientId, abcModelId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState.context.filterClientId ||
      (locationClientId as string),
  );

  const initialValues = {
    a: '' as string | undefined | null,
    b: '' as string | undefined | null,
    behaviors: '' as string | undefined | null,
    emotions: [] as ActivatingEvent['emotions'] | null[],
    feelings: [] as ActivatingEvent['feelings'] | null[],
  };

  const [form] = Form.useForm<typeof initialValues>();

  const emotions = useWatch('emotions', form);

  const [modelId, setModelId] = useState(abcModelId as string | undefined);

  useActivityTracking(modelId ? modelId : `${dbCols.abcModels}/create`);

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const {
    data: abcModelData,
    isLoading: abcModelLoading,
    refetch: refetchAbcModel,
    error: abcModelError,
  } = useCustom<AbcModel>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'abcModel',
      fields: [
        '_id',
        'a',
        'b',
        'behaviors',
        'feelings',
        { emotions: ['id', 'strength'] },
        'userId',
      ],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: modelId,
        },
      },
    },
  });

  useEffect(() => {
    if (abcModelData?.data) {
      form?.setFieldsValue({
        a: abcModelData?.data?.a,
        b: abcModelData?.data?.b,
        emotions: abcModelData?.data?.emotions || [],
        behaviors: abcModelData?.data?.behaviors || '',
        feelings: abcModelData?.data?.feelings || [],
      });
      setClientId(abcModelData?.data.userId);
      if (abcModelData?.data?._id) {
        therapistActivitySend({
          type: 'LOADED',
          resource: abcModelData?.data?._id,
          clientId: abcModelData?.data?.userId,
        });
      }
    }
  }, [abcModelData]);

  const {
    mutate: upsertAbcModel,
    data: upsertAbcModelData,
    isLoading: upsertAbcModelLoading,
    error: upsertAbcModelError,
  } = useCustomMutation<{ abcModel: AbcModel }>();

  const saveData = useCallback(
    async (data: typeof initialValues) => {
      if (!clientId) {
        return;
      }
      const res = await upsertAbcModel({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertAbcModel',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'AbcModelInput',
              value: {
                data,
                where: {
                  clientId,
                  _id: modelId,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.abcModel.saveSuccess'));
    },
    [clientId, modelId, t],
  );

  useEffect(() => {
    // @ts-ignore
    if (upsertAbcModelData?.data?._id) {
      if (!modelId) {
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: upsertAbcModelData?.data?._id,
        });

        // @ts-ignore
        setModelId(upsertAbcModelData?.data?._id);
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          resource: modelId,
        });
      }
    }
  }, [upsertAbcModelData]);

  // TODO: think if we should add extra logic for not allowing saving while first upsert is in progress
  const onValuesChange = useCallback(
    debounce(
      (
        changedValues: Partial<typeof initialValues>,
        allValues: typeof initialValues,
      ) => {
        saveData(allValues);
      },
      700,
    ),
    [saveData],
  );

  return (
    <Create
      title={
        <>
          {t('shared.abcModel.title')}

          <Tooltip
            title={t('shared.abcModel.description')}
            overlayClassName="abc-model--tooltip"
          >
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </>
      }
      isLoading={clientsLoading}
      saveButtonProps={{
        style: {
          display: 'none',
        },
      }}
    >
      <div className="abc-model-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="abc-model--client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
      </div>

      <Form<typeof initialValues>
        form={form}
        initialValues={{
          ...initialValues,
        }}
        onFinish={(values) => {}}
        onValuesChange={(a, b) => {
          onValuesChange(a, b);
        }}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        labelWrap
      >
        <Card
          title={t('shared.abcModel.a')}
          headStyle={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          <Form.Item
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            name="a"
            className="abc-model--row"
          >
            <Input.TextArea
              placeholder={t('shared.abcModel.aPlaceholder')}
              autoSize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Card>
        <div className="abc-models--question-container">
          <ArrowDownOutlined color={colors['--triad-blue-0']} />
        </div>

        <Card
          title={t('shared.abcModel.b')}
          headStyle={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          <Form.Item
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            name="b"
            className="abc-model--row"
          >
            <Input.TextArea
              placeholder={t('shared.abcModel.bPlaceholder')}
              autoSize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
        </Card>
        <div className="abc-models--question-container">
          <ArrowDownOutlined color={colors['--triad-blue-0']} />
        </div>

        <Card
          title={t('shared.abcModel.c')}
          headStyle={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          <Form.Item
            name={['behaviors']}
            label={t('shared.abcModel.behaviors')}
          >
            <Input.TextArea
              placeholder={t('shared.abcModel.behaviorsPlaceholder')}
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>

          <Divider />

          <Form.Item
            name={['emotions']}
            label={t('shared.abcModel.emotions')}
            getValueProps={(value) => {
              return value.id;
            }}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder={t('shared.abcModel.emotionsPlaceholder')}
              optionFilterProp="children"
              value={emotions?.map((e: any) => e.id)}
              onChange={(emotionsIds) => {
                const eventEmotions =
                  (emotionsIds as unknown as number[]).map((id) => {
                    const emotion = flattenedEmotions.find((e) => e.id === id);
                    if (emotion) {
                      // @ts-ignore
                      const existingEmotion = emotions.find(
                        (em: any) => em.id === id,
                      );
                      return {
                        id: emotion.id,
                        strength: !!existingEmotion
                          ? existingEmotion.strength
                          : 50,
                      };
                    }
                  }) || [];

                form.setFieldValue(['emotions'], eventEmotions);
                setTimeout(() => {
                  onValuesChange({}, form.getFieldsValue());
                }, 10);
              }}
            >
              {baseEmotions.map((eg) => {
                return (
                  <OptGroup label={eg.name} key={eg.name}>
                    {eg.children.map((e) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
            </Select>
          </Form.Item>

          {!!emotions?.length && (
            <Title level={5} style={{ textAlign: 'center' }}>
              {t('createThought.rateEmotions')}:
            </Title>
          )}

          <Form.List name={['emotions']}>
            {(fields, { add, remove }, errors) => {
              return (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Row key={field.key}>
                        <Col span={13} push={2}>
                          <Form.Item
                            {...field}
                            label={
                              flattenedEmotions.find(
                                (e) => e.id === emotions?.[field.key]?.id,
                              )?.name || ''
                            }
                            key={field.key}
                            name={[field.key, 'strength']}
                          >
                            <Slider min={1} max={100} />
                          </Form.Item>
                        </Col>
                        <Col span={1} offset={2}>
                          <Form.Item
                            {...field}
                            key={field.key}
                            name={[field.key, 'strength']}
                          >
                            <InputNumber min={1} max={100} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              );
            }}
          </Form.List>

          <Divider />

          <Form.Item
            label={t('shared.abcModel.sensations')}
            name={['feelings']}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder={t('shared.abcModel.sensationsPlaceholder')}
            >
              {baseFeelings.map((bg) => {
                return (
                  <OptGroup label={bg.name} key={bg.name}>
                    {bg.children.map((e) => {
                      return (
                        <Option key={e.id} value={e.id}>
                          {e.name}
                        </Option>
                      );
                    })}
                  </OptGroup>
                );
              })}
            </Select>
          </Form.Item>
        </Card>
      </Form>
    </Create>
  );
};
