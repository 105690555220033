import { CSSProperties } from 'react';
import { colors } from '../../styles/colors';

export const buttonContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

export const button: CSSProperties = {
  margin: '10px 30px',
};

export const requiredMark: CSSProperties = {
  color: colors['--primary-0'],
  position: 'absolute',
};
