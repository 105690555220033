import { translationRu } from './translationRu';

export const portalRu = {
  ...translationRu,
  pages: {
    main: {
      clients: 'Клиенты',
    },
    login: {
      chooseLanguage: 'Выберите язык',

      googleSignIn: 'Войти с Google',
      signin: 'Войти',
      signup: 'Подписаться',
      email: 'Емейл',
      password: 'Пароль',
      remember: 'Запомнить меня',
      forgotPassword: 'Забыли пароль?',
      title: 'Войти в учетную запись',
      noAccount: 'У Вас нет учетной записи?',
      providerPortal: 'Портал провайдера',
      or: 'или',
      resetPassword: 'Поменять пароль',
      sentText:
        'Мы отправили Вам электронное письмо со ссылкой для смены пароля, если у Вас была зарегистрированная учетная запись.',

      copywrite: '© 2023 CopingCard, Inc. Все права защищены.',
    },
    clientsShow: {
      generalTitle: 'Общая информация',
      baseStatsTitle: 'Информация об использовании',
      usageGraphs: 'Графики использования приложения',
      reminderdsTitle: 'Графики использования напоминаний',
      testsTitle: 'История результатов тестов PHQ-9 и GAD-7',
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'Email',
      accountCreated: 'Дата создания учётной записи',
      copingCardsCount: 'Создано копинг-карточек',
      enabledDayRemindersCount: 'Включенно напоминаний по времени',
      enabledLocationRemindersCount: 'Включенно геолокационных напоминаний',
      noData: 'Нет данных для отображения',
      phq9: 'PHQ-9',
      phq9Description: 'История результатов теста PHQ-9',
      gad7: 'GAD-7',
      gad7Description: 'История результатов теста GAD-7',
      triggered: 'Уведомлений сработало',
      reacted: 'Карточек просмотрено',
      crisisTitle: 'Использование кризисной секции',
      crisisDescription: 'История использования кризисной секции',
      activityLog: 'Журнал активности',
      clientActivityLog: 'Журнал активности клиента',
      therapistActivityLog: 'Журнал активности терапевта',
      activityCalendar: 'Календарь активности',
      daysEventsRecorded: 'дней с данными',
      eventsRecorded: 'событий записано',
      daysDataCollected: 'Дней с данными',
      clientActivity: 'Активность клиента',
      therapistActivity: 'Активность терапевта',
      therapistTimeSpend: 'Время, затраченное терапевтом',
      timeSpent: 'Затрачено времени',
      rmtAlert:
        'В зависимости от страховых компаний, с которыми Вы работаете, Вам может быть доступна возможность выставлять счета за на Удаленный терапевтический мониторинг (Remote Therapeutic Monitoring - RTM), если наше мобильное приложение собирет данные об активности клиента не менее чем за 16 дней в течение 30-дневного период для клиента. Пожалуйста, свяжитесь со своими страховыми компаниями для получения дополнительной информации или свяжитесь с нами по адресу support@copingcard.com для получения дополнительной информации.',
      rmtInfo: 'Информация о RTM',
      eventTypes: {
        0: 'создан',
        '0_f': 'создана',
        '0_n': 'создано',
        1: 'сработал',
        '1_f': 'сработала',
        '1_n': 'сработало',
        2: ', клиент отреагировал',
        3: 'копинг-карточка просмотрена',
        4: 'использовал',
        '4_n': 'использован',
        '4_f': 'использована',
        5: 'обновлено',
        '5_m': 'обновлен',
        '5_f': 'обновлена',
        6: 'загружен',
        7: 'просмотрено',
        '7_f': 'просмотрена',
        '7_m': 'просмотрен',
        8: 'сообщил',
        9: 'позвонил',
        10: 'переход на внешнюю ссылку',
        11: 'кликнул',
        12: 'вошел в систему',
      },
      featureFlags: {
        'featureFlags/1': 'поиск карточек',
        'featureFlags/2': 'чтение',
        'featureFlags/3': 'просмотр статистики',
        'featureFlags/4': 'геолокационное напоминание',
        'featureFlags/5': 'напоминание по времени',
        'featureFlags/6': 'тест PHQ-9(депрессия)',
        'featureFlags/7': 'тест GAD-7(тревога)',
        'featureFlags/8': 'медитация',
        'featureFlags/9': 'дерево принятия решений',
        'featureFlags/10': 'кризисная секция',
        'featureFlags/11': 'падающая стрела',
        'featureFlags/12': 'поведенческий эксперимент',
        'featureFlags/13': 'экспозиция',
        'featureFlags/14': 'ситуация экспозиции',
        'featureFlags/15': 'реакция на ситуацию экспозиции',
        'featureFlags/16': 'изображение ИИ',
        'featureFlags/17': 'видео',
        'featureFlags/18': 'вход в систему',
      },
      entityNames: {
        abcModels_one: 'модель ABC',
        abcModels_few: 'модели ABC',
        behaviorExperiments_one: 'поведенческий эксперимент',
        behaviorExperiments_few: 'поведенческие эксперименты',
        conceptualizations_one: 'концептуализация',
        conceptualizations_few: 'концептуализации',
        copingCards: 'копинг-карточки',
        copingCards_one: 'копинг-карточка',
        copingCards_few: 'копинг-карточки',
        dayReminders_one: 'напоминание по времени',
        dayReminders_few: 'напоминания по времени',
        downwardArrows_one: 'падающая стрела',
        downwardArrows_few: 'падающие стрелы',
        exposures_one: 'экспозиция',
        exposures_few: 'экспозиции',
        exposureStimuli_one: 'ситуация или триггер для экспозиции',
        exposureStimuli_few: 'ситуации или триггеры для экспозиции',
        locationsRelations_one: 'геолокационное напоминание',
        locationsRelations_few: 'геолокационные напоминания',
        supportingCycles_one: 'поддерживающий цикл',
        supportingCycles_few: 'поддерживающие циклы',
        thoughts: 'тесты дисфункциональных мыслей',
        thoughts_one: 'тест дисфункциональных мыслей',
        thoughts_few: 'тесты дисфункциональных мыслей',
        tests_one: 'тест',
        tests_few: 'тесты',
        worryTrees_one: 'дерево принятия решений',
        worryTrees_few: 'деревья принятия решений',
      },
      otherEvents: {
        privacyPolicyUpdated: 'настройки политики конфиденциальности обновлены',
        taken: 'пройден',
      },
      therapistActivityTypes: {
        '0_one': 'просмотрен',
        '0_f_one': 'просмотрена',
        '0_n_one': 'просмотрено',
        '0_other': 'просмотрены',
        '0_few': 'просмотрены',
        '1_one': 'создан',
        '1_f_one': 'создана',
        '1_n_one': 'создано',
        '1_other': 'созданы',
        '1_few': 'созданы',
        '2_one': 'редактирован',
        '2_f_one': 'редактирована',
        '2_n_one': 'редактировано',
        '2_other': 'редактированы',
        '2_few': 'редактированы',
        '3_one': 'удален',
        '3_f_one': 'удалена',
        '3_n_one': 'удалено',
        '3_other': 'удалены',
        '3_few': 'удалены',
      },
      therapistOtherResources: {
        clientInfo: {
          activityCalendar: 'календарь активности',
          activityLog: 'журнал активности',
          stats: 'статистика',
        },
        portalCards: 'portalCards',
      },
      screen: 'экран',
      duration: 'длительность',
      addActivityManually: 'Добавить активность вручную',
      minutes: 'минут',
      therapistCustomActivities: [
        'Телефонный звонок',
        'Видеозвонок',
        'Текстовое сообщение/чат',
        'Переписка по электронной почте',
      ],
      selectOrTypeActivity: 'Выберите или введите активность',
      activityRange: 'Дата и время начала и окончания активности',
      customActivity: 'Активность',
      showOffsessionData: 'Включить активность вне сессий',
      showInSessionData: 'Включить активность во время сессий',
    },
    sessions: {
      title: 'Сессии',
      tabs: [
        'Общая информация',
        // 'Домашнее задание (в разработке...)',
        'Заметки о прогрессе',
        // 'Заметки о терапии (в разработке...)',
      ],

      sessionRange: 'Дата и время начала и окончания сессии',
      duration: 'Длительность',
      noFinishDate: 'Нет даты окончания',

      upgradeNeeded: 'Требуется апгрейд аккаунта',
      upgradeNeededDescription: `Вам необходимо обновить свою учетную запись, чтобы иметь возможность использовать эту функцию. Пожалуйста, перейдите в раздел «Подписка», чтобы обновить свою учетную запись.`,
      gotToSubscription: 'Go to Subscription',

      saveSuccess: 'Сессия успешно сохранена',
      preferredPronounsLabel: 'Предпочитаемые местоимения',
      diagnosesLabel: 'Диагнозы (выберите не более 3-х)',
      diagnoses: ['Основной диагноз', 'Вторичный диагноз', 'Третичный диагноз'],
      selectOrSearch: 'Выберите диагноз(ы) (можно использовать поиск)',
      diagnosesLimit: 'Вы можете выбрать не более 3-х диагнозов',
      icd10Hint: 'Коды МКБ-10, версия 2023 года',
      progressNotesFormatLabel: 'Формат заметок о прогрессе',
      progressNotesFormats: [
        {
          name: 'SOAP',
          nonAbbreviated:
            'Субъективное (Subjective), Объективное (Objective), Оценка (Assessment), План (Plan)',
        },
        {
          name: 'DAP',
          nonAbbreviated: 'Данные (Data), Оценка (Assessment), План (Plan)',
        },
        {
          name: 'GIRP',
          nonAbbreviated:
            'Цель (Goal), Вмешательство (Intervention), Реакция (Response), План (Plan)',
        },
        {
          name: 'BIRP',
          nonAbbreviated:
            'Поведение (Behavior), Вмешательство (Intervention), Реакция (Response), План (Plan)',
        },
        {
          name: 'SIRP',
          nonAbbreviated:
            'Ситуация (Situation), Вмешательство (Intervention), Реакция (Response), Прогресс (Progress)',
        },
      ],
      sessionSettings: ['Office', 'Video', 'Phone', 'Text', 'Home Visit'],
      sessionSettingsLabel: 'Настройки сессии',
      placeOfServiceLabel: 'Место оказания услуги',
      placeOfServiceLabelTip: 'Коды Центров услуг Medicare и Medicaid (СMS)',
      placeOfServiceCodes: [
        {
          code: '02',
          name: 'Телемедицина, предоставляемая не в домашних условиях пациента',
          description:
            'Место, где оказываются медицинские услуги и услуги, связанные с здоровьем, с использованием телекоммуникационных технологий. Пациент не находится дома, когда получает медицинские услуги или услуги, связанные с здоровьем, с использованием телекоммуникационных технологий.',
        },
        {
          code: '03',
          name: 'Школа',
          description: 'Учреждение, целью которого является образование.',
        },
        {
          code: '10',
          name: 'Телемедицина, предоставляемая в домашних условиях пациента',
          description:
            'Место, где оказываются медицинские услуги и услуги, связанные с здоровьем, с использованием телекоммуникационных технологий. Пациент находится дома (это место, отличное от больницы или другого учреждения, где пациент получает медицинские услуги или услуги, связанные с здоровьем, в частном доме), когда получает медицинские услуги или услуги, связанные с здоровьем, с использованием телекоммуникационных технологий.',
        },
        {
          code: '11',
          name: 'Офис',
          description:
            'Местоположение, кроме больницы, учреждения квалифицированного сестринского ухода (SNF), военного лечебного учреждения, общественного медицинского центра, государственной или местной государственной поликлиники или учреждения промежуточного ухода (ICF), где медицинский работник регулярно проводит медицинские осмотры, диагностику и лечение. болезни или травмы в амбулаторных условиях.',
        },
        {
          code: '12',
          name: 'Дом',
          description:
            'Местоположение, отличное от больницы или другого учреждения, где пациент получает уход в собственном жилище.',
        },
        {
          code: '14',
          name: 'Групповой дом(приют, интернат)',
          description:
            'Жилой дом, с общими жилыми помещениями, где клиенты получают надзор и другие услуги, такие как социальные и / или поведенческие услуги, кастодиальные услуги и минимальные услуги (например, введение лекарств).',
        },
        {
          code: '18',
          name: 'Рабочее место',
          description:
            'Местоположение, не описанное ни одним другим кодом метоположения предоставления услуг, принадлежащее или управляемое государственным или частным лицом, где работает пациент, и где работник здравоохранения предоставляет постоянные или эпизодические профилактические, терапевтические или реабилитационные услуги отдельному лицу.',
        },
        {
          code: '51',
          name: 'Стационарное психиатрическое учреждение',
          description:
            'Учреждение, которое предоставляет стационарные психиатрические услуги для диагностики и лечения психических заболеваний круглосуточно, врачом или под его наблюдением.',
        },
        {
          code: '52',
          name: 'Психиатрическое учреждение - частичная госпитализация',
          description:
            'Учреждение для диагностики и лечения психических заболеваний, которое предоставляет запланированную терапевтическую программу для пациентов, которым не требуется полная госпитализация, но которым необходимы более широкие программы, чем это возможно при амбулаторных посещениях в больнице или учреждении, аффилированном с больницей.',
        },
        {
          code: '53',
          name: 'Общественный центр психического здоровья',
          description:
            'Учреждение, которое предоставляет следующие услуги: амбулаторные услуги, включая специализированные амбулаторные услуги для детей, пожилых людей, лиц, страдающих хроническими заболеваниями, и жителей района услуг по психическому здоровью, которые были выписаны из стационарного лечения в учреждении по психическому здоровью; услуги неотложной помощи круглосуточно; амбулаторное лечение, другие услуги частичной госпитализации или услуги психосоциальной реабилитации; скрининг пациентов, которые рассматриваются для госпитализации в государственные учреждения по психическому здоровью, для определения целесообразности такой госпитализации; и консультационные и образовательные услуги.',
        },
        {
          code: '54',
          name: 'Учреждение промежуточного ухода за лицами с интеллектуальными нарушениями',
          description:
            'Учреждение, которое в основном предоставляет уход, связанный со здравоохранением, и услуги выше уровня кастодиального ухода для отдельных лиц, но не предоставляет уровень ухода или лечения, доступного в больнице или центре квалифицированного сестринского ухода.',
        },
        {
          code: '55',
          name: 'Стационарное наркологическое учреждение',
          description:
            'Учреждение, которое предоставляет лечение для злоупотребления психоактивными веществами (алкоголем и наркотиками) для проживающих в нём жителей, которым не требуется острый медицинский уход. Услуги включают индивидуальную и групповую терапию и консультации, семейные консультации, лабораторные исследования, лекарства и расходные материалы, психологическое тестирование и проживание.',
        },
        {
          code: '56',
          name: 'Психиатрический стационарный лечебный центр',
          description:
            'Учреждение или отдельная часть учреждения для психиатрического ухода, которое предоставляет полностью планируемую терапию и профессионально обеспеченную среду для проживания и обучения в течение 24 часов.',
        },
        {
          code: '57',
          name: 'Амбулаторное наркологическое учреждение',
          description:
            'Место, где проводится амбулаторное лечение от злоупотребления психоактивными веществами (алкоголем и наркотиками). Услуги включают индивидуальную и групповую терапию и консультирование, семейное консультирование, лабораторные анализы, лекарства и расходные материалы, а также психологическое тестирование.',
        },
        {
          code: '58',
          name: 'Амбулаторное учреждение для лечения опиоидов',
          description:
            'Место, которое предоставляет лечение для наркомании на амбулаторной основе. Услуги включают метадон и другие формы лечения с помощью лекарств (MAT).',
        },
        {
          code: '99',
          name: 'Другое место оказания услуг',
          description: 'Другое место оказания услуг, не указанное выше.',
        },
      ],
      shortSummaryLabel: 'Краткие примечания/резюме',
      shortSummaryPlaceholder:
        'Вы можете добавить краткие заметки или дополнительную информацию о сеансе. Вы можете упомянуть цели, задачи, стратегии, методы и методы плана лечения, проблемы клиента, лекарства и т. д.',
      clientProgressOptionsEn: [
        `Progressing`,
        `No Substantial Change`,
        `Worsening`,
      ],

      clientProgressLabel: 'Прогресс клиента',
      clientProgressOptions: [
        `Улучшение`,
        `Нет существенных изменений`,
        `Ухудшение`,
      ],
      clientMentalStatus: 'Психическое состояние клиента',
      clientMentalStatusTipEn: 'Select options that apply and are important',
      clientMentalStatusTip: 'Выберите подходящие и важные варианты',
      appearanceLabel: 'Внешний вид',
      appearance: [
        'Ухоженный',
        'Аккуратно одетый',
        'Неформально одетый',
        'Плохо ухоженный',
        'Неподходяще одетый',
        'Растрепанный',
      ],
      attitudeLabel: 'Отношение',
      attitude: [
        'Сотрудничающий',
        'Дружелюбный',
        'Заинтересованный',
        'Сопротивляющийся',
        'Осторожный',
        'Враждебный',
        'Апатичный',
      ],
      moodLabel: 'Настроение',
      mood: [
        'Грустный',
        'Тревожный',
        'Злой',
        'Раздражительный',
        'Эутимический (стабильный)',
        'Приподнятое настроение',
        'Дисфорический',
      ],
      affectLabel: 'Аффект',
      affect: [
        'Адекватный',
        'Ровный',
        'Притупленный',
        'Ограниченный',
        'Лабильный',
        'Соответствующий',
        'Несоответствующий',
      ],
      speechLabel: 'Речь',
      speech: [
        'Нормальный темп',
        'Медленная',
        'Быстрая',
        'Напряженная',
        'Тихая',
        'Громкая',
        'Неразборчивая',
      ],
      thoughtProcessLabel: 'Мыслительный процесс',
      thoughtProcess: [
        'Логичный',
        'Связный',
        'Целенаправленный',
        'Дезорганизованный',
        'Косвенный',
        'Обстоятельный',
        'Рассеянный',
      ],
      perceptionLabel: 'Восприятие',
      perception: [
        'Без галлюцинаций',
        'Слуховые галлюцинации',
        'Визуальные галлюцинации',
        'Тактильные галлюцинации',
        'Обонятельные галлюцинации',
        'Иллюзии',
        'Деперсонализация/дереализация',
      ],
      orientationLabel: 'Ориентированность',
      orientation: [
        'Ориентирован во времени, месте, личности и ситуации',
        'Дезориентирован во времени',
        'Дезориентирован в пространстве',
        'Дезориентирован в личности',
        'Дезориентирован в ситуации',
      ],
      memoryLabel: 'Память',
      memory: [
        'Целостная кратковременная память',
        'Целостная долговременная память',
        'Нарушена кратковременная память',
        'Нарушена долговременная память',
        'Конфабуляция',
      ],
      concentrationLabel: 'Концентрация',
      concentration: [
        'Хорошая концентрация',
        'Легкие трудности с концентрацией',
        'Умеренные трудности с концентрацией',
        'Тяжелые трудности с концентрацией',
      ],
      insightLabel: 'Проницательность',
      insight: [
        'Хорошее понимание',
        'Частичное понимание',
        'Плохое понимание',
        'Отсутствие понимания',
        'Отрицание болезни',
      ],
      detectedInterventionsTitle:
        'Использованные вмешательства, которые мы смогли определить',
      detectedInterventionsLabel:
        'Автоматически выявленные вмешательства (Текст не копируется в финальную заметку, он написан и используется как подсказка для ИИ).',
      detectedInterventionsHint:
        'Мы определили некоторые вмешательства, которые Вы использовали во время сессии, и создали краткое резюме. Резюме можно использовать как подсказку для ИИ, чтобы сгенерировать заметку более высокого качества и детализации.',
      interventionsLabel: 'Другие Использованные вмешательства',
      interventionsHint:
        'Выберите вмешательства, которые Вы использовали. Мы предварительно выбрали вмешательства, которые Вы использовали в нашей платформе во время этой сессии.',
      interventionsEducationLabel: 'Образование',
      interventionsEducation: [
        'Объяснение лекарств и вариантов лечения',
        'Предоставление информации о психических расстройствах',
        'Психообразование',
        'Обзор плана лечения/прогресса',
      ],
      interventionsCognitiveLabel: 'Когнитивные техники',
      interventionsCognitive: [
        'Модель ABC',
        'Поведенческий эксперимент',
        'Когнитивная реструктуризация',
        'Копинг-карточки',
        'Тест дисфункциональных мыслей',
        'Падающая стрела',
        'Дневник мыслей',
        'Поддерживающие циклы',
        'Древо принятия решений',
      ],
      interventionsBehavioralActivationLabel: 'Поведенческая активация',
      interventionsBehavioralActivation: [
        'Планирование активности',
        'Постановка целей',
        'Выявление ценностей',
      ],
      interventionsExposuresLabel: 'Экспозиции',
      interventionsExposures: [
        'Экспозиция с предотвращением реакции',
        'Десенсибилизация и переработка движением глаз (ДПДГ, англ. EMDR)',
        'Градуированная экспозиция',
        'Систематическая десенсибилизация',
      ],
      interventionsSkillsTrainingLabel: 'Тренировка навыков',
      interventionsSkillsTraining: [
        'Принятие',
        'Управление гневом',
        'Тренинг ассертивности',
        'Тренинг навыков общения',
        'Толерантность к дистрессу',
        'Эмоциональная регуляция',
        'Навык решения проблем',
        'Генерализация навыков',
        'Тренинг социальных навыков',
      ],
      interventionsRelaxationLabel: 'Расслабление и осознанность',
      interventionsRelaxation: [
        'Автогенная тренировка',
        'Сканирование тела',
        'Глубокое дыхание',
        'Техника заземления (5-4-3-2-1)',
        'Направленная визуализация',
        'Нырятельный рефлекс млекопитающих',
        'Медитация',
        'Прогрессивная мышечная релаксация',
      ],
      interventionsOtherLabel: 'Другие вмешательства',
      interventionsOtherLabelPlaceholder: 'Введите другие вмешательства',

      readyNote: 'Заметка о прогрессе',
      readyNoteHintEn:
        'Use this are to review and edit AI-generated note or write your own note.',
      readyNoteHint:
        'Используйте это поле для редактирования заметки, сгенерированной ИИ, или напишите свою собственную заметку.',
      generateNote: 'Сгенерировать заметку c ИИ',
      saveAsPdfButton: 'Сохранить как PDF',
      confirmChange:
        'Если Вы измените формат, Вы потеряете уже сгенерированную или введенную вручную заметку. Вы уверены, что хотите изменить формат?',
      confirmGenerateChange:
        'Если Вы сгенерируете заметку, Вы потеряете уже созданную или введенную вручную заметку. Вы уверены, что хотите заново сгенерировать заметку?',
      warning: 'Внимание',
      progressNoteSaveSuccess: 'Заметка о прогрессе успешно сохранена',
      generationTips: [
        `Заметка генерируется с использование искусственного интеллекта. Это может занять некоторое время.`,
        `Обычно искусственный интеллект отлично справляется с созданием заметки на основе предоставленных данных и данных, которые мы собираем.`,
        `Но ИИ не идеален и не является Вашей заменой, это просто Ваш помощник.`,
        `Просмотрите созданную заметку и внесите необходимые изменения.`,
        `Убедитесь, что ИИ не добавил ничего, что не произошло во время сеанса, поскольку иногда (очень редко) это может произойти. Данное явление известно под понятием «галлюцинации ИИ».`,
      ],
      gptNotContentFilteredErrorTitle: 'Ошибка генерации контента',
      gptNoteContentFilteredErrorDescription:
        'Сгенерированный ИИ контент отфильтрован из-за политики провайдера ИИ, которая запрещает использование контента, связанного с ненавистью, самоповреждением, сексом или насилием. Мы понимаем, что такой контент может быть важен для специалистов в области психического здоровья, и работаем с нашим провайдером, чтобы решить эту проблему. Мы приносим извинения за временные неудобства.',
    },
    signUp: {
      title: 'Создать учетную запись',
      googleSignIn: 'Создать бесплатный аккаунт c Google',
      signup: 'Создать бесплатный аккаунт',
      email: 'Емейл',
      password: 'Пароль',
      retypePassword: 'Подтвердить Пароль',
      haveAccount: 'Уже есть аккаунт?',
      or: 'или',
    },
    copingCards: {
      title: 'Копинг-карточки',
      frontSide: 'Передняя сторона',
      frontSideTip:
        'Может быть чем угодно, например, автоматическая негативная мысль, ситуация, старая привычка и т. д.',
      backSide: 'Задняя сторона',
      backSideTip:
        'Может быть чем угодно, например, реалистичная мысль, копинг-стратегия, новая привычка и т. д.',
      timeBasedReminders: 'Напоминания по времени',
      timeBasedRemindersTip:
        'Только Клиент может изменять эти напоминания в мобильном приложении',
      create: 'Создать Копинг-карточку для Клиента',
      backSideRequired: 'Задняя сторона обязательна',
      createSuccess: 'Копинг-карточка успешно создана',
      day: 'День',
      morning: 'Утро',
      evening: 'Вечер',
    },
    thoughtList: {
      title: 'Тест дисфункциональных мыслей',
      activatingEvent: 'Активирующее событие',
      updatedAt: 'Обновлено',
      automaticThought: 'Автоматическая мысль',
      userId: 'ID Клиента',
      thoughtsForAlternativeThoughts: 'Альтернативное объяснение',
      thoughtsFor: 'Факты, доказывающие мысль',
      thoughtsAgainst: 'Факты, опровергающие мысль',
      realisticThoughts: 'Реалистичные мысли',
      thoughtEditLimitTip:
        'Вы можете редактировать мысли только для Клиентов с Премиум-аккаунтом',
      thoughtEditNoPermissionTip:
        'У Вас нет прав для редактирования этой мысли',
    },
    error: {
      'info':
        'Возможно, Вы забыли добавить компонент {{action}} в ресурс {{resource}}.',
      '404': 'Извините, страница, которую Вы ввели, не существует.',
      'resource404': 'Вы уверены, что создали ресурс {{resource}}.',
      'backHome': 'На главную',
    },
    verifyEmail: {
      verifyEmail: 'Подтверждение Email',
      sentText:
        'Мы отправили Вам электронное письмо с ссылкой для подтверждения Вашего адреса электронной почты на адрес: ',
      contactSupport:
        'Если Вы не получили электронное письмо с подтверждением в течение 10 минут или у Вас возникли проблемы с подтверждением, обратитесь в службу поддержки по адресу:',
    },
    resetEmail: {
      resetSuccess: 'Сброс пароля выполнен успешно',
      resetError: 'Ошибка сброса пароля',
    },
    initialSetup: {
      title: 'Первоначальная настройка',
      nextStep: 'Далее',
      previousStep: 'Назад',
      finish: 'Закончить',
      selectOrType: 'Выберите или введите',
      step0: {
        title: 'Конфиденциальность и условия использования',
        description:
          'Пожалуйста, прочитайте и примите следующие юридические документы, чтобы иметь возможность использовать Портал провайдера.',
        title2: 'Дополнительные настройки',
      },
      step1: {
        title: 'Личная информация',
        description: 'Пожалуйста, предоставьте информацию о себе',
        prefix: 'Префикс / титул',
        firstName: 'Имя',
        lastName: 'Фамилия',
        phone: 'Телефон',
        extension: 'Добавочный номер',
        country: 'Страна',
        practiceSize: 'Специалистов в Вашей практике',
        clientInviteToken: 'Код приглашения от клиента',
        clientInviteTokenPlaceholder:
          'Введите код, если Вы получили его от клиента',
      },
      step2: {
        saasAgreement: 'Соглашение поставщика психотерапевтических услуг',
        readSaasAgreement: 'Прочитать и принять соглашение',
        title: 'Информация о практике',
        isPracticeAdded:
          'У Вашей практики уже есть учетная запись на портале CopingCard?',
        practiceAddedInfo: `
Вам необходимо ввести код приглашения для Вашей практики, чтобы мы могли подключить к нему вашу учетную запись.

Если у Вас нет кода приглашения, обратитесь к администратору практики, чтобы получить его.
          `,
        description: 'Пожалуйста, предоставьте информацию о Вашей практике',
        practiceInviteToken: 'Код приглашения практики',
        practiceInviteTokenPlaceholder: '',
        npi: 'Национальный номер провайдера (NPI)',
        practiceName: 'Название практики',
        practicePhone: 'Телефон практики',
        extension: 'Добавочный номер',
        practiceLogo: 'Логотип практики',
        practiceUrl: 'URL-адрес практики',
        practiceEmail: 'Электронная почта практики',
        practiceDescription: 'Описание практики',
        locationInfo:
          'Если в Вашей практике несколько офисов, позже Вы сможете добавить их в разделе Практика -> Офисы.',
        practiceAddress1: 'Адрес',
        practiceAddress2: 'Адрес (продолжение)',
        practiceCity: 'Город',
        practiceState: 'Штат/область/регион',
        practiceZip: 'Почтовый индекс',
        practiceCountry: 'Страна',
        acceptedInsurances: 'Принимаемые страховые компании',
        acceptedInsurancesText:
          'Пожалуйста, выберите или напечатайте названия страховых компаний, с которыми работает Ваша практика.',
        tokenNotFound: 'Код приглашения не найден',
        tokenTryAgain:
          'Убедитесь, что Вы правильно ввели код приглашения, или запросите новый код и повторите попытку.',
        tokenSuccess: 'Мы успешно нашли данный код и добавили Вас в практику.',
        redirect:
          'Вы будете автоматически перенаправлены на страницу клиентов через 5 секунд.',
        practiceSuccess: 'Практика успешно добавлена',
      },
    },

    conceptualizations: {
      conceptualizations: 'Концептуализации',
      userId: 'ID Клиента',
    },
    conceptualization: {
      conceptualizations: 'Концептуализации',
      title: 'Формулирование случая / концептуализация',
      clientProfile: 'Профиль клиента',

      showInfo: 'Показать личные данные клиента',
      hideInfo: 'Скрыть личные данные клиента',
      piiTip: `
Информация в этой секции по умолчанию скрыта для защиты личной информации клиентов, например от утечки во время сеанса телетерапии.

Информация снова автоматически скрывается через 1 минуту после ее отображения.
`,
      saveSuccess: 'Формулирование случая/Концептуализация успешно сохранена',
      saveError: 'Ошибка сохранения формулирования случая/концептуализации',
      fields: {
        firstName: 'Имя',
        lastName: 'Фамилия',
        gender: 'Пол',
        dob: 'Дата рождения',
        education: 'Образование',
        maritalStatus: 'Семейное положение',
        clientProfileOther: 'Другая релевантная информация',
        negativePastExperience: 'Негативный опыт в прошлом',
        triggerEvent: 'Критическое событие',
        geneticPredisposition: 'Физические и генетические предпосылки',
        deepBeliefs: 'Глубинные убеждения',
        triggers: 'Триггеры',
        problems: 'Проблемы',
        lifeRules: 'Промежуточные убеждения (правила жизни)',
        goals: 'Цели',
        resources: 'Ресурсы',
      },
      genderOptions: ['Мужской', 'Женский', 'Другой'],
      maritalStatusOptions: [
        'Одинокий/Одинокая',
        'Женатый/Замужняя',
        'В разводе',
        'Вдова/Вдовец',
        'Разлученный/Разлученная',
      ],
      educationOptions: [
        'High School',
        'Some College',
        'College Graduate',
        'Post Graduate',
      ],
      deepBeliefsPlaceholder:
        'Введите глубинные убеждения или выберите Схемы из списка',
      schemaSearch: 'Выбрать схемы',
      schemas: {
        'Disconnection / Rejection': {
          name: 'Нарушение привязанности / отвержение',
          tip: 'Нарушение привязанности / отвержение',
          children: [
            {
              name: 'Покинутость / Нестабильность (AB)',
              tip: 'Ощущение неуверенности в наличии надежной поддержки и связи. Включает в себя убеждение, что близкие вам люди не могут предоставлять постоянную эмоциональную поддержку, связь или практическую защиту из-за их собственной эмоциональной неустойчивости и непредсказуемости, ненадежности или непостоянства. Это также может быть вызвано страхом их скорой смерти или брошенности в пользу кого-то лучшего.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: 'Склонен выбирать партнеров и близких, которые недоступны или непредсказуемы.',
                },
                {
                  name: 'Избегание',
                  tip: `
1. Избегает близких отношений из-за страха быть брошенным.
2. Проявляет инициативу в расторжении отношений, чтобы предотвратить брошенность.
3. Отстраняется в ответ на даже незначительное отвержение или разрыв.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Постоянно следит за близкими.
2. Поддерживает постоянную связь с близкими.
3. Испытывает гнев и ревность в ответ на даже незначительное отвержение или расставание.
                `,
                },
              ],
            },
            {
              name: 'Недоверие / ожидание жестокого обращения (MA)',
              tip: 'Ожидает, что другие причинят вред через злоупотребление, унижение, обман, манипуляцию или эксплуатацию. Считает, что причиненный вред намеренный или результат необоснованной и чрезмерной небрежности. Может привести к чувству того, что его постоянно используют.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Выбирает партнеров и отношения с теми, кто не заслуживает доверия.
2. Постоянно проявляет чрезмерную осторожность и недоверие к другим.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Избегает близкого взаимодействия с другими как в личной, так и профессиональной жизни.
2. Никогда не раскрывает себя.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Имеет тенденцию плохо обращаться с другими или использовать их.`,
                },
              ],
            },
            {
              name: 'Эмоциональная депривация (ED)',
              tip: `
Ожидание того, что другие не будут удовлетворять разумные потребности в эмоциональной поддержке.
Три основных формы эмоциональной депривации включают:
A. Депривация заботы: недостаток заботы, тепла, внимания или компаньонства.
B. Депривация эмпатии: отсутствие понимания, активного слушания, раскрытия своих чувств или обмена эмоциями.
C. Депривация защиты: недоступность направления, силы или руководства от других.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `Выбирает холодных, отстраненных партнеров и значимых других.`,
                },
                {
                  name: 'Избегание',
                  tip: `Избегает близких эмоциональных отношений.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Требует, чтобы другие удовлетворяли все их потребности.
2. Влюбляется быстро, но теряет интерес так же быстро, чувствуя, что им никогда не дают достаточно."
`,
                },
              ],
            },
            {
              name: 'Дефективность / Стыд (DS)',
              tip: `Чувство того, что вы недостаточно хороши, недостаточно квалифицированы или неполноценны в важных областях, или что ваши несовершенства приведут к нелюбви тех, кто вам важен. Это может включать в себя чрезмерную чувствительность к критике, отказу и обвинениям, застенчивость в присутствии других и тенденцию сравнивать себя с другими, что может привести к неуверенности. Это может быть связано с предполагаемыми недостатками, будь то личными, такими как эгоизм, нежелательными желаниями, или физическими, такими как непривлекательный внешний вид или социальная неловкость.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Склонность выбирать отношения с критичными партнерами.
2. Тенденция к самоуничижению.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Имеет трудности с открытым выражением своих мыслей и эмоций.
2. Склонность к высокой степени самоконтроля и избеганию импульсивных поступков.
3. Имеет трудности с формированием близких отношений с другими людьми.
4. Тенденция к частой смене отношений, друзей и социальных групп.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Склонность осуждать или презирать других.
2. Сильное желание представить себя безупречным и чрезмерная забота о своей физической привлекательности.
3. Тенденция к стремлению к совершенству в своих действиях, эмоциях и мыслях.
`,
                },
              ],
            },
            {
              name: 'Социальная изоляция / отчуждение (SI)',
              tip: `Чувство отрыва от других, чувство уникальности и отделенности от остального общества, и отсутствие принадлежности к какой-либо конкретной группе или сообществу.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `Присоединяется к группе, но сохраняет чувство отчуждения и одиночества.`,
                },
                {
                  name: 'Избегание',
                  tip: `Склонность отстраняться от социальных ситуаций и предпочитать проводить значительное время в одиночестве.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Пытается соответствовать другим, но все же испытывает чувства различия и отделенности.`,
                },
              ],
            },
          ],
        },
        'Impaired Autonomy and/or Performance': {
          name: 'Ухудшенная автономия и/или производительность',
          tip: 'Ухудшенная автономия и/или производительность',
          children: [
            {
              name: 'Зависимость / Беспомощность (DI)',
              tip: 'Чувство неспособности эффективно управлять повседневными задачами и обязанностями без сильной зависимости от других для поддержки. Это может принимать форму чувства беспомощности.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Часто запрашивает помощь.
2. Ищет подтверждения от других, нерешителен.
3. Выбирает партнеров, которые склонны к деспотизму и чрезмерной помощи, берущих на себя все задачи.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Склонен откладывать принятие решений.
2. Воздерживается от действий автономно и от выполнения типичных взрослых обязанностей.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Демонстрирует чрезмерную уверенность в себе, даже когда было бы нормально и разумно обратиться за помощью к другим.`,
                },
              ],
            },
            {
              name: 'Уязвимость к вреду и болезням (VH)',
              tip: 'Интенсивное, необоснованное и постоянное чувство, что катастрофа неизбежна, и что вы бессильны ее предотвратить. Это может включать беспокойство о медицинских чрезвычайных ситуациях, таких как сердечные приступы или болезни, СПИД, нервные срывы и внешние события, такие как поломки лифтов, преступления, авиакатастрофы или землетрясения.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Постоянно испытывает беспокойство из-за потенциальных катастроф, которые могут произойти с ним/ней.
2. Регулярно просит других людей об успокоении и утешении.`,
                },
                {
                  name: 'Избегание',
                  tip: 'Избегает ситуаций, которые воспринимаются как рискованные или опасные.',
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Полагается на магическое мышление и ритуалы.
2. Действует импульсивно и рискованно.
`,
                },
              ],
            },
            {
              name: 'Взаимозависимость / Неразвитая идентичность (EM)',
              tip: `Интенсивная эмоциональная связь и зависимость от одного или нескольких ключевых лиц (часто родителей), которая препятствует развитию отдельной идентичности и нормальному социальному росту. Это часто связано с убеждением, что один человек не может функционировать или быть довольным без постоянного присутствия другого. Может также включать чувство подавленности или слияния с другими людьми или недостаточную индивидуальную идентичность. Это может привести к чувству бесцельности и пустоты, а в тяжелых случаях - к неопределенности относительно своей цели в жизни.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Отзеркаливает поведение важного человека в своей жизни.
2. Постоянно уточняет точку зрения других и часто предпочитает, чтобы они взяли на себя контроль над ситуацией.`,
                },
                {
                  name: 'Избегание',
                  tip: `Избегает отношений с людьми, которые не готовы к слиянию или близкой связи.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Устанавливает чрезмерно независимый образ жизни.`,
                },
              ],
            },
            {
              name: 'Неуспешность (FA)',
              tip: `Восприятие себя как человека, который оказался неспособным достичь успеха, обреченный на провал или имеющий врожденные недостатки по сравнению со своими сверстниками в таких областях, как успеваемость, профессиональные достижения, спортивные способности и т.д. Это часто включает мысли о том, что вы недостаточно умны, неумелы, неодаренны, неинформированы и имеете более низкий статус или уровень успеха, чем другие.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Не реализует свои возможности.
2. Самостоятельно саботирует свои рабочие достижения, когда они вот-вот достигнут своих целей.
3. Несправедливо сравнивает свои достижения с достижениями других, часто преуменьшая свои собственные успехи.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Откладывает задачи, прокрастинирует и откладывает все до последнего момента.
2. Избегает брать на себя новые или сложные задачи, предпочитая придерживаться знакомых и легких.
3. Часто ставит карьерные цели, которые ниже его потенциала, и избегает стремиться к более высоким должностям или сложным возможностям.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Проявляет потребность работать чрезмерно.
2. Имеет тенденцию преуменьшать достижения других, часто сравнивая их со своими.`,
                },
              ],
            },
          ],
        },
        'Impaired Limits': {
          name: 'Нарушение границ',
          tip: 'Нарушение границ',
          children: [
            {
              name: 'Привилегированность / Грандиозность (ET)',
              tip: `Вера в свое превосходство над другими, часто приводящая к ощущению права на особые права и привилегии и игнорированию правил социального взаимодействия. Такое мышление может включать в себя настаивание на том, чтобы иметь или делать все, что хочется, даже если это нереалистично, неразумно или вредно для других. В качестве альтернативы, это может включать чрезмерную сосредоточенность на достижении превосходства, например, на достижении большого успеха, известности или богатства, чтобы получить власть и контроль, а не внимания или одобрения. Этот тип мышления иногда может проявляться как чрезмерная конкуренция или желание доминировать над другими, включая проявление власти, навязывание собственных взглядов или контроль над поведением других без учета их потребностей или чувств.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Это поведение приводит к созданию неравных и отстранённых отношений как с партнерами, так и с другими важными людьми.
2. Проявляют эгоистичное поведение, игнорируя потребности и эмоции других.
3. Ведут себя высокомерно.
4. Конкурируют и стремятся к доминированию.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Склонны избегать ситуаций, где они не могут превзойти других или привлечь внимание к себе.
2. Выборочно вступают в отношения с людьми, которых воспринимают как менее значимых.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Они либо дарят роскошные и дорогие подарки, либо жертвуют больше денег на благотворительные цели, чем позволяет их бюджет.`,
                },
              ],
            },
            {
              name: 'Недостаточный самоконтроль / самодисциплина (IS)',
              tip: 'Это относится к широко распространенным трудностям или неспособности к достижению личных целей путем необходимого контроля над собой и терпения во фрустрации, либо сдерживанию излишнего эмоционального выражения и импульсов. В менее тяжелой форме пациент проявляет повышенное внимание к избеганию дискомфорта: избегание боли, конфликтов, столкновений, ответственности или перенапряжения - даже если это означает жертву личного удовлетворения, приверженности или целостности.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Небрежно выполняют утомительные или неприятные задачи.
2. Регулярно борятся с управлением своими эмоциями и злоупотребляют едой и алкоголем.
3. Участвуют в азартных играх или употребляют наркотики исключительно для получения удовольствия.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Они либо безработные, либо прервали свое образование.
2. Не имеют долгосрочных карьерных целей.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Прилагают значительные, краткосрочные усилия для завершения проекта или проявления самодисциплины.`,
                },
              ],
            },
          ],
        },
        'Other-Directedness': {
          name: 'Ориентированность на других',
          tip: 'Ориентированность на других',
          children: [
            {
              name: 'Покорность (SB)',
              tip: `
Чрезмерная передача контроля другим из-за чувства принуждения, часто чтобы избежать гнева, мести или оставления.
Существует два основных типа подчинения:
A) Подавление потребностей: когда человек подавляет свои собственные предпочтения, решения и желания.
B) Подавление эмоций: когда человек подавляет эмоциональное выражение, особенно злость.
Это часто связано с ощущением, что чьи-то желания, мнения и чувства недействительны или важны для других. Это может привести к чрезмерной уступчивости и ощущению себя в ловушке, что может привести к накоплению гнева и неадекватным симптомам, таким как пассивно-агрессивное поведение, неконтролируемые вспышки гнева, психосоматические симптомы, отказ от привязанности, «отыгрывание» и злоупотребление психоактивными веществами.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Выбирают доминирующих, контролирующих партнеров и значимых других и подчиняются их желаниям.
2. Не выражают свои желания напрямую, а только намекают на них.`,
                },
                {
                  name: 'Избегание',
                  tip: `
1. Склонны избегать вступления в отношения вообще.
2. Избегают ситуаций, где их желания и потребности противоречат желаниям и потребностям других людей.
3. Не выражают сочувствие.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `
1. Проявляют пассивно-агрессивное или бунтарское поведение.
2. Испытывают внезапные приступы необузданной злости.
3. Имеют тенденцию опаздывать и прокрастинировать.
4. Принимают на себя обязательства, но не выполняют их и используют оправдания для оправдания своего поведения.`,
                },
              ],
            },
            {
              name: 'Самопожертвование (SS)',
              tip: `Относится к чрезмерному приоритету удовлетворения потребностей других над собственными потребностями, что часто приводит к отсутствию личного удовлетворения. Такое поведение обычно обусловлено желанием не причинять боль другим, чувством вины или эгоизма или необходимостью поддерживать связи с предполагаемыми нуждающимися людьми. Такое поведение может быть связано с повышенной чувствительностью к боли других, но может привести к ощущению личного пренебрежения и обиды на тех, о ком заботятся.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `Склонны заботиться о других, часто отдают больше, чем получают взамен, и пренебрегают своими потребностями.`,
                },
                {
                  name: 'Избегание',
                  tip: `Избегают близких отношений.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Раздражаются на тех, кто важен для них, за то, что не оказывает взаимность или не проявляет благодарности, что приводит к решению прекратить оказывать им помощь.`,
                },
              ],
            },
            {
              name: 'Поиск одобрения / признания (AS)',
              tip: `Сильное желание одобрения, признания или внимания от других людей за счет своего подлинного самоощущения. Самооценка в основном зависит от внешнего одобрения, а не от внутренних ценностей. Это может привести к чрезмерному фокусу на статус, внешность, достижения или социальное принятие в качестве средства получения одобрения или восхищения. Часто приводит к принятию решений, которые не являются подлинными или не приносят удовлетворения, а также к чувствительности к отказам.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Привлекает внимание других к своим достижениям.
2. При принятии важных жизненных решений ставит признание выше собственных потребностей.`,
                },
                {
                  name: 'Избегание',
                  tip: `Воздерживается от установления отношений с людьми, которых они уважают, из-за боязни не получить их одобрения.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Привлекает внимание, ведет себя скандально.`,
                },
              ],
            },
          ],
        },
        'Overvigilance / Inhibition': {
          name: 'Чрезмерная бдительность / ингибиция',
          tip: 'Чрезмерная бдительность / ингибиция',
          children: [
            {
              name: 'Негативизм / Пессимизм (NP)',
              tip: `Постоянная и всепроникающая тенденция фокусироваться на негативных аспектах жизни, таких как боль, смерть, потеря, разочарование, конфликт, вина и обиды, минимизируя или игнорируя положительные или оптимистические аспекты. У людей с этой тенденцией часто есть преувеличенное ожидание, что во многих сферах их жизни, включая работу, финансы и личные отношения, всё неизбежно пойдет не так, как нужно, и даже когда кажется, что все идет хорошо, оно в конечном итоге развалится. Это часто приводит к избыточному страху совершения ошибок, которые могут привести к финансовой разрухе, смущению, унижению или попаданию в нежелательную ситуацию. Из-за этого преувеличенного страха отрицательных результатов люди с этой тенденцией могут испытывать хроническую тревогу, бдительность или нерешительность.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `Склонен предвидеть негативные результаты и принимать меры для их устранения.`,
                },
                {
                  name: 'Избегание',
                  tip: `Не ставит перед собой или перед другими высоких ожиданий, часто довольствуясь меньшим или недооценивая возможности.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Поддерживает нереалистически позитивный взгляд, проявляя непоколебимый оптимизм и тенденцию находить светлую сторону любой ситуации.`,
                },
              ],
            },
            {
              name: 'Подавление эмоций (EI)',
              tip: `
Чрезмерное сдерживание естественных и спонтанных действий, эмоций или коммуникации, часто из-за страха неодобрения или потери контроля. Это подавление часто проявляется различными способами, включая:
A) подавление гнева или агрессивного поведения. 
B) подавление положительных импульсов, таких как радость или привязанность.
C) трудность в выражении уязвимости и открытой коммуникации о чувствах и потребностях.
D) чрезмерный акцент на рациональности, пренебрегая эмоциями.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Подходят к ситуациям с тщательным взвешиванием и логическим мышлением.
2. Воздерживаются от показа спонтанных эмоциональных реакций, будь то положительные или отрицательные.`,
                },
                {
                  name: 'Избегание',
                  tip: `Избегают деятельности, которая включает выражение своих эмоций (например, проявление любви или страха) или поведение в несдержанной манере (например, танцы).`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Они ведут себя в спонтанной и несдержанной манере, иногда под влиянием веществ, таких как алкоголь, которые оказывают успокаивающее действие.`,
                },
              ],
            },
            {
              name: 'Сверхвысокие стандарты / гиперкритичность (US)',
              tip: 'Убеждение, что нужно соответствовать очень высоким внутренним стандартам поведения и достижений, чтобы избежать критики, что приводит к чувству давления и критичности к себе и другим. Это может привести к снижению удовольствия, проблемам с расслаблением, здоровьем, самооценкой, чувством выполненного долга и отношениями. Это может проявляться как перфекционизм, жесткие правила и "долженства" или озабоченность временем и эффективностью.',
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `
1. Стремится к совершенству в своей работе или в других областях жизни, часто за счет других приоритетов.
2. Не позволяет себе развлекаться или ценить деятельность, не связанную напрямую с успехом.
3. Увлекается рациональным использованием времени и личным ростом и комфортом.`,
                },
                {
                  name: 'Избегание',
                  tip: `Занимается прокрастинацией, откладывает задачи или действия.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Отказывается от высоких стандартов и довольствуется результатами ниже среднего.`,
                },
              ],
            },
            {
              name: 'Пунитивность (PU)',
              tip: `Перфекционистское мышление, которое подразумевает, что люди должны быть сурово наказаны за ошибки, характеризующееся гневом, нетерпимостью и карательным и нетерпеливым отношением к тем, кто не оправдывает их ожиданий. Часто приводит к трудностям в прощении себя или других и отсутствию сочувствия к обстоятельствам или человеческим недостаткам.`,
              copings: [
                {
                  name: 'Капитуляция',
                  tip: `Ведет себя чрезмерно резко или грубо по отношению к значимым другим, проявляет гнев, обвиняет себя и других.`,
                },
                {
                  name: 'Избегание',
                  tip: `Избегает ситуаций, связанных с оценкой из-за страха быть наказанным.`,
                },
                {
                  name: 'Сверхкомпенсация',
                  tip: `Действует чрезмерно великодушно, в то же время чувствуя гнев и желание наказать.`,
                },
              ],
            },
          ],
        },
      },
    },

    supportingCycle: {
      supportingCycles: 'Поддерживающие циклы',
      supportingCyclesShort: 'Циклы',
      selectTemplate: 'Выберите шаблон',
      cycleName: 'Название',
      thoughts: 'Мысли, действия или факты',
      relations: 'Приводит к',
      influences: 'Влияет на:',
      tempalteLabel: 'Использовать шаблон (опционально)',
      saveSuccess: 'Поддерживающий цикл успешно сохранен',
      saveError: 'Ошибка при сохранении поддерживающего цикла',
      addField: 'Добавить мысль, действие или факт',
      expandAll: 'Развернуть все',
    },
  },
  components: {
    layout: {},
    header: {
      selectClient: 'Выберите клиента',
      filterResults: 'Фильтровать результаты по клиенту',
      filterTip:
        'Если Вы выберете здесь клиента, Вы увидите данные только для этого клиента во всех инструментах.',
      startSession: 'Начать сессию',
      finishSession: 'Завершить сессию',
      startTime: 'Время начала',
      endTime: 'Время окончания',
      duration: 'Продолжительность',
    },
  },
  modals: {
    mobileAppAccount: {
      title: 'Обнаружена учетная запись мобильного приложения',
      text1:
        'Мы обнаружили, что Вы вошли в систему с учетной записью клиентского мобильного приложения.',
      text2:
        'Этот портал предназначен только для специалистов в области психического здоровья.',
      text3:
        'Если Вы не собирались создавать учетную запись провайдера, пожалуйста, выйдите из системы.',
      text4:
        'Если Вы хотите начать использовать существующую учетную запись мобильного приложения в качестве учетной записи специалиста, продолжайте.',
      okText: 'Продолжить',
      cancelText: 'Выйти',
    },
  },
  actions: {
    list: 'Список',
    create: 'Создать',
    edit: 'Редактировать',
    show: 'Показать',
  },
  buttons: {
    create: 'Создать',
    save: 'Сохранить',
    logout: 'Выйти',
    delete: 'Удалить',
    edit: 'Редактировать',
    cancel: 'Отмена',
    confirm: 'Вы уверены?',
    filter: 'Фильтр',
    clear: 'Очистить',
    refresh: 'Обновить',
    show: 'Показать',
    undo: 'Отменить',
    import: 'Импорт',
    export: 'Экспорт',
    clone: 'Клонировать',
    notAccessTitle: 'У Вас нет разрешения на доступ',
    invite: 'Пригласить',
  },
  warnWhenUnsavedChanges:
    'Вы уверены, что хотите покинуть страницу? У Вас есть несохранённые изменения.',
  notifications: {
    success: 'Успешно',
    error: 'Ошибка (код состояния: {{statusCode}})',
    undoable: 'У вас есть {{seconds}} секунды, чтобы отменить',
    createSuccess: 'Ресурс {{resource}} успешно создан',
    createError:
      'Возникла ошибка при создании {{resource}} (status code: {{statusCode}})',
    deleteSuccess: 'Ресурс {{resource}} успешно удалён {{resource}}',
    deleteError:
      'Ошибка при удалении {{resource}} (status code: {{statusCode}})',
    editSuccess: 'Успешно отредактирован ресурс {{resource}}',
    editError:
      'Ошибка при редактировании {{resource}} (status code: {{statusCode}})',
    importProgress: 'Импорт: {{processed}}/{{total}}',
    unauthorized:
      'У Вас нет прав доступа к этому ресурсу. Обратитесь за помощью к администратору практики.',
  },
  loading: 'Загрузка',
  tags: {
    clone: 'Клонировать',
  },
  dashboard: {
    title: 'Главная',
  },
  clients: {
    clients: 'Клиенты',
    fields: {
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'Емейл',
      actions: 'Действия',
      access: 'Доступ',
      accessLevel: 'Тип аккаунта',
      createdAt: 'Дата создания',
    },
    free: 'Бесплатный',
    premium: 'Премиум',
    premium_practice: 'Премиум через практику',
    premiumNonApp: 'Клиент без приложения',
    thoughtsTest: 'Тест дисфункциональных мыслей',
    thoughtsTestDisabledTip:
      'Для использования этой функции у Клиента должна быть учетная запись Премиум.',
    showInfo: 'Показать личные данные клиентов',
    hideInfo: 'Скрыть личные данные клиентов',
    addNonAppClient: 'Добавить клиентa без приложения',
    nonAppClientDescription:
      'Добавьте клиента, который (еще) не использует мобильное приложение. Может быть полезно для отслеживания клиентов, которые еще не готовы или никогда не будут готовы использовать мобильное приложение. Позже Вы сможете пригласить их использовать приложение.',
    piiTip: `
Информация на этой странице по умолчанию скрыта для защиты личной информации клиентов, например от утечки во время сеанса телетерапии.

Информация снова автоматически скрывается через 1 минуту после ее отображения.
`,
    freeTip: `
Бесплатный: Клиент имеет ограничения на использование Премиум-функций мобильного приложения.
    `,
    premiumTip: `
Премиум: Клиент имеет доступ ко всем Премиум-функциям Мобильного приложения либо заплатив за себя, либо получив доступ в другой практике.
    `,
    premiumPracticeTip: `
Премиум через практику: Клиент имеет доступ ко всем Премиум-функциям мобильного приложения через Вашу практику и идёт в счёт доступных клиентов с соответствии с подпиской Вашей практики.
    `,

    nonAppClientTip: `Клиент без приложения: клиент не использует мобильное приложение. Клиент идёт в счёт Вашей подписки.`,
    freeDowngradeWarning: `
Вы собираетесь снизить тип подписки клиента до бесплатного. Это удалит доступ к Премиум-функциям из учетной записи мобильного приложения клиента и может привести к деактивации напоминаний, а также ограничениям использования приложения. Вы уверены?
`,
    upgradeWarning: `
Вы достигли предела клиентов, поддерживаемых Вашей подпиской. Пожалуйста, обновите подписку.
    `,
    limitReachedWarning: `
Вы достигли предела клиентов, которых поддерживает Ваша текущая подписка. Обновите подписку или переведите некоторых клиентов на бесплатную версию.
`,
    expiredWarning: `
Срок действия Вашей подписки истек. Обновите подписку, чтобы иметь возможность предоставлять Премиум-доступ Вашим клиентам.
`,

    mergeTip: `Объединить учетные записи клиента с мобильным приложением и без приложения`,
    mergeWarning: `Вы собираетесь объединить учетную запись клиента с мобильным приложением и без приложения. Это удалит учетную запись без мобильного приложения и все ее данные.`,
    mergeTipDetails: `Вы можете объединить учетную запись этого клиента без мобильного приложения, с учетной записью с мобильным приложением. Данная учетная запись (без мобильного приложения) будет удалена, а все ее данные перемещены в учетную запись с мобильным приложением.
Если в учетной записи клиента мобильного приложения есть данные о концептуализации, мы будем использовать данные из учетной записи с мобильным приложением. Если концептуализация пуста, мы скопируем данные из учётной записи аккаунта без доступа к мобильному приложению.
Все остальные данные будут скопированы с обеих учетных записей.
    `,
    selectMergeAppClient: 'Выберите учетную запись клиента для объединения',
    mergeLabel: `Клиент с мобильным приложением для объединения данных`,
    deleteTip: 'Удалить клиента',
    deleteConfirmText: `Вы действительно хотите удалить этого клиента и все связанные с ним данные? Это действие не может быть отменено.`,
    deleteOkText: 'Удалить',
    deleteCancelText: 'Отмена',
    inviteAppClient: 'Пригласить клиента',
  },

  invites: {
    invites: 'Приглашения',
    createInvite: 'Создать приглашение',
    sendInvite: 'Отправить приглашение',
    who: 'Кого Вы хотите пригласить?',
    clientsStats: 'Добавленные клиенты / Клиенты по подписке',
    client: 'Клиента',
    practiceMember: 'Сотрудника практики',
    practicionerRole: 'Какая роль будет у этого сотрудника?',
    messagePreview: 'Cообщение',
    copyToken: 'Скопировать код',
    upgrade: 'Изменить подписку',
    clientInviteHtml: `
      <p>Здравствуйте, {{name}}!</p>
             <p>{{practitioner}} предлагает Вам загрузить мобильное приложение <b>CopingCard</b>.</p>
             <br />
            <p><b>Шаг 1. Загрузите приложение и создайте учетную запись.</b></p>
            <p>Если Вы используете iPhone, загрузите наше приложение с <b><a href="https://apps.apple.com/ru/app/copingcard-cbt-mental-health/id1612621383?platform=iphone">App Store</a></b>. </p>
            <br />
            <p>Если Вы используете телефон Android, загрузите наше приложение с <b><a href="https://play.google.com/store/apps/details?id=com.copingcard&hl=ru">Google Play</a></b>. </p>
            <br />
            <p>Затем зарегистрируйтесь удобным для Вас способом.</p>
            <br />
            <p><b>Шаг 2. Настройте обмен данными с {{practitioner}}</b></p>
             <p>После того, как Вы установили приложение и создали учетную запись, нажмите <b><a href="{{link}}">на эту ссылку</a></b>, чтобы принять приглашение и настроить обмен данными,
             или используйте следующий код приглашения, чтобы ввести его вручную в разделе Настройки -> Обмен данными с терапевтами: <b>{{token}}</b></p>
             <br />
             <p>С уважением,</p>
             <p>Команда CopingCard, Inc.</p>
    `,
    userInviteHtml: `
      <p>Здравствуйте, {{name}}!</p>
             <p>{{practitioner}} приглашает Вас присоединиться к платформе <b><a href="{{link}}">CopingCard</a></b>
             как {{role}}.</p>

             <br />
             <p>Нажмите на ссылку выше, чтобы принять приглашение и создать учетную запись,
             или используйте следующий код, если Вы уже зарегистрированы: <b>{{token}}</b></p>
             <br />
             <p>С уважением,</p>
             <p>Команда CopingCard, Inc.</p>
    `,
    userTooltip:
      'Пользователи могут управлять своими клиентами и работать с ними.',
    adminTooltip:
      'Администраторы могут управлять другими пользователями практики, их разрешениями, работать со всеми клиентами и их информацией. Они также могут управлять информацией о практике и управлять платежами.',
    permanentInviteLink: 'Постоянная ссылка приглашения клиента',
    fields: {
      email: 'Емейл',
      createdAt: 'Дата создания',
      expiresAt: 'Истекает',
      from: 'От',
      type: 'Тип',
      to: 'Кому',
      token: 'Токен',
      firstName: 'Имя',
      lastName: 'Фамилия',
      message: 'Сообщение',
      copy: 'Копировать cсылку',
    },
    types: {
      0: 'Постоянная ссылка с приглашением',
      1: 'Клиент',
      2: 'Терапевт',
      3: 'Пользователь практики',
      4: 'Администратор практики',
    },
  },
  messages: {
    messages: 'Сообщения',
    fields: {
      from: 'От',
      to: 'Кому',
      message: 'Сообщение',
      createdAt: 'Дата создания',
      readAt: 'Дата прочтения',
      senderEmail: 'Емейл отправителя',
      copyMessage: 'Копировать сообщение',
      subject: 'Тема',
    },
    defaultSubject: 'Сообщение от потенциального клиента',
    readMessage: 'Прочитать сообщение',
  },
  portalThoughts: {
    portalThoughts: 'Тестирование мыслей',
  },
  createThought: {
    create: 'Заполнить тест дисфункциональных мыслей для клиента',
    selectClient: 'Выберите клиента',
    selectClientTip: 'Вы можете выбрать только клиентов с Премиум-аккаунтами',
    eventTitle: 'Активирующее событие',
    emotionsTitle: 'Эмоции',
    feelingsTitle: 'Ощущения',
    automaticThoughtTitle: 'Автоматическая мысль',
    thoughtsForTitle: 'Факты, доказывающие мысль',
    thoughtsAgainstTitle: 'Факты, опровергающие мысль',
    realisticThoughtsTitle: 'Реалистичные и полезные мнения',
    resultsTitle: 'Результат',
    timeRemindersTitle: 'Напоминания по времени',
    locationRemindersTitle: 'Геолокационные напоминания',
    eventLabel: 'Опишите активирующее событие',
    chooseEmotions: 'Выберите эмоции',
    rateEmotions: 'Оцените силу эмоций',
    chooseFeelings: 'Выберите ощущения',
    feelingsInBody: 'Ощущения в теле',
    automaticThoughtBelief: 'Убеждённость',
    addEvidenceAgainst: 'Добавить опровергающий факт',
    addEvidenceFor: 'Добавить факт',
    evidenceAgainst: 'Факт, опровергающий мысль',
    evidenceFor: 'Факт, поддерживающий негативную мысль',
    alternativeThought:
      'Альтернативное объяснение фактов в поддержку негативной мысли',
    alternativeThoughtTip:
      'Чем еще можно объяснить факты в поддержку автоматической негативной мысли, кроме самой этой мысли?',
    realisticThought: 'Реалистичная мысль или идея',
    automaticThoughtAdd: 'Добавить мысль',
    resultBelief:
      'Насколько клиент верит в свои первоначальные негативные мысли?',
    resultEmotionStrength:
      'Насколько сильна основная первоначальная негативная эмоция?',
    initialThoughtBelief: 'Убеждённость в первоначальной мысли',
    initialEmotionStrength: 'Сила первоначальной эмоции',
    day: 'Дневное напоминание',
    evening: 'Вечернее напоминание',
    morning: 'Утреннее напоминание',
    createSuccess: 'Вы успешно создали копинг-карту для своего клиента',
    thoughtEvidenceRequired:
      'Факт в поддержку мысли является обязательным полем',
    evidenceAgainstRequired:
      'Факт, опровергающий мысль, является обязательным полем',
    realisticThoughtRequired: 'Реалистичная мысль является обязательным полем',
    cognitiveDistortionsTile: 'Когнитивные искажения автоматической мысли',
    cognitiveDistortions: 'Когнитивные искажения',
    cognitiveDistortionsPlaceholder: 'Выберите когнитивные искажения',
  },
  portalTests: {
    portalTests: 'Тесты',
    fields: {
      type: 'Тип',
      score: 'Результат',
      severity: 'Степень тяжести',
      createdAt: 'Пройден',
    },
  },
  practice: {
    practice: 'Практика',
    practiceInfo: 'Информация о практике',
  },
  tools: {
    tools: 'Инструменты',
  },
  practiceUsers: {
    practiceUsers: 'Пользователи',
    fields: {
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'Емейл',
      role: 'Роль',
      actions: 'Действия',
    },
  },
  practiceInfo: {
    practiceInfo: 'Информация',
    fields: {},
  },
  portalSubscriptions: {
    title: 'Подписка',
    subscriptionOptions: ['Ежемесячная', '6 месяцев', 'Годовая'],
    headline: 'Ваша текущая подписка',
    orderForm: '(Форма заказа подписки)',
    customNumber: 'Введите количество клиентов',
    free: 'Бесплатно',
    premium: 'Премиум',
    clients: 'Клиентов',
    month: 'месяц',
    halfYear: '6 месяцев',
    year: 'год',
    purchase: 'Купить Премиум подписку',
    paymentSuccess:
      'Поздравляем! Вы успешно завершили процесс оплаты, и мы обновили Вашу подписку.',
    paymentFailed:
      'Похоже, у Вас возникла проблема с оплатой. Пожалуйста, попробуйте еще раз или свяжитесь с нами по адресу support@copingcard.com, и мы поможем Вам.',
    upgrade: 'Купить Премиум подписку',
    manageSubscription: 'Управление подпиской в Stripe',
    paidSupportOptions: [
      'Поддержка по емейл',
      'Поддержка по емейл, видео, телефону',
      'Приоритетная поддержка по емейл, видео, телефону',
    ],
    freeFeatures: [
      '5 клиентов (просмотр/управление данными)',
      '5 клиентских лицензий на полный доступ к мобильному приложению на 3 месяца',
      'Бесплатные направления клиентов',
      'Поддержка по емейл',
    ],
    paidFeatures: [
      '{{clients}} клиентов (просмотр/управление данными)',
      '{{clients}} клиентских лицензий на полный доступ к мобильному приложению на срок действия Вашей подписки',
      'Бесплатные направления клиентов',
    ],
    subscriptionExpiringInDays: 'Ваша подписка истекает через:',
    subscriptionExpired:
      'Срок действия Вашей подписки истек. Ваши клиенты будут переведены на Бесплатную версию через:',
    clientDowngradeTip: `
      Ваши клиенты потеряют дооступ к Premium функциям мобильного приложения.
      Напоминания и уведомления, превышающие лимит бесплатной версии, будут отключены.
      Будут применяться другие ограничения бесплатной версии.
    `,
  },
  profile: {
    profile: 'Личный профиль',
    description:
      'Пожалуйста, предоставьте информацию о себе и о Вашей квалификации. Эта информация будет отображаться в нашем мобильном приложении, если Вы укажете, что хотите получать от нас потенциальных клиентов, и мы утвердим Вашу заявку.',
    wantsReferrals: 'Хочу получать потенциальных клиентов',

    approvedForReferrals:
      'Профиль одобрен для получения потенциальных клиентов',
    approvedForReferralsTip:
      'Ваш профиль должен быть одобрен нашей командой, прежде чем начнём напрвалять Вам потенциальных клиентов. Одним из требований является наличие 3 или более клиентов, добавленных на портал и активированных: использовавших тест дисфункциональных мыслей, прошдших тест на депрессию и/или тревогу, прослуших медитацию.',
    formats: 'Форматы терапии',
    formatsOptions: ['Очная', 'Онлайн'],
    firstName: 'Имя',
    lastName: 'Фамилия',
    selectOrType: 'Выберите или напечатайте',
    professionalTitle: 'Титул',
    professionalTitlePlaceholder: 'например, кандидат наук',
    phone: 'Телефон',
    extension: 'Добавочный номер',
    imageUrl: 'Ваша фотография',
    uploadPhoto: 'Загрузить',
    onlyImage: 'Разрешены только файлы изображений!',
    sizeLimit: 'Ограничение на размер файла 5 МБ!',
    uploadSuccess: 'Загрузка прошла успешно.',
    mentalHealthRole: 'Роль в области психического здоровья',
    mentalHealthRoles: [
      'Арт-терапевт',
      'Клинический социальный работник/терапевт',
      'Консультант',
      'Нарколог',
      'Лицензированный профессиональный консультант',
      'Лицензированный психоаналитик',
      'Психолог с ограниченной лицензией',
      'Лицензированный профессиональный консультант-интерн',
      'Психотерапевт по вопросам брака и семьи',
      'Психотерапевт-интерн по вопросам брака и семьи',
      'Ассоциированный специалист по по вопросам брака и семьи',
      'Трудотерапевт',
      'Пасторский советник',
      'Профессионал с предварительной лицензией',
      'Психиатрическая медсестра',
      'Практикующая психиатрическая медсестра',
      'Психиатр',
      'Психологический консультант',
      'Психолог',
      'Психотерапевт',
    ],
    licensedInStates: 'Лицензия в штате',
    state: 'Штат',
    licenseNumber: 'Номер лицензии',
    addState: 'Добавить штат',
    about: 'О себе',
    education: 'Образование',
    addEducation: 'Добавить образование',
    acceptingClients: 'Приём новых клиентов',
    acceptingOptions: ['Да', 'Нет', 'Лист ожидания'],
    yearsInPractice: 'Лет практики',
    languages: 'Языки',
    langaugeHint: 'Пожалуйста, выберите или введите языки, на которых говорите',
    languagesList: [
      'Английский',
      'Испанский',
      'Китайский',
      'Вьетнамский',
      'Арабский',
      'Французский',
      'Корейский',
      'Русский',
      'Немецкий',
      'Украинский',
      'Белорусский',
    ],
    finances: 'Оплата',
    costPerSessionFrom: 'Стоимость сеанса от',
    fromPlaceholder: 'от',
    costPerSessionTo: 'до',
    slidingScale: 'Скользящая шкала',
    slidingScaleTip: `Плата по скользящяей шкале — это переменные цены на продукты или услуги в зависимости от платежеспособности клиента. Таким образом, такие сборы снижаются для тех, кто имеет более низкие доходы или, альтернативно, меньше денег, остающихся после их личных расходов, независимо от дохода. Плата по скользящяей шкале является формой ценовой дискриминации или дифференцированного ценообразования.`,
    freeInitialConsultation: 'Бесплатная первая консультация',
    currency: 'Валюта',
    clients: 'Клиенты',
    age: 'Возраст',
    clientAges: ['Взрослые', 'Дети', 'Пожилые', 'Подростки'],
    modality: 'Модальность',
    modalities: ['Индивидуальная', 'Семейная', 'Групповая'],
    issues: 'Проблемы',
    clientIssues: [
      'СДВГ',
      'Аборты, потеря беременности и выкидыш',
      'Плохое обращение(абьюз)',
      'Расстройства адаптации',
      'Проблемы подросткового возраста',
      'Проблемы усыновления',
      'Проблемы старения',
      'Злоупотребление алкоголем и алкогольная зависимость',
      'Альцгеймер',
      'Проблемы гнева',
      'Тревожность',
      'Привязанность',
      'Расстройства аутистического спектра',
      'Биполярное расстройство',
      'Проблемы с образом тела',
      'Повторяющееся поведение, сфокусированное на теле (BFRB)',
      'Выгорание',
      'Рак',
      'Профориентация',
      'Детские проблемы',
      'Хроническая боль или заболевание',
      'Созависимость',
      'Усталость сострадания',
      'Совместное воспитание',
      'Культурное и системное угнетение',
      'Депрессия',
      'Диссоциативные расстройства',
      'Развод',
      'Насилие в семье или насилие со стороны интимного партнера',
      'Наркотическая зависимость/злоупотребление/наркомания',
      'Расстройства пищевого поведения',
      'Стресс семейного ухода',
      'Семейный конфликт',
      'Проблемы приемной семьи',
      'Игромания',
      'Гендерная идентичность',
      'Горе или утрата',
      'Высокочувствительная личность',
      'Историческая/межпоколенческая травма',
      'Иммиграция/Аккультурация',
      'Заключение и реинтеграция',
      'Бесплодие',
      'Измена или любовная связь',
      'Бессонница',
      'Интеллектуальная недееспособность',
      'Интернет-зависимость',
      'Стресс на работе',
      'Кинк/Сексуальные аутсайдеры',
      'Проблемы ЛГБТК',
      'Нарушения обучения',
      'Этапы жизни',
      'Мужские проблемы',
      'Обсессивно-компульсивное расстройство (ОКР)',
      'Панические расстройства',
      'Воспитание детей',
      'Персональный рост',
      'Расстройства личности',
      'Инвалидность',
      'Политический климат',
      'Полиаморные и открытые отношения',
      'Посттравматическое стрессовое расстройство (ПТСР)',
      'Беременность и послеродовой период',
      'Психоз и шизофрения',
      'Расовая идентичность',
      'Вопросы отношений/брака',
      'Самооценка',
      'Самоповреждение',
      'Сексуальное насилие или нападение',
      'Сексуальная зависимость',
      'Сексуальная идентичность',
      'Сексуальные проблемы',
      'Боязнь общества',
      'Духовное насилие и религиозная травма',
      'Духовность',
      'Стресс',
      'Суицидальные мысли',
      'Терапевты, которые лечат других терапевтов',
      'Синдром Туретта',
      'Токсичная мужественность',
      'Черепно-мозговая травма (ЧМТ)',
      'Ветераны/военная служба',
      'Женские проблемы',
    ],

    approachLabel: 'Терапевтические подходы',
    approaches: [
      'Анималотерапия (ААТ)',
      'Арт-терапия',
      'Биологическая обратная связь',
      'Брейнспоттинг',
      'Внутренние семейные системы',
      'Гештальт-терапия',
      'Гипнотерапия',
      'Глубинная терапия',
      'Гуманистическая терапия',
      'Десенсибилизация и переработка движениями глаз (EMDR)',
      'Диалектическая поведенческая терапия (ДПТ)',
      'Добрачное консультирование',
      'Игровая терапия',
      'Интегральная терапия',
      'Интегративная терапия',
      'Интервенция',
      'Клиническая нейропсихология',
      'Когнитивно-поведенческая терапия (КПТ)',
      'Консультирование наркозависимых',
      'Консультирование пар',
      'Консультирование по различению',
      'Коучинг',
      'Кратковременная терапия, ориентированная на решение',
      'Культурно-сенситивная терапия',
      'Личностно-ориентированный подход',
      'Межличностная нейробиология (IPNB)',
      'Межличностная терапия (ИПТ)',
      'Метакогнитивная терапия (МКТ)',
      'Метод Готтмана',
      'Мотивационное интервью',
      'Музыкотерапия',
      'Нарративная терапия',
      'Нейроаффективная реляционная модель (NARM)',
      'Нейрообратная связь',
      'Ненасильственное общение',
      'Песочная (Sand Tray) терапия',
      'Песочная (Sandplay) терапия',
      'Психоаналитический',
      'Психобиологический подход к парной терапии (ПАКТ)',
      'Психодинамическая терапия',
      'Психодрама',
      'Психологическое тестирование и оценка',
      'Психотерапия с участием лошадей (EAP)',
      'Регрессия в прошлые жизни',
      'Реляционная терапия',
      'Семейная терапия',
      'Семейные системы',
      'Сенсомоторная психотерапия',
      'Совместная парная терапия',
      'Созерцательная терапия',
      'Схемотерапия',
      'Танцевальная / двигательная терапия',
      'Телесно-ориентированнаяа терапия',
      'Теория привязанности',
      'Терапия Хакоми',
      'Терапия дикой природы',
      'Терапия отношений Имаго',
      'Терапия принятия и приверженности (ACT)',
      'Терапия снижения вреда',
      'Терапия управления гневом',
      'Терапия, основанная на осознанности',
      'Травмотерапия',
      'Трансперсональная терапия',
      'Ускоренная эмпирическая динамическая психотерапия (AEDP)',
      'Феминистская терапия',
      'Холистическая терапия',
      'Экзистенциальная терапия',
      'Эклектическая терапия',
      'Экотерапия',
      'Экспериментальная терапия',
      'Экспозиционная терапия',
      'Экспрессивная арт-терапия',
      'Эмоционально-ориентированная терапия',
      'Энергетическая психология',
    ],
    otherInfo: 'Другая информация',
    otherProfileLink: 'Ссылка на другой профиль',
  },
  resourcesCommon: {
    actions: 'Действия',
    createdAt: 'Создано',
    duration: 'Продолжительность',
    finishedAt: 'Завершено',
    updatedAt: 'Обновлено',
    view: 'Просмотреть',
    edit: 'Редактировать',
    userId: 'ID Клиента',
    yes: 'Да',
    no: 'Нет',
    next: 'Далее',
    finish: 'Закончить',
  },
  posts: {
    posts: 'Посты',
    fields: {
      id: 'Идентификатор',
      title: 'Заголовок',
      category: {
        title: 'Категория',
        filter: {
          placeholder: 'Выбрать категорию',
        },
      },
      status: {
        title: 'Статус',
        published: 'Опубликовано',
        draft: 'Черновик',
        rejected: 'Отклоненный',
      },
      content: 'Содержание',
      createdAt: 'Создано',
    },
    titles: {
      create: 'Создать пост',
      edit: 'Редактировать сообщение',
      list: 'Посты',
      show: 'Показать сообщение',
    },
  },
  table: {
    actions: 'Действия',
  },
  roles: {
    0: 'Клиент',
    1: 'Клиент с премиальным доступом',
    2: 'Пользователь',
    3: 'Администратор практики',
    4: 'Администратор',
  },
  icd10: [
    {
      value: 'F01-F09',
      title:
        'F01-F09 Психические расстройства, обусловленные известными физиологическими состояниями',
      children: [
        {
          value: 'F01',
          title: 'F01 Сосудистая деменция',
          children: [
            {
              value: 'F01.5',
              title: 'F01.5 Сосудистая деменция неуточненной степени тяжести',
              children: [
                {
                  value: 'F01.50',
                  title:
                    'F01.50 Сосудистая деменция неуточненной степени тяжести без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F01.51',
                  title:
                    'F01.51 Сосудистая деменция неуточненной степени тяжести с поведенческим расстройством',
                  children: [
                    {
                      value: 'F01.511',
                      title:
                        'F01.511 Сосудистая деменция неуточненной степени тяжести с ажитацией.',
                    },
                    {
                      value: 'F01.518',
                      title:
                        'F01.518 Сосудистая деменция неуточненной степени тяжести с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F01.52',
                  title:
                    'F01.52 Сосудистая деменция неуточненной степени тяжести с психотическим расстройством',
                },
                {
                  value: 'F01.53',
                  title:
                    'F01.53 Сосудистая деменция неуточненной степени тяжести с расстройством настроения',
                },
                {
                  value: 'F01.54',
                  title:
                    'F01.54 Сосудистая деменция неуточненной степени тяжести с тревогой',
                },
              ],
            },
            {
              value: 'F01.A',
              title: 'F01.A Сосудистая деменция, легкая',
              children: [
                {
                  value: 'F01.A0',
                  title:
                    'F01.A0 Сосудистая деменция, легкая, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F01.A1',
                  title:
                    'F01.A1 Сосудистая деменция, легкая, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F01.A11',
                      title: 'F01.A11 Сосудистая деменция, легкая, с ажитацией',
                    },
                    {
                      value: 'F01.A18',
                      title:
                        'F01.A18 Сосудистая деменция, легкая, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F01.A2',
                  title:
                    'F01.A2 Сосудистая деменция, легкая, с психотическим расстройством',
                },
                {
                  value: 'F01.A3',
                  title:
                    'F01.A3 Сосудистая деменция, легкая, с расстройством настроения',
                },
                {
                  value: 'F01.A4',
                  title: 'F01.A4 Сосудистая деменция, легкая, с тревогой',
                },
              ],
            },
            {
              value: 'F01.B',
              title: 'F01.B Сосудистая деменция, умеренная',
              children: [
                {
                  value: 'F01.B0',
                  title:
                    'F01.B0 Сосудистая деменция, умеренная, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F01.B1',
                  title:
                    'F01.B1 Сосудистая деменция, умеренная, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F01.B11',
                      title:
                        'F01.B11 Сосудистая деменция, умеренная, с ажитацией',
                    },
                    {
                      value: 'F01.B18',
                      title:
                        'F01.B18 Сосудистая деменция, умеренная, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F01.B2',
                  title:
                    'F01.B2 Сосудистая деменция, умеренная, с психотическим расстройством',
                },
                {
                  value: 'F01.B3',
                  title:
                    'F01.B3 Сосудистая деменция, умеренная, с расстройством настроения',
                },
                {
                  value: 'F01.B4',
                  title: 'F01.B4 Сосудистая деменция, умеренная, с тревогой',
                },
              ],
            },
            {
              value: 'F01.C',
              title: 'F01.C Сосудистая деменция, тяжелая',
              children: [
                {
                  value: 'F01.C0',
                  title:
                    'F01.C0 Сосудистая деменция, тяжелая, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F01.C1',
                  title:
                    'F01.C1 Сосудистая деменция, тяжелая, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F01.C11',
                      title:
                        'F01.C11 Сосудистая деменция, тяжелая, с ажитацией',
                    },
                    {
                      value: 'F01.C18',
                      title:
                        'F01.C18 Сосудистая деменция, тяжелая, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F01.C2',
                  title:
                    'F01.C2 Сосудистая деменция, тяжелая, с психотическим расстройством',
                },
                {
                  value: 'F01.C3',
                  title:
                    'F01.C3 Сосудистая деменция, тяжелая, с расстройством настроения',
                },
                {
                  value: 'F01.C4',
                  title: 'F01.C4 Сосудистая деменция, тяжелая, с тревогой',
                },
              ],
            },
          ],
        },
        {
          value: 'F02',
          title:
            'F02 Деменция при других заболеваниях, классифицированных в других рубриках',
          children: [
            {
              value: 'F02.8',
              title:
                'F02.8 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени',
              children: [
                {
                  value: 'F02.80',
                  title:
                    'F02.80 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F02.81',
                  title:
                    'F02.81 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F02.811',
                      title:
                        'F02.811 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с ажитацией',
                    },
                    {
                      value: 'F02.818',
                      title:
                        'F02.818 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F02.82',
                  title:
                    'F02.82 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с психотическим расстройством',
                },
                {
                  value: 'F02.83',
                  title:
                    'F02.83 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с расстройством настроения',
                },
                {
                  value: 'F02.84',
                  title:
                    'F02.84 Деменция при других заболеваниях, классифицированных в других рубриках, неуточненной степени, с тревогой',
                },
              ],
            },
            {
              value: 'F02.A',
              title:
                'F02.A Деменция при других заболеваниях, классифицированных в других рубриках, легкая',
              children: [
                {
                  value: 'F02.A0',
                  title:
                    'F02.A0 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F02.A1',
                  title:
                    'F02.A1 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F02.A11',
                      title:
                        'F02.A11 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с ажитацией',
                    },
                    {
                      value: 'F02.A18',
                      title:
                        'F02.A18 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F02.A2',
                  title:
                    'F02.A2 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с психотическим расстройством',
                },
                {
                  value: 'F02.A3',
                  title:
                    'F02.A3 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с расстройством настроения',
                },
                {
                  value: 'F02.A4',
                  title:
                    'F02.A4 Деменция при других заболеваниях, классифицированных в других рубриках, легкая, с тревогой',
                },
              ],
            },
            {
              value: 'F02.B',
              title:
                'F02.B Деменция при других заболеваниях, классифицированных в других рубриках, умеренная',
              children: [
                {
                  value: 'F02.B0',
                  title:
                    'F02.B0 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F02.B1',
                  title:
                    'F02.B1 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F02.B11',
                      title:
                        'F02.B11 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с ажитацией',
                    },
                    {
                      value: 'F02.B18',
                      title:
                        'F02.B18 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F02.B2',
                  title:
                    'F02.B2 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с психотическим расстройством',
                },
                {
                  value: 'F02.B3',
                  title:
                    'F02.B3 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с расстройством настроения',
                },
                {
                  value: 'F02.B4',
                  title:
                    'F02.B4 Деменция при других заболеваниях, классифицированных в других рубриках, умеренная, с тревогой',
                },
              ],
            },
            {
              value: 'F02.C',
              title:
                'F02.C Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая',
              children: [
                {
                  value: 'F02.C0',
                  title:
                    'F02.C0 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F02.C1',
                  title:
                    'F02.C1 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F02.C11',
                      title:
                        'F02.C11 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с ажитацией',
                    },
                    {
                      value: 'F02.C18',
                      title:
                        'F02.C18 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F02.C2',
                  title:
                    'F02.C2 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с психотическим расстройством',
                },
                {
                  value: 'F02.C3',
                  title:
                    'F02.C3 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с расстройством настроения',
                },
                {
                  value: 'F02.C4',
                  title:
                    'F02.C4 Деменция при других заболеваниях, классифицированных в других рубриках, тяжелая, с тревогой',
                },
              ],
            },
          ],
        },
        {
          value: 'F03',
          title: 'F03 Неуточненная деменция',
          children: [
            {
              value: 'F03.9',
              title:
                'F03.9 Неуточненная деменция, неуточненной степени тяжести',
              children: [
                {
                  value: 'F03.90',
                  title:
                    'F03.90 Неуточненная деменция, неуточненной степени тяжести, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F03.91',
                  title:
                    'F03.91 Неуточненная деменция, неуточненной степени тяжести, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F03.911',
                      title:
                        'F03.911 Неуточненная деменция, неуточненной степени тяжести, с ажитацией',
                    },
                    {
                      value: 'F03.918',
                      title:
                        'F03.918 Неуточненная деменция, неуточненной степени тяжести, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F03.92',
                  title:
                    'F03.92 Неуточненная деменция, неуточненной степени тяжести, с психотическим расстройством',
                },
                {
                  value: 'F03.93',
                  title:
                    'F03.93 Неуточненная деменция, неуточненной степени тяжести, с расстройством настроения',
                },
                {
                  value: 'F03.94',
                  title:
                    'F03.94 Неуточненная деменция, неуточненной степени тяжести, с тревогой',
                },
              ],
            },
            {
              value: 'F03.A',
              title: 'F03.A Неуточненная деменция, легкой степени тяжести',
              children: [
                {
                  value: 'F03.A0',
                  title:
                    'F03.A0 Неуточненная деменция, легкой степени тяжести, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F03.A1',
                  title:
                    'F03.A1 Неуточненная деменция, легкой степени тяжести, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F03.A11',
                      title:
                        'F03.A11 Неуточненная деменция, легкой степени тяжести, с ажитацией',
                    },
                    {
                      value: 'F03.A18',
                      title:
                        'F03.A18 Неуточненная деменция, легкой степени тяжести, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F03.A2',
                  title:
                    'F03.A2 Неуточненная деменция, легкой степени тяжести, с психотическим расстройством',
                },
                {
                  value: 'F03.A3',
                  title:
                    'F03.A3 Неуточненная деменция, легкой степени тяжести, с расстройством настроения',
                },
                {
                  value: 'F03.A4',
                  title:
                    'F03.A4 Неуточненная деменция, легкой степени тяжести, с тревогой',
                },
              ],
            },
            {
              value: 'F03.B',
              title: 'F03.B Неуточненная деменция, умеренной степени тяжести',
              children: [
                {
                  value: 'F03.B0',
                  title:
                    'F03.B0 Неуточненная деменция, умеренной степени тяжести, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F03.B1',
                  title:
                    'F03.B1 Неуточненная деменция, умеренной степени тяжести, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F03.B11',
                      title:
                        'F03.B11 Неуточненная деменция, умеренной степени тяжести, с ажитацией',
                    },
                    {
                      value: 'F03.B18',
                      title:
                        'F03.B18 Неуточненная деменция, умеренной степени тяжести, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F03.B2',
                  title:
                    'F03.B2 Неуточненная деменция, умеренной степени тяжести, с психотическим расстройством',
                },
                {
                  value: 'F03.B3',
                  title:
                    'F03.B3 Неуточненная деменция, умеренной степени тяжести, с расстройством настроения',
                },
                {
                  value: 'F03.B4',
                  title:
                    'F03.B4 Неуточненная деменция, умеренной степени тяжести, с тревогой',
                },
              ],
            },
            {
              value: 'F03.C',
              title: 'F03.C Неуточненная деменция, тяжелой степени тяжести',
              children: [
                {
                  value: 'F03.C0',
                  title:
                    'F03.C0 Неуточненная деменция, тяжелой степени тяжести, без поведенческих расстройств, психотических расстройств, расстройств настроения и тревоги',
                },
                {
                  value: 'F03.C1',
                  title:
                    'F03.C1 Неуточненная деменция, тяжелой степени тяжести, с поведенческим расстройством',
                  children: [
                    {
                      value: 'F03.C11',
                      title:
                        'F03.C11 Неуточненная деменция, тяжелой степени тяжести, с ажитацией',
                    },
                    {
                      value: 'F03.C18',
                      title:
                        'F03.C18 Неуточненная деменция, тяжелой степени тяжести, с другими поведенческими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F03.C2',
                  title:
                    'F03.C2 Неуточненная деменция, тяжелой степени тяжести, с психотическим расстройством',
                },
                {
                  value: 'F03.C3',
                  title:
                    'F03.C3 Неуточненная деменция, тяжелой степени тяжести, с расстройством настроения',
                },
                {
                  value: 'F03.C4',
                  title:
                    'F03.C4 Неуточненная деменция, тяжелой степени тяжести, с тревогой',
                },
              ],
            },
          ],
        },
        {
          value: 'F04',
          title:
            'F04 Амнестический синдром, обусловленное известным физиологическим состоянием',
        },
        {
          value: 'F05',
          title:
            'F05 Делирий, обусловленный известным физиологическим состоянием',
        },
        {
          value: 'F06',
          title:
            'F06 Другие психические расстройства, обусловленные известным физиологическим состоянием',
          children: [
            {
              value: 'F06.0',
              title:
                'F06.0 Психотическое расстройство с галлюцинациями, обусловленное известным физиологическим состоянием',
            },
            {
              value: 'F06.1',
              title:
                'F06.1 Кататоническое расстройство, обусловленное известным физиологическим состоянием',
            },
            {
              value: 'F06.2',
              title:
                'F06.2 Психотическое расстройство с бредом, обусловленное известным физиологическим состоянием',
            },
            {
              value: 'F06.3',
              title:
                'F06.3 Расстройство настроения, обусловленное известным физиологическим состоянием',
              children: [
                {
                  value: 'F06.30',
                  title:
                    'F06.30 Расстройство настроения, обусловленное известным физиологическим состоянием, неуточненное',
                },
                {
                  value: 'F06.31',
                  title:
                    'F06.31 Расстройство настроения, обусловленное известным физиологическим состоянием, с депрессивными признаками',
                },
                {
                  value: 'F06.32',
                  title:
                    'F06.32 Расстройство настроения, обусловленное известным физиологическим состоянием, с эпизодом депрессии большой степени тяжести',
                },
                {
                  value: 'F06.33',
                  title:
                    'F06.33 Расстройство настроения, обусловленное известным физиологическим состоянием, с маниакальными признаками',
                },
                {
                  value: 'F06.34',
                  title:
                    'F06.34 Расстройство настроения, обусловленное известным физиологическим состоянием, с смешанными признаками',
                },
              ],
            },
            {
              value: 'F06.4',
              title:
                'F06.4 Тревожное расстройство, обусловленное известным физиологическим состоянием',
            },
            {
              value: 'F06.7',
              title:
                'F06.7 Легкая нейрокогнитивная дисфункция, обусловленная известным физиологическим состоянием',
              children: [
                {
                  value: 'F06.70',
                  title:
                    'F06.70 Легкая нейрокогнитивная дисфункция, обусловленная известным физиологическим состоянием, без поведенческих расстройств',
                },
                {
                  value: 'F06.71',
                  title:
                    'F06.71 Легкая нейрокогнитивная дисфункция, обусловленная известным физиологическим состоянием, с поведенческими расстройствами',
                },
              ],
            },
            {
              value: 'F06.8',
              title:
                'F06.8 Другие уточненные психические расстройства, обусловленные известным физиологическим состоянием',
            },
          ],
        },
        {
          value: 'F07',
          title:
            'F07 Расстройства личности и поведения, обусловленные известным физиологическим состоянием',
          children: [
            {
              value: 'F07.0',
              title: 'F07.0 Расстройство личности органической этиологии',
            },
            {
              value: 'F07.8',
              title:
                'F07.8 Другие расстройства личности и поведения, обусловленные известным физиологическим состоянием',
              children: [
                {
                  value: 'F07.81',
                  title: 'F07.81 Постконтузионный синдром',
                },
                {
                  value: 'F07.89',
                  title:
                    'F07.89 Другие расстройства личности и поведения, обусловленные известным физиологическим состоянием',
                },
              ],
            },
            {
              value: 'F07.9',
              title:
                'F07.9 Неуточненное расстройство личности и поведения, обусловленное известным физиологическим состоянием',
            },
          ],
        },
        {
          value: 'F09',
          title:
            'F09 Неуточненное психическое расстройство, обусловленное известным физиологическим состоянием',
        },
      ],
    },
    {
      value: 'F10-F19',
      title:
        'F10-F19 Психические и поведенческие расстройства, обусловленные употреблением психоактивных веществ',
      children: [
        {
          value: 'F10',
          title:
            'F10 Психические и поведенческие расстройства, обусловленные употреблением алкоголя',
          children: [
            {
              value: 'F10.1',
              title: 'F10.1 Злоупотребление алкоголем',
              children: [
                {
                  value: 'F10.10',
                  title: 'F10.10 Злоупотребление алкоголем, неосложненное.',
                },
                {
                  value: 'F10.11',
                  title: 'F10.11 Злоупотребление алкоголем, в стадии ремиссии',
                },
                {
                  value: 'F10.12',
                  title: 'F10.12 Злоупотребление алкоголем с интоксикацией',
                  children: [
                    {
                      value: 'F10.120',
                      title:
                        'F10.120 Злоупотребление алкоголем с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F10.121',
                      title:
                        'F10.121 Злоупотребление алкоголем с интоксикационным делирием',
                    },
                    {
                      value: 'F10.129',
                      title:
                        'F10.129 Злоупотребление алкоголем с интоксикацией, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F10.13',
                  title: 'F10.13 Злоупотребление алкоголем, с синдромом отмены',
                  children: [
                    {
                      value: 'F10.130',
                      title:
                        'F10.130 Злоупотребление алкоголем с синдромом отмены, неосложненное',
                    },
                    {
                      value: 'F10.131',
                      title:
                        'F10.131 Злоупотребление алкоголем с делирием отмены',
                    },
                    {
                      value: 'F10.132',
                      title:
                        'F10.132 Злоупотребление алкоголем с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F10.139',
                      title:
                        'F10.139 Злоупотребление алкоголем с синдромом отмены, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F10.14',
                  title:
                    'F10.14 Злоупотребление алкоголем с алкогольно-индуцированным аффективным расстройством',
                },
                {
                  value: 'F10.15',
                  title:
                    'F10.15 Злоупотребление алкоголем с алкогольно-индуцированным психотическим расстройством',
                  children: [
                    {
                      value: 'F10.150',
                      title:
                        'F10.150 Злоупотребление алкоголем с алкогольно-индуцированным психотическим расстройством с бредом',
                    },
                    {
                      value: 'F10.151',
                      title:
                        'F10.151 Злоупотребление алкоголем с алкогольно-индуцированным психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F10.159',
                      title:
                        'F10.159 Злоупотребление алкоголем с алкогольно-индуцированным психотическим расстройством, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F10.18',
                  title:
                    'F10.18 Злоупотребление алкоголем с другими алкогольно-индуцированными расстройствами',
                  children: [
                    {
                      value: 'F10.180',
                      title:
                        'F10.180 Злоупотребление алкоголем с алкогольно-индуцированным тревожным расстройством',
                    },
                    {
                      value: 'F10.181',
                      title:
                        'F10.181 Злоупотребление алкоголем с алкогольно-индуцированной сексуальной дисфункцией',
                    },
                    {
                      value: 'F10.182',
                      title:
                        'F10.182 Злоупотребление алкоголем с алкогольно-индуцированным расстройством сна',
                    },
                    {
                      value: 'F10.188',
                      title:
                        'F10.188 Злоупотребление алкоголем с другими алкогольно-индуцированными расстройствами',
                    },
                  ],
                },
                {
                  value: 'F10.19',
                  title:
                    'F10.19 Злоупотребление алкоголем с неуточненным алкогольно-индуцированным расстройством',
                },
              ],
            },
            {
              value: 'F10.2',
              title: 'F10.2 Алкогольная зависимость',
              children: [
                {
                  value: 'F10.20',
                  title: 'F10.20 Алкогольная зависимость, неосложненная',
                },
                {
                  value: 'F10.21',
                  title: 'F10.21 Алкогольная зависимость, в ремиссии',
                },
                {
                  value: 'F10.22',
                  title: 'F10.22 Алкогольная зависимость с интоксикацией',
                  children: [
                    {
                      value: 'F10.220',
                      title:
                        'F10.220 Алкогольная зависимость с интоксикацией, неосложненная',
                    },
                    {
                      value: 'F10.221',
                      title:
                        'F10.221 Алкогольная зависимость с интоксикационным делирием.',
                    },
                    {
                      value: 'F10.229',
                      title:
                        'F10.229 Алкогольная зависимость с интоксикацией, неуточненная',
                    },
                  ],
                },
                {
                  value: 'F10.23',
                  title: 'F10.23 Алкогольная зависимость с синдромом отмены',
                  children: [
                    {
                      value: 'F10.230',
                      title:
                        'F10.230 Алкогольная зависимость с синдромом отмены, неосложненная',
                    },
                    {
                      value: 'F10.231',
                      title:
                        'F10.231 Алкогольная зависимость с делирием отмены.',
                    },
                    {
                      value: 'F10.232',
                      title:
                        'F10.232 Алкогольная зависимость с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F10.239',
                      title:
                        'F10.239 Алкогольная зависимость с синдромом отмены, неуточненная',
                    },
                  ],
                },
                {
                  value: 'F10.24',
                  title:
                    'F10.24 Алкогольная зависимость с алкогольно-индуцированным аффективным расстройством',
                },
                {
                  value: 'F10.25',
                  title:
                    'F10.25 Алкогольная зависимость с алкогольно-индуцированным психотическим расстройством',
                  children: [
                    {
                      value: 'F10.250',
                      title:
                        'F10.250 Алкогольная зависимость с алкогольно-индуцированным психотическим расстройством с бредом',
                    },
                    {
                      value: 'F10.251',
                      title:
                        'F10.251 Алкогольная зависимость с алкогольно-индуцированным психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F10.259',
                      title:
                        'F10.259 Алкогольная зависимость с алкогольно-индуцированным психотическим расстройством, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F10.26',
                  title:
                    'F10.26 Алкогольная зависимость с индуцированным алкоголем персистирующим амнестическим расстройством',
                },
                {
                  value: 'F10.27',
                  title:
                    'F10.27 Алкогольная зависимость с индуцированной алкоголем персистирующей деменцией',
                },
                {
                  value: 'F10.28',
                  title:
                    'F10.28 Алкогольная зависимость с другими алкогольно-индуцированными расстройствами',
                  children: [
                    {
                      value: 'F10.280',
                      title:
                        'F10.280 Алкогольная зависимость с алкогольно-индуцированным тревожным расстройством',
                    },
                    {
                      value: 'F10.281',
                      title:
                        'F10.281 Алкогольная зависимость с алкогольной сексуальной дисфункцией',
                    },
                    {
                      value: 'F10.282',
                      title:
                        'F10.282 Алкогольная зависимость с алкогольным расстройством сна',
                    },
                    {
                      value: 'F10.288',
                      title:
                        'F10.288 Алкогольная зависимость с другими алкогольно-индуцированными расстройствами',
                    },
                  ],
                },
                {
                  value: 'F10.29',
                  title:
                    'F10.29 Алкогольная зависимость с неуточненным алкогольно-индуцированным расстройством',
                },
              ],
            },
            {
              value: 'F10.9',
              title: 'F10.9 Алкогольное употребление, неуточненное',
              children: [
                {
                  value: 'F10.90',
                  title:
                    'F10.90 Алкогольное употребление, неуточненное, неосложненное',
                },
                {
                  value: 'F10.91',
                  title:
                    'F10.91 Алкогольное употребление, неуточненное, в ремиссии',
                },
                {
                  value: 'F10.92',
                  title:
                    'F10.92 Алкогольное употребление, неуточненное, с опьянением',
                  children: [
                    {
                      value: 'F10.920',
                      title:
                        'F10.920 Алкогольное употребление, неуточненное, с опьянением, неосложненное',
                    },
                    {
                      value: 'F10.921',
                      title:
                        'F10.921 Алкогольное употребление, неуточненное, с опьянением, с делирием отмены',
                    },
                    {
                      value: 'F10.929',
                      title:
                        'F10.929 Употребление алкоголя неуточненное в сочетании с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F10.93',
                  title:
                    'F10.93 Алкогольное употребление, неуточненное, с синдромом отмены',
                  children: [
                    {
                      value: 'F10.930',
                      title:
                        'F10.930 Алкогольное употребление, неуточненное, с синдромом отмены, неосложненное',
                    },
                    {
                      value: 'F10.931',
                      title:
                        'F10.931 Употребление алкоголя неуточненное с делирием отмены.',
                    },
                    {
                      value: 'F10.932',
                      title:
                        'F10.932 Употребление алкоголя неуточненное с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F10.939',
                      title:
                        'F10.939 Употребление алкоголя неуточненное с абстинентным синдромом, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F10.94',
                  title:
                    'F10.94 Употребление алкоголя неуточненное в сочетании с вызванным алкоголем расстройством настроения',
                },
                {
                  value: 'F10.95',
                  title:
                    'F10.95 Употребление алкоголя неуточненное в сочетании с алкогольным психотическим расстройством',
                  children: [
                    {
                      value: 'F10.950',
                      title:
                        'F10.950 Употребление алкоголя неуточненное с вызванным алкоголем психотическим расстройством с бредом',
                    },
                    {
                      value: 'F10.951',
                      title:
                        'F10.951 Употребление алкоголя неуточненное с вызванным алкоголем психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F10.959',
                      title:
                        'F10.959 Употребление алкоголя неуточненное с вызванным алкоголем психотическим расстройством, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F10.96',
                  title:
                    'F10.96 Употребление алкоголя неуточненное с вызванным алкоголем персистирующим амнестическим расстройством',
                },
                {
                  value: 'F10.97',
                  title:
                    'F10.97 Употребление алкоголя неуточненное при персистирующей вызванной алкоголем деменции',
                },
                {
                  value: 'F10.98',
                  title:
                    'F10.98 Употребление алкоголя неуточненное с другими вызванными алкоголем расстройствами',
                  children: [
                    {
                      value: 'F10.980',
                      title:
                        'F10.980 Употребление алкоголя неуточненное с вызванным алкоголем тревожным расстройством',
                    },
                    {
                      value: 'F10.981',
                      title:
                        'F10.981 Употребление алкоголя неуточненное с вызванной алкоголем сексуальной дисфункцией',
                    },
                    {
                      value: 'F10.982',
                      title:
                        'F10.982 Употребление алкоголя неуточненное с вызванным алкоголем нарушением сна',
                    },
                    {
                      value: 'F10.988',
                      title:
                        'F10.988 Употребление алкоголя неуточненное с другими вызванными алкоголем расстройствами',
                    },
                  ],
                },
                {
                  value: 'F10.99',
                  title:
                    'F10.99 Употребление алкоголя неуточненное с неуточненным вызванным алкоголем расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F11',
          title:
            'F11 Психические и поведенческие расстройства, обусловленные употреблением опиоидов',
          children: [
            {
              value: 'F11.1',
              title: 'F11.1 Злоупотребление опиоидами',
              children: [
                {
                  value: 'F11.10',
                  title: 'F11.10 Злоупотребление опиоидами, неосложненное',
                },
                {
                  value: 'F11.11',
                  title: 'F11.11 Злоупотребление опиоидами, в ремиссии',
                },
                {
                  value: 'F11.12',
                  title: 'F11.12 Злоупотребление опиоидами с интоксикацией',
                  children: [
                    {
                      value: 'F11.120',
                      title:
                        'F11.120 Злоупотребление опиоидами с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F11.121',
                      title:
                        'F11.121 Злоупотребление опиоидами с интоксикационным делирием.',
                    },
                    {
                      value: 'F11.122',
                      title:
                        'F11.122 Злоупотребление опиоидами с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F11.129',
                      title:
                        'F11.129 Злоупотребление опиоидами с интоксикацией, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F11.13',
                  title: 'F11.13 Злоупотребление опиоидами с синдромом отмены',
                },
                {
                  value: 'F11.14',
                  title:
                    'F11.14 Злоупотребление опиоидами с вызванным опиоидами аффективным расстройством',
                },
                {
                  value: 'F11.15',
                  title:
                    'F11.15 Злоупотребление опиоидами с вызванным опиоидами психотическим расстройством',
                  children: [
                    {
                      value: 'F11.150',
                      title:
                        'F11.150 Злоупотребление опиоидами с вызванным опиоидами психотическим расстройством с бредом',
                    },
                    {
                      value: 'F11.151',
                      title:
                        'F11.151 Злоупотребление опиоидами с вызванным опиоидами психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F11.159',
                      title:
                        'F11.159 Злоупотребление опиоидами с вызванным опиоидами психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F11.18',
                  title:
                    'F11.18 Злоупотребление опиоидами с другим вызванным опиоидами расстройством',
                  children: [
                    {
                      value: 'F11.181',
                      title:
                        'F11.181 Злоупотребление опиоидами с сексуальной дисфункцией, вызванной опиоидами',
                    },
                    {
                      value: 'F11.182',
                      title:
                        'F11.182 Злоупотребление опиоидами с вызванным опиоидами нарушением сна',
                    },
                    {
                      value: 'F11.188',
                      title:
                        'F11.188 Злоупотребление опиоидами с другим вызванным опиоидами расстройством',
                    },
                  ],
                },
                {
                  value: 'F11.19',
                  title:
                    'F11.19 Злоупотребление опиоидами с неуточненным расстройством, вызванным опиоидами',
                },
              ],
            },
            {
              value: 'F11.2',
              title: 'F11.2 Опиоидная зависимость',
              children: [
                {
                  value: 'F11.20',
                  title: 'F11.20 Опиоидная зависимость, неосложненная',
                },
                {
                  value: 'F11.21',
                  title: 'F11.21 Опиоидная зависимость, в ремиссии',
                },
                {
                  value: 'F11.22',
                  title: 'F11.22 Опиоидная зависимость с интоксикацией',
                  children: [
                    {
                      value: 'F11.220',
                      title:
                        'F11.220 Опиоидная зависимость с интоксикацией, неосложненная',
                    },
                    {
                      value: 'F11.221',
                      title:
                        'F11.221 Опиоидная зависимость с интоксикационным делирием',
                    },
                    {
                      value: 'F11.222',
                      title:
                        'F11.222 Опиоидная зависимость с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F11.229',
                      title:
                        'F11.229 Опиоидная зависимость с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F11.23',
                  title: 'F11.23 Опиоидная зависимость с синдромом отмены',
                },
                {
                  value: 'F11.24',
                  title:
                    'F11.24 Опиоидная зависимость с вызванным опиоидами аффективным расстройством',
                },
                {
                  value: 'F11.25',
                  title:
                    'F11.25 Опиоидная зависимость с вызванным опиоидами психотическим расстройством',
                  children: [
                    {
                      value: 'F11.250',
                      title:
                        'F11.250 Опиоидная зависимость с вызванным опиоидами психотическим расстройством с бредом',
                    },
                    {
                      value: 'F11.251',
                      title:
                        'F11.251 Опиоидная зависимость с вызванным опиоидами психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F11.259',
                      title:
                        'F11.259 Опиоидная зависимость с вызванным опиоидами психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F11.28',
                  title:
                    'F11.28 Опиоидная зависимость с другим вызванным опиоидами расстройством',
                  children: [
                    {
                      value: 'F11.281',
                      title:
                        'F11.281 Опиоидная зависимость с сексуальной дисфункцией, вызванной опиоидами',
                    },
                    {
                      value: 'F11.282',
                      title:
                        'F11.282 Опиоидная зависимость с вызванным опиоидами нарушением сна',
                    },
                    {
                      value: 'F11.288',
                      title:
                        'F11.288 Опиоидная зависимость с другим вызванным опиоидами расстройством',
                    },
                  ],
                },
                {
                  value: 'F11.29',
                  title:
                    'F11.29 Опиоидная зависимость с неуточненным вызванным опиоидами расстройством',
                },
              ],
            },
            {
              value: 'F11.9',
              title: 'F11.9 Употребление опиоидов, неуточненное.',
              children: [
                {
                  value: 'F11.90',
                  title:
                    'F11.90 Употребление опиоидов, неуточненное, неосложненное',
                },
                {
                  value: 'F11.91',
                  title:
                    'F11.91 Употребление опиоидов, неуточненное, в ремиссии',
                },
                {
                  value: 'F11.92',
                  title:
                    'F11.92 Употребление опиоидов, неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F11.920',
                      title:
                        'F11.920 Употребление опиоидов, неуточненное, с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F11.921',
                      title:
                        'F11.921 Употребление опиоидов, неуточненное, с интоксикационным делирием',
                    },
                    {
                      value: 'F11.922',
                      title:
                        'F11.922 Употребление опиоидов, неуточненное, с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F11.929',
                      title:
                        'F11.929 Употребление опиоидов, неуточненное, с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F11.93',
                  title:
                    'F11.93 Употребление опиоидов, неуточненное, с синдромом отмены',
                },
                {
                  value: 'F11.94',
                  title:
                    'F11.94 Употребление опиоидов, неуточненное, с вызванным опиоидами аффективным расстройством',
                },
                {
                  value: 'F11.95',
                  title:
                    'F11.95 Употребление опиоидов, неуточненное, с вызванным опиоидами психотическим расстройством',
                  children: [
                    {
                      value: 'F11.950',
                      title:
                        'F11.950 Употребление опиоидов, неуточненное, с вызванным опиоидами психотическим расстройством с бредом',
                    },
                    {
                      value: 'F11.951',
                      title:
                        'F11.951 Употребление опиоидов, неуточненное, с вызванным опиоидами психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F11.959',
                      title:
                        'F11.959 Употребление опиоидов, неуточненное, с вызванным опиоидами психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F11.98',
                  title:
                    'F11.98 Употребление опиоидов, неуточненное, с другим вызванным опиоидами расстройством',
                  children: [
                    {
                      value: 'F11.981',
                      title:
                        'F11.981 Употребление опиоидов неуточненное с сексуальной дисфункцией, вызванной опиоидами',
                    },
                    {
                      value: 'F11.982',
                      title:
                        'F11.982 Употребление опиоидов неуточненное с нарушением сна, вызванным опиоидами',
                    },
                    {
                      value: 'F11.988',
                      title:
                        'F11.988 Употребление опиоидов, неуточненное, с другим вызванным опиоидами расстройством',
                    },
                  ],
                },
                {
                  value: 'F11.99',
                  title:
                    'F11.99 Употребление опиоидов, неуточненное, с неуточненным вызванным опиоидами расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F12',
          title:
            'F12 Психические и поведенческие расстройства, вызванные употреблением каннабиоидов',
          children: [
            {
              value: 'F12.1',
              title: 'F12.1 Злоупотребление каннабиоидами',
              children: [
                {
                  value: 'F12.10',
                  title: 'F12.10 Злоупотребление каннабисом неосложненное',
                },
                {
                  value: 'F12.11',
                  title: 'F12.11 Злоупотребление каннабисом в стадии ремиссии',
                },
                {
                  value: 'F12.12',
                  title: 'F12.12 Злоупотребление каннабисом с интоксикацией',
                  children: [
                    {
                      value: 'F12.120',
                      title:
                        'F12.120 Злоупотребление каннабисом с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F12.121',
                      title:
                        'F12.121 Злоупотребление каннабисом с интоксикационным делирием.',
                    },
                    {
                      value: 'F12.122',
                      title:
                        'F12.122 Злоупотребление каннабисом с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F12.129',
                      title:
                        'F12.129 Злоупотребление каннабисом с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F12.13',
                  title: 'F12.13 Злоупотребление каннабисом с синдромом отмены',
                },
                {
                  value: 'F12.15',
                  title:
                    'F12.15 Злоупотребление каннабисом с психотическим расстройством',
                  children: [
                    {
                      value: 'F12.150',
                      title:
                        'F12.150 Злоупотребление каннабисом с психотическим расстройством с бредом',
                    },
                    {
                      value: 'F12.151',
                      title:
                        'F12.151 Злоупотребление каннабисом с психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F12.159',
                      title:
                        'F12.159 Злоупотребление каннабисом с неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.18',
                  title:
                    'F12.18 Злоупотребление каннабисом с другим вызванным каннабисом расстройством',
                  children: [
                    {
                      value: 'F12.180',
                      title:
                        'F12.180 Злоупотребление каннабисом с вызванным каннабисом тревожным расстройством',
                    },
                    {
                      value: 'F12.188',
                      title:
                        'F12.188 Злоупотребление каннабисом с другим вызванным каннабисом расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.19',
                  title:
                    'F12.19 Злоупотребление каннабисом с неуточненным вызванным каннабисом расстройством',
                },
              ],
            },
            {
              value: 'F12.2',
              title: 'F12.2 Зависимость от каннабиоидов',
              children: [
                {
                  value: 'F12.20',
                  title: 'F12.20 Зависимость от каннабиоидов, неосложненная',
                },
                {
                  value: 'F12.21',
                  title: 'F12.21 Зависимость от каннабиоидов в стадии ремиссии',
                },
                {
                  value: 'F12.22',
                  title: 'F12.22 Зависимость от каннабиоидов с интоксикацией',
                  children: [
                    {
                      value: 'F12.220',
                      title:
                        'F12.220 Зависимость от каннабиоидов с неосложненной интоксикацией',
                    },
                    {
                      value: 'F12.221',
                      title:
                        'F12.221 Зависимость от каннабиоидов с интоксикационным делирием',
                    },
                    {
                      value: 'F12.222',
                      title:
                        'F12.222 Зависимость от каннабиоидов с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F12.229',
                      title:
                        'F12.229 Зависимость от каннабиоидов с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F12.23',

                  title:
                    'F12.23 Зависимость от каннабиоидов с синдромом отмены',
                },
                {
                  value: 'F12.25',
                  title:
                    'F12.25 Зависимость от каннабиоидов с психотическим расстройством',
                  children: [
                    {
                      value: 'F12.250',
                      title:
                        'F12.250 Зависимость от каннабиоидов с психотическим расстройством с бредом',
                    },
                    {
                      value: 'F12.251',
                      title:
                        'F12.251 Зависимость от каннабиоидов с психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F12.259',
                      title:
                        'F12.259 Зависимость от каннабиоидов с неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.28',
                  title:
                    'F12.28 Зависимость от каннабиоидов с другим вызванным каннабиоидами расстройством',
                  children: [
                    {
                      value: 'F12.280',
                      title:
                        'F12.280 Зависимость от каннабиоидов с вызванным каннабиоидами тревожным расстройством',
                    },
                    {
                      value: 'F12.288',
                      title:
                        'F12.288 Зависимость от каннабиоидов с другим вызванным каннабиоидами расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.29',
                  title:
                    'F12.29 Зависимость от каннабиоидов с неуточненным вызванным каннабиоидами расстройством',
                },
              ],
            },
            {
              value: 'F12.9',
              title: 'F12.9 Употребление каннабиса неуточненное',
              children: [
                {
                  value: 'F12.90',
                  title:
                    'F12.90 Употребление каннабиса неуточненное, неосложненное',
                },
                {
                  value: 'F12.91',
                  title:
                    'F12.91 Употребление каннабиса неуточненное в стадии ремиссии',
                },
                {
                  value: 'F12.92',
                  title:
                    'F12.92 Употребление каннабиса неуточненное с интоксикацией',
                  children: [
                    {
                      value: 'F12.920',
                      title:
                        'F12.920 Употребление каннабиса неуточненное с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F12.921',
                      title:
                        'F12.921 Употребление каннабиса неуточненное с интоксикацией, с интоксикационным делирием',
                    },
                    {
                      value: 'F12.922',
                      title:
                        'F12.922 Употребление каннабиса неуточненное с интоксикацией, с нарушением восприятия',
                    },
                    {
                      value: 'F12.929',
                      title:
                        'F12.929 Употребление каннабиса неуточненное с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F12.93',
                  title:
                    'F12.93 Употребление каннабиса неуточненное с синдромом отмены',
                },
                {
                  value: 'F12.95',
                  title:
                    'F12.95 Употребление каннабиса неуточненное с психотическим расстройством',
                  children: [
                    {
                      value: 'F12.950',
                      title:
                        'F12.950 Употребление каннабиса неуточненное с психотическим расстройством с бредом',
                    },
                    {
                      value: 'F12.951',
                      title:
                        'F12.951 Употребление каннабиса неуточненное с психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F12.959',
                      title:
                        'F12.959 Употребление каннабиса неуточненное с неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.98',
                  title:
                    'F12.98 Употребление каннабиса неуточненное с другим вызванным каннабиоидами расстройством',
                  children: [
                    {
                      value: 'F12.980',
                      title:
                        'F12.980 Употребление каннабиса неуточненное с тревожным расстройством',
                    },
                    {
                      value: 'F12.988',
                      title:
                        'F12.988 Употребление каннабиса неуточненное с другим вызванным каннабиоидами расстройством',
                    },
                  ],
                },
                {
                  value: 'F12.99',
                  title:
                    'F12.99 Употребление каннабиса неуточненное с неуточненным вызванным каннабиоидами расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F13',
          title:
            'F13 Психические и поведенческие расстройства, вызванные употреблением седативных, гипнотических или анксиолитических препаратов',

          children: [
            {
              value: 'F13.1',
              title:
                'F13.1 Злоупотребление седативными, снотворными или анксиолитическими средствами',
              children: [
                {
                  value: 'F13.10',
                  title:
                    'F13.10 Злоупотребление седативными, снотворными или анксиолитическими средствами, неосложненное',
                },
                {
                  value: 'F13.11',
                  title:
                    'F13.11 Злоупотребление седативными, снотворными или анксиолитическими средствами, в стадии ремиссии',
                },
                {
                  value: 'F13.12',
                  title:
                    'F13.12 Злоупотребление седативными, снотворными или анксиолитическими средствами с интоксикацией',
                  children: [
                    {
                      value: 'F13.120',
                      title:
                        'F13.120 Злоупотребление седативными, снотворными или анксиолитическими средствами с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F13.121',
                      title:
                        'F13.121 Злоупотребление седативными, снотворными или анксиолитическими средствами с интоксикацией, с интоксикационным делирием',
                    },
                    {
                      value: 'F13.129',
                      title:
                        'F13.129 Злоупотребление седативными, снотворными или анксиолитическими средствами с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F13.13',
                  title:
                    'F13.13 Злоупотребление седативными, снотворными или анксиолитическими средствами с синдромом отмены',
                  children: [
                    {
                      value: 'F13.130',
                      title:
                        'F13.130 Злоупотребление седативными, снотворными или анксиолитическими средствами с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F13.131',
                      title:
                        'F13.131 Злоупотребление седативными, снотворными или анксиолитическими средствами с делирием отмены',
                    },
                    {
                      value: 'F13.132',
                      title:
                        'F13.132 Злоупотребление седативными, снотворными или анксиолитическими средствами с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F13.139',
                      title:
                        'F13.139 Злоупотребление седативными, снотворными или анксиолитическими средствами с неуточненным синдромом отмены',
                    },
                  ],
                },
                {
                  value: 'F13.14',
                  title:
                    'F13.14 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F13.15',
                  title:
                    'F13.15 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F13.150',
                      title:
                        'F13.150 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F13.151',
                      title:
                        'F13.151 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F13.159',
                      title:
                        'F13.159 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F13.18',
                  title:
                    'F13.18 Злоупотребление седативными, снотворными или анксиолитическими средствами с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F13.180',
                      title:
                        'F13.180 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F13.181',
                      title:
                        'F13.181 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванной ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F13.182',
                      title:
                        'F13.182 Злоупотребление седативными, снотворными или анксиолитическими средствами с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F13.188',
                      title:
                        'F13.188 Злоупотребление седативными, снотворными или анксиолитическими средствами с другими вызванными ими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F13.19',
                  title:
                    'F13.19 Злоупотребление седативными, снотворными или анксиолитическими средствами с неуточненным вызванным ими расстройством',
                },
              ],
            },
            {
              value: 'F13.2',
              title:
                'F13.2 Зависимость от седативных, снотворных или анксиолитических средств',
              children: [
                {
                  value: 'F13.20',
                  title:
                    'F13.20 Зависимость от седативных, снотворных или анксиолитических средств неосложненная',
                },
                {
                  value: 'F13.21',
                  title:
                    'F13.21 Зависимость от седативных, снотворных или анксиолитических средств в ремиссии',
                },
                {
                  value: 'F13.22',
                  title:
                    'F13.22 Зависимость от седативных, снотворных или анксиолитических средств с интоксикацией',
                  children: [
                    {
                      value: 'F13.220',
                      title:
                        'F13.220 Зависимость от седативных, снотворных или анксиолитических средств с неосложненной интоксикацией',
                    },
                    {
                      value: 'F13.221',
                      title:
                        'F13.221 Зависимость от седативных, снотворных или анксиолитических средств с интоксикационным бредом',
                    },
                    {
                      value: 'F13.229',
                      title:
                        'F13.229 Зависимость от седативных, снотворных или анксиолитических средств с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F13.23',
                  title:
                    'F13.23 Зависимость от седативных, снотворных или анксиолитических средств с синдромом отмены',
                  children: [
                    {
                      value: 'F13.230',
                      title:
                        'F13.230 Зависимость от седативных, снотворных или анксиолитических средств с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F13.231',
                      title:
                        'F13.231 Зависимость от седативных, снотворных или анксиолитических средств с синдромом отмены с делирием',
                    },
                    {
                      value: 'F13.232',
                      title:
                        'F13.232 Зависимость от седативных, снотворных или анксиолитических средств с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F13.239',
                      title:
                        'F13.239 Зависимость от седативных, снотворных или анксиолитических средств с неуточненным синдромом отмены',
                    },
                  ],
                },
                {
                  value: 'F13.24',
                  title:
                    'F13.24 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F13.25',
                  title:
                    'F13.25 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F13.250',
                      title:
                        'F13.250 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F13.251',
                      title:
                        'F13.251 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F13.259',
                      title:
                        'F13.259 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F13.26',
                  title:
                    'F13.26 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими персистирующим амнестическим расстройством',
                },
                {
                  value: 'F13.27',
                  title:
                    'F13.27 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими персистирующей деменцией',
                },
                {
                  value: 'F13.28',
                  title:
                    'F13.28 Зависимость от седативных, снотворных или анксиолитических средств с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F13.280',
                      title:
                        'F13.280 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F13.281',
                      title:
                        'F13.281 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F13.282',
                      title:
                        'F13.282 Зависимость от седативных, снотворных или анксиолитических средств с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F13.288',
                      title:
                        'F13.288 Зависимость от седативных, снотворных или анксиолитических средств с другим вызванными ими расстройствам',
                    },
                  ],
                },
                {
                  value: 'F13.29',
                  title:
                    'F13.29 Зависимость от седативных, снотворных или анксиолитических средств с неуточненным вызванным ими расстройством',
                },
              ],
            },
            {
              value: 'F13.9',
              title:
                'F13.9 Использование седативных, снотворных или анксиолитических средств неуточненное.',
              children: [
                {
                  value: 'F13.90',
                  title:
                    'F13.90 Использование седативных, снотворных или анксиолитических средств неуточненное, неосложненное',
                },
                {
                  value: 'F13.91',
                  title:
                    'F13.91 Использование седативных, снотворных или анксиолитических средств неуточненное, в ремиссии',
                },
                {
                  value: 'F13.92',
                  title:
                    'F13.92 Использование седативных, снотворных или анксиолитических средств неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F13.920',
                      title:
                        'F13.920 Использование седативных, снотворных или анксиолитических средств неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F13.921',
                      title:
                        'F13.921 Применение седативных, снотворных или анксиолитических средств неуточненное с интоксикационным делирием',
                    },
                    {
                      value: 'F13.929',
                      title:
                        'F13.929 Применение седативных, снотворных или анксиолитических средств неуточненное с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F13.93',
                  title:
                    'F13.93 Применение седативных, снотворных или анксиолитических средств неуточненное с синдромом отмены',
                  children: [
                    {
                      value: 'F13.930',
                      title:
                        'F13.930 Применение седативных, снотворных или анксиолитических средств неуточненное с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F13.931',
                      title:
                        'F13.931 Использование седативных, снотворных или анксиолитических средств неуточненное с делирием отмены',
                    },
                    {
                      value: 'F13.932',
                      title:
                        'F13.932 Применение седативных, снотворных или анксиолитических средств неуточненное с синдромом отмены с нарушениями восприятия',
                    },
                    {
                      value: 'F13.939',
                      title:
                        'F13.939 Применение седативных, снотворных или анксиолитических средств неуточненное с синдромом отмены неуточненным',
                    },
                  ],
                },
                {
                  value: 'F13.94',
                  title:
                    'F13.94 Использование седативных, снотворных или анксиолитических средств неуточненное с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F13.95',
                  title:
                    'F13.95 Использование седативных, снотворных или анксиолитических средств неуточненное с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F13.950',
                      title:
                        'F13.950 Применение седативных, снотворных или анксиолитических средств неуточненное с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F13.951',
                      title:
                        'F13.951 Использование седативных, снотворных или анксиолитических средств неуточненное с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F13.959',
                      title:
                        'F13.959 Использование седативных, снотворных или анксиолитических средств неуточненное с вызванным ими психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F13.96',
                  title:
                    'F13.96 Использование седативных, снотворных или анксиолитических средств неуточненное с вызванным ими амнестическим расстройством',
                },
                {
                  value: 'F13.97',
                  title:
                    'F13.97 Использование седативных, снотворных или анксиолитических средств неуточненное при персистирующей деменции, вызванной седативными, снотворными или анксиолитическими средствами',
                },
                {
                  value: 'F13.98',
                  title:
                    'F13.98 Использование седативных, снотворных или анксиолитических средств неуточненное с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F13.980',
                      title:
                        'F13.980 Применение седативных, снотворных или анксиолитических средств неуточненное с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F13.981',
                      title:
                        'F13.981 Применение седативных, снотворных или анксиолитических средств неуточненное с вызванной ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F13.982',
                      title:
                        'F13.982 Применение седативных, снотворных или анксиолитических средств неуточненное с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F13.988',
                      title:
                        'F13.988 Применение седативных, снотворных или анксиолитических средств неуточненное с другими вызванными ими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F13.99',
                  title:
                    'F13.99 Использование седативных, снотворных или анксиолитических средств неуточненное с неуточненным вызванным ими расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F14',
          title:
            'Психические и поведенческие расстройства, вызванные употреблением кокаина',
          children: [
            {
              value: 'F14.1',
              title: 'F14.1 Злоупотребление кокаином',
              children: [
                {
                  value: 'F14.10',
                  title: 'F14.10 Злоупотребление кокаином неосложненное',
                },
                {
                  value: 'F14.11',
                  title: 'F14.11 Злоупотребление кокаином в стадии ремиссии',
                },
                {
                  value: 'F14.12',
                  title: 'F14.12 Злоупотребление кокаином с интоксикацией',
                  children: [
                    {
                      value: 'F14.120',
                      title:
                        'F14.120 Злоупотребление кокаином с неосложненной интоксикацией',
                    },
                    {
                      value: 'F14.121',
                      title:
                        'F14.121 Злоупотребление кокаином с интоксикацией с бредом',
                    },
                    {
                      value: 'F14.122',
                      title:
                        'F14.122 Злоупотребление кокаином с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F14.129',
                      title:
                        'F14.129 Злоупотребление кокаином с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F14.13',
                  title:
                    'F14.13 Злоупотребление кокаином неуточненное с абстиненцией',
                },
                {
                  value: 'F14.14',
                  title:
                    'F14.14 Злоупотребление кокаином с вызванным им аффективным расстройством',
                },
                {
                  value: 'F14.15',
                  title:
                    'F14.15 Злоупотребление кокаином с вызванным им психотическим расстройством',
                  children: [
                    {
                      value: 'F14.150',
                      title:
                        'F14.150 Злоупотребление кокаином с вызванным им психотическим расстройством с бредом',
                    },
                    {
                      value: 'F14.151',
                      title:
                        'F14.151 Злоупотребление кокаином с вызванным им психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F14.159',
                      title:
                        'F14.159 Злоупотребление кокаином с вызванным им неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F14.18',
                  title:
                    'F14.18 Злоупотребление кокаином с другими вызванными им расстройствами',
                  children: [
                    {
                      value: 'F14.180',
                      title:
                        'F14.180 Злоупотребление кокаином с вызванным им тревожным расстройством',
                    },
                    {
                      value: 'F14.181',
                      title:
                        'F14.181 Злоупотребление кокаином с вызванной кокаином сексуальной дисфункцией',
                    },
                    {
                      value: 'F14.182',
                      title:
                        'F14.182 Злоупотребление кокаином с вызванным им нарушением сна',
                    },
                    {
                      value: 'F14.188',
                      title:
                        'F14.188 Злоупотребление кокаином с другими вызванными им расстройствами',
                    },
                  ],
                },
                {
                  value: 'F14.19',
                  title:
                    'F14.19 Злоупотребление кокаином с неуточненным вызванным им расстройством',
                },
              ],
            },
            {
              value: 'F14.2',
              title: 'F14.2 Зависимость от кокаина',
              children: [
                {
                  value: 'F14.20',
                  title: 'F14.20 Зависимость от кокаина неосложненная',
                },
                {
                  value: 'F14.21',
                  title: 'F14.21 Зависимость от кокаина в стадии ремиссии',
                },
                {
                  value: 'F14.22',
                  title: 'F14.22 Зависимость от кокаина с интоксикацией',
                  children: [
                    {
                      value: 'F14.220',
                      title:
                        'F14.220 Зависимость от кокаина с неосложненной интоксикацией',
                    },
                    {
                      value: 'F14.221',
                      title:
                        'F14.221 Зависимость от кокаина с интоксикацией с бредом',
                    },
                    {
                      value: 'F14.222',
                      title:
                        'F14.222 Зависимость от кокаина с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F14.229',
                      title:
                        'F14.229 Зависимость от кокаина с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F14.23',
                  title: 'F14.23 Зависимость от кокаина с синдромом отмены',
                },
                {
                  value: 'F14.24',
                  title:
                    'F14.24 Зависимость от кокаина с вызванным им аффективным расстройством',
                },
                {
                  value: 'F14.25',
                  title:
                    'F14.25 Зависимость от кокаина с вызванным им психотическим расстройством',
                  children: [
                    {
                      value: 'F14.250',
                      title:
                        'F14.250 Зависимость от кокаина с вызванным им психотическим расстройством с бредом',
                    },
                    {
                      value: 'F14.251',
                      title:
                        'F14.251 Зависимость от кокаина с вызванным им психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F14.259',
                      title:
                        'F14.259 Зависимость от кокаина с вызванным им неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F14.28',
                  title:
                    'F14.28 Зависимость от кокаина с другим вызванным им расстройством',
                  children: [
                    {
                      value: 'F14.280',
                      title:
                        'F14.280 Зависимость от кокаина с вызванным им тревожным расстройством',
                    },
                    {
                      value: 'F14.281',
                      title:
                        'F14.281 Зависимость от кокаина с вызванной им сексуальной дисфункцией',
                    },
                    {
                      value: 'F14.282',
                      title:
                        'F14.282 Зависимость от кокаина с вызванным им нарушением сна',
                    },
                    {
                      value: 'F14.288',
                      title:
                        'F14.288 Зависимость от кокаина с другим вызванным им расстройством',
                    },
                  ],
                },
                {
                  value: 'F14.29',
                  title:
                    'F14.29 Зависимость от кокаина с неуточненным вызванным им расстройством',
                },
              ],
            },
            {
              value: 'F14.9',
              title: 'F14.9 Употребление кокаина неуточненное',
              children: [
                {
                  value: 'F14.90',
                  title:
                    'F14.90 Употребление кокаина неуточненное, неосложненное',
                },
                {
                  value: 'F14.91',
                  title:
                    'F14.91 Употребление кокаина неуточненное, в стадии ремиссии',
                },
                {
                  value: 'F14.92',
                  title:
                    'F14.92 Употребление кокаина неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F14.920',
                      title:
                        'F14.920 Употребление кокаина неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F14.921',
                      title:
                        'F14.921 Употребление кокаина неуточненное, с интоксикацией с бредом',
                    },
                    {
                      value: 'F14.922',
                      title:
                        'F14.922 Употребление кокаина неуточненное, с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F14.929',
                      title:
                        'F14.929 Употребление кокаина неуточненное, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F14.93',
                  title:
                    'F14.93 Употребление кокаина неуточненное, с синдромом отмены',
                },
                {
                  value: 'F14.94',
                  title:
                    'F14.94 Употребление кокаина неуточненное, с вызванным им аффективным расстройством',
                },
                {
                  value: 'F14.95',
                  title:
                    'F14.95 Употребление кокаина неуточненное, с вызванным им психотическим расстройством',
                  children: [
                    {
                      value: 'F14.950',
                      title:
                        'F14.950 Употребление кокаина неуточненное, с вызванным им психотическим расстройством с бредом',
                    },
                    {
                      value: 'F14.951',
                      title:
                        'F14.951 Употребление кокаина неуточненное, с вызванным им психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F14.959',
                      title:
                        'F14.959 Употребление кокаина неуточненное, с вызванным им неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F14.98',
                  title:
                    'F14.98 Употребление кокаина неуточненное, с другим уточненным вызванным им расстройством',
                  children: [
                    {
                      value: 'F14.980',
                      title:
                        'F14.980 Употребление кокаина неуточненное, с вызванным им тревожным расстройством',
                    },
                    {
                      value: 'F14.981',
                      title:
                        'F14.981 Употребление кокаина неуточненное, с вызванной им сексуальной дисфункцией',
                    },
                    {
                      value: 'F14.982',
                      title:
                        'F14.982 Употребление кокаина неуточненное, с вызванным им нарушением сна',
                    },
                    {
                      value: 'F14.988',
                      title:
                        'F14.988 Употребление кокаина неуточненное, с другим уточненным вызванным им расстройством',
                    },
                  ],
                },
                {
                  value: 'F14.99',
                  title:
                    'F14.99 Употребление кокаина неуточненное, с неуточненным вызванным им расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F15',
          title:
            'F15 Психические и поведенческие расстройства, вызванные употреблением других стимуляторов',
          children: [
            {
              value: 'F15.1',
              titleEn: 'F15.1 Other stimulant abuse',
              title: 'F15.1 Злоупотребление другими стимуляторами',
              children: [
                {
                  value: 'F15.10',
                  title:
                    'F15.10 Злоупотребление другими стимуляторами, неосложненное',
                },
                {
                  value: 'F15.11',
                  title:
                    'F15.11 Злоупотребление другими стимуляторами, в стадии ремиссии',
                },
                {
                  value: 'F15.12',
                  title:
                    'F15.12 Злоупотребление другими стимуляторами, с интоксикацией',
                  children: [
                    {
                      value: 'F15.120',
                      title:
                        'F15.120 Злоупотребление другими стимуляторами, с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F15.121',
                      title:
                        'F15.121 Злоупотребление другими стимуляторами, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F15.122',
                      title:
                        'F15.122 Злоупотребление другими стимуляторами, с интоксикацией, с нарушением восприятия',
                    },
                    {
                      value: 'F15.129',
                      title:
                        'F15.129 Злоупотребление другими стимуляторами, с интоксикацией, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F15.13',
                  title:
                    'F15.13 Злоупотребление другими стимуляторами, с абстиненцией',
                },
                {
                  value: 'F15.14',
                  title:
                    'F15.14 Злоупотребление другими стимуляторами, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F15.15',
                  title:
                    'F15.15 Злоупотребление другими стимуляторами, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F15.150',
                      title:
                        'F15.150 Злоупотребление другими стимуляторами, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F15.151',
                      title:
                        'F15.151 Злоупотребление другими стимуляторами, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F15.159',
                      title:
                        'F15.159 Злоупотребление другими стимуляторами, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.18',
                  title:
                    'F15.18 Злоупотребление другими стимуляторами, с другим вызванным ими расстройством',
                  children: [
                    {
                      value: 'F15.180',
                      title:
                        'F15.180 Злоупотребление другими стимуляторами, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F15.181',
                      title:
                        'F15.181 Злоупотребление другими стимуляторами с с вызванным ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F15.182',
                      title:
                        'F15.182 Злоупотребление другими стимуляторами, с вызванным ими нарушением сна',
                    },

                    {
                      value: 'F15.188',
                      title:
                        'F15.188 Злоупотребление другими стимуляторами, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.19',
                  title:
                    'F15.19 Злоупотребление другими стимуляторами, с вызванным ими неуточненным расстройством',
                },
              ],
            },
            {
              value: 'F15.2',
              title: 'F15.2 Зависимость от других стимуляторов',
              children: [
                {
                  value: 'F15.20',
                  title:
                    'F15.20 Зависимость от других стимуляторов, неосложненная',
                },
                {
                  value: 'F15.21',
                  title:
                    'F15.21 Зависимость от других стимуляторов, в стадии ремиссии',
                },
                {
                  value: 'F15.22',
                  title:
                    'F15.22 Зависимость от других стимуляторов, с интоксикацией',
                  children: [
                    {
                      value: 'F15.220',
                      title:
                        'F15.220 Зависимость от других стимуляторов, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F15.221',
                      title:
                        'F15.221 Зависимость от других стимуляторов, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F15.222',
                      title:
                        'F15.222 Зависимость от других стимуляторов, с интоксикацией, с нарушением восприятия',
                    },
                    {
                      value: 'F15.229',
                      title:
                        'F15.229 Зависимость от других стимуляторов, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F15.23',
                  title:
                    'F15.23 Зависимость от других стимуляторов, с синдромом отмены',
                },
                {
                  value: 'F15.24',
                  title:
                    'F15.24 Зависимость от других стимуляторов, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F15.25',
                  title:
                    'F15.25 Зависимость от других стимуляторов, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F15.250',
                      title:
                        'F15.250 Зависимость от других стимуляторов, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F15.251',
                      title:
                        'F15.251 Зависимость от других стимуляторов, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F15.259',
                      title:
                        'F15.259 Зависимость от других стимуляторов, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.28',
                  title:
                    'F15.28 Зависимость от других стимуляторов, с другим вызванным ими расстройством',
                  children: [
                    {
                      value: 'F15.280',
                      title:
                        'F15.280 Зависимость от других стимуляторов, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F15.281',
                      title:
                        'F15.281 Зависимость от других стимуляторов, с вызванным ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F15.282',
                      title:
                        'F15.282 Зависимость от других стимуляторов, с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F15.288',
                      title:
                        'F15.288 Зависимость от других стимуляторов, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.29',
                  title:
                    'F15.29 Зависимость от других стимуляторов, с вызванным ими неуточненным расстройством',
                },
              ],
            },
            {
              value: 'F15.9',
              title: 'F15.9 Использование других стимуляторов, неуточненное',
              children: [
                {
                  value: 'F15.90',
                  title:
                    'F15.90 Использование других стимуляторов, неуточненное, неосложненное',
                },
                {
                  value: 'F15.91',
                  title:
                    'F15.91 Использование других стимуляторов, неуточненное, в стадии ремиссии',
                },
                {
                  value: 'F15.92',
                  title:
                    'F15.92 Использование других стимуляторов, неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F15.920',
                      title:
                        'F15.920 Использование других стимуляторов, неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F15.921',
                      title:
                        'F15.921 Использование других стимуляторов, неуточненное, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F15.922',
                      title:
                        'F15.922 Использование других стимуляторов, неуточненное, с интоксикацией, с нарушением восприятия',
                    },
                    {
                      value: 'F15.929',
                      title:
                        'F15.929 Использование других стимуляторов, неуточненное, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F15.93',
                  title:
                    'F15.93 Использование других стимуляторов, неуточненное, с синдромом отмены',
                },
                {
                  value: 'F15.94',
                  title:
                    'F15.94 Использование других стимуляторов, неуточненное, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F15.95',
                  title:
                    'F15.95 Использование других стимуляторов, неуточненное, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F15.950',
                      title:
                        'F15.950 Использование других стимуляторов, неуточненное, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F15.951',
                      title:
                        'F15.951 Использование других стимуляторов, неуточненное, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F15.959',
                      title:
                        'F15.959 Использование других стимуляторов, неуточненное, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.98',
                  title:
                    'F15.98 Использование других стимуляторов, неуточненное, с другим вызванным ими расстройством',
                  children: [
                    {
                      value: 'F15.980',
                      title:
                        'F15.980 Использование других стимуляторов, неуточненное, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F15.981',
                      title:
                        'F15.981 Использование других неуточненных стимуляторов с вызванным ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F15.982',
                      title:
                        'F15.982 Использование других стимуляторов, неуточненное, с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F15.988',
                      title:
                        'F15.988 Использование других стимуляторов, неуточненное, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F15.99',
                  title:
                    'F15.99 Использование других стимуляторов, неуточненное, с неуточненным вызванным ими расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F16',
          title:
            'F16 Психические и поведенческие расстройства, вызванные употреблением галлюциногенов',
          children: [
            {
              value: 'F16.1',
              title: 'F16.1 Злоупотребление галлюциногенами',
              children: [
                {
                  value: 'F16.10',
                  title:
                    'F16.10 Злоупотребление галлюциногенами, неосложненное',
                },
                {
                  value: 'F16.11',
                  title:
                    'F16.11 Злоупотребление галлюциногенами, в стадии ремиссии',
                },
                {
                  value: 'F16.12',
                  title:
                    'F16.12 Злоупотребление галлюциногенами, с интоксикацией',
                  children: [
                    {
                      value: 'F16.120',
                      title:
                        'F16.120 Злоупотребление галлюциногенами, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F16.121',
                      title:
                        'F16.121 Злоупотребление галлюциногенами, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F16.122',
                      title:
                        'F16.122 Злоупотребление галлюциногенами, с интоксикацией, с нарушением восприятия',
                    },
                    {
                      value: 'F16.129',
                      title:
                        'F16.129 Злоупотребление галлюциногенами, с интоксикацией, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F16.14',
                  title:
                    'F16.14 Злоупотребление галлюциногенами, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F16.15',
                  title:
                    'F16.15 Злоупотребление галлюциногенами, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F16.150',
                      title:
                        'F16.150 Злоупотребление галлюциногенами, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F16.151',
                      title:
                        'F16.151 Злоупотребление галлюциногенами, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F16.159',
                      title:
                        'F16.159 Злоупотребление галлюциногенами, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.18',
                  title:
                    'F16.18 Злоупотребление галлюциногенами, с другим вызванным ими расстройством',
                  children: [
                    {
                      value: 'F16.180',
                      title:
                        'F16.180 Злоупотребление галлюциногенами, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F16.183',
                      title:
                        'F16.183 Злоупотребление галлюциногенами, с вызванным ими персистирующим нарушением восприятия (флэшбэки)',
                    },
                    {
                      value: 'F16.188',
                      title:
                        'F16.188 Злоупотребление галлюциногенами, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.19',
                  title:
                    'F16.19 Злоупотребление галлюциногенами, с неуточненным вызванным ими расстройством',
                },
              ],
            },
            {
              value: 'F16.2',
              title: 'F16.2 Зависимость от галлюциногенов',
              children: [
                {
                  value: 'F16.20',
                  title: 'F16.20 Зависимость от галлюциногенов, неосложненная',
                },
                {
                  value: 'F16.21',
                  title:
                    'F16.21 Зависимость от галлюциногенов, в стадии ремиссии',
                },
                {
                  value: 'F16.22',
                  title:
                    'F16.22 Зависимость от галлюциногенов, с интоксикацией',
                  children: [
                    {
                      value: 'F16.220',
                      title:
                        'F16.220 Зависимость от галлюциногенов, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F16.221',
                      title:
                        'F16.221 Зависимость от галлюциногенов, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F16.229',
                      title:
                        'F16.229 Зависимость от галлюциногенов, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F16.24',
                  title:
                    'F16.24 Зависимость от галлюциногенов, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F16.25',
                  title:
                    'F16.25 Зависимость от галлюциногенов, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F16.250',
                      title:
                        'F16.250 Зависимость от галлюциногенов, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F16.251',
                      title:
                        'F16.251 Зависимость от галлюциногенов, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F16.259',
                      title:
                        'F16.259 Зависимость от галлюциногенов, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.28',
                  title:
                    'F16.28 Зависимость от галлюциногенов, с другим вызванным ими расстройством',
                  children: [
                    {
                      value: 'F16.280',
                      title:
                        'F16.280 Зависимость от галлюциногенов, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F16.283',
                      title:
                        'F16.283 Зависимость от галлюциногенов, с вызванным ими персистирующим нарушением восприятия (флэшбэки)',
                    },
                    {
                      value: 'F16.288',
                      title:
                        'F16.288 Зависимость от галлюциногенов, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.29',
                  title:
                    'F16.29 Зависимость от галлюциногенов, с неуточненным вызванным ими расстройством',
                },
              ],
            },
            {
              value: 'F16.9',
              title: 'F16.9 Употребление галлюциногенов, неуточненное.',
              children: [
                {
                  value: 'F16.90',
                  title:
                    'F16.90 Употребление галлюциногенов, неуточненное, неосложненное',
                },
                {
                  value: 'F16.91',
                  title:
                    'F16.91 Употребление галлюциногенов, неуточненное, в стадии ремиссии',
                },
                {
                  value: 'F16.92',
                  title:
                    'F16.92 Употребление галлюциногенов, неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F16.920',
                      title:
                        'F16.920 Употребление галлюциногенов, неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F16.921',
                      title:
                        'F16.921 Употребление галлюциногенов, неуточненное, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F16.929',
                      title:
                        'F16.929 Употребление галлюциногенов, неуточненное, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F16.94',
                  title:
                    'F16.94 Употребление галлюциногенов, неуточненное, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F16.95',
                  title:
                    'F16.95 Употребление галлюциногенов, неуточненное, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F16.950',
                      title:
                        'F16.950 Употребление галлюциногенов, неуточненное, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F16.951',
                      title:
                        'F16.951 Употребление галлюциногенов, неуточненное, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F16.959',
                      title:
                        'F16.959 Употребление галлюциногенов, неуточненное, с вызванным ими неуточненным психотическим расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.98',
                  title:
                    'F16.98 Употребление галлюциногенов, неуточненное, с другим уточненным вызванным ими расстройством',
                  children: [
                    {
                      value: 'F16.980',
                      title:
                        'F16.980 Употребление галлюциногенов, неуточненное, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F16.983',
                      title:
                        'F16.983 Употребление галлюциногенов, неуточненное, с вызванным ими персистирующим нарушением восприятия (флэшбэки)',
                    },
                    {
                      value: 'F16.988',
                      title:
                        'F16.988 Употребление галлюциногенов, неуточненное, с другим вызванным ими расстройством',
                    },
                  ],
                },
                {
                  value: 'F16.99',
                  title:
                    'F16.99 Употребление галлюциногенов, неуточненное, с неуточненным вызванным ими расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F17',
          title: 'F17 Никотиновая зависимость',
          children: [
            {
              value: 'F17.2',
              title: 'F17.2 Никотиновая зависимость',
              children: [
                {
                  value: 'F17.20',
                  title: 'F17.20 Никотиновая зависимость, неуточненная',
                  children: [
                    {
                      value: 'F17.200',
                      title:
                        'F17.200 Никотиновая зависимость, неуточненная, неосложненная',
                    },
                    {
                      value: 'F17.201',
                      title:
                        'F17.201 Никотиновая зависимость, неуточненная, в стадии ремиссии',
                    },
                    {
                      value: 'F17.203',
                      title:
                        'F17.203 Никотиновая зависимость, неуточненная, с синдромом отмены',
                    },
                    {
                      value: 'F17.208',
                      title:
                        'F17.208 Никотиновая зависимость, неуточненная, с другими вызванными ею расстройствами',
                    },
                    {
                      value: 'F17.209',
                      title:
                        'F17.209 Никотиновая зависимость, неуточненная, с неуточненными вызванными ею расстройствами',
                    },
                  ],
                },
                {
                  value: 'F17.21',
                  title: 'F17.21 Никотиновая зависимость, сигаретная',
                  children: [
                    {
                      value: 'F17.210',
                      title:
                        'F17.210 Никотиновая зависимость, сигаретная, неосложненная',
                    },
                    {
                      value: 'F17.211',
                      title:
                        'F17.211 Никотиновая зависимость, сигаретная, в стадии ремиссии',
                    },
                    {
                      value: 'F17.213',
                      title:
                        'F17.213 Никотиновая зависимость, сигаретная, с синдромом отмены',
                    },
                    {
                      value: 'F17.218',
                      title:
                        'F17.218 Никотиновая зависимость, сигаретная, с другими вызванными ею расстройствами',
                    },
                    {
                      value: 'F17.219',
                      title:
                        'F17.219 Никотиновая зависимость, сигаретная, с неуточненными вызванными ею расстройствами',
                    },
                  ],
                },
                {
                  value: 'F17.22',
                  title: 'F17.22 Никотиновая зависимость, жевательного табака',
                  children: [
                    {
                      value: 'F17.220',
                      title:
                        'F17.220 Никотиновая зависимость, жевательного табака, неосложненная',
                    },
                    {
                      value: 'F17.221',
                      title:
                        'F17.221 Никотиновая зависимость, жевательного табака, в стадии ремиссии',
                    },
                    {
                      value: 'F17.223',
                      title:
                        'F17.223 Никотиновая зависимость, жевательного табака, с синдромом отмены',
                    },
                    {
                      value: 'F17.228',
                      title:
                        'F17.228 Никотиновая зависимость, жевательного табака, с другими вызванными ею расстройствами',
                    },
                    {
                      value: 'F17.229',
                      title:
                        'F17.229 Никотиновая зависимость, жевательного табака, с неуточненными вызванными ею расстройствами',
                    },
                  ],
                },
                {
                  value: 'F17.29',
                  title:
                    'F17.29 Никотиновая зависимость, других табачных изделий',
                  children: [
                    {
                      value: 'F17.290',
                      title:
                        'F17.290 Никотиновая зависимость, других табачных изделий, неосложненная',
                    },
                    {
                      value: 'F17.291',
                      title:
                        'F17.291 Никотиновая зависимость, других табачных изделий, в стадии ремиссии',
                    },
                    {
                      value: 'F17.293',
                      title:
                        'F17.293 Никотиновая зависимость, других табачных изделий, с синдромом отмены',
                    },
                    {
                      value: 'F17.298',
                      title:
                        'F17.298 Никотиновая зависимость, других табачных изделий, с другими вызванными ею расстройствами',
                    },
                    {
                      value: 'F17.299',
                      title:
                        'F17.299 Никотиновая зависимость, других табачных изделий, с неуточненными вызванными ею расстройствами',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          value: 'F18',
          title:
            'F18 Психические и поведенческие расстройства, вызванные употреблением летучих растворителей',
          children: [
            {
              value: 'F18.1',
              title: 'F18.1 Злоупотребление летучими растворителями',
              children: [
                {
                  value: 'F18.10',
                  title:
                    'F18.10 Злоупотребление летучими растворителями, неосложненное',
                },
                {
                  value: 'F18.11',
                  title:
                    'F18.11 Злоупотребление летучими растворителями, в стадии ремиссии',
                },
                {
                  value: 'F18.12',
                  title:
                    'F18.12 Злоупотребление летучими растворителями с интоксикацией',
                  children: [
                    {
                      value: 'F18.120',
                      title:
                        'F18.120 Злоупотребление летучими растворителями с интоксикацией, неосложненное',
                    },
                    {
                      value: 'F18.121',
                      title:
                        'F18.121 Злоупотребление летучими растворителями с интоксикацией, с делирием',
                    },
                    {
                      value: 'F18.129',
                      title:
                        'F18.129 Злоупотребление летучими растворителями с интоксикацией, неуточненное',
                    },
                  ],
                },
                {
                  value: 'F18.14',
                  title:
                    'F18.14 Злоупотребление летучими растворителями с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F18.15',
                  title:
                    'F18.15 Злоупотребление летучими растворителями с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F18.150',
                      title:
                        'F18.150 Злоупотребление летучими растворителями с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F18.151',
                      title:
                        'F18.151 Злоупотребление летучими растворителями с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F18.159',
                      title:
                        'F18.159 Злоупотребление летучими растворителями с вызванным ими психотическим расстройством, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F18.17',
                  title:
                    'F18.17 Злоупотребление летучими растворителями с вызванным ими деменцией',
                },
                {
                  value: 'F18.18',
                  title:
                    'F18.18 Злоупотребление летучими растворителями с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F18.180',
                      title:
                        'F18.180 Злоупотребление летучими растворителями с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F18.188',
                      title:
                        'F18.188 Злоупотребление летучими растворителями с другими вызванными ими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F18.19',
                  title:
                    'F18.19 Злоупотребление летучими растворителями с неуточненными вызванными ими расстройствами',
                },
              ],
            },
            {
              value: 'F18.2',
              title: 'F18.2 Зависимосоть от летучих растворителей',
              children: [
                {
                  value: 'F18.20',
                  title:
                    'F18.20 Зависимость от летучих растворителей, неосложненная',
                },
                {
                  value: 'F18.21',
                  title:
                    'F18.21 Зависимость от летучих растворителей, в стадии ремиссии',
                },
                {
                  value: 'F18.22',
                  title:
                    'F18.22 Зависимость от летучих растворителей с интоксикацией',
                  children: [
                    {
                      value: 'F18.220',
                      title:
                        'F18.220 Зависимость от летучих растворителей с неосложненной интоксикацией',
                    },
                    {
                      value: 'F18.221',
                      title:
                        'F18.221 Зависимость от летучих растворителей с интоксикацией, с делирием',
                    },
                    {
                      value: 'F18.229',
                      title:
                        'F18.229 Зависимость от летучих растворителей с интоксикацией, неуточненная',
                    },
                  ],
                },
                {
                  value: 'F18.24',
                  title:
                    'F18.24 Зависимость от летучих растворителей с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F18.25',
                  title:
                    'F18.25 Зависимость от летучих растворителей с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F18.250',
                      title:
                        'F18.250 Зависимость от летучих растворителей с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F18.251',
                      title:
                        'F18.251 Зависимость от летучих растворителей с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F18.259',
                      title:
                        'F18.259 Зависимость от летучих растворителей с вызванным ими психотическим расстройством, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F18.27',
                  title:
                    'F18.27 Зависимость от летучих растворителей с вызванным ими деменцией',
                },
                {
                  value: 'F18.28',
                  title:
                    'F18.28 Зависимость от летучих растворителей с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F18.280',
                      title:
                        'F18.280 Зависимость от летучих растворителей с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F18.288',
                      title:
                        'F18.288 Зависимость от летучих растворителей с другими вызванными ими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F18.29',
                  title:
                    'F18.29 Зависимость от летучих растворителей с неуточненным вызванными ими расстройством',
                },
              ],
            },
            {
              value: 'F18.9',
              title:
                'F18.9 Злоупотребление летучими растворителями, неуточненное',
              children: [
                {
                  value: 'F18.90',
                  title:
                    'F18.90 Злоупотребление летучими растворителями, неуточненное, неосложненное',
                },
                {
                  value: 'F18.91',
                  title:
                    'F18.91 Злоупотребление летучими растворителями, неуточненное, в стадии ремиссии',
                },
                {
                  value: 'F18.92',
                  title:
                    'F18.92 Злоупотребление летучими растворителями, неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F18.920',
                      title:
                        'F18.920 Злоупотребление летучими растворителями, неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F18.921',
                      title:
                        'F18.921 Злоупотребление летучими растворителями, неуточненное, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F18.929',
                      title:
                        'F18.929 Злоупотребление летучими растворителями, неуточненное, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F18.94',
                  title:
                    'F18.94 Злоупотребление летучими растворителями, неуточненное, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F18.95',
                  title:
                    'F18.95 Злоупотребление летучими растворителями, неуточненное, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F18.950',
                      title:
                        'F18.950 Злоупотребление летучими растворителями, неуточненное, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F18.951',
                      title:
                        'F18.951 Злоупотребление летучими растворителями, неуточненное, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F18.959',
                      title:
                        'F18.959 Злоупотребление летучими растворителями, неуточненное, с вызванным ими психотическим расстройством, неуточненным',
                    },
                  ],
                },
                {
                  value: 'F18.97',
                  title:
                    'F18.97 Злоупотребление летучими растворителями, неуточненное, с вызванной ими деменцией',
                },
                {
                  value: 'F18.98',
                  title:
                    'F18.98 Злоупотребление летучими растворителями, неуточненное, с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F18.980',
                      title:
                        'F18.980 Злоупотребление летучими растворителями, неуточненное, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F18.988',
                      title:
                        'F18.988 Злоупотребление летучими растворителями, неуточненное, с другими вызванными ими расстройствами',
                    },
                  ],
                },
                {
                  value: 'F18.99',
                  title:
                    'F18.99 Злоупотребление летучими растворителями, неуточненное, с неуточненным вызванным ими расстройством',
                },
              ],
            },
          ],
        },
        {
          value: 'F19',
          title:
            'F19 Психические и поведенческие расстройства, вызванные использованием других психоактивных веществ',
          children: [
            {
              value: 'F19.1',
              title: 'F19.1 Злоупотребление другими психоактивными веществами',
              children: [
                {
                  value: 'F19.10',
                  title:
                    'F19.10 Злоупотребление другими психоактивными веществами, неосложненное',
                },
                {
                  value: 'F19.11',
                  title:
                    'F19.11 Злоупотребление другими психоактивными веществами, в стадии ремиссии',
                },
                {
                  value: 'F19.12',
                  title:
                    'F19.12 Злоупотребление другими психоактивными веществами, с интоксикацией',
                  children: [
                    {
                      value: 'F19.120',
                      title:
                        'F19.120 Злоупотребление другими психоактивными веществами, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F19.121',
                      title:
                        'F19.121 Злоупотребление другими психоактивными веществами, с интоксикацией, с делирием',
                    },
                    {
                      value: 'F19.122',
                      title:
                        'F19.122 Злоупотребление другими психоактивными веществами, с интоксикацией, с нарушениями восприятия',
                    },
                    {
                      value: 'F19.129',
                      title:
                        'F19.129 Злоупотребление другими психоактивными веществами, с неуточненной интоксикацией',
                    },
                  ],
                },
                {
                  value: 'F19.13',
                  title:
                    'F19.13 Злоупотребление другими психоактивными веществами, с синдромом отмены',
                  children: [
                    {
                      value: 'F19.130',
                      title:
                        'F19.130 Злоупотребление другими психоактивными веществами, с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F19.131',
                      title:
                        'F19.131 Злоупотребление другими психоактивными веществами, с синдромом отмены, с делирием',
                    },
                    {
                      value: 'F19.132',
                      title:
                        'F19.132 Злоупотребление другими психоактивными веществами, с синдромом отмены, с нарушениями восприятия',
                    },
                    {
                      value: 'F19.139',
                      title:
                        'F19.139 Злоупотребление другими психоактивными веществами, с неуточненным синдромом отмены',
                    },
                  ],
                },
                {
                  value: 'F19.14',
                  title:
                    'F19.14 Злоупотребление другими психоактивными веществами, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F19.15',
                  title:
                    'F19.15 Злоупотребление другими психоактивными веществами, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F19.150',
                      title:
                        'F19.150 Злоупотребление другими психоактивными веществами, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F19.151',
                      title:
                        'F19.151 Злоупотребление другими психоактивными веществами, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F19.159',
                      title:
                        'F19.159 Злоупотребление другими психоактивными веществами, с вызванным ими психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.16',
                  title:
                    'F19.16 Злоупотребление другими психоактивными веществами, с вызванным ими персистирующим амнестическим расстройством',
                },
                {
                  value: 'F19.17',
                  title:
                    'F19.17 Злоупотребление другими психоактивными веществами, с вызванной ими персистирующей деменцией',
                },
                {
                  value: 'F19.18',
                  title:
                    'F19.18 Злоупотребление другими психоактивными веществами, с вызванными ими другими расстройствами',
                  children: [
                    {
                      value: 'F19.180',
                      title:
                        'F19.180 Злоупотребление другими психоактивными веществами, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F19.181',
                      title:
                        'F19.181 Злоупотребление другими психоактивными веществами, с вызванной ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F19.182',
                      title:
                        'F19.182 Злоупотребление другими психоактивными веществами, с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F19.188',
                      title:
                        'F19.188 Злоупотребление другими психоактивными веществами, с вызванным ими другим расстройством',
                    },
                  ],
                },
                {
                  value: 'F19.19',
                  title:
                    'F19.19 Злоупотребление другими психоактивными веществами, с вызванным ими расстройством неуточненным',
                },
              ],
            },
            {
              value: 'F19.2',
              title: 'F19.2 Зависимость от других психоактивных веществ',
              children: [
                {
                  value: 'F19.20',
                  title:
                    'F19.20 Зависимость от других психоактивных веществ, неосложненная',
                },
                {
                  value: 'F19.21',
                  title:
                    'F19.21 Зависимость от других психоактивных веществ, в стадии ремиссии',
                },
                {
                  value: 'F19.22',
                  title:
                    'F19.22 Зависимость от других психоактивных веществ, с интоксикацией',
                  children: [
                    {
                      value: 'F19.220',
                      title:
                        'F19.220 Зависимость от других психоактивных веществ, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F19.221',
                      title:
                        'F19.221 Зависимость от других психоактивных веществ с интоксикационным делирием.',
                    },
                    {
                      value: 'F19.222',
                      title:
                        'F19.222 Зависимость от других психоактивных веществ с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F19.229',
                      title:
                        'F19.229 Зависимость от других психоактивных веществ с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F19.23',
                  title:
                    'F19.23 Зависимость от других психоактивных веществ, с синдромом отмены',
                  children: [
                    {
                      value: 'F19.230',
                      title:
                        'F19.230 Зависимость от других психоактивных веществ, с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F19.231',
                      title:
                        'F19.231 Зависимость от других психоактивных веществ, с синдромом отмены с делирием',
                    },
                    {
                      value: 'F19.232',
                      title:
                        'F19.232 Зависимость от других психоактивных веществ, с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F19.239',
                      title:
                        'F19.239 Зависимость от других психоактивных веществ, с синдромом отмены неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.24',
                  title:
                    'F19.24 Зависимость от других психоактивных веществ, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F19.25',
                  title:
                    'F19.25 Зависимость от других психоактивных веществ, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F19.250',
                      title:
                        'F19.250 Зависимость от других психоактивных веществ, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F19.251',
                      title:
                        'F19.251 Зависимость от других психоактивных веществ, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F19.259',
                      title:
                        'F19.259 Зависимость от других психоактивных веществ, с вызванным ими психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.26',
                  title:
                    'F19.26 Зависимость от других психоактивных веществ, с вызванным ими персистирующим амнестическим расстройством',
                },
                {
                  value: 'F19.27',
                  title:
                    'F19.27 Зависимость от других психоактивных веществ, с вызванным ими персистирующим деменциальным расстройством',
                },
                {
                  value: 'F19.28',
                  title:
                    'F19.28 Зависимость от других психоактивных веществ, с вызванным ими другими расстройствами',
                  children: [
                    {
                      value: 'F19.280',
                      title:
                        'F19.280 Зависимость от других психоактивных веществ, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F19.281',
                      title:
                        'F19.281 Зависимость от других психоактивных веществ, с вызванной ими с сексуальной дисфункцией',
                    },
                    {
                      value: 'F19.282',
                      title:
                        'F19.282 Зависимость от других психоактивных веществ, с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F19.288',
                      title:
                        'F19.288 Зависимость от других психоактивных веществ, с вызванным ими другим расстройством',
                    },
                  ],
                },
                {
                  value: 'F19.29',
                  title:
                    'F19.29 Зависимость от других психоактивных веществ, с вызванным ими расстройством неуточненным',
                },
              ],
            },
            {
              value: 'F19.9',
              title:
                'F19.9 Употребление других психоактивных веществ, неуточненное',
              children: [
                {
                  value: 'F19.90',
                  title:
                    'F19.90 Употребление других психоактивных веществ, неуточненное, неосложненное',
                },
                {
                  value: 'F19.91',
                  title:
                    'F19.91 Употребление других психоактивных веществ, неуточненное в стадии ремиссии',
                },
                {
                  value: 'F19.92',
                  title:
                    'F19.92 Употребление других психоактивных веществ, неуточненное, с интоксикацией',
                  children: [
                    {
                      value: 'F19.920',
                      title:
                        'F19.920 Употребление других психоактивных веществ, неуточненное, с неосложненной интоксикацией',
                    },
                    {
                      value: 'F19.921',
                      title:
                        'F19.921 Употребление других психоактивных веществ, неуточненное, с интоксикацией с делирием',
                    },
                    {
                      value: 'F19.922',
                      title:
                        'F19.922 Употребление других психоактивных веществ, неуточненное, с интоксикацией с нарушением восприятия',
                    },
                    {
                      value: 'F19.929',
                      title:
                        'F19.929 Употребление других психоактивных веществ, неуточненное, с интоксикацией неуточненной',
                    },
                  ],
                },
                {
                  value: 'F19.93',
                  title:
                    'F19.93 Употребление других психоактивных веществ, неуточненное, с синдромом отмены',
                  children: [
                    {
                      value: 'F19.930',
                      title:
                        'F19.930 Употребление других психоактивных веществ, неуточненное, с неосложненным синдромом отмены',
                    },
                    {
                      value: 'F19.931',
                      title:
                        'F19.931 Употребление других психоактивных веществ, неуточненное, с синдромом отмены с делирием',
                    },
                    {
                      value: 'F19.932',
                      title:
                        'F19.932 Употребление других психоактивных веществ, неуточненное, с синдромом отмены с нарушением восприятия',
                    },
                    {
                      value: 'F19.939',
                      title:
                        'F19.939 Употребление других психоактивных веществ, неуточненное, с синдромом отмены неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.94',
                  title:
                    'F19.94 Употребление других психоактивных веществ, неуточненное, с вызванным ими аффективным расстройством',
                },
                {
                  value: 'F19.95',
                  title:
                    'F19.95 Употребление других психоактивных веществ, неуточненное, с вызванным ими психотическим расстройством',
                  children: [
                    {
                      value: 'F19.950',
                      title:
                        'F19.950 Употребление других психоактивных веществ, неуточненное, с вызванным ими психотическим расстройством с бредом',
                    },
                    {
                      value: 'F19.951',
                      title:
                        'F19.951 Употребление других психоактивных веществ, неуточненное, с вызванным ими психотическим расстройством с галлюцинациями',
                    },
                    {
                      value: 'F19.959',
                      title:
                        'F19.959 Употребление других психоактивных веществ, неуточненное, с вызванным ими психотическим расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.96',
                  title:
                    'F19.96 Употребление других психоактивных веществ, неуточненное, с вызванным ими ерсистирующим амнестическим расстройством',
                },
                {
                  value: 'F19.97',
                  title:
                    'F19.97 Употребление других психоактивных веществ, неуточненное, с вызванным ими персистирующей деменцией',
                },
                {
                  value: 'F19.98',
                  title:
                    'F19.98 Употребление других психоактивных веществ, неуточненное, с другими вызванными ими расстройствами',
                  children: [
                    {
                      value: 'F19.980',
                      title:
                        'F19.980 Употребление других психоактивных веществ, неуточненное, с вызванным ими тревожным расстройством',
                    },
                    {
                      value: 'F19.981',
                      title:
                        'F19.981 Употребление других психоактивных веществ, неуточненное, с вызванной ими сексуальной дисфункцией',
                    },
                    {
                      value: 'F19.982',
                      title:
                        'F19.982 Употребление других психоактивных веществ, неуточненное, с вызванным ими нарушением сна',
                    },
                    {
                      value: 'F19.988',
                      title:
                        'F19.988 Употребление других психоактивных веществ, неуточненное, с другим вызванным ими расстройством неуточненным',
                    },
                  ],
                },
                {
                  value: 'F19.99',
                  title:
                    'F19.99 Употребление других психоактивных веществ, неуточненное, с вызванным ими расстройством неуточненным',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: 'F20-F29',
      title:
        'F20-F29 Шизофрения, шизотипические, бредовые расстройства и другие неаффективные психотические расстройства',
      children: [
        {
          value: 'F20',
          title: 'F20 Шизофрения',
          children: [
            {
              value: 'F20.0',
              title: 'F20.0 Параноидная шизофрения',
            },
            {
              value: 'F20.1',
              title: 'F20.1 Гебефреническая шизофрения',
            },
            {
              value: 'F20.2',
              title: 'F20.2 Кататоническая шизофрения',
            },
            {
              value: 'F20.3',
              title: 'F20.3 Недифференцированная шизофрения',
            },
            {
              value: 'F20.5',
              title: 'F20.5 Остаточная шизофрения',
            },
            {
              value: 'F20.8',
              title: 'F20.8 Другой тип шизофрении',
              children: [
                {
                  value: 'F20.81',
                  title: 'F20.81 Шизофреноподобное расстройство',
                },
                {
                  value: 'F20.89',
                  title: 'F20.89 Другой тип шизофрении',
                },
              ],
            },
            {
              value: 'F20.9',
              title: 'F20.9 Шизофрения неуточненная',
            },
          ],
        },
        {
          value: 'F21',
          title: 'F21 Шизотипическое расстройство',
        },
        {
          value: 'F22',
          title: 'F22 Бредовые расстройства',
        },
        {
          value: 'F23',
          title: 'F23 Острые и преходящие психотические расстройства',
        },
        {
          value: 'F24',
          title: 'F24 Индуцированное бредовое расстройство',
        },
        {
          value: 'F25',
          title: 'F25 Шизоаффективные расстройства',
          children: [
            {
              value: 'F25.0',
              title: 'F25.0 Шизоаффективное расстройство, биполярный тип',
            },
            {
              value: 'F25.1',
              title: 'F25.1 Шизоаффективное расстройство, депрессивный тип',
            },
            {
              value: 'F25.8',
              title: 'F25.8 Другие шизоаффективные расстройства',
            },
            {
              value: 'F25.9',
              title: 'F25.9 Шизоаффективное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F28',
          title:
            'F28 Другие неорганические психотические расстройства, не связанные с приемом психоактивного вещества',
        },
        {
          value: 'F29',
          title:
            'F29 Неорганический психоз неуточненный, не связанный с приемом психоактивного вещества',
        },
      ],
    },
    {
      value: 'F30-F39',
      title: 'F30-F39 Расстройства настроения [Аффективные расстройства]',
      children: [
        {
          value: 'F30',
          title: 'F30 Маниакальный эпизод',
          children: [
            {
              value: 'F30.1',
              title: 'F30.1 Маниакальный эпизод без психотических симптомов',
              children: [
                {
                  value: 'F30.10',
                  title:
                    'F30.10 Маниакальный эпизод без психотических симптомов, неуточненный',
                },
                {
                  value: 'F30.11',
                  title:
                    'F30.11 Маниакальный эпизод без психотических симптомов, легкий',
                },
                {
                  value: 'F30.12',
                  title:
                    'F30.12 Маниакальный эпизод без психотических симптомов, умеренный',
                },
                {
                  value: 'F30.13',
                  title:
                    'F30.13 Маниакальный эпизод без психотических симптомов, тяжелый',
                },
              ],
            },
            {
              value: 'F30.2',
              title:
                'F30.2 Маниакальный эпизод, тяжелый с психотическими симптомами',
            },
            {
              value: 'F30.3',
              title: 'F30.3 Маниакальный эпизод в частичной ремиссии',
            },
            {
              value: 'F30.4',
              title: 'F30.4 Маниакальный эпизод в полной ремиссии',
            },
            {
              value: 'F30.8',
              title: 'F30.8 Другие маниакальные эпизоды',
            },
            {
              value: 'F30.9',
              title: 'F30.9 Маниакальный эпизод неуточненный',
            },
          ],
        },
        {
          value: 'F31',
          title: 'F31 Биполярное аффективное расстройство',
          children: [
            {
              value: 'F31.0',
              title:
                'F31.0 Биполярное аффективное расстройство, текущий эпизод гипомании',
            },
            {
              value: 'F31.1',
              title:
                'F31.1 Биполярное аффективное расстройство, текущий эпизод мании без психотических симптомов',
              children: [
                {
                  value: 'F31.10',
                  title:
                    'F31.10 Биполярное аффективное расстройство, текущий эпизод мании без психотических симптомов, неуточненный',
                },
                {
                  value: 'F31.11',
                  title:
                    'F31.11 Биполярное аффективное расстройство, текущий эпизод мании без психотических симптомов, легкий',
                },
                {
                  value: 'F31.12',
                  title:
                    'F31.12 Биполярное аффективное расстройство, текущий эпизод мании без психотических симптомов, умеренный',
                },
                {
                  value: 'F31.13',
                  title:
                    'F31.13 Биполярное аффективное расстройство, текущий эпизод мании без психотических симптомов, тяжелый',
                },
              ],
            },
            {
              value: 'F31.2',
              title:
                'F31.2 Биполярное аффективное расстройство, текущий эпизод мании с психотическими симптомами',
            },
            {
              value: 'F31.3',
              title:
                'F31.3 Биполярное аффективное расстройство, текущий эпизод депрессии легкой или умеренной тяжести',
              children: [
                {
                  value: 'F31.30',
                  title:
                    'F31.30 Биполярное аффективное расстройство, текущий эпизод депрессии легкой или умеренной тяжести, неуточненный',
                },
                {
                  value: 'F31.31',
                  title:
                    'F31.31 Биполярное аффективное расстройство, текущий эпизод депрессии, легкий',
                },
                {
                  value: 'F31.32',
                  title:
                    'F31.32 Биполярное аффективное расстройство, текущий эпизод депрессии, умеренный',
                },
              ],
            },
            {
              value: 'F31.4',
              title:
                'F31.4 Биполярное аффективное расстройство, текущий эпизод депрессии тяжелый без психотических симптомов',
            },
            {
              value: 'F31.5',
              title:
                'F31.5 Биполярное аффективное расстройство, текущий эпизод депрессии тяжелый с психотическими симптомами',
            },
            {
              value: 'F31.6',
              title:
                'F31.6 Биполярное аффективное расстройство, текущий эпизод смешанного характера',
              children: [
                {
                  value: 'F31.60',
                  title:
                    'F31.60 Биполярное аффективное расстройство, текущий эпизод смешанного характера, неуточненный',
                },
                {
                  value: 'F31.61',
                  title:
                    'F31.61 Биполярное аффективное расстройство, текущий эпизод смешанного характера, легкий',
                },
                {
                  value: 'F31.62',
                  title:
                    'F31.62 Биполярное аффективное расстройство, текущий эпизод смешанного характера, умеренный',
                },
                {
                  value: 'F31.63',
                  title:
                    'F31.63 Биполярное аффективное расстройство, текущий эпизод смешанного характера, тяжелый без психотических симптомов',
                },
                {
                  value: 'F31.64',
                  title:
                    'F31.64 Биполярное аффективное расстройство, текущий эпизод смешанного характера, тяжелый с психотическими симптомами',
                },
              ],
            },
            {
              value: 'F31.7',
              title:
                'F31.7 Биполярное аффективное расстройство, текущая ремиссия',
              children: [
                {
                  value: 'F31.70',
                  title:
                    'F31.70 Биполярное расстройство, в стадии ремиссии, последний эпизод неуточненный.',
                },
                {
                  value: 'F31.71',
                  title:
                    'F31.71 Биполярное расстройство, в частичной ремиссии, последний эпизод гипоманический',
                },
                {
                  value: 'F31.72',
                  title:
                    'F31.72 Биполярное расстройство, в полной ремиссии, последний эпизод маниакальный',
                },
                {
                  value: 'F31.73',
                  title:
                    'F31.73 Биполярное расстройство, в частичной ремиссии, последний эпизод маниакальный',
                },
                {
                  value: 'F31.74',
                  title:
                    'F31.74 Биполярное расстройство, в полной ремиссии, последний эпизод маниакальный',
                },
                {
                  value: 'F31.75',
                  title:
                    'F31.75 Биполярное расстройство, в частичной ремиссии, последний эпизод депрессивный',
                },
                {
                  value: 'F31.76',
                  title:
                    'F31.76 Биполярное расстройство, в полной ремиссии, последний эпизод депрессивный',
                },
                {
                  value: 'F31.77',
                  title:
                    'F31.77 Биполярное расстройство, в частичной ремиссии, последний эпизод смешанного характера',
                },
                {
                  value: 'F31.78',
                  title:
                    'F31.78 Биполярное расстройство, в полной ремиссии, последний эпизод смешанного характера',
                },
              ],
            },
            {
              value: 'F31.8',
              title: 'F31.8 Другие биполярные аффективные расстройства',
              children: [
                {
                  value: 'F31.81',
                  title: 'F31.81 Биполярное расстройство II типа',
                },
                {
                  value: 'F31.89',
                  title: 'F31.89 Другие биполярные аффективные расстройства',
                },
              ],
            },
            {
              value: 'F31.9',
              title: 'F31.9 Биполярное аффективное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F32',
          title: 'F32 Депрессивный эпизод',
          children: [
            {
              value: 'F32.0',
              title:
                'F32.0 Большое депрессивное расстройство, единичный эпизод, легкий',
            },
            {
              value: 'F32.1',
              title:
                'F32.1 Большое депрессивное расстройство, единичный эпизод, умеренный',
            },
            {
              value: 'F32.2',
              title:
                'F32.2 Большое депрессивное расстройство, единичный эпизод, тяжелый без психотических симптомов',
            },
            {
              value: 'F32.3',
              title:
                'F32.3 Большое депрессивное расстройство, единичный эпизод, тяжелый с психотическими симптомами',
            },
            {
              value: 'F32.4',
              title:
                'F32.4 Большое депрессивное расстройство, единичный эпизод, в частичной ремиссии',
            },
            {
              value: 'F32.5',
              title:
                'F32.5 Большое депрессивное расстройство, единичный эпизод, в полной ремиссии',
            },
            {
              value: 'F32.8',
              title: 'F32.8 Другие депрессивные эпизоды',
              children: [
                {
                  value: 'F32.81',
                  title:
                    'F32.81 Дисфорическое расстройство, связанное с менструальным циклом',
                },
                {
                  value: 'F32.89',
                  title: 'F32.89 Другие уточненные депрессивные эпизоды',
                },
              ],
            },
            {
              value: 'F32.9',
              title:
                'F32.9 Большое депрессивное расстройство, единичный эпизод, неуточненный',
            },
            {
              value: 'F32.A',
              title: 'F32.A Депрессия, неуточненная',
            },
          ],
        },
        {
          value: 'F33',
          title: 'F33 Большое депрессивное расстройство, рекуррентное',
          children: [
            {
              value: 'F33.0',
              title:
                'F33.0 Большое депрессивное расстройство, рекуррентное, легкое',
            },
            {
              value: 'F33.1',
              title:
                'F33.1 Большое депрессивное расстройство, рекуррентное, умеренное',
            },
            {
              value: 'F33.2',
              title:
                'F33.2 Большое депрессивное расстройство, рекуррентное, тяжелое без психотических симптомов',
            },
            {
              value: 'F33.3',
              title:
                'F33.3 Большое депрессивное расстройство, рекуррентное, тяжелое с психотическими симптомами',
            },
            {
              value: 'F33.4',
              title:
                'F33.4 Большое депрессивное расстройство, рекуррентное, в ремиссии',
              children: [
                {
                  value: 'F33.40',
                  title:
                    'F33.40 Большое депрессивное расстройство, рекуррентное, в ремиссии, неуточненное',
                },
                {
                  value: 'F33.41',
                  title:
                    'F33.41 Большое депрессивное расстройство, рекуррентное, в частичной ремиссии',
                },
                {
                  value: 'F33.42',
                  title:
                    'F33.42 Большое депрессивное расстройство, рекуррентное, в полной ремиссии',
                },
              ],
            },
            {
              value: 'F33.8',
              title: 'F33.8 Другие рекуррентные депрессивные расстройства',
            },
            {
              value: 'F33.9',
              title:
                'F33.9 Большое депрессивное расстройство, рекуррентное, неуточненное',
            },
          ],
        },
        {
          value: 'F34',
          title:
            'F34 Устойчивые расстройства настроения [аффективные расстройства]',
          children: [
            {
              value: 'F34.0',
              title: 'F34.0 Циклотимия',
            },
            {
              value: 'F34.1',
              title: 'F34.1 Дистимия',
            },
            {
              value: 'F34.8',
              title:
                'F34.8 Другие устойчивые расстройства настроения [аффективные расстройства]',
              children: [
                {
                  value: 'F34.81',
                  title:
                    'F34.81 Деструктивное расстройство дисрегуляции настроения',
                },
                {
                  value: 'F34.89',
                  title:
                    'F34.89 Другие уточненные устойчивые расстройства настроения [аффективные расстройства]',
                },
              ],
            },
            {
              value: 'F34.9',
              title:
                'F34.9 Устойчивое расстройство настроения [аффективное расстройство], неуточненное',
            },
          ],
        },
        {
          value: 'F39',
          title:
            'F39 Неуточненное расстройство настроения [аффективное расстройство]',
        },
      ],
    },
    {
      value: 'F40-F48',
      title:
        'F40-F48 Тревожные, диссоциативные, стрессовые, соматоформные и другие не психотические психические расстройства',
      children: [
        {
          value: 'F40',
          title: 'F40 Фобические тревожные расстройства',
          children: [
            {
              value: 'F40.0',
              title: 'F40.0 Агорафобия',
              children: [
                {
                  value: 'F40.00',
                  title: 'F40.00 Агорафобия, неуточненная',
                },
                {
                  value: 'F40.01',
                  title: 'F40.01 Агорафобия с паническим расстройством',
                },
                {
                  value: 'F40.02',
                  title: 'F40.02 Агорафобия без панического расстройства',
                },
              ],
            },
            {
              value: 'F40.1',
              title: 'F40.1 Социальная фобия',
              children: [
                {
                  value: 'F40.10',
                  title: 'F40.10 Социальная фобия, неуточненная',
                },
                {
                  value: 'F40.11',
                  title: 'F40.11 Социальная фобия, обобщенная',
                },
              ],
            },
            {
              value: 'F40.2',
              title: 'F40.2 Специфические (изолированные) фобии',
              children: [
                {
                  value: 'F40.21',
                  title: 'F40.21 Фобия животных',
                  children: [
                    {
                      value: 'F40.210',
                      title: 'F40.210 Арахнофобия',
                    },
                    {
                      value: 'F40.218',
                      title: 'F40.218 Другие фобии животных',
                    },
                  ],
                },
                {
                  value: 'F40.22',
                  title: 'F40.22 Фобия природной среды',
                  children: [
                    {
                      value: 'F40.220',
                      title: 'F40.220 Фобия грозы',
                    },
                    {
                      value: 'F40.228',
                      title: 'F40.228 Другие фобии природной среды',
                    },
                  ],
                },
                {
                  value: 'F40.23',
                  title: 'F40.23 Фобия крови, инъекций и травм',
                  children: [
                    {
                      value: 'F40.230',
                      title: 'F40.230 Гемофобия',
                    },
                    {
                      value: 'F40.231',
                      title: 'F40.231 Фобия инъекций и трансфузий',
                    },
                    {
                      value: 'F40.232',
                      title: 'F40.232 Фобия другой медицинской помощи',
                    },
                    {
                      value: 'F40.233',
                      title: 'F40.233 Фобия травмы',
                    },
                  ],
                },
                {
                  value: 'F40.24',
                  title: 'F40.24 Ситуационные фобии',
                  children: [
                    {
                      value: 'F40.240',
                      title: 'F40.240 Клаустрофобия',
                    },
                    {
                      value: 'F40.241',
                      title: 'F40.241 Акрофобия',
                    },
                    {
                      value: 'F40.242',
                      title: 'F40.242 Фобия мостов',
                    },
                    {
                      value: 'F40.243',
                      title: 'F40.243 Фобия полета',
                    },
                    {
                      value: 'F40.248',
                      title: 'F40.248 Другие ситуационные фобии',
                    },
                  ],
                },
                {
                  value: 'F40.29',
                  title: 'F40.29 Другая уточненная фобия',
                  children: [
                    {
                      value: 'F40.290',
                      title: 'F40.290 Андрофобия',
                    },
                    {
                      value: 'F40.291',
                      title: 'F40.291 Гинефобия',
                    },
                    {
                      value: 'F40.298',
                      title: 'F40.298 Другая уточненная фобия',
                    },
                  ],
                },
              ],
            },
            {
              value: 'F40.8',
              title: 'F40.8 Другие фобические тревожные расстройства',
            },
            {
              value: 'F40.9',
              title: 'F40.9 Фобическое тревожное расстройство, неуточненное',
            },
          ],
        },
        {
          value: 'F41',
          title: 'F41 Другие тревожные расстройства',
          children: [
            {
              value: 'F41.0',
              title:
                'F41.0 Паническое расстройство [эпизодическая пароксизмальная тревожность]',
            },
            {
              value: 'F41.1',
              title: 'F41.1 Генерализованное тревожное расстройство',
            },
            {
              value: 'F41.3',
              title: 'F41.3 Другие смешанные тревожные расстройства',
            },
            {
              value: 'F41.8',
              title: 'F41.8 Другие уточненные тревожные расстройства',
            },
            {
              value: 'F41.9',
              title: 'F41.9 Тревожное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F42',
          title: 'F42 Обсессивно-компульсивное расстройство',
          children: [
            {
              value: 'F42.2',
              title: 'F42.2 Смешанные навязчивые мысли и действия',
            },
            {
              value: 'F42.3',
              title: 'F42.3 Патологическое накопительство',
            },
            {
              value: 'F42.4',
              title:
                'F42.4 Экскориационное расстройство (невротическое расчесывание кожи)',
            },
            {
              value: 'F42.8',
              title: 'F42.8 Другие обсессивно-компульсивные расстройства',
            },
            {
              value: 'F42.9',
              title: 'F42.9 Обсессивно-компульсивное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F43',
          title: 'F43 Реакция на тяжелый стресс и расстройства адаптации',
          children: [
            {
              value: 'F43.0',
              title: 'F43.0 Острая реакция на стресс',
            },
            {
              value: 'F43.1',
              title: 'F43.1 Посттравматическое стрессовое расстройство (ПТСР)',
              children: [
                {
                  value: 'F43.10',
                  title:
                    'F43.10 Посттравматическое стрессовое расстройство, неуточненное',
                },
                {
                  value: 'F43.11',
                  title:
                    'F43.11 Посттравматическое стрессовое расстройство, острое',
                },
                {
                  value: 'F43.12',
                  title:
                    'F43.12 Посттравматическое стрессовое расстройство, хроническое',
                },
              ],
            },
            {
              value: 'F43.2',
              title: 'F43.2 Расстройства приспособительных реакций',
              children: [
                {
                  value: 'F43.20',
                  title:
                    'F43.20 Расстройство приспособительных реакций, неуточненное',
                },
                {
                  value: 'F43.21',
                  title:
                    'F43.21 Расстройство адаптации с депрессивным настроением',
                },
                {
                  value: 'F43.22',
                  title: 'F43.22 Расстройство адаптации с тревожностью',
                },
                {
                  value: 'F43.23',
                  title:
                    'F43.23 Расстройство адаптации с смешанным тревожным и депрессивным настроением',
                },
                {
                  value: 'F43.24',
                  title: 'F43.24 Расстройство адаптации с нарушением поведения',
                },
                {
                  value: 'F43.25',
                  title:
                    'F43.25 Расстройство адаптации с смешанным нарушением эмоций и поведения',
                },
                {
                  value: 'F43.29',
                  title: 'F43.29 Расстройство адаптации с другими симптомами',
                },
              ],
            },
            {
              value: 'F43.8',
              title: 'F43.8 Другие реакции на тяжелый стресс',
              children: [
                {
                  value: 'F43.81',
                  title: 'F43.81 Длительное расстройство горя',
                },
                {
                  value: 'F43.89',
                  title: 'F43.89 Другие реакции на тяжелый стресс',
                },
              ],
            },
            {
              value: 'F43.9',
              title: 'F43.9 Реакция на тяжелый стресс неуточненная',
            },
          ],
        },
        {
          value: 'F44',
          title: 'F44 Диссоциативные и конверсионные расстройства',
          children: [
            {
              value: 'F44.0',
              title: 'F44.0 Диссоциативная амнезия',
            },
            {
              value: 'F44.1',
              title: 'F44.1 Диссоциативная фуга',
            },
            {
              value: 'F44.2',
              title: 'F44.2 Диссоциативный ступор',
            },
            {
              value: 'F44.4',
              title:
                'F44.4 Конверсионное расстройство с двигательными симптомами или дефицитом',
            },
            {
              value: 'F44.5',
              title:
                'F44.5 Конверсионное расстройство с судорожными припадками или конвульсиями',
            },
            {
              value: 'F44.6',
              title:
                'F44.6 Конверсионное расстройство с сенсорным симптомом или дефицитом',
            },
            {
              value: 'F44.7',
              title:
                'F44.7 Конверсионное расстройство со смешанными симптомами',
            },
            {
              value: 'F44.8',
              title: 'F44.8 Другие диссоциативные и конверсионные расстройства',
              children: [
                {
                  value: 'F44.81',
                  title: 'F44.81 Диссоциативное расстройство личности',
                },
                {
                  value: 'F44.89',
                  title:
                    'F44.89 Другие диссоциативные и конверсионные расстройства',
                },
              ],
            },
            {
              value: 'F44.9',
              title:
                'F44.9 Диссоциативное и конверсионное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F45',
          title: 'F45 Соматоформные расстройства',
          children: [
            {
              value: 'F45.0',
              title: 'F45.0 Соматизированное расстройство',
            },
            {
              value: 'F45.1',
              title: 'F45.1 Недифференцированное соматоформное расстройство',
            },
            {
              value: 'F45.2',
              title: 'F45.2 Ипохондрические расстройства',
              children: [
                {
                  value: 'F45.20',
                  title: 'F45.20 Неуточненное ипохондрическое расстройство',
                },
                {
                  value: 'F45.21',
                  title: 'F45.21 Ипохондрия',
                },
                {
                  value: 'F45.22',
                  title: 'F45.22 Дисморфическое расстройство тела',
                },
                {
                  value: 'F45.29',
                  title: 'F45.29 Другие ипохондрические расстройства',
                },
              ],
            },
            {
              value: 'F45.4',
              title:
                'F45.4 Болевые расстройства, связанные с психологическими факторами',
              children: [
                {
                  value: 'F45.41',
                  title:
                    'F45.41 Болевое расстройство, связанное исключительно с психологическими факторами',
                },
                {
                  value: 'F45.42',
                  title:
                    'F45.42 Болевое расстройство с сопутствующими психологическими факторами',
                },
              ],
            },
            {
              value: 'F45.8',
              title: 'F45.8 Другие соматоформные расстройства',
            },
            {
              value: 'F45.9',
              title: 'F45.9 Соматоформное расстройство неуточненное',
            },
          ],
        },
        {
          value: 'F48',
          title: 'F48 Другие невротические расстройства',
          children: [
            {
              value: 'F48.1',
              title: 'F48.1 Синдром деперсонализации-дереализации',
            },
            {
              value: 'F48.2',
              title: 'F48.2 Псевдобульбарный синдром',
            },
            {
              value: 'F48.8',
              title: 'F48.8 Другие уточненные невротические расстройства',
            },
            {
              value: 'F48.9',
              title: 'F48.9 Невротическое расстройство неуточненное',
            },
          ],
        },
      ],
    },
    {
      value: 'F50-F59',
      title:
        'F50-F59 Поведенческие синдромы, связанные с физиологическими нарушениями и физическими факторами',
      children: [
        {
          value: 'F50',
          title: 'F50 Расстройства приема пищи',
          children: [
            {
              value: 'F50.0',
              title: 'F50.0 Анорексия',
              children: [
                {
                  value: 'F50.00',
                  title: 'F50.00 Нервная анорексия неуточненная',
                },
                {
                  value: 'F50.01',
                  title: 'F50.01 Нервная анорексия, ограничительная',
                },
                {
                  value: 'F50.02',
                  title:
                    'F50.02 Нервная анорексия, с компульсивным перееданием и очищением',
                },
              ],
            },
            {
              value: 'F50.2',
              title: 'F50.2 Нервная булимия',
            },
            {
              value: 'F50.8',
              title: 'F50.8 Другие расстройства приема пищи',
              children: [
                {
                  value: 'F50.81',
                  title: 'F50.81 Компульсивное переедание',
                },
                {
                  value: 'F50.82',
                  title:
                    'F50.82 Избегающее/ограничительное расстройство приема пищи',
                },
                {
                  value: 'F50.89',
                  title: 'F50.89 Другие уточненные расстройства приема пищи',
                },
              ],
            },
            {
              value: 'F50.9',
              title: 'F50.9 Расстройство приема пищи неуточненное',
            },
          ],
        },
        {
          value: 'F51',
          title:
            'F51 Расстройства сна неорганической этиологии, не связанные с приемом веществ',
          children: [
            {
              value: 'F51.0',
              title:
                'F51.0 Бессонница неорганической этиологии, не связанная с приемом веществ',
              children: [
                {
                  value: 'F51.01',
                  title: 'F51.01 Первичная бессонница',
                },
                {
                  value: 'F51.02',
                  title: 'F51.02 Бессонница, связанная с адаптацией',
                },
                {
                  value: 'F51.03',
                  title: 'F51.03 Парадоксальная бессонница',
                },
                {
                  value: 'F51.04',
                  title: 'F51.04 Психофизиологическая бессонница',
                },
                {
                  value: 'F51.05',
                  title:
                    'F51.05 Бессонница, связанная с другим психическим расстройством',
                },
                {
                  value: 'F51.09',
                  title:
                    'F51.09 Другая бессонница неорганической этиологии, не связанная с приемом веществ',
                },
              ],
            },
            {
              value: 'F51.1',
              title:
                'F51.1 Гиперсомния неорганической этиологии, не связанная с приемом веществ',
              children: [
                {
                  value: 'F51.11',
                  title: 'F51.11 Первичная гиперсомния',
                },
                {
                  value: 'F51.12',
                  title: 'F51.12 Синдром недостаточного сна',
                },
                {
                  value: 'F51.13',
                  title:
                    'F51.13 Гиперсомния, связанная с другим психическим расстройством',
                },
                {
                  value: 'F51.19',
                  title:
                    'F51.19 Другая гиперсомния неорганической этиологии, не связанная с приемом веществ',
                },
              ],
            },
            {
              value: 'F51.3',
              title: 'F51.3 Снохождение [сомнамбулизм]',
            },
            {
              value: 'F51.4',
              title: 'F51.4 Ужасы во время сна [ночные ужасы]',
            },
            {
              value: 'F51.5',
              title: 'F51.5 Кошмары',
            },
            {
              value: 'F51.8',
              title:
                'F51.8 Другие расстройства сна неорганической этиологии, не связанные с приемом веществ',
            },
            {
              value: 'F51.9',
              title:
                'F51.9 Расстройство сна неуточненное неорганической этиологии, не связанное с приемом веществ',
            },
          ],
        },
        {
          value: 'F52',
          title:
            'F52 Сексуальная дисфункция, не обусловленная органическими нарушениями или болезнями, не связанная с приемом веществ',
          children: [
            {
              value: 'F52.0',
              title: 'F52.0 Отсутствие или потеря сексуального влечения',
            },
            {
              value: 'F52.1',
              title: 'F52.1 Сексуальное отвращение',
            },
            {
              value: 'F52.2',
              title: 'F52.2 Расстройства полового возбуждения',
              children: [
                {
                  value: 'F52.21',
                  title: 'F52.21 Мужское эректильное расстройство',
                },
                {
                  value: 'F52.22',
                  title: 'F52.22 Расстройство полового возбуждения у женщин',
                },
              ],
            },
            {
              value: 'F52.3',
              title: 'F52.3 Оргазмическая дисфункция',
              children: [
                {
                  value: 'F52.31',
                  title: 'F52.31 Женская оргазмическая дисфункция',
                },
                {
                  value: 'F52.32',
                  title: 'F52.32 Мужская оргазмическая дисфункция',
                },
              ],
            },
            {
              value: 'F52.4',
              title: 'F52.4 Преждевременная эякуляция',
            },
            {
              value: 'F52.5',
              title:
                'F52.5 Вагинизм неорганического происхождения, не обусловленный приемом веществ',
            },
            {
              value: 'F52.6',
              title:
                'F52.6 Диспареуния неорганического происхождения, не обусловленная приемом веществ',
            },
            {
              value: 'F52.8',
              title:
                'F52.8 Другая сексуальная дисфункция, не обусловленная органическим нарушением или приемом веществ',
            },
            {
              value: 'F52.9',
              title:
                'F52.9 Сексуальная дисфункция, не обусловленная органическим нарушением или приемом веществ, неуточненная',
            },
          ],
        },
        {
          value: 'F53',
          title:
            'F53 Психические расстройства и расстройства поведения, связанные с послеродовым периодом, не классифицированные в других рубриках',
          children: [
            {
              value: 'F53.0',
              title: 'F53.0 Послеродовая депрессия',
            },
            {
              value: 'F53.1',
              title: 'F53.1 Послеродовой психоз',
            },
          ],
        },
        {
          value: 'F54',
          title:
            'F54 Психологические и поведенческие факторы, связанные с расстройствами или заболеваниями, классифицированными в других рубриках',
        },
        {
          value: 'F55',
          title: 'F55 Злоупотребление не психоактивными веществами',
          children: [
            {
              value: 'F55.0',
              title: 'F55.0 Злоупотребление антацидами',
            },
            {
              value: 'F55.1',
              title:
                'F55.1 Злоупотребление растительными или народными средствами',
            },
            {
              value: 'F55.2',
              title: 'F55.2 Злоупотребление слабительными',
            },
            {
              value: 'F55.3',
              title: 'F55.3 Злоупотребление стероидами или гормонами',
            },
            {
              value: 'F55.4',
              title: 'F55.4 Злоупотребление витаминами',
            },
            {
              value: 'F55.8',
              title:
                'F55.8 Злоупотребление другими не психоактивными веществами',
            },
          ],
        },
        {
          value: 'F59',
          title:
            'F59 Неуточненные поведенческие синдромы, связанные с физиологическими нарушениями и физическими факторами',
        },
      ],
    },
    {
      value: 'F60-F69',
      title: 'F60-F69 Расстройства личности и поведения в зрелом возрасте',
      children: [
        {
          value: 'F60',
          title: 'F60 Специфические расстройства личности',
          children: [
            {
              value: 'F60.0',
              title: 'F60.0 Параноидное расстройство личности',
            },
            {
              value: 'F60.1',
              title: 'F60.1 Шизоидное расстройство личности',
            },
            {
              value: 'F60.2',
              title: 'F60.2 Диссоциальное расстройство личности',
            },
            {
              value: 'F60.3',
              title: 'F60.3 Пограничное расстройство личности',
            },
            {
              value: 'F60.4',
              title: 'F60.4 Истерическое расстройство личности',
            },
            {
              value: 'F60.5',
              title: 'F60.5 Обсессивно-компульсивное расстройство личности',
            },
            {
              value: 'F60.6',
              title: 'F60.6 Тревожное (уклоняющееся) расстройство личности',
            },
            {
              value: 'F60.7',
              title: 'F60.7 Расстройство типа зависимой личности',
            },
            {
              value: 'F60.8',
              title: 'F60.8 Другие специфические расстройства личности',
              children: [
                {
                  value: 'F60.81',
                  title: 'F60.81 Нарциссическое расстройство личности',
                },
                {
                  value: 'F60.89',
                  title: 'F60.89 Другие специфические расстройства личности',
                },
              ],
            },
            {
              value: 'F60.9',
              title: 'F60.9 Расстройство личности неуточненное',
            },
          ],
        },
        {
          value: 'F63',
          title: 'F63 Расстройства привычек и влечений',
          children: [
            {
              value: 'F63.0',
              title: 'F63.0 Патологическое влечение к азартным играм',
            },
            {
              value: 'F63.1',
              title: 'F63.1 Патологическое влечение к поджогам [пиромания]',
            },
            {
              value: 'F63.2',
              title: 'F63.2 Патологическое влечение к воровству [клептомания]',
            },
            {
              value: 'F63.3',
              title: 'F63.3 Трихотилломания',
            },
            {
              value: 'F63.8',
              title: 'F63.8 Другие расстройства привычек и влечений',
              children: [
                {
                  value: 'F63.81',
                  title: 'F63.81 Перемежающееся взрывное расстройство',
                },
                {
                  value: 'F63.89',
                  title: 'F63.89 Другие расстройства привычек и влечений',
                },
              ],
            },
            {
              value: 'F63.9',
              title: 'F63.9 Расстройство привычек и влечений, неуточненное',
            },
          ],
        },
        {
          value: 'F64',
          title: 'F64 Расстройства половой идентификации',
          children: [
            {
              value: 'F64.0',
              title: 'F64.0 Транссексуализм',
            },
            {
              value: 'F64.1',
              title: 'F64.1 Трансвестизм двойной роли',
            },
            {
              value: 'F64.2',
              title:
                'F64.2 Расстройство половой идентификации в детском возрасте',
            },
            {
              value: 'F64.8',
              title: 'F64.8 Другие расстройства половой идентификации',
            },
            {
              value: 'F64.9',
              title: 'F64.9 Расстройство половой идентификации, неуточненное',
            },
          ],
        },
        {
          value: 'F65',
          title: 'F65 Расстройства сексуального предпочтения',
          children: [
            {
              value: 'F65.0',
              title: 'F65.0 Фетишизм',
            },
            {
              value: 'F65.1',
              title: 'F65.1 Фетишистский трансвестизм',
            },
            {
              value: 'F65.2',
              title: 'F65.2 Эксгибиционизм',
            },
            {
              value: 'F65.3',
              title: 'F65.3 Вуайеризм',
            },
            {
              value: 'F65.4',
              title: 'F65.4 Педофилия',
            },
            {
              value: 'F65.5',
              title: 'F65.5 Садомазохизм',
              children: [
                {
                  value: 'F65.50',
                  title: 'F65.50 Садомазохизм неуточненный',
                },
                {
                  value: 'F65.51',
                  title: 'F65.51 Сексуальный мазохиз',
                },
                {
                  value: 'F65.52',
                  title: 'F65.52 Сексуальный садизм',
                },
              ],
            },
            {
              value: 'F65.8',
              title: 'F65.8 Другие расстройства сексуального предпочтения',
              children: [
                {
                  value: 'F65.81',
                  title: 'F65.81 Фроттеризм',
                },
                {
                  value: 'F65.89',
                  title: 'F65.89 Другие расстройства сексуального предпочтения',
                },
              ],
            },
            {
              value: 'F65.9',
              title:
                'F65.9 Расстройство сексуального предпочтения, неуточненное',
            },
          ],
        },
        {
          value: 'F66',
          title: 'F66 Другие сексуальные расстройства',
        },
        {
          value: 'F68',
          title:
            'F68 Другие расстройства личности и поведения в зрелом возрасте',
          children: [
            {
              value: 'F68.1',
              title:
                'F68.1 Умышленное вызывание или симулирование симптомов или инвалидности физического или психологического характера [поддельное нарушение]',
              children: [
                {
                  value: 'F68.10',
                  title:
                    'F68.10 Умышленное вызывание или симулирование симптомов или инвалидности физического или психологического характера [поддельное нарушение], неуточненное',
                },
                {
                  value: 'F68.11',
                  title:
                    'F68.11 Умышленное вызывание или симулирование симптомов или инвалидности физического или психологического характера [поддельное нарушение], с преобладанием психологических признаков и симптомов',
                },
                {
                  value: 'F68.12',
                  title:
                    'F68.12 Умышленное вызывание или симулирование симптомов или инвалидности физического или психологического характера [поддельное нарушение], с преобладанием физических признаков и симптомов',
                },
                {
                  value: 'F68.13',
                  title:
                    'F68.13 Умышленное вызывание или симулирование симптомов или инвалидности физического или психологического характера [поддельное нарушение], с сочетанием физических и психологических признаков и симптомов',
                },
              ],
            },
            {
              value: 'F68.A',
              title: 'F68.A Мнимое расстройство, навязанное другому',
            },
            {
              value: 'F68.8',
              title:
                'F68.8 Другие уточненные расстройства личности и поведения в зрелом возрасте',
            },
          ],
        },
        {
          value: 'F69',
          title:
            'F69 Неуточненное расстройство личности и поведения в зрелом возрасте',
        },
      ],
    },
    {
      value: 'F70-F79',
      title: 'F70-F79 Умственная отсталость',
      children: [
        {
          value: 'F70',
          title: 'F70 Умственная отсталость легкой степени',
        },
        {
          value: 'F71',
          title: 'F71 Умственная отсталость умеренная',
        },
        {
          value: 'F72',
          title: 'F72 Умственная отсталость тяжелая',
        },
        {
          value: 'F73',
          title: 'F73 Умственная отсталость глубокая',
        },
        {
          value: 'F78',
          title: 'F78 Другие формы умственной отсталости',
          children: [
            {
              value: 'F78.A',
              title:
                'F78.A Другие генетически обусловленные формы умственной отсталости',
              children: [
                {
                  value: 'F78.A1',
                  title: 'F78.A1 Умственная отсталость, связанная с SYNGAP1',
                },
                {
                  value: 'F78.A9',
                  title:
                    'F78.A9 Другие генетически обусловленные формы умственной отсталости',
                },
              ],
            },
          ],
        },
        {
          value: 'F79',
          title: 'F79 Умственная отсталость неуточненная',
        },
      ],
    },
    {
      value: 'F80-F89',
      title: 'F80-F89 Нарушения психологического развития',
      children: [
        {
          value: 'F80',
          title: 'F80 Специфические расстройства развития речи и языка',
          children: [
            {
              value: 'F80.0',
              title: 'F80.0 Специфическое расстройство речевой артикуляции',
            },
            {
              value: 'F80.1',
              title: 'F80.1 Расстройство экспрессивной речи',
            },
            {
              value: 'F80.2',
              title:
                'F80.2 Смешанное рецептивно-экспрессивное расстройство речи',
            },
            {
              value: 'F80.4',
              title:
                'F80.4 Задержка развития речи и языка, обусловленная нарушением слуха',
            },
            {
              value: 'F80.8',
              title: 'F80.8 Другие расстройства развития речи и языка',
              children: [
                {
                  value: 'F80.81',
                  title:
                    'F80.81 Расстройство речевого темпа, начинающееся в детстве',
                },
                {
                  value: 'F80.82',
                  title:
                    'F80.82 Расстройство социально-прагматической коммуникации',
                },
                {
                  value: 'F80.89',
                  title: 'F80.89 Другие расстройства развития речи и языка',
                },
              ],
            },
            {
              value: 'F80.9',
              title: 'F80.9 Расстройство развития речи и языка, неуточненное',
            },
          ],
        },
        {
          value: 'F81',
          title: 'F81 Специфические расстройства развития учебных навыков',
          children: [
            {
              value: 'F81.0',
              title: 'F81.0 Специфическое расстройство чтения',
            },
            {
              value: 'F81.2',
              title: 'F81.2 Специфическое расстройство математических навыков',
            },
            {
              value: 'F81.8',
              title: 'F81.8 Другие расстройства развития учебных навыков',
              children: [
                {
                  value: 'F81.81',
                  title: 'F81.81 F81.81 Расстройство письменной речи',
                },
                {
                  value: 'F81.89',
                  title: 'F81.89 Другие расстройства развития учебных навыков',
                },
              ],
            },
            {
              value: 'F81.9',
              title: 'F81.9 Расстройство развития учебных навыков неуточненное',
            },
          ],
        },
        {
          value: 'F82',
          title: 'F82 Специфические расстройства развития моторной функции',
        },
        {
          value: 'F84',
          title: 'F84 Общие расстройства психологического развития',
          children: [
            {
              value: 'F84.0',
              title: 'F84.0 Аутизм',
            },
            {
              value: 'F84.2',
              title: 'F84.2 Синдром Ретта',
            },
            {
              value: 'F84.3',
              title:
                'F84.3 Другое дезинтегративное расстройство детского возраста',
            },
            {
              value: 'F84.5',
              title: 'F84.5 Синдром Аспергера',
            },
            {
              value: 'F84.8',
              title: 'F84.8 Другие общие расстройства развития',
            },
            {
              value: 'F84.9',
              title: 'F84.9 Общее расстройство развития неуточненное',
            },
          ],
        },
        {
          value: 'F88',
          title: 'F88 Другие расстройства психологического развития',
        },
        {
          value: 'F89',
          title: 'F89 Расстройство психологического развития неуточненное',
        },
      ],
    },
    {
      value: 'F90-F98',
      title:
        'F90-F98 Расстройства поведения и эмоциональные расстройства, начинающиеся обычно в детском и подростковом возрасте',
      children: [
        {
          value: 'F90',
          title: 'F90 Синдром дефицита внимания с гиперактивностью',
          children: [
            {
              value: 'F90.0',
              title:
                'F90.0 Синдром дефицита внимания с гиперактивностью, преимущественно невнимательный тип',
            },
            {
              value: 'F90.1',
              title:
                'F90.1 Синдром дефицита внимания с гиперактивностью, преимущественно гиперактивный тип',
            },
            {
              value: 'F90.2',
              title:
                'F90.2 Синдром дефицита внимания с гиперактивностью, смешанный тип',
            },
            {
              value: 'F90.8',
              title:
                'F90.8 Синдром дефицита внимания с гиперактивностью, другой тип',
            },
            {
              value: 'F90.9',
              title:
                'F90.9 Синдром дефицита внимания с гиперактивностью, неуточненный тип',
            },
          ],
        },
        {
          value: 'F91',
          title: 'F91 Расстройства поведения',
          children: [
            {
              value: 'F91.0',
              title: 'F91.0 Расстройство поведения, ограниченное рамками семьи',
            },
            {
              value: 'F91.1',
              title:
                'F91.1 F91.1 Расстройство поведения, с началом в детском возрасте',
            },
            {
              value: 'F91.2',
              title:
                'F91.2 Расстройство поведения, с началом в подростковом возрасте',
            },
            {
              value: 'F91.3',
              title: 'F91.3 Вызывающее оппозиционное расстройство',
            },
            {
              value: 'F91.8',
              title: 'F91.8 Другие расстройства поведения',
            },
            {
              value: 'F91.9',
              title: 'F91.9 Расстройство поведения неуточненное',
            },
          ],
        },
        {
          value: 'F93',
          title:
            'F93 Эмоциональные расстройства, начинающиеся в детском возрасте',
          children: [
            {
              value: 'F93.0',
              title: 'F93.0 Тревожное расстройство у детей, вызванное разлукой',
            },
            {
              value: 'F93.8',
              title:
                'F93.8 Другие эмоциональные расстройства детского возраста',
            },
            {
              value: 'F93.9',
              title:
                'F93.9 Эмоциональное расстройство детского возраста неуточненное',
            },
          ],
        },
        {
          value: 'F94',
          title:
            'F94 Расстройства социального функционирования, начало которых характерно для детского и подросткового возрастов',
          children: [
            {
              value: 'F94.0',
              title: 'F94.0 Элективный мутизм',
            },
            {
              value: 'F94.1',
              title:
                'F94.1 Реактивное расстройство привязанностей в детском возрасте',
            },
            {
              value: 'F94.2',
              title:
                'F94.2 Расстройство привязанностей в детском возрасте по расторможенному типу',
            },
            {
              value: 'F94.8',
              title:
                'F94.8 Другие расстройства социального функционирования детского возраста',
            },
            {
              value: 'F94.9',
              title:
                'F94.9 Расстройство социального функционирования детского возраста неуточненное',
            },
          ],
        },
        {
          value: 'F95',
          title: 'F95 Тики',
          children: [
            {
              value: 'F95.0',
              title: 'F95.0 Транзиторные тики',
            },
            {
              value: 'F95.1',
              title: 'F95.1 Хронические моторные тики или вокализмы',
            },
            {
              value: 'F95.2',
              title: 'F95.2 Синдром Туретта',
            },
            {
              value: 'F95.8',
              title: 'F95.8 Другие тики',
            },
            {
              value: 'F95.9',
              title: 'F95.9 Тики неуточненные',
            },
          ],
        },
        {
          value: 'F98',
          title:
            'F98 Другие расстройства поведения и эмоциональные расстройства, начало которых характерно для детского и подросткового возрастов',
          children: [
            {
              value: 'F98.0',
              title:
                'F98.0 Энурез неорганической природы, не вызванный приемом веществ',
            },
            {
              value: 'F98.1',
              title:
                'F98.1 Энкопрез неорганической природы, не вызванный приемом веществ',
            },
            {
              value: 'F98.2',
              title:
                'F98.2 Расстройство приема пищи в младенческом и детском возрасте',
              children: [
                {
                  value: 'F98.21',
                  title:
                    'F98.21 Руминативное расстройство в младенческом возрасте',
                },
                {
                  value: 'F98.29',
                  title:
                    'F98.29 Другие расстройства приема пищи в младенческом и детском возрасте',
                },
              ],
            },
            {
              value: 'F98.3',
              title:
                'F98.3 Поедание несъедобного (пика) в младенчестве и детстве',
            },
            {
              value: 'F98.4',
              title: 'F98.4 Стереотипные двигательные расстройства',
            },
            {
              value: 'F98.5',
              title: 'F98.5 Заикание, начинающееся во взрослом возрасте',
            },
            {
              value: 'F98.8',
              title:
                'F98.8 Другие уточненные расстройства поведения и эмоциональные расстройства, начало которых характерно для детского и подросткового возрастов',
            },
            {
              value: 'F98.9',
              title:
                'F98.9 Другие неуточненные расстройства поведения и эмоциональные расстройства, начало которых характерно для детского и подросткового возрастов',
            },
          ],
        },
      ],
    },
    {
      value: 'F99',
      title: 'F99 Психическое расстройство без дополнительных уточнений',
      children: [
        {
          value: 'F99.my',
          title: 'F99 Психическое расстройство без дополнительных уточнений',
        },
      ],
    },
    {
      value: 'U00-U49',
      title:
        'U00-U49 Предварительное назначение новых заболеваний неясной этиологии или экстренного применения',
      children: [
        {
          value: 'U07',
          title: 'U07 Экстренное применение U07',
          children: [
            {
              value: 'U07.0',
              title: 'U07.0 Расстройство, связанное с вейпингом',
            },
            {
              value: 'U07.1',
              title: 'U07.1 COVID-19',
            },
          ],
        },
        {
          value: 'U09',
          title: 'U09 Состояние после COVID-19',
          children: [
            {
              value: 'U09.9',
              title: 'U09.9 Состояние после COVID-19, неуточненное',
            },
          ],
        },
      ],
    },
  ],
};
