import {
  Button,
  Card,
  Checkbox,
  CreateButton,
  EditButton,
  Form,
  Input,
  List,
  SaveButton,
  Space,
  Table,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustomMutation,
  useGetIdentity,
  useGetLocale,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import { useCcEditableTable } from '../../helpers';
import { getYupSyncValidator } from '../../helpers/validation';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  DayReminders,
  dbCols,
  getValidators,
  Thought,
  User,
  UserRole,
} from '@cc/schema';
import { useActor } from '@xstate/react';
import { RuleObject } from 'antd/lib/form';
import { format, set } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useTranslation } from 'react-i18next';
import { TherapistActivityReactContext } from '../../App';
import {
  useFormSubmitDisabledAnyTouched,
  useSubscriptionWarnings,
} from '../../hooks';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { IUser } from '../../providers';
import { DateFnsTimePicker } from '../../components/Pickers';

const { Text } = Typography;

export const CopingCardsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { t, i18n } = useTranslation();

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const locale = useGetLocale();

  const currentLocale = locale();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const { portalThoughtUpdateValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const validationRules = getYupSyncValidator(
    translate,
    portalThoughtUpdateValidator,
  ) as RuleObject;

  const subWarnings = useSubscriptionWarnings();

  const { mutate: mutateDayReminders } = useCustomMutation<DayReminders>();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const {
    table: { tableQueryResult, tableProps, setFilters },
    form: { formProps },
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useCcEditableTable<Thought>(
    {
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'updatedAt',
          order: 'desc',
        },
      ],

      initialFilter: therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],

      metaData: {
        fields: [
          {
            data: [
              '_id',
              'userId',
              'automaticThought',
              { realisticThoughts: ['thought', 'thoughtBelief'] },
              'updatedAt',
              {
                dayReminders: [
                  '_id',
                  { morning: [{ time: ['hours', 'minutes'] }, 'enabled'] },
                  { day: [{ time: ['hours', 'minutes'] }, 'enabled'] },
                  { evening: [{ time: ['hours', 'minutes'] }, 'enabled'] },
                ],
              },
            ],
          },
          'count',
        ],
      },
    },
    {
      metaData: {
        fields: [
          '_id',
          'userId',
          'automaticThought',
          { realisticThoughts: ['thought', 'thoughtBelief'] },
          {
            dayReminders: [
              { morning: [{ time: ['hours', 'minutes'] }, 'enabled'] },
              { day: [{ time: ['hours', 'minutes'] }, 'enabled'] },
              { evening: [{ time: ['hours', 'minutes'] }, 'enabled'] },
            ],
          },
          'updatedAt',
        ],
      },
    },
  );

  const updateDateReminders = async (dayReminders: DayReminders) => {
    await mutateDayReminders({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'updateDayReminders',
        fields: [{ dayReminders: ['_id'] }],
        variables: {
          dayReminders: {
            name: 'dayReminders',
            type: 'DayRemindersUpdateInput!',
            value: dayReminders,
          },
        },
      },
    });
    // await tableQueryResult?.refetch();
    saveButtonProps.onClick?.();
  };

  useEffect(() => {
    setFilters(
      therapistActivityState?.context?.filterClientId
        ? [
            {
              field: 'userId',
              operator: 'eq',
              value: therapistActivityState.context.filterClientId,
            },
          ]
        : [],
      'replace',
    );
  }, [therapistActivityState?.context?.filterClientId]);

  const {
    handleFormChange,
    isDisabled: isSubmitDisabled,
    fieldsCount,
  } = useFormSubmitDisabledAnyTouched(formProps?.form!);

  useActivityTracking(
    t('pages.clientsShow.therapistOtherResources.portalCards'),
  );

  const expandedRowRender = (record: Thought) => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: 400 }}>
          <ReactCardFlip
            isFlipped={isCardFlipped}
            flipDirection="horizontal"
            flipSpeedBackToFront={2}
            flipSpeedFrontToBack={2}
          >
            <Card
              style={{ width: 400 }}
              onClick={() => setIsCardFlipped(!isCardFlipped)}
              bordered
              hoverable
              bodyStyle={{
                minHeight: 200,
                boxShadow: '0 0 10px #ccc',
                background: '#45403e',
                color: '#f5f1f1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {(record.automaticThought || '').split('\n').map((t, idx) => {
                  return <div key={idx}>{t}</div>;
                })}
              </div>
            </Card>

            <Card
              style={{ width: 400 }}
              onClick={() => setIsCardFlipped(!isCardFlipped)}
              bordered
              hoverable
              bodyStyle={{
                minHeight: 200,
                boxShadow: '0 0 10px #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {record.realisticThoughts?.map((t: any, i: number) => {
                  return (t.thought || '')
                    .split('\n')
                    .map((th: string, idx: number) => {
                      const prefix =
                        i === 0 ? '' : idx === 0 ? `${i + 1}. ` : '';
                      return <div key={idx}>{`${prefix}${th}`}</div>;
                    });
                })}
              </div>
            </Card>
          </ReactCardFlip>
        </div>
      </div>
    );
  };

  return (
    <List
      title={translate('pages.copingCards.title')}
      pageHeaderProps={{
        extra: (
          <Space>
            <CreateButton />
          </Space>
        ),
      }}
    >
      <Form {...formProps} onFieldsChange={handleFormChange}>
        <Table
          {...tableProps}
          rowKey="_id"
          expandable={{
            expandedRowRender,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            // Note: The data we get from Ant.design filters does not match the
            // react refine one. Currently it works fine if I simply silence it the way below.
            // TOOD: Find a way to convert the data to match the react refine one and fix it better
            // @ts-ignore
            tableProps?.onChange?.(pagination, null, sorter, extra);
          }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            key="_id"
            dataIndex="userId"
            sorter={{ multiple: 2 }}
            title={translate('pages.thoughtList.userId')}
            filterSearch
            // @ts-ignore
            onFilter={(value: string, record: Thought) => {
              return record.userId === value;
            }}
            filters={
              // @ts-ignore
              clients?.data?.data?.map((c, idx) => {
                return {
                  text: `${c._id} - ${c.firstName} ${c.lastName}`,
                  value: c._id,
                };
              })
            }
            render={(value, record) => {
              // @ts-ignore
              const client = clients?.data?.data?.find?.(
                // @ts-ignore
                (c) => c._id === value,
              );

              return (
                <Tooltip
                  title={client ? `${client.firstName} ${client.lastName}` : ''}
                >
                  {`${value.replace('users/', '')} - ${(
                    client?.firstName || ''
                  ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
                </Tooltip>
              );
            }}
          />
          <Table.Column<Thought>
            key="automaticThought"
            dataIndex="automaticThought"
            title={
              <>
                {translate('pages.copingCards.frontSide')}
                <Tooltip title={translate('pages.copingCards.frontSideTip')}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            }
            render={(value: string, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item
                    name={['automaticThought']}
                    style={{ margin: 0, minWidth: 120 }}
                    rules={[validationRules]}
                  >
                    <Input.TextArea autoSize={{ minRows: 4 }} />
                  </Form.Item>
                );
              }
              return (
                <>
                  {(value || '').split('\n').map((t, idx) => {
                    return <div key={idx}>{t}</div>;
                  })}
                </>
              );
            }}
          />
          <Table.Column<Thought>
            key="realisticThoughts"
            dataIndex={['realisticThoughts']}
            title={
              <>
                {translate('pages.copingCards.backSide')}
                <Tooltip title={translate('pages.copingCards.backSideTip')}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            }
            render={(thoughts, record, indx) => {
              if (isEditing(record._id)) {
                return (
                  <Form.List name={['realisticThoughts']}>
                    {(fields, { add, remove }, errors) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key, 'thought']}
                                style={{ margin: 0, minWidth: 140 }}
                                rules={[
                                  {
                                    required: true,
                                    message: translate(
                                      'createThought.realisticThoughtRequired',
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea autoSize={{ minRows: 3 }} />
                              </Form.Item>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                );
              }
              return (
                <div>
                  {
                    // @ts-ignore
                    thoughts.map((t, i) => {
                      return (
                        <div key={i}>
                          <>
                            {(t.thought || '')
                              .split('\n')
                              .map((th: string, idx: number) => {
                                const prefix =
                                  i === 0 ? '' : idx === 0 ? `${i + 1}. ` : '';
                                return <div key={idx}>{`${prefix}${th}`}</div>;
                              })}
                          </>
                        </div>
                      );
                    })
                  }
                </div>
              );
            }}
          />
          <Table.Column
            key="dayReminders"
            dataIndex="dayReminders"
            title={
              <>
                {translate('pages.copingCards.timeBasedReminders')}
                <Tooltip
                  title={translate('pages.copingCards.timeBasedRemindersTip')}
                >
                  <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            }
            width={300}
            render={(reminders, recordHigher: DayReminders, indx) => {
              const columns = [
                {
                  title: '',
                  dataIndex: 'period',
                  render: (period: string) => {
                    return (
                      <Text strong>
                        {translate(`pages.copingCards.${period}`)}
                      </Text>
                    );
                  },
                },
                {
                  title: '',
                  dataIndex: 'time',
                  render: (
                    time: DayReminders['day']['time'],
                    record: DayReminders['day'],
                  ) => {
                    return (
                      <DateFnsTimePicker
                        size="small"
                        defaultValue={set(new Date(), {
                          hours: time.hours,
                          minutes: time.minutes,
                        })}
                        format={
                          i18n.languages[0]?.startsWith('ru')
                            ? 'HH:mm'
                            : 'hh:mm a'
                        }
                        disabled={!isEditing(recordHigher._id)}
                        onChange={(date) => {
                          const hours = date?.getHours() || 0;
                          const minutes = date?.getMinutes() || 0;

                          const copiedDayReminders = { ...reminders };
                          copiedDayReminders[
                            // @ts-ignore
                            record.key
                          ].time = {
                            hours,
                            minutes,
                          };
                          updateDateReminders(copiedDayReminders);
                        }}
                      />
                    );
                  },
                },
                {
                  title: '',
                  dataIndex: 'enabled',
                  render: (enabled: boolean, record: DayReminders['day']) => {
                    return (
                      <Checkbox
                        checked={enabled}
                        disabled={!isEditing(recordHigher._id)}
                        onChange={(e) => {
                          const checked = e.target.checked;

                          const copiedDayReminders = { ...reminders };
                          copiedDayReminders[
                            // @ts-ignore
                            record.key
                          ].enabled = checked;
                          updateDateReminders(copiedDayReminders);
                        }}
                      />
                    );
                  },
                },
              ];
              const { _id, ...remindersWithoutId } = reminders;

              const dayRemindersData = Object.keys(
                remindersWithoutId || {},
              ).map((period) => {
                return {
                  key: period,
                  period,
                  ...(remindersWithoutId?.[period] || {}),
                };
              });
              return (
                <Form.Item name={['dayReminders']}>
                  <Table
                    showHeader={false}
                    columns={columns}
                    dataSource={dayRemindersData}
                    pagination={false}
                    size="small"
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            key="updatedAt"
            dataIndex="updatedAt"
            title={translate('pages.thoughtList.updatedAt')}
            sorter={{ multiple: 1 }}
            render={(text, record, indx) => {
              const date = new Date(text);
              return format(
                date,
                currentLocale?.startsWith('ru')
                  ? 'yyyy-MM-dd HH:mm:ss'
                  : 'yyyy-MM-dd hh:mm:ss aaa',
              );
            }}
          />
          <Table.Column<Thought>
            title={translate('resourcesCommon.actions')}
            dataIndex="actions"
            render={(_, record) => {
              // @ts-ignore
              const client: Client = // @ts-ignore
              ((clients?.data?.data as Client[]) || []).find(
                (c) => c._id === record.userId,
              );

              const hasPremiumAccess = client?.accessLevel;

              const canEdit =
                client?.access?.allowEdit ||
                (client?.access?.allowAdminEdit &&
                  identity?.db?.role === UserRole.THERAPIST_ADMIN);

              if (isEditing(record._id)) {
                return (
                  <Space>
                    <SaveButton
                      {...saveButtonProps}
                      onClick={() => {
                        const resource =
                          t(
                            'pages.clientsShow.therapistOtherResources.portalCards',
                          ) +
                          '/' +
                          (record._id.split('/')[1] || '');
                        therapistActivitySend({
                          type: 'UPDATED',
                          resource,
                        });
                        saveButtonProps.onClick?.();

                        therapistActivitySend({
                          type: 'LOADED',
                          resource: t(
                            'pages.clientsShow.therapistOtherResources.portalCards',
                          ),
                        });
                      }}
                      // disabled={isSubmitDisabled}
                      size="small"
                    />
                    <Button
                      {...cancelButtonProps}
                      size="small"
                      onClick={() => {
                        const resource =
                          t(
                            'pages.clientsShow.therapistOtherResources.portalCards',
                          ) +
                          '/' +
                          (record._id.split('/')[1] || '');
                        therapistActivitySend({
                          type: 'TIMED_OUT',
                          resource,
                        });
                        cancelButtonProps.onClick?.();
                        therapistActivitySend({
                          type: 'LOADED',
                          resource: t(
                            'pages.clientsShow.therapistOtherResources.portalCards',
                          ),
                        });
                      }}
                    >
                      {translate('buttons.cancel')}
                    </Button>
                  </Space>
                );
              }
              return (
                <Space>
                  {
                    <>
                      <EditButton
                        {...editButtonProps(record._id)}
                        onClick={() => {
                          const resource =
                            t(
                              'pages.clientsShow.therapistOtherResources.portalCards',
                            ) +
                            '/' +
                            (record._id.split('/')[1] || '');
                          therapistActivitySend({
                            type: 'TIMED_OUT',
                            resource: t(
                              'pages.clientsShow.therapistOtherResources.portalCards',
                            ),
                          });
                          therapistActivitySend({
                            type: 'LOADED',
                            resource,
                            clientId: record.userId,
                          });
                          editButtonProps(record._id).onClick?.();
                        }}
                        hideText
                        size="small"
                        disabled={!hasPremiumAccess || !canEdit}
                      />
                      {(!hasPremiumAccess || !canEdit) && (
                        <Tooltip
                          title={translate(
                            !hasPremiumAccess
                              ? 'pages.thoughtList.thoughtEditLimitTip'
                              : 'pages.thoughtList.thoughtEditNoPermissionTip',
                          )}
                        >
                          <QuestionCircleOutlined
                            style={{ marginLeft: '10px' }}
                          />
                        </Tooltip>
                      )}
                    </>
                  }

                  {/* <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} /> */}
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
