import { CSSProperties } from 'react';
import { colors } from '../../../styles/colors';

export const checkboxStyle: CSSProperties = {
  margin: '0 10px',
};

export const linkStyle: CSSProperties = {
  marginLeft: '10px',
};
