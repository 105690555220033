import { IDataContextProvider } from '@pankod/refine-core/dist/interfaces';
import { differenceInSeconds, sub } from 'date-fns';
import { uniqBy } from 'lodash';
import { assign, createMachine, InterpreterFrom } from 'xstate';
import { choose } from 'xstate/lib/actions';
import * as Sentry from '@sentry/react';

export type TherapistActivityContext = typeof initialTherapistActivityContext;

export enum TherapistActivityType {
  view,
  create,
  edit,
  delete,
}

const lastActivity = {
  createdAt: undefined as undefined | Date,
  finishedAt: undefined as undefined | Date,
  resource: '',
  clientId: '' as string | undefined,
  activity: TherapistActivityType.view,
};

const { finishedAt, ...currentActivity } = lastActivity;

export const initialTherapistActivityContext = {
  filterClientId: '',
  unsavedActivities: [] as typeof lastActivity[],
  gqlDataProvider: undefined as undefined | Required<IDataContextProvider>,
  currentActivity,
  lastActivity,
};

export type TherapistActivityEvents =
  | { type: 'ADD_ACTIVITY'; activity: any }
  | { type: 'ENTITY_CLIENT_ID_CHANGED'; clientId: string | undefined }
  | { type: 'LOADED'; resource: any; clientId?: string }
  | { type: 'RESOURCE_UPDATED'; resource: any }
  | { type: 'LEFT'; resource?: any }
  | { type: 'TIMED_OUT'; resource: any }
  | { type: 'RESUMED'; resource: any }
  | { type: 'CREATED'; resource: any }
  | { type: 'UPDATED'; resource: any }
  | { type: 'DELETED'; resource: any }
  | { type: 'SET_FILTER_CLIENT_ID'; clientId: string }
  | { type: 'UNSET_FILTER_CLIENT_ID' };

export type TherapistActivityService = InterpreterFrom<
  typeof createTherapistActivityMachine
>;

export const createTherapistActivityMachine = ({
  gqlDataProvider,
}: {
  gqlDataProvider: Required<IDataContextProvider>;
}) => {
  const context = {
    ...initialTherapistActivityContext,
    gqlDataProvider,
    startedAt: new Date(),
  };
  const machine = createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgGUBRAFQH0BhAGQEkKA5WpgEQG0AGAXUSgADgHtYuAC65R+ISAAeiAMwBOAGwA6AKzqATABZVAdl7KAjL16rVAGhABPRHpuaAHNtXLDltecMAvgH2aFh4hKQAqqyUtIws7DRcfIJIIGIS0rLySgjKHjq8xm565gZ6vAZq2gb2TggVypqqvPrm6q2qesZGykEhGDgExOTUNABiTAxUFABK9MxsHDwC8hlSMnJpucrqbprq6gat1upe2trKdYiWxs2Xlm5mBurKPcb9IKFDESTRsRMpjN5vElkkVqkROINtltiptHcOl4PMptG5itprgh-J9vuFiJosNIAG5SBwkTgUBjUCgQtbQrJbUA7Lyacoac6qAxGPZYgymTTKbzlM7KAxuLqtXGDfFEQmYElkzS4CAAGzAJAYAHkAIKUulpdaMnI3NzmTRWS1ojzmDQIrH+LmacxvCrWMU2NzSsLDOVE3CkyQOZVqjWzChkSIAWVpKXpmU2JuxxnNlqs1u0tvU9scN25emadp6NRKF29PwJ-sDwckACcsABrAhQTUUcZUOOGhmJuHY1SptMZrM5+qHO56fJnI42qoGcuy+WKoOaOuN5skcNkLWRWZ0Cg0SIABU4OpmBqhCdhzMQlwMOjURWUViMlUxuYQXQLvEzegnrtU6Lzr6i4Bkqq6YE2+AtlQTAxpwNDbh2qxdpeTKKDc-YWoObg2kWWLFKoBzcqonhdC02Z6EBEQgdWK71hB650OGp6xshF4wmhuT8ua+jaFYqKoo+VzvuY5jGAWBgIkYegIlJ+RUZWCqgcu4GQS2R4nmenbscavbcQcMn8ZcfHGEKDouHebivMYui8Fm2baApfpKbRqnrksTBUAAmgsCTLPQAASOqsAA4qxkLpN2V7oQgfF3jZxTmGaE5tOoDovHcuh7OiPTtHo2ZOTRYH0WpmiYLWYDoNIUF-MeLHnpFqFJns2jOroMnGD0+jeMJ9SiR0BwdEieyWPyXrBF8MrAVWGxwJosDoMSYCcFV6AkBAshgMq+DEqIDZbXi00ubNsDzYty2rQgBC7ZgVWbCk2mNRxSZvPs7VGOKQouv4xgOsYLRsvlHXdHxT5zhNh3UTN0hzQtS0rZIa1gLWtaiLWmjCKqVUAGZo6gmiQ4pS64LD50I+gV07aIt2Mg9bFPbp155MUOj6B9bhfRY3QOmcdz8hYkkAUlLTgxN+CiBAcDyITRDxs9vYALS2li+QFhzhj5Z9Fz6IV0P1DpPZM2KWIIoRpnonZ2VPKJuvHcuKrqnLjMxSU+FlJouUc3xvEkeottLjWJXNk7hsxeUTS8UZgmmb1Ny8PlbJSTZ3tdO0-vKYHa5QZopJgAA7sHKHy0z2gTs66Ip50JFuA6olNCRPRmuoLoyeY6euUH2flZV1VQCH0W5GcBZvHx5g1FlXix9ipRaO0rQVEcqLHODAw+lDduZwx2eQBsUH95xiBD4KNl2ePhyTw6X2Cu0OG-h0+Tx+3J370mLg89YzSmWPokeCWlEQ1NdexM5oOzAC-Xs2Z9juhTBZJ4xwp5iQTsRXmJh2Q6wAWvImykSanThhdRG4CmadT+h-LkHJxRuHKMYP2QQAhAA */
      context,
      tsTypes: {} as import('./therapistActivityMachine.typegen').Typegen0,
      schema: {
        context: {} as TherapistActivityContext,
        events: {} as TherapistActivityEvents,
        services: {} as {
          createPracticeUsageEvents: {
            data: Date | undefined;
          };
        },
      },
      type: 'parallel',
      states: {
        activity: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                LOADED: {
                  actions: ['maybeFinishCurrentActivity', 'addNewActivity'],
                  target: 'tracking',
                },
                RESUMED: {
                  actions: ['resumeActivity'],
                  target: 'tracking',
                },
              },
            },
            tracking: {
              initial: 'viewing',
              states: {
                viewing: {},
                creating: {
                  on: {
                    UPDATED: {
                      actions: [],
                    },
                  },
                },
                editing: {},
              },
              on: {
                LEFT: {
                  actions: ['maybeFinishCurrentActivity'],
                  target: 'idle',
                },
                RESOURCE_UPDATED: {
                  actions: ['changeActivityResource'],
                },
                TIMED_OUT: {
                  actions: ['maybeFinishCurrentActivity'],
                  target: 'idle',
                },
                CREATED: {
                  actions: ['swithActivityToCreating'],
                  target: 'tracking.creating',
                },
                UPDATED: {
                  actions: ['switchActivityToEditing'],
                  target: 'tracking.editing',
                },
                ENTITY_CLIENT_ID_CHANGED: {
                  actions: ['changeActivityClientId'],
                },
              },
            },
          },
          on: {
            DELETED: {
              actions: ['addDeleteActivity'],
            },
          },
        },
        saving: {
          initial: 'idle',
          states: {
            idle: {
              after: {
                10000: {
                  target: 'saveData',
                },
              },
            },
            saveData: {
              invoke: {
                src: 'createPracticeUsageEvents',
                onDone: {
                  target: 'idle',
                  actions: ['removeSavedActivities'],
                },
                onError: {
                  target: 'idle',
                  // actions: [() => console.log('hi')],
                },
              },
            },
          },
        },
      },
      // @ts-ignore
      on: {
        SET_FILTER_CLIENT_ID: {
          actions: choose([
            {
              cond: 'isCurrentActivityOnSpecificEntity',
              actions: ['setFilterClientId'],
            },
            {
              actions: ['setFilterClientId', 'restartActivityWithClient'],
            },
          ]),
        },
        UNSET_FILTER_CLIENT_ID: {
          actions: choose([
            {
              cond: 'isCurrentActivityOnSpecificEntity',
              actions: ['unsetFilterClientId', 'maybeFinishCurrentActivity'],
            },
            {
              actions: ['unsetFilterClientId'],
            },
          ]),
        },
      },
    },
    {
      actions: {
        addNewActivity: assign((ctx, e) => {
          return {
            currentActivity: {
              createdAt: new Date(),
              resource: e.resource,
              clientId: e?.clientId ? e.clientId : ctx.filterClientId,
              activity: TherapistActivityType.view,
            },
          };
        }),
        maybeFinishCurrentActivity: assign((ctx, e) => {
          const lastActivity = ctx.currentActivity.createdAt
            ? {
                createdAt: ctx.currentActivity.createdAt,
                finishedAt: new Date(),
                resource: ctx.currentActivity.resource,
                clientId: ctx.currentActivity.clientId,
                activity: ctx.currentActivity.activity,
              }
            : ctx.lastActivity;
          return {
            lastActivity,
            currentActivity: {
              ...initialTherapistActivityContext.currentActivity,
            },
            unsavedActivities:
              lastActivity?.finishedAt &&
              lastActivity?.clientId &&
              !lastActivity?.resource.endsWith('/create')
                ? uniqBy(
                    [...ctx.unsavedActivities, lastActivity],
                    (a) => a.resource + String(a.createdAt),
                  )
                : ctx.unsavedActivities,
          };
        }),
        resumeActivity: assign((ctx, e) => {
          const createdAt =
            ctx.lastActivity?.activity === TherapistActivityType.create &&
            ctx.lastActivity?.createdAt &&
            ctx.lastActivity?.finishedAt
              ? sub(new Date(), {
                  seconds: differenceInSeconds(
                    ctx.lastActivity.finishedAt,
                    ctx.lastActivity.createdAt,
                  ),
                })
              : new Date();
          return {
            currentActivity: {
              createdAt,
              resource: ctx.lastActivity.resource,
              clientId: ctx.lastActivity.clientId,
              activity: ctx.lastActivity.activity,
            },
            lastActivity: {
              ...initialTherapistActivityContext.lastActivity,
            },
          };
        }),
        swithActivityToCreating: assign((ctx, e) => {
          return {
            currentActivity: {
              ...ctx.currentActivity,
              activity: TherapistActivityType.create,
            },
          };
        }),
        switchActivityToEditing: assign((ctx, e) => {
          return {
            currentActivity: {
              ...ctx.currentActivity,
              activity: TherapistActivityType.edit,
            },
          };
        }),
        changeActivityClientId: assign((ctx, e) => {
          return {
            currentActivity: {
              ...ctx.currentActivity,
              clientId: e.clientId,
            },
          };
        }),
        changeActivityResource: assign((ctx, e) => {
          return {
            currentActivity: {
              ...ctx.currentActivity,
              resource: e.resource,
            },
          };
        }),
        addDeleteActivity: assign((ctx, e) => {
          return {
            unsavedActivities: [
              ...ctx.unsavedActivities,
              {
                resource: e.resource,
                activity: TherapistActivityType.delete,
                createdAt: sub(new Date(), { seconds: 5 }), // Note: The idea is that one needs to read what is deleted
                finishedAt: new Date(),
                clientId: ctx.filterClientId ? ctx.filterClientId : undefined,
              },
            ],
          };
        }),
        setFilterClientId: assign((ctx, e) => {
          return {
            filterClientId: e.clientId,
          };
        }),
        restartActivityWithClient: assign((ctx, e) => {
          return {
            currentActivity: {
              ...ctx.currentActivity,
              createdAt: new Date(),
              clientId: e.clientId,
            },
          };
        }),
        unsetFilterClientId: assign((ctx, e) => {
          return { filterClientId: '' };
        }),
        removeSavedActivities: assign((ctx, e) => {
          if (!e.data) {
            return {
              unsavedActivities: ctx.unsavedActivities,
            };
          }

          const unsavedActivities = ctx.unsavedActivities.filter(
            (a) => a.createdAt && a.createdAt > e.data!,
          );

          return {
            unsavedActivities,
          };
        }),
      },
      guards: {
        isCurrentActivityOnSpecificEntity: (ctx, e) => {
          return ctx.currentActivity.resource.split('/').length > 1;
        },
      },
      services: {
        createPracticeUsageEvents: async (ctx, e) => {
          const { gqlDataProvider, unsavedActivities } = ctx;

          if (!unsavedActivities.length) {
            return [];
          }

          if (gqlDataProvider) {
            try {
              const response = await gqlDataProvider.custom({
                url: '',
                method: 'post',
                metaData: {
                  operation: 'upsertPracticeUsageEvents',
                  fields: ['_key'],
                  variables: {
                    events: {
                      name: 'events',
                      type: '[PracticeUsageEventInput!]',
                      value: unsavedActivities.map((a) => {
                        const { clientId, ...rest } = a;
                        return {
                          ...rest,
                          _to: clientId,
                        };
                      }),
                    },
                  },
                },
              });

              if (response.data) {
                return unsavedActivities[unsavedActivities.length - 1]
                  .createdAt;
              }
            } catch (e) {
              Sentry.captureException(e);
              return;
            }
          }

          return;
        },
      },
    },
  );
  return machine;
};
