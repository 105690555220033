import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { merge } from 'lodash';
import {
  ActivatingEvent,
  baseFeelingsEn,
  baseFeelingsRu,
  basicFeelingsEn,
  basicFeelingsRu,
  bodyPartsEn,
  bodyPartsRu,
  User,
  baseEmotionsEn,
  baseEmotionsRu,
  DayReminders,
  Thought,
  translationEn,
  getValidators,
  UserRole,
  dbCols,
} from '@cc/schema';
import {
  numberToOnesAndZerosArrayOfArrays,
  onesAndZerosArrayOfArraysToNumber,
} from '@cc/utils';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Create,
  Divider,
  Form,
  Input,
  InputNumber,
  Result,
  Row,
  Select,
  Slider,
  Table,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustomMutation,
  useGetIdentity,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { RuleObject } from 'antd/lib/form';
import { useWatch } from 'antd/lib/form/Form';
import { set } from 'date-fns';
import { cloneDeep } from 'lodash';
import qs from 'qs';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { DateFnsTimePicker } from '../../components/Pickers';
import {
  useFormSubmitDisabledAnyTouched,
  useSubscriptionWarnings,
} from '../../hooks';
import { IUser } from '../../providers';
import { getYupSyncValidator } from '../../helpers';
import { useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import * as Sentry from '@sentry/react';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';

const { Text, Title } = Typography;
const { Panel } = Collapse;
const { OptGroup, Option } = Select;

export const emptyBasicThought = {
  thought: '',
  thoughtBelief: 50,
};

type CreateActivatingEventForm = {
  // Activating Event
  clientId: string;
  description: string; // Activating event
  emotions: ActivatingEvent['emotions'];
  bodyPartsFeelings: ActivatingEvent['bodyPartsFeelings'];
  feelings: ActivatingEvent['feelings'];
  behaviors: ActivatingEvent['behaviors'];

  // Thought
  automaticThought: string;
  thoughtBelief: number;
  // thoughtsFor: string[];
  // thoughtsForAlternativeThoughts: string[] | null[] | (string | null)[];
  thoughtsFor: {
    for: string;
    alternativeThought: string | null;
  }[];
  thoughtsAgainst: string[];
  cognitiveDistortions?: number[];
  realisticThoughts: typeof emptyBasicThought[];
  result: {
    initialThoughtBelief: number;
    initialNegativeEmotionStrenght: number;
  };
  cardName: string;
  categoriesIds: number[] | null;

  // Reminders
  dayReminders?: DayReminders;
  // locationReminders?: LocationReminder[];
};

export const CreateThought: React.FC<IResourceComponentsProps> = () => {
  const [form] = Form.useForm<CreateActivatingEventForm>();

  const { t, i18n } = useTranslation();
  const { data: user } = useGetIdentity<IUser>();
  const location = routerProvider.useLocation();
  const translate = useTranslate();

  const { portalDysThoughtTestValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const validationRules = getYupSyncValidator(
    translate,
    portalDysThoughtTestValidator,
  ) as RuleObject;

  const [isCardFlipped, setIsCardFlipped] = useState(false);

  // Note: as RuleObject is silenciing Antd since it seems to have wrong types

  const {
    handleFormChange,
    isDisabled: isSubmitDisabled,
    fieldsCount,
  } = useFormSubmitDisabledAnyTouched(form);

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<User>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, //Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const {
    mutate,
    data: createData,
    isLoading: createLoading,
    error: createError,
  } = useCustomMutation<{ thought: Thought }>();

  const baseEmotions = useMemo(() => {
    const baseEmotions = i18n.languages[0]?.startsWith('ru')
      ? baseEmotionsRu
      : baseEmotionsEn;

    return baseEmotions.map((e) => ({
      ...e,
      children: e.children.map((ch) => ({ ...ch, strength: 50 })),
    }));
  }, [i18n.languages[0]]);

  const flattenedEmotions = useMemo(() => {
    return baseEmotions.reduce(
      (acc, val) => [...acc, ...val.children],
      [] as typeof baseEmotionsEn[0]['children'],
    );
  }, [baseEmotions]);

  const baseFeelings = i18n.languages[0]?.startsWith('ru')
    ? baseFeelingsRu
    : baseFeelingsEn;

  const headerFeelings = i18n.languages[0]?.startsWith('ru')
    ? basicFeelingsRu
    : basicFeelingsEn;

  const bodyParts = i18n.languages[0]?.startsWith('ru')
    ? bodyPartsRu
    : bodyPartsEn;

  const setEmotions = useWatch('emotions', form) || [];
  const bodyPartsFeelings = useWatch('bodyPartsFeelings', form) || [];
  const dayReminders = useWatch('dayReminders', form);

  const bodyPartsAsArray = useMemo(
    () =>
      numberToOnesAndZerosArrayOfArrays(
        headerFeelings.length,
        bodyParts.length,
        // @ts-ignore
        bodyPartsFeelings,
      ),
    [bodyPartsFeelings],
  );

  const feelingsColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'feelingName',
      },
      ...headerFeelings.map((feeling) => {
        return {
          title: feeling,
          dataIndex: feeling,
          render: (value: any, record: any) => {
            return (
              <Checkbox
                checked={!!value}
                onChange={(e) => {
                  const checked = e.target.checked;
                  const copiedBodyPartsAsArray = cloneDeep(bodyPartsAsArray);
                  const columnIdx = headerFeelings.indexOf(feeling);
                  copiedBodyPartsAsArray[record.key][columnIdx] = checked
                    ? 1
                    : 0;
                  const encodedArray = onesAndZerosArrayOfArraysToNumber(
                    copiedBodyPartsAsArray,
                  );

                  form.setFieldValue('bodyPartsFeelings', encodedArray);
                }}
              />
            );
          },
        };
      }),
    ];
  }, [headerFeelings, bodyPartsAsArray]);

  const feelingsData = useMemo(() => {
    return bodyPartsAsArray.map((row, i) => {
      return Object.assign(
        {},
        ...[
          { key: i },
          {
            feelingName: bodyParts[i],
          },
          ...row.map((col, j) => {
            return {
              [headerFeelings[j]]: col,
            };
          }),
        ],
      );
    });
  }, [feelingsColumns, bodyPartsAsArray]);

  const dayRemindersColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'period',
        render: (period: string) => {
          return <Text strong>{translate(`createThought.${period}`)}</Text>;
        },
      },
      {
        title: '',
        dataIndex: 'time',
        render: (
          time: DayReminders['day']['time'],
          record: DayReminders['day'],
        ) => {
          return (
            <DateFnsTimePicker
              defaultValue={set(new Date(), {
                hours: time.hours,
                minutes: time.minutes,
              })}
              format={i18n.languages[0]?.startsWith('ru') ? 'HH:mm' : 'hh:mm a'}
              onChange={(date) => {
                const hours = date?.getHours() || 0;
                const minutes = date?.getMinutes() || 0;

                const copiedDayReminders = {
                  ...form.getFieldValue('dayReminders'),
                };
                copiedDayReminders[
                  // @ts-ignore
                  record.key
                ].time = {
                  hours,
                  minutes,
                };
                form.setFieldValue('dayReminders', copiedDayReminders);
              }}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'enabled',
        render: (enabled: boolean, record: DayReminders['day']) => {
          return (
            <Checkbox
              checked={enabled}
              onChange={(e) => {
                const checked = e.target.checked;

                const copiedDayReminders = {
                  ...form.getFieldValue('dayReminders'),
                };
                copiedDayReminders[
                  // @ts-ignore
                  record.key
                ].enabled = checked;
                form.setFieldValue('dayReminders', copiedDayReminders);
              }}
            />
          );
        },
      },
    ];
  }, [dayReminders]);

  const dayRemindersData = useMemo(() => {
    return Object.keys(dayReminders || {}).map((period) => {
      return {
        key: period,
        period,
        // @ts-ignore
        ...(dayReminders?.[period] || {}),
      };
    });
  }, [dayReminders]);

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  const { innerWidth } = useWindowSize();
  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const initialValues = useMemo(() => {
    return {
      clientId:
        therapistActivityState.context.filterClientId ||
        parsedSearch.clientId ||
        '',
      thoughtBelief: 50,
      bodyPartsFeelings: 0,
      feelings: [],
      emotions: [],
      behaviors: '',
      // thoughtsFor: [''],
      thoughtsFor: [{ for: '', alternativeThought: '' }],
      thoughtsAgainst: [''],
      realisticThoughts: [{ ...emptyBasicThought }],

      result: {
        initialThoughtBelief: 50,
        initialNegativeEmotionStrenght: 50,
      },
      dayReminders: {
        morning: {
          time: { hours: 9, minutes: 0 },
          enabled: true,
        },
        day: {
          time: { hours: 14, minutes: 0 },
          enabled: true,
        },
        evening: {
          time: { hours: 21, minutes: 0 },
          enabled: true,
        },
      },
    };
  }, [parsedSearch.clientId, therapistActivityState.context.filterClientId]);

  const clientId = useWatch('clientId', form);

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const onSave = async () => {
    const { thoughtsFor, emotions, feelings, result, behaviors, ...values } =
      form.getFieldsValue();

    const remappedValues = {
      ...values,
      emotions: emotions ? emotions : [],
      feelings: feelings ? feelings : [],
      behaviors: behaviors ? behaviors : '',
      result: result ? result : initialValues.result,
      thoughtsFor: thoughtsFor?.map((t) => t.for),
      thoughtsForAlternativeThoughts: thoughtsFor?.map(
        (t) => t.alternativeThought,
      ),
    };

    const mergedValues = merge(initialValues, remappedValues);

    try {
      const res = await mutate({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'createPortalThought',
          fields: [{ thought: ['_id, updatedAt'] }],
          variables: {
            input: {
              name: 'input',
              type: 'PortalThoughtCreateInput',
              value: mergedValues,
            },
          },
        },
      });
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (createData?.data?.thought?.updatedAt) {
      const handle1 = setTimeout(() => {
        setIsCardFlipped(true);
      }, 1500);

      const handle2 = setTimeout(() => {
        setIsCardFlipped(false);
      }, 4500);

      return () => {
        clearTimeout(handle1);
        clearTimeout(handle2);
      };
    }
  }, [createData?.data?.thought?.updatedAt]);

  useEffect(() => {
    if (createData?.data?.thought?._id) {
      therapistActivitySend({
        type: 'CREATED',
        resource: createData?.data?.thought._id,
      });
    }
  }, [createData?.data?.thought?._id]);

  useActivityTracking(
    createData?.data?.thought?._id || `${dbCols.thoughts}/create`,
  );

  return (
    <Create
      title={translate('createThought.create')}
      saveButtonProps={{
        onClick: onSave,
        disabled:
          isSubmitDisabled || fieldsCount < 10 || createData?.data?.thought._id,
      }}
    >
      <Form<CreateActivatingEventForm>
        form={form}
        initialValues={initialValues}
        onFinish={(values) => {
          console.log('values', values);
        }}
        onFieldsChange={handleFormChange}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
        labelWrap
      >
        <Form.Item
          label={
            <>
              {translate('createThought.selectClient')}
              <Tooltip title={translate('createThought.selectClientTip')}>
                <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            </>
          }
          name="clientId"
          required
          rules={[validationRules]}
        >
          <Select
            showSearch
            placeholder={translate('createThought.selectClient')}
            optionFilterProp="children"
            loading={clientsLoading}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {
              // @ts-ignore
              ((clients?.data?.data as Client[]) || [])
                .filter((c) => {
                  return !!c.accessLevel;
                })
                .filter(
                  (c) =>
                    c.access?.allowEdit ||
                    (user?.db?.role === UserRole.THERAPIST_ADMIN &&
                      c.access?.allowAdminEdit),
                )
                .map((client) => (
                  <Select.Option key={client._id} value={client._id}>
                    {client.firstName +
                      ' ' +
                      client.lastName +
                      ' - (' +
                      client._id +
                      ')'}
                  </Select.Option>
                ))
            }
          </Select>
        </Form.Item>

        <Collapse collapsible="header" defaultActiveKey={['1']}>
          <Panel header={`1. ${translate('createThought.eventTitle')}`} key="1">
            <Form.Item
              name="description"
              label={translate('createThought.eventTitle')}
              required
              rules={[validationRules]}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                size="large"
                placeholder={translate('createThought.eventLabel')}
              />
            </Form.Item>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`2. ${translate('createThought.emotionsTitle')}`}
            key="2"
          >
            <Form.Item
              name={['emotions', 'id']}
              label={translate('createThought.chooseEmotions')}
              required
              rules={[{ type: 'array', required: true }]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={(emotionsIds) => {
                  const eventEmotions =
                    (emotionsIds as unknown as number[]).map((id) => {
                      const emotion = flattenedEmotions.find(
                        (e) => e.id === id,
                      );
                      if (emotion) {
                        const existingEmotion = setEmotions.find(
                          (em) => em.id === id,
                        );
                        return {
                          id: emotion.id,
                          strength: !!existingEmotion
                            ? existingEmotion.strength
                            : 50,
                        };
                      }
                    }) || [];

                  form.setFieldValue('emotions', eventEmotions);
                }}
              >
                {baseEmotions.map((eg) => {
                  return (
                    <OptGroup label={eg.name} key={eg.name}>
                      {eg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            {!!setEmotions?.length && (
              <Title level={5} style={{ textAlign }}>
                {translate('createThought.rateEmotions')}:
              </Title>
            )}

            <Form.List name="emotions">
              {(fields, { add, remove }, errors) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Row key={field.key}>
                          <Col span={13} push={2}>
                            <Form.Item
                              {...field}
                              label={
                                flattenedEmotions.find(
                                  (e) => e.id === setEmotions[field.key].id,
                                )?.name || ''
                              }
                              key={field.key}
                              name={[field.key, 'strength']}
                            >
                              <Slider min={1} max={100} />
                            </Form.Item>
                          </Col>
                          <Col span={1}>
                            <Form.Item
                              {...field}
                              key={field.key}
                              name={[field.key, 'strength']}
                            >
                              <InputNumber min={1} max={100} />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                );
              }}
            </Form.List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`3. ${translate('createThought.feelingsTitle')}`}
            key="3"
          >
            <Form.Item
              label={translate('createThought.chooseFeelings')}
              name={['feelings']}
              rules={[validationRules]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {baseFeelings.map((bg) => {
                  return (
                    <OptGroup label={bg.name} key={bg.name}>
                      {bg.children.map((e) => {
                        return (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="bodyPartsFeelings"
              label={translate('createThought.feelingsInBody')}
              rules={[validationRules]}
            >
              <Table
                columns={feelingsColumns}
                dataSource={feelingsData}
                pagination={false}
              />
            </Form.Item>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`4. ${translate('shared.abcModel.behaviors')}`}
            key="4"
          >
            <Form.Item
              name="behaviors"
              label={translate('shared.abcModel.behaviors')}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                size="large"
                placeholder={translate('shared.abcModel.behaviorsPlaceholder')}
              />
            </Form.Item>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`5. ${translate('createThought.automaticThoughtTitle')}`}
            key="5"
          >
            <Form.Item
              label={translate('createThought.automaticThoughtTitle')}
              name="automaticThought"
              required
              rules={[validationRules]}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                placeholder={translate('createThought.automaticThoughtTitle')}
              />
            </Form.Item>

            <Row>
              <Col span={13} push={2}>
                <Form.Item
                  label={translate('createThought.automaticThoughtBelief')}
                  name={'thoughtBelief'}
                >
                  <Slider min={1} max={100} />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item name={'thoughtBelief'}>
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`6. ${translate('createThought.thoughtsForTitle')}`}
            key="6"
          >
            <Form.List name="thoughtsFor">
              {(fields, { add, remove }, errors) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <>
                          <Form.Item
                            label={translate('createThought.evidenceFor')}
                            wrapperCol={{
                              offset: 0,
                              span: 10,
                            }}
                            key={field.key}
                            required={index === 0}
                          >
                            <div style={{ display: 'flex' }}>
                              <Form.Item
                                {...field}
                                style={{ width: '100%' }}
                                name={[`${field.name}`, 'for']}
                                rules={[
                                  {
                                    required: true,
                                    message: translate(
                                      'createThought.thoughtEvidenceRequired',
                                    ),
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  autoSize={{ minRows: 4, maxRows: 8 }}
                                  placeholder={translate(
                                    'createThought.evidenceFor',
                                  )}
                                />
                              </Form.Item>

                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  style={{ margin: '5px 0 0 10px' }}
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </div>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            label={translate(
                              'createThought.alternativeThought',
                            )}
                            name={[`${field.name}`, 'alternativeThought']}
                            tooltip={translate(
                              'createThought.alternativeThoughtTip',
                            )}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 4, maxRows: 8 }}
                              placeholder={translate(
                                'createThought.alternativeThought',
                              )}
                            />
                          </Form.Item>
                          <Divider />
                        </>
                      );
                    })}

                    <Form.Item wrapperCol={{ offset: 5 }}>
                      <Button
                        type="primary"
                        onClick={() => add({ for: '', alternativeThought: '' })}
                        icon={<PlusOutlined />}
                      >
                        {translate('createThought.addEvidenceFor')}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`7. ${translate('createThought.thoughtsAgainstTitle')}`}
            key="7"
          >
            <Form.List name="thoughtsAgainst">
              {(fields, { add, remove }, errors) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Form.Item
                          label={
                            index === 0
                              ? translate('createThought.evidenceAgainst')
                              : ''
                          }
                          wrapperCol={{
                            offset: index === 0 ? 0 : 5,
                            span: 10,
                          }}
                          key={field.key}
                          required={index === 0}
                        >
                          <div style={{ display: 'flex' }}>
                            <Form.Item
                              {...field}
                              style={{ width: '100%' }}
                              rules={[
                                {
                                  required: true,
                                  message: translate(
                                    'createThought.evidenceAgainstRequired',
                                  ),
                                },
                              ]}
                            >
                              <Input.TextArea
                                autoSize={{ minRows: 4, maxRows: 8 }}
                                placeholder={translate(
                                  'createThought.evidenceAgainst',
                                )}
                              />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{ margin: '5px 0 0 10px' }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </div>
                        </Form.Item>
                      );
                    })}
                    <Form.Item wrapperCol={{ offset: 5 }}>
                      <Button
                        type="primary"
                        onClick={() => add('')}
                        icon={<PlusOutlined />}
                      >
                        {translate('createThought.addEvidenceAgainst')}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`8. ${translate('createThought.cognitiveDistortionsTile')}`}
            key="8"
          >
            <Form.Item
              label={translate('createThought.cognitiveDistortions')}
              name="cognitiveDistortions"
              rules={[validationRules]}
            >
              <Select
                placeholder={translate(
                  'createThought.cognitiveDistortionsPlaceholder',
                )}
                optionFilterProp="children"
                loading={clientsLoading}
                mode="multiple"
                allowClear
              >
                {Object.keys(translationEn.cognitiveDistorions).map((key) => (
                  <Select.Option key={key} value={key}>
                    {translate(`cognitiveDistorions.${key}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`9. ${translate('createThought.realisticThoughtsTitle')}`}
            key="9"
          >
            <Form.List
              name="realisticThoughts"
              // @ts-ignore
              rules={[validationRules]}
            >
              {(fields, { add, remove }, errors) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Form.Item
                          label={
                            index === 0
                              ? translate('createThought.realisticThought')
                              : ''
                          }
                          wrapperCol={{
                            offset: index === 0 ? 0 : 5,
                            span: 10,
                          }}
                          key={field.key}
                          required={index === 0}
                        >
                          <div style={{ display: 'flex' }}>
                            <Form.Item
                              {...field}
                              style={{ width: '100%' }}
                              name={[field.key, 'thought']}
                              rules={[
                                {
                                  required: true,
                                  message: translate(
                                    'createThought.realisticThoughtRequired',
                                  ),
                                },
                              ]}
                            >
                              <Input.TextArea
                                autoSize={{ minRows: 4, maxRows: 8 }}
                                placeholder={translate(
                                  'createThought.realisticThought',
                                )}
                              />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{ margin: '5px 0 0 10px' }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </div>

                          <Row>
                            <Col span={14}>
                              <Form.Item
                                {...field}
                                label={translate(
                                  'createThought.automaticThoughtBelief',
                                )}
                                key={field.key}
                                name={[field.key, 'thoughtBelief']}
                              >
                                <Slider min={1} max={100} />
                              </Form.Item>
                            </Col>
                            <Col span={1} push={1}>
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.key, 'thoughtBelief']}
                              >
                                <InputNumber min={1} max={100} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      );
                    })}
                    <Form.Item wrapperCol={{ offset: 5 }}>
                      <Button
                        type="primary"
                        onClick={() => add({ ...emptyBasicThought })}
                        icon={<PlusOutlined />}
                      >
                        {translate('createThought.automaticThoughtAdd')}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`10. ${translate('createThought.resultsTitle')}`}
            key="10"
          >
            <p>{translate('createThought.resultBelief')}</p>
            <Row>
              <Col span={13} push={1}>
                <Form.Item
                  labelCol={{ span: 8 }}
                  label={translate('createThought.initialThoughtBelief')}
                  name={['result', 'initialThoughtBelief']}
                >
                  <Slider min={1} max={100} />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item name={['result', 'initialThoughtBelief']}>
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>

            <p>{translate('createThought.resultEmotionStrength')}</p>

            <Row>
              <Col span={13} push={1}>
                <Form.Item
                  labelCol={{ span: 8 }}
                  label={translate('createThought.initialEmotionStrength')}
                  name={['result', 'initialNegativeEmotionStrenght']}
                >
                  <Slider min={1} max={100} />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item name={['result', 'initialNegativeEmotionStrenght']}>
                  <InputNumber min={1} max={100} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel
            header={`11. ${translate('createThought.timeRemindersTitle')}`}
            key="11"
          >
            <Form.Item
              name="dayReminders"
              label={translate('createThought.timeRemindersTitle')}
              rules={[validationRules]}
            >
              <Table
                showHeader={false}
                columns={dayRemindersColumns}
                dataSource={dayRemindersData}
                pagination={false}
              />
            </Form.Item>
          </Panel>
        </Collapse>

        {/* <Collapse>
          <Panel
            header={`12. ${translate('createThought.locationRemindersTitle')}`}
            key="12"
          >
            <Form.Item
              label={translate('invites.fields.lastName')}
              name="lastName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Panel>
        </Collapse> */}

        {!createLoading &&
          (createData?.data?.thought?.updatedAt || !!createError) && (
            <Result
              status={createError ? 'error' : 'success'}
              title={
                createError
                  ? createError.message
                  : translate('createThought.createSuccess')
              }
              extra={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ width: 400 }}>
                    <ReactCardFlip
                      isFlipped={isCardFlipped}
                      flipDirection="horizontal"
                      flipSpeedBackToFront={2}
                      flipSpeedFrontToBack={2}
                    >
                      <Card
                        style={{ width: 400 }}
                        onClick={() => setIsCardFlipped(!isCardFlipped)}
                        bordered
                        hoverable
                        bodyStyle={{
                          minHeight: 200,
                          boxShadow: '0 0 10px #ccc',
                          background: '#45403e',
                          color: '#f5f1f1',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 10,
                        }}
                      >
                        {form.getFieldValue('automaticThought')}
                      </Card>

                      <Card
                        style={{ width: 400 }}
                        onClick={() => setIsCardFlipped(!isCardFlipped)}
                        bordered
                        hoverable
                        bodyStyle={{
                          minHeight: 200,
                          boxShadow: '0 0 10px #ccc',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 10,
                        }}
                      >
                        {form
                          .getFieldValue('realisticThoughts')
                          ?.map((t: any, idx: number) => {
                            return <p key={idx}>{t.thought}</p>;
                          })}
                      </Card>
                    </ReactCardFlip>
                  </div>
                </div>
              }
            />
          )}
        <Divider />
      </Form>
    </Create>
  );
};
