export const baseFeelingsEn = [
  {
    name: 'Common',
    id: 1,
    children: [
      {
        name: 'bitterness in the mouth',
        id: 100,
      },
      {
        name: 'chills',
        id: 101,
      },
      {
        name: 'fever',
        id: 102,
      },
      {
        name: 'dizziness',
        id: 103,
      },
      {
        name: 'nausea',
        id: 104,
      },
      {
        name: 'heartbeat rate change',
        id: 105,
      },
      {
        name: 'weakness',
        id: 106,
      },
      {
        name: 'tremor',
        id: 107,
      },
      {
        name: 'goosebumps',
        id: 108,
      },
      {
        name: 'lump in the throat',
        id: 109,
      },
      {
        name: 'heavy heart',
        id: 110,
      },
      {
        name: 'itching',
        id: 111,
      },
      {
        name: 'sweaty hands',
        id: 112,
      },
      {
        name: 'tingling',
        id: 113,
      },
      {
        name: 'numbness',
        id: 114,
      },
    ],
  },
  {
    name: 'Breath',
    id: 2,
    children: [
      {
        name: 'slow',
        id: 200,
      },
      {
        name: 'rapid',
        id: 201,
      },
      {
        name: 'deep',
        id: 202,
      },
      {
        name: 'shallow',
        id: 203,
      },
    ],
  },
  {
    name: 'Visual perception',
    id: 3,
    children: [
      {
        name: 'brightness and saturation',
        id: 300,
      },
      {
        name: 'dullness',
        id: 301,
      },
      {
        name: 'eyesight darkening/black out',
        id: 302,
      },
      {
        name: 'sharpness reduction',
        id: 303,
      },
    ],
  },
  {
    name: 'Hearing',
    id: 4,
    children: [
      {
        name: 'volume and saturation',
        id: 400,
      },
      {
        name: 'muffled',
        id: 401,
      },
      {
        name: 'tinnitus',
        id: 402,
      },
      {
        name: 'noise in the ears',
        id: 403,
      },
      {
        name: 'popped ears',
        id: 404,
      },
    ],
  },
  {
    name: 'Smell and Taste',
    id: 5,
    children: [
      {
        name: 'saturation',
        id: 500,
      },
      {
        name: 'tastelessness, dullness',
        id: 501,
      },
    ],
  },
  {
    name: 'Other',
    id: 6,
    children: [
      {
        name: 'not listed above',
        id: 600,
      },
      {
        name: 'I do not feel anything',
        id: 601,
      },
    ],
  },
];

export const baseFeelingsRu = [
  {
    name: 'Общие',
    id: 1,
    children: [
      {
        name: 'горечь во рту',
        id: 100,
      },
      {
        name: 'озноб',
        id: 101,
      },
      {
        name: 'жар',
        id: 102,
      },
      {
        name: 'головокружение',
        id: 103,
      },
      {
        name: 'тошнота',
        id: 104,
      },
      {
        name: 'изменение сердцебиения',
        id: 105,
      },
      {
        name: 'мышечная слабость',
        id: 106,
      },
      {
        name: 'тремор',
        id: 107,
      },
      {
        name: 'мурашки по коже',
        id: 108,
      },
      {
        name: 'ком в горле',
        id: 109,
      },
      {
        name: '"камень" на сердце',
        id: 110,
      },
      {
        name: 'зуд',
        id: 111,
      },
      {
        name: 'потение рук',
        id: 112,
      },
      {
        name: 'покалывание',
        id: 113,
      },
      {
        name: 'онемение',
        id: 114,
      },
    ],
  },
  {
    name: 'Дыхание',
    id: 2,
    children: [
      {
        name: 'замедленное',
        id: 200,
      },
      {
        name: 'ускоренное',
        id: 201,
      },
      {
        name: 'глубокое',
        id: 202,
      },
      {
        name: 'поверхностное',
        id: 203,
      },
    ],
  },
  {
    name: 'Зрительное восприятие',
    id: 3,
    children: [
      {
        name: 'яркость и насыщенность зрительных образов',
        id: 300,
      },
      {
        name: 'тусклость зрительных образов',
        id: 301,
      },
      {
        name: 'потемнение в глазах',
        id: 302,
      },
      {
        name: 'снижение резкости',
        id: 303,
      },
    ],
  },
  {
    name: 'Слуховое восприятие',
    id: 4,
    children: [
      {
        name: 'громкость и насыщенность звуков',
        id: 400,
      },
      {
        name: 'звуки более глухие',
        id: 401,
      },
      {
        name: 'звон в ушах',
        id: 402,
      },
      {
        name: 'шум в ушах',
        id: 403,
      },
      {
        name: 'заложило уши',
        id: 404,
      },
    ],
  },
  {
    name: 'Обоняние и вкус',
    id: 5,
    children: [
      {
        name: 'насыщенность вкусов и запахов',
        id: 500,
      },
      {
        name: 'безвкусие, тусклость запахов',
        id: 501,
      },
    ],
  },
  {
    name: 'Другое',
    id: 6,
    children: [
      {
        name: 'не указано выше',
        id: 600,
      },
      {
        name: 'я ничего не чувствую',
        id: 601,
      },
    ],
  },
];

export const basicFeelingsEn = ['Chill', 'Heat', 'Pain', 'Tension'];
export const bodyPartsEn = [
  'Head',
  'Neck/throat',
  'Hands',
  'Upper body',
  'Middle body',
  'Lower body',
  'Legs',
];

export const basicFeelingsRu = ['Холод', 'Жар', 'Боль', 'Напряжение'];
export const bodyPartsRu = [
  'Голова',
  'Шея/горло',
  'Руки',
  'Верх тела',
  'Середина тела',
  'Низ  тела',
  'Ноги',
];

export const feelingsInBody = bodyPartsEn.reduce((acc, v, idx) => {
  acc[idx] = basicFeelingsEn.reduce((facc, fv, fidx) => {
    facc[fidx] = false;
    return facc;
  }, {} as Record<number, boolean>);
  return acc;
}, {} as Record<number, Record<number, boolean>>);

export const baseFeelingsEnHash = baseFeelingsEn
  .map((feelingsGroup) => feelingsGroup.children)
  .flat(1)
  .reduce((acc, feeling) => {
    acc[feeling.id] = feeling.name;
    return acc;
  }, {} as Record<string, string>);

export const baseFeelingsRuHash = baseFeelingsRu
  .map((feelingsGroup) => feelingsGroup.children)
  .flat(1)
  .reduce((acc, feeling) => {
    acc[feeling.id] = feeling.name;
    return acc;
  }, {} as Record<string, string>);
