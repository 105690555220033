// Free level
type FeatureCount = null | number;

export const FEATURE_FLAGS_IDS = {
  cardsSearch: 'featureFlags/1',
  articlesView: 'featureFlags/2',
  statsView: 'featureFlags/3',
  locationReminders: 'featureFlags/4',
  dayReminders: 'featureFlags/5',
  phq9: 'featureFlags/6',
  gad7: 'featureFlags/7',
  meditations: 'featureFlags/8',
  worryTrees: 'featureFlags/9',
  crisisContact: 'featureFlags/10',
  downwardArrows: 'featureFlags/11',
  behaviorExperiments: 'featureFlags/12',
  exposures: 'featureFlags/13',
  exposureSituations: 'featureFlags/14',
  exposureSituationReactions: 'featureFlags/15',
  aiImages: 'featureFlags/16',
  videoView: 'featureFlags/17',
  login: 'featureFlags/18',
};

export const featureFlags = {
  cardsSearch: {
    name: 'cardsSearch',
    count: null as FeatureCount, // unlimited
  },
  // TODO: Articles are very likely to be moved to DB. Stats would need
  // an update
  articlesView: {
    name: 'articlesView',
    count: null as FeatureCount, // unlimited
  },
  statsView: {
    name: 'statsView',
    count: 1 as FeatureCount,
  },
  locationReminders: {
    name: 'locationReminders',
    count: 2 as FeatureCount,
  },
  dayReminders: {
    name: 'dayReminders',
    count: 9 as FeatureCount,
  },
  phq9: {
    name: 'phq9',
    count: 14 as FeatureCount, // To be used as limit in days since last test
  },
  gad7: {
    name: 'gad7',
    count: 14 as FeatureCount, // To be used as limit in days since last test
  },
  meditations: {
    name: 'meditations',
    count: 3 as FeatureCount, // To be used as limit in days since last play
  },
  worryTrees: {
    name: 'worryTrees',
    count: 1 as FeatureCount, // To be used as limit in days since last play
  },
  downwardArrows: {
    name: 'downwardArrows',
    count: 2 as FeatureCount, // To be used as limit in days since last play
  },
  crisisContact: {
    name: 'crisisContact',
    count: null as FeatureCount, // unlimited
  },
  behaviorExperiments: {
    name: 'behaviorExperiments',
    count: 3 as FeatureCount, // To be used as limit in days since last created
  },
  exposures: {
    name: 'exposures',
    count: 3 as FeatureCount, // To be used as limit in days since last created (1 exposure per 3 days)
  },
  exposureSituations: {
    name: 'exposureSituations',
    count: null, // No limit currently
  },
  exposureSituationReactions: {
    name: 'exposureSituationReactions',
    count: null, // No limit currently
  },
  aiImages: {
    name: 'aiImages',
    count: 3 as FeatureCount, // To be used as free limit within 24 hours
  },
  videoView: {
    name: 'videoView',
    count: null,
  },
  login: {
    name: 'login',
    count: null,
  },
};

export const premiumFeatureFlags: typeof featureFlags = Object.entries(
  featureFlags,
).reduce((acc, entry) => {
  // @ts-ignore
  acc[entry[0]] = { ...entry[1], count: null };
  return acc;
}, {} as typeof featureFlags);
