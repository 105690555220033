import { CopyOutlined } from '@ant-design/icons';
import { Invite, InviteType } from '@cc/schema';
import {
  Button,
  CreateButton,
  DeleteButton,
  List,
  Space,
  Table,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useGetIdentity,
  useGetLocale,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import { format } from 'date-fns';
import { ClientsLimitReached } from '../../components/ClientsLimitReached';
import { SubscriptionExpired } from '../../components/SubscriptionExpired';
import { useSubscriptionWarnings } from '../../hooks';
import { IUser } from '../../providers';

const { Text, Title } = Typography;

export const generateInviteLink = (token: string, type: InviteType) => {
  if (type === InviteType.CLIENT) {
    return `https://copingcard.com/deep/join/${token}`;
  }

  return `https://portal.copingcard.com/join/${token}`;
};

export const InviteList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const locale = useGetLocale();

  const currentLocale = locale();

  const { data: user } = useGetIdentity<IUser>();

  const subWarnings = useSubscriptionWarnings();

  const { replace } = useNavigation();

  const { tableProps } = useTable<Invite>({
    queryOptions: {
      select: (data) => {
        // @ts-ignore
        return { data: data.data.data, total: data.total };
      },
    },
    initialSorter: [
      {
        field: 'expiresAt',
        order: 'asc',
      },
    ],
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'createdAt',
            'expiresAt',
            'from',
            'type',
            'to',
            'token',
            'firstName',
            'lastName',
            'email',
            'message',
          ],
        },
        'count',
      ],
    },
  });

  return (
    <List
      title={translate('invites.invites')}
      pageHeaderProps={{
        extra: (
          <Space wrap>
            <CreateButton
              disabled={
                subWarnings.limitReached || subWarnings.subscriptionExpired
              }
            />
          </Space>
        ),
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div
          style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}
        >
          <Title style={{ marginRight: 10 }} level={5}>
            {translate('invites.permanentInviteLink')}:
          </Title>
          {!subWarnings.limitReached && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Text style={{ marginRight: '15px' }}>
                {generateInviteLink(user?.db?._id || '', InviteType.CLIENT)}
              </Text>
              <Button
                size="small"
                icon={<CopyOutlined />}
                title={translate('invites.fields.copy')}
                onClick={() => {
                  const text = generateInviteLink(
                    user?.db?._id || '',
                    InviteType.CLIENT,
                  );
                  navigator.clipboard.writeText(text);
                }}
              >
                {translate('invites.fields.copy')}
              </Button>
            </div>
          )}
        </div>
        <ClientsLimitReached />
        <SubscriptionExpired />
      </div>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          key="type"
          dataIndex="type"
          title={translate('invites.fields.type')}
          render={(text, record, indx) => {
            if (text !== 0 && !text) return undefined;
            return translate(`invites.types.${text}`);
          }}
        />
        <Table.Column
          key="firstName"
          dataIndex="firstName"
          title={translate('invites.fields.firstName')}
        />
        <Table.Column
          key="lastName"
          dataIndex="lastName"
          title={translate('invites.fields.lastName')}
        />
        <Table.Column
          key="createdAt"
          dataIndex="createdAt"
          title={translate('invites.fields.createdAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss aaa',
            );
          }}
        />
        <Table.Column
          key="expiresAt"
          dataIndex="expiresAt"
          title={translate('invites.fields.expiresAt')}
          sorter
          render={(text, record, indx) => {
            if (!text) return undefined;
            const date = new Date(text);
            return format(
              date,
              currentLocale?.startsWith('ru')
                ? 'yyyy-MM-dd HH:mm:ss'
                : 'yyyy-MM-dd hh:mm:ss aaa',
            );
          }}
        />
        {/* <Table.Column
          key="from"
          dataIndex="from"
          title={translate('invites.fields.from')}
        />
        <Table.Column
          key="to"
          dataIndex="to"
          title={translate('invites.fields.to')}
        /> */}
        <Table.Column
          key="token"
          dataIndex="token"
          title={translate('invites.fields.token')}
          render={(text, record, indx) => {
            return (
              <div>
                {text}{' '}
                <Tooltip title={translate('invites.copyToken')}>
                  <Button
                    onClick={() => navigator.clipboard.writeText(text)}
                    style={{ marginLeft: '5px' }}
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column
          key="email"
          dataIndex="email"
          title={translate('invites.fields.email')}
          sorter
        />
        <Table.Column<Invite>
          title={translate('resourcesCommon.actions')}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                size="small"
                icon={<CopyOutlined />}
                title={translate('invites.fields.copy')}
                onClick={() => {
                  const text = generateInviteLink(record.token, record.type);
                  navigator.clipboard.writeText(text);
                }}
              >
                {translate('invites.fields.copy')}
              </Button>
              <DeleteButton
                metaData={{
                  fields: ['_id'],
                }}
                hideText
                size="small"
                recordItemId={record._id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
