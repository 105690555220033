import { useIdleTimer } from 'react-idle-timer';
import type { PresenceType } from 'react-idle-timer';
import { useContext, useEffect, useState } from 'react';
import { TherapistActivityReactContext } from '../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../machines/therapistActivityMachine';

export const useActivityTracking = (resource: string) => {
  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const [previousResource, setPreviousResource] = useState('');

  useEffect(() => {
    const previousPrefix = previousResource.split('/')[0];
    const currentPrefix = resource.split('/')[0];
    if (
      !!resource &&
      previousPrefix === currentPrefix &&
      previousResource !== resource
    ) {
      therapistActivitySend({
        type: 'RESOURCE_UPDATED',
        resource,
      });
    }

    setPreviousResource(resource);
  }, [resource]);

  useEffect(() => {
    therapistActivitySend({
      type: 'LOADED',
      resource,
    });

    return () => {
      therapistActivitySend({
        type: 'LEFT',
      });
      setPreviousResource('');
    };
  }, []);

  const onPresenceChange = (presence: PresenceType) => {
    const isIdle = presence.type === 'idle';
    const isActive = presence.type === 'active' && !presence.prompted;
    const isPrompted = presence.type === 'active' && presence.prompted;
    if (isIdle) {
      therapistActivitySend({
        type: 'TIMED_OUT',
        resource,
      });
    }
    if (isActive) {
      therapistActivitySend({
        type: 'RESUMED',
        resource,
      });
    }
  };

  useIdleTimer({
    onPresenceChange,
    timeout: 60_000,
  });
};
