import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { timeout } from '@cc/utils';

export const useFormSubmitDisabled = <T>(
  form: FormInstance<T>,
  keys = [] as (string | string[])[],
  skipTouched = false,
) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const handleFormChange = async () => {
    // const values = form.getFieldsValue();
    // Note: Need to do it next cycle to allow form to update
    await timeout(50);
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    const allFields = keys.length ? keys : Object.keys(form.getFieldsValue());
    const allFieldsTouched = skipTouched
      ? true
      : form.isFieldsTouched(allFields, true);

    // const errors = form.getFieldsError();
    // debugger;

    setIsDisabled(hasErrors || !allFieldsTouched);
  };

  // Running first time to do initial validation
  useEffect(() => {
    handleFormChange();
  }, []);

  return { handleFormChange, isDisabled };
};

export const useFormSubmitDisabledAnyTouched = <T>(form: FormInstance<T>) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [fieldsCount, setFieldsCount] = useState(0);

  const handleFormChange = async () => {
    // Note: Need to do it next cycle to allow form to update
    await timeout(50);
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    // const errors = form.getFieldsError();
    // const values = form.getFieldsValue();
    const allFields = Object.keys(form.getFieldsValue());

    setFieldsCount(allFields.length);

    const anyTouched = allFields.reduce((acc, cur) => {
      return acc || form.isFieldTouched(cur);
    }, false);

    setIsDisabled(hasErrors || !anyTouched);
  };

  // Running first time to do initial validation
  useEffect(() => {
    handleFormChange();
  }, []);
  return { handleFormChange, isDisabled, fieldsCount };
};
