import { ArrowDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { dbCols } from '@cc/schema';
import {
  Create,
  Form,
  Input,
  message,
  Select,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { useCustom, useCustomMutation } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { useActor } from '@xstate/react';
import { debounce } from 'lodash';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { TherapistActivityReactContext } from '../../App';
import { useClientOptions } from '../../hooks/client-options';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { DownwardArrow } from '../../schema/be-graphql-generated';
import { colors } from '../../styles/colors';
import './styles.less';

const { Title } = Typography;

export const DownwardArrowCreate = () => {
  const { t, i18n } = useTranslation();
  const location = routerProvider.useLocation();

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const { clientId: locationClientId, downwardArrowId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { innerWidth } = useWindowSize();

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState.context.filterClientId ||
      (locationClientId as string),
  );

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const initialValues = useMemo(() => {
    return {
      beliefs: [''] as string[],
    };
  }, [therapistActivityState.context.filterClientId, locationClientId]);

  const [form] = Form.useForm<typeof initialValues>();

  const [arrowId, setArrowId] = useState(downwardArrowId as string | undefined);

  useActivityTracking(arrowId ? arrowId : `${dbCols.downwardArrows}/create`);

  const {
    data: downwardArrowData,
    isLoading: downwardArrowLoading,
    refetch: refetchdownwardArrow,
    error: downwardArrowError,
  } = useCustom<DownwardArrow>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'downwardArrow',
      fields: ['_id', 'beliefs', 'userId'],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: arrowId,
        },
      },
    },
  });

  useEffect(() => {
    if (downwardArrowData?.data) {
      form?.setFieldsValue({
        beliefs: downwardArrowData?.data?.beliefs || [''],
      });
      setClientId(downwardArrowData?.data.userId);
      if (downwardArrowData?.data?._id) {
        therapistActivitySend({
          type: 'LOADED',
          resource: downwardArrowData?.data?._id,
          clientId: downwardArrowData?.data?.userId,
        });
      }
    }
  }, [downwardArrowData]);

  const {
    mutate: upsertDownwardArrow,
    data: upsertDownwardArrowData,
    isLoading: upsertDownwardArrowLoading,
    error: upsertDownwardArrowError,
  } = useCustomMutation<{ downwardArrow: DownwardArrow }>();

  const saveData = useCallback(
    async (data: typeof initialValues) => {
      if (!clientId) {
        return;
      }
      const res = await upsertDownwardArrow({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertDownwardArrow',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'DownwardArrowInput',
              value: {
                data,
                where: {
                  clientId,
                  _id: arrowId,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.downwardArrow.saveSuccess'));
    },
    [clientId, arrowId, t],
  );

  useEffect(() => {
    // @ts-ignore
    if (upsertDownwardArrowData?.data?._id) {
      if (!arrowId) {
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: upsertDownwardArrowData?.data?._id,
        });

        // @ts-ignore
        setArrowId(upsertDownwardArrowData?.data?._id);
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          resource: arrowId,
        });
      }
    }
  }, [upsertDownwardArrowData]);

  const onValuesChange = useCallback(
    debounce(
      (
        changedValues: Partial<typeof initialValues>,
        allValues: typeof initialValues,
      ) => {
        saveData(allValues);
      },
      700,
    ),
    [saveData],
  );

  return (
    <Create
      title={
        <>
          {t('shared.downwardArrow.title')}

          <Tooltip
            title={t('shared.downwardArrow.description')}
            overlayClassName="downward-arrow--tooltip"
          >
            <QuestionCircleOutlined style={{ margin: '0 10px' }} />
          </Tooltip>
        </>
      }
      isLoading={clientsLoading}
      saveButtonProps={{
        style: {
          display: 'none',
        },
      }}
    >
      <div className="downward-arrow-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="downward-arrow--client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
      </div>

      <Form<typeof initialValues>
        form={form}
        initialValues={{
          ...initialValues,
        }}
        onFinish={(values) => {}}
        onValuesChange={onValuesChange}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        labelWrap
      >
        <Form.List
          name="beliefs"
          rules={[
            {
              validator: async (_, beliefs) => {
                if (!beliefs || beliefs.length < 2) {
                  return Promise.reject(new Error('At least 2 beliefs'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            const debouncedAdd = debounce(() => add(''), 500);
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <>
                      <Form.Item
                        {...field}
                        style={{ flex: 1 }}
                        label={t('shared.downwardArrow.thought')}
                      >
                        <Input.TextArea
                          id={`field$-${index}`}
                          placeholder={t('shared.downwardArrow.thought')}
                          style={{ width: '60%' }}
                          autoSize={{ minRows: 4, maxRows: 10 }}
                          onChange={(e) => {
                            if (e.target.value && fields.length < index + 2) {
                              debouncedAdd();
                            }
                          }}
                        />
                      </Form.Item>
                      {fields.length >= index + 2 && (
                        <div className="downward-arrow--question-container">
                          <ArrowDownOutlined color={colors['--triad-blue-0']} />
                          <Title level={5}>
                            {t('shared.downwardArrow.question')}
                          </Title>
                          <ArrowDownOutlined color={colors['--triad-blue-0']} />
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            );
          }}
        </Form.List>
      </Form>
    </Create>
  );
};
