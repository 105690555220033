import { QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigation, useTranslate } from '@pankod/refine-core';
import { Button, Tooltip } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { useSubscriptionWarnings } from '../../hooks';

type Props = {
  hideUpgradeButton?: boolean;
};

export const SubscriptionExpired = ({ hideUpgradeButton = false }: Props) => {
  const subWarnings = useSubscriptionWarnings();

  const translate = useTranslate();

  const { replace } = useNavigation();

  const expiringWarningColor =
    subWarnings.daysTillSubscriptionExpires > 0 &&
    subWarnings.daysTillSubscriptionExpires < 3
      ? 'white'
      : subWarnings.daysTillSubscriptionExpires > 3
      ? ''
      : '';
  const expiringWarningBackgroundColor =
    subWarnings.daysTillSubscriptionExpires > 0 &&
    subWarnings.daysTillSubscriptionExpires < 3
      ? '#b21f00'
      : subWarnings.daysTillSubscriptionExpires > 3
      ? '#EE9A3E'
      : '';

  return !!subWarnings.subscriptionExpired &&
    !!subWarnings.subscriptionExpirationDate ? (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 270,
        whiteSpace: 'normal',
      }}
    >
      {!!subWarnings.subscriptionExpired &&
        !!subWarnings.subscriptionExpirationDate && (
          <div>
            <div>
              {translate('portalSubscriptions.subscriptionExpired')}
              <Tooltip
                title={translate('portalSubscriptions.clientDowngradeTip')}
              >
                <QuestionCircleOutlined style={{ marginLeft: '25px' }} />
              </Tooltip>
            </div>

            <Countdown
              value={subWarnings.subscriptionExpirationDate.getTime()}
              format="HH:mm:ss"
              valueStyle={{
                backgroundColor: expiringWarningBackgroundColor,
                color: expiringWarningColor,
                textAlign: 'center',
              }}
            />
          </div>
        )}

      {!hideUpgradeButton && !!subWarnings.subscriptionExpired && (
        <Button
          type="link"
          style={{ margin: 10 }}
          onClick={() => replace('/portalSubscriptions')}
        >
          {translate('invites.upgrade')}
        </Button>
      )}
    </div>
  ) : null;
};
