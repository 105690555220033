import { useGetLocale } from '@pankod/refine-core';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
  ],
};

const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const Editor = ({
  quillRef,
  editorState,
  setEditorState,
}: {
  quillRef: React.MutableRefObject<ReactQuill | null>;
  editorState: ReactQuill.Value;
  setEditorState: React.Dispatch<React.SetStateAction<ReactQuill.Value>>;
}) => {
  const { t, i18n } = useTranslation();

  const getLocale = useGetLocale();
  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  // Note: this is an ugly (as most of Quill, though still has fastest initial setup and RN support)
  // and does not work well on multiple switches. But first one works correctly.
  useEffect(() => {
    if (currentLocale?.startsWith('ru')) {
      require('./quill.snow.ru-override.less');
    } else {
      require('react-quill/dist/quill.snow.css');
    }
  }, [currentLocale]);

  return (
    <ReactQuill
      theme="snow"
      value={editorState}
      onChange={setEditorState}
      modules={modules}
      formats={formats}
      ref={quillRef}
    />
  );
};
