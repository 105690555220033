import { assign, createMachine } from 'xstate';

export type WorryTreeContext = typeof initialWorryTreeContext;

const initialWorryTreeContext = {
  selectedActivityIdx: null as number | null,
  isNotSolvable: false,
  worryAbout: '',
  options: '',
  plan: '',
  immediateAction: '',
};

export type WorryTreeEvents =
  | {
      type: 'GO_BACK';
    }
  | {
      type: 'GO_NEXT';
      decisionIdx?: number;
    }
  | {
      type: 'WORRY_ABOUT_CHANGED';
      data: string;
    }
  | {
      type: 'OPTIONS_CHANGED';
      data: string;
    }
  | {
      type: 'IMMEDIATE_ACTION_CHANGED';
      data: string;
    }
  | {
      type: 'PLAN_CHANGED';
      data: string;
    };

export const createWorryTreeMachine = ({
  context = initialWorryTreeContext,
}: {
  context?: Partial<WorryTreeContext>;
}) => {
  const machine = createMachine(
    {
      id: 'worryTreeMachine',
      context: {
        ...initialWorryTreeContext,
        ...context,
      },
      tsTypes: {} as import('./worryTreeMachine.typegen').Typegen0,
      schema: {
        context: {} as WorryTreeContext,
        events: {} as WorryTreeEvents,
        services: {},
      },
      initial: 'worryAbout',
      states: {
        worryAbout: {
          on: {
            GO_NEXT: {
              target: 'isReal',
              cond: 'isWorryAboutFilled',
            },
            WORRY_ABOUT_CHANGED: {
              actions: 'setWorryAbout',
            },
          },
        },
        isReal: {
          on: {
            GO_BACK: {
              target: 'worryAbout',
            },
            GO_NEXT: [
              {
                target: 'isDoable',
                cond: 'isDoable',
              },
              {
                target: 'switchFocus',
              },
            ],
          },
        },
        isDoable: {
          on: {
            GO_BACK: {
              target: 'isReal',
            },
            GO_NEXT: [
              {
                target: 'listOptions',
                cond: 'isListOptions',
              },
              {
                target: 'switchFocus',
                actions: 'setNotSolvable',
              },
            ],
          },
        },
        switchFocus: {
          on: {
            GO_BACK: {
              target: 'isReal',
            },
            GO_NEXT: {
              target: 'done',
              actions: 'setSelectedActivityIdx',
            },
          },
        },
        listOptions: {
          on: {
            GO_BACK: {
              target: 'isDoable',
            },
            GO_NEXT: [
              {
                target: 'isDoableNow',
                cond: 'areOptionsReady',
              },
            ],
            OPTIONS_CHANGED: {
              actions: 'setOptions',
            },
          },
        },
        isDoableNow: {
          on: {
            GO_BACK: {
              target: 'listOptions',
            },
            GO_NEXT: [
              {
                target: 'immediateAction',
                cond: 'isDoableNow',
              },
              {
                target: 'createPlan',
              },
            ],
          },
        },
        createPlan: {
          on: {
            GO_BACK: {
              target: 'isDoableNow',
            },
            GO_NEXT: [
              {
                target: 'switchFocus',
                cond: 'isPlanReady',
              },
            ],
            PLAN_CHANGED: {
              actions: 'setPlan',
            },
          },
        },
        immediateAction: {
          on: {
            GO_BACK: {
              target: 'createPlan',
            },
            GO_NEXT: {
              target: 'done',
              cond: 'isImmediateActionFilled',
            },
            IMMEDIATE_ACTION_CHANGED: {
              actions: 'setImmediateAction',
            },
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        setWorryAbout: assign((ctx, evt) => {
          return {
            worryAbout: evt.data,
          };
        }),
        setSelectedActivityIdx: assign((ctx, evt) => {
          return {
            selectedActivityIdx: evt.decisionIdx,
          };
        }),
        setNotSolvable: assign((ctx, evt) => {
          return {
            isNotSolvable: true,
          };
        }),
        setPlan: assign((ctx, evt) => {
          return {
            plan: evt.data,
          };
        }),
        setOptions: assign((ctx, evt) => {
          return {
            options: evt.data,
          };
        }),
        setImmediateAction: assign((ctx, evt) => {
          return {
            immediateAction: evt.data,
          };
        }),
      },
      services: {},
      guards: {
        isWorryAboutFilled: (ctx) => {
          return ctx.worryAbout.trim().length > 5;
        },
        isDoable: (ctx, evt) => {
          return evt.decisionIdx === 0;
        },
        isListOptions: (ctx, evt) => {
          return evt.decisionIdx === 0;
        },
        isDoableNow: (ctx, evt) => {
          return evt.decisionIdx === 0;
        },
        isPlanReady: (ctx, evt) => {
          return ctx.plan.trim().length > 5;
        },
        areOptionsReady: (ctx, evt) => {
          return ctx.options.trim().length > 5;
        },
        isImmediateActionFilled: (ctx, evt) => {
          return ctx.immediateAction.trim().length > 5;
        },
      },
    },
  );

  return machine;
};
