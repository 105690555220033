import { User, Practice, UserRole } from '@cc/schema' 
import { FirebaseApp } from '@firebase/app';
import { formatISO} from 'date-fns';
import * as fbAuth from 'firebase/auth';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from 'firebase/auth';
import { gqlDataProvider } from '../App';
import { httpClient } from '../authProvider';
import { LOCAL_STORAGE_KEYS } from '../helpers/constants';
import i18n from '../i18n';
import * as Sentry from '@sentry/react';
import { timeout } from '@cc/utils';

// Note: the code is heavily inspired by refine-firebase

export declare interface ILoginArgs {
  provider: 'email' | 'gmail';
  email?: string;
  password?: string;
  remember?: boolean;
  loginAfterRegister?: boolean;
  loginFunc?: () => void;

}

export declare interface ILoginInfo { 
  city: string;
  countryCode: string; // Country 2 letter code
  countryCallingCode: string;
  ip: string;
  region: string; // State
  regionCode: string; // State 2 letter code
  zip: string;
  timezone: string;
};

declare interface ILoginProps {
  setLocation: (location: string) => void;
}

export declare interface IUpdatePasswordArgs {
  actionCode: string;
  password: string
}

declare interface IRegisterProps {
  setReCaptchaContainer: (ref: any) => void;
}

export declare interface IRegisterArgs extends ILoginArgs {}

export type ExtendedUserFrmoDb =   User & {
  practice: Pick<Practice, 'subscription'>};
export declare interface IUser {
  fb: Partial<fbAuth.User> | null;
  db: ExtendedUserFrmoDb | null; 
  loginInfo: ILoginInfo | undefined;
}

declare interface IAuthCallbacks {
  onRegister?: (user: fbAuth.User) => void;
  onLogin?: (user: fbAuth.User) => void;
  onLogout?: (auth: fbAuth.Auth) => any;
}

declare type TLogoutData = void | false | string;
declare interface IAuthContext {
  login: (params: any) => Promise<any>;
  logout: (params: any) => Promise<TLogoutData>;
  checkAuth: (params?: any) => Promise<void>;
  checkError: (error: any) => Promise<void>;
  getPermissions: (params?: any) => Promise<any>;
  getUserIdentity?: () => Promise<any>;
  isProvided?: boolean;
  [key: string]: any;
}


export class FirebaseAuth {
  auth: fbAuth.Auth;
  authActions: IAuthCallbacks;
  loginInfo:   ILoginInfo | undefined;
  loginInfoRequested: boolean;
  authRetries: number;
  cachedDbUser: User | null;


  constructor(authActions?: IAuthCallbacks, firebaseApp?: FirebaseApp) {
    this.auth = fbAuth.getAuth(firebaseApp);
    this.auth.useDeviceLanguage();
    this.authRetries= 0;
    this.cachedDbUser = null;

    this.resetCachedDbUser = this.resetCachedDbUser.bind(this);
    this.getAuthProvider = this.getAuthProvider.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    this.onUpdateUserData = this.onUpdateUserData.bind(this);
    this.getUserIdentity = this.getUserIdentity.bind(this);
    this.handleCheckAuth = this.handleCheckAuth.bind(this);
    this.createRecaptcha = this.createRecaptcha.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.getDbUser = this.getDbUser.bind(this);
    this.loginInfo = undefined;
    this.loginInfoRequested = false;
    this.updateHeaderToken = this.updateHeaderToken.bind(this);

    this.authActions = authActions || {};

    onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        httpClient.setHeader('Authentication', `Bearer ${token}`);
      } else {
        httpClient.setHeader('Authentication', `Bearer `);
      }
    });
  }

  public resetCachedDbUser() {
    this.cachedDbUser = null;
  }

  public async updateHeaderToken() {
    if (this.auth.currentUser) {
      const idTokenResult = await fbAuth.getIdTokenResult(this.auth.currentUser, true);
      httpClient.setHeader('Authentication', `Bearer ${idTokenResult.token}`);
      return idTokenResult?.token;
    }
  } 

  public async handleLogOut() {
    await fbAuth.signOut(this.auth);
    this.resetCachedDbUser();
    localStorage.removeItem(
      LOCAL_STORAGE_KEYS.clientAccountWarningShown,
    );
    localStorage.removeItem(
      LOCAL_STORAGE_KEYS.utmData,
    );
    localStorage.removeItem(LOCAL_STORAGE_KEYS.inviteToken);
    await this.authActions?.onLogout?.(this.auth);
  }

  private async saveUser(user: fbAuth.User) {
        const inviteToken = localStorage?.getItem(
          LOCAL_STORAGE_KEYS.inviteToken,
        );

        const utmData = localStorage?.getItem(
          LOCAL_STORAGE_KEYS.utmData,
        );

        const newUser = {
          createdAt: formatISO(user.metadata.creationTime ? new Date(user.metadata.creationTime) : new Date()),
          displayName: user.displayName,
          email: user.email,
          lng: i18n.languages[0],
          photoUrl: user.photoURL,
          providerId: user.providerId,
          role: UserRole.THERAPIST_ADMIN,
          uid: user.uid,
          updatedAt: formatISO(Date.now()),
          timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
          inviteToken,
          utmData,
        };


        if (inviteToken) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.inviteToken);
        }
        if (utmData) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.utmData);
        }

        const res = await gqlDataProvider.custom<User>?.({
          url: '',
          method: 'post',
          metaData: {
            operation: 'createUser',
            fields: [{ user: ['_id'] }],
            variables: {
              user: {
                name: 'user',
                type: 'UserInput!',
                value: newUser,
              },
            },
          },
        });

      return res;
  };

  private async getDbUser(user: fbAuth.User) {
    // @ts-ignore
      const res = await gqlDataProvider.getOne<User>({
        resource: 'practiceUsers',
        // resource: 'user',
        id: user.uid, // Note: id is not used on BE
        metaData: {
          // @ts-ignore
          fields: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'phone',
            'extension',
            'country',
            'practiceId',
            'displayName',
            'role',
            'accessLevel',
            'diagnoses',
            {
              featureFlags: [
                { cardsSearch: ['name', 'count'] },
                { articlesView: ['name', 'count'] },
                { statsView: ['name', 'count'] },
                { locationReminders: ['name', 'count'] },
                { dayReminders: ['name', 'count'] },
                { phq9: ['name', 'count'] },
                { gad7: ['name', 'count'] },
                { meditations: ['name', 'count'] },
                { worryTrees: ['name', 'count'] },
              ],
            },
            {
              privacySettings: [
                'isPrivacyAccepted',
                'isTermsAccepted',
                'isProductEmailNotifyOn',
                'isProductPushNotifyOn',
                'isErrorInfoCollectionOn',
                'isGAUsageCollectionOn',
                'isKeepLoggedInOn',
              ],
            },
            {
              practice: [
                '_id',
                {
                  subscription: [
                    'stripeCustomerId',
                    'quantity',
                    'currentPeriodStart',
                    'currentPeriodEnd',
                    'cancelAtPeriodEnd',
                    'status',
                    'freeAppsUsed',
                    'downgradeWarningSeenAt',
                    'currentProductId',
                    'currentPriceId',
                    {
                      subscriptions: [
                        'id',
                        'status',
                        'currentPeriodEnd',
                        'currentPeriodStart',
                        'canceledAt',
                        'cancelAtPeriodEnd',
                        'latestInvoiceId',
                        {
                          plan: [
                            'productId',
                            'priceId',
                            'quantity',
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
        },
      });

      return res;
  }

  public async handleRegister(args = {} as IRegisterArgs) {
    try {
      const { provider, email, password, loginFunc} = args;


      if (provider === 'email' && email && password) {
        const userCredential = await fbAuth.createUserWithEmailAndPassword(
          this.auth,
          email,
          password,
        );

        // const actionCodeSettings = {
        //   handleCodeInApp: false,
        //   url: 'https://portal.copingcard.com',
        //   dynamicLinkDomain: 'portal.copingcard.com',
        // };

        // await fbAuth.sendEmailVerification(
        //   userCredential.user,
        //   actionCodeSettings,
        // );

        const { user } = userCredential;

        if (user) {
          await this.saveUser(user);
          this.authActions?.onRegister?.(user);
            this.resetCachedDbUser();
          loginFunc?.()
        }
      } else {
        const provider = new GoogleAuthProvider();

        // TODO: add
        // this.auth.languageCode = lng

        try {
          const res = await signInWithPopup(this.auth, provider);
          const userCredential = GoogleAuthProvider.credentialFromResult(res);
          const user = res.user;
          const userToken = userCredential?.accessToken;
          // The signed-in user info.

          if (user) {
            await this.saveUser(user);
            await this.authActions?.onRegister?.(user);
            this.resetCachedDbUser();
            loginFunc?.()
          }
        } catch (error) {
          return Promise.reject(error);
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async handleLogIn(params = {} as ILoginArgs) {
    const { provider, email, password, remember, loginAfterRegister} = params;

    this.resetCachedDbUser();
    
    try {
      if (loginAfterRegister) {
        return Promise.resolve();
      }
      if (provider === 'email' && email && password) {
        if (this.auth) {
          await this.auth.setPersistence(
            remember
              ? fbAuth.browserLocalPersistence
              : fbAuth.browserSessionPersistence,
          );

          const userCredential = await fbAuth.signInWithEmailAndPassword(
            this.auth,
            email,
            password,
          );
          const userToken = await userCredential?.user?.getIdToken?.();
          if (userToken) {
            await this.authActions?.onLogin?.(userCredential.user);
            await this.getUserIdentity();
          } else {
            return Promise.reject();
          }
        } else {
          return Promise.reject();
        }
      } else {
        if (this.auth) {
          await this.auth.setPersistence(
            remember
              ? fbAuth.browserLocalPersistence
              : fbAuth.browserSessionPersistence,
          );

          const provider = new GoogleAuthProvider();

          try {
            const res = await signInWithPopup(this.auth, provider);
            const userCredential = GoogleAuthProvider.credentialFromResult(res);
            const userToken = userCredential?.accessToken;
            const user = res.user;

            try {
              const dbUser = await this.getDbUser(user);
              if (!dbUser) {
                await this.saveUser(user);
              }
            } catch (e) {
              if (['У вас нет разрешения на получение', 'You do not have permissions'].some(s => (e as any)?.message?.includes(s)) ) {
                await this.saveUser(user);
              }
            }


            if (userToken) {
              await this.authActions?.onLogin?.(user);
              await this.getUserIdentity();
            } else {
              return Promise.reject();
            }
          } catch (error) {
            return Promise.reject(error);
          }
        } else {
          return Promise.reject();
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async handleResetPassword(email: string) {
    try {
      // Note: keeping for reference, thought currently it does not allow customizing the URL
      // fbAuth.sendPasswordResetEmail(this.auth, email);

        const res = await gqlDataProvider.custom<User>?.({
          url: '',
          method: 'post',
          metaData: {
            operation: 'resetPracticeUserPassword',
            fields: ['success'],
            variables: {
              input: {
                name: 'input',
                type: 'PracticeResetUserPasswordInput!',
                value: {
                  data: email,
                },
              },
            },
          },
        });

            this.resetCachedDbUser();
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(e);
    }
    return Promise.resolve(); 
  }

  public async handleUpdatePassword(params = {} as IUpdatePasswordArgs) {

    const {actionCode, password} = params;
    try {
      const email = await fbAuth.verifyPasswordResetCode(this.auth, actionCode);

      if (!email) {
        return Promise.reject()
      }

      await fbAuth.confirmPasswordReset(this.auth, actionCode, password);

            this.resetCachedDbUser();
    } catch (e) {
      Sentry.captureException(e);
      return Promise.reject(e);
    }
    return Promise.resolve();
  }

  public async onUpdateUserData(args: IRegisterArgs) {
    try {
      if (this.auth?.currentUser) {
        const { email, password } = args;
        if (password) {
          await fbAuth.updatePassword(this.auth.currentUser, password);
        }

        if (email && this.auth.currentUser.email !== email) {
          await fbAuth.updateEmail(this.auth.currentUser, email);
        }
      }

            this.resetCachedDbUser();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async getUserIdentity(): Promise<IUser> {
    const user = this.auth?.currentUser;
    
    if (user) {

      let dbUser = this.cachedDbUser;
      if (!dbUser) {
        const res = await this.getDbUser(user);
        dbUser = res.data
        this.cachedDbUser = {...(res.data ||{})};
      }

      if (!this.loginInfo && !this.loginInfoRequested) {
        this.loginInfoRequested = true;



        try {
          // TODO: think of using https://ipregistry.co/ since they have more data
          const res = await fetch('https://ipapi.co/json/');
          const data = await res.json();
          if (data) {
            this.loginInfo = {
              ip: data.ip,
              city: data.city,
              region: data.region,
              regionCode: data.region_code,
              countryCode: data.country_code,
              countryCallingCode: data.country_calling_code,
              zip: data.postal,
              timezone: data.timezone,
            };
          }
          } catch (error) {}
      }


      return {
        fb: {...this.auth.currentUser},
        // @ts-ignore
        db: {...dbUser},
        loginInfo: this.loginInfo,
      }
    }
    return Promise.reject();
  }

  private async handleCheckAuth() {
    if (this.auth?.currentUser) {
      return Promise.resolve();
    } else {
      await timeout(100);
      if (this.auth?.currentUser) {
        return Promise.resolve();
      }
      await timeout(200);
      if (this.auth?.currentUser) {
        return Promise.resolve();
      }
      return Promise.reject('User is not found');
    }
  }

  public async getPermissions(): Promise<fbAuth.ParsedToken> {
    if (this.auth?.currentUser) {
      var idTokenResult = await fbAuth.getIdTokenResult(this.auth.currentUser);
      return idTokenResult?.claims;
    } else {
      return Promise.reject('User is not found in getPermissions');
    }
  }

  public createRecaptcha(
    containerOrId: string | HTMLDivElement,
    parameters?: fbAuth.RecaptchaParameters,
  ) {
    return new fbAuth.RecaptchaVerifier(
      containerOrId,
      parameters || {},
      this.auth,
    );
  }

  public getAuthProvider(): IAuthContext {
    return {
      login: this.handleLogIn,
      register: this.handleRegister,
      forgotPassword: this.handleResetPassword,
      updatePassword: this.handleUpdatePassword,
      logout: this.handleLogOut,
      checkAuth: this.handleCheckAuth,
      checkError: (error) => {
        if (error) {
        }
        if (error?.message?.includes('auth/id-token-expired')) {

          if (this.authRetries < 2) {
            this.authRetries++;

            const refreshToken = async () => {
              try {
              const newToken = await this.auth.currentUser?.getIdToken(true);
              const res = await this.updateHeaderToken();
              this.authRetries = 0;

              return Promise.resolve();
              } catch (e) {
                console.log('token refresh e', e);

              }
            }

            refreshToken();

          } else {

            this.authRetries = 0;

            return Promise.reject('login');

          }
        }
        if (error.status === 401) {
          return Promise.reject('login');
        }
        return Promise.resolve();
      },
      getPermissions: this.getPermissions,
      getUserIdentity: this.getUserIdentity,
    };
  }
}
