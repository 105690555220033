import { Client, User, UserRole } from '@cc/schema';
import { useGetIdentity, useList } from '@pankod/refine-core';
import { Select } from 'antd';
import { useMemo } from 'react';
import { IUser } from '../providers';
import { useSubscriptionWarnings } from './sub-warnings';

export const useClientOptions = () => {
  const { data: user } = useGetIdentity<IUser>();

  const subWarnings = useSubscriptionWarnings();

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<User>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            'diagnoses',
            'preferredPronouns',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });

  const memoizedClienOptions = useMemo(() => {
    // @ts-ignore
    return ((clients?.data?.data as Client[]) || [])
      .filter((c) => {
        return !!c.accessLevel;
      })
      .filter(
        (c) =>
          c.access?.allowEdit ||
          (user?.db?.role === UserRole.THERAPIST_ADMIN &&
            c.access?.allowAdminEdit),
      )
      .map((client) => (
        <Select.Option key={client._id} value={client._id}>
          {client.firstName + ' ' + client.lastName + ' - (' + client._id + ')'}
        </Select.Option>
      ));
    // @ts-ignore
  }, [user?.db?.role, JSON.stringify(clients?.data?.data)]);

  return {
    clientsLoading,
    memoizedClienOptions,
    // @ts-ignore
    breifClientInfo: (clients?.data?.data || []) as Client[],
  };
};
