import { useEffect } from 'react';
import {
  Authenticated,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { LOCAL_STORAGE_KEYS } from '../../helpers/constants';
import { IUser } from '../../providers';
import {
  DeleteInvite,
  Invite,
  TherapistClientEdge,
} from '../../schema/be-graphql-generated';
import { InviteType } from '@cc/schema';

const Inner = () => {
  const { resource, token } = routerProvider.useParams();

  const { mutate } = useCustomMutation<TherapistClientEdge>();
  const { mutate: deleteInviteMutation } = useCustomMutation<DeleteInvite>();

  const { data: identity } = useGetIdentity<IUser>();

  const { replace } = useNavigation();

  if (token) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.inviteToken, token);
  }

  const {
    data: inviteData,
    isLoading: inviteLoading,
    refetch: refetchInvite,
    error: inviteError,
  } = useCustom<Invite>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'inviteByToken',
      fields: [
        '_id',
        'token',
        'type',
        'from',
        'allowEdit',
        'allowView',
        'allowAdminView',
        'allowAdminEdit',
      ],
      variables: {
        token: {
          name: 'token',
          type: 'String',
          value: token,
        },
      },
    },
  });

  const createTherapistClientEdge = async () => {
    if (identity?.db?.practiceId && token) {
      await mutate({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'createTherapistClientEdge',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'therapistClientECreateInput',
              value: {
                data: {
                  from: identity?.db?._id,
                  to: inviteData?.data.from,
                  practiceId: identity?.db?.practiceId,
                  allowEdit: inviteData?.data.allowEdit,
                  allowView: inviteData?.data.allowView,
                  allowAdminView: inviteData?.data.allowAdminView,
                  allowAdminEdit: inviteData?.data.allowAdminEdit,
                },
              },
            },
          },
        },
      });
      await deleteInviteMutation({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'deleteInvite',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'deleteInviteInput',
              value: {
                where: {
                  id: inviteData?.data._id,
                },
              },
            },
          },
        },
      });
      localStorage.removeItem(LOCAL_STORAGE_KEYS.inviteToken);
      replace('/');
    }
  };

  useEffect(() => {
    if (
      token &&
      identity?.db?.practiceId &&
      inviteData?.data?._id &&
      inviteData?.data?.type === InviteType.THERAPIST
    ) {
      createTherapistClientEdge();
    }

    const handle = setTimeout(() => {
      replace('/');
    }, 800);
    return () => clearTimeout(handle);
  }, [identity, token, inviteData]);

  return null;
};

export const InviteJoin = () => {
  return (
    <Authenticated>
      <Inner />
    </Authenticated>
  );
};
