import { getValidators, User } from '@cc/schema';
import { Button, Form, Input, Select, Typography } from '@pankod/refine-antd';
import {
  useCustomMutation,
  useGetIdentity,
  useNotification,
  useTranslate,
} from '@pankod/refine-core';
import { RuleObject } from 'antd/lib/form';
import countries from 'i18n-iso-countries';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'rooks';
import { firebaseAuth } from '../../../authProvider';
import { getYupSyncValidator } from '../../../helpers';
import { useFormSubmitDisabled } from '../../../hooks';
import { IUser } from '../../../providers';
import { StepProps } from '../step0';
import { button, buttonContainer } from '../styles';

const { Paragraph, Title } = Typography;

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export interface IProfessionalForm {
  firstName: string;
  lastName: string;
  phone: string;
  extension?: string;
  country: string;
  clientInviteToken?: string;
}

export const Step1 = ({
  currentStep,
  stepsLenght,
  setCurrentStep,
  setIsNextDisabled,
}: StepProps) => {
  const translate = useTranslate();

  const { i18n } = useTranslation();

  const { data: identity, remove, refetch } = useGetIdentity<IUser>();

  const { innerWidth } = useWindowSize();

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const { practiceUserPersonalInfoValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  // Note: as RuleObject is silenciing Antd since it seems to have wrong types
  const validationRules = getYupSyncValidator(
    translate,
    practiceUserPersonalInfoValidator,
  ) as RuleObject;

  const [form] = Form.useForm<IProfessionalForm>();

  const countriesArr = useMemo(() => {
    const countriesArr = Object.entries(countries.getNames(i18n.languages[0]))
      .map(([key, value]) => ({
        name: value,
        value: key,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const res = countriesArr?.length
      ? countriesArr
      : (translate('countriesFallback', {
          returnObjects: true,
        }) as unknown as typeof countriesArr);
    return res;
  }, [i18n.languages[0]]);

  useEffect(() => {
    if (identity?.loginInfo) {
      if (!form.getFieldValue('country')) {
        form.setFieldsValue({ country: identity?.loginInfo?.countryCode });
      }
      if (!form.getFieldValue('phone')) {
        form.setFieldsValue({ phone: identity?.loginInfo?.countryCallingCode });
      }
    }
  }, [JSON.stringify(identity?.loginInfo)]);

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabled(
      form,
      ['firstName', 'lastName', 'phone', 'country'],
      true,
    );

  useEffect(() => {
    setIsNextDisabled(isSubmitDisabled);
  }, [isSubmitDisabled]);

  const { mutate } = useCustomMutation<{
    new: User | null;
    tokenError: string | null;
  }>();
  const { open } = useNotification();

  const updatePracticeUserInfo = async () => {
    const value = form.getFieldsValue();

    await mutate(
      {
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'practiceUserInfoUpdate',
          fields: [{ new: ['_id'] }, 'tokenError'],
          variables: {
            privacySettings: {
              name: 'input',
              type: 'PracticeUserInfoUpdateInput!',
              value,
            },
          },
        },
      },
      {
        onSuccess: (data, variables, context) => {
          if (data?.data?.tokenError) {
            open?.({
              type: 'error',
              message: '',
              description: data.data.tokenError,
            });
          }
        },
      },
    );

    await remove();
    firebaseAuth.resetCachedDbUser();
    await refetch();
  };

  return (
    <div>
      <Title level={4} style={{ textAlign }}>
        {translate('pages.initialSetup.step1.title')}
      </Title>
      <Paragraph>{translate('pages.initialSetup.step1.description')}</Paragraph>
      <Form<IProfessionalForm>
        form={form}
        onFinish={(values) => {
          console.log(values);
        }}
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        requiredMark
        onFieldsChange={handleFormChange}
        initialValues={{
          firstName: identity?.db?.firstName || '',
          lastName: identity?.db?.lastName || '',
          phone: identity?.db?.phone || '',
          extension: identity?.db?.extension || '',
          country: identity?.db?.country || '',
          clientInviteToken: '',
        }}
      >
        <Form.Item
          name="firstName"
          label={translate('pages.initialSetup.step1.firstName')}
          required
          rules={[validationRules]}
        >
          <Input
            type="text"
            size="large"
            placeholder={translate('pages.initialSetup.step1.firstName')}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={translate('pages.initialSetup.step1.lastName')}
          required
          rules={[validationRules]}
        >
          <Input
            type="text"
            size="large"
            placeholder={translate('pages.initialSetup.step1.lastName')}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={translate('pages.initialSetup.step1.phone')}
          required
          rules={[validationRules]}
        >
          <Input
            type="tel"
            size="large"
            placeholder={translate('pages.initialSetup.step1.phone')}
          />
        </Form.Item>
        <Form.Item
          name="extension"
          label={translate('pages.initialSetup.step1.extension')}
          rules={[validationRules]}
        >
          <Input
            size="large"
            placeholder={translate('pages.initialSetup.step1.extension')}
          />
        </Form.Item>
        <Form.Item
          name="country"
          label={translate('pages.initialSetup.step1.country')}
          required
          rules={[validationRules]}
        >
          <Select
            showSearch
            size="large"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option!.children as unknown as string) || '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            filterSort={(optionA, optionB) => {
              return (optionA!.children as unknown as string)
                .toLowerCase()
                .localeCompare(
                  (optionB!.children as unknown as string).toLowerCase(),
                );
            }}
          >
            {countriesArr.map((c) => (
              <Select.Option key={c.name} value={c.value}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* 
        It looks like this is not needed anymore
        <Form.Item
          name="clientInviteToken"
          label={translate('pages.initialSetup.step1.clientInviteToken')}
          rules={[validationRules]}
        >
          <Input
            type="text"
            size="large"
            placeholder={translate(
              'pages.initialSetup.step1.clientInviteTokenPlaceholder',
            )}
          />
        </Form.Item> */}
      </Form>

      <div style={buttonContainer}>
        {currentStep !== 0 && (
          <Button
            onClick={() => setCurrentStep(currentStep - 1)}
            style={button}
          >
            {translate('pages.initialSetup.previousStep')}
          </Button>
        )}
        <Button
          onClick={async () => {
            await updatePracticeUserInfo();
            setTimeout(() => setCurrentStep(currentStep + 1), 700);
          }}
          style={button}
          disabled={isSubmitDisabled}
          type="primary"
        >
          {currentStep === stepsLenght - 1
            ? translate('pages.initialSetup.finish')
            : translate('pages.initialSetup.nextStep')}
        </Button>
      </div>
    </div>
  );
};
