import {
  Button,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Input,
  List,
  Modal,
  SaveButton,
  Select,
  ShowButton,
  Skeleton,
  Space,
  Table,
  Tooltip,
  useModal,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
  useNotification,
  useRouterContext,
  useTranslate,
} from '@pankod/refine-core';

import {
  ApartmentOutlined,
  ContainerOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  UsergroupDeleteOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { ACCESS_LEVELS, Client, User, UserRole } from '@cc/schema';
import { timeout } from '@cc/utils';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { firebaseAuth } from '../../authProvider';
import { ClientsLimitReached } from '../../components/ClientsLimitReached';
import { SubscriptionExpired } from '../../components/SubscriptionExpired';
import { SubscriptionExpiringSoon } from '../../components/SubscriptionExpiringSoon';
import { useCcEditableTable } from '../../helpers';
import { useSubscriptionWarnings } from '../../hooks';
import { IUser } from '../../providers';
import { colors } from '../../styles/colors';
import { useClientOptions } from '../../hooks/client-options';

const data = [
  '_id',
  'firstName',
  'lastName',
  'email',
  'accessLevel',
  'createdAt',
  'role',
  'diagnoses',
  {
    access: ['allowView', 'allowEdit', 'allowAdminView', 'allowAdminEdit'],
  },
  {
    activeSubscriptions: [
      {
        data: [
          '_id',
          'startsAt',
          'expiresAt',
          'type',
          'isActive',
          'practiceId',
        ],
      },
    ],
  },
];
const metaData = {
  fields: [
    {
      data,
    },

    'count',
  ],
};

export const ClientList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { Link } = useRouterContext();
  const { push } = useNavigation();
  const [obfuscate, setObfuscate] = useState(true);
  const [isPremiumByPracticeSet, setIsPremiumByPracticeSet] = useState(false);

  const [selectedMergeClientId, setSelectedMergeClientId] = useState<string>();

  const { modalProps, show, close } = useModal();
  const {
    modalProps: modalPropsExpired,
    show: showExpired,
    close: closeExpired,
  } = useModal();
  const {
    modalProps: modalPropsLimitReached,
    show: showLimitReached,
    close: closeLimitReached,
  } = useModal();

  const [nonAppClientIdToMerge, setNonAppClientIdToMerge] = useState<string>();
  const {
    modalProps: modalPropsMergeAccounts,
    show: showMergeAccounts,
    close: closeMergeAccounts,
  } = useModal();

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const { replace } = useNavigation();

  const subWarnings = useSubscriptionWarnings();

  const { open } = useNotification();

  useEffect(() => {
    if (!obfuscate) {
      const handle = setTimeout(() => {
        setObfuscate(true);
      }, 1000 * 60);
      return () => clearTimeout(handle);
    }
  }, [obfuscate]);

  const showUpgradeSubscriptionError = () => {
    open?.({
      type: 'error',
      message: '',
      description: translate('clients.upgradeWarning'),
    });
  };

  const nonAppClientInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const [nonAppClientForm] = Form.useForm<typeof nonAppClientInitialValues>();

  const {
    table: { tableProps, tableQueryResult },
    form: { formProps },
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useCcEditableTable<Client>(
    {
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      hasPagination: true,
      initialPageSize: subWarnings.limitReached
        ? subWarnings.clientsPurchased * 2
        : 10, // default
      defaultSetFilterBehavior: 'replace',
      initialSorter: [
        {
          field: '_id',
          order: 'desc',
        },
      ],
      metaData,
    },
    {
      metaData: {
        fields: data,
      },
    },
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { mutate: createClient } = useCustomMutation<User>();
  const { mutate: mergeNonAppClient } = useCustomMutation<User>();

  const handleOk = async () => {
    setConfirmLoading(true);
    const values = nonAppClientForm.getFieldsValue();
    await createClient({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'createNonAppClient',
        fields: ['_id'],
        variables: {
          input: {
            name: 'input',
            type: 'CreateNonAppClientInput!',
            value: {
              ...values,
              email: values?.email?.toLowerCase() || null,
            },
          },
        },
      },
    });

    await timeout(500);
    nonAppClientForm.resetFields();

    await tableQueryResult.refetch();

    firebaseAuth.resetCachedDbUser();
    setTimeout(() => refetchIdentity(), 500);

    setIsModalOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (
      // @ts-ignore
      subWarnings.clientsPurchased !== tableProps?.pagination?.pageSize
    ) {
      // @ts-ignore
      tableProps?.onChange?.(
        { current: 1, pageSize: subWarnings.clientsPurchased * 4 },
        // @ts-ignore
        null,
        null,
        null,
      );
    }
  }, [subWarnings.clientsPurchased]);

  return (
    <List
      title={translate('clients.clients')}
      pageHeaderProps={{
        extra: (
          <Space wrap>
            <div style={{ marginRight: 30 }}>
              <Tooltip title={translate('clients.piiTip')}>
                <QuestionCircleOutlined style={{ marginRight: 5 }} />
              </Tooltip>
              <Button
                icon={obfuscate ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={() => setObfuscate(!obfuscate)}
              >
                {translate(obfuscate ? 'clients.showInfo' : 'clients.hideInfo')}
              </Button>
            </div>
            <div>
              <Tooltip title={translate('clients.nonAppClientDescription')}>
                <QuestionCircleOutlined style={{ marginRight: 5 }} />
              </Tooltip>
              <CreateButton
                disabled={
                  subWarnings?.limitReached || subWarnings?.subscriptionExpired
                }
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {translate('clients.addNonAppClient')}
              </CreateButton>
            </div>
            <CreateButton
              disabled={
                subWarnings?.limitReached || subWarnings?.subscriptionExpired
              }
              onClick={() => {
                push('/invites/create?type=1');
              }}
            >
              {translate('clients.inviteAppClient')}
            </CreateButton>
            <ClientsLimitReached />
            <SubscriptionExpiringSoon />
            <SubscriptionExpired />

            <Modal
              title={
                <>
                  <>{translate('clients.addNonAppClient')}</>
                  <Tooltip title={translate('clients.nonAppClientDescription')}>
                    <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                  </Tooltip>
                </>
              }
              open={isModalOpen}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <Form<typeof nonAppClientInitialValues>
                form={nonAppClientForm}
                initialValues={{
                  ...nonAppClientInitialValues,
                }}
                onFinish={(values) => {}}
                // onValuesChange={onValuesChange}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 16 }}
                labelWrap
              >
                <Form.Item
                  label={translate('invites.fields.firstName')}
                  name="firstName"
                  required
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={translate('invites.fields.lastName')}
                  name="lastName"
                  required
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={translate('invites.fields.email')}
                  name="email"
                >
                  <Input type="email" />
                </Form.Item>
              </Form>
            </Modal>
          </Space>
        ),
      }}
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="_id"
          onChange={(pagination, filters, sorter, extra) => {
            if (subWarnings.limitReached) {
              showUpgradeSubscriptionError();
              return;
            }
            // Note: The data we get from Ant.design filters does not match the
            // react refine one. Currently it works fine if I simply silence it the way below.
            // TOOD: Find a way to convert the data to match the react refine one and fix it better
            // @ts-ignore
            tableProps?.onChange?.(pagination, null, sorter, extra);
          }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            key="_id"
            dataIndex="_id"
            title="ID"
            sorter
            filterSearch
            // @ts-ignore
            onFilter={(value: string, record: User) => {
              return record._id === value;
            }}
            filters={
              // @ts-ignore
              tableProps.dataSource?.map((c, idx) => {
                return {
                  text: `${c._id} - ${c.firstName} ${c.lastName}`,
                  value: c._id,
                };
              })
            }
            render={(value, record) => {
              const client = tableProps.dataSource?.find?.(
                (c) => c._id === value,
              );

              return (
                <Tooltip
                  title={client ? `${client.firstName} ${client.lastName}` : ''}
                >
                  {`${value.replace('users/', '')} - ${(
                    client?.firstName || ''
                  ).charAt(0)}${(client?.lastName || '').charAt(0)}`}
                </Tooltip>
              );
            }}
          />
          <Table.Column<Client>
            key="firstName"
            dataIndex="firstName"
            title={translate('clients.fields.firstName')}
            sorter
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item name={['firstName']}>
                    <Input />
                  </Form.Item>
                );
              }
              return obfuscate ? (
                <Skeleton active paragraph={{ rows: 0 }} />
              ) : (
                value
              );
            }}
          />
          <Table.Column<Client>
            key="lastName"
            dataIndex="lastName"
            title={translate('clients.fields.lastName')}
            sorter
            render={(value, record) => {
              if (isEditing(record._id)) {
                return (
                  <Form.Item name={['lastName']}>
                    <Input />
                  </Form.Item>
                );
              }
              return obfuscate ? (
                <Skeleton active paragraph={{ rows: 0 }} />
              ) : (
                value
              );
            }}
          />
          <Table.Column<Client>
            key="email"
            dataIndex="email"
            title={translate('clients.fields.email')}
            sorter
            filterSearch
            // @ts-ignore
            onFilter={(value: string, record: User) => {
              return record.email === value;
            }}
            filters={
              // @ts-ignore
              tableProps.dataSource?.map((c, idx) => {
                return {
                  text: c.email,
                  value: c.email,
                };
              })
            }
            render={(value: string, record) => {
              const normalizedValue =
                record?.role === UserRole.NON_MOBILE
                  ? value?.startsWith('cc-') &&
                    value?.endsWith('@copingcard.com')
                    ? ''
                    : value?.replace('cc-', '')
                  : value;

              return obfuscate ? (
                <Skeleton active paragraph={{ rows: 0 }} />
              ) : (
                normalizedValue
              );
            }}
          />
          <Table.Column<Client>
            key="accessLevel"
            dataIndex="accessLevel"
            title={
              <>
                {translate('clients.fields.accessLevel')} {''}{' '}
                <Tooltip
                  title={
                    <>
                      {translate('clients.freeTip')}
                      <br />
                      {translate('clients.premiumTip')}
                      <br />
                      {translate('clients.premiumPracticeTip')}
                      <br />
                      {translate('clients.nonAppClientTip')}
                    </>
                  }
                >
                  <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>{' '}
              </>
            }
            render={(value, record) => {
              if (
                record.role !== UserRole.NON_MOBILE &&
                isEditing(record._id)
              ) {
                const options =
                  value === ACCESS_LEVELS.premium ||
                  (value === ACCESS_LEVELS.premium_practice &&
                    record.activeSubscriptions?.data?.find(
                      (s) => s.practiceId !== identity?.db?.practiceId,
                    ))
                    ? [
                        {
                          label: translate(`clients.premium_practice`),
                          value: ACCESS_LEVELS.premium_practice,
                        },
                        {
                          label: translate(`clients.premium`),
                          value: ACCESS_LEVELS.premium,
                        },
                      ]
                    : [
                        {
                          label: translate(`clients.premium_practice`),
                          value: ACCESS_LEVELS.premium_practice,
                        },
                        {
                          label: translate(`clients.free`),
                          value: null,
                        },
                      ];

                return (
                  <Form.Item
                    name={['accessLevel']}
                    getValueProps={(value) => {
                      const valueToRender =
                        !isPremiumByPracticeSet &&
                        value === ACCESS_LEVELS.premium_practice &&
                        !record.activeSubscriptions?.data?.find(
                          (s) => s.practiceId === identity?.db?.practiceId,
                        ) &&
                        record.activeSubscriptions?.data?.find(
                          (s) => s.practiceId !== identity?.db?.practiceId,
                        )
                          ? ACCESS_LEVELS.premium
                          : value;
                      return {
                        value: valueToRender,
                      };
                    }}
                  >
                    <Select
                      // @ts-ignore
                      options={options}
                      onChange={(value) => {
                        if (value === ACCESS_LEVELS.premium_practice) {
                          if (subWarnings.limitReached) {
                            showLimitReached();
                            return;
                          }
                          if (subWarnings.subscriptionExpired) {
                            showExpired();
                            return;
                          }
                          setIsPremiumByPracticeSet(true);
                        } else {
                          setIsPremiumByPracticeSet(false);
                        }
                        if (value === null) {
                          show();
                        }
                      }}
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                );
              }

              const valueToRender =
                !isPremiumByPracticeSet &&
                value === ACCESS_LEVELS.premium_practice &&
                !record.activeSubscriptions?.data?.find(
                  (s) => s.practiceId === identity?.db?.practiceId,
                ) &&
                record.activeSubscriptions?.data?.find(
                  (s) => s.practiceId !== identity?.db?.practiceId,
                )
                  ? ACCESS_LEVELS.premium
                  : value;

              return translate(
                record.role === UserRole.NON_MOBILE
                  ? 'clients.premiumNonApp'
                  : valueToRender === ACCESS_LEVELS.premium_practice
                  ? `clients.premium_practice`
                  : valueToRender === ACCESS_LEVELS.premium
                  ? `clients.premium`
                  : 'clients.free',
              );
            }}
          />
          <Table.Column
            key="access"
            dataIndex="access"
            title={translate('clients.fields.access')}
            render={(value, record) => {
              if (!value) {
                return <EyeInvisibleOutlined />;
              }
              return (
                <div>
                  {value.allowView && <EyeOutlined />}
                  {value.allowEdit && (
                    <EditOutlined style={{ marginLeft: '5px' }} />
                  )}
                </div>
              );
            }}
          />
          <Table.Column
            key="createdAt"
            dataIndex="createdAt"
            title={translate('clients.fields.createdAt')}
            sorter
            render={(text, record, indx) => {
              if (!text) return undefined;
              const date = new Date(text);
              return format(date, 'yyyy-MM-dd');
            }}
          />
          <Table.Column<Client>
            title={translate('resourcesCommon.actions')}
            dataIndex="actions"
            render={(_, record) => {
              if (isEditing(record._id)) {
                return (
                  <Space wrap>
                    <SaveButton
                      {...saveButtonProps}
                      onClick={async () => {
                        setIsPremiumByPracticeSet(false);
                        await saveButtonProps.onClick();
                        firebaseAuth.resetCachedDbUser();
                        setTimeout(() => refetchIdentity(), 500);
                      }}
                      size="small"
                    />
                    <Button {...cancelButtonProps} size="small">
                      {translate('buttons.cancel')}
                    </Button>
                  </Space>
                );
              }
              const canEdit =
                record?.access?.allowEdit ||
                (record?.access?.allowAdminEdit &&
                  identity?.db?.role === UserRole.THERAPIST_ADMIN);

              const canDelete = record.role === UserRole.NON_MOBILE;

              return (
                <Space wrap>
                  {canEdit && (
                    <Tooltip
                      title={translate(
                        record?.accessLevel
                          ? 'clients.thoughtsTest'
                          : 'clients.thoughtsTestDisabledTip',
                      )}
                    >
                      <Link
                        to={`/tools/portalThoughts/create?clientId=${record._id}`}
                      >
                        <Button
                          icon={<ContainerOutlined />}
                          disabled={!record?.accessLevel}
                        />
                      </Link>
                    </Tooltip>
                  )}

                  <Tooltip title={translate('resourcesCommon.edit')}>
                    <EditButton
                      {...editButtonProps(record._id)}
                      hideText
                      size="small"
                    />
                  </Tooltip>

                  <Tooltip title={translate('resourcesCommon.view')}>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record._id}
                      disabled={
                        !(
                          record?.access?.allowView ||
                          (identity?.db?.role === UserRole.THERAPIST_ADMIN &&
                            record?.access?.allowAdminView)
                        )
                      }
                    />
                  </Tooltip>

                  {canEdit && (
                    <Tooltip
                      title={translate(
                        record?.accessLevel
                          ? 'pages.conceptualization.title'
                          : 'clients.thoughtsTestDisabledTip',
                      )}
                    >
                      <Link
                        to={`/tools/conceptualizations/create?clientId=${record._id}`}
                      >
                        <Button
                          icon={<ApartmentOutlined />}
                          disabled={!record?.accessLevel}
                        />
                      </Link>
                    </Tooltip>
                  )}
                  {canDelete && (
                    <Tooltip title={translate('clients.mergeTip')}>
                      <Button
                        icon={<UsergroupDeleteOutlined />}
                        onClick={() => {
                          setNonAppClientIdToMerge(record._id);
                          showMergeAccounts();
                        }}
                      />
                    </Tooltip>
                  )}
                  {canDelete && (
                    <Tooltip title={translate('clients.deleteTip')}>
                      <DeleteButton
                        metaData={{
                          fields: ['_id'],
                        }}
                        onSuccess={() => {
                          firebaseAuth.resetCachedDbUser();
                          setTimeout(() => refetchIdentity(), 500);
                        }}
                        hideText
                        size="small"
                        recordItemId={record._id}
                        confirmTitle={translate('clients.deleteConfirmText')}
                        confirmOkText={translate('clients.deleteOkText')}
                        confirmCancelText={translate(
                          'clients.deleteCancelText',
                        )}
                      />
                    </Tooltip>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Form>

      <Modal
        {...modalProps}
        maskClosable={false}
        title={translate('clients.freeDowngradeWarning')}
        onOk={() => {
          close();
        }}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      />

      <Modal
        {...modalPropsExpired}
        maskClosable={false}
        title={translate('clients.expiredWarning')}
        onOk={() => {
          closeExpired();
        }}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      />

      <Modal
        {...modalPropsLimitReached}
        maskClosable={false}
        title={translate('clients.limitReachedWarning')}
        onOk={() => {
          closeLimitReached();
        }}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      />
      <Modal
        {...modalPropsMergeAccounts}
        maskClosable={false}
        title={translate('clients.mergeTip')}
        onOk={async () => {
          const id = selectedMergeClientId;

          await mergeNonAppClient({
            url: '',
            method: 'post',
            values: {},
            metaData: {
              operation: 'mergeClients',
              fields: ['_id'],
              variables: {
                input: {
                  name: 'input',
                  type: 'MergeClientsInput!',
                  value: {
                    nonAppClientId: nonAppClientIdToMerge,
                    appClientId: id,
                  },
                },
              },
            },
          });

          await timeout(500);

          closeMergeAccounts();
          setSelectedMergeClientId(undefined);
          setNonAppClientIdToMerge(undefined);

          await tableQueryResult.refetch();

          firebaseAuth.resetCachedDbUser();
          setTimeout(() => refetchIdentity(), 500);
        }}
        onCancel={() => {
          setSelectedMergeClientId(undefined);
          setNonAppClientIdToMerge(undefined);
          closeMergeAccounts();
        }}
      >
        <div>
          <div>
            <WarningOutlined
              color="yellow"
              style={{
                fontSize: 20,
                marginRight: 5,
                color: colors['--primary-0'],
              }}
            />

            {translate('clients.mergeWarning')}
          </div>

          <br />
          <div>{translate('clients.mergeTipDetails')}</div>
          <br />
          <div
            style={{
              fontWeight: 'bold',
              marginBottom: 5,
            }}
          >
            {translate('clients.mergeLabel')}:
          </div>
          <Select
            style={{
              minWidth: 300,
            }}
            showSearch
            value={selectedMergeClientId}
            placeholder={translate('clients.selectMergeAppClient')}
            optionFilterProp="children"
            loading={clientsLoading}
            onChange={setSelectedMergeClientId}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {memoizedClienOptions}
          </Select>
        </div>
      </Modal>
    </List>
  );
};
