import {
  Document,
  DocumentMetadata,
  Edge,
  EdgeMetadata,
  ObjectWithId,
  ObjectWithKey,
} from 'arangojs/documents';
import { number, string } from 'yup';
import {
  ACCESS_LEVELS,
  ActivatingEventValidator,
  DayRemindersValidator,
  LocationValidator,
  PrivacySettings,
  ThoughtValidator,
} from '.';
import { featureFlags } from './features';
import {
  DateTimeReminderValidator,
  PracticeLocationValidator,
  PracticeValidator,
  ProfileValidator,
  TestValidator,
} from './validators';

export const dbCols = {
  activatingEvents: 'activatingEvents',
  abcModels: 'abcModels',
  behaviorExperiments: 'behaviorExperiments',
  conceptualizations: 'conceptualizations',
  dateTimeReminders: 'dateTimeReminders',
  dayReminders: 'dayReminders',
  downwardArrows: 'downwardArrows',
  exposures: 'exposures',
  exposureStimuli: 'exposureStimuli',
  featureFlags: 'featureFlags',
  invites: 'invites',
  locations: 'locations',
  locationsRelations: 'locationsRelations',
  messages: 'messages',
  migration_history: 'migration_history',
  practices: 'practices',
  practicesLocations: 'practicesLocations',
  practiceUsageEventsRelations: 'practiceUsageEventsRelations',
  progressNotes: 'progressNotes',
  sessionsRelations: 'sessionsRelations',
  subscriptions: 'subscriptions',
  supportingCycles: 'supportingCycles',
  tests: 'tests',
  therapistClientRelations: 'therapistClientRelations',
  therapistProfiles: 'therapistProfiles',
  thoughts: 'thoughts',
  usageEventsRelations: 'usageEventsRelations',
  users: 'users',
  worryTrees: 'worryTrees',
} as const;

export type ArangoObjectSelector = ObjectWithId | ObjectWithKey;

export type Created = {
  createdAt: string;
};
export type CreatedUpdated = Created & {
  updatedAt: string;
};
export type UserId = {
  userId: string;
};

export enum UserRole {
  FREE,
  PAID,
  THERAPIST,
  THERAPIST_ADMIN,
  ADMIN,
  NON_MOBILE,
}

export type DataWithCursor<T extends {}> = {
  data: Document<T>[];
  cursor?: number;
};

export type RelayPagination<T extends {}> = {
  totalCount: number;
  edges: {
    cursor: string;
    node: Document<T>;
  }[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

export type RelayPaginationEdge<T extends {}> = {
  totalCount: number;
  edges: {
    cursor: string;
    node: Edge<T>;
  }[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

// TODO: move to validators or only GraphQL
export type User = DocumentMetadata &
  CreatedUpdated & {
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    displayName?: string | null;
    timezone: string;
    role: UserRole;
    lng: string;
    providerId: string;
    photoUrl?: string | null;
    uid: string;
    fcmToken?: string | null;
    accessLevel?: keyof typeof ACCESS_LEVELS | null;
    adaptyProfileId?: string | null;
    hasAdaptyPremiumAccess?: boolean | null;
    featureFlags?: typeof featureFlags;
    privacySettings?: PrivacySettings | null;
    phone?: string | null;
    extension?: string | null;
    country?: string | null;
    state?: string | null;
    practiceId?: string | null;
    subscriptionExpiredAt?: string | null;
    stripeCustomerId?: string | null;
    practice?: Practice | null;
    diagnoses?: Array<string | null> | null;
    gender?: number | null;
    preferredPronouns?: number | null;
  };

export type TherapistDataAccess = {
  allowView?: boolean;
  allowEdit?: boolean;
  allowAdminView?: boolean;
  allowAdminEdit?: boolean;
};
export type Client = User & {
  mostUsedStats?: {
    entityId: string;
    count: number;
  };
  tests?: Test[];
  access?: TherapistDataAccess;
  thoughts?: Thought[];
  enabledDayRemindersCount?: number;
  enabledLocationRemindersCount?: number;
  cardsViewedStats?: UsageEventStats;
  crisisStats?: UsageEventStats;
  dayRemindersTriggeredStats?: UsageEventStats;
  dayRemindersReactedStats?: UsageEventStats;
  locationRemindersTriggeredStats?: UsageEventStats;
  locationRemindersReactedStats?: UsageEventStats;
  meditationsStats?: UsageEventStats;
  activeSubscriptions?: {
    data: Subscription[];
    count: number;
  };
};

export type ActivatingEvent = DocumentMetadata &
  UserId &
  CreatedUpdated &
  ActivatingEventValidator;

export type DayReminders = DocumentMetadata &
  CreatedUpdated &
  DayRemindersValidator;

export type Thought = DocumentMetadata &
  UserId &
  CreatedUpdated &
  ThoughtValidator;

export type Location = DocumentMetadata &
  CreatedUpdated &
  LocationValidator & { userId: string };

export enum LocationRelations {
  HappendAt,
  Remind,
}

export type LocationsRelations = DocumentMetadata &
  EdgeMetadata &
  CreatedUpdated & {
    type: LocationRelations;
    enter?: boolean;
    leave?: boolean;
  };

export type LocationReminder = DocumentMetadata &
  EdgeMetadata &
  CreatedUpdated & {
    type: LocationRelations.Remind;
    enter?: boolean;
    leave?: boolean;
  };

export enum UsageEventTypes {
  Created,
  Triggered,
  Reacted,
  CardFlipped,
  Used,
  Updated,
  Loaded,
  Viewed,
  Messaged,
  Phoned,
  OutsideLinkClicked,
  Clicked,
  LoggedIn,
}

export enum StatsInterval {
  Week,
  Month,
  ThreeMonths,
  SixMonths,
  Year,
  AllTime,
}

export type UsageEventStats = {
  stats: { date: string; count: number }[];
};

export type UsageEventsRelation = DocumentMetadata &
  EdgeMetadata &
  Created & {
    type: UsageEventTypes;
  };

export type DateTimeReminder = DocumentMetadata &
  CreatedUpdated &
  DateTimeReminderValidator;

export enum TestType {
  phq9,
  gad7,
}
export type Test = DocumentMetadata &
  Created &
  TestValidator & { type: TestType };

export enum InviteType {
  PERMANENT_LINK_CLIENT,
  CLIENT,
  THERAPIST,
  PRACTICE_USER,
  PRACTICE_ADMIN,
}

export type Practice = DocumentMetadata &
  CreatedUpdated &
  PracticeValidator & {
    saasSignedUser: string;
    practiceLocations?: {
      data: PracticeLocation[];
      count: number;
    };
    subscription?: {
      stripeCustomerId?: string;
      subscriptions?: {
        id: string;
        status: string;
        currentPeriodEnd: string;
        currentPeriodStart: string;
        canceledAt: string | null;
        cancelAtPeriodEnd: boolean;
        latestInvoiceId?: string | null;
        plan: {
          productId: string;
          priceId: string;
          quantity: number;
        };
      }[];
      quantity?: number;
      currentPeriodStart?: string;
      currentPeriodEnd?: string;
      cancelAtPeriodEnd?: boolean;
      status?: string;
      freeAppsUsed?: number;
      downgradeWarningSeenAt?: string | null;
      currentProductId?: string | null;
      currentPriceId?: string | null;
    } | null;
    trialDays?: number | null;
    trialEndDate?: string | null;
    trialStartedAt?: string | null;
  };

export type PracticeLocation = DocumentMetadata &
  CreatedUpdated &
  PracticeLocationValidator & {
    practiceId: string;
  };

export type Invite = DocumentMetadata & {
  token: string;
  createdAt: string;
  expiresAt: string;
  from: string;
  to?: string | null;
  type: InviteType;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  message?: string | null;
  allowEdit?: boolean | null;
  allowView?: boolean | null;
  allowAdminView?: boolean | null;
  allowAdminEdit?: boolean | null;
};

export enum SubscriptionType {
  ADAPTY,
  PROVIDER,
  MANUAL,
}

export type Subscription = DocumentMetadata &
  CreatedUpdated & {
    userId: string;
    type: SubscriptionType;
    practiceId?: string | null;
    lastSyncrhonizedAt?: string | null;
    name?: string | null;
    startsAt?: string | null;
    expiresAt?: string | null;
    activatedAt?: string | null;
    isActive?: boolean | null;
    isLifetime?: boolean | null;
    renewedAt?: string | null;
    unsubscribedAt?: string | null;
    vendorProductId?: string | null;
    willRenew?: boolean | null;
    clientsCount?: number | null;
    price?: number | null;
  };

type ProfileWithExtra = ProfileValidator &
  DocumentMetadata &
  CreatedUpdated &
  UserId & {
    practiceCity: string;
    practiceState: string;
    practiceCountry: string;
    practiceZip: string;

    lastLoadedAt?: string | null;
  };

export type Profile = Omit<ProfileWithExtra, 'firstName' | 'lastName'>;

export const STRIPE_PRODUCT_META_NAMES = {
  client_mobile_apps: 'client_mobile_apps',
  phone_support: 'phone_support',
  priority_support: 'priority_support',
};

export type Message = DocumentMetadata &
  Created & {
    from: string;
    to: string;
    message: string;
    readAt?: string | null;
    fromUser?: User | null;
  };
