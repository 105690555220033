export const translationRu = {
  aiProgressNotes: {
    activities: {
      0: 'посмотрели',
      1: 'создали',
      2: 'изменили',
      3: 'удалили',
    },
    alternativeDetails: {
      0: 'Мы рассмотрели ранее задокументированный {{technique}}',
      '0_f': 'Мы рассмотрели ранее задокументированную {{technique}}',
      '0_n': 'Мы рассмотрели ранее задокументированное {{technique}}',
      1: 'Мы выявили и задокументировали новый {{technique}}',
      '1_f': 'Мы выявили и задокументировали новую {{technique}}',
      '1_n': 'Мы выявили и задокументировали новое {{technique}}',
      2: 'Мы изменили ранее задокументированный {{technique}}',
      '2_f': 'Мы изменили ранее задокументированную {{technique}}',
      '2_n': 'Мы изменили ранее задокументированное {{technique}}',
      3: 'Мы удалили ранее задокументированный {{technique}}',
      '3_f': 'Мы удалили ранее задокументированную {{technique}}',
      '3_n': 'Мы удалили ранее задокументированное {{technique}}',
    },
    activitiesDetails: {
      0: 'Мы посмотрели существующий {{technique}}',
      '0_f': 'Мы посмотрели существующую {{technique}}',
      '0_n': 'Мы посмотрели существующее {{technique}}',
      1: 'Мы создали новый {{technique}}',
      '1_f': 'Мы создали новую {{technique}}',
      '1_n': 'Мы создали новое {{technique}}',
      2: 'Мы изменили существующий {{technique}}',
      '2_f': 'Мы изменили существующую {{technique}}',
      '2_n': 'Мы изменили существующее {{technique}}',
      3: 'Мы удалили существующий {{technique}}',
      '3_f': 'Мы удалили существующую {{technique}}',
      '3_n': 'Мы удалили существующее {{technique}}',
    },
    weUsed: 'Мы использовали',
    abcModels: {
      technique: 'технику ABC-модель',
      title: 'ABC-модель',
      b: 'для мысли "{{b}}"',
      a: 'в ситуации "{{a}}"',
      emotions: 'с эмоцией {{emotion}} с силой {{strength}}',
      feelings: 'с ощущениями в теле "{{feeling}}"',
      behaviors: 'и следующим поведением: "{{behavior}}"',
    },
    behaviorExperiments: {
      technique: 'технику поведенческого эксперимента',
      typeVariants: ['Активног эксперимена', 'Наблюдательного эксперимента'],
      title: 'поведенческий эксперимент',
      hypothesisA: 'для мысли (Гипотеза А) "{{hypothesisA}}"',
      hypothesisB: 'с альтернативной мыслью (Гипотеза B) "{{hypothesisB}}"',
      safetyBehaviors:
        'и охранительным(избегающим) поведением "{{safetyBehaviors}}"',
      purpose: 'Целью данного {{type}} была "{{purpose}}".',
      experiment: 'Сам эксперимент заключался в "{{experiment}}"',
      time: 'с датой, временем или продолжительностью "{{time}}"',
      place: 'с местом проведения "{{place}}"',
      people: 'с участием других людей "{{people}}"',
      resources: 'с дополнительными ресурсами "{{resources}}"',
      survey: 'с опросом "{{survey}}"',
      followUpExperimentsIdeas:
        'Идеи для будущих экспериментов: "{{followUpExperimentsIdeas}}"',
    },
    downwardArrows: {
      technique: 'технику "падающая стрела"',
      title: 'падающая стрела',
      startingThought: 'для автоматической мысли "{{startingThought}}"',
      intermediateThought:
        'с промежуточным убеждением (правилом жизни) "{{intermediateThought}}"',
      deepBelief: 'с глубинным убеждением "{{deepBelief}}"',
    },
    exposures: {
      techniques: [
        'технику экспозиции',
        'технику экспозиции с предотвращением реакции',
      ],
      titles: ['Экспозиция', 'Экспозиция с предотвращением реакции'],
      titlesHierarchy: [
        'иерархию экспозиции',
        'иерархию экспозиции с предотвращением реакции',
      ],
      fear: 'для страха или тревоги "{{fear}}"',
      obsession: 'для обсессии "{{obsession}}"',
      safetyBehaviorsToAvoid:
        'и компульсией (охранительным/избегающим поведением) "{{safetyBehaviorsToAvoid}}"',
      situationsHierarchy: 'с иерархией ситуаций или стимулов: "{{hierarchy}}"',
      lastExpositionSituationTitle:
        'Последняя экспозиция проводилась в ситуации "{{title}}"',
      lastExpositionDuration:
        'Длительность экспозиции: "{{lastExpositionDuration}}"',
      maxSudStrength: 'Максимальная сила реакции(SUD): "{{maxSudStrength}}"',
      finishSudStrength: 'конечная сила реакции (SUD) "{{finishSudStrength}}"',
      lastExposureNotes:
        'Заметки о последней экспозиции "{{lastExposureNotes}}"',
    },
    supportingCycles: {
      technique: 'технику "поддерживающий цикл"',
      title: 'поддерживающий цикл',
      name: ', названный "{{name}},"',
      forThoughts: 'с мыслями, действиями или фактами: "{{thoughts}}"',
    },
    portalCards: {
      resource: 'portalCards',
      technique: 'копинг карточку',
      frontSide: 'с лицевой стороной: "{{frontSide}}"',
      backSide: 'и обратной стороной: "{{backSide}}"',
    },
    thoughts: {
      technique: 'технику "тест дисфункциональных мыслей"',
      title: 'тест дисфункциональных мыслей',
      activatingEvent:
        'Данная мысль возникла в результате активирующего события "{{activatingEvent}}"',
      emotions: 'с эмоцией "{{emotion}}" с силой {{strength}}',
      feelings: 'с ощущениями в теле "{{feeling}}"',
      behaviors: 'и следующим поведением: "{{behaviors}}"',
      automaticThought: 'для автоматической мысли "{{automaticThought}}"',
      thoughtFor:
        'Факты, доказывающие негативную мысль, были: "{{thoughtFor}}"',
      thoughtsForAlternativeThought:
        'но у которых могут быть альтернативные объяснения: "{{thoughtsForAlternativeThought}}" данного факта',
      thoughtsAgainst:
        'Факты, опровергающие негативную мысль: "{{thoughtsAgainst}}"',
      cognitiveDistortions: 'Когнитивные искажения "{{cognitiveDistortions}}"',
      realisticThoughts:
        'Сформулированные реалистичные мысли: "{{realisticThoughts}}"',
    },
    worryTrees: {
      technique: 'технику "дерево принятия решений"',
      title: 'дерево принятия решений',
      worryAbout: 'для тревожной мысли "{{worryAbout}}"',
      options: 'с вариантами решения проблем "{{options}}"',
      plan: 'с планом действий "{{plan}}"',
      immediateAction:
        'с действиями, которые можно сделать прямо сейчас: "{{immediateAction}}"',
    },
  },
  alarmPermissionsModal: {
    settingText: 'Открыть настройки разрешений будильника',
    text: 'Вы отклонили или удалили точные разрешения будильника для CopingCard.',
    text1:
      'Без этих разрешений мы не сможем Вам запланировать для Вас напоминания по времени.',
    title: 'Точные разрешения для будильника отключены!',
  },
  anxietyScreen: {
    dynamics: 'Динамика результатов',
    gad7: 'Тест на тревожность GAD-7',
    gad7Info:
      'GAD-7 (Опросник на генерализированное тревожное расстройство-7) используется для быстрого скрининга на наличие клинически значимого тревожного расстройства (одного из четырех наиболее распространенных тревожных расстройств — генерализованного тревожного расстройства, панического расстройства, социальной фобии и посттравматического стрессового расстройства), особенно в амбулаторных условиях.',
    level: 'Уровень тревожности',
    newTest: 'Пройти новый тест',
  },
  auth: {
    notAuthorized: 'У вас нет разрешения на получение этой информации',
    tokenError: 'Ошибка проверки токена',
  },
  backgroundHeadlessNotification: {
    channelName: 'Фоновое отслеживание геолокационных напоминаний',
    text: 'Мы отслеживаем геозоны, чтобы показывать напоминания для Ваших карточек.',
    title: 'CopingCard геолокационные уведеомления',
  },
  backgroundLocationAuthorizationAlert: {
    cancelButton: 'Отменить',
    instructions:
      'Вы использовали геолокационные уведомления для своих карточек, но не дали нужные разрешения сервису определения местоположения. Вы должны включить «Всегда» в сервисах определения местоположения, чтобы получать уведомления о геолокации, либо отключить геолокационные уведомления для карточек, чтобы не получать это уведомление.',
    settingsButton: 'Настройки',
    titleWhenNotEnabled: 'Сервисы определения местоположения не включены',
    titleWhenOff: 'Сервисы определения местоположения ВЫКЛЮЧЕНЫ',
  },
  backgroundNotificatoinsPermissionsRationale: {
    message:
      'Чтобы отслеживать Ваши действия в фоновом режиме, включите разрешение на определение местоположения {backgroundPermissionOptionLabel}',
    negativeAction: 'Отменить',
    positiveAction: 'Измените на {backgroundPermissionOptionLabel}',
    title: `Разрешить {applicationName} доступ к местоположению этого устройства в фоновом режиме?`,
  },
  batterySettingsModal: {
    okButton: 'ОК, открыть настройки',
    text: 'Чтобы обеспечить доставку уведомлений, отключите оптимизацию батареи для приложения.',
    title: 'Обнаружены ограничения',
  },
  be: {
    tokenNotFound: 'Код приглашения не найден',
    tokenNotValid: 'Код приглашения недействителен',
    noPermissions: 'У Вас нет прав для выполнения этого действия',
  },
  cardNotifications: {
    intro: 'Уведомления для реалистичной мысли:',
  },
  cardsScreen: {
    createFirstCard: 'Создайте свою первую карту',
    resetFilterButton: 'Сбросить фильтр карточек',
    reviewCardTitle: 'Повтор карточек',
    totalCount: 'Всего карточек',
  },
  channelsAndCategories: {
    channels: [
      {
        description:
          'Этот канал используется для общих уведомлений CopingCard, таких как новый функционал, обновления, новости и т.д.',
        groupId: 'CcGeneral',
        id: 'CcGeneralNews',
        name: 'Новости и обновления',
      },
      {
        description:
          'Этот канал получает временные напоминания о Ваших копинг-картах.',
        groupId: 'CcCards',
        id: 'CcCardsTime',
        name: 'Напоминания о копинг-картах по времени',
      },
      {
        description:
          'Этот канал получает геолокационные напоминания о Ваших копинг-картах.',
        groupId: 'CcCards',
        id: 'CcCardsGeolocation',
        name: 'Геолокационные напоминания о копинг-картах',
      },
      {
        description:
          'Этот канал получает напоминания о Ваших запланированных тестах.',
        groupId: 'CcTests',
        id: 'CcTests',
        name: 'Напоминания о запланированных тестах',
      },
    ],
    groups: [
      {
        description:
          'Эта группа используется для общих уведомлений CopingCard, таких как новый функционал, обновления, новости и т.д.',
        id: 'CcGeneral',
        name: 'Общие уведомления',
      },
      {
        description:
          'Эта группа организует напоминания для ваших копинговых карт на основе времени и геолокации.',
        id: 'CcCards',
        name: 'Напоминания о копинг-картах',
      },
      {
        description:
          'Эта группа организует напоминания о Ваших запланированных тестах.',
        id: 'CcTests',
        name: 'Напоминания о запланированных тестах',
      },
    ],
  },
  common: {
    and: 'И',
    back: 'Назад',
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    continue: 'Далее',
    copyToClipboard: 'Скопировать',
    delete: 'Удалить',
    done: 'закончить',
    edit: 'Редактировать',
    emailTo: 'на',
    goBack: 'Вернуться',
    learnMore: 'Узнать больше',
    na: 'Не доступно',
    no: 'нет',
    noResults: 'Нет результатов',
    ok: 'Ок',
    optionalData: 'Опциональная информация',
    or: 'или',
    readMore: 'Ещё',
    sort: 'Сортировать',
    sortAscending: 'Сортировать по возрастанию',
    sortDescending: 'Сортировать по убыванию',
    save: 'Сохранить',
    search: 'Поиск',
    selectDate: 'Выберите дату',
    selected: 'выбрано',
    send: 'отправить',
    timezone: 'Часовой пояс',
    tryAgain: 'попробовать снова',
    yes: 'да',
    next: 'Далее',
    unsavedChanges: 'У Вас есть несохраненные изменения',
    returnToScreen: 'Вернуться к текущему экрану',
    saveAndGoBack: 'Сохранить и вернуться назад',
    discardAndGoBack: 'Отменить изменения и вернуться назад',
    moveUp: 'Переместить вверх',
    moveDown: 'Переместить вниз',
    start: 'Старт',
    stop: 'Стоп',
    createdAt: 'Создано',
    failedToFetchImage: 'Не удалось загрузить изображение',
    uploadSuccess: 'Изображение успешно загружено',
    close: 'Закрыть',
  },
  countriesFallback: [
    {
      name: 'Австралия',
      value: 'AU',
    },
    {
      name: 'Австрия',
      value: 'AT',
    },
    {
      name: 'Азербайджан',
      value: 'AZ',
    },
    {
      name: 'Аландские острова',
      value: 'AX',
    },
    {
      name: 'Албания',
      value: 'AL',
    },
    {
      name: 'Алжир',
      value: 'DZ',
    },
    {
      name: 'Американское Самоа',
      value: 'AS',
    },
    {
      name: 'Ангилья',
      value: 'AI',
    },
    {
      name: 'Ангола',
      value: 'AO',
    },
    {
      name: 'Андорра',
      value: 'AD',
    },
    {
      name: 'Антарктида',
      value: 'AQ',
    },
    {
      name: 'Антигуа и Барбуда',
      value: 'AG',
    },
    {
      name: 'Аргентина',
      value: 'AR',
    },
    {
      name: 'Армения',
      value: 'AM',
    },
    {
      name: 'Аруба',
      value: 'AW',
    },
    {
      name: 'Афганистан',
      value: 'AF',
    },
    {
      name: 'Багамы',
      value: 'BS',
    },
    {
      name: 'Бангладеш',
      value: 'BD',
    },
    {
      name: 'Барбадос',
      value: 'BB',
    },
    {
      name: 'Бахрейн',
      value: 'BH',
    },
    {
      name: 'Беларусь',
      value: 'BY',
    },
    {
      name: 'Белиз',
      value: 'BZ',
    },
    {
      name: 'Бельгия',
      value: 'BE',
    },
    {
      name: 'Бенин',
      value: 'BJ',
    },
    {
      name: 'Бермуды',
      value: 'BM',
    },
    {
      name: 'Болгария',
      value: 'BG',
    },
    {
      name: 'Боливия',
      value: 'BO',
    },
    {
      name: 'Бонэйр, Синт-Эстатиус и Саба',
      value: 'BQ',
    },
    {
      name: 'Босния и Герцеговина',
      value: 'BA',
    },
    {
      name: 'Ботсвана',
      value: 'BW',
    },
    {
      name: 'Бразилия',
      value: 'BR',
    },
    {
      name: 'Британская территория в Индийском океане',
      value: 'IO',
    },
    {
      name: 'Бруней',
      value: 'BN',
    },
    {
      name: 'Буркина-Фасо',
      value: 'BF',
    },
    {
      name: 'Бурунди',
      value: 'BI',
    },
    {
      name: 'Бутан',
      value: 'BT',
    },
    {
      name: 'Вануату',
      value: 'VU',
    },
    {
      name: 'Ватикан',
      value: 'VA',
    },
    {
      name: 'Великобритания',
      value: 'GB',
    },
    {
      name: 'Венгрия',
      value: 'HU',
    },
    {
      name: 'Венесуэла',
      value: 'VE',
    },
    {
      name: 'Виргинские Острова (Великобритания)',
      value: 'VG',
    },
    {
      name: 'Виргинские Острова (США)',
      value: 'VI',
    },
    {
      name: 'Внешние малые острова (США)',
      value: 'UM',
    },
    {
      name: 'Восточный Тимор',
      value: 'TL',
    },
    {
      name: 'Вьетнам',
      value: 'VN',
    },
    {
      name: 'Габон',
      value: 'GA',
    },
    {
      name: 'Гаити',
      value: 'HT',
    },
    {
      name: 'Гайана',
      value: 'GY',
    },
    {
      name: 'Гамбия',
      value: 'GM',
    },
    {
      name: 'Гана',
      value: 'GH',
    },
    {
      name: 'Гваделупа',
      value: 'GP',
    },
    {
      name: 'Гватемала',
      value: 'GT',
    },
    {
      name: 'Гвиана',
      value: 'GF',
    },
    {
      name: 'Гвинея',
      value: 'GN',
    },
    {
      name: 'Гвинея-Бисау',
      value: 'GW',
    },
    {
      name: 'Германия',
      value: 'DE',
    },
    {
      name: 'Гернси',
      value: 'GG',
    },
    {
      name: 'Гибралтар',
      value: 'GI',
    },
    {
      name: 'Гондурас',
      value: 'HN',
    },
    {
      name: 'Гонконг',
      value: 'HK',
    },
    {
      name: 'Государство Палестина',
      value: 'PS',
    },
    {
      name: 'Гренада',
      value: 'GD',
    },
    {
      name: 'Гренландия',
      value: 'GL',
    },
    {
      name: 'Греция',
      value: 'GR',
    },
    {
      name: 'Грузия',
      value: 'GE',
    },
    {
      name: 'Гуам',
      value: 'GU',
    },
    {
      name: 'Дания',
      value: 'DK',
    },
    {
      name: 'Демократическая Республика Конго',
      value: 'CD',
    },
    {
      name: 'Джерси',
      value: 'JE',
    },
    {
      name: 'Джибути',
      value: 'DJ',
    },
    {
      name: 'Доминика',
      value: 'DM',
    },
    {
      name: 'Доминиканская Республика',
      value: 'DO',
    },
    {
      name: 'Египет',
      value: 'EG',
    },
    {
      name: 'Замбия',
      value: 'ZM',
    },
    {
      name: 'Зимбабве',
      value: 'ZW',
    },
    {
      name: 'Израиль',
      value: 'IL',
    },
    {
      name: 'Индия',
      value: 'IN',
    },
    {
      name: 'Индонезия',
      value: 'ID',
    },
    {
      name: 'Иордания',
      value: 'JO',
    },
    {
      name: 'Ирак',
      value: 'IQ',
    },
    {
      name: 'Иран',
      value: 'IR',
    },
    {
      name: 'Ирландия',
      value: 'IE',
    },
    {
      name: 'Исландия',
      value: 'IS',
    },
    {
      name: 'Испания',
      value: 'ES',
    },
    {
      name: 'Италия',
      value: 'IT',
    },
    {
      name: 'Йемен',
      value: 'YE',
    },
    {
      name: 'Кабо-Верде',
      value: 'CV',
    },
    {
      name: 'Казахстан',
      value: 'KZ',
    },
    {
      name: 'Камбоджа',
      value: 'KH',
    },
    {
      name: 'Камерун',
      value: 'CM',
    },
    {
      name: 'Канада',
      value: 'CA',
    },
    {
      name: 'Катар',
      value: 'QA',
    },
    {
      name: 'Кения',
      value: 'KE',
    },
    {
      name: 'Кипр',
      value: 'CY',
    },
    {
      name: 'Киргизия',
      value: 'KG',
    },
    {
      name: 'Кирибати',
      value: 'KI',
    },
    {
      name: 'КНДР (Корейская Народно-Демократическая Республика)',
      value: 'KP',
    },
    {
      name: 'КНР (Китайская Народная Республика)',
      value: 'CN',
    },
    {
      name: 'Кокосовые острова',
      value: 'CC',
    },
    {
      name: 'Колумбия',
      value: 'CO',
    },
    {
      name: 'Коморы',
      value: 'KM',
    },
    {
      name: 'Косово',
      value: 'XK',
    },
    {
      name: 'Коста-Рика',
      value: 'CR',
    },
    {
      name: 'Кот-д’Ивуар',
      value: 'CI',
    },
    {
      name: 'Куба',
      value: 'CU',
    },
    {
      name: 'Кувейт',
      value: 'KW',
    },
    {
      name: 'Кюрасао',
      value: 'CW',
    },
    {
      name: 'Лаос',
      value: 'LA',
    },
    {
      name: 'Латвия',
      value: 'LV',
    },
    {
      name: 'Лесото',
      value: 'LS',
    },
    {
      name: 'Либерия',
      value: 'LR',
    },
    {
      name: 'Ливан',
      value: 'LB',
    },
    {
      name: 'Ливия',
      value: 'LY',
    },
    {
      name: 'Литва',
      value: 'LT',
    },
    {
      name: 'Лихтенштейн',
      value: 'LI',
    },
    {
      name: 'Люксембург',
      value: 'LU',
    },
    {
      name: 'Маврикий',
      value: 'MU',
    },
    {
      name: 'Мавритания',
      value: 'MR',
    },
    {
      name: 'Мадагаскар',
      value: 'MG',
    },
    {
      name: 'Майотта',
      value: 'YT',
    },
    {
      name: 'Макао',
      value: 'MO',
    },
    {
      name: 'Малави',
      value: 'MW',
    },
    {
      name: 'Малайзия',
      value: 'MY',
    },
    {
      name: 'Мали',
      value: 'ML',
    },
    {
      name: 'Мальдивы',
      value: 'MV',
    },
    {
      name: 'Мальта',
      value: 'MT',
    },
    {
      name: 'Марокко',
      value: 'MA',
    },
    {
      name: 'Мартиника',
      value: 'MQ',
    },
    {
      name: 'Маршалловы Острова',
      value: 'MH',
    },
    {
      name: 'Мексика',
      value: 'MX',
    },
    {
      name: 'Микронезия',
      value: 'FM',
    },
    {
      name: 'Мозамбик',
      value: 'MZ',
    },
    {
      name: 'Молдавия',
      value: 'MD',
    },
    {
      name: 'Монако',
      value: 'MC',
    },
    {
      name: 'Монголия',
      value: 'MN',
    },
    {
      name: 'Монтсеррат',
      value: 'MS',
    },
    {
      name: 'Мьянма',
      value: 'MM',
    },
    {
      name: 'Намибия',
      value: 'NA',
    },
    {
      name: 'Науру',
      value: 'NR',
    },
    {
      name: 'Непал',
      value: 'NP',
    },
    {
      name: 'Нигер',
      value: 'NE',
    },
    {
      name: 'Нигерия',
      value: 'NG',
    },
    {
      name: 'Нидерланды',
      value: 'NL',
    },
    {
      name: 'Никарагуа',
      value: 'NI',
    },
    {
      name: 'Ниуэ',
      value: 'NU',
    },
    {
      name: 'Новая Зеландия',
      value: 'NZ',
    },
    {
      name: 'Новая Каледония',
      value: 'NC',
    },
    {
      name: 'Норвегия',
      value: 'NO',
    },
    {
      name: 'ОАЭ',
      value: 'AE',
    },
    {
      name: 'Оман',
      value: 'OM',
    },
    {
      name: 'Остров Буве',
      value: 'BV',
    },
    {
      name: 'Остров Мэн',
      value: 'IM',
    },
    {
      name: 'Остров Норфолк',
      value: 'NF',
    },
    {
      name: 'Остров Рождества',
      value: 'CX',
    },
    {
      name: 'Острова Кайман',
      value: 'KY',
    },
    {
      name: 'Острова Кука',
      value: 'CK',
    },
    {
      name: 'Острова Питкэрн',
      value: 'PN',
    },
    {
      name: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
      value: 'SH',
    },
    {
      name: 'Пакистан',
      value: 'PK',
    },
    {
      name: 'Палау',
      value: 'PW',
    },
    {
      name: 'Панама',
      value: 'PA',
    },
    {
      name: 'Папуа — Новая Гвинея',
      value: 'PG',
    },
    {
      name: 'Парагвай',
      value: 'PY',
    },
    {
      name: 'Перу',
      value: 'PE',
    },
    {
      name: 'Польша',
      value: 'PL',
    },
    {
      name: 'Португалия',
      value: 'PT',
    },
    {
      name: 'Пуэрто-Рико',
      value: 'PR',
    },
    {
      name: 'Республика Конго',
      value: 'CG',
    },
    {
      name: 'Республика Корея',
      value: 'KR',
    },
    {
      name: 'Реюньон',
      value: 'RE',
    },
    {
      name: 'Российская Федерация',
      value: 'RU',
    },
    {
      name: 'Руанда',
      value: 'RW',
    },
    {
      name: 'Румыния',
      value: 'RO',
    },
    {
      name: 'САДР',
      value: 'EH',
    },
    {
      name: 'Сальвадор',
      value: 'SV',
    },
    {
      name: 'Самоа',
      value: 'WS',
    },
    {
      name: 'Сан-Марино',
      value: 'SM',
    },
    {
      name: 'Сан-Томе и Принсипи',
      value: 'ST',
    },
    {
      name: 'Саудовская Аравия',
      value: 'SA',
    },
    {
      name: 'Свазиленд',
      value: 'SZ',
    },
    {
      name: 'Северная Македония',
      value: 'MK',
    },
    {
      name: 'Северные Марианские Острова',
      value: 'MP',
    },
    {
      name: 'Сейшельские Острова',
      value: 'SC',
    },
    {
      name: 'Сен-Бартелеми',
      value: 'BL',
    },
    {
      name: 'Сен-Мартен',
      value: 'MF',
    },
    {
      name: 'Сен-Пьер и Микелон',
      value: 'PM',
    },
    {
      name: 'Сенегал',
      value: 'SN',
    },
    {
      name: 'Сент-Винсент и Гренадины',
      value: 'VC',
    },
    {
      name: 'Сент-Китс и Невис',
      value: 'KN',
    },
    {
      name: 'Сент-Люсия',
      value: 'LC',
    },
    {
      name: 'Сербия',
      value: 'RS',
    },
    {
      name: 'Сингапур',
      value: 'SG',
    },
    {
      name: 'Синт-Мартен',
      value: 'SX',
    },
    {
      name: 'Сирия',
      value: 'SY',
    },
    {
      name: 'Словакия',
      value: 'SK',
    },
    {
      name: 'Словения',
      value: 'SI',
    },
    {
      name: 'Соломоновы Острова',
      value: 'SB',
    },
    {
      name: 'Сомали',
      value: 'SO',
    },
    {
      name: 'Судан',
      value: 'SD',
    },
    {
      name: 'Суринам',
      value: 'SR',
    },
    {
      name: 'США',
      value: 'US',
    },
    {
      name: 'Сьерра-Леоне',
      value: 'SL',
    },
    {
      name: 'Таджикистан',
      value: 'TJ',
    },
    {
      name: 'Таиланд',
      value: 'TH',
    },
    {
      name: 'Тайвань',
      value: 'TW',
    },
    {
      name: 'Танзания',
      value: 'TZ',
    },
    {
      name: 'Теркс и Кайкос',
      value: 'TC',
    },
    {
      name: 'Того',
      value: 'TG',
    },
    {
      name: 'Токелау',
      value: 'TK',
    },
    {
      name: 'Тонга',
      value: 'TO',
    },
    {
      name: 'Тринидад и Тобаго',
      value: 'TT',
    },
    {
      name: 'Тувалу',
      value: 'TV',
    },
    {
      name: 'Тунис',
      value: 'TN',
    },
    {
      name: 'Туркмения',
      value: 'TM',
    },
    {
      name: 'Турция',
      value: 'TR',
    },
    {
      name: 'Уганда',
      value: 'UG',
    },
    {
      name: 'Узбекистан',
      value: 'UZ',
    },
    {
      name: 'Украина',
      value: 'UA',
    },
    {
      name: 'Уоллис и Футуна',
      value: 'WF',
    },
    {
      name: 'Уругвай',
      value: 'UY',
    },
    {
      name: 'Фареры',
      value: 'FO',
    },
    {
      name: 'Фиджи',
      value: 'FJ',
    },
    {
      name: 'Филиппины',
      value: 'PH',
    },
    {
      name: 'Финляндия',
      value: 'FI',
    },
    {
      name: 'Фолклендские острова',
      value: 'FK',
    },
    {
      name: 'Франция',
      value: 'FR',
    },
    {
      name: 'Французская Полинезия',
      value: 'PF',
    },
    {
      name: 'Французские Южные и Антарктические Территории',
      value: 'TF',
    },
    {
      name: 'Херд и Макдональд',
      value: 'HM',
    },
    {
      name: 'Хорватия',
      value: 'HR',
    },
    {
      name: 'ЦАР',
      value: 'CF',
    },
    {
      name: 'Чад',
      value: 'TD',
    },
    {
      name: 'Черногория',
      value: 'ME',
    },
    {
      name: 'Чехия',
      value: 'CZ',
    },
    {
      name: 'Чили',
      value: 'CL',
    },
    {
      name: 'Швейцария',
      value: 'CH',
    },
    {
      name: 'Швеция',
      value: 'SE',
    },
    {
      name: 'Шпицберген и Ян-Майен',
      value: 'SJ',
    },
    {
      name: 'Шри-Ланка',
      value: 'LK',
    },
    {
      name: 'Эквадор',
      value: 'EC',
    },
    {
      name: 'Экваториальная Гвинея',
      value: 'GQ',
    },
    {
      name: 'Эритрея',
      value: 'ER',
    },
    {
      name: 'Эстония',
      value: 'EE',
    },
    {
      name: 'Эфиопия',
      value: 'ET',
    },
    {
      name: 'ЮАР',
      value: 'ZA',
    },
    {
      name: 'Южная Георгия и Южные Сандвичевы Острова',
      value: 'GS',
    },
    {
      name: 'Южный Судан',
      value: 'SS',
    },
    {
      name: 'Ямайка',
      value: 'JM',
    },
    {
      name: 'Япония',
      value: 'JP',
    },
  ],
  depressionScreen: {
    dynamics: 'Динамика результатов',
    level: 'Уровень депрессии',
    newTest: 'Пройти новый тест',
    phq9: 'Тест на депрессию PHQ-9',
    phq9Info:
      'PHQ-9 (Опросник по состоянию здоровья-9) представляет собой многоцелевой инструмент для скрининга, диагностики, мониторинга и измерения тяжести депрессии. PHQ-9 продемонстрировал хорошую надежность и валидность. Это простой, быстрый, эффективный и надежный инструмент для скрининга и оценки тяжести депрессии.',
  },
  downgradeDetectedModal: {
    contactTherapist:
      'Если полный доступ был предоставлен Вам через специалиста/поставщика услуг в области психического здоровья, свяжитесь с ним, чтобы восстановить доступ.',
    contactSupport:
      'Если Вы считаете, что произошла ошибка, обратитесь в службу поддержки по адресу:',
    downgrade: 'Ограничить функционал',
    notificationsTurnOff:
      'Некоторые из Ваших уведомлений и напоминаний будут отключены.',
    pay: 'Сохранить функционал',
    text: 'Ваша подписка больше не действительна. Если Вы не подпишетесь снова, платные функции будут отключены. В частности, Вы потеряете доступ к:',
    title: 'Подписка недействительна',
  },
  errorModal: {
    clientErrors: 'Ошибки приложения',
    code: 'Код',
    contact:
      'Попробуйте еще раз или перезагрузите приложение. Если это не решит проблему, свяжитесь с нами по адресу:',
    extraInfo: 'Дополнительная информация',
    graphqlErrors: 'Ошибки Graphq',
    location: 'Где',
    message: `Сообщение`,
    name: 'Название',
    network: 'Сетевая ошибка',
    path: 'Путь',
    title: `Ой! Произошла ошибка.`,
    tokenExpiredTitle: 'Ваша сессия истекла',
    tokenError:
      'Ваш токен недействителен или просрочен. Пожалуйста, войдите в приложение снова.',
    tokenSettings:
      'Вы можете управлять настройками входа в Настройках -> Настройки конфиденциальности',
  },
  confirmModal: {
    cancelButton: 'Отменить',
    confirmButton: 'Подтвердить',
  },
  crisis: {
    crisisText: `Если Вы испытываете психологический кризис и нуждаетесь в немедленной помощи, пожалуйста, свяжитесь с кризисной линией Вашей страны или региона.`,
    courage:
      'Помните, что для того, чтобы обратиться за помощью, требуется мужество, и нет ничего постыдного в том, чтобы искать поддержки для своего психического здоровья. Вы не одиноки, и есть люди и ресурсы, которые помогут Вам в это трудное время.',
    findCrisisLine: 'найти кризисную линию',
    selfHelp: 'Самопомощь',
    mammalianText:
      'Рефлекс ныряния млекопитающих — это физиологическая реакция, возникающая у млекопитающих, в том числе у человека, когда они погружаются в воду. Этот рефлекс вызывает ряд автоматических изменений в организме, в том числе снижение частоты сердечных сокращений и сужение кровеносных сосудов, чтобы сохранить кислород и обеспечить длительные периоды подводной активности. В то время как рефлекс ныряния у млекопитающих обычно ассоциируется с нырянием и плаванием, недавние исследования показывают, что преднамеренное срабатывание этого рефлекса с помощью таких практик, как погружение в холодную воду или задержка дыхания, может помочь облегчить симптомы тревоги и стресса.',
    instructionsLink: 'Читать инструкцию',
    muscleRelaxationTitle: 'Прогрессирующая мышечная релаксация по Джекобсону',
    muslceRelaxationText:
      'Прогрессивная мышечная релаксация — это техника релаксации, разработанная врачом Эдмундом Джекобсоном в начале 20 века, которая включает в себя систематическое напряжение и расслабление различных групп мышц тела. Техника предназначена для того, чтобы помочь людям распознать и снять физическое напряжение в теле, что, в свою очередь, может уменьшить чувство тревоги и стресса. Исследования показали, что регулярная практика прогрессивной мышечной релаксации может привести к значительному снижению тревожности и связанных с ней симптомов, а также к улучшению качества сна и общего самочувствия.',

    technique54321Text:
      'Техника 5-4-3-2-1 — это простое упражнение на осознанность, которое можно использовать для уменьшения чувства тревоги и стресса. Техника включает в себя задействование каждого из органов чувств, чтобы «заземлиться» в настоящем моменте и отвлечься от тревожных мыслей. Это делается путем определения и сосредоточения на пяти вещах, которые вы можете видеть, четырех вещах, которые вы можете потрогать, трех вещах, которые вы можете услышать, двух вещах, которые вы можете обонять, и одной вещи, которую вы можете попробовать. Сосредоточившись на сенсорных переживаниях в настоящий момент, люди могут переключить свое внимание с тревожных мыслей на настоящий момент, что приводит к уменьшению чувства тревоги и стресса.',
    goToTool: 'Перейти к инструменту',
    meditationsText:
      'Медитация осознанности — это техника, которая включает в себя намеренное сосредоточение внимания на переживаниях настоящего момента, таких как мысли, эмоции и ощущения, без осуждения. Доказано, что регулярная практика медитации осознанности оказывает ряд благотворных эффектов на психическое здоровье, в том числе уменьшает симптомы тревоги и стресса. Считается, что это связано с тем, что медитация осознанности может помочь людям развить большее осознание и принятие своих мыслей и эмоций, что приводит к снижению склонности зацикливаться на негативных моделях мышления и размышлениях. Кроме того, было показано, что медитация осознанности оказывает положительное влияние на функционирование мозга, приводя к усилению эмоциональной регуляции и снижению реакции на стрессоры.',
    goToMediations: 'Перейти к Медитациям',
  },

  urgentCrisis: {
    internationlHelp:
      'Если Вы находитесь в другой стране, Вы можете найти список кризисных линий в Вашей стране на сайте',
    internationlHelpName:
      'Международной ассоциации психологической помощи в кризисных ситуациях',
    ifUS: 'Если Вы находитесь в США, позвоните по номеру:',
    orChat: 'или пообщайтесь в чате',
    pleaseCall: 'позвоните по номеру:',
    ifBY: 'Если Вы в Беларуси,',
    ifUA: 'Если Вы в Украине,',
    ifRU: 'Если Вы в России,',
    findCrisisLine: 'найдите список кризисных линий',
  },
  mammalianReflex: {
    warning:
      '⚠️ Противопоказания! Активация рефлекса ныряльщика замедляет частоту сердечных сокращений. Людям с кардиологическими проблемами (в частности, с брадикардией) следует избегать этой стратегии. Не используйте эту стратегию, если у Вас подобные проблемы, либо если Вы не уверены в том, есть ли они у Вас. Проконсультируйтесь с Вашим врачом перед использованием.',
    instructions: 'Инструкции',
    details: `Когда мы находимся в состоянии крайнего эмоционального возбуждения, наш мозг не функционирует должным образом, чтобы эффективно воспринимать новую информацию и обрабатывать ее. Необходимо «перезагрузить» нервную систему!\n
Как можно помочь себе, если, находясь в эмоциональном возбуждении, вы осознаете, что нужно успокоиться, но внутренних ресурсов не хватает, а советы "успокоиться" только раздражают?\n
👉 Вариантом решения этой задачи является использование доступного каждому млекопитающему психофизиологического эффекта, который называется "рефлекс ныряльщика".\n
Как активировать рефлекс:
➡️ наполняем миску ледяной водой (10 градусов по Цельсию)
➡️ наклоняемся и задерживаем дыхание
➡️ опускаем лицо в ледяную воду на 30 секунд
➡️ проверьте: область под глазами/над скулами (самая чувствительная часть лица) должна чувствовать ледяную воду\n
Эти элементарные действия заставляют включаться парасимпатическую нервную систему - человек расслабляется и успокаивается😇\n
✅ Лайфхак: прием лучше всего работает, если задержать дыхание и наклониться на 30 секунд.
    `,
  },

  technique54321: {
    title: '5-4-3-2-1 Техника заземления',
    instructions: `Техника 5-4-3-2-1 — это упражнение на «заземление», которое может помочь Вам уменьшить тревогу и почувствовать себя в настоящем моменте. Вот шаги, которые необходимо выполнить:\n
Найдите тихое и удобное место, чтобы комфортно сидеть или стоять.\n
Сделайте пять глубоких вдохов/выдохов. Вдыхая, представляйте, что Вы наполняете легкие спокойствием, а выдыхая, представляйте, что освобождаетесь от любого напряжения или беспокойства.\n
5. Затем оглянитесь вокруг и определите пять вещей, которые можно рассмотреть. Это могут быть предметы в комнате или снаружи, все, что Вы видите вокруг себя. Сосредоточьтесь на каждом предмете и попытайтесь рассмотреть его форму, цвет, текстуру и любые другие детали, которые Вы заметите.\n
4. Затем выберите четыре вещи, которые Вы можете потрогать или почувтсвовать. Это может быть текстура Вашей одежды, ощущение Ваших ног на земле, температура воздуха вокруг Вас или что-то еще, что Вы можете физически ощутить. Обратите внимание на каждое ощущение и постарайтесь описать его себе как можно подробнее.\n
3. Затем выберите три вещи, которые Вы можете услышать. Это может быть шум уличного движения, гул компьютера, шелест листьев на ветру или что-то еще, что Вы можете услышать. Сосредоточьтесь на каждом звуке и постарайтесь различить паттерны, ритмы или тона, которые Вы замечаете.\n
2. Затем выберите два аромата, которые Вы можете почувствовать. Это может быть запах цветов, пищи, духи или что-то еще. Сделайте глубокий вдох и постарайтесь сосредоточиться на запахе.\n
1. Наконец, определите одну вещь, которую Вы можете попробовать на вкус. Это может быть вкус Вашей любимой еды, напитка и т.д. Потратьте немного времени, чтобы насладиться вкусом и сосредоточиться на нем.\n
К концу этого упражнения Вы должны чувствовать себя более «заземленными», сосредоточенными и присутствующими в настоящем моменте. Это отличный способ отдохнуть от негативных мыслей и беспокойства и почувствовать большую связь с окружающим миром.
    `,
  },
  muscleRelaxation: {
    videoTitle: 'Смотреть видео с инструкциями',
    readTitle: 'Читать инструкции',
    videoId: 'y_Y-RrUhJH4',
    phasesAndStepsTitle: 'Фазы и Шаги',
    phasesAndStepsText: `Метод прогрессивной мышечной релаксации по Джекобсону устраняет тревогу в стрессовых ситуациях. Процесс расслабления проходит следующие фазы.\n
1. Попытка к расслаблению.
2. Расслабление.
3. Наблюдение за процессом перехода от напряжения к расслаблению.
4. «Переживание» расслабления.\n\n
Расслабление происходит поэтапно.
Расслабление мышц рук.
Расслабление мышц лица.
Расслабление мышц шеи и спины.
Расслабление мышц нижней части тела.
Каждому шагу расслабления соответствуют специфические упражнения.\n
Овладение этими приемами расслабления поможет в кратчайшие сроки восстановить нервно-психическое равновесие, почувствовать себя отдохнувшими и полными сил.
    `,
    armsTitle: 'Расслабление мышц рук (4-5 минут)',
    armsText: `Устройтесь поудобнее (сидя или лежа).

Расслабьтесь, насколько можете. При полном расслаблении сожмите кулак правой руки, при этом контролируйте степень сжатия, почувствуйте напряжение мышц кисти, предплечья — разожмите кулак и почувствуйте расслабление. Сравните свои ощущения.

Еще раз сожмите кулак как можно сильнее — держите. Разожмите кулак, попытайтесь достичь глубокого расслабления. Срав­ните Ваши ощущения.

Повторите все левой рукой. В это время все тело остается полностью расслабленным.

Сожмите пальцы обеих рук в кулак.

Почувствуйте напряжение пальцев, предплечья.

Разожмите пальцы, расслабьтесь. Сравните Ваши ощущения. Продолжайте расслабление.

Согните правую руку в локте и напрягите бицепс.

Напрягите бицепс как можно сильнее, контролируйте напряже­ние бицепса.

Разогните локоть, расслабьте бицепс и почувствуйте разницу. Попытайтесь максимально почувствовать расслабление бицепса.

Еще раз напрягите бицепс, поддерживайте напряжение, почув­ствуйте степень напряжения.

Расслабьтесь полностью. Сравните Ваши ощущения.

Постоянно сосредоточивайте Ваше внимание на ощущениях при напряжении и расслаблении.

Повторите то же самое левой рукой.

Повторите то же самое одновременно двумя руками.

Меняйте степень и длительность напряжения мышц перед рас­слаблением. Сравните Ваши ощущения. Вытяните руки вперед и почувствуйте напряжение трицепса. Верните руки в исходное положение, расслабьтесь, почувствуйте разницу в ощущениях.

Повторите еще раз. Еще раз сравните Ваши ощущения. Теперь попытайтесь полностью расслабить мышцы рук без пред­варительного напряжения.

Продолжайте расслабляться больше и больше, достигайте более глубокого расслабления.

Отметьте ощущения тепла и тяжести в руках по мере расслабления. Руки расслабленные и тяжелые... расслабленные и тяжелые.
`,
    faceTitle: 'Расслабление мышц лица (4-5 минут)',
    faceText: `Наморщите лоб, поднимите брови.

Наморщите лоб сильнее... и расслабьте.

Сдвиньте брови и отметьте напряжение.

Расслабьте и разгладьте брови.

Сильно зажмурьте глаза, ощущайте напряжение... и расслабьтесь, не открывая глаз.

Почувствуйте расслабление мышц лба, глаз.

Крепко сожмите зубы, напрягите челюсти. Почувствуйте напряжение челюстей.

Расслабьтесь, чуть-чуть приоткройте рот.

Крепко прижмите язык к верхнему небу. Ощущайте напряжение языка...и расслабьте, опустив язык.

Округлите губы, как будто Вы хотите произнести букву “о”, расслабьтесь.

Почувствуйте разницу между напряжением и расслаблением.

Ощутите расслабление всех мышц Вашего лица: лоб, глаза, челюсти, язык расслабляются. Расслабьтесь полностью.

Ощутите тепло и тяжесть во всех мышцах лица.
`,
    neckTitle: 'Расслабление мышц шеи и спины (4-5 минут)',
    neckText: `Сконцентрируйте внимание на мышцах шеи.

Наклоните голову назад как можно сильнее, ощутите напряжение шеи.

Поверните голову налево до отказа. Отметьте напряжение.

Поверните голову направо до отказа, почувствуйте напряжение.

Верните голову в исходное положение.

Расслабьтесь. Сравните Ваши ощущения.

Наклоните голову вперед, коснитесь подбородком груди. Почувствуйте напряжение шеи. Верните голову в исходное положение.

Расслабьтесь, почувствуйте тепло и расслабление.

Поднимите плечи к ушам, сведите лопатки, не напрягая при этом рук. Сделайте круговое движение плечами с максимальной амплитудой.

Верните плечи в исходное положение. Расслабьтесь. Ощутите приятный контраст между напряжением и расслаблением.

Выгните позвоночник дугой и ощутите напряжение вдоль позвоночника.

Вернитесь в исходное положение. Почувствуйте расслабление, оно разливается по всей спине.

Спина тяжелая и теплая. Ощутите приятное тепло...
`,
    legsTitle: 'Расслабление нижней части тела (4-5 минут)',
    legsText: `Сконцентрируйте внимание на нижней части тела.

Напрягите ягодицы и бедра, крепко прижав пятки к опоре.

Расслабьтесь... Отметьте разницу в ощущениях.

Еще раз напрягите ягодицы и бедра.

Сохраняйте их в напряженном состоянии.

Сохраняя напряжение в ягодицах и бедрах, согните ступни, носки — на себя, ощутите напряжение икроножных мышц.

Расслабьтесь. Еще глубже... глубже.

Почувствуйте расслабление всех мышц нижней части Вашего тела.

Отметьте, как Ваши ноги тяжелеют по мере расслабления. Ноги расслабленны и тяжелы.

Ощутите тепло, распространяющееся по Вашим ногам.

Ноги тяжелые и теплые.
    `,
    summary: `Как только Вы закончите всю последовательность, сделайте несколько глубоких вдохов и просканируйте свое тело на наличие оставшихся областей напряжения.

Если Вы обнаружите какие-либо области, которые все еще напряжены, повторите упражнение на расслабление этих мышц.

Закончив, сделайте еще несколько глубоких вдохов и медленно откройте глаза.

Регулярно практикуя прогрессивную мышечную релаксацию, Вы сможете лучше осознавать свое тело и научиться распознавать, когда Вы удерживаете напряжение в мышцах. Эта техника может помочь Вам уменьшить стресс и способствовать расслаблению в Вашей повседневной жизни.
    `,
  },

  fBConsentModal: {
    agreeButton: 'Принять и использовать Facebook',
    cancelButton: 'Отмена',
    text: 'Нам нужно Ваше согласие, чтобы разрешить инициализацию Facebook SDK и ведение журнала событий, чтобы использовать Facebook для регистрации или входа в систему.',
    title: 'Использование Facebook SDK',
  },

  flippingCard: {
    automaticThought: 'Автоматическая мысль',
    cardName: 'Название карточки',
    categoriesSelectText: 'Выберите категории для мысли',
    editEvent: 'К Aктивирующему событию',
    realisticThoughts: 'Реалистичные мысли',
    supportingThoughts: 'Поддерживающие мысли',
    confirmCardDeleteTitle: 'Удаление карточки',
    confirmCardDeleteText: `Вы уверены, что хотите удалить эту карточку?

  Карточка будет удалена без возможности восстановления вместе с соответствующим анализом мыслей, напоминаниями по времени и геолокационными уведомлениями.
    `,
  },
  gad7: {
    q1: `Вы нервничали, тревожились или испытывали сильный стресс`,
    q2: `Вы были неспособны успокоиться или контролировать свое волнение`,
    q3: `Вы слишком сильно волновались по различным поводам`,
    q4: `Вам было трудно расслабиться`,
    q5: `Вы были настолько суетливы, что Вам было тяжело усидеть на месте`,
    q6: `Вы легко злились или раздражались`,
    q7: `Вы испытывали страх, словно должно произойти нечто ужасное`,
    score: `Оценка GAD-7`,
    severity: `Степень тревожности`,
    title: `ГЕНЕРАЛИЗОВАННОЕ ТРЕВОЖНОЕ РАССТРОЙСТВО-7 (GAD-7)`,
  },
  insights: {
    cardsFlipped: 'Просмотренных копинг-карточек',
    dayRemindersTitle: 'Использование напоминаний по времени',
    locationRemindersTitle: 'Использование геолокационных напоминаний',
    mostUsedCards: 'Самые используемые карточки',
    opened: 'Открытые',
    triggered: 'Сработавшие',
    triggeredOpenedRatio: 'Процент открытых из сработавших',
    meditationsTitle: 'Прослушано медитаций',
  },
  inviteTherapistModal: {
    title: 'Пригласить специалиста в области психического здоровья',
    description:
      'Пригласите Вашего психолога/психотерапевта присоединиться к психотерапевтической платформе CopingCard, чтобы Вы могли поделиться с ним своими данными, чтобы сделать Вашу терапию более эффективной.',
    noName:
      'Похоже, Вы еще не добавили свое имя. Пожалуйста, введите свое имя, чтобы мы могли упомянуть его в электронном письме с приглашением.',
    yourName: 'Ваше имя',
    yourLastName: 'Ваша фамилия',
    therapistInfo: 'Информация о специалисте по психическому здоровью',
    firstName: 'Имя',
    lastName: 'Фамилия',
    therapistEmail: 'Емейл',
    sendInvite: 'Отправить приглашение',
    emailText: 'Текст приглашения, который будет отправлен',

    inviteText: `
Здравствуйте, {{firstName}} {{lastName}}!
{{yourName}} {{yourLastName}} приглашает Вас присоединиться к психотерапевтической платформе CopingCard.

Нажмите на ссылку выше, чтобы принять приглашение и/или создать учетную запись и принять приглашение.

Это позволит эффективно обмениваться информацией и прогрессом во время терапии.

С уважением,
Команда CopingCard, Inc.
`,
    inviteTextHtml: `
    <p>Здравствуйте, {{firstName}} {{lastName}}!</p>
    <p>{{yourName}} {{yourLastName}} приглашает Вас присоединиться к платформе специалистов <b><a href="{{link}}">CopingCard</a></b>.
     <br />
     <p>Нажмите на ссылку выше, чтобы принять приглашение и/или создать учетную запись и принять приглашение.</p>
     <br />
     <p>Это позволит эффективно обмениваться информацией и прогрессом во время терапии.</p>
     <br />
    <p>С уважением,</p>
    <p>Команда CopingCard, Inc.</p>
`,
  },
  labels: {
    email: 'Емейл',
    password: 'Пароль',
  },
  locationModal: {
    addLocation: 'Добавить местоположение',
    locationName: 'Название местоположения',
    savedLocations: 'Сохранённые местоположения',
    searchPlaceholder: 'Поиск',
    selectSavedLocation: 'Выбрать из сохранённых местоположений',
  },
  meditations: {
    loading:
      'Загрузка медитаций. Это может занять какое-то время при первой загрузке...',
  },
  navigation: {
    appBlockModalText:
      'Пользователь заблокировал приложение от получения уведомлений, что делает невозможным получение напоминаний. Разрешите получение в настройках для работы функционала напоминаний.',
    backButtonTitle: 'Назад',
    blockModalTitle: 'Обнаружены ограничения',
    channelBlockModalText:
      'Пользователь заблокировал канал уведомлений, что делает невозможным получение напоминаний. Разрешите получение в настройках для работы функционала напоминаний.',
    channelGroupBlockModalText:
      'Пользователь заблокировал группу каналов уведомлений, что делает невозможным получение напоминаний.',
    toCopingCards: 'Перейти к Копинг-картам',
  },
  notificationPermissionsModal: {
    settingText: 'Открыть настройки приложения',
    text: 'Вы отклонили или удалили разрешения на уведомления для CopingCard.',
    text1:
      'Без этих разрешений мы не сможем присылать Вам напоминания о повторениях мыслей.',
    text2:
      'Вам нужно вручную включить разрешения для уведомлений в пользовательском интерфейсе настроек, чтобы иметь возможность получать напоминания.',
    title: 'Нет разрешений для уведомлений!',
  },
  notifications: {
    locationReminderBody:
      'Сработало геолокационное напоминание для Вашей мысли',
    locationReminderTitle: 'Геолокационное напоминание',
    testReminderBody: 'Пришло время пройти запланированный тест',
    testReminderTitle: 'Уведомление о запланированном тесте',
    timeNotificationBody: 'Пришло время повторить Вашу новую мысль',
    timeNotificationTitle: 'Уведомление по времени',
    fineLocatonPermissionTitle: 'Разрешите получение геолокации',
    fineLocatonPermissionText:
      'Точное местоположение необходимо для отображения Вашего положения на карте.',
  },
  phq9: {
    q1: `Вам не хотелось ничего делать`,
    q2: `У Вас было плохое настроение, Вы были подавлены или испытывали чувство безысходности`,
    q3: `Вам было трудно заснуть, у Вас был прерывистый сон, или Вы слишком много спали`,
    q4: `Вы были утомлены, или у Вас было мало сил`,
    q5: `У Вас был плохой аппетит, или Вы переедали`,
    q6: `Вы плохо о себе думали: считали себя неудачником (неудачницей), или были в себе разочарованы, или считали, что подвели свою семью`,
    q7: `Вам было трудно сосредоточиться (например, на чтении газеты или на просмотре телепередач)`,
    q8: `Вы двигались или говорили настолько медленно, что окружающие это замечали? Или, наоборот, Вы были настолько суетливы или взбудоражены, что двигались гораздо больше обычного`,
    q9: `Вас посещали мысли о том, что Вам лучше было бы умереть, или о том, чтобы причинить себе какой-нибудь вред`,
    score: `Оценка PHQ-9`,
    severity: `Степень депрессии`,
    title: `ОПРОСНИК ПО СОСТОЯНИЮ ЗДОРОВЬЯ-9 (PHQ-9)`,
  },
  privacySettings: {
    accept: 'Принять',
    agree: 'Принять и сохранить настройки',
    deleteText:
      'Чтобы удалить свою учетную запись и связанные с ней данные, свяжитесь с нами по адресу:',
    errorsTracking: 'Сбор информации об ошибках',
    errorsTrackingInfo:
      'Собирайте информацию об ошибках и сбоях, чтобы помочь с исправлениями и облегчить поддержку.',
    iAgree: 'Я согласен с',
    keepSignedIn: 'Не выходить из приложения',
    keepSignedInInfo: `
    ПРЕДУПРЕЖДЕНИЕ! Если вы включите эту настройку и кто-то получит несанкционированный доступ к вашему телефону (например, он будет украден и не защищен паролем, но при этом будет иметь доступ в Интернет), это может привести к утечке вашей личной медицинской информации.
    Мы настоятельно рекомендуем вам оставить эту настройку выключенной.
    `,
    keepSignedInWarning: `
    WARNING! If you turn the setting on and someone gets unauthorized access to your phone (e.g. it is stolen and not password-protected while still having internet access) it can lead to a leak of your personal health information.
    We strongly encourage you to keep the setting off.
    `,
    mainTerms:
      'Пожалуйста, прочитайте и примите Политику конфиденциальности и Условия обслуживания, чтобы использовать приложение.',
    optionalSettings: 'Опциональные настройки конфиденциальности',
    productEmailNotify: 'Получать электронные письма об обновлениях',
    productEmailNotifyInfo:
      'Присылайте мне информацию о продуктах, услугах, обновлениях, предложениях или рекомендациях по электронной почте.',
    productPushNotify: 'Получать уведомления об обновлениях в приложении',
    productPushNotifyInfo:
      'Присылайте мне информацию о продуктах, услугах, обновлениях, предложениях или рекомендациях с помощью уведомлений в приложении.',
    restoreSubscription: 'Восстановить подписку',
    usageTracking: 'Отслеживание использования с помощью Google Analytics',
    usageTrackingInfo:
      'Собирайте информацию об экранах, которые я открываю в приложении, с помощью Google Analytics, чтобы помочь улучшить приложение.',
  },
  questionariesShared: {
    actions: `Обычно предлагаемые лечебные мероприятия`,
    actions0: `никаких`,
    actions1: `бдительное ожидание; повторить {{test}} при последующем визите`,
    actions2: `План лечения, c возможным психологическим консультированием, последующим наблюдением и/или фармакотерапией`,
    actions3: `Активное лечение с помощью фармакотерапии и/или психотерапии`,
    actions4: `Немедленное начало фармакотерапии и, в случае тяжелых нарушений или плохого ответа на терапию, ускоренное направление к специалисту по психическому здоровью для психотерапии и/или совместного лечения`,
    answer0: `Ни разу`,
    answer1: `Несколько дней`,
    answer2: `Более недели`,
    answer3: `Почти каждый день`,
    backToTests: 'Вернуться к тестам',
    disclaimer:
      'ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ: Несмотря на то, что {{test}} показывает высокую надежность и достоверность, он не может заменить медицинскую консультацию, диагностику или лечение у специалиста по психическому здоровью. Это приложение не дает медицинских рекомендаций. Оно предназначено только для информационных целей. Никогда не игнорируйте профессиональные медицинские советы при обращении за лечением из-за чего-то, что Вы прочитали в нашем приложении или на веб-сайте. Если Вы считаете, что Вам может потребоваться неотложная медицинская помощь, немедленно позвоните своему врачу или наберите 103 (или Ваш номер службы экстренной помощи).',
    extraQuestion1: `Насколько трудно Вам было работать, заниматься домашними делами или общаться с людьми из-за этих проблем?`,
    extraQuestionAnswer0: `Совсем не трудно`,
    extraQuestionAnswer1: `Немного трудно`,
    extraQuestionAnswer2: `Очень трудно`,
    extraQuestionAnswer3: `Чрезвычайно трудно`,
    intro1: `Как часто за последние 2 недели  Вас беспокоила следующая проблема?`,
    nextQuestion: `Следующий вопрос`,
    score: `Оценка {{test}}`,
    seeResult: 'Результат',
    severity0: `нет-минимальная`,
    severity1: `незначительная`,
    severity2: `умеренная`,
    severity3: `умеренно тяжелая`,
    severity4: `тяжелая`,
    testReminder: 'Напоминание о следующем тесте',
    testResult: 'Результат теста',
    seeTherapistsText:
      'Вы хотите найти специалистов в области психического здоровья, которые используют CopingCard в процессе лечения?',
    seeTherapists: 'Перейти к поиску терапевтов',
  },
  rnPaperDates: {
    close: 'Закрыть',
    dateIsDisabled: 'День не разрешается',
    mustBeBetween: (startDate: any, endDate: any) =>
      `Должно быть между ${startDate} - ${endDate}`,
    mustBeHigherThan: (date: any) => `Должно быть позже чем ${date}`,
    mustBeLowerThan: (date: any) => `Должно быть раньше чем ${date}`,
    next: 'Следующий',
    notAccordingToDateFormat: (inputFormat: any) =>
      `Формат даты должен быть ${inputFormat}`,
    pickDateFromCalendar: 'Выберите дату в календаре',
    previous: 'Предыдущий',
    save: 'Сохранить',
    selectMultiple: 'Выберите даты',
    selectRange: 'Выберите период',
    selectSingle: 'Выберите дату',
    typeInDate: 'Напечатайте дату',
  },
  screens: {
    abcModel: 'ABC-модель',
    abcModels: 'ABC-модель',
    anxiety: 'Тревожность',
    article: 'Статья',
    articles: 'Статьи',
    audio: 'Аудио',
    behaviorExperiment: 'Поведенческий эксперимент',
    behaviorExperiments: 'Поведенческие эксперименты',
    cardNotifications: 'Напоминания карточки',
    copingCard: 'Копинг-карточка',
    copingCards: 'Копинг-карточки',
    crisis: 'Кризис',
    urgentCrisis: 'Неотложный кризис',
    depression: 'Депрессия',
    downwardArrow: 'Падающая стрела',
    downwardArrows: 'Падающая стрела',
    exposures: 'Экспозиции',
    exposure: 'Экспозиция',
    exposureSituation: 'Ситуация экспозиции',
    gad7: 'GAD-7',
    generalSettings: 'Профиль',
    home: 'Главная',
    info: 'Инфо',
    insights: 'Инсайты',
    learn: 'Обучение',
    mammalianReflex: 'Нырятельный рефлекс млекопитающих',
    muscleRelaxation: 'Мышечная релаксация',
    technique54321: 'Техника 5-4-3-2-1',
    meditations: 'Медитации',
    notificationsSettings: 'Общие настройки',
    passwordReset: 'Смена пароля',
    phq9: 'PHQ-9',
    privacy: 'Политика конфиденциальности',
    privacySettings: 'Настройки конфиденциальности',
    saas: 'Соглашение об услугах для поставщиков медицинских услуг',
    settings: 'Настройки',
    signIn: 'Вход',
    subscription: 'Управление подпиской',
    viciousCycles: 'Поддерживающие циклы',
    viciousCycle: 'Поддерживающий цикл',
    terms: 'Условия использования',
    tests: 'Тесты',
    therapist: 'Специалист по психическому здоровью',
    therapists: 'Найти специалиста по психическому здоровью',
    therapistMessage: 'Связаться со специалистом',
    therapistsSharing: 'Обмен данными с терапевтами',
    thoughtsTesting: 'Тестирование мыслей',
    thoughtsTestingLocation: 'Местоположение события',
    toolKit: 'Набор инструментов',
    tools: 'Инструменты',
    video: 'Видео',
    worryTrees: 'Дерево принятия решений',
    worryTree: 'Дерево принятия решений',
  },
  sendTherapistMessageModal: {
    title: 'Отправить емейл специалисту',
    message: 'Напишите Ваше Сообщение',
  },
  settings: {
    chooseLanguage: 'Выберите язык',
    displayName: 'Отображаемое имя',
    displayNameHint:
      'Отображаемое имя получено от поставщика OAuth, который вы использовали для входа в систему, например Facebook или Google',
    email: 'Емейл адрес',
    firstName: 'Имя',
    lastName: 'Фамилия',
    profileInfo: 'Информация о пользователе',
    reminders: 'Напоминания',
    remindersCount: 'Всего напоминаний',
    saveSettings: 'Сохранить настройки',
    searchTimezone: 'Найдите страну или часовую зону',
    signOut: 'Выйти',
    userId: 'ID пользователя',
    userIdCopied: 'ID пользователя скопирован в буфер обмена',
  },
  signIn: {
    authEmailInUse: 'Этот адрес электронной почты уже используется!',
    authEmailInvalid: 'Этот адрес электронной почты недействителен!',
    authFacebookCancel: 'Пользователь отменил процесс входа/регистрации.',
    authNoToken: 'Произошла ошибка при получении токена доступа к {{token}}',
    authUserNotFound: 'Пользователь с данным емейл не найден!',
    authWeakPassword: 'Этот пароль недостаточно надежен!',
    authWrongPassword: 'Пароль недействительный!',
    checkEmailAgain: `Все еще не получили емейл или не уверены, что ввели правильный адрес электронной почты?`,
    checkEmailSpam: `Если Вы не получили от нас письмо в течение нескольких минут, проверьте папку со спамом, чтобы убедиться, что оно не там`,
    websiteOpenInstructions:
      'Если ссылка для подтверждения электронной почты откроется в браузере, а не в нашем мобильном приложении, Вам нужно будет закрыть и снова открыть мобильное приложение, чтобы завершить проверку и залогиниться в приложение.',
    checkEmailTitle: `Емейл с инструкциями по изменения пароля отправлен`,
    confirmEmailTitle: 'Емейл с подтверждением отправлен',
    confirmPassword: 'Подвердить пароль',
    email: 'Адрес электронной почты',
    facebookSignUp: 'Попдисаться с Facebook',
    facebooSignIn: 'Войти с Facebook',
    forgotPassword: 'Забыли пароль?',
    googleSignIn: 'Войти с Google',
    googleSignUp: 'Попдисаться с Google',
    noAccount: 'Еще нет аккаунта?',
    password: 'Пароль',
    passwordEmailHint:
      'Пожалуйста, укажите адрес электронной почты, на который Вы зарегистрированы, чтобы мы могли отправить Вам письмо для сброса пароля. Чтобы продолжить, нажмите ссылку в электронном письме на своем телефоне.',
    passwordHint:
      'Пароль должен состоять не менее чем из 8 символов. Добавление цифр, прописных букв и специальных символов приветствуется, но не требуется.',
    passwordResetFinished:
      'Мы обновили Ваш пароль. Вскоре Вы будете автоматически перенаправлены на экран входа в систему.',
    reset: 'Изменить',
    resetTitle: 'Поменять пароль',
    signIn: 'Войти',
    signUp: 'Подписаться',
    title: 'CopingCard приветствует Вас',
    tryAnother: 'Всё ещё нет емейла?',
    tryAnotherButton: 'Вернуться на экран логина',
  },
  states: [
    'Алабама',
    'Аляска',
    'Аризона',
    'Арканзас',
    'Калифорния',
    'Колорадо',
    'Коннектикут',
    'Делавэр',
    'Флорида',
    'Грузия',
    'Гавайи',
    'Айдахо',
    'Иллинойс',
    'Индиана',
    'Айова',
    'Канзас',
    'Кентукки',
    'Луизиана',
    'Мэн',
    'Мэриленд',
    'Массачусетс',
    'Мичиган',
    'Миннесота',
    'Миссисипи',
    'Миссури',
    'Монтана',
    'Небраска',
    'Невада',
    'Нью-Гемпшир',
    'Нью-Джерси',
    'Нью-Мексико',
    'Нью-Йорк',
    'Северная Каролина',
    'Северная Дакота',
    'Огайо',
    'Оклахома',
    'Орегон',
    'Пенсильвания',
    'Пуэрто-Рико',
    'Род-Айленд',
    'Южная Каролина',
    'Южная Дакота',
    'Теннесси',
    'Техас',
    'Юта',
    'Вермонт',
    'Вирджиния',
    'Вашингтон',
    'Вашингтон, округ Колумбия',
    'Западная Виргиния',
    'Висконсин',
    'Вайоминг',
  ],
  subscription: {
    feature1: 'Неограниченным напоминаниям',
    feature2: 'Неограниченным деревьям принятия решений',
    feature3: 'Неограниченным геолокационным уведомлениям',
    feature4: 'Неограниченным тестам на депрессию(PHQ-9) и тревожность(GAD-7)',
    feature5: 'Неограниченному прослушиванию медитаций',
    feature6: 'Неограниченному созданию поведенческих экспериментов',
    feature7:
      'Неограниченной длительности истории в разделе инсайты/статистика',
    feature8: 'Неограниченные использование инструмента "падающая стрела"',
    feature9: 'Неограниченному созданию экспозиций',
    feature10: 'Неограниченному созданию изображений с помощью ИИ',
    featureDescription1:
      'Бесплатным аккаунтам доступно только 9 активных напоминаний',
    featureDescription2:
      'Бесплатным аккаунтам доступна возможоност создать только 1 дерево принятия решений в день',
    featureDescription3:
      'Бесплатным аккаунтам доступно только 2 геолокационных уведомления',
    featureDescription4:
      'Бесплатные аккаунты могут проходить тесты PHQ-9 и GAD-7 только раз в 2 недели',
    featureDescription5:
      'Бесплатные аккаунты могут слушать медитацию только раз в 3 дня',
    featureDescription6:
      'Бесплатные аккаунты могут создавать поведенческие эксперименты только раз в 3 дня',
    featureDescription7:
      'Бесплатным аккаунтам доступен только 1 месяц истории/статистики',
    featureDescription8:
      'Бесплатным аккаунтам доступна возможоност создать использовать инструмент "падающая стрела" только 2 раза в день',
    featureDescription9:
      'Бесплатным аккаунтам доступна возможоность создать только 1 экспозицию раз в 3 дня',
    featureDescription10:
      'Бесплатным аккаунтам доступна возможоность создать только 3 изображения с помощью ИИ в день',
    month_four: 'месяца',
    month_one: 'месяц',
    month_other: 'месяцев',
    month_three: 'месяца',
    month_two: 'месяца',
    noSubscriptionText:
      'Мы не получили никаких покупок для восстановления из магазина приложений. Если вы считаете, что это ошибка, свяжитесь с нами по адресу support@copingcard.com.',
    noSubscriptionTitle: 'Нет покупок для восстановления',
    notNow: 'Позже',
    paymentInfo1:
      'Цены будут автоматически переведены в Вашу местную валюту. Оплата будет снята с учетной записи {{store}} при подтверждении покупки.',
    paymentInfo2:
      'Подписка автоматически продлевается, если она не будет отменена по крайней мере за 24 часа до окончания действия текущего периода.',
    paymentInfo3:
      'Плата за продление будет взиматься с Вашей учетной записи в течение 24 часов до окончания текущего периода.',
    paymentInfo4:
      'Вы можете управлять своими подписками и отменять их, перейдя в настройки своей учетной записи в {{store}} после покупки.',
    paymentInfo5:
      'Вы можете отключить автоматическое продление в любое время в настройках своей учетной записи {{store}}, но средств за неиспользованную часть срока не подлежат возврату.',
    paymentInfo6:
      'Любая неиспользованная часть бесплатного пробного периода, если она предлагается, будет аннулирована, когда пользователь приобретет подписку, где это применимо.',
    paymentMotivation:
      'Платная подписка позволяет нам покрывать наши расходы и добавлять функционал.',
    paywall_downgrade:
      'Пожалуйста, выберите подписку, чтобы Ваши платные функции и уведомления оставались включенными. После подписки Вы сохраните доступ к:',
    paywall_geo_notifications:
      'Много функционала, который мы предоставляем Вам, например, геолокационные напоминания, требует от нас оплаты другим компаниям и поставщикам. Пожалуйста, приобретите платную подписку, чтобы получить доступ к:',
    paywall_settings:
      'Много функционала, который мы предоставляем Вам, требует от нас оплаты другим компаниям и поставщикам. Пожалуйста, приобретите платную подписку, чтобы получить доступ к:',
    paywall_stats:
      'Много функционала, который мы предоставляем Вам, например, неограниченный доступ к статистике, требует от нас оплаты другим компаниям(например, хостинг провайдерам). Пожалуйста, приобретите платную подписку, чтобы получить доступ к:',
    paywall_tests_phq_9:
      'Много функционала, который мы предоставляем Вам, требует от нас оплаты другим компаниям и поставщикам. Пожалуйста, приобретите платную подписку, чтобы получить доступ к:',
    paywall_time_notifications:
      'Много функционала, который мы предоставляем Вам, например, напоминания по времени, требует от нас оплаты другим компаниям и поставщикам. Пожалуйста, приобретите платную подписку, чтобы получить доступ к:',
    paywall_meditations:
      'Бесплатные аккаунты могут слушать медитацию только раз в 3 дня. Купите платную подписку, чтобы получить неограниченный доступ к медитациям и другим премиум-функциям.',
    paywall_worry_trees:
      'Бесплатные аккаунты могут создавать только 1 дерево принятия решений в день. Купите платную подписку, чтобы получить неограниченный доступ к деревьям принятия решений и другим премиум-функциям.',
    paywall_behavior_experiments:
      'Бесплатные аккаунты могут создавать поведенческие эксперименты только раз в 3 дня. Купите платную подписку, чтобы получить неограниченный доступ к поведенческим экспериментам и другим премиум-функциям.',
    paywall_exposures:
      'Бесплатные аккаунты могут создавать экспозицию только раз в 3 дня. Купите платную подписку, чтобы иметь возможность создавать неограниченное количество экспозиций и другие премиум-функции.',
    paywall_ai_image_generation:
      'Бесплатные аккаунты могут создавать только 3 изображения, сгенерированные ИИ, в день. Обновите сейчас, чтобы иметь возможность создавать неограниченное количество изображений, сгенерированных искусственным интеллектом, и другие премиум-функции.',
    save: 'экономия',
    subscriptionFoundText:
      'Нам удалось найти для Вас действующую подписку. Ваш Премиум-доступ восстановлен.',
    subscriptionFoundTitle: 'Найдена действующая подписка',
    title: 'Разблокируйте дополнительный функционал',
    titlePremium: 'У Вас премиальный доступ',
    premiumIntro: 'Это предоставляет Вам полный доступ к:',
    year_four: 'года',
    year_one: 'год',
    year_other: 'лет',
    year_three: 'года',
    year_two: 'года',
    goBack: 'Вернуться',
  },
  therapist: {
    call: 'Позвонить',
    sendMessage: 'Отправить емейл',
    about: 'Обо мне',
    aceptingClients: 'Принимаю клиентов',
    mentalHealthRole: 'Роль в психическом здоровье',
    phone: 'Телефон',
    extension: 'Добавочный номер',
    therapyFormats: 'Форматы терапии',
    licensedInStates: 'Лицензии в штатах',
    licenseNumber: 'Номер лицензии',
    yearsOfPractice: 'Лет практики',
    education: 'Образование',
    languages: 'Языки',
    therapyApproaches: 'Терапевтические подходы',
    finances: 'Оплата',
    contPerSessionFrom: 'Стоимость сессии от',
    to: 'до',
    currency: 'Валюта',
    freeInitialConsulatation: 'Бесплатная первая консультация',
    slidingScale: 'Скользящая шкала',
    clients: 'Клиенты',
    modality: 'Модальность',
    age: 'Возраст',
    issues: 'Проблемы',
    linkToWebsite: 'Ссылка на профиль на другом ресурсе',
    acceptedInsurances: 'Принимаемые страховые компании',
    visitProfile: 'Перейти к профилю на другом ресурсе',
    messageSentSuccess: 'Сообщение успешно отправлено',
  },
  therapistsSearch: {
    info: 'Укажите свое местоположение, чтобы найти терапевтов.',
    country: 'Ваша страна',
    state: 'Ваш штат',
    learnMoreAndContact: 'Узнать больше и связаться',
    providerInvitationText:
      'Вы специалист по психическому здоровью и хотите, чтобы Вас отображали здесь? Пожалуйста, создайте бесплатную учетную запись провайдера по адресу:',
    noResults:
      'По Вашему запросу ничего не найдено. Пожалуйста, попробуйте позже.',
  },
  therapistsSharing: {
    title: 'Обмен данными с специалистами по психическому здоровью',
    inviteLinkOrToken:
      'У Вас есть ссылка или код приглашения от специалиста по психическому здоровью?',
    enterLinkOrToken: 'Ввести ссылку или код приглашения',
    dataYouCanShare:
      'Данные, которыми вы можете поделиться со своим специалистом в области психического здоровья, включают в себя: копинг-карты, данные тестов дисфункциональных мышлей, результаты тестов на депрессию и тревожность, а также статистику использования инструментов.',
    adminDataYouCanShare: `
Это позволит администраторам данной практики терапевта ПРОСМАТРИВАТЬ те же данные, которыми Вы делитесь со своим терапевтом.

Некоторые из ситуаций, когда это может быть полезно:
- Ваш терапевт в отпуске, и вам нужно поделиться своими данными с другим терапевтом в данной практике
- Вы хотите, чтобы Ваш терапевт мог получить помощь/второе мнение от других терапевтов в данной практике
- с Вами работают несколько терапевтов в данной практике
- переход от одного терапевта к другому в данной практике
`,
    currentTherapists: 'Вы делитись данными с',
    viewSharing: 'Разрешить просмотр моих данных',
    editSharing: 'Разрешить редактировать мои данные',
    adminViewSharing: 'Разрешить просмотр моих данных администраторам практики',
    adminEditSharing:
      'Разрешить редактировать мои данные администраторам практики',
    professional: 'Cпециалист по психическому здоровью',
    inviteTherapist: 'Пригласить своего психотерапевта',
    enterInviteModalTitle: 'Введите ссылку или код приглашения',
    enterInviteModaLabel: 'Ссылка или код приглашения',
    editingTip: `
Для этого разрешения требуется разрешение на просмотр.

Может быть очень полезно позволить Вашему психологу/психотерапевту редактировать данные на начальных этапах терапии, особенно во время сеансов телемедицины.
  
Ваш специалист по психическому здоровью сможет редактировать Ваши копинг-карточки и данные тестов дисфункционального мышления, чтобы помочь Вам быстрее освоить эти инструменты.
 
Вы сможете отменить доступ в любой момент.
  `,

    adminEditingTip: `
Это позволит администраторам данной практики терапевта РЕДАКТИРОВАТЬ те же данные, которые Вы разрешаете редактировать своему терапевту.
Для этого разрешения требуется разрешение на просмотр.

Некоторые из ситуаций, когда это может быть полезно:
- Ваш терапевт в отпуске, и вам нужно поделиться своими данными с другим терапевтом в данной практике
- Вы хотите, чтобы Ваш терапевт мог получить помощь/второе мнение от других терапевтов в данной практике
- с Вами работают несколько терапевтов в данной практике
- переход от одного терапевта к другому в данной практике
`,
    acceptInvite: 'Принять приглашение',
    pendingInvites: 'Приглашения в ожидании',
    expiresAt: 'Истекает',
    inviteFrom: 'Приглашение от',
    confirmRevoke: 'Подтвердите отмену доступа',
    editRevoke: `
Если Вы отмените доступ на редактирование своих данных, Ваш специалист по психическому здоровью больше не сможет быстро редактировать Ваши копинг-карточки или тесты дисфункциональных мыслей.
    `,
    viewRevoke: `
Если Вы отмените доступ на просмотр своих данных, Ваш специалист по психическому здоровью больше не сможет видеть, как Вы используете наше приложение и какой контент Вы создаете, что может затруднить оказание Вам помощи в терапии.

Это также отменит доступ на редкатирование.
    `,
    noTherapists:
      'Вы еще не предоставили доступ к своим данным ни одному специалисту в области психического здоровья.',
    noTokenTitle: 'Код не найден',
    noTokenText: `
Мы не нашли этот токен в нашей базе данных.

Возможно, срок его действия истек, или он был неверным.

Пожалуйста, попробуйте еще раз или свяжитесь со своим специалистом по психическому здоровью для получения нового кода/приглашения.
`,
    accessGranted: 'Вы успешно предоставили доступ к своим данным',
    accessUpdated: 'Вы успешно обновили доступ к своим данным',
    inviteSent: 'Ваше приглашение отправлено',
    alreadyAdded:
      'Вы уже предоставляете доступ к своим данным этому специалисту в области психического здоровья.',

    congratsOnPremium: 'Вы получили Премиум доступ!',
    congratsOnPremiumText: `
Поздравляем!

Ваш психолог/психотерапевт предоставил Вам полный доступ к премиум-функциям нашего приложения.

Мы надеемся, что это поможет Вам на Вашем пути к счастью и здоровью.
    `,
  },
  thoughtChoiceModal: {
    title: 'Создание карточки',
    text: 'Выберите, хотите ли вы создать карточку с помощью инструмента тестирования автоматических мыслей, или просто быстро создать карточку.',
    goToTesting: 'Перейти к тестированию мыслей',
    quickEdit: 'Быстро добавить карточку',
  },
  thoughtsTesting: {
    addEvidenceAgainst: 'Добавить опровергающий факт',
    addEvidenceFor: 'Добавить факт',
    automaticThought: 'Автоматическая мысль',
    automaticThoughtAdd: 'Добавить мысль',
    automaticThoughtBelief: 'Убеждённость',
    automaticThoughtDefinition:
      'Идея или зрительный образ, оценка события или себя в нем, которые появились у Вас вместе или незадолго до ухудшения состояния.',
    automaticThoughtName: 'Название',
    autoThoughtsTitle: 'Перечислите автоматические мысли',
    buttonNextText: 'Далее',
    chooseEmotions: 'Выберите эмоции',
    chooseFeelings: 'Выберите ощущения',
    cognitiveDistortions: 'Когнитивные искажения',
    cognitiveDistortionsDefinition:
      'Когнитивное искажение — это преувеличенный или иррациональный паттерн мышления.',

    cognitiveDistortionsPlaceholder: 'Выберите когнитивные искажения',
    congrats: 'Поздравляем!',
    congratsCardCreated: `В результате была создана новая Копинг-карта`,
    congratsCardsCreatedEnd: `новых Копинг-карт было создано в результате`,
    congratsCardsCreatedStart: ``,
    congratsIntro: `Вы закончили тестирование Ваших автоматических мыслей!`,
    congratsTextPlural: `Вы можете найти Копинг-карты для этих мыслей в разделе Копинг-карты нашего приложения.`,
    congratsTextSingle: `Вы можете найти Копинг-карту для этой мысли в разделе Копинг-карты нашего приложения.`,
    dateAndDuration: 'Дата и продолжительность события',
    day: 'Дневное напоминание',
    emotionDefinition:
      'ЭМОЦИИ — психический процесс, отражающий в форме непосредственного переживания (удовлетворения, радости, страха и т. д.) значимость действующих на человека явлений и ситуаций. Частью переживания эмоций являются телесные ощущения.',
    emotionsTitle: 'Укажите свои эмоции',
    evening: 'Вечернее напоминание',
    eventInfo: 'Событие, на фоне которого Ваше состояние заметно ухудшилось.',
    eventLabel: 'Опишите активирующее событие',
    eventLasted: 'продолжалось',
    eventStarted: 'началось',
    eventTitle: 'Активирующее событие',
    evidenceAgainst: 'Факт, опровергающий мысль',
    evidenceFor: 'Факт, поддерживающий негативную мысль',
    withEvidenceFor: 'Фактом в поддержку',
    withEvidenceAgainst: 'Фактом(ами) опровергающим(и) автоматическую мысль',
    alternativeThought: 'Альтернативное объяснение',
    alternativeThoughtInfo:
      'Приведите возможное альтернативное объяснение для фактов в поддержку автоматической мысли, если они есть.',
    alternativeThoughtTitle:
      'Каким ещё может быть объяснение этого факта, кроме Вашей изначальной мысли?',
    feelingsDefinition:
      'ОЩУЩЕНИЕ (англ. sensation ) — процесс чувственного познания отдельных свойств явлений и предметов объективного мира, т. е. процесс отражения прямого воздействия стимулов на органы чувств (рецепторы).',
    feelingsInBody: 'Ощущения в теле',
    feelingsTitle: 'Укажите общие ощущения',
    forThought: 'Для автоматической негативной мысли',
    with: 'С',
    having: 'Который может иметь',
    location: 'Место',
    locationReminders: 'Геолокационные напоминания',
    locationRemindersDisclosure:
      'Copingcard требуется сбор данных о точном местоположении, даже когда приложение закрыто или не используется, чтобы иметь возможность напоминать Вам о Ваших карточках, используя геолокацию.',
    locationRemindersWhy:
      'Геолокационные напоминания могут помочь Вам вспомнить реалистичные мысли, когда Вы приближаетесь к (200м) и/или покидаете какое-то место. ',
    morning: 'Утреннее напоминание',
    optionalData: 'Дополнительные сведения',
    participants: 'Участники',
    photos: 'Фото или изображение',
    photosDialogueTitle: 'Выберите источник изображения',
    rateEmotions: 'Оцените силу эмоций',
    realisticThought: 'Реалистичная мысль или идея',
    realisticThoughtsDefinition:
      'Суждения о событии или себе , которые вытекают из приведённых Вами фактов или (в случае невозможности проверить изначальную мысль фактами) помогают Вам чувствовать себя лучше.',
    realisticThoughtsTitle: 'Реалистичные и полезные мнения',
    reminderLabel: 'Выберите время напоминания',
    reminders: 'Напоминания',
    remindersWhy:
      'Прочтение автоматической и реалистичной мысли несколько раз в день значительно снижает эффект автоматических негативных мыслей. Мы создали 3 напоминания, чтобы помочь Вам прочитать эти мысли. Здесь Вы можете настроить время на более удобное для Вас.',
    resultBelief: 'Насколько Вы верите в свои первоначальные негативные мысли?',
    resultEmotionStrength:
      'Насколько сильна основная первоначальная негативная эмоция?',
    resultsTitle: 'Результат',
    selectEnd: 'Выберите время завершения события',
    selectStart: 'Выберите время начала события',
    thoughtForForThought: 'для мысли',
    thoughtsAgainstDefinition:
      'Преимущественно доступные чувственному восприятию и проверке конкретные явления действительности (факты), которые опровергают проверяемую мысль.',
    thoughtsAgainstTitle: 'Факты, опровергающие негативную мысль',
    thoughtsForDefinition:
      'Преимущественно доступные чувственному восприятию и проверке конкретные явления действительности (факты), которые свидетельствуют о том, что эта мысль является верной.',
    thoughtsForTitle: 'Факты, доказывающие негативную мысль',
    timeReminders: 'Напоминания по времени',
    validationErrors:
      'Похоже, есть некоторые ошибки. Пожалуйста, исправьте их, прежде чем продолжить, или обратитесь в службу поддержки, если  не понятно, как их исправить.',
  },
  validators: {
    email: 'должен быть действительным адресом электронной почты',
    regex: 'должно соответствовать следующему: {{regex}}',
    required: 'является обязательным полем',
    retypePassword: 'пароли должны совпадать',
    stringMax: 'должно быть не более {{max}} символов',
    stringMin: 'должно быть не менее {{min}} символов',
    url: 'Пожалуйста, введите корректный URL',
  },
  viciousCycles: {
    title: 'Поддерживающие циклы',
    info: 'Поддерживающие циклы',
    definition: `В когнитивно-поведенческой терапии поддерживающие циклы относятся к паттернам негативных мыслей, эмоций и поведения, которые поддерживают или усугубляют определенную проблему. Эти циклы включают в себя самовоспроизводящуюся петлю, в которой негативные убеждения и эмоции приводят к неадекватному поведению, которое, в свою очередь, усиливает негативные убеждения и эмоции. Результатом является ухудшение проблемы, а также увеличение интенсивности негативных мыслей, эмоций и поведения, связанных с ней. Цель когнитивно-поведенческой терапии состоит в том, чтобы выявить и разорвать эти порочные круги, помогая клиентам разработать более адаптивные убеждения и стратегиии преодоления трудностей, которые в конечном итоге могут привести к позитивным изменениям и улучшению самочувствия.`,
    edit: 'Редактировать цикл',
    totalCount: 'Всего: {{count}}',
    create: 'Создать поддерживающий цикл',
    advancedWarning1: `Это продвинутая техника когнитивно-поведенческой терапии. Если Вы не знаете, как ее использовать, может быть лучше `,
    advancedWarning2: ` использовать нашу Платформу или `,
    advancedWarning3: ` того, кто уже использует ее в терапии.`,
    inviteTherapist: `пригласить Вашего терапевта`,
    deleteSuccess: 'Поддерживающий цикл успешно удален',
    therapistFind: 'найти',
  },
  viciousCycle: {
    title: 'Поддерживающий цикл',
    selectTemplate: 'Выберите шаблон (опционально)',
    cycleName: 'Название',
    thoughts: 'Мысли, действия или факты',
    relations: 'Приводит к',
    influences: 'Влияет на:',
    tempalteLabel: 'Использовать шаблон (опционально)',
    saveSuccess: 'Поддерживающий цикл успешно сохранен',
    saveError: 'Ошибка при сохранении поддерживающего цикла',
    addField: 'Добавить мысль, действие или факт',
  },
  shared: {
    genderOptions: ['Мужской', 'Женский', 'Другой'],
    preferredPronounsOptions: ['Он/Его', 'Она/Её', 'Они/Их'],
    abcModel: {
      title: 'ABC-модель',
      saveSuccess: 'ABC-модель успешно сохранена',
      description: `ABC-модель техника Когнитивно-поведенческой терапии (КПТ) - это инструмент, который помогает людям понять связь между их мыслями, эмоциями и поведением. Модель основана на предположении, что источником эмоционального дискомфорта являются не сами события, а наше восприятие этих событий. Модель ABC состоит из трех основных компонентов: 

      A - Активирующее событие: ситуация или триггер, вызывающий мысль или эмоцию. 
      
      B - Когнитивная оценка: мысли или трактовки, которые мы делаем об активирующем событии. 
      
      C - Реакция: эмоциональные, физиологические и поведенческие реакции, возникающие из-за наших когниций. 
      
      Цель ABC-модели состоит в том, чтобы помочь людям определить и противостоять своим иррациональным или дисфункциональным убеждениям и заменить их более здоровыми и адаптивными мыслями, что способствует более позитивным эмоциональным и поведенческим результатам.
      `,
      a: 'A - Активирующее событие',
      aPlaceholder: 'Что произошло?',
      b: 'B - Когнитивная оценка',
      bPlaceholder: 'Что Вы подумали?',
      c: 'C - Реакция',
      behaviors: 'Поведение',
      behaviorsPlaceholder: 'Как Вы себя повели?',
      emotions: 'Эмоции',
      emotionsPlaceholder: 'Какие эмоции Вы испытали?',
      sensations: 'Физические ощущения',
      sensationsPlaceholder: 'Какие физические ощущения Вы испытали?',
    },
    abcModels: {
      title: 'ABC-модель',
      create: 'Создать ABC-модель',
      edit: 'Редактировать ABC-модель',
    },
    behaviorExperiment: {
      title: 'Поведенческий эксперимент',
      description: `Поведенческий эксперимент — это метод, обычно используемый в когнитивно-поведенческой терапии (КПТ) для проверки достоверности убеждений и предположений пациента. Цель эксперимента — собрать эмпирические данные, которые бросают вызов дисфункциональным мыслям и поведению пациента и заменяют их более реалистичными и адаптивными.

В поведенческом эксперименте терапевт и пациент совместно разрабатывают эксперимент, чтобы проверить предположения или убеждения пациента. Например, если пациент боится публичных выступлений, эксперимент может включать в себя короткое выступление перед небольшой группой доверенных лиц. Терапевт и пациент наблюдают и оценивают переживания пациента, включая любые физиологические или эмоциональные реакции и любую полученную обратную связь.

Проведя эксперимент и проанализировав результаты, пациент может получить новое представление о своих мыслях и поведении и разработать более адаптивные стратегии преодоления. Поведенческие эксперименты часто используются в сочетании с другими методами когнитивно-поведенческой терапии, такими как когнитивная реструктуризация, чтобы помочь пациентам бросить вызов своим дисфункциональным моделям мышления и изменить их.
      `,
      purpose: 'Цель эксперимента',
      purposeRequired: 'Цель эксперимента обязательна',
      purposePlaceholder: 'Что Вы хотите протестировать?',
      purposes: [
        'Выработка формулирования/концептуализации',
        'Проверка негативных когниций',
        'Создание и проверка новых точек зрения',
      ],
      purposesTip: `Выработка формулирования/концептуализации: эти эксперименты помогают сформулировать роблемы пациента, особенно в начале терапии, когда понимание еще только развивается. Эти эксперименты дают терапевтам и клиентам возможность наблюдать за тонкими аспектами поведения и мышления пациента, которые могут быть недоступны только через словесные отчеты, основанные на памяти.
       Проверка негативных когниций: проверка негативных когниций, снижение доверия к устаревшим и бесполезным негативным автоматическим мыслям, дисфункциональным предположениям и глубинным убеждениям. Эти эксперименты позволяют пациентам увидеть обоснованность новых, более адаптивных точек зрения через непосредственный личный опыт.
       Создание и проверка новых точек зрения: создание и укрепление новых, более реалистичных и более полезных мыслей, предположений и убеждений.
      `,
      design: 'Структура эксперимента',
      designRequired: 'Структура эксперимента обязательна',
      designs: [
        'Эксперимент, проверяющий гипотезу(ы)',
        'Поисковый эксперимент',
      ],
      designsTip: `Эксперименты по проверке гипотез пытаются найти ответы на вопросы, похожие на «Правда ли, что…?».
      Поисковые эксперименты — это более открытые, исследовательские, ориентированные на открытия вопросы, подобные «Что произойдет, если…». . . ?`,
      hypotheses: 'Гипотезы',
      hypothesisType: 'Количество гипотез',
      hypothesisTypeVariants: [
        'Проверка гипотезы А',
        'Сравнение гипотез А и Б',
        'Проверка гипотезы Б',
      ],
      hypothesisTypeVariantsTip: `
      Тестирование гипотезы А: проверяет достоверность текущей негативной мысли или убеждения (гипотеза А).
      Сравнение гипотез А и Б: проверяет достоверность текущей негативной мысли (гипотеза А) и новой, возможно полезной или обоснованной мысли (гипотеза Б).
      Тестирование гипотезы Б: проверяет достоверность новой, возможно полезной мысли (гипотеза Б).
      `,
      type: 'Тип эксперимента',
      typeVariants: ['Активный эксперимент', 'Наблюдательный эксперимент'],
      activeSubtype: 'Подтип активного эксперимента',
      activeSubtypes: [
        'В реальных ситуациях',
        'В смоделированных ситуациях (ролевая игра)',
      ],
      observationalSubtype: 'Подтип наблюдательного эксперимента',
      observationalSubtypes: [
        'Прямое наблюдение (моделирование)',
        'Опрос',
        'Информация, полученная из других источников',
      ],
      settings: 'Условия',
      timeAndPlace: 'Время и место',
      time: 'Дата и время',
      place: 'Место',
      people: 'Люди',
      peoplePlaceholder: 'Кто будет участвовать в эксперименте?',
      resources: 'Ресурсы и инструменты',
      resourcesPlaceholder: 'Какие ресурсы Вам понадобятся?',
      survey: 'Опрос',
      surveyPlaceholder:
        'Информация об опросе, который Вы могли бы использовать.',
      safetyBehaviors: 'Охранительное(избегающее) поведение',
      safetyBehaviorsPlaceholder:
        'Какое охранительное(избегающее) поведение Вы попробуете не использовать?',
      safetyBehaviorsTip: `Охранительное поведение — это действия, предпринимаемые для уменьшения негативных эмоций в ситуациях, в которых они возникают. Подавление такого поведения имеет решающее значение в поведенческих экспериментах, чтобы предотвратить их влияние на эксперимент и процесс терапии.
       Если охранительное поведение сохраняется, оно может укрепить убеждение человека в том, что стимул или ситуация опасны, и что помогает именно безопасное поведение, тем самым поддерживая или даже усиливая негативные эмоции.
        `,
      safetyAndCoping: 'Безопасное поведение и копинг-стратегии',
      selectOrType: 'Выберите или введите',

      helpfulCopingStrategies: 'Полезные копинг-стратегии',
      helpfulCopingStrategiesPlaceholder:
        'Какие копинг-стратегии Вы можете использовать, чтобы справиться с ситуацией?',
      hypothesisA: 'Гипотеза А',
      hypothesisAPlaceholder:
        'Какую мысль или убеждение Вы хотели бы проверить?',
      hypothesisAStrength: 'Как сильно Вы верите в гипотезу А?',
      hypothesisATip:
        'Гипотеза А — это текущая дисфункциональная мысль или убеждение, которую Вы хотите проверить.',
      hypothesisAConfirmationCriteria:
        'Критерии/признаки подтверждения Гипотезы А',
      hypothesisB: 'Гипотеза Б',
      hypothesisBPlaceholder:
        'Какую правдоподобную и полезную альтернативную мысль или убеждение Вы хотели бы проверить?',
      hypothesisBStrength: 'Как сильно Вы верите в гипотезу Б?',
      hypothesisBTip:
        'Гипотеза Б — это новая, возможно полезная мысль или убеждение, которую Вы хотите проверить.',
      hypothesisBConfirmationCriteria:
        'Критерии/признаки подтверждения Гипотезы Б',
      experiment: 'Эксперимент',
      expremientPlaceholder: 'Как Вы планируете проверить гипотезу?',
      prediction: 'Прогноз',
      expectedEmotions: 'Ожидаемые эмоции',
      expectedEmotionsPlaceholder:
        'Какие эмоции Вы ожидаете испытать во время эксперимента?',
      predictionPlaceholder: 'Что, по Вашему мнению, должно произойти?',
      expectedFeelings: 'Ожидаемые физические ощущения',
      behaviors: 'Ожидаемая реакция среды/людей',
      behaviorsPlaceholder:
        'Как отреагировало окружение, другие люди и Вы сами?',
      physicalReactions: 'Физические реакции',
      physicalReactionsPlaceholder:
        'Как Вы думаете, как отреагирует Ваше тело?',
      otherPredictions: 'Другие прогнозы',
      otherPredictionsPlaceholder: 'Что ещё Вы можете предположить?',
      outcome: 'Результат',
      outcomePlaceholder: 'Что произошло?',
      actualBehavior: 'Фактическая реакция среды/людей',
      actualBehaviorPlaceholder: 'Как Вы действительно повели себя?',
      actualEmotions: 'Фактические эмоции',
      actualFeelings: 'Фактические физические ощущения',
      newThought: 'Новая мысль или убеждение',
      newThoughtPlaceholder:
        'На основе результатов эксперимента, какая у Вас новая мысль?',
      evaluation: 'Оценка',
      newLearnings: 'Новые знания и понимания',
      newLearningsPlaceholder: 'Что Вы узнали в результате эксперимента?',
      meaningForOriginalBelief: 'Как это влияет на первоначальное убеждение?',
      meaningForOriginalBeliefPlaceholder:
        'Что это означает для Вашего первоначального убеждения или предсказания?',
      oldThoughtStrength:
        'Насколько сильно Вы верите в свою старую мысль или убеждение после эксперимента?',
      newThoughtStrength:
        'Насколько сильно Вы верите в свою новую мысль или убеждение?',
      generateGptSuggestions: 'Сгенерировать идеи с помощью ChatGPT',
      generateGptSuggestionsMore: 'Сгенерировать другой вариант',
      generateGptSuggestionsTip: ``,
      generating:
        'Генерация идей с помощью ChatGPT. Это может занять некоторое время...',
      generationError: 'Ошибка при генерации идей',
      patientReflections: 'Наблюдения пациента',
      patientReflectionsPlaceholder: 'Введите наблюдения пациента',
      therapistReflections: 'Наблюдения терапевта',
      therapistReflectionsPlaceholder: 'Введите наблюдения терапевта',
      followUp: 'Последующие эксперименты',
      followUpExperimentsIdeas: 'Идеи последующих экспериментов',
      followUpExperimentsIdeasPlaceholder:
        'Введите идеи последующих экспериментов',
      generatedWarning: `Предупреждение!
      
      Некоторый контент ниже был сгенерирован ChatGPT.
      
      Вы, как специалист в области психического здоровья, ответственны за просмотр и редактирование контента перед его использованием с Вашими клиентами.`,
      generatedVariant: 'Сгенерированный вариант',
      saveSuccess: 'Эксперимент успешно сохранен',
      purposeSelectText: 'Выберите цель эксперимента',
      designSelectText: 'Выберите структуру эксперимента',
      hypothesisTypeSelectText: 'Выберите количество гипотез',
      typeSelectText: 'Выберите тип эксперимента',
      activeSubtypeSelectText: 'Выберите подтип активного эксперимента',
      observationalSubtypeSelectText:
        'Выберите подтип наблюдательного эксперимента',
      jumpToOutcome: 'Перейти к результату',
    },
    behaviorExperiments: {
      title: 'Поведенческие эксперименты',
      initialThoughtOrBelief: 'Начальная мысль или убеждение',
      edit: 'Редактировать эксперимент',
      create: 'Создать эксперимент',
    },
    exposure: {
      types: [
        {
          name: 'Градуированная экспозиция',
          description:
            'Градуированная экспозиция, также известная как систематическая десенсибилизация, - это метод, используемый в когнитивно-поведенческой терапии (КПТ), который помогает людям с фобиями или тревожными расстройствами постепенно сталкиваться с и преодолевать свои страхи. Этот метод предполагает создание иерархии ситуаций или стимулов, вызывающих страх (или другую негативную эмоцию), начиная с наименее тревожных и заканчивая наиболее тревожными. Затем человек подвергается воздействию каждого элемента в иерархии по очереди, практикуя техники расслабления или совладания со страхом. Постепенно сталкиваясь со своими страхами (или другими эмоциями) в контролируемой и структурированной среде, человек учится контролировать и уменьшать свою тревогу, что в конечном итоге приводит к исчезновению нежелательной реакции.',
        },
        {
          name: 'Экспозиция с предотвращением реакции',
          description:
            'Экспозиция с предотвращением реакции (ЭПР) - это форма когнитивно-поведенческой терапии (КПТ), которая в основном используется для лечения обсессивно-компульсивного расстройства (ОКР) и смежных тревожных расстройств. ЭПР включает постепенную экспозицию страховым ситуациям или стимулам, предотвращая при этом вовлечение человека в компульсивное или избегающее поведение. Цель ЭПР - помочь человеку противостоять своим страхам, снизить тревогу и прервать цикл навязчивых мыслей и компульсий. Через повторное воздействие и практику люди учатся переносить тревогу и сопротивляться вовлечению в компульсивное поведение, что в конечном итоге приводит к уменьшению интенсивности и частоты их симптомов.',
        },
      ],
      avoidances: [
        'Никогда не избегаю',
        'Избегаю иногда',
        'Избегаю в половине случаев',
        'Избегаю часто',
        'Избегаю всегда',
      ],
      title: 'Экспозиция',
      titlePlural: 'Экспозиции',
      chooseExpositionType: 'Выберите тип экспозиции',
      type: 'Тип экспозиции',
      typeRequired: 'Тип экспозиции - обязателено поле',
      fear: 'Страх или тревога',
      fearPlaceholder: 'О чем Вы беспокоитесь или чего боитесь?',
      obsession: 'Обсессия',
      obsessionPlaceholder: 'ОБСЕССИЯ: Чего Вы боитесь или о чём беспокоитесь?',
      compulsion: 'Компульсия',
      compulsionPlaceholder:
        'КОМПУЛЬСИЯ: Что Вы обычно делаете, чтобы уменьшить тревогу?',
      compulsionTip:
        'Какого охранительного(избегающего) поведение поведения Вы обычно придерживаетесь и хотите уменьшить/прекратить?',
      situationsOrStimuliHierarchy: 'Иерархия ситуаций или стимулов',
      generateHierarchy: 'Сгенерировать иерархию с помощью ChatGPT',
      situationOrStimulus: `Ситуация или стимул`,
      situationOrStimulusTitle: 'Название ситуации или стимула',
      situationOrStimulusTitlePlaceholder: 'Какова ситуация или стимул?',
      situationOrStimulusDescription: 'Описание',
      situationOrStimulusDescriptionPlaceholder: 'Опишите ситуацию или стимул',
      imagePrompt: 'Подсказка для генерации изображения с помощью ИИ',
      imagePromptTip:
        'Подсказка для ИИ (Dalle 3), которую Вы можете использовать для создания изображений для ситуации или стимула',
      imagePromptPlaceholder:
        'Введите подсказку для гернерации изображения с помощью ИИ Dalle 3',
      imageDeleteWarning: 'Вы уверены, что хотите удалить это изображение?',
      addSituationOrStimuli: 'Добавить ситуацию или стимул',
      sud: 'Уровень напряжения и тревоги (SUD)',
      sudTip: 'Насколько неприятна ситуация или стимул? (0-100)',
      saveSuccess: 'Экспозиция успешно сохранена',
      situationOrStimulusSaveSuccess: 'Ситуация или стимул успешно сохранены',
      rank: 'Ранг',
      editTitleAndDescription: 'Редактировать название и описание',
      doYouAvoid: 'Вы избегаете эту ситуацию или стимул?',
      worstThingThatCouldHappen: 'Худшее, что может произойти',
      avoiding: 'Избегание',
      situationOrStimuliImages: 'Изображения ситуации или стимула',
      generateImageUsingAI: 'Сгенерировать изображение с помощью ИИ',
      generateImageUsingAIMobile: 'Сгенерировать изображение',
      deleteAvailable120Minutes:
        'Удаление доступно для терапевтов только в течение 2 часов после создания, чтобы предотвратить удаление контента, созданного клиентом.',
      startExposure: 'Начать новую экспозицию',
      newExposure: 'Новая экспозиция',
      anticipated: 'Ожидаемые',
      reactionNotes: 'Заметки',
      addReaction: 'Добавить реакцию',
      exposureGraph: 'График экспозиций',
      expositionDuration: 'Длительность экспозиции',
      highestSud: 'Максимальный SUD',
      endSud: 'Конечный SUD',
      duration: 'Длительность',
      imageGenerationError: 'Ошибка при генерации изображения',
      contentFilter:
        'Нам не удалось сгенерировать изображение из-за ограничений фильтра контента OpenAI. Пожалуйста, попробуйте еще раз с другим запросом, который является более нейтральным.',

      safetyBehaviorsToAvoid:
        'Охранительные поведения (компульсии), которые нужно не испльзовать',

      edit: 'Редактировать экспозицию',

      create: 'Создать экспозицию',
      upsertExposureLoading: 'Сохранение экспозиции...',
      stimuludDeleteWarning:
        'Вы уверены, что хотите удалить ситуацию или стимул? Вся связанная с ним информация будет удалена.',
      editDetails: 'Редактировать детали',
    },
    downwardArrows: {
      downwardArrows: 'Падающая стрела',
      initialThought: 'Начальная мысль',
      totalCount: 'Всего: {{count}}',
      edit: 'Редактировать падающую стрелу',
      create: 'Создать падающую стрелу',
    },
    downwardArrow: {
      title: 'Падающая стрела',

      saveSuccess: 'Падающая стрела успешно сохранена',
      thought: 'Мысль или убеждение',
      question:
        'Если бы это было правдой, меня бы это беспокоило, потому что это означало бы...',
      description: `Техника «Падающая стрела» используется для перехода от поверхностных уровней мыслей (негативных автоматических мыслей) к более глубоким уровням (промежуточным или глубинным убеждениям), лежащим в основе дистресса или проблемного поведения человека.

Она включает в себя постановку ряда вопросов, направленных на выявление основных предположений, убеждений или страхов, которые управляют негативными мыслями человека.

Техника получила свое название от визуальной метафоры «копать глубже» в убеждения человека, начиная с поверхностной мысли и используя серию вопросов, чтобы добраться до первопричины негативной мысли.

Сократический диалог — ключевой компонент техники «Падающая стрела». В нем терапевт задает вопросы в непредвзятой, любознательной и исследовательской манере, побуждая пациента более внимательно исследовать свои мысли и убеждения.

Терапевт просит пациента определить свои негативные автоматические мысли, например: «Я неудачник». Затем терапевт задает ряд вопросов, чтобы копнуть глубже, например: «Что для вас значит быть неудачником?» и «Почему это важно для вас?» Продолжая задавать такие вопросы, терапевт может помочь пациенту раскрыть основные убеждения и предположения, которые подпитывают его негативные мысли.
      `,
    },
    worryTrees: {
      worryAbout: 'Беспокойство',
      isReal: 'Реально?',
      notSolvable: 'Не решаемо',
      options: 'Варианты решения',
      immediateAction: 'Немедленное действие',
      plan: 'План действий',
      refocusActivity: 'Активность переключения',

      definition:
        'Инструмент «Дерево принятия решений» в когнитивно-поведенческой терапии представляет собой систематический подход к осмыслению и лечению генерализованного тревожного расстройства (ГТР) путем разбиения сложных, вызывающих тревогу ситуаций на более мелкие, более управляемые части. Этот инструмент помогает клиентам с ГТР избежать «аналитического паралича» и принять меры, различая тип беспокойства, с которым они сталкиваются, и определяя соответствующие ответы. Используя этот инструмент, клиентам предлагается рассматривать свои проблемы как проблемы, которые можно преодолеть с помощью навыков решения проблем, а не зацикливаться на беспокойстве и тревожности. Инструмент «Дерево принятия решений» направлен на то, чтобы сообщить клиентам, что есть лучшая альтернатива беспокойству и что они могут улучшить свои навыки решения проблем, чтобы эффективно справляться со своей тревогой.',
      deleteSuccess: 'Дерево принятия решений успешно удалено',
      view: 'Просмотреть дерево',
      create: 'Создать дерево принятия решений',
    },
    worryTree: {
      worryTree: 'Дерево тревоги',
      worryTrees: 'Дерево тревоги',
      worryTreesTitle: 'Дерево принятия решений',
      title: 'Дерево принятия решений',
      worryAbout: 'О чем я беспокоюсь?',
      isReal: 'Это реальная проблема?',
      isDoable: 'Могу я что-нибудь сделать, чтобы решить эту проблему?',
      isDoableNow: 'Могу я что-нибудь сделать прямо сейчас?',
      listOptions: 'Перечислите варианты решения проблемы.',
      developPlan:
        'Спланируйте, что Вы можете сделать и когда Вы это сделаете.',
      decision: 'Решение',
      selectedActivity: 'Выбранная активность',
      refocusTitle:
        'Отпустите тревожную мысль. Измените фокус Вашего внимания.',
      doneTitle: 'Отпустите тревожную мысль. Сделайте выбранное действие.',
      immediateAction: 'Назовите то, что Вы собираетесь сделать прямо сейчас.',
      saveSuccess: 'Дерево принятия решений успешно сохранено',
      savedForReference:
        'Ваше дерево принятия решений было сохранено для дальнейшего использования.',
      refocusIdeas: [
        {
          title: 'Практиковать глубокое дыхание',
          info: 'Делайте медленные, глубокие вдохи и выдохи, чтобы снизить частоту сердечных сокращений и успокоить разум.',
        },
        {
          title: 'Заняться физической активностью',
          info: 'Занимайтесь спортом или прогуляйтесь, чтобы помочь высвободить эндорфины и уменьшить стресс.',
        },
        {
          title: 'Практиковать осознанность',
          info: 'Сосредоточьтесь на настоящем моменте, обращая внимание на свои мысли, чувства и ощущения без осуждения.',
          mobileExtraInfo:
            'Вы можете перейти в раздел «Медитации», следуя по ссылке ниже.',
          link: 'screens.meditations',
        },
        {
          title: 'Заняться творческой деятельностью',
          info: 'Займитесь делом, требующим полного внимания, например рисованием или игрой на музыкальном инструменте.',
        },
        {
          title: 'Послушать музыку',
          info: 'Послушайте успокаивающую музыку или любимые песни, чтобы отвлечься.',
        },
        {
          title: 'Практиковать визуализацию',
          info: 'Визуализируйте мирную сцену или ситуацию, которая помогает вам чувствовать себя спокойно и расслабленно.',
        },
        {
          title: 'Сделать запись в дневнике',
          info: 'Запишите свои мысли и чувства, чтобы упорядочить их и обрести ясность.',
        },
        {
          title: 'Решите головоломку',
          info: 'Решите сложную головоломку или игру, чтобы переключить внимание.',
        },
        {
          title: 'Практиковать благодарность',
          info: 'Сосредоточьтесь на вещах в своей жизни, за которые Вы благодарны, и выразите благодарность за них.',
        },
        {
          title: 'Обратиться за профессиональной помощью',
          info: 'Если Ваше беспокойство непрекращается и мешает Вашей повседневной жизни, подумайте о том, чтобы обратиться за помощью к специалисту в области психического здоровья.',
          mobileExtraInfo:
            'Вы можете перейти в раздел «Поиск терапевтов», следуя по ссылке ниже.',
          link: 'screens.therapists',
          root: 'screens.tests',
        },
        {
          title: 'Практикуйте прогрессирующую мышечную релаксацию',
          info: '',
          mobileExtraInfo:
            'Вы можете найти видео и инструкции о том, как сделать это, следуя по ссылке ниже.',
          link: 'screens.muscleRelaxation',
          root: 'screens.crisis',
        },
      ],
    },
  },
  audios: [
    {
      url: 'meditation-1-1-ru.m4a',
      title: 'Дышащее тело',
      duration: 374,
    },
    {
      url: 'meditation-4-ru.m4a',
      title: 'Безопасное место',
      duration: 345,
    },
    {
      url: 'meditation-2-ru.m4a',
      title: 'Песочные часы',
      duration: 478,
    },
    {
      url: 'meditation-3-ru.m4a',
      title: 'Звуки и мысли',
      duration: 403,
    },
    {
      url: 'meditation-5-ru.m4a',
      title: 'Триангуляция',
      duration: 429,
    },
    {
      url: 'meditation-6-ru.m4a',
      title: 'Тренировка "наблюдающего Я"',
      duration: 567,
    },
  ],
  videos: [
    {
      id: 'fHt-dkS-r6U',
      name: 'Доказательная психотерапия. Виды когнитивно-поведенческой психотерапии.',
    },
    {
      id: 'ndcA-AHHEHk',
      name: 'Когнитивно-поведенческая психотерапия (КПТ). Методология и этика',
    },
    {
      id: 'uQXBmyBK0j4',
      name: 'КПТ. Взаимосвязь когнитивных и эмоционнальных процессов.',
    },
    {
      id: '1AafsRPpvKE',
      name: 'Глубинные убеждения в КПТ',
    },
    {
      id: 'lIKOXr109Jg',
      name: 'Схема-терапия',
    },
    {
      id: 'fC2EbupiGl4',
      name: 'Посттравматическое стрессовое расстройство (ПТСР)',
    },
    {
      id: 'oEiAw94jq-g',
      name: 'Расстройства личности и когнитивно-поведенческая психотерапия (КПТ)',
    },
  ],
  cognitiveDistorions: {
    10: 'Дихотомическое мышление (черно-белое, поляризованное мышление)',
    20: 'Катастрофизация',
    30: 'Сверхгенерализация (сверхобобщение)',
    40: 'Преуменьшение позитивного (обесценивание)/преувеличение негатива',
    50: 'Долженствование (сверхвысокие стандарты, «я должен»)',
    60: 'Навешивание ярлыков и стереотипов',
    70: 'Персонализация (приняте на свой счет, атрибутивность)',
    80: 'Субъективная аргументация (эмоциональное обоснование)',
    90: 'Произвольное умозаключение (нелогичное мышление)',
    100: 'Селективное абстрагирование (избирательное внимание)',
    110: 'Туннельное зрение',
    120: 'Чтение мыслей, ясновидение (предвидение)',
  },
  oldVersionModal: {
    appOutdatedTitle: 'Приложение устарело',
    yourVersionIsOutdated:
      'Ваша версия приложения устарела и её нужно обновить',
    installWarning:
      '⚠️ Если кнопка ниже не перенаправит Вас в App/Play Store автоматически, обновите приложение вручную.',
    getUpdate: 'Обновить',
  },
};
