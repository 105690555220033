import { Dropdown, Icons, Menu, Space } from '@pankod/refine-antd';
import {
  useGetLocale,
  useLogin,
  useNavigation,
  useRegister,
  useSetLocale,
  useTranslate,
} from '@pankod/refine-core';
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from 'antd';
import { useEffect, useMemo } from 'react';
import qs from 'qs';
import { getValidators } from '@cc/schema';
import routerProvider from '@pankod/refine-react-router-v6';
import { RuleObject } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { getYupSyncValidator, LOCAL_STORAGE_KEYS } from '../../helpers';
import { useFormSubmitDisabled } from '../../hooks';
import { resources } from '../../i18n';
import { ILoginArgs, IRegisterArgs } from '../../providers';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  containerStyles,
  googleButton,
  imageContainer,
  langaugeSelector,
  languageDropdown,
  layoutStyles,
  titleStyles,
} from './styles';
import { firebaseApp } from '../../authProvider';
import { LoggedOutFooter } from '../../components/layout/loggedOutFooter';

const { DownOutlined, GoogleOutlined } = Icons;

const { Link, Text, Title } = Typography;
export interface ISignupForm {
  email: string;
  password: string;
  retypePassword: string;
}

const analytics = getAnalytics(firebaseApp);
/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const SignupPage = () => {
  const [form] = Form.useForm<ISignupForm>();
  const translate = useTranslate();
  const { t, i18n } = useTranslation();

  const location = routerProvider.useLocation();

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  useEffect(() => {
    if (parsedSearch?.utm_source) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.utmData,
        JSON.stringify(parsedSearch),
      );
    }

    const to = String(parsedSearch?.to) || '';
    const joinIndex = to?.indexOf('join/');
    const joinIndexEncoded = to?.indexOf('join%2F');
    if (joinIndex > -1 || joinIndexEncoded > -1) {
      const joinCode =
        joinIndex > -1
          ? to.slice(joinIndex + 5)
          : to.slice(joinIndexEncoded + 7);

      localStorage.setItem(LOCAL_STORAGE_KEYS.inviteToken, joinCode);
    }
    logEvent(analytics, 'sign_up_page_viewed', parsedSearch || {});
  }, []);

  const { replace } = useNavigation();

  const { tt, signUpValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabled(form);

  // Note: as RuleObject is silenciing Antd since it seems to have wrong types
  const validationRules = getYupSyncValidator(t, signUpValidator) as RuleObject;

  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const { mutate: login } = useLogin<ILoginArgs | undefined>();
  const { mutate: register, isLoading } = useRegister<
    IRegisterArgs | undefined
  >();

  const CardTitle = (
    <Title level={3} style={titleStyles}>
      {translate('pages.signUp.title')}
    </Title>
  );

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {Object.keys(resources)
        .sort()
        .map((lang: string) => (
          <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
            {lang?.startsWith('en') ? 'English' : 'Русский'}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <img src={'/logo.svg'} alt="CopingCard Logo" />
            </div>
            <Title
              level={2}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              {translate('pages.login.providerPortal')}
            </Title>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0, padding: '0 20px' }}
              bodyStyle={{ padding: '24px 20px' }}
            >
              <div style={langaugeSelector}>
                <Title level={5}>
                  {translate('pages.login.chooseLanguage')}
                </Title>
                <div style={languageDropdown}>
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <Space>
                        {currentLocale?.startsWith('en')
                          ? 'English'
                          : 'Русский'}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </div>
              </div>
              <Button
                type="primary"
                color="secondary"
                size="large"
                loading={isLoading}
                onClick={async () => {
                  logEvent(analytics, 'gmail_sinup_clicked');
                  await register(
                    {
                      provider: 'gmail',
                      loginAfterRegister: true,
                      loginFunc: () =>
                        login({
                          provider: 'gmail',
                          loginAfterRegister: true,
                        }),
                    },
                    // TODO: uncomment and use when React Refine fixes the problem
                    // {
                    //   onSuccess: (d, v, c) => {
                    //     debugger;
                    //     login({ provider: 'gmail', loginAfterRegister: true });
                    //   },
                    // },
                  );
                }}
                block
                style={googleButton}
                icon={<GoogleOutlined />}
              >
                {translate('pages.signUp.googleSignIn')}
              </Button>

              <Title level={4} style={titleStyles}>
                {translate('pages.login.or')}
              </Title>
              <Form<ISignupForm>
                layout="vertical"
                labelWrap
                form={form}
                onFinish={(v) => {
                  logEvent(analytics, 'email_sinup_clicked');
                  register({
                    ...v,
                    provider: 'email',
                    loginAfterRegister: true,
                    loginFunc: () =>
                      login({
                        provider: 'email',
                        loginAfterRegister: true,
                      }),
                  });
                }}
                initialValues={{
                  remember: false,
                }}
                onFieldsChange={handleFormChange}
              >
                <Form.Item
                  name="email"
                  label={translate('pages.signUp.email')}
                  rules={[validationRules]}
                  required
                >
                  <Input
                    type="email"
                    size="large"
                    placeholder={translate('pages.signUp.email')}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={translate('pages.signUp.password')}
                  rules={[validationRules]}
                  style={{ marginBottom: '12px' }}
                  required
                >
                  <Input.Password
                    visibilityToggle
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="retypePassword"
                  label={translate('pages.signUp.retypePassword')}
                  // rules={[validationRules]}
                  style={{ marginBottom: '12px' }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(translate('validators.retypePassword')),
                        );
                      },
                    }),
                  ]}
                  required
                >
                  <Input.Password
                    visibilityToggle
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  disabled={isSubmitDisabled}
                >
                  {translate('pages.signUp.signup')}
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate('pages.signUp.haveAccount')}{' '}
                  <Link
                    strong
                    onClick={() => {
                      replace(`/login${location.search}`);
                    }}
                  >
                    {translate('pages.login.signin')}
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
        <LoggedOutFooter />
      </Row>
    </Layout>
  );
};
