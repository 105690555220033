const initialValues = {
  userId: '' as any,
  name: '',
  thoughts: [
    {
      thought: '' as string,
      edges: [false] as boolean[],
    },
  ],
};
export const generatePlantUmlText = (values: typeof initialValues) => {
  if (!values.name && values.thoughts.every((t) => !t.thought)) {
    return '';
  }

  const title = values.name
    ? 'title ' + values?.name?.trim()?.replace(/\n/g, '\\n') + '\n'
    : '';
  let nodesText = '@startuml\n';
  nodesText += title;
  let edgesText = '';
  values.thoughts.forEach((t, idx) => {
    const thoughtCorrected = t?.thought?.trim()?.replace(/\n/g, '\\n');

    const thoughtFull = thoughtCorrected
      ? `usecase (t${idx}) as "${thoughtCorrected}"\n`
      : '';

    nodesText += thoughtFull;

    t.edges.forEach((e, i) => {
      if (e) {
        edgesText += `(t${idx}) --> (t${i})\n`;
      }
    });
  });
  const logoText = '';
  // 'by: <img:http://copingcard.com/static/logo.png>';
  // 'by: <img:http://copingcard.com/static/favicon-32x32.png>';
  // 'by: <img:https://copingcard.com/static/logo-39bd0b4ba0c580edf887d8a70027b3e4.svg>';
  // 'usecase (by) as "by:": <img:https://storage.googleapis.com/copingcard.appspot.com/shared/images/logo.png{scale=0.5}>';
  // 'by: <img:https://storage.googleapis.com/copingcard.appspot.com/shared/images/logo.png{scale=0.5}>';

  // setUmlText(nodesText + edgesText + logoText + '\n@enduml');
  return nodesText + edgesText + '\n@enduml';
};
