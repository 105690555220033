import { Typography } from '@pankod/refine-antd';
import {
  LayoutWrapper,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import React, { useEffect } from 'react';
import { IUser } from '../../providers';
import { useWindowSize } from 'rooks';
import qs from 'qs';
import routerProvider from '@pankod/refine-react-router-v6';
import { firebaseAuth } from '../../authProvider';
import { applyActionCode } from 'firebase/auth';
import * as Sentry from '@sentry/react';

const { Link, Paragraph, Text, Title } = Typography;

export const VerifyEmail = () => {
  const translate = useTranslate();

  const location = routerProvider.useLocation();
  const { replace } = useNavigation();

  const { data: identity } = useGetIdentity<IUser>();
  const currentUserEmail = identity?.db?.email || '';

  useEffect(() => {
    if (identity?.fb?.emailVerified) {
      replace('/');
    }
    const parsedSearch = qs.parse(
      location.search.charAt(0) === '?'
        ? location.search.slice(1)
        : location.search,
    );

    if (parsedSearch?.mode && parsedSearch?.oobCode) {
      try {
        if (parsedSearch.mode === 'resetPassword') {
          const resetUrl = `/reset-password${location.search}`;
          replace(resetUrl);
          return;
        }
        applyActionCode(firebaseAuth.auth, parsedSearch.oobCode as string);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    replace('/');
  }, [location.search, identity?.fb?.emailVerified]);

  const { innerWidth } = useWindowSize();

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';
  return (
    <LayoutWrapper>
      <Title level={3} style={{ textAlign }}>
        {translate('pages.verifyEmail.verifyEmail')}
      </Title>
      <Paragraph>
        {translate('pages.verifyEmail.sentText')}{' '}
        <Text strong>{currentUserEmail}</Text>
      </Paragraph>
      <Paragraph>{translate('signIn.checkEmailSpam')}.</Paragraph>
      <Paragraph>
        {translate('pages.verifyEmail.contactSupport')}{' '}
        <Link href="mailto:support@copingcard.com" target="_blank">
          support@copingcard.com
        </Link>
      </Paragraph>
    </LayoutWrapper>
  );
};
