import { Dropdown, Icons, Menu, Space } from '@pankod/refine-antd';
import {
  useForgotPassword,
  useGetLocale,
  useLogin,
  useNavigation,
  useNotification,
  useSetLocale,
  useTranslate,
} from '@pankod/refine-core';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { getValidators } from '@cc/schema';
import routerProvider from '@pankod/refine-react-router-v6';
import { RuleObject } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { getYupSyncValidator, LOCAL_STORAGE_KEYS } from '../../helpers';
import { useFormSubmitDisabledAnyTouched } from '../../hooks';
import { resources } from '../../i18n';
import { ILoginArgs } from '../../providers';
import {
  containerStyles,
  googleButton,
  imageContainer,
  langaugeSelector,
  languageDropdown,
  layoutStyles,
  titleStyles,
} from './styles';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '../../authProvider';
import { LoggedOutFooter } from '../../components/layout/loggedOutFooter';

const { DownOutlined, GoogleOutlined } = Icons;

const { Link, Text, Title } = Typography;

const analytics = getAnalytics(firebaseApp);
export interface ILoginForm {
  email: string;
  password: string;
  remember: boolean;
}

export interface IForgotPasswordForm {
  email: string;
}

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const LoginPage = () => {
  const [form] = Form.useForm<ILoginForm>();
  const [forgotPasswordForm] = Form.useForm<IForgotPasswordForm>();
  const translate = useTranslate();

  const location = routerProvider.useLocation();

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  useEffect(() => {
    if (parsedSearch?.utm_source) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.utmData,
        JSON.stringify(parsedSearch),
      );
    }
    const to = String(parsedSearch?.to) || '';
    const joinIndex = to?.indexOf('join/');
    const joinIndexEncoded = to?.indexOf('join%2F');
    if (joinIndex > -1 || joinIndexEncoded > -1) {
      const joinCode =
        joinIndex > -1
          ? to.slice(joinIndex + 5)
          : to.slice(joinIndexEncoded + 7);

      localStorage.setItem(LOCAL_STORAGE_KEYS.inviteToken, joinCode);
    }

    logEvent(analytics, 'login_page_viewed', parsedSearch || {});
  }, []);

  const { replace } = useNavigation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const { mutate: login, isLoading } = useLogin<ILoginArgs | undefined>();

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const {
    handleFormChange: handleLoginFormChange,
    isDisabled: isLoginDisabled,
  } = useFormSubmitDisabledAnyTouched(form);

  const { mutate: handleResetPassword } = useForgotPassword();

  const { open: openNotification } = useNotification();

  const { t, i18n } = useTranslation();
  const { resendEmailValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const resendValidationRules = getYupSyncValidator(
    t,
    resendEmailValidator,
  ) as RuleObject;

  const { signInValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const loginValidationRules = getYupSyncValidator(
    t,
    signInValidator,
  ) as RuleObject;

  const CardTitle = (
    <Title level={3} style={titleStyles}>
      {translate(
        showForgotPassword ? 'pages.login.resetPassword' : 'pages.login.title',
      )}
    </Title>
  );

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {Object.keys(resources)
        .sort()
        .map((lang: string) => (
          <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
            {lang?.startsWith('en') ? 'English' : 'Русский'}
          </Menu.Item>
        ))}
    </Menu>
  );

  const {
    handleFormChange: handleResetFormChange,
    isDisabled: isResendSubmitDisabled,
  } = useFormSubmitDisabledAnyTouched(forgotPasswordForm);

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <img src={'/logo.svg'} alt="CopingCard Logo" />
            </div>
            <Title
              level={2}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              {translate('pages.login.providerPortal')}
            </Title>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0, padding: '0 20px' }}
              bodyStyle={{ padding: '24px 20px' }}
            >
              <div style={langaugeSelector}>
                <Title level={5}>
                  {translate('pages.login.chooseLanguage')}
                </Title>
                <div style={languageDropdown}>
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <Space>
                        {currentLocale?.startsWith('en')
                          ? 'English'
                          : 'Русский'}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </div>
              </div>
              {showForgotPassword ? (
                <Form<IForgotPasswordForm>
                  layout="vertical"
                  labelWrap
                  form={forgotPasswordForm}
                  onFinish={(values) => {
                    openNotification?.({
                      type: 'success',
                      message: '',
                      description: translate('pages.login.sentText'),
                      key: 'reset-password',
                    });
                    handleResetPassword(values.email);
                  }}
                  requiredMark
                  onFieldsChange={handleResetFormChange}
                >
                  <Form.Item
                    name="email"
                    label={translate('pages.login.email')}
                    rules={[resendValidationRules]}
                  >
                    <Input
                      type="email"
                      size="large"
                      placeholder={translate('pages.login.email')}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    block
                    disabled={isResendSubmitDisabled}
                  >
                    {translate('pages.login.resetPassword')}
                  </Button>
                </Form>
              ) : (
                <div>
                  <Form<ILoginForm>
                    layout="vertical"
                    labelWrap
                    form={form}
                    onFinish={(values) => {
                      login({ ...values, provider: 'email' });
                      logEvent(analytics, 'email_login_clicked');
                    }}
                    requiredMark={true}
                    onFieldsChange={handleLoginFormChange}
                    initialValues={{
                      remember: false,
                    }}
                  >
                    <Button
                      type="primary"
                      color="secondary"
                      size="large"
                      loading={isLoading}
                      onClick={() => {
                        const remember = form.getFieldValue('remember');
                        login({ provider: 'gmail', remember });
                        logEvent(analytics, 'gmail_login_clicked');
                      }}
                      block
                      style={googleButton}
                      icon={<GoogleOutlined />}
                    >
                      {translate('pages.login.googleSignIn')}
                    </Button>
                    <Title level={3} style={titleStyles}>
                      {translate('pages.login.or')}
                    </Title>
                    <Form.Item
                      name="email"
                      label={translate('pages.login.email')}
                      rules={[loginValidationRules]}
                    >
                      <Input
                        type="email"
                        size="large"
                        placeholder={translate('pages.login.email')}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={translate('pages.login.password')}
                      rules={[loginValidationRules]}
                      style={{ marginBottom: '12px' }}
                    >
                      <Input.Password
                        visibilityToggle
                        placeholder="●●●●●●●●"
                        size="large"
                      />
                    </Form.Item>
                    <div style={{ marginBottom: '12px' }}>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          {translate('pages.login.remember')}
                        </Checkbox>
                      </Form.Item>

                      <a
                        style={{
                          float: 'right',
                          fontSize: '12px',
                        }}
                        href="#"
                        onClick={(e) => {
                          setShowForgotPassword(true);
                        }}
                      >
                        {translate('pages.login.forgotPassword')}
                      </a>
                    </div>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      block
                      // Note: Commenting out since it prevennts logging in when browser remembers
                      // disabled={isLoginDisabled}
                    >
                      {translate('pages.login.signin')}
                    </Button>
                  </Form>
                </div>
              )}
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate('pages.login.noAccount')}{' '}
                  <Link
                    strong
                    onClick={() => replace(`/signup${location.search}`)}
                  >
                    {translate('pages.login.signup')}
                  </Link>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
        <LoggedOutFooter />
      </Row>
    </Layout>
  );
};
