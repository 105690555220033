import { useNavigation, useTranslate } from '@pankod/refine-core';
import { Button, Statistic } from 'antd';
import { useSubscriptionWarnings } from '../../hooks';

type Props = {
  hideUpgradeButton?: boolean;
};

export const ClientsLimitReached = ({ hideUpgradeButton = false }: Props) => {
  const subWarnings = useSubscriptionWarnings();

  const translate = useTranslate();

  const { replace } = useNavigation();

  const warningColor = subWarnings.limitReached ? 'white' : '';
  const warningBackgroundColor = subWarnings.limitReached
    ? '#b21f00'
    : subWarnings.limitApproaching
    ? '#EE9A3E'
    : '';

  return subWarnings.limitReached || subWarnings.limitApproaching ? (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div>
        <div>{translate('invites.clientsStats')}</div>

        <Statistic
          value={subWarnings.clientsAdded}
          suffix={` / ${subWarnings.clientsPurchased}`}
          valueStyle={{
            color: warningColor,
            backgroundColor: warningBackgroundColor,
            textAlign: 'center',
          }}
        />
      </div>

      {!hideUpgradeButton &&
        (subWarnings.limitReached || subWarnings.limitApproaching) && (
          <Button
            type="link"
            style={{ margin: 10 }}
            onClick={() => replace('/portalSubscriptions')}
          >
            {translate('invites.upgrade')}
          </Button>
        )}
    </div>
  ) : null;
};
