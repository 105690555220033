import {
  getSaasAgreementEn,
  getSaasAgreementRu,
  getValidators,
  Invite,
  InviteType,
  Practice,
  User,
} from '@cc/schema';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Result,
  Row,
  Select,
  Typography,
  useModal,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from '@pankod/refine-core';
import { RuleObject } from 'antd/lib/form';
import { format } from 'date-fns';
import countries from 'i18n-iso-countries';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useWindowSize } from 'rooks';
import { firebaseAuth } from '../../../authProvider';
import { getYupSyncValidator, LOCAL_STORAGE_KEYS } from '../../../helpers';
import { useFormSubmitDisabledAnyTouched } from '../../../hooks';
import { IUser } from '../../../providers';
import { StepProps } from '../step0';
import { linkStyle } from '../step0/styles';
import { button, buttonContainer } from '../styles';

const { Link, Paragraph, Title } = Typography;

export const urlRegExp =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export const praticeSizeOptions = ['1', '2-5', '6-10', '11-20', '20+'];

const insurances = [
  'Aetna',
  'Ambetter',
  "America's 1st Choice",
  'Anthem',
  'AvMed',
  'Beacon',
  'Blue Choice',
  'Blue Cross Blue Shield (BCBS)',
  'Cigna',
  'ComPsych',
  'Coventry',
  'Employer Assistance Plan (EAP)',
  'First Health',
  'Friday Health Plans',
  'GEHA Health Plans',
  'Golden Rule',
  'HSA',
  'Harvard Pilgrim',
  'Humana',
  'Kaiser',
  'Lyra Health',
  'Magellan',
  'Managed Health Network (MHN)',
  'Medicare',
  'Meritain Health',
  'Multiplan PHCS',
  'OSCAR',
  'Optum',
  'Optum Health',
  'Optum/United Health Care',
  'Oscar Health Insurance Co.',
  'Oxford (UnitedHealthcare)',
  'Tricare',
  'UMR',
  'United',
  'United Behavioral Health',
  'United Health Care',
  'Wellcare',
];

export interface IActionForm {
  action?: number;
}

export interface IJoinForm {
  practiceInviteToken: string;
}

export interface IPracticeForm {
  isSaasAgreementAccepted: boolean;
  practiceAddress1: string;
  practiceAddress2: string;
  practiceCity: string;
  practiceState: string;
  practiceZip: string;
  practiceCountry: string;
  practiceSize: string;
  npi?: string;
  practiceName: string;
  practicePhone: string;
  extension?: string;
  practiceUrl: string;
  practiceEmail: string;
  practiceDescription: string;
  acceptedInsurances: string[];
}

export const Step2 = ({
  currentStep,
  stepsLenght,
  setCurrentStep,
  setIsNextDisabled,
}: StepProps) => {
  const translate = useTranslate();

  const { i18n } = useTranslation();

  const { data: identity, refetch: refetchIdentity } = useGetIdentity<IUser>();

  const { modalProps, show, close } = useModal();

  const { innerWidth, innerHeight } = useWindowSize();

  const textAlign = (innerWidth || 1000) < 995 ? 'center' : 'left';

  const [actionForm] = Form.useForm<IActionForm>();
  const [joinForm] = Form.useForm<IJoinForm>();
  const [form] = Form.useForm<IPracticeForm>();

  const action = Form.useWatch('action', actionForm);
  const practiceInviteToken = Form.useWatch('practiceInviteToken', joinForm);

  const [savedInviteToken, setSavedInviteToken] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.inviteToken),
  );
  const [initialInviteLoadDone, setInitialInviteLoadDone] = useState(false);

  const practiceCountry = Form.useWatch('practiceCountry', form);

  const [touchStatuses, setTouchStatuses] = useState<string[]>([]);

  const isSaaSagreementAccepted = Form.useWatch(
    'isSaasAgreementAccepted',
    form,
  );
  const showNpi = practiceCountry === 'US';

  const [showNoToken, setShowNoToken] = useState(false);
  const [isSuccess, setIsSuccess] = useState<'token' | 'practice'>();

  const { practiceAndLocationValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const { replace } = useNavigation();

  // Note: as RuleObject is silenciing Antd since it seems to have wrong types
  const validationRules = getYupSyncValidator(
    translate,
    practiceAndLocationValidator,
  ) as RuleObject;

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabledAnyTouched(form);

  useEffect(() => {
    setIsNextDisabled(isSubmitDisabled);
  }, [isSubmitDisabled]);

  const {
    data: inviteData,
    isLoading: inviteLoading,
    refetch: refetchInvite,
    error: inviteError,
  } = useCustom<Invite>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'inviteByToken',
      fields: ['_id', 'token', 'type'],
      variables: {
        token: {
          name: 'token',
          type: 'String',
          value:
            joinForm.getFieldValue('practiceInviteToken') || savedInviteToken,
        },
      },
    },
  });

  useEffect(() => {
    if (inviteData?.data?._id) {
      setInitialInviteLoadDone(true);
    }
    if (
      inviteData?.data &&
      savedInviteToken &&
      (!inviteData?.data?._id ||
        ![InviteType.PRACTICE_USER, InviteType.PRACTICE_ADMIN].includes(
          inviteData?.data?.type,
        ))
    ) {
      setSavedInviteToken(null);
    }
    // @ts-ignore
  }, [inviteData]);

  const countriesArr = useMemo(() => {
    const countriesArr = Object.entries(countries.getNames(i18n.languages[0]))
      .map(([key, value]) => ({
        name: value,
        value: key,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const res = countriesArr?.length
      ? countriesArr
      : (translate('countriesFallback', {
          returnObjects: true,
        }) as unknown as typeof countriesArr);
    return res;
  }, [i18n.languages[0]]);

  const isDisabled =
    action === undefined
      ? true
      : action === 1
      ? !(practiceInviteToken || savedInviteToken)
      : isSubmitDisabled || !isSaaSagreementAccepted;

  useEffect(() => {
    if (identity?.loginInfo) {
      if (!form.getFieldValue('practiceCountry')) {
        form.setFieldsValue({
          practiceCountry: identity?.db?.country || '',
        });
      }

      if (!form.getFieldValue('practiceState')) {
        form.setFieldsValue({ practiceState: identity?.loginInfo?.region });
      }

      if (!form.getFieldValue('practicePhone')) {
        form.setFieldsValue({
          practicePhone: identity?.db?.phone || '',
        });
      }
      if (!form.getFieldValue('extension')) {
        form.setFieldsValue({
          extension: identity?.db?.extension || '',
        });
      }
    }
  }, [JSON.stringify(identity)]);

  const {
    mutate: createPracticeAndLocationMutation,
    isLoading: isCreatePracticeAndLocationMutationLoading,
    data: createPracticeAndLocationMutationData,
    error: createPracticeAndLocationMutationError,
  } = useCustomMutation<Practice>();

  const {
    mutate: linkUserToPracticeMutation,
    isLoading: isLinkUserToPracticeMutationLoading,
    data: createLinkUserToPracticeMutationData,
    error: createLinkUserToPracticeMutationError,
  } = useCustomMutation<User>();

  const createPracticeAndLocation = async () => {
    const values = form.getFieldsValue();

    await createPracticeAndLocationMutation({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'createPracticeAndLocation',
        fields: ['_id'],
        variables: {
          input: {
            name: 'input',
            type: 'PracticeAndLocationCreateInput',
            value: {
              data: values,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isSuccess || createPracticeAndLocationMutationData?.data?._id) {
      firebaseAuth.resetCachedDbUser();
      const handleRefetch = setTimeout(() => {
        refetchIdentity();
      }, 3000);
      const handle = setTimeout(async () => {
        replace('/clients');
      }, 5000);
      return () => {
        clearTimeout(handle);
        clearTimeout(handleRefetch);
      };
    }
  }, [isSuccess, createPracticeAndLocationMutationData?.data?._id]);

  const saasOpts = {
    date: format(new Date(), 'dd MMMM, yyyy'),
    name: form.getFieldValue('practiceName'),
    address: [
      form.getFieldValue('practiceAddress1') || '',
      form.getFieldValue('practiceAddress2') || '',
      form.getFieldValue('practiceCity') || '',
      form.getFieldValue('practiceState') || '',
      form.getFieldValue('practiceZip') || '',
      form.getFieldValue('practiceCountry') || '',
    ]
      .filter((a) => !!a)
      .join(', '),
  };

  const saas = i18n.languages[0]?.startsWith('ru')
    ? getSaasAgreementRu(saasOpts)
    : getSaasAgreementEn(saasOpts);

  return (
    <div>
      <Title level={4} style={{ textAlign }}>
        {translate('pages.initialSetup.step2.title')}
      </Title>
      <Paragraph>{translate('pages.initialSetup.step2.description')}</Paragraph>

      <Form<IActionForm>
        form={actionForm}
        onFinish={(v) => {}}
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        requiredMark
        onFieldsChange={(a) => {
          if (action === 2 && a?.[0]?.value === 1) {
            const allFields = Object.keys(form.getFieldsValue());
            const touchedStatus = allFields.reduce((acc, cur) => {
              const isTouched = form.isFieldTouched(cur);
              if (isTouched) {
                acc[cur as string] = isTouched;
              }
              return acc;
            }, {} as Record<string, boolean>);
            setTouchStatuses(Object.keys(touchedStatus));
          }
          if (action === 1 && a?.[0]?.value === 2) {
            if (touchStatuses.length) {
              touchStatuses.forEach((field) => {
                form.setFields([{ name: field, touched: true }]);
              });
            }
          }
        }}
        initialValues={{
          action: undefined,
        }}
      >
        <Form.Item
          label={translate('pages.initialSetup.step2.isPracticeAdded')}
          name={'action'}
          required
        >
          <Radio.Group>
            <Radio value={1}>{translate('common.yes')}</Radio>
            <Radio value={2}>{translate('common.no')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>

      <Divider />

      {action === 1 && (
        <Form<IJoinForm>
          form={joinForm}
          onFinish={(values) => {
            console.log(values);
          }}
          labelWrap
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}
          requiredMark
          onFieldsChange={(a) => console.log(a)}
          initialValues={{
            action: undefined,
          }}
        >
          <Paragraph>
            {translate('pages.initialSetup.step2.practiceAddedInfo')}
          </Paragraph>

          <Form.Item
            required
            name="practiceInviteToken"
            label={translate('pages.initialSetup.step2.practiceInviteToken')}
            rules={[{ required: true, type: 'string', min: 8 }]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate(
                'pages.initialSetup.step2.practiceInviteTokenPlaceholder',
              )}
              defaultValue={savedInviteToken || ''}
              onChange={(e) => {
                if (
                  showNoToken &&
                  e.target.value !== form.getFieldValue('practiceInviteToken')
                ) {
                  setShowNoToken(false);
                }
              }}
            />
          </Form.Item>
        </Form>
      )}

      {action === 2 && (
        <Form<IPracticeForm>
          form={form}
          onFinish={(values) => {
            console.log(values);
          }}
          labelWrap
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}
          requiredMark
          onFieldsChange={handleFormChange}
          initialValues={{
            isSaasAgreementAccepted: false,
            practiceAddress1: '',
            practiceAddress2: '',
            practiceCity: '',
            practiceState: '',
            practiceZip: '',
            practiceCountry: '',
            practiceSize: '',
            npi: '',
            practiceName: '',
            practicePhone: '',
            extension: '',
            practiceUrl: '',
            practiceEmail: '',
            practiceDescription: '',
            acceptedInsurances: [],
          }}
        >
          <Paragraph>
            {translate('pages.initialSetup.step2.locationInfo')}
          </Paragraph>
          <Form.Item
            name="practiceAddress1"
            label={translate('pages.initialSetup.step2.practiceAddress1')}
            required
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate(
                'pages.initialSetup.step2.practiceAddress1',
              )}
            />
          </Form.Item>
          <Form.Item
            name="practiceAddress2"
            label={translate('pages.initialSetup.step2.practiceAddress2')}
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate(
                'pages.initialSetup.step2.practiceAddress2',
              )}
            />
          </Form.Item>
          <Form.Item
            name="practiceCity"
            label={translate('pages.initialSetup.step2.practiceCity')}
            required
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practiceCity')}
            />
          </Form.Item>
          <Form.Item
            name="practiceState"
            label={translate('pages.initialSetup.step2.practiceState')}
            required
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practiceState')}
            />
          </Form.Item>
          <Form.Item
            name="practiceZip"
            label={translate('pages.initialSetup.step2.practiceZip')}
            required
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practiceZip')}
            />
          </Form.Item>
          <Form.Item
            name="practiceCountry"
            label={translate('pages.initialSetup.step2.practiceCountry')}
            required
            rules={[validationRules]}
          >
            <Select
              size="large"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return ((option!.children as unknown as string) || '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              filterSort={(optionA, optionB) => {
                return (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase(),
                  );
              }}
            >
              {countriesArr.map((c) => (
                <Select.Option key={c.name} value={c.value}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Divider />
          <Form.Item
            name="practiceSize"
            label={translate('pages.initialSetup.step1.practiceSize')}
            required
            rules={[validationRules]}
          >
            <Select size="large">
              {praticeSizeOptions.map((o, idx) => (
                <Select.Option value={o} key={o}>
                  {o}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {showNpi && (
            <Form.Item
              name="npi"
              label={translate('pages.initialSetup.step2.npi')}
              rules={[validationRules]}
            >
              <Input
                type="text"
                size="large"
                placeholder={translate('pages.initialSetup.step2.npi')}
              />
            </Form.Item>
          )}
          <Form.Item
            name="practiceName"
            label={translate('pages.initialSetup.step2.practiceName')}
            required
            rules={[validationRules]}
          >
            <Input
              type="text"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practiceName')}
            />
          </Form.Item>
          <Form.Item
            name="practiceEmail"
            label={translate('pages.initialSetup.step2.practiceEmail')}
            required
            rules={[validationRules]}
          >
            <Input
              type="email"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practiceEmail')}
            />
          </Form.Item>
          <Form.Item
            name="practiceUrl"
            label={translate('pages.initialSetup.step2.practiceUrl')}
            rules={[
              {
                pattern: urlRegExp,
                message: translate('validators.url'),
              },
            ]}
          >
            <Input
              type="url"
              size="large"
              addonBefore="https://"
              placeholder={translate('pages.initialSetup.step2.practiceUrl')}
            />
          </Form.Item>
          <Form.Item
            name="practicePhone"
            label={translate('pages.initialSetup.step2.practicePhone')}
            required
            rules={[validationRules]}
          >
            <Input
              type="tel"
              size="large"
              placeholder={translate('pages.initialSetup.step2.practicePhone')}
            />
          </Form.Item>
          <Form.Item
            name="extension"
            label={translate('pages.initialSetup.step2.extension')}
            rules={[validationRules]}
          >
            <Input
              size="large"
              placeholder={translate('pages.initialSetup.step2.extension')}
            />
          </Form.Item>

          <Form.Item
            name="practiceDescription"
            label={translate('pages.initialSetup.step2.practiceDescription')}
            required
            rules={[validationRules]}
          >
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 8 }}
              size="large"
              placeholder={translate(
                'pages.initialSetup.step2.practiceDescription',
              )}
            />
          </Form.Item>

          <Divider />
          <Form.Item
            required
            label={translate('pages.initialSetup.step2.saasAgreement')}
          >
            <Row
              gutter={8}
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Col offset={1}>
                <Form.Item
                  valuePropName="checked"
                  required
                  rules={[{ required: true }]}
                  name="isSaasAgreementAccepted"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col>
                <Link
                  style={linkStyle}
                  strong
                  onClick={() => {
                    show();
                  }}
                >
                  {translate('pages.initialSetup.step2.readSaasAgreement')}
                </Link>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Paragraph>
            {translate('pages.initialSetup.step2.acceptedInsurancesText')}
          </Paragraph>

          <Form.Item
            name="acceptedInsurances"
            label={translate('pages.initialSetup.step2.acceptedInsurances')}
            rules={[validationRules]}
          >
            <Select
              size="large"
              mode="tags"
              style={{ width: '100%' }}
              placeholder={translate('pages.initialSetup.selectOrType')}
            >
              {insurances.map((i) => (
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Modal
            {...modalProps}
            okText={translate('privacySettings.accept')}
            onOk={() => {
              form.setFieldValue('isSaasAgreementAccepted', true);
              close();
            }}
            maskClosable={false}
            title={translate('screens.saas')}
            width={innerWidth ? (innerWidth > 1000 ? 1000 : 400) : 400}
          >
            <div
              style={{
                overflowY: 'scroll',
                maxHeight: innerHeight
                  ? innerHeight > 600
                    ? '600px'
                    : `${innerHeight - 50}px`
                  : '600px',
              }}
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              >
                {saas}
              </ReactMarkdown>
            </div>
          </Modal>
        </Form>
      )}

      {!inviteLoading &&
        (inviteError || showNoToken || isSuccess === 'token') && (
          <Result
            style={{ background: 'white' }}
            status={inviteError || showNoToken ? 'error' : 'success'}
            title={
              inviteError
                ? inviteError.message
                : showNoToken
                ? translate('pages.initialSetup.step2.tokenNotFound')
                : translate('pages.initialSetup.step2.tokenSuccess')
            }
            extra={
              <div>
                {isSuccess === 'token'
                  ? translate('pages.initialSetup.step2.redirect')
                  : translate('pages.initialSetup.step2.tokenTryAgain')}
              </div>
            }
          />
        )}

      {!isCreatePracticeAndLocationMutationLoading &&
        (createPracticeAndLocationMutationError ||
          createPracticeAndLocationMutationData?.data?._id) && (
          <Result
            status={
              createPracticeAndLocationMutationError ? 'error' : 'success'
            }
            title={
              createPracticeAndLocationMutationError
                ? createPracticeAndLocationMutationError.message
                : translate('pages.initialSetup.step2.practiceSuccess')
            }
            extra={
              createPracticeAndLocationMutationData?.data?._id ? (
                <div>{translate('pages.initialSetup.step2.redirect')}</div>
              ) : null
            }
          />
        )}

      <div style={buttonContainer}>
        {currentStep !== 0 && (
          <Button
            onClick={() => setCurrentStep(currentStep - 1)}
            style={button}
          >
            Previous Step
          </Button>
        )}
        <Button
          onClick={async () => {
            if (action === 1) {
              setShowNoToken(false);
              const res = await refetchInvite();
              if (!res.data?.data?._id) {
                setShowNoToken(true);
              } else {
                const res2 = await linkUserToPracticeMutation({
                  url: '',
                  method: 'post',
                  values: {},
                  metaData: {
                    operation: 'addPracticeUser',
                    fields: [{ new: ['_id'] }, 'tokenError'],
                    variables: {
                      input: {
                        name: 'input',
                        type: 'PracticeAddUserInput!',
                        value: {
                          data: {
                            token: res.data?.data?.token,
                          },
                        },
                      },
                    },
                  },
                });

                const savedToken = localStorage.getItem(
                  LOCAL_STORAGE_KEYS.inviteToken,
                );
                if (savedToken && savedToken === res.data?.data?.token) {
                  localStorage.removeItem(LOCAL_STORAGE_KEYS.inviteToken);
                }

                setIsSuccess('token');
              }
            } else {
              const res = await createPracticeAndLocation();
            }
            // setCurrentStep(currentStep + 1);
          }}
          style={button}
          disabled={
            isDisabled ||
            !!isSuccess ||
            !!createPracticeAndLocationMutationData?.data?._id
          }
          type="primary"
        >
          {currentStep === stepsLenght - 1 ? 'Finish' : 'Next Step'}
        </Button>
      </div>
    </div>
  );
};
