import { Line, LineConfig } from '@ant-design/charts';
import {
  Client,
  dbCols,
  portalEn,
  Practice,
  UsageEventTypes,
  UserRole,
} from '@cc/schema';
import {
  Badge,
  BadgeProps,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Modal,
  Radio,
  RefreshButton,
  Row,
  Select,
  Show,
  Space,
  Spin,
  Statistic,
  Timeline,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useGetLocale,
  useOne,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import {
  add,
  endOfDay,
  endOfMinute,
  endOfMonth,
  format,
  formatDuration,
  intervalToDuration,
  parseISO,
  roundToNearestMinutes,
  startOfMinute,
  startOfMonth,
  sub,
} from 'date-fns';
import { registerLocale } from '@antv/g2plot';
import { RU_RU_LOCALE } from '@cc/schema';
import { colors } from '../../styles/colors';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'rooks';
import {
  PracticeUsageEvent,
  UsageEvent,
} from '../../schema/be-graphql-generated';
import './styles.less';
import {
  AlertOutlined,
  CopyOutlined,
  PlusCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { DateFnsCalendar, DateFnsDatePicker } from '../../components/Pickers';
import { IUser } from '../../providers';
import { useTranslation } from 'react-i18next';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import ruPicker from 'antd/es/date-picker/locale/ru_RU';
import enUSPicker from 'antd/es/date-picker/locale/en_US';
import { enUS, ru } from 'date-fns/locale';
import { useWatch } from 'antd/lib/form/Form';

registerLocale('ru', RU_RU_LOCALE);

const zeroPad = (num: any) => String(num).padStart(2, '0');

const STATS_VIEW_RESOURCE = 'clientInfo/stats';
const CLIENT_ACTIVITY_CALENDAR_RESOURCE = 'clientInfo/activityCalendar';
const CLIENT_ACTIVITY_LOG_RESOURCE = 'clientInfo/activityLog';

const { Title } = Typography;

const { RangePicker } = DateFnsDatePicker;

const filterActivitiesByDate = (
  a: [string, UsageEvent[] | PracticeUsageEvent[]],
  value: Date,
) => {
  const [d, entries] = a;

  const date = format(value, 'yyyy-MM');
  const splitDate = d.split('-');
  const improvedDate = new Date(Number(splitDate[0]), Number(splitDate[1]) - 1);
  return format(improvedDate, 'yyyy-MM') === date;
};

const metaData = {
  operation: 'client',
  fields: [
    '_id',
    'firstName',
    'lastName',
    'email',
    'displayName',
    'role',
    'createdAt',
    'accessLevel',
    {
      mostUsedStats: ['count', 'entityId'],
    },
    { tests: ['_id', 'createdAt', 'type', 'score'] },
    { thoughts: ['count'] },
    { enabledDayRemindersCount: ['enabled'] },
    { enabledLocationRemindersCount: ['enabled'] },
    {
      cardsViewedStats: [{ stats: ['date', 'count'] }],
    },
    {
      dayRemindersTriggeredStats: [{ stats: ['date', 'count'] }],
    },
    {
      dayRemindersReactedStats: [{ stats: ['date', 'count'] }],
    },
    {
      locationRemindersTriggeredStats: [{ stats: ['date', 'count'] }],
    },
    {
      locationRemindersReactedStats: [{ stats: ['date', 'count'] }],
    },
    {
      meditationsStats: [{ stats: ['date', 'count'] }],
    },
    {
      crisisStats: [{ stats: ['date', 'count'] }],
    },
  ],
};

const customActivityInitialValues = {
  activityRange: [] as [Date, Date] | [],
  selectedActivity: undefined as number | undefined,
};

export const ClientShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { i18n } = useTranslation();

  const getLocale = useGetLocale();
  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const [isLogAscending, setIsLogAscending] = useState(false);
  const [calendarMode, setCalendarMode] = useState<'month' | 'year'>('month');
  const [isClientActivity, setIsClientActivity] = useState(true);

  const [showInSessionData, setShowInSessionData] = useState(false);
  const [showOffsessionData, setShowOffsessionData] = useState(true);

  const therapistActivityService = useContext(TherapistActivityReactContext);

  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const [customActivityForm] =
    Form.useForm<typeof customActivityInitialValues>();

  const [isActivitySelectVisible, setIsActivitySelectVisible] = useState(false);
  const activityRange = useWatch('activityRange', customActivityForm);
  const selectedActivity = useWatch('selectedActivity', customActivityForm);

  useActivityTracking(STATS_VIEW_RESOURCE);

  const { mutate: mutatePracticeUsageEvent } =
    useCustomMutation<PracticeUsageEvent>();

  const parseEvent = useCallback(
    (event: UsageEvent) => {
      if (event._to.startsWith('featureFlags/')) {
        let parsedExtras = {} as any;
        try {
          parsedExtras = JSON.parse(event?.extras || '');
        } catch (e) {}

        switch (event._to) {
          case 'featureFlags/18':
            return t(`pages.clientsShow.featureFlags.${event._to}`);
          case 'featureFlags/17': {
            return t(`pages.clientsShow.featureFlags.${event._to}`) +
              parsedExtras?.videoId
              ? `, videoId: ${parsedExtras.videoId}`
              : '';
          }
          case 'featureFlags/16':
            return (
              t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'n',
              })
            );
          case 'featureFlags/15':
            return (
              t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          case 'featureFlags/14':
            return (
              t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          case 'featureFlags/10': {
            const firstPart = t(`pages.clientsShow.featureFlags.${event._to}`);
            const secondPart = !!parsedExtras?.screen
              ? ', ' +
                t(`pages.clientsShow.eventTypes.${event.type}`, {
                  context: 'm',
                }) +
                ` ${t(`pages.clientsShow.screen`)}`
              : `, ${t(`pages.clientsShow.eventTypes.${event.type}`, {
                  context: 'f',
                })}`;
            const thirdPart = parsedExtras?.screen
              ? parsedExtras?.video
                ? ` "${t(`screens.${parsedExtras.screen}`)}"` +
                  `, videoId: ${parsedExtras.video}`
                : ` "${t(`screens.${parsedExtras.screen}`)}"`
              : '';

            return firstPart + secondPart + thirdPart;
          }
          case 'featureFlags/8': {
            return (
              t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              }) +
              (parsedExtras?.url ? `, ${parsedExtras.url}` : '')
            );
          }
          case 'featureFlags/3':
            return t(`pages.clientsShow.featureFlags.${event._to}`);
          case 'featureFlags/2':
            return t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'n',
              }) +
              parsedExtras?.article
              ? `, "${parsedExtras.article}"`
              : '';
          default:
            return (
              t(`pages.clientsShow.featureFlags.${event._to}`) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`)
            );
        }
      } else {
        switch (event._to?.split('/')[0] || '') {
          case dbCols.abcModels:
            return (
              t(`pages.clientsShow.entityNames.abcModels`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          case dbCols.behaviorExperiments:
            return (
              t(`pages.clientsShow.entityNames.behaviorExperiments`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'm',
              })
            );
          case dbCols.dayReminders:
            return (
              t(`pages.clientsShow.entityNames.dayReminders`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'n',
              })
            );
          case dbCols.downwardArrows:
            return (
              t(`pages.clientsShow.entityNames.downwardArrows`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          case dbCols.exposures:
            return (
              t(`pages.clientsShow.entityNames.exposures`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          case dbCols.exposureStimuli:
            return (
              t(`pages.clientsShow.entityNames.exposureStimuli`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`)
            );
          case dbCols.locationsRelations:
            return (
              t(`pages.clientsShow.entityNames.locationsRelations`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, { context: 'n' })
            );
          case dbCols.supportingCycles:
            return (
              t(`pages.clientsShow.entityNames.supportingCycles`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'm',
              })
            );
          case dbCols.tests: {
            return (
              t(`pages.clientsShow.entityNames.tests`, {
                count: 1,
              }) +
              ' ' +
              t(
                event.type === UsageEventTypes.Created
                  ? `pages.clientsShow.otherEvents.taken`
                  : `pages.clientsShow.eventTypes.${event.type}`,
              )
            );
          }
          case t('pages.clientsShow.entityNames.copingCards'): {
            return (
              t(`pages.clientsShow.entityNames.copingCards`, {
                count: 1,
              }) +
              ', ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'f',
              })
            );
          }
          case dbCols.thoughts:
            return (
              t(`pages.clientsShow.entityNames.thoughts`, {
                count: 1,
              }) +
              ', ' +
              t(`pages.clientsShow.eventTypes.${event.type}`, {
                context: 'm',
              })
            );
          case dbCols.worryTrees:
            return (
              t(`pages.clientsShow.entityNames.worryTrees`, {
                count: 1,
              }) +
              ' ' +
              t(`pages.clientsShow.eventTypes.${event.type}`)
            );
          case dbCols.users: {
            if (!!(JSON.parse(event.extras || '') || {})?.privacySettings) {
              return t(`pages.clientsShow.otherEvents.privacyPolicyUpdated`);
            } else {
              return event._to;
            }
          }
          default:
            return event._to;
        }
      }
    },
    [t],
  );

  const parseTherapistActivity = useCallback(
    (event: PracticeUsageEvent) => {
      const { resource, activity } = event;
      const splitResource = resource.split('/');

      // Note: if there is id of the resource, then it is a single resource
      const countToUse = splitResource.length > 1 ? 1 : 2;

      const formatStandardActivity = (
        resourceKey: string,
        activityExtendContext = {} as any,
      ) => {
        return (
          t(resourceKey, {
            count: countToUse,
          }) +
          ' ' +
          t(`pages.clientsShow.therapistActivityTypes.${activity}`, {
            count: countToUse,
            ...activityExtendContext,
          }) +
          (splitResource?.length > 1 ? `, ID: ${splitResource[1]}` : '')
        );
      };

      switch (splitResource[0] || '') {
        case dbCols.abcModels:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.abcModels`,
            {
              context: 'f',
            },
          );
        case dbCols.behaviorExperiments:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.behaviorExperiments`,
            {
              context: 'f',
            },
          );
        case dbCols.conceptualizations:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.conceptualizations`,
            {
              context: 'f',
            },
          );
        case dbCols.dayReminders:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.dayReminders`,
            {
              context: 'n',
            },
          );
        case dbCols.downwardArrows:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.downwardArrows`,
            {
              context: 'f',
            },
          );
        case dbCols.exposures:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.exposures`,
            {
              context: 'f',
            },
          );
        case dbCols.exposureStimuli:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.exposureStimuli`,
          );
        case dbCols.locationsRelations:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.exposures`,
            {
              context: 'n',
            },
          );
        case dbCols.supportingCycles:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.supportingCycles`,
          );
        case dbCols.tests:
          return formatStandardActivity(`pages.clientsShow.entityNames.tests`);
        case dbCols.thoughts:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.thoughts`,
          );
        case dbCols.worryTrees:
          return formatStandardActivity(
            `pages.clientsShow.entityNames.worryTrees`,
            {
              context: 'n',
            },
          );
        // Note: Non-DB activities
        case 'clientInfo': {
          switch (splitResource[1] || '') {
            case 'activityCalendar':
              return (
                t(
                  `pages.clientsShow.therapistOtherResources.clientInfo.activityCalendar`,
                ) +
                ' ' +
                t(`pages.clientsShow.therapistActivityTypes.0`, {
                  count: 1,
                })
              );
            case 'activityLog':
              return (
                t(
                  `pages.clientsShow.therapistOtherResources.clientInfo.activityLog`,
                ) +
                ' ' +
                t(`pages.clientsShow.therapistActivityTypes.0`, {
                  count: 1,
                })
              );
            case 'stats':
              return (
                t(
                  `pages.clientsShow.therapistOtherResources.clientInfo.stats`,
                ) +
                ' ' +
                t(`pages.clientsShow.therapistActivityTypes.0`, {
                  count: 1,
                  context: 'f',
                })
              );
            default:
              return event.resource;
          }
        }
        case 'manualActivity': {
          switch (splitResource[1] || '') {
            case '0':
            case '1':
            case '2':
            case '3':
              return (
                t(`pages.clientsShow.therapistCustomActivities`, {
                  returnObjects: true,
                })?.[Number(splitResource[1])]?.toLowerCase() || event.resource
              );
            default:
              return splitResource[1];
          }
        }
        case t('pages.clientsShow.therapistOtherResources.portalCards'): {
          const res = formatStandardActivity(
            `pages.clientsShow.entityNames.copingCards`,
            {
              context: 'f',
            },
          );
          return formatStandardActivity(
            `pages.clientsShow.entityNames.copingCards`,
            {
              context: 'f',
            },
          );
        }
        default:
          return event.resource;
      }
    },
    [t],
  );
  const { queryResult } = useShow<Client>({
    metaData,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  useEffect(() => {
    if (record?._id) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId: record._id,
      });
    }
  }, [record?._id]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isManualActivityModalOpen, setIsManualActivityModalOpen] =
    useState(false);

  const onManualActivityModalClose = () => {
    setIsManualActivityModalOpen(false);
    customActivityForm.resetFields();
  };

  const onManualActivityModalOkClose = () => {
    setIsManualActivityModalOpen(false);
    customActivityForm.submit();
  };

  const { innerWidth } = useWindowSize();

  const [refetchSartDate, setRefetchStartDate] = useState(
    startOfMonth(new Date()),
  );
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const {
    data: usageEventsData,
    isLoading: usageEventsLoading,
    refetch: refetchUsageEvents,
    error: usageEventsError,
  } = useCustom<{
    usageEvents: Omit<UsageEvent, '_from'>[];
  }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'usageEvents',
      fields: ['_id', '_to', 'type', 'createdAt', 'extras'],
      variables: {
        sort: 'DESC',
        endDate: {
          name: 'endDate',
          type: 'DateTime',
          value: endDate,
        },
        startDate: {
          name: 'startDate',
          type: 'DateTime',
          value: refetchSartDate,
        },
        clientId: record?._id,
      },
    },
  });

  const {
    data: practiceUsageEventsData,
    isLoading: practiceUsageEventsLoading,
    refetch: refetchPracticeUsageEvents,
    error: practiceUsageEventsError,
  } = useCustom<{
    usageEvents: Omit<UsageEvent, '_from'>[];
  }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'practiceUsageEvents',
      fields: ['_id', '_to', 'activity', 'createdAt', 'finishedAt', 'resource'],
      variables: {
        sort: 'DESC',
        endDate: {
          name: 'endDate',
          type: 'DateTime',
          value: endDate,
        },
        startDate: {
          name: 'startDate',
          type: 'DateTime',
          value: refetchSartDate,
        },
        clientId: {
          name: 'clientId',
          type: 'ID',
          value: record?._id,
        },
        showOffsessionData: {
          name: 'showOffsessionData',
          type: 'Boolean',
          value: showOffsessionData,
        },
        showInSessionData: {
          name: 'showInSessionData',
          type: 'Boolean',
          value: showInSessionData,
        },
      },
    },
  });

  const groupedByDateData = useMemo(() => {
    // @ts-ignore
    const grouped = usageEventsData?.data?.reduce((acc, curr) => {
      const date = format(parseISO(curr.createdAt), 'yyyy-MM-dd');
      if (acc[date]) {
        acc[date].push(curr);
      } else {
        acc[date] = [curr];
      }
      return acc;
    }, {} as { [key: string]: UsageEvent[] });

    return (grouped || {}) as { [key: string]: UsageEvent[] };
  }, [usageEventsData?.data]);

  const practiceGroupedByDateData = useMemo(() => {
    const nonOverlappingFinishesAndStarts = (practiceUsageEventsData?.data
      // @ts-ignore
      ?.sort(
        // @ts-ignore
        (a, b) =>
          parseISO(a.createdAt).valueOf() - parseISO(b.createdAt).valueOf(),
      )
      // @ts-ignore
      .reduce((acc, curr) => {
        const lastActvitiy = acc.length ? acc.pop() : undefined;

        const needsLastActivityFinishAdjusted =
          !!lastActvitiy &&
          (!lastActvitiy.finishedAt ||
            parseISO(lastActvitiy.finishedAt) > parseISO(curr.createdAt));

        if (needsLastActivityFinishAdjusted) {
          return [
            ...acc,
            {
              ...lastActvitiy,
              finishedAt: curr.createdAt,
            },
            curr,
          ];
        } else {
          return lastActvitiy ? [...acc, lastActvitiy, curr] : [...acc, curr];
        }
      }, [] as PracticeUsageEvent[]) || []) as PracticeUsageEvent[];

    const grouped = nonOverlappingFinishesAndStarts?.reduce((acc, curr) => {
      const date = format(parseISO(curr.createdAt), 'yyyy-MM-dd');
      if (acc[date]) {
        acc[date].push(curr);
      } else {
        acc[date] = [curr];
      }
      return acc;
    }, {} as { [key: string]: PracticeUsageEvent[] });

    return (grouped || {}) as { [key: string]: PracticeUsageEvent[] };
  }, [practiceUsageEventsData?.data]);

  const groupedByDate = useMemo(() => {
    const maybeReversedData = isLogAscending
      ? Object.entries(groupedByDateData || {})
          .reverse()
          .reduce((acc, [key, value]) => {
            acc[key] = [...(value || [])].reverse();
            return acc;
          }, {} as { [key: string]: UsageEvent[] })
      : groupedByDateData;

    return Object.keys(maybeReversedData || {}).length ? (
      <Timeline mode="left">
        {Object.entries(maybeReversedData).map(([d, entries], i) => (
          <Timeline.Item key={d + i} label={d} className="timeline-label">
            {((entries as UsageEvent[]) || []).map((e, idx) => {
              return (
                <p key={idx}>
                  {format(
                    parseISO(e.createdAt),
                    currentLocale?.startsWith('ru')
                      ? 'HH:mm:ss'
                      : 'hh:mm:ss aaa',
                  ) +
                    ' - ' +
                    parseEvent(e)}
                </p>
              );
            })}
          </Timeline.Item>
        ))}
      </Timeline>
    ) : (
      `${t('pages.clientsShow.daysDataCollected')}: 0`
    );
  }, [groupedByDateData, isLogAscending, i18n.language]);

  const practiceGroupedByDate = useMemo(() => {
    const maybeReversedData = isLogAscending
      ? Object.entries(practiceGroupedByDateData || {})
          .reverse()
          .reduce((acc, [key, value]) => {
            acc[key] = [...(value || [])].reverse();
            return acc;
          }, {} as { [key: string]: PracticeUsageEvent[] })
      : practiceGroupedByDateData;

    return Object.keys(maybeReversedData || {}).length ? (
      <Timeline mode="left">
        {Object.entries(maybeReversedData).map(([d, entries], i) => (
          <Timeline.Item key={d + i} label={d} className="timeline-label">
            {((entries as PracticeUsageEvent[]) || []).map((e, idx) => {
              const dur = e.finishedAt
                ? intervalToDuration({
                    start: parseISO(e.createdAt),
                    end: parseISO(e.finishedAt),
                  })
                : undefined;

              const formatedDuration = dur
                ? [dur.hours, dur.minutes, dur.seconds].map(zeroPad).join(':')
                : '';

              return (
                <p key={idx}>
                  {format(
                    parseISO(e.createdAt),
                    currentLocale?.startsWith('ru')
                      ? 'HH:mm:ss'
                      : 'hh:mm:ss aaa',
                  ) +
                    ' - ' +
                    (e.finishedAt
                      ? `${format(
                          parseISO(e.finishedAt),
                          currentLocale?.startsWith('ru')
                            ? 'HH:mm:ss'
                            : 'hh:mm:ss aaa',
                        )}: `
                      : '') +
                    parseTherapistActivity(e) +
                    (e.finishedAt
                      ? `, ${t(
                          'pages.clientsShow.duration',
                        )}: ${formatedDuration}`
                      : '')}
                </p>
              );
            })}
          </Timeline.Item>
        ))}
      </Timeline>
    ) : (
      `${t('pages.clientsShow.daysDataCollected')}: 0`
    );
  }, [practiceGroupedByDateData, isLogAscending, i18n.language]);

  const onCloseDrawer = () => {
    therapistActivitySend({
      type: 'TIMED_OUT',
      resource: CLIENT_ACTIVITY_LOG_RESOURCE,
    });
    setOpenDrawer(false);
    therapistActivitySend({
      type: 'LOADED',
      resource: CLIENT_ACTIVITY_CALENDAR_RESOURCE,
      clientId: record?._id,
    });
  };

  const normalizedEmail =
    record?.role === UserRole.NON_MOBILE
      ? record?.email?.startsWith('cc-') &&
        record?.email?.endsWith('@copingcard.com')
        ? ''
        : record?.email?.replace('cc-', '')
      : record?.email;

  const cardsViewedStats = {
    smooth: true,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    lineStyle: {
      lineWidth: 3,
    },
    data:
      // @ts-ignore
      data?.data?.cardsViewedStats?.stats
        // @ts-ignore
        .map((r) => ({
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || [],
  };

  const meditationsStats = {
    smooth: true,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    lineStyle: {
      lineWidth: 3,
    },
    data:
      // @ts-ignore
      data?.data?.meditationsStats?.stats
        // @ts-ignore
        .map((r) => ({
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || [],
  };

  const dayTimeNotificationsStats = {
    smooth: true,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    seriesField: 'type',
    lineStyle: {
      lineWidth: 3,
    },
    data: [
      // @ts-ignore
      ...(data?.data?.dayRemindersTriggeredStats?.stats
        // @ts-ignore
        .map((r) => ({
          type: t('pages.clientsShow.triggered'),
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || []),

      // @ts-ignore
      ...(data?.data?.dayRemindersReactedStats?.stats
        // @ts-ignore
        .map((r) => ({
          type: t('pages.clientsShow.reacted'),
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || []),
    ],
  };

  const locationRemindersStats = {
    smooth: true,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    seriesField: 'type',
    lineStyle: {
      lineWidth: 3,
    },
    data: [
      // @ts-ignore
      ...(data?.data?.locationRemindersTriggeredStats?.stats
        // @ts-ignore
        .map((r) => ({
          type: t('pages.clientsShow.triggered'),
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || []),

      // @ts-ignore
      ...(data?.data?.locationRemindersReactedStats?.stats
        // @ts-ignore
        .map((r) => ({
          type: t('pages.clientsShow.reacted'),
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || []),
    ],
  };

  const phq9Config = {
    smooth: true,
    xField: 'createdAt',
    yField: 'score',
    point: {
      size: 5,
      shape: 'diamond',
    },
    lineStyle: {
      lineWidth: 3,
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', 0],
        end: ['max', 5],
        color: colors['--triad-green-0'],
      },
      {
        type: 'regionFilter',
        start: ['min', 5],
        end: ['max', 10],
        color: colors['--triad-green-1'],
      },
      {
        type: 'regionFilter',
        start: ['min', 10],
        end: ['max', 15],
        color: colors['--primary-1'],
      },
      {
        type: 'regionFilter',
        start: ['min', 15],
        end: ['max', 20],
        color: colors['--primary-2'],
      },
      {
        type: 'regionFilter',
        start: ['min', 20],
        end: ['max', 30],
        color: colors['--primary-0'],
      },
    ],
    data:
      // @ts-ignore
      data?.data?.tests
        // @ts-ignore
        ?.filter((t) => t.type === 0)
        // @ts-ignore
        .map((r) => ({
          score: r.score,
          createdAt: format(
            parseISO(r.createdAt),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || [],
  };

  const gad7Config = {
    smooth: true,
    xField: 'createdAt',
    yField: 'score',
    point: {
      size: 5,
      shape: 'diamond',
    },

    lineStyle: {
      lineWidth: 3,
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', 0],
        end: ['max', 5],
        color: colors['--triad-green-0'],
      },
      {
        type: 'regionFilter',
        start: ['min', 5],
        end: ['max', 10],
        color: colors['--primary-1'],
      },
      {
        type: 'regionFilter',
        start: ['min', 10],
        end: ['max', 15],
        color: colors['--primary-2'],
      },
      {
        type: 'regionFilter',
        start: ['min', 15],
        end: ['max', 30],
        color: colors['--primary-0'],
      },
    ],
    data:
      // @ts-ignore
      data?.data?.tests
        // @ts-ignore
        ?.filter((t) => t.type === 1)
        // @ts-ignore
        .map((r) => ({
          score: r.score,
          createdAt: format(
            parseISO(r.createdAt),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || [],
  };

  const crisisStats = {
    smooth: true,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    lineStyle: {
      lineWidth: 3,
    },
    data:
      // @ts-ignore
      data?.data?.crisisStats?.stats
        // @ts-ignore
        .map((r) => ({
          count: r.count,
          date: format(
            parseISO(r.date),
            currentLocale?.startsWith('ru')
              ? 'yyyy-MM-dd HH:mm'
              : 'yyyy-MM-dd hh:mm aaa',
          ),
        })) || [],
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const getClientListData = (value: Date) => {
    const parsedDate = format(value, 'yyyy-MM-dd');
    return groupedByDateData[parsedDate]?.length
      ? groupedByDateData[parsedDate]
          .sort(
            (a, b) =>
              parseISO(a.createdAt).valueOf() - parseISO(b.createdAt).valueOf(),
          )
          .map((e) => ({
            type: e._to?.startsWith('featureFlags/10') ? 'error' : 'success',
            content:
              format(
                parseISO(e.createdAt),
                currentLocale?.startsWith('ru') ? 'HH:mm:ss' : 'hh:mm:ss aaa',
              ) +
              ' - ' +
              parseEvent(e),
          }))
      : [];
  };

  const getTherapistListData = (value: Date) => {
    const parsedDate = format(value, 'yyyy-MM-dd');
    return practiceGroupedByDateData[parsedDate]?.length
      ? practiceGroupedByDateData[parsedDate]
          .sort(
            (a, b) =>
              parseISO(a.createdAt).valueOf() - parseISO(b.createdAt).valueOf(),
          )
          .map((e) => ({
            type: 'success',
            content:
              format(
                parseISO(e.createdAt),
                currentLocale?.startsWith('ru') ? 'HH:mm:ss' : 'hh:mm:ss aaa',
              ) +
              ' - ' +
              parseTherapistActivity(e),
          }))
      : [];
  };

  const getListData = (value: Date) => {
    return isClientActivity
      ? getClientListData(value)
      : getTherapistListData(value);
  };

  const getMonthData = (value: Date) => {
    const valuesOfTheMonth = Object.entries(
      (isClientActivity ? groupedByDateData : practiceGroupedByDateData) || {},
    ).filter((a) => filterActivitiesByDate(a, value));
    const days = valuesOfTheMonth.length;

    const events = valuesOfTheMonth.reduce((acc, curr) => {
      return acc + (curr[1] as UsageEvent[] | PracticeUsageEvent[])?.length;
    }, 0);

    return {
      days,
      events,
    };
  };

  const getPracticeEventsDuration = (value: Date) => {
    const valuesOfTheMonth = Object.entries(
      practiceGroupedByDateData || {},
    ).filter((a) => filterActivitiesByDate(a, value));

    const duration = valuesOfTheMonth.reduce((acc, curr) => {
      return (
        acc +
        (curr[1] as PracticeUsageEvent[])?.reduce((acc, curr) => {
          return (
            acc +
            (curr.finishedAt
              ? parseISO(curr.finishedAt).valueOf() -
                parseISO(curr.createdAt).valueOf()
              : 0)
          );
        }, 0)
      );
    }, 0);

    return duration;
  };

  const getPracticeEventsDurationFormated = (value: Date) => {
    const duration = getPracticeEventsDuration(value);

    const dur = intervalToDuration({ start: 0, end: duration });
    return [dur.hours, dur.minutes, dur.seconds].map(zeroPad).join(':');
  };

  const monthCellRender = (value: Date) => {
    const { days, events } = getMonthData(value);
    return days ? (
      <div className="notes-month">
        <section>
          <b>{`${t(
            isClientActivity
              ? 'pages.clientsShow.clientActivity'
              : 'pages.clientsShow.therapistActivity',
          )}:`}</b>
        </section>
        <section>{`${days} ${t(
          'pages.clientsShow.daysEventsRecorded',
        )}`}</section>
        <section>{`${events} ${t(
          'pages.clientsShow.eventsRecorded',
        )}`}</section>
        {isClientActivity ? null : (
          <section>{`${t(
            'pages.clientsShow.timeSpent',
          )}: ${getPracticeEventsDurationFormated(value)}`}</section>
        )}
      </div>
    ) : null;
  };

  // @ts-ignore
  const dateCellRender = (value: Date) => {
    const listData = getListData(value);
    return (
      <div>
        {
          // @ts-ignore
          listData.map((item, idx) => (
            <Badge
              key={item.content + idx}
              status={item.type as BadgeProps['status']}
              text={item.content}
              size="small"
              className="calendard-event-badge"
            />
          ))
        }
      </div>
    );
  };

  const onCopyClickedClient = () => {
    const maybeReversedData = isLogAscending
      ? Object.entries(groupedByDateData || {})
          .reverse()
          .reduce((acc, [key, value]) => {
            acc[key] = [...(value || [])].reverse();
            return acc;
          }, {} as { [key: string]: UsageEvent[] })
      : groupedByDateData;

    const text = Object.entries(maybeReversedData || {})
      .map(
        ([d, entries]) =>
          d +
          '\n' +
          ((entries as UsageEvent[]) || [])
            .map((e) => {
              return (
                format(
                  parseISO(e.createdAt),
                  currentLocale?.startsWith('ru') ? 'HH:mm:ss' : 'hh:mm:ss aaa',
                ) +
                ' - ' +
                parseEvent(e)
              );
            })
            .join('\n'),
      )
      .join('\n\n');

    navigator.clipboard.writeText(text);
  };

  const onCopyClickedTherapist = () => {
    const maybeReversedData = isLogAscending
      ? Object.entries(practiceGroupedByDateData || {})
          .reverse()
          .reduce((acc, [key, value]) => {
            acc[key] = [...(value || [])].reverse();
            return acc;
          }, {} as { [key: string]: PracticeUsageEvent[] })
      : practiceGroupedByDateData;

    const text = Object.entries(maybeReversedData || {})
      .map(
        ([d, entries]) =>
          d +
          '\n' +
          ((entries as PracticeUsageEvent[]) || [])
            .map((e) => {
              const dur = e.finishedAt
                ? intervalToDuration({
                    start: parseISO(e.createdAt),
                    end: parseISO(e.finishedAt),
                  })
                : undefined;

              const formatedDuration = dur
                ? [dur.hours, dur.minutes, dur.seconds].map(zeroPad).join(':')
                : '';

              return (
                format(
                  parseISO(e.createdAt),
                  currentLocale?.startsWith('ru') ? 'HH:mm:ss' : 'hh:mm:ss aaa',
                ) +
                ' - ' +
                (e.finishedAt
                  ? `${format(
                      parseISO(e.finishedAt),
                      currentLocale?.startsWith('ru')
                        ? 'HH:mm:ss'
                        : 'hh:mm:ss aaa',
                    )}: `
                  : '') +
                parseTherapistActivity(e) +
                (e.finishedAt
                  ? `, ${t('pages.clientsShow.duration')}: ${formatedDuration}`
                  : '')
              );
            })
            .join('\n'),
      )
      .join('\n\n');

    navigator.clipboard.writeText(text);
  };

  const onCopyClicked = () => {
    return isClientActivity ? onCopyClickedClient() : onCopyClickedTherapist();
  };

  const { data: identity, refetch: refetchIdentity } = useGetIdentity<IUser>();

  const { data: practiceData, refetch: refetchPractice } = useOne<Practice>({
    resource: 'practice',
    id: identity?.db?.practiceId || '',
    queryOptions: {
      enabled: false,
    },
    metaData: {
      fields: [
        '_id',
        'practicePhone',
        'extension',
        {
          practiceLocations: [
            {
              data: ['_id', 'practiceCountry'],
            },
          ],
        },
      ],
    },
  });

  const isUs =
    practiceData?.data?.practiceLocations?.data?.[0]?.practiceCountry === 'US';

  const onModalClose = () => {
    therapistActivitySend({
      type: 'TIMED_OUT',
      resource: CLIENT_ACTIVITY_CALENDAR_RESOURCE,
    });
    setIsModalOpen(false);
    therapistActivitySend({
      type: 'LOADED',
      resource: STATS_VIEW_RESOURCE,
      clientId: record?._id,
    });
  };

  return (
    <Show
      isLoading={isLoading}
      title={<></>}
      breadcrumb={null}
      resource="clients"
      headerButtons={
        <>
          <RefreshButton
            {...(isLoading ? { disabled: true } : {})}
            metaData={metaData}
          />
          <Button
            onClick={() => {
              therapistActivitySend({
                type: 'TIMED_OUT',
                resource: STATS_VIEW_RESOURCE,
              });
              setIsModalOpen(true);
              therapistActivitySend({
                type: 'LOADED',
                resource: CLIENT_ACTIVITY_CALENDAR_RESOURCE,
                clientId: record?._id,
              });
            }}
          >
            {t('pages.clientsShow.activityCalendar')}
          </Button>
        </>
      }
    >
      <Title level={4}>{t('pages.clientsShow.generalTitle')}</Title>

      <Modal
        width={
          (innerWidth || 1000) < 600
            ? innerWidth
              ? innerWidth - 10
              : '99%'
            : '90%'
        }
        visible={isModalOpen}
        onCancel={onModalClose}
        onOk={onModalClose}
        title={
          <div className="calendar-title-container">
            <div className="calendar-title-row">
              <div className="calendar-title">
                {t('pages.clientsShow.activityCalendar')}
              </div>
              <Radio.Group
                onChange={(e) => setIsClientActivity(e.target.value)}
                value={isClientActivity}
              >
                <Radio value={true}>
                  {t('pages.clientsShow.clientActivity')}
                </Radio>
                <Radio value={false}>
                  {t('pages.clientsShow.therapistActivity')}
                </Radio>
              </Radio.Group>
              {!isClientActivity && (
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => setIsManualActivityModalOpen(true)}
                >
                  {t('pages.clientsShow.addActivityManually')}
                </Button>
              )}
              <div className="calendar-title--stats">
                <div className="calendar-title-days">
                  {calendarMode === 'month' ? (
                    t('pages.clientsShow.daysDataCollected') +
                    ': ' +
                    getMonthData(refetchSartDate).days
                  ) : (
                    <RangePicker
                      picker="month"
                      value={[refetchSartDate, new Date()]}
                      onCalendarChange={(dates) => {
                        if (dates?.[0] && dates?.[1]) {
                          setRefetchStartDate(startOfMonth(dates[0]));
                          setEndDate(endOfMonth(dates[1]));
                        }
                      }}
                    />
                  )}
                </div>

                <div className="calendar-title-days">
                  {calendarMode === 'month' && !isClientActivity
                    ? t('pages.clientsShow.therapistTimeSpend') +
                      ': ' +
                      getPracticeEventsDurationFormated(refetchSartDate)
                    : null}
                </div>
              </div>
              {isUs ? (
                <Tooltip title={t('pages.clientsShow.rmtAlert')}>
                  <div
                    style={{
                      color:
                        getMonthData(refetchSartDate).days > 15 ||
                        getPracticeEventsDuration(refetchSartDate) >
                          20 * 60 * 1000
                          ? colors['--primary-0']
                          : '',
                    }}
                  >
                    <AlertOutlined
                      className="calendar-title--alert"
                      style={{
                        color:
                          getMonthData(refetchSartDate).days > 15 ||
                          getPracticeEventsDuration(refetchSartDate) >
                            20 * 60 * 1000
                            ? colors['--primary-0']
                            : '',
                        fontSize: 22,
                      }}
                    />
                    {t('pages.clientsShow.rmtInfo')}
                  </div>
                </Tooltip>
              ) : null}
              <Button
                className="calendar-title--log-button"
                onClick={() => {
                  therapistActivitySend({
                    type: 'TIMED_OUT',
                    resource: CLIENT_ACTIVITY_CALENDAR_RESOURCE,
                  });
                  setOpenDrawer(true);
                  therapistActivitySend({
                    type: 'LOADED',
                    resource: CLIENT_ACTIVITY_LOG_RESOURCE,
                    clientId: record?._id,
                  });
                }}
              >
                {t('pages.clientsShow.activityLog')}
              </Button>
            </div>
            {!isClientActivity && (
              <div className="calendar-title-row">
                <Checkbox
                  checked={showOffsessionData}
                  onChange={() => setShowOffsessionData(!showOffsessionData)}
                >
                  {t('pages.clientsShow.showOffsessionData')}
                </Checkbox>
                <Checkbox
                  checked={showInSessionData}
                  onChange={() => setShowInSessionData(!showInSessionData)}
                >
                  {t('pages.clientsShow.showInSessionData')}
                </Checkbox>
              </div>
            )}
          </div>
        }
      >
        <DateFnsCalendar
          fullscreen
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          validRange={[
            startOfMonth(sub(new Date(), { months: 6 })),
            new Date(),
          ]}
          mode={calendarMode}
          onChange={(date) => {
            setRefetchStartDate(startOfMonth(date));
            setEndDate(endOfMonth(date));
            setTimeout(() => {
              refetchUsageEvents();
              if (calendarMode === 'year') {
                setCalendarMode('month');
              }
            }, 20);
          }}
          value={refetchSartDate}
          onPanelChange={(date, mode) => {
            if (calendarMode === 'month' && mode === 'year') {
              setRefetchStartDate(startOfMonth(sub(date, { months: 3 })));
              setEndDate(endOfMonth(new Date()));
              setTimeout(() => refetchUsageEvents(), 20);
            } else if (calendarMode === 'year' && mode === 'month') {
              setRefetchStartDate(startOfMonth(new Date()));
              setEndDate(endOfMonth(new Date()));
              setTimeout(() => refetchUsageEvents(), 20);
            }
            setCalendarMode(mode);
          }}
        />
      </Modal>

      <Modal
        visible={isManualActivityModalOpen}
        onCancel={onManualActivityModalClose}
        onOk={onManualActivityModalOkClose}
        title={t('pages.clientsShow.addActivityManually')}
        okButtonProps={{
          disabled:
            selectedActivity === undefined ||
            !activityRange?.[0] ||
            !activityRange?.[1],
        }}
        destroyOnClose
      >
        <Form<typeof customActivityInitialValues>
          form={customActivityForm}
          initialValues={{ ...customActivityInitialValues }}
          onFinish={async (values) => {
            if (!values.activityRange[0] || !values.activityRange[1]) {
              return;
            }
            const resource = 'manualActivity/' + values.selectedActivity;

            const remappedActivity = {
              createdAt: startOfMinute(values.activityRange[0]),
              finishedAt: endOfMinute(values.activityRange[1]),
              activity: 0,
              resource,
            };

            const res = await mutatePracticeUsageEvent({
              url: '',
              method: 'post',
              values: {},
              metaData: {
                operation: 'upsertPracticeUsageEvents',
                fields: ['_key'],
                variables: {
                  events: {
                    name: 'events',
                    type: '[PracticeUsageEventInput!]',
                    value: [
                      {
                        ...remappedActivity,
                        _to: record?._id,
                      },
                    ],
                  },
                },
              },
            });

            customActivityForm.resetFields();
            setTimeout(() => {
              refetchPracticeUsageEvents();
            }, 80);
          }}
          onValuesChange={(changedValues, allValues) => {}}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelWrap
        >
          <Form.Item
            name="activityRange"
            label={t('pages.clientsShow.activityRange')}
          >
            <RangePicker
              locale={currentLocale?.startsWith('ru') ? ruPicker : enUSPicker}
              showTime={{
                format: currentLocale?.startsWith('ru') ? 'HH:mm' : 'hh:mm a',
              }}
              format={
                currentLocale?.startsWith('ru')
                  ? 'yyyy-MM-dd HH:mm'
                  : 'yyyy-MM-dd hh:mm a'
              }
              disabledDate={(current) => {
                return current && current > endOfDay(new Date());
              }}
            />
          </Form.Item>

          <Form.Item
            name="selectedActivity"
            label={t('pages.clientsShow.customActivity')}
          >
            <Select
              style={{ minWidth: 120, width: 320 }}
              allowClear
              mode="tags"
              size="large"
              placeholder={t('pages.clientsShow.selectOrTypeActivity')}
              open={isActivitySelectVisible}
              onMouseEnter={() => {
                setIsActivitySelectVisible(true);
              }}
              onChange={(values) => {
                customActivityForm.setFieldsValue({
                  selectedActivity: values?.[values.length - 1],
                });
                setIsActivitySelectVisible(false);
              }}
            >
              {portalEn.pages.clientsShow.therapistCustomActivities.map(
                (i: string, idx) => (
                  <Select.Option key={i} value={idx}>
                    {
                      t(`pages.clientsShow.therapistCustomActivities`, {
                        returnObjects: true,
                      })[idx]
                    }
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>

          {activityRange?.[0] && activityRange?.[1] ? (
            <Form.Item label={t('pages.clientsShow.duration').toUpperCase()}>
              {formatDuration(
                intervalToDuration({
                  start: roundToNearestMinutes(activityRange[0]),
                  end: roundToNearestMinutes(activityRange[1]),
                }),
                {
                  format: ['hours', 'minutes', 'seconds'],
                  locale: currentLocale?.startsWith('ru') ? ru : enUS,
                },
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Modal>

      <Drawer
        title={t(
          isClientActivity
            ? 'pages.clientsShow.clientActivityLog'
            : 'pages.clientsShow.therapistActivityLog',
        )}
        width={(innerWidth || 0) > 720 ? 720 : innerWidth || '100%'}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        autoFocus
        onClose={onCloseDrawer}
        extra={
          <Space className="drawer-header--extra">
            <Button
              icon={
                isLogAscending ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )
              }
              onClick={() => {
                setIsLogAscending(!isLogAscending);
              }}
            >
              {t('common.sort')}
            </Button>
            <Button icon={<CopyOutlined />} onClick={onCopyClicked}>
              {t('common.copyToClipboard')}
            </Button>
            <Button onClick={onCloseDrawer}>{t('common.cancel')}</Button>
            <Button
              disabled={true}
              onClick={() => {
                console.log('implement me');
              }}
              type="primary"
            >
              {t('common.save')}
            </Button>
          </Space>
        }
      >
        <Spin spinning={!!usageEventsLoading} size="large" />
        {isClientActivity ? groupedByDate : practiceGroupedByDate}
      </Drawer>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title="ID"
            value={(record?._id || '')?.replace('users/', '')}
            loading={isLoading}
            groupSeparator={''}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.firstName')}
            value={record?.firstName || ''}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.lastName')}
            value={record?.lastName || ''}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          {normalizedEmail ? (
            <Statistic
              title={t('pages.clientsShow.email')}
              value={normalizedEmail || ''}
              loading={isLoading}
            />
          ) : null}
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pages.clientsShow.baseStatsTitle')}</Title>
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.accountCreated')}
            value={
              record?.createdAt
                ? format(parseISO(record.createdAt), 'yyyy-MM-dd')
                : ''
            }
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.copingCardsCount')}
            // @ts-ignore
            value={record?.thoughts?.count}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.enabledDayRemindersCount')}
            // @ts-ignore
            value={record?.enabledDayRemindersCount?.enabled || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={t('pages.clientsShow.enabledLocationRemindersCount')}
            // @ts-ignore
            value={record?.enabledLocationRemindersCount?.enabled || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          {/* <Statistic
            title={t('pages.clientsShow.email')}
            value={record?.email || ''}
            loading={isLoading}
          /> */}
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pages.clientsShow.usageGraphs')}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>{t('insights.cardsFlipped')}</Title>
          <Line {...(cardsViewedStats as unknown as LineConfig)} />
        </Col>
        <Col span={12}>
          <Title level={5}>{t('insights.meditationsTitle')}</Title>
          <Line {...(meditationsStats as unknown as LineConfig)} />
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pages.clientsShow.reminderdsTitle')}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>{t('insights.dayRemindersTitle')}</Title>
          <Line {...(dayTimeNotificationsStats as unknown as LineConfig)} />
        </Col>
        <Col span={12}>
          <Title level={5}>{t('insights.locationRemindersTitle')}</Title>
          <Line {...(locationRemindersStats as unknown as LineConfig)} />
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pages.clientsShow.testsTitle')}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>{t('pages.clientsShow.phq9Description')}</Title>
          <Line {...(phq9Config as unknown as LineConfig)} />
        </Col>
        <Col span={12}>
          <Title level={5}>{t('pages.clientsShow.gad7Description')}</Title>
          <Line {...(gad7Config as unknown as LineConfig)} />
        </Col>
      </Row>
      <Divider />
      <Title level={4}>{t('pages.clientsShow.crisisTitle')}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>{t('pages.clientsShow.crisisDescription')}</Title>
          <Line {...(crisisStats as unknown as LineConfig)} />
        </Col>
        <Col span={12}></Col>
      </Row>
    </Show>
  );
};
