import { TFunction } from 'i18next';
import * as yup from 'yup';
import { setLocale } from 'yup';
import { MakeUndefinesOptional } from '@cc/utils';

export type ActivatingEventValidator = yup.Asserts<
  ReturnType<typeof getValidators>['activatingEventValidator']
>;

export type ActivatingEventWithLocationId = yup.Asserts<
  ReturnType<typeof getValidators>['activatingEventValidatorWithLocationId']
>;

export type DayRemindersValidator = yup.Asserts<
  ReturnType<typeof getValidators>['dayRemindersValidator']
>;

export type PrivacySettings = MakeUndefinesOptional<
  yup.Asserts<ReturnType<typeof getValidators>['privacySettingsValidator']>
>;

export type ThoughtValidator = yup.Asserts<
  ReturnType<typeof getValidators>['thoughtValidator']
>;

export type LocationValidator = yup.Asserts<
  ReturnType<typeof getValidators>['locationValidator']
>;
export type DateTimeReminderValidator = yup.Asserts<
  ReturnType<typeof getValidators>['dateTimeReminderValidator']
>;
export type TestValidator = yup.Asserts<
  ReturnType<typeof getValidators>['testValidator']
>;

export type PracticeLocationValidator = yup.Asserts<
  ReturnType<typeof getValidators>['practiceLocationValidator']
>;

export type PracticeValidator = yup.Asserts<
  ReturnType<typeof getValidators>['practiceValidator']
>;

export type ProfileValidator = yup.Asserts<
  ReturnType<typeof getValidators>['profileValidator']
>;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const urlRegExp =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const getValidators = (t: TFunction) => {
  setLocale({
    mixed: {
      required: 'validators.required',
    },
    string: {
      email: 'validators.email',

      min: ({ min }: { min: number }) => ({
        key: 'validators.stringMin',
        values: { min },
      }),
      max: ({ max }: { max: number }) => ({
        key: 'validators.stringMax',
        values: { max },
      }),
    },
  });

  const tt = (error: string | { key: 'string'; values: {} } | undefined) => {
    if (error === undefined) return '';
    if (typeof error !== null && typeof error === 'object') {
      return t(error.key, error.values);
    }
    return t(error as string);
  };

  const _id = yup.string().required();
  const id = yup.string().required();
  const userId = yup.string().trim().required();

  const emailValidator = yup
    .string()
    .trim()
    .label(t('labels.email'))
    .email()
    .required();

  const passwordValidator = yup
    .string()
    .label(t('labels.password'))
    .min(8)
    .max(30)
    .required();

  const retypePasswordValidator = yup
    .string()
    .min(8)
    .required()
    .oneOf([yup.ref('password'), null], 'validators.retypePassword');

  const resendEmailValidator = yup.object({
    email: emailValidator,
  });

  const signInValidator = yup.object({
    email: emailValidator,
    password: passwordValidator,
  });

  const signUpValidator = yup.object({
    email: emailValidator,
    password: passwordValidator,
    retypePassword: retypePasswordValidator,
  });

  const resetPasswordValidator = yup.object({
    password: passwordValidator,
    retypePassword: retypePasswordValidator,
  });

  const nameValidator = yup.string().trim().min(1);

  const languageValidator = yup.mixed().oneOf(['en', 'ru']);

  const settingsValidator = yup.object({
    firstName: nameValidator.required(),
    lastName: nameValidator.required(),
    displayName: nameValidator.nullable(),
    lng: languageValidator,
    timezone: yup.string().required(),
  });

  const namesValidator = yup.object({
    firstName: nameValidator.required(),
    lastName: nameValidator.required(),
  });

  const settingsValidatorObject = yup.object({
    settings: settingsValidator,
  });

  const privacySettingsValidator = yup.object({
    isPrivacyAccepted: yup.boolean().nullable(),
    isTermsAccepted: yup.boolean().nullable(),
    isProductEmailNotifyOn: yup.boolean().nullable(),
    isProductPushNotifyOn: yup.boolean().nullable(),
    isErrorInfoCollectionOn: yup.boolean().nullable(),
    isGAUsageCollectionOn: yup.boolean().nullable(),
    isKeepLoggedInOn: yup.boolean().nullable(),
  });

  const privacySettingsValidatorObject = yup.object({
    privacySettings: privacySettingsValidator,
  });

  const intervalValidator = yup
    .object({
      start: yup.date().required(),
      end: yup
        .date()
        .when('start', (start, schema) => start && schema.min(start)),
    })
    .nullable();

  const emotion = yup.object({
    id: yup.number().required(),
    strength: yup.number().required(),
  });

  const activatingEventValidator = yup.object({
    description: yup.string().trim().required().min(2),
    interval: intervalValidator,
    participantIds: yup.array().of(yup.string().required()).nullable(),
    imageUrl: yup.array().of(yup.string().url().required()).nullable(),

    emotions: yup.array().of(emotion.required()).required(),
    feelings: yup.array().of(yup.number().required()).required(),
    bodyPartsFeelings: yup.number().integer().required(),
    behaviors: yup.string().trim().nullable(),
  });

  const activatingEventValidatorWithLocationId = activatingEventValidator.shape(
    {
      locationId: yup.string(),
    },
  );

  const activatingEventValidatorInput = yup.object({
    activatingEvent: activatingEventValidatorWithLocationId,
  });

  const thoughtBasic = yup.object({
    thought: yup.string().trim().defined().min(0),
    thoughtBelief: yup.number().required(),
  });

  const thoughtsForOrAgainst = yup.array().of(yup.string().trim().required());
  const thoughtsForOrAgainstEmpty = yup.array().of(yup.string().trim());

  const thoughtTestResult = yup.object({
    initialThoughtBelief: yup.number().required(),
    initialNegativeEmotionStrenght: yup.number().required(),
  });

  // TODO: add repetition pattern?
  const dateTimeReminderValidator = yup.object({
    userId,
    date: yup.date().required(),
    enabled: yup.bool().required(),
    for: yup.string().nullable(),
  });

  const testValidator = yup.object({
    userId,
    type: yup.number().required(),
    answers: yup.array().of(yup.number().nullable().defined()).required(),
    score: yup.number().required(),
  });

  const timeReminder = yup.object({
    time: yup
      .object({
        hours: yup.number().min(0).max(23).required(),
        minutes: yup.number().min(0).max(59).required(),
      })
      .required(),
    enabled: yup.bool().required(),
  });

  const dayRemindersValidatorBase = yup.object({
    morning: timeReminder.required(),
    day: timeReminder.required(),
    evening: timeReminder.required(),
  });

  const dayRemindersValidator = dayRemindersValidatorBase.shape({
    thoughtId: yup.string().trim().required(),
  });

  const dayRemindersUpdateValidator = dayRemindersValidatorBase.shape({
    _id,
  });

  const dayRemindersValidatorInput = yup.object({
    dayReminders: dayRemindersValidator,
  });
  const dayRemindersUpdateValidatorInput = yup.object({
    dayReminders: dayRemindersUpdateValidator,
  });

  const thoughtValidator = yup.object({
    activatingEventId: yup.string().trim().nullable(),
    automaticThought: yup.string().trim().required(),
    thoughtBelief: yup.number().required(),
    thoughtsFor: thoughtsForOrAgainst.required(),
    thoughtsForAlternativeThoughts: yup
      .array()
      .of(yup.string().trim().nullable().defined())
      .nullable(),
    thoughtsAgainst: thoughtsForOrAgainst.required(),
    realisticThoughts: yup.array().of(thoughtBasic.required()).required(),
    cognitiveDistortions: yup.array().of(yup.string().required()).nullable(),
    result: thoughtTestResult.required(),
    cardName: yup.string().trim().nullable(),
    categoriesIds: yup.array().of(yup.number().required()).nullable(),
  });

  const thoughtShortenedValidator = yup.object({
    activatingEventId: yup.string().trim().nullable(),
    automaticThought: yup.string().trim(),
    thoughtBelief: yup.number().required(),
    thoughtsFor: thoughtsForOrAgainstEmpty.required(),
    thoughtsForAlternativeThoughts: yup
      .array()
      .of(yup.string().trim().nullable().min(0))
      .nullable(),
    thoughtsAgainst: thoughtsForOrAgainstEmpty.required(),
    cognitiveDistortions: yup.array().of(yup.string().required()).nullable(),
    realisticThoughts: yup.array().of(thoughtBasic.required()).required(),
    result: thoughtTestResult,
    cardName: yup.string().trim().nullable(),
    categoriesIds: yup.array().of(yup.number().required()).nullable(),
  });

  const thoughtShortenedUpdateValidator = thoughtShortenedValidator.shape({
    _id,
  });

  const thoughtUpdateValidator = thoughtValidator.shape({
    _id,
  });

  const thoughtsValidator = yup.array().of(thoughtValidator).required();

  const automaticThoughtsValidator = yup.object({
    automaticThoughts: thoughtsValidator,
  });

  const thoughtValidatorInput = yup.object({ thought: thoughtValidator });
  const thoughtUpdateValidatorInput = yup.object({
    thought: thoughtUpdateValidator,
  });

  const thoughtShortenedValidatorInput = yup.object({
    thought: thoughtShortenedValidator,
  });
  const thoughtShortenedUpdateValidatorInput = yup.object({
    thought: thoughtShortenedUpdateValidator,
  });

  const locationValidatorBase = yup.object({
    name: yup.string().trim().required(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  });

  const locationValidator = locationValidatorBase;

  const locationUpdateValidator = locationValidatorBase.shape({
    _id,
  });

  const locationValidatorInput = yup.object({
    location: locationValidatorBase,
  });

  const locationUpdateValidatorInput = yup.object({
    location: locationUpdateValidator,
  });

  const websiteFormValidator = yup.object({
    email: yup.string().trim().required(),
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    lng: yup.string().trim().required(),
    phone: yup.string().trim().matches(phoneRegExp),
    companyName: yup.string().trim(),
    companyWebsite: yup.string().trim().url(),
    role: yup.string().trim(),
    message: yup.string().trim(),
  });

  const inviteTherapistValidator = yup.object({
    email: emailValidator,
    yourName: nameValidator.required(),
    yourLastName: nameValidator.required(),
    firstName: nameValidator.required(),
    lastName: nameValidator.required(),
  });

  const practiceInviteValidator = yup.object({
    email: emailValidator,
    firstName: nameValidator.required(),
    lastName: nameValidator.required(),
  });

  const practiceUserPersonalInfoValidator = yup.object({
    firstName: nameValidator.required(),
    lastName: nameValidator.required(),
    phone: yup.string().trim().required(),
    extension: yup.string().trim().nullable(),
    country: yup.string().trim().required(),
    clientInviteToken: yup.string().trim(),
  });

  const practiceLocationValidator = yup.object({
    practiceAddress1: yup.string().trim().required(),
    practiceAddress2: yup.string().trim().nullable(),
    practiceCity: yup.string().trim().required(),
    practiceState: yup.string().trim().required(),
    practiceZip: yup.string().trim().required(),
    practiceCountry: yup.string().trim().required(),
  });

  const practiceValidator = yup.object({
    practiceSize: yup.string().trim().required(),
    npi: yup.string().trim().nullable(),
    practiceName: yup.string().trim().required(),
    practicePhone: yup.string().trim().required(),
    extension: yup.string().trim().nullable(),
    practiceUrl: yup.string().trim().url().nullable(),
    practiceEmail: yup.string().trim().email().required(),
    practiceDescription: yup.string().trim().required(),
    acceptedInsurances: yup.array().of(yup.string().required()).nullable(),
    isSaasAgreementAccepted: yup.bool().required(),
  });

  const practiceAndLocationValidator = practiceValidator.concat(
    practiceLocationValidator,
  );

  const portalThoughtFormValidator = yup.object({
    for: yup.string().trim().required(),
    alternativeThought: yup.string().trim().nullable().defined(),
  });

  const portalDysThoughtTestValidator = yup.object({
    // Activating Event
    clientId: yup.string().required(),
    description: yup.string().trim().required().min(2),
    emotions: yup.array().of(emotion.required()).required(),
    feelings: yup.array().of(yup.number().required()).required(),
    bodyPartsFeelings: yup.number().integer().required(),
    // not used, but adding for consistency
    interval: intervalValidator,
    participantIds: yup.array().of(yup.string().required()).nullable(),
    imageUrl: yup.array().of(yup.string().url().required()).nullable(),
    // done

    // Thought
    automaticThought: yup.string().trim().required(),
    thoughtBelief: yup.number().required(),
    // thoughtsFor: yup.array().of(portalThoughtFormValidator).required(),
    // thoughtsAgainst: thoughtsForOrAgainst.required(),
    realisticThoughts: yup.array().of(thoughtBasic.required()).required(),
    cognitiveDistortions: yup.array().of(yup.string()).nullable(),
    result: thoughtTestResult.required(),
    cardName: yup.string().trim().nullable(),
    categoriesIds: yup.array().of(yup.number().required()).nullable(),

    // Reminders
    dayReminders: dayRemindersValidator,
  });

  const portalThoughtUpdateValidator = yup.object({
    automaticThought: yup.string().trim().required(),
    thoughtBelief: yup.number().required(),
    thoughtsFor: yup.array().of(portalThoughtFormValidator).required(),
    thoughtsAgainst: thoughtsForOrAgainst.required(),
    cognitiveDistortions: yup.array().of(yup.string()).nullable(),
    realisticThoughts: yup.array().of(thoughtBasic.required()).required(),
  });

  const licenseValidator = yup
    .object({
      state: yup.string().trim().required(),
      number: yup.string().trim().required(),
    })
    .nullable();

  const profileValidator = yup.object({
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    wantsReferrals: yup.bool().required(),
    approvedForReferrals: yup.bool().required(),
    formats: yup.array().of(yup.string().trim().required()).required(),
    mentalHealthRole: yup.string().required(),
    title: yup.string().trim().nullable(),
    licensedInStates: yup.array().of(yup.string().trim().required()).nullable(),
    licenses: yup.array().of(licenseValidator).nullable(),
    acceptingClients: yup.string().trim().required(),
    yearsInPractice: yup.number().required(),
    imageUrl: yup.string().trim().nullable(),
    about: yup.string().trim().required(),
    education: yup.array().of(yup.string().trim().required()).required(),
    languages: yup.array().of(yup.string().trim().required()).required(),
    approaches: yup.array().of(yup.string().trim().required()).required(),
    costPerSessionFrom: yup.number().required(),
    costPerSessionTo: yup.number().required(),
    currency: yup.string().trim().required(),
    slidingScale: yup.boolean().required(),
    modalities: yup.array().of(yup.string().trim().required()).required(),
    clientAges: yup.array().of(yup.string().trim().required()).required(),
    clientIssues: yup.array().of(yup.string().trim().required()).required(),
    linkToOtherProfile: yup.string().trim().nullable(),
    phone: yup.string().trim().required(),
    extension: yup.string().trim().nullable(),
    freeInitialConsulation: yup.boolean().required(),
  });

  return {
    inviteTherapistValidator,
    practiceInviteValidator,
    signInValidator,
    emailValidator,
    passwordValidator,
    signUpValidator,
    resetPasswordValidator,
    resendEmailValidator,
    tt,
    settingsValidator,
    settingsValidatorObject,
    privacySettingsValidator,
    privacySettingsValidatorObject,
    activatingEventValidator,
    activatingEventValidatorWithLocationId,
    activatingEventValidatorInput,
    namesValidator,
    thoughtValidator,
    thoughtUpdateValidator,
    thoughtsValidator,
    thoughtValidatorInput,
    thoughtUpdateValidatorInput,
    thoughtShortenedValidator,
    thoughtShortenedUpdateValidator,
    thoughtShortenedValidatorInput,
    thoughtShortenedUpdateValidatorInput,
    automaticThoughtsValidator,
    dayRemindersValidator,
    dayRemindersValidatorInput,
    dayRemindersUpdateValidator,
    dayRemindersUpdateValidatorInput,
    locationValidator,
    locationValidatorInput,
    locationUpdateValidatorInput,
    dateTimeReminderValidator,
    testValidator,
    websiteFormValidator,

    practiceUserPersonalInfoValidator,
    practiceLocationValidator,
    practiceValidator,
    practiceAndLocationValidator,
    portalDysThoughtTestValidator,
    portalThoughtUpdateValidator,
    profileValidator,
  };
};
