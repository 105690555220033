import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as plantumlEncoder from 'plantuml-encoder';
import { useTranslation } from 'react-i18next';
import { Create, useForm } from '@pankod/refine-antd';
import { debounce } from 'lodash';
import routerProvider from '@pankod/refine-react-router-v6';
import qs from 'qs';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
  useWarnAboutChange,
} from '@pankod/refine-core';
import { SupportingCycle } from '../../schema/be-graphql-generated';
import * as Sentry from '@sentry/react';
import { useWatch } from 'antd/lib/form/Form';
import { generatePlantUmlText } from '@cc/utils';

import './styles.less';
import { useClientOptions } from '../../hooks/client-options';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { dbCols } from '@cc/schema';

const { Title } = Typography;

export const SupportingCyclesCreate = (props: any) => {
  const [umlText, setUmlText] = useState('');

  const { t } = useTranslation();
  const locale = useGetLocale();
  const currentLocale = locale();

  const location = routerProvider.useLocation();
  const { setWarnWhen } = useWarnAboutChange();

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const { clientId, cycleId: locationCycleId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const [encodedText, setEncodedText] = useState('');
  const [cycleId, setCycleId] = useState(locationCycleId as string | undefined);

  useActivityTracking(cycleId ? cycleId : `${dbCols.supportingCycles}/create`);

  const initialValues = useMemo(
    () => ({
      userId: therapistActivityState.context.filterClientId || clientId,
      name: '',
      thoughts: [
        {
          thought: '' as string,
          edges: [false] as boolean[],
        },
      ],
    }),
    [therapistActivityState.context.filterClientId, clientId],
  );

  const { formProps, saveButtonProps } = useForm<typeof initialValues>({
    warnWhenUnsavedChanges: true,
  });

  const userId = useWatch('userId', formProps.form);

  useEffect(() => {
    if (userId && therapistActivityState.context.filterClientId !== userId) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId: userId,
      });
    }
  }, [userId]);

  const onFinish = (values: typeof initialValues) => {
    setUmlText(generatePlantUmlText(values));
  };

  const {
    data: supportingCycleData,
    isLoading: supportingCycleLoading,
    refetch: refetchSupportingCycle,
    error: supportingCycleError,
  } = useCustom<SupportingCycle>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'supportingCycle',
      fields: [
        '_id',
        'name',
        {
          thoughts: ['thought', 'edges'],
        },
        'userId',
      ],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: cycleId,
        },
      },
    },
  });

  useEffect(() => {
    if (supportingCycleData?.data) {
      formProps?.form?.setFieldsValue({
        name: supportingCycleData?.data?.name,
        thoughts: supportingCycleData?.data?.thoughts,
      });
      // @ts-ignore
      onFinish(formProps?.form?.getFieldsValue());

      if (supportingCycleData?.data?._id) {
        therapistActivitySend({
          type: 'LOADED',
          resource: supportingCycleData?.data?._id,
          clientId: supportingCycleData?.data?.userId || '',
        });
      }
    }
  }, [supportingCycleData]);

  const {
    data: supportingCycleTemplatesData,
    isLoading: supportingCycleTemplatesLoading,
    refetch: refetchSupportingCycleTemplates,
    error: supportingCycleTemplatesError,
  } = useCustom<SupportingCycle[]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'supportingCycles',
      fields: [
        {
          data: [
            '_id',
            'name',
            {
              thoughts: ['thought', 'edges'],
            },
            'templateLanguage',
          ],
        },
        'count',
      ],
      variables: {
        where: {
          name: 'where',
          type: 'JSON',
          value: {
            isTemplate_eq: true,
            templateLanguage_eq: currentLocale || 'en',
          },
        },
      },
    },
  });

  const [availableTemplates, setAvailableTemplates] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // useEffect to generate templateSelectOptions from supportingCycleTemplatesData
  useEffect(() => {
    if (supportingCycleTemplatesData) {
      // @ts-ignore
      const options = supportingCycleTemplatesData?.data?.data?.map((c) => ({
        label: c.name || '',
        value: c._id,
      }));
      setSelectedTemplate(null);
      setAvailableTemplates(options);
    }
  }, [supportingCycleTemplatesData]);

  const {
    mutate: upsertSupportingCycle,
    data: upsertSupportingCycleData,
    isLoading: upsertSupportingCycleLoading,
    error: upsertSupportingCycleError,
  } = useCustomMutation<{ supportingCycle: SupportingCycle }>();

  const saveSupportingCycle = async () => {
    // @ts-ignore
    const values: typeof initialValues =
      formProps?.form?.getFieldsValue() || {};

    const { userId, ...valuesToSave } = values;

    if (!userId) {
      return;
    }

    if (Object.keys(valuesToSave).length === 0) {
      return;
    }

    try {
      const res = await upsertSupportingCycle({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertSupportingCycle',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'SupportingCycleInput',
              value: {
                data: valuesToSave,
                where: {
                  clientId: userId,
                  _id: cycleId,
                },
              },
            },
          },
        },
      });
    } catch (error) {
      message.success(t('pages.supportingCycle.saveError'));
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (upsertSupportingCycleData) {
      setWarnWhen(false);
      message.success(t('pages.supportingCycle.saveSuccess'));
    }

    // @ts-ignore
    if (upsertSupportingCycleData?.data?._id) {
      if (!cycleId) {
        // @ts-ignore
        setCycleId(upsertSupportingCycleData?.data?._id);
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: upsertSupportingCycleData?.data?._id,
        });
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          // @ts-ignore
          resource: upsertSupportingCycleData?.data?._id,
        });
      }
    }
  }, [upsertSupportingCycleData]);

  useEffect(() => {
    const loadSupportingCycle = async () => {
      if (cycleId) {
        const res = await refetchSupportingCycle();
        const currentValues = formProps?.form?.getFieldsValue();

        if (res?.data?.data) {
          formProps?.form?.setFieldsValue({
            ...currentValues,
            // @ts-ignore
            ...(res?.data?.data || {}),
          });
        }
      }
    };
    loadSupportingCycle();
  }, [cycleId]);

  const saveData = useCallback(
    debounce(
      async (
        changedValues: Partial<typeof initialValues>,
        allValues: typeof initialValues,
      ) => {
        onFinish(allValues);
      },
      700,
    ),
    [],
  );

  const onValuesChange = (
    changedValues: Partial<typeof initialValues>,
    allValues: typeof initialValues,
  ) => {
    saveData(changedValues, allValues);
    formProps?.onValuesChange?.(changedValues, allValues);
  };

  useEffect(() => {
    const encoded = plantumlEncoder.encode(umlText);
    setEncodedText(encoded);
  }, [umlText]);

  return (
    <Create
      title={t('pages.supportingCycle.supportingCycles')}
      isLoading={
        clientsLoading ||
        supportingCycleLoading ||
        supportingCycleTemplatesLoading
      }
      saveButtonProps={{
        ...saveButtonProps,
        loading: upsertSupportingCycleLoading,
        onClick: saveSupportingCycle,
        disabled: !userId || !umlText,
      }}
    >
      <Form
        // @ts-ignore
        form={formProps?.form}
        name="supportingCycles"
        onFinish={onFinish}
        initialValues={initialValues}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 21 }}
        onValuesChange={onValuesChange}
        labelWrap
      >
        <div className="rowContainer">
          <Form.Item
            className="select-client-item"
            label={
              <>
                {t('createThought.selectClient')}
                <Tooltip title={t('createThought.selectClientTip')}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            }
            name="userId"
            style={{ display: userId ? 'none' : 'block' }}
            required
            labelCol={{ span: 7 }}
          >
            <Select
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </Form.Item>
          {availableTemplates?.length ? (
            <Form.Item
              label={t('pages.supportingCycle.tempalteLabel')}
              style={{ marginLeft: 'auto' }}
              labelCol={{ span: 7 }}
            >
              <Select
                className="startFromTemplateSelect"
                showSearch
                value={selectedTemplate}
                filterOption={(input, option) =>
                  !!option?.label.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setSelectedTemplate(value);
                  const template: SupportingCycle =
                    // @ts-ignore
                    supportingCycleTemplatesData?.data?.data.find(
                      // @ts-ignore
                      (t) => t._id === value,
                    );

                  const currentValues = formProps?.form?.getFieldsValue();
                  formProps?.form?.setFieldsValue({
                    ...currentValues,
                    thoughts: template.thoughts,
                    name: template.name,
                  });

                  setTimeout(
                    () =>
                      // @ts-ignore
                      onFinish(formProps?.form?.getFieldsValue()),
                    10,
                  );
                }}
                options={availableTemplates}
                placeholder={t('pages.supportingCycle.selectTemplate')}
              />
            </Form.Item>
          ) : null}
        </div>
        <Form.Item
          label={t('pages.supportingCycle.cycleName')}
          name="name"
          className="card-form-item"
          labelCol={{ span: 2 }}
        >
          <Input placeholder={t('pages.supportingCycle.cycleName')} />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div>
            <Title
              level={5}
              style={{
                minWidth: 500,
              }}
            >
              {t('pages.supportingCycle.thoughts')}:
            </Title>
          </div>
        </div>
        <Form.List name="thoughts">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div className="thoughtRow" key={key}>
                      <div className="thoughtWithMinus">
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ margin: '5px 0 0 10px', flex: 1 }}
                            onClick={() => {
                              // @ts-ignore
                              const currentValues: typeof initialValues =
                                formProps?.form?.getFieldsValue() || {};
                              const thoughts = currentValues.thoughts || [];
                              const thoughtsBefore = thoughts.slice(0, key);
                              const thoughtsAfter = thoughts.slice(key + 1);

                              const thoughtsBeforeWithCorrectEdges =
                                thoughtsBefore.map((t) => {
                                  const edges = t.edges || [];
                                  const edgesBefore = edges.slice(0, key);
                                  const edgesAfter = edges.slice(key + 1);
                                  return {
                                    ...t,
                                    edges: [...edgesBefore, ...edgesAfter],
                                  };
                                });

                              const thoughtsAfterWithCorrectEdges =
                                thoughtsAfter.map((t, index) => {
                                  const edges = t.edges || [];
                                  const edgesBefore = edges.slice(0, key);
                                  const edgesAfter = edges.slice(key + 1);
                                  return {
                                    ...t,
                                    edges: [...edgesBefore, ...edgesAfter],
                                  };
                                });
                              remove(name);
                              // Note: This needs to be done in a timeout because otherwise
                              // the form will introduce a new value for edges and empty thought
                              setTimeout(() => {
                                formProps?.form?.setFieldValue('thoughts', [
                                  ...thoughtsBeforeWithCorrectEdges,
                                  ...thoughtsAfterWithCorrectEdges,
                                ]);
                                const updatedValues =
                                  formProps?.form?.getFieldsValue();
                                // @ts-ignore
                                onFinish(updatedValues);
                              }, 10);
                            }}
                          />
                        ) : null}
                        <Form.Item
                          {...restField}
                          name={[name, 'thought']}
                          rules={[
                            { required: true, message: 'Missing thought' },
                          ]}
                          label={key + 1}
                          style={{ flex: 5 }}
                        >
                          <Input.TextArea
                            className="thoughtRowInput"
                            placeholder={t('pages.supportingCycle.thoughts')}
                            autoSize={{ minRows: 1, maxRows: 6 }}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item
                        label={t('pages.supportingCycle.relations')}
                        style={{ flex: 5, minWidth: 320 }}
                        labelCol={{ span: 4 }}
                      >
                        <Form.List name={[name, 'edges']}>
                          {(edges, { add: addEdge, remove: removeEdge }) => {
                            return (
                              <Space
                                key={key}
                                style={{
                                  display: 'flex',
                                  marginBottom: 8,
                                }}
                                align="baseline"
                              >
                                {edges.map(({ key, name, ...restField }) => (
                                  <Tooltip
                                    key={`tooltip-${key}`}
                                    title={formProps?.form?.getFieldValue([
                                      'thoughts',
                                      key,
                                      'thought',
                                    ])}
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>{key + 1}</Checkbox>
                                    </Form.Item>
                                  </Tooltip>
                                ))}
                              </Space>
                            );
                          }}
                        </Form.List>
                      </Form.Item>
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    onClick={() => {
                      const values = formProps?.form?.getFieldsValue();

                      // @ts-ignore
                      const thoughtCount = values?.thoughts?.length || 0;
                      // @ts-ignore
                      const currentThoughts = values.thoughts;

                      currentThoughts?.forEach((thought: any) => {
                        thought.edges.push(false);
                      });

                      formProps?.form?.setFieldValue(
                        'thoughts',
                        currentThoughts,
                      );

                      add({
                        thought: '',
                        edges: new Array(thoughtCount + 1).fill(false),
                      });
                    }}
                    icon={<PlusOutlined />}
                  >
                    {t('pages.supportingCycle.addField')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        {umlText && encodedText && (
          <img
            src={`http://www.plantuml.com/plantuml/img/${encodedText}`}
            alt="UML"
          />
        )}
      </Form>
    </Create>
  );
};
