import { CSSProperties } from 'react';
import { colors } from '../../styles/colors';

export const layoutStyles: CSSProperties = {
  background: `radial-gradient(50% 50% at 50% 50%, ${colors['--triad-blue-2']} 0%, ${colors['--triad-blue-4']} 100%)`,
  backgroundSize: 'cover',
};

export const containerStyles: CSSProperties = {
  maxWidth: '408px',
  margin: 'auto',
};

export const titleStyles: CSSProperties = {
  textAlign: 'center',
  color: '#626262',
  fontSize: '30px',
  letterSpacing: '-0.04em',
};

export const imageContainer: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '28px',
};

export const langaugeSelector: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
};

export const googleButton: CSSProperties = {
  margin: '16px 0',
};

export const languageDropdown: CSSProperties = {
  marginLeft: 'auto',
};
