import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import 'antd/lib/date-picker/style/index';
import * as React from 'react';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';

export interface TimePickerProps
  extends Omit<PickerTimeProps<Date>, 'picker'> {}

export const DateFnsDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const DateFnsTimePicker = React.forwardRef<any, TimePickerProps>(
  (props, ref) => {
    return (
      // @ts-ignore
      <DateFnsDatePicker {...props} picker="time" mode={undefined} ref={ref} />
    );
  },
);

DateFnsTimePicker.displayName = 'TimePicker';

// export const DateFnsTimePicker = generatePicker<Date>(dateFnsGenerateConfig);
export const DateFnsCalendar = generateCalendar<Date>(dateFnsGenerateConfig);
