import {
  BulbOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  LineChartOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { dbCols, exposureInitialValues, User, UserRole } from '@cc/schema';
import {
  Button,
  Create,
  Divider,
  EditButton,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Radio,
  SaveButton,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useGetLocale,
  useList,
  useWarnAboutChange,
} from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import * as Sentry from '@sentry/react';
import { useActor } from '@xstate/react';
import { useWatch } from 'antd/lib/form/Form';
import { differenceInHours } from 'date-fns';
import { debounce } from 'lodash';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'rooks';
import { TherapistActivityReactContext } from '../../App';
import { useCcEditableTable } from '../../helpers';
import { useSubscriptionWarnings } from '../../hooks';
import { useClientOptions } from '../../hooks/client-options';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { IUser } from '../../providers';
import { Exposure, ExposureStimulus } from '../../schema/be-graphql-generated';
import './styles.less';

const { Title } = Typography;

export const ExposureCreate = () => {
  const { t, i18n } = useTranslation();
  const location = routerProvider.useLocation();

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const subWarnings = useSubscriptionWarnings();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const {
    data: clients,
    isError: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useList<{ data: User[]; count: number }>({
    resource: 'clients',
    config: {
      pagination: {
        pageSize: subWarnings.clientsPurchased * 4, // Some customers who became non Premium via practice will be hidden
      },
    },
    metaData: {
      fields: [
        {
          data: [
            '_id',
            'firstName',
            'lastName',
            'email',
            'accessLevel',
            {
              access: [
                'allowView',
                'allowEdit',
                'allowAdminView',
                'allowAdminEdit',
              ],
            },
          ],
        },
        'count',
      ],
    },
  });
  const { memoizedClienOptions } = useClientOptions();

  const { setWarnWhen } = useWarnAboutChange();

  const {
    data: identity,
    remove,
    refetch: refetchIdentity,
  } = useGetIdentity<IUser>();

  const { clientId: locationClientId, exposureId: savedExposureId } =
    useMemo(
      () =>
        qs.parse(
          location.search.charAt(0) === '?'
            ? location.search.slice(1)
            : location.search,
        ),
      [location.search],
    ) || {};

  const { innerWidth } = useWindowSize();

  const [clientId, setClientId] = useState<string | undefined>(
    therapistActivityState.context.filterClientId ||
      (locationClientId as string),
  );

  const [form] = Form.useForm<typeof exposureInitialValues>();

  const [isGenerating, setIsGenerating] = useState(false);
  const type = useWatch('type', form);
  const fear = useWatch('fear', form);
  const obsession = useWatch('obsession', form);

  const [exposureId, setExposureId] = useState(
    savedExposureId as string | undefined,
  );

  useActivityTracking(exposureId ? exposureId : `${dbCols.exposures}/create`);

  const {
    mutate: generateGptExposuresHierarchy,
    data: generateGptExposuresHierarchyData,
    isLoading: generateGptExposuresHierarchyLoading,
    error: generateGptExposuresHierarchyError,
  } = useCustomMutation<{ response: ExposureStimulus[] }>();

  useEffect(() => {
    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  useEffect(() => {
    if (
      generateGptExposuresHierarchyData &&
      !generateGptExposuresHierarchyLoading
    ) {
      setIsGenerating(false);
      tableQueryResult.refetch();
    }
  }, [generateGptExposuresHierarchyLoading, generateGptExposuresHierarchyData]);

  const onGenerateHypothesis = async () => {
    const values = form.getFieldsValue();
    const requestData = {
      lng: currentLocale?.startsWith('ru') ? 'ru' : 'en',
      type: values.type,
      safetyBehaviorsToAvoid: values.safetyBehaviorsToAvoid,
      fear: values.type === 0 ? values.fear : values.obsession,
    };

    setIsGenerating(true);
    try {
      generateGptExposuresHierarchy({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'generateGptExposuresHierarchy',
          fields: ['response'],
          variables: {
            clientId: {
              name: 'clientId',
              type: 'ID',
              value: clientId,
            },
            fear: {
              name: 'fear',
              type: 'String!',
              value: requestData?.fear,
            },
            lng: {
              name: 'lng',
              type: 'String!',
              value: requestData?.lng,
            },
            type: {
              name: 'type',
              type: 'Int!',
              value: requestData?.type,
            },
            exposureId: {
              name: 'exposureId',
              type: 'ID!',
              value: exposureId,
            },
            safetyBehaviorsToAvoid: {
              name: 'safetyBehaviorsToAvoid',
              type: 'String',
              value: requestData?.safetyBehaviorsToAvoid,
            },
          },
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const memoizedTypeOptions = useMemo(() => {
    return t('shared.exposure.types', {
      returnObjects: true,
      // @ts-ignore
    }).map((type: any, index: number) => ({
      label: type.name,
      value: index,
      description: type.description,
    }));
  }, [i18n.language]);

  const memoizedAvoidanceOptions = useMemo(() => {
    return t('shared.exposure.avoidances', {
      returnObjects: true,
      // @ts-ignore
    }).map((avoidance: string, index: number) => (
      <Select.Option key={index} value={index}>
        {avoidance}
      </Select.Option>
    ));
  }, [i18n.language]);

  const {
    data: exposureData,
    isLoading: exposureLoading,
    refetch: refetchExposure,
    error: exposureError,
  } = useCustom<Exposure>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'exposure',
      fields: [
        '_id',
        'userId',
        'type',
        'fear',
        'obsession',
        'safetyBehaviorsToAvoid',
        {
          hierarchy: [
            '_id',
            'createdAt',
            'rank',
            'title',
            'description',
            'exposureId',
            'imageUrls',
            'imagePrompt',
            'sud',
            'avoidance',
            'worstThingThatCouldHappen',
            {
              expositions: [
                'createdAt',
                'anticipatedSud',
                'reactionNotes',
                {
                  suds: ['sud', 'reactedAt'],
                },
              ],
            },
          ],
        },
      ],
      variables: {
        _id: {
          name: '_id',
          type: 'ID',
          value: exposureId,
        },
      },
    },
  });

  useEffect(() => {
    if (exposureData?.data) {
      const {
        __typename,
        _id,
        _key,
        _rev,
        createdAt,
        updatedAt,
        hierarchy,
        ...rest
      } = exposureData?.data;
      form?.setFieldsValue({
        ...rest,
        type: rest?.type || 0,
      });
      if (exposureData?.data.userId) {
        setClientId(exposureData?.data.userId);
      }

      if (exposureData?.data?._id && exposureData?.data?.userId) {
        therapistActivitySend({
          type: 'ENTITY_CLIENT_ID_CHANGED',
          clientId: exposureData?.data?.userId,
        });
      }
    }
  }, [exposureData]);

  const {
    mutate: upsertExposure,
    data: upsertExposureData,
    isLoading: upsertExposureLoading,
    error: upsertExposureError,
  } = useCustomMutation<Exposure>();

  const saveData = useCallback(
    async (values?: typeof exposureInitialValues) => {
      const data = values || form?.getFieldsValue();

      if (!clientId) {
        return;
      }

      const res = await upsertExposure({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertExposure',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'ExposureInput',
              value: {
                data,
                where: {
                  clientId,
                  _id: exposureId,
                },
              },
            },
          },
        },
      });

      message.success(t('shared.exposure.saveSuccess'));
      setWarnWhen(false);
    },
    [clientId, exposureId, t],
  );

  const {
    mutate: upsertExposureStimulus,
    data: upsertExposureStimulusData,
    isLoading: upsertExposureStimulusLoading,
    error: upsertExposureStimulusError,
  } = useCustomMutation<{ exposureStimulus: ExposureStimulus }>();

  useEffect(() => {
    if (exposureData?.data?._id) {
      setExposureId(exposureData?.data?._id);
    }
  }, [exposureData]);

  useEffect(() => {
    if (upsertExposureData?.data?._id) {
      if (!exposureId) {
        therapistActivitySend({
          type: 'CREATED',
          resource: upsertExposureData?.data?._id,
        });
        setExposureId(upsertExposureData?.data?._id);
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          resource: upsertExposureData?.data?._id,
        });
      }
    }
  }, [upsertExposureData, exposureId]);

  const {
    table: { tableProps, tableQueryResult },
    form: { formProps },
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useCcEditableTable<ExposureStimulus>(
    {
      queryOptions: {
        select: (data) => {
          // @ts-ignore
          return { data: data.data.data, total: data.total };
        },
      },
      initialSorter: [
        {
          field: 'rank',
          order: 'asc',
        },
      ],
      permanentFilter: [
        {
          field: 'exposureId',
          operator: 'eq',
          value: exposureId,
        },
      ],
      metaData: {
        operation: 'exposureStimuli',
        fields: [
          {
            data: [
              '_id',
              'createdAt',
              'rank',
              'title',
              'exposureId',
              'description',
              'imageUrls',
              'imagePrompt',
              'sud',
              'avoidance',
              'worstThingThatCouldHappen',
              {
                expositions: [
                  'createdAt',
                  'anticipatedSud',
                  'reactionNotes',
                  {
                    suds: ['sud', 'reactedAt'],
                  },
                ],
              },
            ],
          },
          'count',
        ],
      },
    },
    {
      metaData: {
        operation: 'exposureStimulus',
        fields: [
          '_id',
          'createdAt',
          'rank',
          'title',
          'description',
          'imageUrls',
          'imagePrompt',

          'exposureId',
          'sud',
          'avoidance',
          'worstThingThatCouldHappen',
          {
            expositions: [
              'createdAt',
              'anticipatedSud',
              'reactionNotes',
              {
                suds: ['sud', 'reactedAt'],
              },
            ],
          },
        ],
      },
    },
  );

  const createExposureStimulus = useCallback(
    async (values?: ExposureStimulus) => {
      if (!clientId || !exposureId) {
        return;
      }

      const data = {
        exposureId,
        expositions: [],
        rank: (tableProps.dataSource || []).length,
      };

      const res = await upsertExposureStimulus({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertExposureStimulus',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'ExposureStimulusInput',
              value: {
                data,
                where: {
                  clientId,
                  exposureId,
                  _id: values?._id || null,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.exposure.situationOrStimulusSaveSuccess'));
    },
    [clientId, exposureId, t, (tableProps.dataSource || []).length],
  );

  useEffect(() => {
    if (!upsertExposureLoading && upsertExposureStimulusData?.data) {
      setTimeout(async () => {
        const newRes = await tableQueryResult.refetch();
        if (newRes?.data?.data.length) {
          const newStimulus = newRes?.data?.data[newRes?.data?.data.length - 1];
          if (newStimulus) {
            // @ts-ignore
            editButtonProps?.(newStimulus._id)?.onClick();
          }
        }
      }, 100);
    }
  }, [upsertExposureStimulusData, upsertExposureLoading]);

  const {
    mutate: mutateUpdateExposureStimuli,
    data: updateExposureStimuliData,
    isLoading: updateExposureStimuliLoading,
    error: updateExposureStimuliError,
  } = useCustomMutation<{ exposureStimuli: ExposureStimulus[] }>();

  const updateExposureStimuli = useCallback(
    async (values?: ExposureStimulus[]) => {
      if (!clientId || !values?.length) {
        return;
      }

      // Note: Add if more is needed
      const updateValues = values.map((value, index) => {
        const { _id, rank, exposureId, avoidance } = value;

        return {
          _id,
          rank,
          exposureId,
          avoidance,
        };
      });

      const res = await mutateUpdateExposureStimuli({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'updateExposureStimuli',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'UpdateExposureStimuliInput',
              value: {
                data: updateValues,
                where: {
                  clientId,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.exposure.situationOrStimulusSaveSuccess'));
      setTimeout(() => tableQueryResult.refetch(), 100);
    },
    [clientId, exposureId, t, (tableProps.dataSource || []).length],
  );

  useEffect(() => {
    // @ts-ignore
    if (updateExposureStimuliData?.data.length && exposureId) {
      therapistActivitySend({
        type: 'UPDATED',
        resource: exposureId,
      });
    }
  }, [updateExposureStimuliData]);

  const {
    mutate: deleteExposureStimulus,
    data: deleteExposureStimulusData,
    isLoading: deleteExposureStimulusLoading,
    error: deleteExposureStimulusError,
  } = useCustomMutation<{ exposureStimulus: ExposureStimulus }>();

  const onDeleteExposureStimulus = useCallback(
    async (_id: string) => {
      if (!clientId || !_id) {
        return;
      }

      const res = await deleteExposureStimulus({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'deleteExposureStimulus',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'deleteExposureStimulusInput',
              value: {
                where: {
                  id: _id,
                },
              },
            },
          },
        },
      });
      message.success(t('shared.exposure.situationOrStimulusSaveSuccess'));
      setTimeout(() => tableQueryResult.refetch(), 100);
    },
    [clientId, exposureId, t, (tableProps.dataSource || []).length],
  );

  useEffect(() => {
    // @ts-ignore
    if (!!deleteExposureStimulusData?.data && exposureId) {
      therapistActivitySend({
        type: 'UPDATED',
        resource: exposureId,
      });
    }
  }, [deleteExposureStimulusData]);

  const moveExposureStimuli = async (index: number, newIndex: number) => {
    const dataSource = [...(tableProps.dataSource || [])];

    const item = dataSource[index];
    const newDataSource = dataSource.filter((_, i) => i !== index);
    newDataSource.splice(newIndex, 0, item);

    const newValues = newDataSource.map((item, index) => ({
      ...item,
      rank: index,
    }));

    await updateExposureStimuli(newValues);
  };

  const onValuesChange = useCallback(
    debounce(
      (
        changedValues: Partial<typeof exposureInitialValues>,
        allValues: typeof exposureInitialValues,
      ) => {
        saveData(allValues);
      },
      700,
    ),
    [clientId, exposureId],
  );

  return (
    <Create
      title={<>{t('shared.exposure.title')}</>}
      isLoading={clientsLoading}
      saveButtonProps={{
        style: {
          display: 'none',
        },
        onClick: () => saveData(),
        disabled:
          !clientId ||
          isGenerating ||
          exposureLoading ||
          exposureLoading ||
          generateGptExposuresHierarchyLoading,
      }}
    >
      <div className="exposure-container">
        {
          <div
            style={{
              display: clientId ? 'none' : 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <Title level={5}>{t('createThought.selectClient')}:</Title>

            <Tooltip title={t('createThought.selectClientTip')}>
              <QuestionCircleOutlined style={{ margin: '0 10px' }} />
            </Tooltip>

            <Select
              className="exposure--client-selector"
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              value={clientId}
              onChange={(value) => {
                setClientId(value as string);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </div>
        }
      </div>

      <Form<typeof exposureInitialValues>
        form={form}
        initialValues={{
          ...exposureInitialValues,
        }}
        onValuesChange={onValuesChange}
        onFinish={(values) => {}}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        labelWrap
      >
        <Form.Item
          name="type"
          label={t('shared.exposure.type')}
          rules={[
            {
              required: true,
              message: t('shared.exposure.typeRequired'),
            },
          ]}
          style={{
            display: exposureId || !clientId ? 'none' : 'block',
          }}
        >
          <Radio.Group>
            {memoizedTypeOptions.map((option: any) => (
              <Radio key={option.value} value={option.value}>
                {option.label}{' '}
                <Tooltip
                  title={option.description}
                  overlayClassName="exposure--tooltip"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {type === null ? null : type === 0 ? (
          <Form.Item name={'fear'} label={t('shared.exposure.fear')}>
            <Input.TextArea
              placeholder={t('shared.exposure.fearPlaceholder')}
              autoSize={{ minRows: 2, maxRows: 6 }}
              disabled={!exposureId && upsertExposureLoading}
            />
          </Form.Item>
        ) : (
          <div>
            <Form.Item
              name={'obsession'}
              label={t('shared.exposure.obsession')}
            >
              <Input.TextArea
                placeholder={t('shared.exposure.obsessionPlaceholder')}
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={!exposureId && upsertExposureLoading}
              />
            </Form.Item>

            <Form.Item
              name={'safetyBehaviorsToAvoid'}
              label={t('shared.exposure.compulsion')}
              tooltip={t('shared.exposure.compulsionTip')}
            >
              <Input.TextArea
                placeholder={t('shared.exposure.compulsion')}
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={!exposureId && upsertExposureLoading}
              />
            </Form.Item>
          </div>
        )}

        {type !== null && (fear || obsession) && (
          <div>
            <Divider />

            <Title level={5}>
              {t('shared.exposure.situationsOrStimuliHierarchy')}:
            </Title>

            <div className="exposures--generate-row">
              <Button
                onClick={() => {
                  createExposureStimulus();
                }}
                disabled={isGenerating || upsertExposureStimulusLoading}
              >
                <PlusOutlined /> {t('shared.exposure.addSituationOrStimuli')}
              </Button>
              <Button
                type="primary"
                onClick={onGenerateHypothesis}
                icon={<BulbOutlined />}
                disabled={
                  isGenerating || (tableProps.dataSource?.length || 0) > 1
                }
              >
                {t('shared.exposure.generateHierarchy')}
              </Button>
            </div>
            <div className="exposures--is-generating-row">
              {isGenerating && (
                <Spin
                  style={{
                    margin: '20px 0',
                  }}
                  size="large"
                  tip={t('shared.behaviorExperiment.generating')}
                />
              )}
            </div>
          </div>
        )}
      </Form>

      {type !== null && (fear || obsession) && (
        <Form {...formProps}>
          <Table
            {...tableProps}
            rowKey="_id"
            onChange={(pagination, filters, sorter, extra) => {
              // Note: The data we get from Ant.design filters does not match the
              // react refine one. Currently it works fine if I simply silence it the way below.
              // TOOD: Find a way to convert the data to match the react refine one and fix it better
              // @ts-ignore
              tableProps?.onChange?.(pagination, null, sorter, extra);
            }}
            pagination={false}
          >
            <Table.Column<ExposureStimulus>
              key="rank"
              dataIndex={'rank'}
              title={t('shared.exposure.rank')}
              width={40}
              render={(value, record) => {
                return (
                  <div className="exposures--move-row">
                    <div>{`${(record.rank || 0) + 1}. `}</div>
                    <div className="exposures--move-buttons">
                      <Tooltip title={t('common.moveUp')}>
                        <Button
                          icon={<CaretUpOutlined />}
                          disabled={
                            record.rank === 0 || updateExposureStimuliLoading
                          }
                          onClick={() => {
                            const newIndex = (record.rank || 0) - 1;
                            if (newIndex >= 0) {
                              moveExposureStimuli(record.rank || 0, newIndex);
                            }
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t('common.moveDown')}>
                        <Button
                          icon={<CaretDownOutlined />}
                          disabled={
                            record.rank ===
                              (tableProps.dataSource || []).length - 1 ||
                            updateExposureStimuliLoading
                          }
                          onClick={() => {
                            const newIndex = (record.rank || 0) + 1;
                            if (
                              newIndex < (tableProps.dataSource || []).length
                            ) {
                              moveExposureStimuli(record.rank || 0, newIndex);
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                );
              }}
            />
            <Table.Column<ExposureStimulus>
              key="title"
              dataIndex="title"
              title={t('shared.exposure.situationOrStimulusTitle')}
              width={'30%'}
              render={(value, record) => {
                if (isEditing(record._id)) {
                  return (
                    <Form.Item
                      name={['title']}
                      style={{ margin: 0, minWidth: 120 }}
                    >
                      <Input.TextArea
                        placeholder={t(
                          'shared.exposure.situationOrStimulusTitle',
                        )}
                        autoSize={{ minRows: 4 }}
                      />
                    </Form.Item>
                  );
                }
                return value;
              }}
            />
            <Table.Column<ExposureStimulus>
              key="description"
              dataIndex="description"
              title={t('shared.exposure.situationOrStimulusDescription')}
              render={(value, record) => {
                if (isEditing(record._id)) {
                  return (
                    <Form.Item
                      name={['description']}
                      style={{ margin: 0, minWidth: 120 }}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 4 }}
                        placeholder={t(
                          'shared.exposure.situationOrStimulusDescriptionPlaceholder',
                        )}
                      />
                    </Form.Item>
                  );
                }
                return value;
              }}
            />

            <Table.Column<ExposureStimulus>
              key="sud"
              dataIndex="sud"
              title={
                <Tooltip title={t('shared.exposure.sudTip')}>
                  {t('shared.exposure.sud')}
                  <QuestionCircleOutlined style={{ margin: '0 10px' }} />
                </Tooltip>
              }
              render={(value, record) => {
                if (isEditing(record._id)) {
                  return (
                    <Form.Item name={['sud']} style={{ margin: 0 }}>
                      <InputNumber min={0} max={100} />
                    </Form.Item>
                  );
                }
                return value;
              }}
            />

            <Table.Column<ExposureStimulus>
              key="avoidance"
              dataIndex="avoidance"
              title={
                <Tooltip title={t('shared.exposure.doYouAvoid')}>
                  {t('shared.exposure.avoiding')}
                  <QuestionCircleOutlined style={{ margin: '0 10px' }} />
                </Tooltip>
              }
              render={(value, record) => {
                if (isEditing(record._id)) {
                  return (
                    <Form.Item
                      name={'avoidance'}
                      style={{ margin: 0, minWidth: 120 }}
                    >
                      <Select
                        className="exposure--client-selector"
                        placeholder={t('shared.exposure.avoiding')}
                        optionFilterProp="children"
                        dropdownStyle={{ minWidth: 240 }}
                      >
                        {memoizedAvoidanceOptions}
                      </Select>
                    </Form.Item>
                  );
                }
                return record.avoidance !== null
                  ? t('shared.exposure.avoidances', {
                      returnObjects: true,
                      // @ts-ignore
                    })?.[record.avoidance] || null
                  : null;
              }}
            />

            <Table.Column<ExposureStimulus>
              title={t('resourcesCommon.actions')}
              dataIndex="actions"
              width={60}
              render={(_, record) => {
                // @ts-ignore
                const client: Client = // @ts-ignore
                ((clients?.data?.data as Client[]) || []).find(
                  (c) => c._id === clientId,
                );

                const hasPremiumAccess = client?.accessLevel;

                const canEdit =
                  client?.access?.allowEdit ||
                  (client?.access?.allowAdminEdit &&
                    identity?.db?.role === UserRole.THERAPIST_ADMIN);

                if (isEditing(record._id)) {
                  return (
                    <Space>
                      <SaveButton
                        {...saveButtonProps}
                        onClick={() => {
                          therapistActivitySend({
                            type: 'UPDATED',
                            resource: exposureId,
                          });
                          saveButtonProps.onClick?.();
                        }}
                        size="small"
                      />
                      <Button {...cancelButtonProps} size="small">
                        {t('buttons.cancel')}
                      </Button>
                    </Space>
                  );
                }
                return (
                  <Space>
                    {
                      <>
                        <Tooltip
                          title={t('shared.exposure.editTitleAndDescription')}
                        >
                          <EditButton
                            {...editButtonProps(record._id)}
                            hideText
                            size="small"
                            disabled={!hasPremiumAccess || !canEdit}
                            resourceNameOrRouteName="exposureStimulus"
                          />
                        </Tooltip>

                        <Link
                          to={`/tools/exposures/edit?exposureStimulusId=${record._id}`}
                        >
                          <Button icon={<LineChartOutlined />} />
                        </Link>
                      </>
                    }

                    <Tooltip
                      title={t('shared.exposure.deleteAvailable120Minutes')}
                      placement="bottom"
                    >
                      <Popconfirm
                        okText={t('buttons.delete', 'Delete')}
                        cancelText={t('buttons.cancel', 'Cancel')}
                        okType="danger"
                        title={t('buttons.confirm', 'Are you sure?')}
                        onConfirm={() => onDeleteExposureStimulus(record._id)}
                        disabled={
                          !hasPremiumAccess ||
                          !canEdit ||
                          (record?.createdAt
                            ? differenceInHours(
                                new Date(),
                                new Date(record?.createdAt),
                              ) > 2
                            : false)
                        }
                      >
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          disabled={
                            !hasPremiumAccess ||
                            !canEdit ||
                            (record?.createdAt
                              ? differenceInHours(
                                  new Date(),
                                  new Date(record?.createdAt),
                                ) > 2
                              : false)
                          }
                        />
                      </Popconfirm>
                    </Tooltip>
                  </Space>
                );
              }}
            />
          </Table>
        </Form>
      )}
    </Create>
  );
};
