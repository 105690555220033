import {
  Button,
  Create,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Tag,
  Tooltip,
  TreeSelect,
} from '@pankod/refine-antd';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import routerProvider from '@pankod/refine-react-router-v6';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import {
  useCustom,
  useCustomMutation,
  useGetLocale,
} from '@pankod/refine-core';
import { useWindowSize } from 'rooks';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import './styles.less';
import { Client, dbCols, portalEn, User } from '@cc/schema';
import Cascader, { DefaultOptionType } from 'antd/lib/cascader';
import Card from 'antd/lib/card/Card';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { DateFnsDatePicker } from '../../components/Pickers';
import { debounce } from 'lodash';
import { useSubscriptionWarnings } from '../../hooks';
import { useWatch } from 'antd/lib/form/Form';
import { Conceptualization } from '../../schema/be-graphql-generated';
import * as Sentry from '@sentry/react';
import { useClientOptions } from '../../hooks/client-options';
import { xarrowProps } from '../../helpers';
import { TherapistActivityReactContext } from '../../App';
import { useActor } from '@xstate/react';
import { TherapistActivityService } from '../../machines/therapistActivityMachine';
import { useActivityTracking } from '../../hooks/use-activity-tracking';
import { recursivelyDisableIfChildren } from '../sessions/create/ProgressNote';

type SchemaTherapySchemas = typeof portalEn.pages.conceptualization.schemas;

export const CreateConceptualization = () => {
  const location = routerProvider.useLocation();

  const locationClientId = (useMemo(
    () =>
      qs.parse(
        location.search.charAt(0) === '?'
          ? location.search.slice(1)
          : location.search,
      ),
    [location.search],
  )?.clientId || '') as string;

  const subWarnings = useSubscriptionWarnings();

  const { t, i18n } = useTranslation();

  const { clientsLoading, memoizedClienOptions } = useClientOptions();

  const therapistActivityService = useContext(TherapistActivityReactContext);
  const [therapistActivityState, therapistActivitySend] = useActor(
    therapistActivityService as TherapistActivityService,
  );

  const initialValues = useMemo(() => {
    return {
      clientId:
        therapistActivityState?.context?.filterClientId ||
        locationClientId ||
        '',
      firstName: '',
      lastName: '',
      gender: undefined as undefined | number | null,
      dob: '' as string | null,
      education: undefined as undefined | number,
      maritalStatus: undefined as undefined | number,
      clientProfileOther: '',
      negativePastExperience: '',
      triggerEvent: '',
      geneticPredisposition: '',
      deepBeliefs: '',
      triggers: '',
      problems: [] as string[],
      lifeRules: '',
      goals: '',
      resources: '',
      schemas: [],
    };
  }, [therapistActivityState?.context?.filterClientId, locationClientId]);

  const [form] = Form.useForm<typeof initialValues>();

  const clientId =
    useWatch('clientId', form) ||
    therapistActivityState?.context?.filterClientId ||
    locationClientId;

  const {
    data: clientData,
    isLoading: clientLoading,
    refetch: refetchClient,
    error: clientError,
  } = useCustom<Client>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'client',
      fields: ['_id', 'firstName', 'lastName', 'email', 'diagnoses'],
      variables: {
        id: {
          name: 'id',
          type: 'ID',
          value: clientId || '',
        },
      },
    },
  });

  const {
    data: conceptualizationData,
    isLoading: conceptualizationLoading,
    refetch: refetchConceptualization,
    error: conceptualizationError,
  } = useCustom<Conceptualization>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'conceptualization',
      fields: [
        '_id',
        'dob',
        'gender',
        'education',
        'maritalStatus',
        'clientProfileOther',
        'negativePastExperience',
        'triggerEvent',
        'geneticPredisposition',
        'deepBeliefs',
        'triggers',
        'problems',
        'lifeRules',
        'goals',
        'resources',
      ],
      variables: {
        clientId: {
          name: 'clientId',
          type: 'ID',
          value: clientId || '',
        },
      },
    },
  });

  useEffect(() => {
    const loadConceptualization = async () => {
      if (clientId) {
        const res = await refetchConceptualization();
        const currentValues = form.getFieldsValue();

        if (res?.data?.data) {
          // @ts-ignore
          form.setFieldsValue({
            ...currentValues,
            // @ts-ignore
            ...(res?.data?.data || {}),
          });

          therapistActivitySend({
            type: 'ENTITY_CLIENT_ID_CHANGED',
            clientId,
          });
        }
      }
    };
    loadConceptualization();

    if (
      clientId &&
      therapistActivityState.context.filterClientId !== clientId
    ) {
      therapistActivitySend({
        type: 'ENTITY_CLIENT_ID_CHANGED',
        clientId,
      });
    }
  }, [clientId]);

  const client = clientData?.data;

  const [diagnoses, setDiagnoses] = useState<string[]>([]);

  useEffect(() => {
    const firstName = form.getFieldValue('firstName');
    const lastName = form.getFieldValue('lastName');

    if (
      client &&
      ((!firstName && client.firstName) || (!lastName && client?.lastName))
    ) {
      form?.setFieldsValue({
        firstName: client.firstName || '',
        lastName: client.lastName || '',
      });
    }

    if (client?.diagnoses?.length) {
      setDiagnoses((client?.diagnoses || []) as unknown as string[]);
    }
  }, [client]);

  const schemas = useMemo(() => {
    return t('pages.conceptualization.schemas', {
      returnObjects: true,
    }) as SchemaTherapySchemas;
  }, [i18n.language]);

  const [obfuscate, setObfuscate] = useState(true);

  useEffect(() => {
    if (!obfuscate) {
      const handle = setTimeout(() => {
        setObfuscate(true);
      }, 1000 * 60);
      return () => clearTimeout(handle);
    }
  }, [obfuscate]);

  useLayoutEffect(() => {
    return () => {
      const values = form.getFieldsValue();
      saveConceptualization(values);
    };
  }, []);

  const options = useMemo(() => {
    return Object.entries(schemas).map(([domainKey, domainValue]) => {
      const domain = {
        value: domainValue.name,
        label: domainValue.name,
        children: domainValue.children.map((schema) => {
          const option = {
            value: schema.name,
            label: (
              <Tooltip title={schema.tip}>
                <div>{schema.name}</div>
              </Tooltip>
            ),
            children: schema.copings.map((coping) => {
              const option = {
                value: coping.name,
                label: (
                  <Tooltip title={coping.tip}>
                    <div>{coping.name}</div>
                  </Tooltip>
                ),
              };
              return option;
            }),
          };
          return option;
        }),
      };
      return domain;
    });
  }, [JSON.stringify(schemas)]);

  const updateXarrow = useXarrow();

  const triggerScroll = () => {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  };

  const {
    mutate: upsertConceptualization,
    data: upsertConceptualizationData,
    isLoading: upsertConceptualizationLoading,
    error: upsertConceptualizationError,
  } = useCustomMutation<{ conceptualization: Conceptualization }>();

  const saveConceptualization = async (
    changedValues: Partial<typeof initialValues>,
  ) => {
    const { schemas, dob, clientId, ...valuesToSave } = changedValues;

    const formClientId = form.getFieldValue('clientId');
    if (!formClientId) {
      return;
    }

    let toSave: Partial<typeof initialValues> = {
      ...valuesToSave,
    };

    if (dob) {
      toSave.dob = dob || null;
    }

    if (Object.keys(toSave).length === 0) {
      return;
    }

    try {
      const res = await upsertConceptualization({
        url: '',
        method: 'post',
        values: {},
        metaData: {
          operation: 'upsertConceptualization',
          fields: ['_id'],
          variables: {
            input: {
              name: 'input',
              type: 'ConceptualizationInput',
              value: {
                data: toSave,
                where: {
                  clientId: formClientId,
                },
              },
            },
          },
        },
      });
      message.success(t('pages.conceptualization.saveSuccess'));
    } catch (error) {
      message.success(t('pages.conceptualization.saveError'));
      Sentry.captureException(error);
    }
  };

  useActivityTracking(
    conceptualizationData?.data?._id ||
      // @ts-ignore
      upsertConceptualizationData?.data?._id ||
      `${dbCols.conceptualizations}/create`,
  );

  useEffect(() => {
    // @ts-ignore
    if (upsertConceptualizationData?.data?._id) {
      if (!conceptualizationData?.data?._id) {
        therapistActivitySend({
          type: 'CREATED',
          // @ts-ignore
          resource: upsertConceptualizationData?.data?._id,
        });
      } else {
        therapistActivitySend({
          type: 'UPDATED',
          // @ts-ignore
          resource: upsertConceptualizationData?.data?._id,
        });
      }
    }
  }, [upsertConceptualizationData]);

  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if ((innerWidth || 0) > 1024) {
      const handle = setInterval(() => {
        updateXarrow();
      }, 200);
      return () => clearInterval(handle);
    }
  }, [innerWidth]);

  const filter = (inputValue: string, path: DefaultOptionType[]) => {
    return path.some(
      (option) =>
        (option.value as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );
  };

  const saveData = useCallback(
    debounce(async (changedValues: Partial<typeof initialValues>) => {
      saveConceptualization(changedValues);
    }, 700),
    [],
  );

  const onValuesChange = (
    changedValues: Partial<typeof initialValues>,
    allValues: typeof initialValues,
  ) => {
    saveData(changedValues);
  };

  const getLocale = useGetLocale();

  const currentLocale = useMemo(() => getLocale(), [i18n.languages[0]]);

  const treeData = useMemo(() => {
    return recursivelyDisableIfChildren(
      t('icd10', {
        returnObjects: true,
      }) as any[],
    );
  }, [currentLocale]);

  const {
    mutate: updateClientDiagnoses,
    data: updateClientDiagnosesData,
    isLoading: updateClientDiagnosesLoading,
    error: updateProgressNoteClientDiagnosesError,
  } = useCustomMutation<{ client: User }>();

  const updateClient = async (values: string[]) => {
    if (!clientId || !values) {
      return;
    }

    const res = await updateClientDiagnoses({
      url: '',
      method: 'post',
      values: {},
      metaData: {
        operation: 'updateClientDiagnoses',
        fields: ['_id'],
        variables: {
          input: {
            name: 'input',
            type: 'UpdateClientDiagnosesInput',
            value: {
              data: values,
              where: {
                id: clientId,
              },
            },
          },
        },
      },
    });
  };

  return (
    <Create
      title={t('pages.conceptualization.title')}
      saveButtonProps={{
        style: {
          display: 'none',
        },
      }}
      isLoading={clientsLoading || conceptualizationLoading || clientLoading}
    >
      <Form<typeof initialValues>
        form={form}
        initialValues={{
          ...initialValues,
          firstName: client?.firstName || '',
          lastName: client?.lastName || '',
        }}
        onFinish={(values) => {}}
        onValuesChange={onValuesChange}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        labelWrap
      >
        <div className="rowContainer">
          <Form.Item
            className="select-client-item"
            label={
              <>
                {t('createThought.selectClient')}
                <Tooltip title={t('createThought.selectClientTip')}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
              </>
            }
            name="clientId"
            style={{ display: clientId ? 'none' : 'block' }}
          >
            <Select
              showSearch
              placeholder={t('createThought.selectClient')}
              optionFilterProp="children"
              loading={clientsLoading}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {memoizedClienOptions}
            </Select>
          </Form.Item>
        </div>
        <div className="rowContainer">
          <Card
            style={{ width: '100%' }}
            title={t('pages.conceptualization.clientProfile')}
            extra={
              <div>
                <Tooltip title={t('pages.conceptualization.piiTip')}>
                  <QuestionCircleOutlined style={{ margin: '0 5px' }} />
                </Tooltip>
                <Button
                  icon={
                    (innerWidth || 0) < 500 ? null : obfuscate ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  onClick={() => setObfuscate(!obfuscate)}
                  size="small"
                >
                  {t(
                    obfuscate
                      ? 'pages.conceptualization.showInfo'
                      : 'pages.conceptualization.hideInfo',
                  )}
                </Button>
              </div>
            }
          >
            {obfuscate ? (
              <Skeleton active>
                <Skeleton.Input active size="small" />
                <Skeleton.Input active size="small" />
                <Skeleton.Input active size="small" />
              </Skeleton>
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Descriptions>
                    <Descriptions.Item label="ID">
                      {(client?._id || '')?.replace('users/', '')}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('pages.clientsShow.email')}>
                      {client?.email || ''}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="row-container--card">
                  <Form.Item
                    label={t('pages.conceptualization.fields.firstName')}
                    name="firstName"
                    className="card-form-item"
                  >
                    <Input
                      placeholder={t(
                        'pages.conceptualization.fields.firstName',
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('pages.conceptualization.fields.lastName')}
                    name="lastName"
                    className="card-form-item"
                  >
                    <Input
                      placeholder={t('pages.conceptualization.fields.lastName')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('pages.conceptualization.fields.dob')}
                    name="dob"
                    className="card-form-item"
                  >
                    <DateFnsDatePicker showToday={false} />
                  </Form.Item>
                </div>
                <div className="row-container--card">
                  <Form.Item
                    label={t('pages.conceptualization.fields.gender')}
                    name="gender"
                    className="card-form-item"
                  >
                    <Select
                      placeholder={t('pages.conceptualization.fields.gender')}
                      options={(
                        t('pages.conceptualization.genderOptions', {
                          returnObjects: true,
                        }) as string[]
                      ).map((o, idx) => ({
                        value: idx,
                        label: o,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('pages.conceptualization.fields.education')}
                    name="education"
                    className="card-form-item"
                  >
                    <Select
                      placeholder={t(
                        'pages.conceptualization.fields.education',
                      )}
                      options={(
                        t('pages.conceptualization.educationOptions', {
                          returnObjects: true,
                        }) as string[]
                      ).map((o, idx) => ({
                        value: idx,
                        label: o,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('pages.conceptualization.fields.maritalStatus')}
                    name="maritalStatus"
                    className="card-form-item"
                  >
                    <Select
                      placeholder={t(
                        'pages.conceptualization.fields.maritalStatus',
                      )}
                      options={(
                        t('pages.conceptualization.maritalStatusOptions', {
                          returnObjects: true,
                        }) as string[]
                      ).map((o, idx) => ({
                        value: idx,
                        label: o,
                      }))}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  label={t('pages.conceptualization.fields.clientProfileOther')}
                  name="clientProfileOther"
                  style={{ flex: 1 }}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 10 }}
                    placeholder={t(
                      'pages.conceptualization.fields.clientProfileOther',
                    )}
                    onResize={(e) => {
                      triggerScroll();
                      return e;
                    }}
                  />
                </Form.Item>
              </div>
            )}
          </Card>
        </div>

        <Divider />

        <Xwrapper>
          <div className="rowContainer">
            <Form.Item
              label={t('pages.conceptualization.fields.geneticPredisposition')}
              name="geneticPredisposition"
              style={{ flex: 1 }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t(
                  'pages.conceptualization.fields.geneticPredisposition',
                )}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>

          <div className="rowContainer">
            <Form.Item
              label={t('pages.conceptualization.fields.negativePastExperience')}
              name="negativePastExperience"
              style={{ flex: 1 }}
            >
              <Input.TextArea
                id="negativeExperience"
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t(
                  'pages.conceptualization.fields.negativePastExperience',
                )}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>

          <div className="rowContainer">
            <Form.Item
              label={
                <div>
                  <div>{t('pages.conceptualization.fields.deepBeliefs')}</div>

                  <Form.Item name="schemas">
                    <Cascader
                      style={{
                        maxWidth: 160,
                      }}
                      options={options}
                      onChange={(value, selectedOptions) => {
                        const currentBeliefs =
                          form.getFieldValue('deepBeliefs');
                        const selectedValues = selectedOptions
                          .map((option) => option.value)
                          .join(' -> ');
                        const newBeliefs = currentBeliefs
                          ? currentBeliefs + '\n' + selectedValues
                          : selectedValues;
                        form.setFieldValue('deepBeliefs', newBeliefs);
                        form.setFieldValue('schemas', '');
                      }}
                      placeholder={t('pages.conceptualization.schemaSearch')}
                      showSearch={{ filter }}
                      expandTrigger="hover"
                      size="small"
                    />
                  </Form.Item>
                </div>
              }
              name="deepBeliefs"
              style={{ flex: 3 }}
            >
              <Input.TextArea
                id="beliefs"
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t(
                  'pages.conceptualization.deepBeliefsPlaceholder',
                )}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('pages.conceptualization.fields.triggerEvent')}
              name="triggerEvent"
              style={{ flex: 2 }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t('pages.conceptualization.fields.triggerEvent')}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>

          <div className="rowContainer">
            <Form.Item
              label={t('pages.conceptualization.fields.lifeRules')}
              name="lifeRules"
              style={{ flex: 1, margin: '30px 0' }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t('pages.conceptualization.fields.lifeRules')}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>
          <div className="rowContainer">
            <Form.Item
              label={t('pages.conceptualization.fields.triggers')}
              name="triggers"
              style={{ flex: 1 }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t('pages.conceptualization.fields.triggers')}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('pages.conceptualization.fields.resources')}
              name="resources"
              style={{ flex: 1 }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t('pages.conceptualization.fields.resources')}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>

          <div className="rowContainerSelect" id="problems">
            <Form.Item
              name="problems"
              label={t('pages.conceptualization.fields.problems')}
              style={{ flex: 1 }}
            >
              <Select
                mode="tags"
                placeholder={t('profile.selectOrType')}
                size="large"
                onBlur={(e) => {
                  triggerScroll();
                  return e;
                }}
                onChange={(value) => {
                  triggerScroll();
                  return value;
                }}
                onDropdownVisibleChange={(open) => {
                  triggerScroll();
                  return open;
                }}
              >
                {portalEn.profile.clientIssues.map((i: string, idx) => (
                  <Select.Option key={i} value={i}>
                    {
                      t(`profile.clientIssues`, {
                        returnObjects: true,
                      })[idx]
                    }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="rowContainer">
            <Form.Item
              label={t('pages.conceptualization.fields.goals')}
              name="goals"
              style={{ flex: 1 }}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder={t('pages.conceptualization.fields.goals')}
                onResize={(e) => {
                  triggerScroll();
                  return e;
                }}
              />
            </Form.Item>
          </div>
          <div className="rowContainer">
            <Form.Item
              label={t('pages.sessions.diagnosesLabel')}
              tooltip={t('pages.sessions.icd10Hint')}
              style={{ flex: 1 }}
            >
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={t('pages.sessions.selectOrSearch')}
                value={diagnoses}
                allowClear
                onChange={async (values) => {
                  setDiagnoses(values as string[]);
                  await updateClient(values as string[]);
                }}
                treeData={treeData}
                treeLine
                treeCheckable
                dropdownMatchSelectWidth
                treeNodeFilterProp="title"
                tagRender={({ label, value, closable, onClose }) => {
                  const index = diagnoses?.findIndex(
                    (diagnosis) => diagnosis === value,
                  );
                  return (
                    <Tag
                      closable={closable}
                      onClose={onClose}
                      className="diagnosis--tag"
                    >
                      <b>
                        {t('pages.sessions.diagnoses', {
                          returnObjects: true,
                        })[index] || ''}
                        :
                      </b>{' '}
                      {label}
                    </Tag>
                  );
                }}
                {...((diagnoses?.length || 0) > 2 && {
                  open: false,
                  onDropdownVisibleChange: (open) => {
                    if (open) {
                      message.warning(t('pages.sessions.diagnosesLimit'));
                    }
                  },
                })}
              />
            </Form.Item>
          </div>

          {(innerWidth || 0) > 1024 && (
            <>
              <Xarrow
                start="negativeExperience"
                end="beliefs"
                startAnchor="bottom"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="triggerEvent"
                end="lifeRules"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="beliefs"
                end="lifeRules"
                startAnchor="bottom"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="lifeRules"
                end="problems"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="triggers"
                end="problems"
                startAnchor="bottom"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="resources"
                end="problems"
                startAnchor="bottom"
                endAnchor="top"
                {...xarrowProps}
              />
              <Xarrow
                start="problems"
                end="goals"
                endAnchor="top"
                {...xarrowProps}
              />
            </>
          )}
        </Xwrapper>
      </Form>
    </Create>
  );
};
