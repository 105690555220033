import { Dropdown, Icons, Menu, Space } from '@pankod/refine-antd';
import {
  useGetLocale,
  useNavigation,
  useNotification,
  useSetLocale,
  useTranslate,
  useUpdatePassword,
} from '@pankod/refine-core';
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { applyActionCode } from 'firebase/auth';

import { getValidators } from '@cc/schema';
import routerProvider from '@pankod/refine-react-router-v6';
import * as Sentry from '@sentry/react';
import { RuleObject } from 'antd/lib/form';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { getYupSyncValidator } from '../../helpers';
import { useFormSubmitDisabled } from '../../hooks';
import { resources } from '../../i18n';
import { IUpdatePasswordArgs } from '../../providers';
import {
  containerStyles,
  imageContainer,
  langaugeSelector,
  languageDropdown,
  layoutStyles,
  titleStyles,
} from './styles';
import { firebaseAuth } from '../../authProvider';
import { LoggedOutFooter } from '../../components/layout/loggedOutFooter';

const { DownOutlined } = Icons;

const { Title } = Typography;
export interface IChangePasswordForm {
  password: string;
  retypePassword: string;
}

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const PasswordResetPage = () => {
  const [form] = Form.useForm<IChangePasswordForm>();
  const translate = useTranslate();
  const { t, i18n } = useTranslation();

  const location = routerProvider.useLocation();

  const { replace } = useNavigation();

  const { mutate: updatePassword, isLoading } =
    useUpdatePassword<IUpdatePasswordArgs>();

  const parsedSearch = qs.parse(
    location.search.charAt(0) === '?'
      ? location.search.slice(1)
      : location.search,
  );

  const { resetPasswordValidator } = useMemo(
    () => getValidators(translate),
    [i18n.languages[0]],
  );

  const { handleFormChange, isDisabled: isSubmitDisabled } =
    useFormSubmitDisabled(form);

  useEffect(() => {
    const parsedSearch = qs.parse(
      location.search.charAt(0) === '?'
        ? location.search.slice(1)
        : location.search,
    );

    const applyCode = async () => {
      try {
        await applyActionCode(
          firebaseAuth.auth,
          parsedSearch.oobCode as string,
        );
      } catch (error) {
        // Note: there is an error even for a valid code
        // Without using it is not possible to reset the password
        // I'm still keeping the error logging to see how often we get it
        Sentry.captureException(error);
      }
    };

    if (parsedSearch?.mode === 'resetPassword' && parsedSearch?.oobCode) {
      applyCode();
    }
  }, [location.search]);
  // Note: as RuleObject is silenciing Antd since it seems to have wrong types
  const validationRules = getYupSyncValidator(
    t,
    resetPasswordValidator,
  ) as RuleObject;

  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const { open: openNotification } = useNotification();

  const CardTitle = (
    <Title level={3} style={titleStyles}>
      {translate('pages.login.resetPassword')}
    </Title>
  );

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {Object.keys(resources)
        .sort()
        .map((lang: string) => (
          <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
            {lang?.startsWith('en') ? 'English' : 'Русский'}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <img src={'/logo.svg'} alt="CopingCard Logo" />
            </div>
            <Title
              level={2}
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              {translate('pages.login.providerPortal')}
            </Title>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0, padding: '0 20px' }}
              bodyStyle={{ padding: '24px 20px' }}
            >
              <div style={langaugeSelector}>
                <Title level={5}>
                  {translate('pages.login.chooseLanguage')}
                </Title>
                <div style={languageDropdown}>
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <Space>
                        {currentLocale?.startsWith('en')
                          ? 'English'
                          : 'Русский'}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </div>
              </div>

              <Form<IChangePasswordForm>
                layout="vertical"
                form={form}
                labelWrap
                onFinish={async (v) => {
                  if (parsedSearch?.mode && parsedSearch?.oobCode) {
                    try {
                      if (parsedSearch.mode === 'resetPassword') {
                        await updatePassword({
                          actionCode: (parsedSearch.oobCode as string) || '',
                          password: v.password,
                        });
                        openNotification?.({
                          type: 'success',
                          message: '',
                          description: translate(
                            'pages.resetEmail.resetSuccess',
                          ),
                          key: 'reset-success',
                        });
                      }
                    } catch (error) {
                      Sentry.captureException(error);
                      openNotification?.({
                        type: 'error',
                        message: '',
                        description: translate('pages.resetEmail.resetError'),
                        key: 'reset-failure',
                      });
                    }
                  }
                  replace('/');
                }}
                initialValues={{}}
                onFieldsChange={handleFormChange}
              >
                <Form.Item
                  name="password"
                  label={translate('pages.signUp.password')}
                  rules={[validationRules]}
                  style={{ marginBottom: '12px' }}
                  required
                >
                  <Input.Password
                    visibilityToggle
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="retypePassword"
                  label={translate('pages.signUp.retypePassword')}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(translate('validators.retypePassword')),
                        );
                      },
                    }),
                  ]}
                  style={{ marginBottom: '12px' }}
                  required
                >
                  <Input.Password
                    visibilityToggle
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  disabled={isSubmitDisabled}
                >
                  {translate('pages.login.resetPassword')}
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
        <LoggedOutFooter />
      </Row>
    </Layout>
  );
};
