import { useTranslate } from '@pankod/refine-core';
import React from 'react';

export const LoggedOutFooter: React.FC = () => {
  const translate = useTranslate();
  return (
    <footer>
      <div
        style={{ display: 'flex', color: 'white', gap: 10, flexWrap: 'wrap' }}
      >
        <div>
          <a
            href="https://copingcard.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('screens.privacy')}
          </a>
        </div>
        <div>
          <a
            href="https://copingcard.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('screens.terms')}
          </a>
        </div>
        <div>{translate('pages.login.copywrite')}</div>
      </div>
    </footer>
  );
};
