export const translationEn = {
  aiProgressNotes: {
    activities: {
      0: 'viewed',
      1: 'created',
      2: 'edited',
      3: 'deleted',
    },
    activitiesDetails: {
      0: 'We viewed an existing {{technique}}',
      1: 'We created a new {{technique}}',
      2: 'We edited an existing {{technique}}',
      3: 'We deleted an existing {{technique}}',
    },
    alternativeDetails: {
      0: 'We reviewed previously documented {{technique}}',
      1: 'We identified and documented a new {{technique}}',
      2: 'We edited a previously documented {{technique}}',
      3: 'We deleted a previously documented {{technique}}',
    },
    weUsed: 'We used the',
    abcModels: {
      technique: 'ABC Model technique',
      title: 'ABC Model',
      b: 'for the thought "{{b}}"',
      a: 'in the situation "{{a}}"',
      emotions: 'with the emotion of {{emotion}} with strength {{strength}}',
      feelings: 'with body sensations of "{{feeling}}"',
      behaviors: 'and the following behavior: "{{behavior}}"',
    },
    behaviorExperiments: {
      technique: 'Behavior Experiment technique',
      typeVariants: ['Active experiment', 'Observational experiment'],
      title: 'Behavior Experiment',
      hypothesisA: 'for the thought (Hyptothesis A) "{{hypothesisA}}"',
      hypothesisB:
        'with an alternative thought (Hyptothesis B) "{{hypothesisB}}"',
      safetyBehaviors: 'and with safety behaviors "{{safetyBehaviors}}"',
      purpose: 'The purpose of the {{type}} was to "{{purpose}}".',
      experiment: 'The experiment itself was "{{experiment}}"',
      time: 'with date, time or duration "{{time}}"',
      place: 'with a place "{{place}}"',
      people: 'with people "{{people}}"',
      resources: 'with resources "{{resources}}"',
      survey: 'with a survey "{{survey}}"',
      followUpExperimentsIdeas:
        'Here are some follow-up experiments ideas: "{{followUpExperimentsIdeas}}"',
    },
    downwardArrows: {
      technique: 'Downward Arrow technique',
      title: 'Downward Arrow',
      startingThought:
        'for the automatic (superficial level) thought "{{startingThought}}"',
      intermediateThought:
        'with an underlying assumption (life rule) "{{intermediateThought}}"',
      deepBelief: 'with a core belief "{{deepBelief}}"',
    },
    exposures: {
      techniques: [
        'Exposure technique',
        'Exposure with Response Prevention technique',
      ],
      titles: ['Exposure', 'Exposure with Response Prevention'],
      titlesHierarchy: [
        'Exposure Hierarchy',
        'Exposure with Response Prevention Hierarchy',
      ],
      fear: 'for the fear or worry "{{fear}}"',
      obsession: 'for the obsession "{{obsession}}"',
      safetyBehaviorsToAvoid:
        'with compulsions (safety behaviors) to avoid "{{safetyBehaviorsToAvoid}}"',
      situationsHierarchy:
        'with situations or stimulus hierarchy: "{{hierarchy}}"',
      lastExpositionSituationTitle:
        'The situation last exposure was made in was "{{title}}"',
      lastExpositionDuration:
        'Last exposure duration was "{{lastExpositionDuration}}"',
      maxSudStrength:
        'Maximum reaction strength (SUD) was "{{maxSudStrength}}"',
      finishSudStrength:
        'final reaction strength (SUD) was "{{finishSudStrength}}"',
      lastExposureNotes: 'Last exposure notes: "{{lastExposureNotes}}"',
    },
    supportingCycles: {
      technique: 'Vicious Cycle (Maintenance Process) technique',
      title: 'Vicious Cycle',
      name: ' named "{{name}}"',
      forThoughts: 'with thoughts, actions or facts: "{{thoughts}}"',
    },
    portalCards: {
      resource: 'portalCards',
      technique: 'a coping card',
      frontSide: 'with the front side: "{{frontSide}}"',
      backSide: 'and the back side: "{{backSide}}"',
    },
    thoughts: {
      technique: 'Dysfunctional Thought Test technique',
      title: 'Dysfunctional Thought Test',
      activatingEvent:
        'The negative thought appeared as a result of the activating event "{{activatingEvent}}"',
      behaviors: 'and the following behavior: "{{behaviors}}"',
      emotions: 'with the emotion of "{{emotion}}" with strength {{strength}}',
      feelings: 'with body sensations of "{{feeling}}"',
      automaticThought: 'for the automatic thought "{{automaticThought}}"',
      thoughtFor:
        'The fact or evidence supporting the negative thought was "{{thoughtFor}}"',
      thoughtsForAlternativeThought:
        'which could have an alternative explanation "{{thoughtsForAlternativeThought}}"',
      thoughtsAgainst:
        'The facts and evidence against the automatic negative thought were "{{thoughtsAgainst}}"',
      cognitiveDistortions:
        'The cognitive distortions for the thought were "{{cognitiveDistortions}}"',
      realisticThoughts:
        'The discovered realistic thoughts were "{{realisticThoughts}}"',
    },
    worryTrees: {
      technique: 'Worry Tree technique',
      title: 'Worry Tree',
      worryAbout: 'for the worrying thought "{{worryAbout}}"',
      options: 'with options "{{options}}"',
      plan: 'with plan "{{plan}}"',
      immediateAction: 'with immediate actions "{{immediateAction}}"',
    },
  },
  alarmPermissionsModal: {
    settingText: 'Open Alarm Permission Settings',
    text: 'You have denied or removed Exact Alarm Permissions for CopingCard.',
    text1:
      'Exact Alarm Permissions are required for us to be able to schedule time-based notifications for you.',
    title: 'Exact Alarm Permissions Disabled!',
  },
  anxietyScreen: {
    dynamics: 'Trends',
    gad7: 'GAD-7 Anxiety Test',
    gad7Info:
      'GAD-7 (General Anxiety Disorder-7) is used for rapid screening for the presence of a clinically significant anxiety disorder (one of the four most common anxiety disorders - Generalized Anxiety Disorder, Panic Disorder, Social Phobia and PostTraumatic Stress Disorder), especially in outpatient settings.',
    level: 'Anxiety Level',
    newTest: 'Take a New Test',
  },
  auth: {
    notAuthorized: 'You do not have permissions to get this information',
    tokenError: 'Token Verification Error',
  },
  backgroundHeadlessNotification: {
    channelName: 'Background geofencing for location based reminders',
    text: 'Tap to launch CopingCard App',
    title: 'CopingCard Geofencing Notifications is on',
  },
  backgroundLocationAuthorizationAlert: {
    cancelButton: 'Cancel',
    instructions:
      "You have used geolocation notifications for your cards, but you have not provided the required permissions to the location service of your phone. You must enable 'Always' in location services to receive geolocation notifications, or turn off location notifications for cards to not receive this notification.",
    settingsButton: 'Settings',
    titleWhenNotEnabled: 'Location services not enabled',
    titleWhenOff: 'Location services OFF',
  },
  backgroundNotificatoinsPermissionsRationale: {
    message:
      'In order to track your activity in the background, please enable {backgroundPermissionOptionLabel} location permission',
    negativeAction: 'Cancel',
    positiveAction: 'Change to {backgroundPermissionOptionLabel}',
    title: `Allow {applicationName} to access this device location in the background?`,
  },
  batterySettingsModal: {
    okButton: 'OK, open settings',
    text: 'To ensure notifications are delivered, please disable battery optimization for the app.',
    title: 'Restrictions Detected',
  },
  be: {
    tokenNotFound: 'Invite code not found',
    tokenNotValid: 'Invite code not valid',
    noPermissions: 'You do not have permissions to do this action',
  },
  cardNotifications: {
    intro: 'Notifications for realistic thought:',
  },
  cardsScreen: {
    createFirstCard: 'Create your first Card',
    resetFilterButton: 'Reset Cards Filter',
    reviewCardTitle: 'Cards Review',
    totalCount: 'Total cards count',
  },
  channelsAndCategories: {
    channels: [
      {
        description:
          'This channel is used for general CopingCard notifications like new features, updates, new, etc.',
        groupId: 'CcGeneral',
        id: 'CcGeneralNews',
        name: 'News & Updates',
      },
      {
        description:
          'This channel receives time-based reminders for your coping cards',
        groupId: 'CcCards',
        id: 'CcCardsTime',
        name: 'Card Time-based Reminders',
      },
      {
        description:
          'This channel receives location-based reminders for your coping cards',
        groupId: 'CcCards',
        id: 'CcCardsGeolocation',
        name: 'Card Geolocation Notifications',
      },
      {
        description: 'This channel receives reminders for your scheduled tests',
        groupId: 'CcTests',
        id: 'CcTests',
        name: 'Scheduled Tests Reminders',
      },
    ],
    groups: [
      {
        description:
          'This group is used for general CopingCard notifications like new features, updates, news, etc.',
        id: 'CcGeneral',
        name: 'General Notifications',
      },
      {
        description:
          'This group organizes time- and geolocation-based reminders for your Coping Cards',
        id: 'CcCards',
        name: 'Coping Cards Reminders',
      },
      {
        description: 'This group organizes reminders for your scheduled tests',
        id: 'CcTests',
        name: 'Scheduled Tests Reminders',
      },
    ],
  },
  common: {
    and: 'And',
    back: 'Back',
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    copyToClipboard: 'Copy to Clipboard',
    delete: 'Delete',
    done: 'done',
    edit: 'Edit',
    emailTo: 'to',
    goBack: 'Go Back',
    learnMore: 'Learn More',
    na: 'N/A',
    no: 'no',
    noResults: 'No results',
    ok: 'Ok',
    optionalData: 'Optional Data',
    or: 'or',
    sort: 'Sort',
    sortAscending: 'Sort Ascending',
    sortDescending: 'Sort Descending',
    readMore: 'More',
    save: 'Save',
    search: 'Search',
    selectDate: 'Select Date',
    selected: 'selected',
    send: 'send',
    timezone: 'Timezone',
    tryAgain: 'try again',
    yes: 'yes',
    next: 'Next',
    unsavedChanges: 'You have Unsaved Changes',
    returnToScreen: 'Return to the Current Screen',
    saveAndGoBack: 'Save and Go Back',
    discardAndGoBack: 'Discard and Go Back',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    start: 'Start',
    stop: 'Stop',
    createdAt: 'Created at',
    failedToFetchImage: 'Failed to fetch image',
    uploadSuccess: 'Upload Success',
    close: 'Close',
  },
  countriesFallback: [
    {
      name: 'Afghanistan',
      value: 'AF',
    },
    {
      name: 'Åland Islands',
      value: 'AX',
    },
    {
      name: 'Albania',
      value: 'AL',
    },
    {
      name: 'Algeria',
      value: 'DZ',
    },
    {
      name: 'American Samoa',
      value: 'AS',
    },
    {
      name: 'Andorra',
      value: 'AD',
    },
    {
      name: 'Angola',
      value: 'AO',
    },
    {
      name: 'Anguilla',
      value: 'AI',
    },
    {
      name: 'Antarctica',
      value: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      value: 'AG',
    },
    {
      name: 'Argentina',
      value: 'AR',
    },
    {
      name: 'Armenia',
      value: 'AM',
    },
    {
      name: 'Aruba',
      value: 'AW',
    },
    {
      name: 'Australia',
      value: 'AU',
    },
    {
      name: 'Austria',
      value: 'AT',
    },
    {
      name: 'Azerbaijan',
      value: 'AZ',
    },
    {
      name: 'Bahamas',
      value: 'BS',
    },
    {
      name: 'Bahrain',
      value: 'BH',
    },
    {
      name: 'Bangladesh',
      value: 'BD',
    },
    {
      name: 'Barbados',
      value: 'BB',
    },
    {
      name: 'Belarus',
      value: 'BY',
    },
    {
      name: 'Belgium',
      value: 'BE',
    },
    {
      name: 'Belize',
      value: 'BZ',
    },
    {
      name: 'Benin',
      value: 'BJ',
    },
    {
      name: 'Bermuda',
      value: 'BM',
    },
    {
      name: 'Bhutan',
      value: 'BT',
    },
    {
      name: 'Bolivia',
      value: 'BO',
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      value: 'BQ',
    },
    {
      name: 'Bosnia and Herzegovina',
      value: 'BA',
    },
    {
      name: 'Botswana',
      value: 'BW',
    },
    {
      name: 'Bouvet Island',
      value: 'BV',
    },
    {
      name: 'Brazil',
      value: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      value: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      value: 'BN',
    },
    {
      name: 'Bulgaria',
      value: 'BG',
    },
    {
      name: 'Burkina Faso',
      value: 'BF',
    },
    {
      name: 'Burundi',
      value: 'BI',
    },
    {
      name: 'Cambodia',
      value: 'KH',
    },
    {
      name: 'Cameroon',
      value: 'CM',
    },
    {
      name: 'Canada',
      value: 'CA',
    },
    {
      name: 'Cape Verde',
      value: 'CV',
    },
    {
      name: 'Cayman Islands',
      value: 'KY',
    },
    {
      name: 'Central African Republic',
      value: 'CF',
    },
    {
      name: 'Chad',
      value: 'TD',
    },
    {
      name: 'Chile',
      value: 'CL',
    },
    {
      name: 'Christmas Island',
      value: 'CX',
    },
    {
      name: 'Cocos (Keeling) Islands',
      value: 'CC',
    },
    {
      name: 'Colombia',
      value: 'CO',
    },
    {
      name: 'Comoros',
      value: 'KM',
    },
    {
      name: 'Cook Islands',
      value: 'CK',
    },
    {
      name: 'Costa Rica',
      value: 'CR',
    },
    {
      name: "Cote D'Ivoire",
      value: 'CI',
    },
    {
      name: 'Croatia',
      value: 'HR',
    },
    {
      name: 'Cuba',
      value: 'CU',
    },
    {
      name: 'Curaçao',
      value: 'CW',
    },
    {
      name: 'Cyprus',
      value: 'CY',
    },
    {
      name: 'Czech Republic',
      value: 'CZ',
    },
    {
      name: 'Democratic Republic of the Congo',
      value: 'CD',
    },
    {
      name: 'Denmark',
      value: 'DK',
    },
    {
      name: 'Djibouti',
      value: 'DJ',
    },
    {
      name: 'Dominica',
      value: 'DM',
    },
    {
      name: 'Dominican Republic',
      value: 'DO',
    },
    {
      name: 'Ecuador',
      value: 'EC',
    },
    {
      name: 'Egypt',
      value: 'EG',
    },
    {
      name: 'El Salvador',
      value: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      value: 'GQ',
    },
    {
      name: 'Eritrea',
      value: 'ER',
    },
    {
      name: 'Estonia',
      value: 'EE',
    },
    {
      name: 'Eswatini',
      value: 'SZ',
    },
    {
      name: 'Ethiopia',
      value: 'ET',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      value: 'FK',
    },
    {
      name: 'Faroe Islands',
      value: 'FO',
    },
    {
      name: 'Fiji',
      value: 'FJ',
    },
    {
      name: 'Finland',
      value: 'FI',
    },
    {
      name: 'France',
      value: 'FR',
    },
    {
      name: 'French Guiana',
      value: 'GF',
    },
    {
      name: 'French Polynesia',
      value: 'PF',
    },
    {
      name: 'French Southern Territories',
      value: 'TF',
    },
    {
      name: 'Gabon',
      value: 'GA',
    },
    {
      name: 'Georgia',
      value: 'GE',
    },
    {
      name: 'Germany',
      value: 'DE',
    },
    {
      name: 'Ghana',
      value: 'GH',
    },
    {
      name: 'Gibraltar',
      value: 'GI',
    },
    {
      name: 'Greece',
      value: 'GR',
    },
    {
      name: 'Greenland',
      value: 'GL',
    },
    {
      name: 'Grenada',
      value: 'GD',
    },
    {
      name: 'Guadeloupe',
      value: 'GP',
    },
    {
      name: 'Guam',
      value: 'GU',
    },
    {
      name: 'Guatemala',
      value: 'GT',
    },
    {
      name: 'Guernsey',
      value: 'GG',
    },
    {
      name: 'Guinea',
      value: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      value: 'GW',
    },
    {
      name: 'Guyana',
      value: 'GY',
    },
    {
      name: 'Haiti',
      value: 'HT',
    },
    {
      name: 'Heard Island and McDonald Islands',
      value: 'HM',
    },
    {
      name: 'Holy See (Vatican City State)',
      value: 'VA',
    },
    {
      name: 'Honduras',
      value: 'HN',
    },
    {
      name: 'Hong Kong',
      value: 'HK',
    },
    {
      name: 'Hungary',
      value: 'HU',
    },
    {
      name: 'Iceland',
      value: 'IS',
    },
    {
      name: 'India',
      value: 'IN',
    },
    {
      name: 'Indonesia',
      value: 'ID',
    },
    {
      name: 'Iraq',
      value: 'IQ',
    },
    {
      name: 'Ireland',
      value: 'IE',
    },
    {
      name: 'Islamic Republic of Iran',
      value: 'IR',
    },
    {
      name: 'Isle of Man',
      value: 'IM',
    },
    {
      name: 'Israel',
      value: 'IL',
    },
    {
      name: 'Italy',
      value: 'IT',
    },
    {
      name: 'Jamaica',
      value: 'JM',
    },
    {
      name: 'Japan',
      value: 'JP',
    },
    {
      name: 'Jersey',
      value: 'JE',
    },
    {
      name: 'Jordan',
      value: 'JO',
    },
    {
      name: 'Kazakhstan',
      value: 'KZ',
    },
    {
      name: 'Kenya',
      value: 'KE',
    },
    {
      name: 'Kiribati',
      value: 'KI',
    },
    {
      name: 'Kosovo',
      value: 'XK',
    },
    {
      name: 'Kuwait',
      value: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      value: 'KG',
    },
    {
      name: "Lao People's Democratic Republic",
      value: 'LA',
    },
    {
      name: 'Latvia',
      value: 'LV',
    },
    {
      name: 'Lebanon',
      value: 'LB',
    },
    {
      name: 'Lesotho',
      value: 'LS',
    },
    {
      name: 'Liberia',
      value: 'LR',
    },
    {
      name: 'Libya',
      value: 'LY',
    },
    {
      name: 'Liechtenstein',
      value: 'LI',
    },
    {
      name: 'Lithuania',
      value: 'LT',
    },
    {
      name: 'Luxembourg',
      value: 'LU',
    },
    {
      name: 'Macao',
      value: 'MO',
    },
    {
      name: 'Madagascar',
      value: 'MG',
    },
    {
      name: 'Malawi',
      value: 'MW',
    },
    {
      name: 'Malaysia',
      value: 'MY',
    },
    {
      name: 'Maldives',
      value: 'MV',
    },
    {
      name: 'Mali',
      value: 'ML',
    },
    {
      name: 'Malta',
      value: 'MT',
    },
    {
      name: 'Marshall Islands',
      value: 'MH',
    },
    {
      name: 'Martinique',
      value: 'MQ',
    },
    {
      name: 'Mauritania',
      value: 'MR',
    },
    {
      name: 'Mauritius',
      value: 'MU',
    },
    {
      name: 'Mayotte',
      value: 'YT',
    },
    {
      name: 'Mexico',
      value: 'MX',
    },
    {
      name: 'Micronesia, Federated States of',
      value: 'FM',
    },
    {
      name: 'Moldova, Republic of',
      value: 'MD',
    },
    {
      name: 'Monaco',
      value: 'MC',
    },
    {
      name: 'Mongolia',
      value: 'MN',
    },
    {
      name: 'Montenegro',
      value: 'ME',
    },
    {
      name: 'Montserrat',
      value: 'MS',
    },
    {
      name: 'Morocco',
      value: 'MA',
    },
    {
      name: 'Mozambique',
      value: 'MZ',
    },
    {
      name: 'Myanmar',
      value: 'MM',
    },
    {
      name: 'Namibia',
      value: 'NA',
    },
    {
      name: 'Nauru',
      value: 'NR',
    },
    {
      name: 'Nepal',
      value: 'NP',
    },
    {
      name: 'Netherlands',
      value: 'NL',
    },
    {
      name: 'New Caledonia',
      value: 'NC',
    },
    {
      name: 'New Zealand',
      value: 'NZ',
    },
    {
      name: 'Nicaragua',
      value: 'NI',
    },
    {
      name: 'Niger',
      value: 'NE',
    },
    {
      name: 'Nigeria',
      value: 'NG',
    },
    {
      name: 'Niue',
      value: 'NU',
    },
    {
      name: 'Norfolk Island',
      value: 'NF',
    },
    {
      name: 'North Korea',
      value: 'KP',
    },
    {
      name: 'Northern Mariana Islands',
      value: 'MP',
    },
    {
      name: 'Norway',
      value: 'NO',
    },
    {
      name: 'Oman',
      value: 'OM',
    },
    {
      name: 'Pakistan',
      value: 'PK',
    },
    {
      name: 'Palau',
      value: 'PW',
    },
    {
      name: 'Panama',
      value: 'PA',
    },
    {
      name: 'Papua New Guinea',
      value: 'PG',
    },
    {
      name: 'Paraguay',
      value: 'PY',
    },
    {
      name: "People's Republic of China",
      value: 'CN',
    },
    {
      name: 'Peru',
      value: 'PE',
    },
    {
      name: 'Philippines',
      value: 'PH',
    },
    {
      name: 'Pitcairn',
      value: 'PN',
    },
    {
      name: 'Poland',
      value: 'PL',
    },
    {
      name: 'Portugal',
      value: 'PT',
    },
    {
      name: 'Puerto Rico',
      value: 'PR',
    },
    {
      name: 'Qatar',
      value: 'QA',
    },
    {
      name: 'Republic of the Congo',
      value: 'CG',
    },
    {
      name: 'Republic of The Gambia',
      value: 'GM',
    },
    {
      name: 'Reunion',
      value: 'RE',
    },
    {
      name: 'Romania',
      value: 'RO',
    },
    {
      name: 'Russian Federation',
      value: 'RU',
    },
    {
      name: 'Rwanda',
      value: 'RW',
    },
    {
      name: 'Saint Barthélemy',
      value: 'BL',
    },
    {
      name: 'Saint Helena',
      value: 'SH',
    },
    {
      name: 'Saint Kitts and Nevis',
      value: 'KN',
    },
    {
      name: 'Saint Lucia',
      value: 'LC',
    },
    {
      name: 'Saint Martin (French part)',
      value: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      value: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      value: 'VC',
    },
    {
      name: 'Samoa',
      value: 'WS',
    },
    {
      name: 'San Marino',
      value: 'SM',
    },
    {
      name: 'Sao Tome and Principe',
      value: 'ST',
    },
    {
      name: 'Saudi Arabia',
      value: 'SA',
    },
    {
      name: 'Senegal',
      value: 'SN',
    },
    {
      name: 'Serbia',
      value: 'RS',
    },
    {
      name: 'Seychelles',
      value: 'SC',
    },
    {
      name: 'Sierra Leone',
      value: 'SL',
    },
    {
      name: 'Singapore',
      value: 'SG',
    },
    {
      name: 'Sint Maarten (Dutch part)',
      value: 'SX',
    },
    {
      name: 'Slovakia',
      value: 'SK',
    },
    {
      name: 'Slovenia',
      value: 'SI',
    },
    {
      name: 'Solomon Islands',
      value: 'SB',
    },
    {
      name: 'Somalia',
      value: 'SO',
    },
    {
      name: 'South Africa',
      value: 'ZA',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      value: 'GS',
    },
    {
      name: 'South Korea',
      value: 'KR',
    },
    {
      name: 'South Sudan',
      value: 'SS',
    },
    {
      name: 'Spain',
      value: 'ES',
    },
    {
      name: 'Sri Lanka',
      value: 'LK',
    },
    {
      name: 'State of Palestine',
      value: 'PS',
    },
    {
      name: 'Sudan',
      value: 'SD',
    },
    {
      name: 'Suriname',
      value: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      value: 'SJ',
    },
    {
      name: 'Sweden',
      value: 'SE',
    },
    {
      name: 'Switzerland',
      value: 'CH',
    },
    {
      name: 'Syrian Arab Republic',
      value: 'SY',
    },
    {
      name: 'Taiwan, Province of China',
      value: 'TW',
    },
    {
      name: 'Tajikistan',
      value: 'TJ',
    },
    {
      name: 'Thailand',
      value: 'TH',
    },
    {
      name: 'The Republic of North Macedonia',
      value: 'MK',
    },
    {
      name: 'Timor-Leste',
      value: 'TL',
    },
    {
      name: 'Togo',
      value: 'TG',
    },
    {
      name: 'Tokelau',
      value: 'TK',
    },
    {
      name: 'Tonga',
      value: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      value: 'TT',
    },
    {
      name: 'Tunisia',
      value: 'TN',
    },
    {
      name: 'Turkey',
      value: 'TR',
    },
    {
      name: 'Turkmenistan',
      value: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      value: 'TC',
    },
    {
      name: 'Tuvalu',
      value: 'TV',
    },
    {
      name: 'Uganda',
      value: 'UG',
    },
    {
      name: 'Ukraine',
      value: 'UA',
    },
    {
      name: 'United Arab Emirates',
      value: 'AE',
    },
    {
      name: 'United Kingdom',
      value: 'GB',
    },
    {
      name: 'United Republic of Tanzania',
      value: 'TZ',
    },
    {
      name: 'United States Minor Outlying Islands',
      value: 'UM',
    },
    {
      name: 'United States of America',
      value: 'US',
    },
    {
      name: 'Uruguay',
      value: 'UY',
    },
    {
      name: 'Uzbekistan',
      value: 'UZ',
    },
    {
      name: 'Vanuatu',
      value: 'VU',
    },
    {
      name: 'Venezuela',
      value: 'VE',
    },
    {
      name: 'Vietnam',
      value: 'VN',
    },
    {
      name: 'Virgin Islands, British',
      value: 'VG',
    },
    {
      name: 'Virgin Islands, U.S.',
      value: 'VI',
    },
    {
      name: 'Wallis and Futuna',
      value: 'WF',
    },
    {
      name: 'Western Sahara',
      value: 'EH',
    },
    {
      name: 'Yemen',
      value: 'YE',
    },
    {
      name: 'Zambia',
      value: 'ZM',
    },
    {
      name: 'Zimbabwe',
      value: 'ZW',
    },
  ],
  depressionScreen: {
    dynamics: 'Trends',
    level: 'Depression Level',
    newTest: 'Take a New Test',
    phq9: 'PHQ-9 Depression Test',
    phq9Info:
      'PHQ-9 (Patient Health Questionnaire-9) is a multipurpose instrument for screening, diagnosing, monitoring and measuring the severity of depression. PHQ-9 demonstrated good reliability and validity. It is a simple, rapid, effective, and reliable tool for screening and evaluation of the severity of depression.',
  },
  downgradeDetectedModal: {
    contactTherapist:
      'If you had your Premium access provided via a Mental Health Professional/Provider, please contact them to regain access.',
    contactSupport: 'If you think it is a mistake, please contact support at: ',
    downgrade: 'Downgrade my experience',
    notificationsTurnOff:
      'Some of your notifications and reminders will be turned off.',
    pay: 'Keep my paid features',
    text: 'Your subscription is no longer valid. Unless you subscribe again the paid features will be turned off. In particular you will lose access to:',
    title: 'Subscription Not Valid',
  },
  errorModal: {
    clientErrors: 'App Errors',
    code: 'Code',
    contact:
      'Try again or reload the app. If the issue persists, please contact us at: ',
    extraInfo: 'Extra Info',
    graphqlErrors: 'Graphql Errors',
    location: 'Location',
    message: `Message`,
    name: 'Name',
    network: 'Network error',
    path: 'Path',
    title: `Oops! We've got some errors.`,
    tokenExpiredTitle: 'Your session has expired',
    tokenError: 'Your token is invalid or expired. Please sign in again.',
    tokenSettings:
      'You can manage Sign-in settings in Settings -> Privacy Settings',
  },
  confirmModal: {
    cancelButton: 'Cancel',
    confirmButton: 'Confirm',
  },
  crisis: {
    crisisText: `If you are experiencing a mental health crisis and need immediate help, please contact your country or local crisis line.`,
    courage:
      'Remember, it takes courage to reach out for help, and there is no shame in seeking support for your mental health. You are not alone, and there are people and resources available to help you through this difficult time.',
    findCrisisLine: 'find a Crisis Line',
    selfHelp: 'Self-Help',
    mammalianText:
      'The Mammalian Diving Reflex is a physiological response that occurs in mammals, including humans, when they are submerged in water. This reflex triggers a series of automatic changes in the body, including a decrease in heart rate and constriction of blood vessels, in order to conserve oxygen and allow for extended periods of underwater activity. While the Mammalian Diving Reflex is typically associated with diving and swimming, recent research suggests that intentionally triggering this reflex through practices such as cold water immersion or breath-holding can help alleviate symptoms of anxiety and stress.',
    instructionsLink: 'Read Instructions',
    muscleRelaxationTitle: 'Progressive Muscle Relaxation by Jacobson',
    muslceRelaxationText:
      'Progressive Muscle Relaxation is a relaxation technique developed by physician Edmund Jacobson in the early 20th century that involves systematically tensing and relaxing different muscle groups in the body. The technique is designed to help individuals recognize and release physical tension in the body, which in turn can reduce feelings of anxiety and stress. Research has shown that regular practice of Progressive Muscle Relaxation can lead to significant reductions in anxiety and related symptoms, as well as improvements in sleep quality and overall well-being.',
    technique54321Text:
      'The 5-4-3-2-1 technique is a simple mindfulness exercise that can be used to reduce feelings of anxiety and stress. The technique involves engaging each of the senses in order to ground oneself in the present moment and distract from anxious thoughts. This is done by identifying and focusing on five things you can see, four things you can touch, three things you can hear, two things you can smell, and one thing you can taste. By focusing on sensory experiences in the present moment, individuals can shift their attention away from anxious thoughts and into the present moment, leading to a reduction in feelings of anxiety and stress.',
    goToTool: 'Go to the tool',
    meditationsText:
      'Mindfulness meditation is a technique that involves intentionally paying attention to present moment experiences, such as thoughts, emotions, and sensations, without judgment. Regular practice of mindfulness meditation has been shown to have a range of beneficial effects on mental health, including reducing symptoms of anxiety and stress. This is thought to be due to the fact that mindfulness meditation can help individuals develop greater awareness and acceptance of their thoughts and emotions, leading to a reduction in the tendency to become caught up in negative thought patterns and rumination. Additionally, mindfulness meditation has been shown to have positive effects on brain function, leading to increased emotional regulation and decreased reactivity to stressors.',
    goToMediations: 'Go to Meditations',
  },
  urgentCrisis: {
    internationlHelp:
      'If you are in another country, you can find a list of crisis lines in your country at',
    internationlHelpName: 'International Association for Crisis Psychology',
    ifUS: 'If you are in the United States, please call',
    orChat: 'or chat at',
    pleaseCall: 'please call',
    ifBY: 'If you are in Belarus',
    ifUA: 'If you are in Ukraine',
    ifRU: 'If you are in Russia',
    findCrisisLine: 'view the list of Crisis Lines',
  },
  mammalianReflex: {
    warning:
      '⚠️ Contraindications! Activating the diving reflex slows the heart rate. People with cardiac problems (particularly those with bradycardia) should avoid this strategy. Do not use this strategy if you have similar problems or if you are not sure if you have them. Consult your physician before use.',
    instructions: 'Instructions',
    details: `When we are in a state of extreme emotional arousal, our brain does not function properly to effectively absorb new information and process it. We need to "reset" our nervous system!\n
How can you help yourself if, while you are in an emotional state of agitation, you realize you need to calm down, but you are lack of internal resources and the advice to "calm down" only annoys you?\n
👉 An option to solve this problem is to use a psychophysiological effect available to every mammal, which is called the "diver reflex".\n
How to activate the reflex:
➡️ fill a bowl with ice water (50 degrees Fahrenheit / 10 degrees Celsius)
➡️ lean in and hold your breath
➡️ lower your face in ice water for 30 seconds
➡️ check: the area under the eyes/over the cheekbones (the most sensitive part of the face) should feel ice water\n
These elementary actions make the parasympathetic nervous system turn on - a person relaxes and calms down😇\n
✅ Life hack: the technique works its best if you hold your breath and bend over for 30 seconds.
    `,
  },
  technique54321: {
    title: '5-4-3-2-1 Grounding Technique',
    instructions: `The 5-4-3-2-1 technique is a grounding exercise that can help you to reduce anxiety and feel more present in the moment. Here are the steps to follow:\n
Find a quiet and comfortable place to sit or stand.\n
Take five deep breaths. As you breathe in, imagine that you are filling your lungs with calmness, and as you breathe out, imagine that you are releasing any tension or anxiety.\n
5. Next, look around and identify five things you can see. These could be objects in the room or outside, anything you can see in your surroundings. Focus on each item for a moment, and try to observe its shape, color, texture, and any other details that you notice.\n
4. Then, identify four things you can feel. This could be the texture of your clothes, the sensation of your feet on the ground, the temperature of the air around you, or anything else you can physically feel. Pay attention to each sensation, and try to describe it to yourself in as much detail as possible.\n
3. Next, identify three things you can hear. This could be the sound of traffic outside, the hum of your computer, the rustle of leaves in the wind, or anything else you can hear. Focus on each sound for a moment, and try to discern any patterns, rhythms, or tones that you notice.\n
2. Then, identify two things you can smell. This could be the scent of flowers, food cooking, perfume, or anything else you can smell. Take a deep breath and try to focus on the scent.\n
1. Finally, identify one thing you can taste. This could be the taste of your favorite food, drink, or something you can find in your surroundings. Take a moment to savor the taste and focus on it.\n
By the end of this exercise, you should feel more grounded, centered, and present in the moment. It is a great way to take a break from negative thoughts and anxiety, and to feel more connected to your surroundings.
    `,
  },
  fBConsentModal: {
    agreeButton: 'Accept and use Facebook SDK',
    cancelButton: 'Cancel',
    text: 'We need your consent to allow Facebook SDK initialization and events logging to enable Facebook Sign In / Sign Up.',
    title: 'Facebook SDK Consent',
  },
  flippingCard: {
    automaticThought: 'Automatic thought',
    cardName: 'Card name',
    categoriesSelectText: 'Choose thought categories',
    editEvent: 'Got to Activating Event',
    realisticThoughts: 'Realistic thoughts',
    supportingThoughts: 'Supporting thoughts',
    confirmCardDeleteTitle: 'Card deletion',
    confirmCardDeleteText: `Are you sure you want to delete this coping card?

  The card will be deleted permanently together with its related thought analysis, time-based and geolocation reminders.
    `,
  },
  muscleRelaxation: {
    videoTitle: 'Watch Video with Instructions',
    readTitle: 'Read Instructions',
    videoId: 'ihO02wUzgkc',
    phasesAndStepsTitle: 'Phases and Steps',
    phasesAndStepsText: `Jacobson's progressive muscle relaxation method eliminates anxiety in stressful situations. The relaxation process goes through the following phases.\n
1. Attempt to relax.
2. Relaxation.
3. Observation of the process of transition from tension to relaxation.
4. "Experiencing" relaxation.\n\n
Relaxation occurs in stages.
Relaxation of the muscles in your arms.
Relaxation of the muscles in your face.
Relaxation of the muscles of your neck and back.
Relaxation of the muscles of the lower part of your body.
Specific exercises correspond to each relaxation step.\n
Mastering these relaxation techniques will help to restore neuropsychic balance in the shortest possible time, to feel rested and full of energy.
`,
    armsTitle: 'Relaxing arms muscles (4-5 minutes)',
    armsText: `Make yourself comfortable (sitting or lying).

Relax as much as you can. With complete relaxation, clench the fist of your right arm, while controlling the degree of compression, feel the tension in the muscles of the arm, forearm - open your fist and feel relaxation. Compare your feelings.

Clench your fist again as tightly as possible - hold. Open your fist, try to achieve deep relaxation. Compare your feelings.

Repeat everything with your left arm. During this time, the whole body remains completely relaxed.

Squeeze the fingers of both arms into a fist.

Feel the tension in your fingers and forearms.

Open your fingers and relax. Compare your feelings. Continue relaxing.

Bend your right arm at the elbow and tighten your biceps.

Tighten the biceps as much as possible, control the tension of the biceps.

Extend your elbow, relax your biceps and feel the difference. Try to feel the relaxation of the biceps as much as possible.

Tighten your biceps again, maintain tension, feel the degree of tension.

Relax completely. Compare your feelings.

Constantly focus your attention on the sensations of tension and relaxation.

Repeat the same with your left arm.

Repeat the same with both arms at the same time.

Vary the degree and duration of muscle tension before relaxing. Compare your feelings. Extend your arms forward and feel the tension in your triceps. Return your arms to their original position, relax, feel the difference in sensations.

Repeat one more time. Compare your feelings again. Now try to completely relax the muscles of the arms without pre-tensioning.

Continue to relax more and more, achieve deeper relaxation.

Notice the feeling of warmth and heaviness in your arms as you relax. Arms relaxed and heavy... relaxed and heavy.
`,
    faceTitle: 'Relaxation of facial muscles (4-5 minutes)',
    faceText: `Wrinkle your forehead, raise your eyebrows.

Wrinkle your forehead more... and relax.

Move your eyebrows and note the tension.

Relax and smooth your brows.

Close your eyes tightly, feel the tension... and relax without opening your eyes.

Feel the relaxation of the muscles of the forehead, eyes.

Clench your teeth tightly, tighten your jaws. Feel the tension in your jaws.

Relax, open your mouth a little.

Press the tongue firmly against the upper palate. Feel the tension of the tongue...and relax by lowering the tongue.

Round your lips as if you want to say the letter "o", relax.

Feel the difference between tension and relaxation.

Feel the relaxation of all the muscles of your face: forehead, eyes, jaws, tongue relax. Relax completely.

Feel the warmth and heaviness in all the muscles of the face.
`,
    neckTitle: 'Relaxation of the muscles of the neck and back (4-5 minutes)',
    neckText: `Concentrate on the muscles of the neck.

Tilt your head back as much as possible, feel the tension in your neck.

Turn your head all the way to the left. Note the tension.

Turn your head to the right to failure, feel the tension.

Return your head to the starting position.

Relax. Compare your feelings.

Tilt your head forward, touch your chin to your chest. Feel the tension in your neck. Return your head to the starting position.

Relax, feel warm and relaxed.

Raise your shoulders to your ears, bring your shoulder blades together without straining your arms. Make a circular movement with your shoulders with maximum amplitude.

Return your shoulders to their original position. Relax. Feel the pleasant contrast between tension and relaxation.

Arch your spine and feel tension along your spine.

Return to starting position. Feel the relaxation, it spreads all over your back.

The back is heavy and warm. Feel the pleasant warmth...
`,
    legsTitle: 'Lower body relaxation (4-5 minutes)',
    legsText: `Concentrate on the lower body.

Tighten your buttocks and thighs, pressing your heels firmly into the ground.

Relax... Note the difference in sensations.

Tighten your buttocks and thighs again.

Keep them tense.

Keeping tension in the buttocks and hips, bend your feet, toes towards your face, feel the tension in the calf muscles.

Relax. Even deeper... deeper.

Feel the relaxation of all the muscles in your lower body.

Notice how your legs get heavier as you relax. The legs are relaxed and heavy.

Feel the warmth spreading through your legs.

Legs are heavy and warm.
    `,
    summary: `Once you have completed the entire sequence, take a few deep breaths and scan your body for any remaining areas of tension.

If you find any areas that are still tense, repeat the relaxation exercise for those muscles.

When you are finished, take a few more deep breaths and slowly open your eyes.

By practicing Progressive Muscle Relaxation regularly, you can become more aware of your body and learn to recognize when you are holding tension in your muscles. This technique can help you reduce stress and promote relaxation in your daily life.   
    `,
  },
  gad7: {
    q1: `Feeling nervous, anxious or on edge`,
    q2: `Not being able to stop or control worrying`,
    q3: `Worrying too much about different things`,
    q4: `Trouble relaxing`,
    q5: `Being so restless that it is hard to sit still`,
    q6: `Becoming easily annoyed or irritable`,
    q7: `Feeling afraid as if something awful might happen`,
    score: `GAD-7 Score`,
    severity: `Anxiety Severity`,
    title: `GENERAL ANXIETY DISORDER-7 (GAD-7)`,
  },
  insights: {
    cardsFlipped: 'Coping Cards Viewed',
    dayRemindersTitle: 'Time-based Reminders Usage',
    locationRemindersTitle: 'Geolocation Notifications Usage',
    mostUsedCards: 'Most Used Cards',
    opened: 'Opened',
    triggered: 'Triggered',
    triggeredOpenedRatio: 'Percent Opened of Triggered',
    meditationsTitle: 'Meditations Listened',
  },
  inviteTherapistModal: {
    title: 'Invite a Mental Health Professional',
    description:
      'Invite a therapist to join the CopingCard Provider Platform so that you could share your data with them to make your therapy more effective.',
    noName:
      'It looks like you have not entered your name yet. Please enter your name so that we could mention it in the invitation email.',
    yourName: 'Your First Name',
    yourLastName: 'Your Last Name',
    therapistInfo: 'Mental Health Professional Info',
    firstName: 'First Name',
    lastName: 'Last Name',
    therapistEmail: 'Email',
    sendInvite: 'Send Invite',
    emailText: 'Invite Email Text Preview',
    inviteText: `
Hi {{firstName}} {{lastName}},

{{yourName}} {{yourLastName}} invites you to join the CopingCard Provider Platform.

Click the link above to accept the invitation and or create your account and accept the invitation.

This would allow for effective information and progress sharing during therapy.

Best regards,
CopingCard, Inc. Team
    `,
    inviteTextHtml: `
    <p>Hi {{firstName}} {{lastName}},</p>
    <p>{{yourName}} {{yourLastName}} invites you to join the <b><a href="{{link}}">CopingCard</a></b> Provider Platform.
    <br />
    <p>Click the link above to accept the invitation and or create your account and accept the invitation.</p>
    <br />
    <p>This would allow for effective information and progress sharing during therapy.</p>
    <br />
    <p>Best regards,</p>
    <p>CopingCard, Inc. Team</p>
    `,
  },
  labels: {
    email: 'Email',
    password: 'Password',
  },
  locationModal: {
    addLocation: 'Add location',
    locationName: 'Location name',
    savedLocations: 'Saved Locations',
    searchPlaceholder: 'Search',
    selectSavedLocation: 'Select from saved locations',
  },
  meditations: {
    loading: 'Loading meditations. It can take some time on the first load...',
  },
  navigation: {
    appBlockModalText:
      'The app is blocked from receiving notifications, which makes receiving reminders not possible. Please go to the Settings and enable notifications.',
    backButtonTitle: 'Back',
    blockModalTitle: 'Restrictions Detected',
    channelBlockModalText:
      'The notifications channel is blocked from receiving notifications, which makes receiving reminders not possible. Please go to the Settings and enable notifications.',
    channelGroupBlockModalText:
      'The notifications channel group is blocked from receiving notifications, which makes receiving reminders not possible. Please go to the Settings and enable notifications.',
    toCopingCards: 'Go To Coping Cards',
  },
  notificationPermissionsModal: {
    settingText: 'Open App Settings',
    text: 'You have denied or removed Notification Permissions for CopingCard.',
    text1:
      'Notification Permissions are required for us to be able to send notifications to you.',
    text2:
      'You need to manually enable the notifications in the Settings UI to be able to get reminders.',
    title: 'Notification Permissions Disabled!',
  },
  notifications: {
    locationReminderBody:
      'You triggered a geolocation reminder for your thought',
    locationReminderTitle: 'Geolocation Reminder',
    testReminderBody: 'It is time to take your scheduled test',
    testReminderTitle: 'Scheduled Test Notification',
    timeNotificationBody: 'It is time to recall your new thought',
    timeNotificationTitle: 'Time-based notification',
    fineLocatonPermissionTitle: 'Please allow fine location permission',
    fineLocatonPermissionText:
      'Fine location permission is required to show your location on the map.',
  },
  phq9: {
    q1: `Little interest or pleasure in doing things`,
    q2: `Feeling down, depressed, or hopeless`,
    q3: `Trouble falling or staying asleep, or sleeping too much`,
    q4: `Feeling tired or having little energy`,
    q5: `Poor appetite or overeating`,
    q6: `Feeling bad about yourself — or that you are a failure or have let yourself or your family down`,
    q7: `Trouble concentrating on things, such as reading the newspaper or watching television`,
    q8: `Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual`,
    q9: `Thoughts that you would be better off dead or of hurting yourself in some way`,
    score: `PHQ-9 Score`,
    severity: `Depression Severity`,
    title: `PATIENT HEALTH QUESTIONNAIRE-9 (PHQ-9)`,
  },
  privacySettings: {
    accept: 'Accept',
    agree: 'Accept and Save Settings',
    deleteText:
      'To delete your account and related data please contact us at: ',
    errorsTracking: 'Errors Info Collection',
    errorsTrackingInfo:
      'Collect the errors and crashes info to assist with fixes and ease support.',
    iAgree: 'I agree to the',
    keepSignedIn: 'Keep me Signed-In',
    keepSignedInInfo: `I do not want the app to log me out frequently.`,
    keepSignedInWarning: `
      WARNING! If you turn the setting on and someone gets unauthorized access to your phone (e.g. it is stolen and not password-protected while still having internet access) it can lead to a leak of your personal health information.
      We strongly encourage you to keep the setting off.
      `,
    mainTerms:
      'Please read and accept the Privacy Policy and the Terms of Service to use the application',
    optionalSettings: 'Optional Privacy Settings',
    productEmailNotify: 'Receive Updates Emails',
    productEmailNotifyInfo:
      'Send me information about products, services, updates, deals, or recommendations by email.',
    productPushNotify: 'Receive App Updates in-app Notifications',
    productPushNotifyInfo:
      'Send me information about products, services, updates, deals, or recommendations via push notifications.',
    restoreSubscription: 'Restore Subscription',
    usageTracking: 'Application Usage Google Analytics Tracking',
    usageTrackingInfo:
      'Collect information on screens I open in the app with the help of Google Analytics to help improve the app.',
  },
  questionariesShared: {
    actions: `Typically Proposed Treatment Actions`,
    actions0: `None`,
    actions1: `Watchful waiting; repeat {{test}} at follow-up`,
    actions2: `Treatment plan, considering counseling, follow-up and/or pharmacotherapy`,
    actions3: `Active treatment with pharmacotherapy and/or psychotherapy`,
    actions4: `Immediate initiation of pharmacotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist for psychotherapy and/or collaborative management`,
    answer0: `Not at all`,
    answer1: `Several days`,
    answer2: `More than half the days`,
    answer3: `Nearly every day`,
    backToTests: 'Go back to tests',
    disclaimer:
      'DISCLAIMER: Even though the {{test}} shows high reliability and validity, it cannot substitute medical advice, diagnosis or treatment by a mental health professional. This app does not provide medical advice. It is intended for informational purposes only. Never ignore professional medical advice in seeking treatment because of something you have read in our application or website. If you think you may have a medical emergency, immediately call your doctor or dial 911(or your emergency service number).',
    extraQuestion1: `How difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?`,
    extraQuestionAnswer0: `Not difficult at all`,
    extraQuestionAnswer1: `Somewhat difficult`,
    extraQuestionAnswer2: `Very difficult`,
    extraQuestionAnswer3: `Extremely difficult`,
    intro1: `Over the last 2 weeks, how often have you been bothered by the following problem?`,
    nextQuestion: `Next Question`,
    score: `{{test}} Score`,
    seeResult: 'See Result',
    severity0: `None-minimal`,
    severity1: `Mild`,
    severity2: `Moderate`,
    severity3: `Moderately Severe`,
    severity4: `Severe`,
    testReminder: 'Next Test Reminder',
    testResult: 'Test Result',
    seeTherapistsText:
      'Do you want to search for Mental Health Professionals who use CopingCard as part of their treatment process?',
    seeTherapists: 'Go to Therapists Search',
  },
  rnPaperDates: {
    close: 'Close',
    dateIsDisabled: 'Day is not allowed',
    mustBeBetween: (startDate: any, endDate: any) =>
      `Must be between ${startDate} - ${endDate}`,
    mustBeHigherThan: (date: any) => `Must be later then ${date}`,
    mustBeLowerThan: (date: any) => `Must be earlier then ${date}`,
    next: 'Next',
    notAccordingToDateFormat: (inputFormat: any) =>
      `Date format must be ${inputFormat}`,
    pickDateFromCalendar: 'Pick date from calendar',
    previous: 'Previous',
    save: 'Save',
    selectMultiple: 'Select dates',
    selectRange: 'Select period',
    selectSingle: 'Select date',
    typeInDate: 'Type in date',
  },
  screens: {
    abcModel: 'ABC Model',
    abcModels: 'ABC Models',
    anxiety: 'Anxiety',
    article: 'Article',
    articles: 'Articles',
    audio: 'Audio',
    behaviorExperiment: 'Behavior Experiment',
    behaviorExperiments: 'Behavior Experiments',
    cardNotifications: 'Coping Card Notifications',
    copingCard: 'Coping Card',
    copingCards: 'Coping Cards',
    crisis: 'Crisis',
    urgentCrisis: 'Urgent Crisis',
    depression: 'Depression',
    downwardArrow: 'Downward Arrow',
    downwardArrows: 'Downward Arrows',
    exposures: 'Exposures',
    exposure: 'Exposure',
    exposureSituation: 'Exposure Situation',
    gad7: 'GAD-7',
    generalSettings: 'General Settings',
    home: 'Home',
    info: 'Info',
    insights: 'Insights',
    learn: 'Learn',
    mammalianReflex: 'Mammalian Diving Reflex',
    muscleRelaxation: 'Muscle Relaxation',
    technique54321: '5-4-3-2-1 Technique',
    meditations: 'Meditations',
    notificationsSettings: 'Notifications Settings',
    passwordReset: 'Reset Password',
    phq9: 'PHQ-9',
    privacy: 'Privacy Policy',
    privacySettings: 'Privacy Settings',
    saas: 'Software Services Agreement for Health Care Providers',
    settings: 'Settings',
    signIn: 'Sign In',
    subscription: 'Subscription Management',
    viciousCycles: 'Vicious Cycles',
    viciousCycle: 'Vicious Cycle',
    terms: 'Terms & Conditions',
    tests: 'Tests',
    therapist: 'Mental Health Professional',
    therapists: 'Search Mental Health Professionals',
    therapistMessage: 'Message Therapist',
    therapistsSharing: 'Data Sharing with Therapists',
    thoughtsTesting: 'Dysfunctional Thoughts Test',
    thoughtsTestingLocation: 'Event Location',
    toolKit: 'Toolkit',
    tools: 'Tools',
    video: 'Video',
    worryTrees: 'Worry Trees',
    worryTree: 'Worry Tree',
  },
  sendTherapistMessageModal: {
    title: 'Send Email to the Therapist',
    message: 'Enter Your Message',
  },
  settings: {
    chooseLanguage: 'Choose Your Language',
    displayName: 'Display name',
    displayNameHint:
      'Display name comes from the OAuth provider you used to log in with, like Facebook or Google',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    profileInfo: 'User Info',
    reminders: 'Reminders',
    remindersCount: 'Total reminders',
    saveSettings: 'Save Settings',
    searchTimezone: 'Search Country or Timezone',
    signOut: 'Sign Out',
    userId: 'User ID',
    userIdCopied: 'User ID Copied to Clipboard',
  },
  signIn: {
    authEmailInUse: 'That email address is already in use!',
    authEmailInvalid: 'That email address is invalid!',
    authFacebookCancel: 'User canceled the login/signup process.',
    authNoToken: 'Something went wrong obtaining {{token}} access token',
    authUserNotFound: 'We have no user with this email!',
    authWeakPassword: 'That password is not strong enough!',
    authWrongPassword: 'The password is wrong for this user!',
    checkEmailAgain: `Still no email or not sure you entered the correct one?`,
    checkEmailSpam: `If you do not receive an email from us within several minutes, check your spam folder to make sure the email is not there.`,
    websiteOpenInstructions:
      'If the link to verify email opens in a browser instead of our Mobile app, you will need to close and re-open the Mobile app for the verification to work.',
    checkEmailTitle: `Reset Email Sent`,
    confirmEmailTitle: 'Confirmation Email Sent',
    confirmPassword: 'Confirm password',
    email: 'Email address',
    facebookSignUp: 'Sign Up with Facebook',
    facebooSignIn: 'Sign In with Facebook',
    forgotPassword: 'Forgot password?',
    googleSignIn: 'Sign In with Google',
    googleSignUp: 'Sign Up with Google',
    noAccount: 'Do not have an account yet?',
    password: 'Password',
    passwordEmailHint:
      'Please provide the email you registered with so that we could send you a password reset email. Please click the link in the email on your phone to proceed.',
    passwordHint:
      'The password should be at least 8 characters long. Adding numbers, uppercase letters, and special characters is encouraged but not required.',
    passwordResetFinished:
      'We have updated your password. You will be automatically redirected to the Sign In screen shortly.',
    reset: 'Reset',
    resetTitle: 'Reset your password',
    signIn: 'Sign In',
    signUp: 'Sign up',
    title: 'Welcome Back to CopingCard',
    tryAnother: 'Still no email?',
    tryAnotherButton: 'Go back to Sign In',
  },
  states: [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'Washington, DC',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  subscription: {
    feature1: 'Unlimited Time-based Reminders',
    feature2: 'Unlimited Worry Trees',
    feature3: 'Unlimited Geolocation Notifications',
    feature4: 'Unlimited PHQ-9 Depression and GAD-7 Anxiety Tests',
    feature5: 'Unlimited Meditations Listening',
    feature6: 'Unlimited Behavior Experiments',
    feature7: 'Unlimited history in Insights/Stats',
    feature8: 'Unlimited Downward Arrows',
    feature9: 'Unlimited Expositions',
    feature10: 'Unlimited AI-generated Images',
    featureDescription1: 'Free accounts get only 9 active time-based reminders',
    featureDescription2: 'Free accounts can create only 1 Worry Tree per day',
    featureDescription3:
      'Free accounts get only 2 active geolocation notifications',
    featureDescription4:
      'Free accounts can have PHQ-9 and GAD-7 tests only once every 2 weeks',
    featureDescription5:
      'Free accounts can listen to a meditation only once every 3 days',
    featureDescription6:
      'Free accounts can create Behavior Experiments only once every 3 days',
    featureDescription7: 'Free accounts get only 1 month of statistics',
    featureDescription8:
      'Free accounts can create only 2 Downard Arrows per day',
    featureDescription9:
      'Free accounts can create an Exposure only once every 3 days',
    featureDescription10:
      'Free accounts can create only 3 AI-generated images per day',
    month_one: 'month',
    month_other: 'months',
    noSubscriptionText:
      'We did not receive any purchases to restore from the app store. If you believe it is a mistake, please contact us at support@copingcard.com',
    noSubscriptionTitle: 'No purchases to restore',
    notNow: 'Not now',
    paymentInfo1:
      'Pricing will be automaticallly convereted into your local currency. Payment will be charged to {{store}} Account upon confirmation of purchase.',
    paymentInfo2:
      'Subscription will renew automatically unless auto-renew is turned off at least 24 hours before the end of the current period.',
    paymentInfo3:
      'Your account will be charged for renewal within 24 hours prior to the end of the current period.',
    paymentInfo4:
      'You can manage and cancel your subscriptions by going to your account settings on the {{store}} after purchase.',
    paymentInfo5:
      'You can turn off auto-renew at any time from your {{store}} account settings but refunds will not be provided for the unused portion of the term.',
    paymentInfo6:
      'Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subcription, where applicable.',
    paymentMotivation:
      'A paid subscription allows us to cover our costs and add more features',
    paywall_downgrade:
      'Please choose a subscription below to keep your paid features and notifications turned on. The features you maintain after subscribing are:',
    paywall_geo_notifications:
      'Many of the features we provide to you, e.g. Geolocation Notifications require us to pay other companies and providers. Please upgrade to get access to:',
    paywall_settings:
      'Many of the features we provide to you require us to pay other companies and providers. Please upgrade to get access to:',
    paywall_stats:
      'Many of the features we provide to you, e.g. Unlimited Stats access require us to pay extra to other providers (hosting, etc.). Please upgrade to get access to:',
    paywall_tests_phq_9:
      'Many of the features we provide to you require us to pay other companies and providers. Please upgrade to get access to:',
    paywall_time_notifications:
      'Many of the features we provide to you, e.g. Time-based Notifications require us to pay other companies and providers. Please upgrade to get access to:',
    paywall_behavior_experiments:
      'Free accounts can create Behavior Experiments only once every 3 days. Upgrade now to have unlimited Behavior Experiments enabled and other Premium features.',
    paywall_meditations:
      'Free accounts can listen to a meditation only once every 3 days. Upgrade now to have unlimited meditations access and other Premium features.',
    paywall_worry_trees:
      'Free accounts can create only 1 Worry Tree per day. Upgrade now to have unlimited Worry Trees enabled and other Premium features.',
    paywall_exposures:
      'Free accounts can create an Exposure only once every 3 days. Upgrade now to be able to create unlimited Exposures and other Premium features',
    paywall_ai_image_generation:
      'Free accounts can create only 3 AI-generated images per day. Upgrade now to be able to create unlimited AI-generated images and other Premium features.',
    save: 'save',
    subscriptionFoundText:
      'We were able to find an existing subscription for you. Your Premium access is restored.',
    subscriptionFoundTitle: 'Existing Subscription Found',
    title: 'Unlock Premium Features',
    titlePremium: 'You Have Premium Access',
    premiumIntro: 'This unlocks the following Premium features for you:',
    year_one: 'year',
    year_other: 'years',
    goBack: 'Go back',
  },
  therapist: {
    call: 'Call',
    sendMessage: 'Send Email',
    about: 'About',
    aceptingClients: 'Accepting Clients',
    mentalHealthRole: 'Mental Health Role',
    phone: 'Phone',
    extension: 'Extension',
    therapyFormats: 'Therapy Formats',
    licensedInStates: 'Licensed in States',
    licenseNumber: 'License Number',
    yearsOfPractice: 'Years of Practice',
    education: 'Education',
    languages: 'Languages',
    therapyApproaches: 'Therapy Approaches',
    finances: 'Finances',
    contPerSessionFrom: 'Cost per Session From',
    to: 'To',
    currency: 'Currency',
    freeInitialConsulatation: 'Free Initial Consultation',
    slidingScale: 'Sliding Scale',
    clients: 'Clients',
    modality: 'Modality',
    age: 'Age',
    issues: 'Issues',
    linkToWebsite: 'Link to Profile on another resource',
    acceptedInsurances: 'Accepted Insurances',
    visitProfile: 'Visit Profile',
    messageSentSuccess: 'Message sent successfully',
  },
  therapistsSearch: {
    info: 'Please specify your location to find therapists in your area.',
    country: 'Your Country',
    state: 'Your State',
    learnMoreAndContact: 'Learn more and contact',
    providerInvitationText:
      'Are you a Mental Health Professional and want to be listed here? Please create a Free Provider Account at:',
    noResults: 'No results found in your area. Please try again later.',
  },
  therapistsSharing: {
    title: 'Data Sharing with Mental Health Professionals',
    inviteLinkOrToken:
      'Do you have an invite link or token from a Mental Health Professional?',
    enterLinkOrToken: 'Enter a link or token',
    dataYouCanShare:
      'Data you can share with your Mental Health Professionals includes: Coping Cards, Dysfunctional Thought Tests data, PHQ-9 Depression and GAD-7 Anxiety Tests results and tools usage statistics.',
    adminDataYouCanShare: `
This would allow admins of the therapist's practice to VIEW the same data that you share wth your therapist.

Some of the situations when this might be useful are:
- your therapist is on vacation and you need to share your data with another therapist in the practice
- you want to allow your therapist to get help/second oppinion from other therapists in the practice
- multiple therapists in the practice work with you
- transistioning from one therapist to another within the practce
      `,
    currentTherapists: 'You currently share data with',
    professional: 'Mental Health Professional',
    viewSharing: 'Allow viewing my data',
    editSharing: 'Allow editing my data',
    adminViewSharing: 'Allow practice admins to view my data',
    adminEditSharing: 'Allow practice admins to edit my data',
    inviteTherapist: 'Invite your Therapist',
    enterInviteModalTitle: 'Enter Invite Link or Token',
    enterInviteModaLabel: 'Invite Link or Token',
    editingTip: `
Requires view permission to be granted first.

It can be very helpfull to allow your Therapist to edit data at initial stages of therapy, especially during Telehealth sessions.

Your Mental Health Professional will be able to edit your Coping Cards and Dysfunctional Thought Tests data to help you learn the tools faster.

You will be able to revoke the access at any time.
      `,

    adminEditingTip: `
This would allow admins of the therapist's practice to EDIT the same data that you allow your therapist to EDIT.
This permission requires Viewing permission as well.

Some of the situations when this might be useful are:
- your therapist is on vacation and you need to share your data with another therapist in the practice
- you want to allow your therapist to get help/second oppinion from other therapists in the practice
- multiple therapists in the practice work with you
- transistioning from one therapist to another within the practce
      `,
    acceptInvite: 'Accept Invite',
    pendingInvites: 'Pending Invites',
    inviteFrom: 'Invite from',
    expiresAt: 'Expires on',
    confirmRevoke: 'Confirm Revoke',
    editRevoke: `
If you revoke Edit access to your data, your Mental Health Professional will no longer be able to quickly edit your Coping Cards or Dysfunctional Thoughts Tests.
    `,
    viewRevoke: `
If you revoke View access to your data, your Mental Health Professional will no longer be able to see how you use our app and what content you crate, which can make it more difficult to assist you in therapy.

This will also revoke Edit access.
  `,
    noTherapists:
      'You have not shared your data with any Mental Health Professionals yet.',
    noTokenTitle: 'Token not found',
    noTokenText: `
We have not found this token in our database.

It might have expired or was incorrect.

Please verify it and try again or contact your Mental Health Professional for a new token/invite link.
`,
    accessGranted: 'You have successfully granted access to your data',
    accessUpdated: 'You have successfully updated access to your data',
    inviteSent: 'Your invite has been sent',
    alreadyAdded:
      'You already share your data with this Mental Health Professional',
    congratsOnPremium: 'You got Premium Access!',
    congratsOnPremiumText: `
Congratulations! 

Your mental health care provider has granted you full access to the Premium features of our app.

We hope this will help you on your journey to be happier and healthier.
    `,
  },
  thoughtChoiceModal: {
    title: 'Card Creation',
    text: 'Choose if you want to create the card going through the Automatic Thoughts Testing or just quickly create a card',
    goToTesting: 'Go to Thought Testing',
    quickEdit: 'Quickly add the card',
  },

  thoughtsTesting: {
    addEvidenceAgainst: 'Add evidence against',
    addEvidenceFor: 'Add evidence',
    automaticThought: 'Automatic thought',
    automaticThoughtAdd: 'Add thought',
    automaticThoughtBelief: 'Belief',
    automaticThoughtDefinition:
      'Automatic thought is an idea or a visual image, an assessment of an event or yourself in it, which appeared together with or shortly before the worsening of the condition.',
    automaticThoughtName: 'Name',
    autoThoughtsTitle: 'List Your Automatic Thoughts',
    buttonNextText: 'Next Step',
    chooseEmotions: 'Choose Emotions',
    chooseFeelings: 'Choose Sensations',
    cognitiveDistortions: 'Cognitive Distortions',
    cognitiveDistortionsDefinition:
      'A cognitive distortion is an exaggerated or irrational thought pattern',
    cognitiveDistortionsPlaceholder: 'Select Cognitive Distortions',
    congrats: 'Congratulations!',
    // TODO: refactor to i18n withCount and _plural
    // https://www.i18next.com/translation-function/plurals
    congratsCardCreated: `A New Coping Card was created as a result.`,
    congratsCardsCreatedEnd: `new Coping Cards have been created as a result.`,
    congratsCardsCreatedStart: ``,
    congratsIntro: `You finished testing your automatic thoughts!`,
    congratsTextPlural: `You can find the Coping Cards for these thoughts in the Coping Cards section of the App.`,
    congratsTextSingle: `You can find the Coping Card for this thought in the Coping Cards section of the App. `,
    dateAndDuration: 'Event Date and Duration',
    day: 'Day reminder',
    emotionDefinition:
      'Emotion is a mental process reflecting in the form of direct experience (satisfaction, joy, fear, etc.) the significance of phenomena and situations acting on a person. Part of the experience of emotions are bodily sensations.',
    emotionsTitle: 'Describe Your Emotions',
    evening: 'Evening reminder',
    eventInfo: 'An event that made your condition noticeably worse.',
    eventLabel: 'Describe Activating Event',
    eventLasted: 'lasted for',
    eventStarted: 'started on',
    eventTitle: 'Activating Event',
    evidenceAgainst: 'Fact/Evidence against the Negative Thought',
    evidenceFor: 'Thought fact/evidence',
    withEvidenceFor: 'Thought fact/evidence',
    withEvidenceAgainst: 'And evidence against the Negative Thought',
    alternativeThought: 'Alternative explanation',
    alternativeThoughtInfo:
      'Give a possible alternative explanation of the evidence for, if there is one.',
    alternativeThoughtTitle:
      'What else can be the explanation of this fact, except for the automatic thought?',
    feelingsDefinition:
      'Sensation is the process of sensual cognition of separate properties of phenomena and objects of the objective world, i.e. the process of reflection of direct influence of stimuli on sensory organs (receptors).',
    feelingsInBody: 'Sensations in the Body',
    feelingsTitle: 'Describe Your Sensations',
    forThought: 'For the automatic negative thought',
    with: 'With a',
    having: 'Which can have an',
    location: 'Location',
    locationReminders: 'Geolocation Notifications',
    locationRemindersDisclosure:
      'Copingcard requires collecting precise location data to enable Geolocation Notifications for your cards even when the app is closed or not in use.',
    locationRemindersWhy:
      'Geofencing location reminders can help your recall realistic thought when you are approaching (200 yards) and/or leaving a location. ',
    morning: 'Morning reminder',
    optionalData: 'Optional Data',
    participants: 'Participants',
    photos: 'Photo or Image',
    photosDialogueTitle: 'Choose Image Source',
    rateEmotions: 'Rate Emotions Strength',
    realisticThought: 'Realistic thought or idea',
    realisticThoughtsDefinition:
      'Realistic thoughts are judgments about the event or yourself, which follow from the facts you listed or (if it is impossible to verify the original thought with facts) help you feel better.',
    realisticThoughtsTitle: 'List Realistic and Helpful Ideas',
    reminderLabel: 'Select Reminder Time',
    reminders: 'Thought reminders',
    remindersWhy:
      'Reading through the automatic and realistics sides of the coping cards 2-3 times a day greatly reduces the effects of the dysfunctional thoughts. We pre-created 3 reminders for you to read the thoughts, but you can customize the time to suit you better.',
    resultBelief: 'How strongly you believe in the initial negative thoughts?',
    resultEmotionStrength: 'How strong is the main initial negative emotion?',
    resultsTitle: 'Assess Result',
    selectEnd: 'Select End Time',
    selectStart: 'Select Start Time',
    thoughtForForThought: 'for thought',
    thoughtsAgainstDefinition:
      'Facts or Evidence which are accessible to sensory perception and verification of specific phenomena of reality (facts), which indicate that this thought is wrong.',
    thoughtsAgainstTitle:
      'List Facts and Evidence against  the Negative Thought',
    thoughtsForDefinition:
      'Facts or Evidence which are accessible to sensory perception and verification of specific phenomena of reality (facts), which indicate that this thought is correct.',
    thoughtsForTitle: 'List Facts and Evidence supporting the Negative Thought',
    timeReminders: 'Time-based Reminders',
    validationErrors:
      'It looks like there are some validation errors. Please fix them before continuing or contact the support if something is not evident on how to fix it.',
  },
  validators: {
    email: 'must be a valid email',
    regex: 'must match the following: {{regex}}',
    required: 'is a required field',
    retypePassword: 'passwords must match',
    stringMax: 'must be at most {{max}} characters',
    stringMin: 'must be at least {{min}} characters',
    url: 'Please enter a valid URL',
  },
  viciousCycles: {
    title: 'Vicious Cycles',
    info: 'Vicious Cycles',
    definition: `In cognitive behavior therapy, vicious cycles refer to patterns of negative thoughts, emotions, and behaviors that maintain or exacerbate a particular problem or issue. These cycles involve a self-perpetuating loop in which negative beliefs and emotions lead to maladaptive behaviors, which in turn reinforce negative beliefs and emotions. The result is a worsening of the problem or issue, as well as an increase in the intensity of the negative thoughts, emotions, and behaviors associated with it. The goal of cognitive behavior therapy is to identify and disrupt these vicious cycles by helping clients develop more adaptive coping strategies and beliefs, which can ultimately lead to positive change and improved well-being.`,
    edit: 'Edit Vicious Cycle',
    totalCount: 'Total: {{count}}',
    create: 'Create Vicious Cycle',
    advancedWarning1: `This is an advanced Cognitive Behavioral Therapy technique. If you are not sure how to use it, it might be better to `,
    advancedWarning2: ` to use our Platform or `,
    advancedWarning3: ` who already uses it in therapy.`,
    inviteTherapist: 'invite your therapist',
    deleteSuccess: 'Vicious cycle deleted successfully',
    therapistFind: 'find one',
  },
  viciousCycle: {
    title: 'Vicious Cycle',
    selectTemplate: 'Select template (optional)',
    cycleName: 'Name',
    thoughts: 'Thoughts, actions or some facts',
    relations: 'Leads To',
    influences: 'Influences',
    tempalteLabel: 'Start from a template (optional)',
    saveSuccess: 'Vicious cycle saved successfully',
    saveError: 'Error saving supporting cycle',
    addField: 'Add thought, action or fact',
  },
  shared: {
    genderOptions: ['Male', 'Female', 'Other'],
    preferredPronounsOptions: [
      'He/Him/His',
      'She/Her/Hers',
      'They/Them/Theirs',
    ],
    abcModel: {
      title: 'ABC Model',
      saveSuccess: 'ABC model saved successfully',
      description: `The ABC model technique is a cognitive-behavioral therapy (CBT) tool used to help individuals understand the connection between their thoughts, emotions, and behaviors. The model is based on the premise that it is not the events themselves that cause emotional distress, but rather our interpretation of those events. The ABC model consists of three main components:  

A - Activating event: The situation or trigger that initiates a thought or emotion. 

B - Beliefs: The thoughts or interpretations we have about the activating event. 

C - Consequences: The emotional and behavioral responses resulting from our beliefs. 

The goal of the ABC model is to help individuals identify and challenge their irrational or maladaptive beliefs and replace them with healthier, more adaptive thoughts, thereby promoting more positive emotional and behavioral outcomes.`,
      a: 'A - Activating event',
      aPlaceholder: 'What happened?',
      b: 'B - Beliefs',
      bPlaceholder: 'What did you think?',
      c: 'C - Consequences',
      behaviors: 'Behavior',
      behaviorsPlaceholder: 'How did you react?',
      emotions: 'Emotions',
      emotionsPlaceholder: 'Which emotions did you experience?',
      sensations: 'Sensations',
      sensationsPlaceholder: 'What body sensations did you experience?',
    },
    abcModels: {
      title: 'ABC Models',
      create: 'Create ABC Model',
      edit: 'Edit ABC Model',
    },
    behaviorExperiment: {
      title: 'Behavior Experiment',
      description: `A behavior experiment is a technique commonly used in Cognitive Behavioral Therapy (CBT) to test the validity of a patient's beliefs and assumptions. The purpose of the experiment is to gather empirical evidence that challenges the patient's unhelpful thoughts and behaviors, and replaces them with more realistic and adaptive ones.

      In a behavior experiment, the therapist and patient collaboratively design an experiment to test the patient's assumptions or beliefs. For example, if a patient has a fear of public speaking, the experiment could involve giving a short speech to a small group of trusted individuals. The therapist and patient would then observe and evaluate the patient's experience, including any physiological or emotional responses, and any feedback received.
      
      By conducting the experiment and analyzing the results, the patient can gain new insights into their thoughts and behaviors, and develop more adaptive coping strategies. Behavior experiments are often used in conjunction with other CBT techniques, such as cognitive restructuring, to help patients challenge and change their unhelpful thought patterns.
      `,
      purpose: 'Experiment Purpose',
      purposeRequired: 'Experiment Purpose is required',
      purposePlaceholder: 'What do you want to test?',
      purposes: [
        'Develop the Formulation/Conceptualization',
        'Testing Negative Cognitions',
        'Constructing and testing new perspectives',
      ],
      purposesTip: `
      Develop the Formulation/Conceptualization: these experiments help elaborate the formulation of the patient's problems, especially early in therapy when understanding is still evolving. These experiments offer therapists and clients the chance to observe subtle aspects of the patient's behavior and thinking that may not be accessible purely through memory-based verbal reports.
      Testing Negative Cognitions: test negative cognitions, reducing the credibility of outdated and unhelpful negative automatic thoughts, dysfunctional assumptions, and core beliefs. These experiments allow patients to see the validity of new, more adaptive perspectives through direct personal experience.
      Constructing and testing new perspectives: creating and strengthening new, more realistic and more helpful thoughts, assumptions, and beliefs.
      `,
      design: 'Experiment Design',
      designRequired: 'Experiment Design is required',
      designs: ['Hypothesis-testing experiment', 'Discovery experiment'],
      designsTip: `Hypothesis-testing experiments try to find answers to questions similar to ‘Is it true that... ?’.
      Discovery experiments are more open, investigative, discovery-oriented questions similar to ‘What would happen if . . . ?’
      `,
      hypotheses: 'Hypotheses',
      hypothesisType: 'Number of Hypotheses',
      hypothesisTypeVariants: [
        'Testing hypothesis A',
        'Comparing hypothesis A & hypothesis B',
        'Testing hypothesis B',
      ],
      hypothesisTypeVariantsTip: `
      Testing hypothesis A: tests the validity of a current unhelpful cognition (hypothesis A).
      Comparing hypothesis A & hypothesis B: tests the validity of a current unhelpful cognition (hypothesis A) and a new, potentially helpful or justified cognition (hypothesis B).
      Testing hypothesis B: tests the validity of a new, potentially helpful cognition (hypothesis B).
      `,
      type: 'Experiment Type',
      typeVariants: ['Active experiment', 'Observational experiment'],
      activeSubtype: 'Active Experiment Subtype',
      activeSubtypes: ['In real situations', 'Simulated (e.g. role play)'],
      observationalSubtype: 'Observational Experiment Subtype',
      observationalSubtypes: [
        'Direct observation (modelling)',
        'Survey',
        'Information gathering from other sources',
      ],
      settings: 'Settings',
      timeAndPlace: 'Time and Place',
      time: 'Date and Time',
      place: 'Place',
      people: 'People',
      peoplePlaceholder: 'Who will be involved in the experiment?',
      resources: 'Resources and Instruments',
      resourcesPlaceholder: 'What resources will you need?',
      survey: 'Survey',
      surveyPlaceholder: 'Info on the survey you could use.',
      safetyBehaviors: 'Safety Behaviors',
      safetyBehaviorsPlaceholder:
        'What safety/avoidance behaviors will you suspend?',
      safetyBehaviorsTip: `Safety-seeking behaviors are actions taken to reduce negative emotions in situations where they occur. Suppressing these behaviors is crucial in behavioral experiments to prevent interference with the experiment and therapy process.
      If safety-seeking behaviors are allowed to persist, they can reinforce the individual's belief that the stimulus or situation is dangerous, and what helps is the safety behavior, thus maintaining or even exacerbating their negative emotions.
        `,
      safetyAndCoping: 'Safety Behaviors and Coping Strategies',
      helpfulCopingStrategies: 'Helpful Coping Strategies',
      helpfulCopingStrategiesPlaceholder:
        'What coping strategies can you use to manage the situation?',
      selectOrType: 'Select or type',
      hypothesisA: 'Hypothesis A',
      hypothesisAPlaceholder:
        'What is the thought or belief you would like to test?',
      hypothesisAStrength: 'How strongly you believe in Hypothesis A?',
      hypothesisATip:
        'Hypothesis A is current unhelpful cognition you want to test.',
      hypothesisAConfirmationCriteria:
        'Criteria/signs for confirming Hypothesis A',
      hypothesisB: 'Hypothesis B',
      hypothesisBPlaceholder:
        'What is a plausible and helpful alternative thought or belief you would like to test?',
      hypothesisBStrength: 'How strongly you believe in Hypothesis B?',
      hypothesisBTip:
        'Hypothesis B is a new, potentially helpful cognition you want to test.',
      hypothesisBConfirmationCriteria:
        'Criteria/signs for confirming Hypothesis B',
      experiment: 'Experiment',
      expremientPlaceholder: 'How can you test this hypothesis?',
      prediction: 'Prediction',
      predictionPlaceholder: 'What do you expect to happen?',
      expectedEmotions: 'Expected Emotions',
      expectedEmotionsPlaceholder:
        'What emotions do you expect to experience during the experiment?',
      expectedFeelings: 'Expected Body Sensations',
      behaviors: 'Expected reaction of the environment/people',
      behaviorsPlaceholder:
        'How do you expect the environemnt, other people and yourself to react?',
      physicalReactions: 'Physical Reactions',
      physicalReactionsPlaceholder: 'How do you expect your body will react?',
      otherPredictions: 'Other Predictions',
      otherPredictionsPlaceholder: 'What else do you expect to happen?',
      outcome: 'Outcome',
      outcomePlaceholder: 'What happened?',
      actualBehavior: 'Actual reaction of the environment/people',
      actualBehaviorPlaceholder:
        'How did the environemnt, other people and yourself react?',
      actualEmotions: 'Actual Emotions',
      actualFeelings: 'Actual Body Sensations',
      newThought: 'New Thought or Belief',
      newThoughtPlaceholder:
        'Based on the evidence from the experiment, what is your new thought?',
      evaluation: 'Evaluation',
      newLearnings: 'New Learnings',
      newLearningsPlaceholder: 'What did you learn from the experiment?',
      meaningForOriginalBelief: 'Meaning for the original belief',
      meaningForOriginalBeliefPlaceholder:
        'What does this mean for your original belief or prediction?',
      oldThoughtStrength:
        'How strongly do you believe in your old thought or belief after the experiment?',
      newThoughtStrength:
        'How strongly do you believe in your new thought or belief?',
      generateGptSuggestions: 'Generate ChatGPT Suggestions',
      generateGptSuggestionsMore: 'Generate Another Variant',
      generateGptSuggestionsTip: ``,
      generating:
        'Generating suggestions using ChatGPT. This can take some time...',
      generationError: 'Error generating suggestions',
      patientReflections: 'Patient Reflections',
      patientReflectionsPlaceholder: 'Enter patient relfections?',
      therapistReflections: 'Therapist Reflections',
      therapistReflectionsPlaceholder: 'Enter therapist relfections?',
      followUp: 'Follow-up Experiments',
      followUpExperimentsIdeas: 'Follow-up Experiments Ideas',
      followUpExperimentsIdeasPlaceholder: 'Enter follow-up experiments ideas?',
      generatedWarning: `Warning!
      
      Some of the content below has been generated by ChatGPT.
      
      It is your responsibility as a Mental Health Professional to review and edit the content before using it with your clients.`,
      generatedVariant: 'Generated Variant',
      saveSuccess: 'Behavior Experiment saved successfully',
      purposeSelectText: 'Choose experiment purpose',
      designSelectText: 'Choose experiment design',
      hypothesisTypeSelectText: 'Choose number of hypotheses',
      typeSelectText: 'Choose experiment type',
      activeSubtypeSelectText: 'Choose active experiment subtype',
      observationalSubtypeSelectText: 'Choose observational experiment subtype',
      jumpToOutcome: 'Jump to Outcome',
    },
    behaviorExperiments: {
      title: 'Behavior Experiments',
      initialThoughtOrBelief: 'Initial Thought or Belief',
      edit: 'Edit Behavior Experiment',
      create: 'Create Behavior Experiment',
    },
    exposure: {
      title: 'Exposure',
      titlePlural: 'Exposures',
      chooseExpositionType: 'Choose Exposure Type',
      type: 'Exposure Type',
      types: [
        {
          name: 'Graduated Exposure',
          description:
            'Graduated Exposure, also known as Systematic Desensitization, is a technique used in Cognitive-Behavioral Therapy (CBT) to help individuals with phobias or anxiety disorders gradually confront and overcome their fears (or other negative emotions). It involves creating a hierarchy of fear-inducing situations or stimuli, starting with the least anxiety-provoking and progressing to the most anxiety-provoking. The individual is then exposed to each item in the hierarchy, one at a time, while practicing relaxation or coping techniques. By gradually facing their fears (or other emotions) in a controlled and structured manner, the individual learns to manage and reduce their anxiety, ultimately leading to the extinction of the response.',
        },
        {
          name: 'Exposure and Response Prevention',
          description:
            'Exposure and Response Prevention (ERP) is a form of cognitive-behavioral therapy (CBT) primarily used to treat obsessive-compulsive disorder (OCD) and related anxiety disorders. ERP involves gradual exposure to fear-inducing situations or stimuli while preventing the individual from engaging in compulsive or avoidance behaviors. The goal of ERP is to help the person confront their fears, reduce anxiety, and break the cycle of obsessions and compulsions. Through repeated exposure and practice, individuals learn to tolerate anxiety and resist engaging in compulsive behaviors, eventually leading to a decrease in the intensity and frequency of their symptoms.',
        },
      ],
      avoidances: [
        'Never Avoid',
        'Occasionally Avoid',
        'Avoid Half of the Times',
        'Freqnetly Avoid',
        'Always Avoid',
      ],
      typeRequired: 'Exposure Type is a required field',
      fear: 'Fear or worry',
      fearPlaceholder: 'What is the fear or worry you have?',
      obsession: 'Obsession',
      obsessionPlaceholder:
        'OBSESSION: What is the thought or image that bothers you?',
      compulsion: 'Compulsion',
      compulsionPlaceholder:
        'COMPULSION: What do you typically do to reduce the anxiety?',
      compulsionTip:
        'What are the safety behaviors you typically engage in and want to reduce/stop?',
      situationsOrStimuliHierarchy: 'Situations or Stimuli Hierarchy',
      generateHierarchy: 'Generate Hierarchy using ChatGPT',
      situationOrStimulus: 'Situation or Stimulus',
      situationOrStimulusTitle: 'Situation or Stimulus Title',
      situationOrStimulusTitlePlaceholder: 'What is the situation or stimulus?',
      situationOrStimulusDescription: 'Description',
      situationOrStimulusDescriptionPlaceholder:
        'Describe the situation or stimulus',
      imagePrompt: 'AI Image Prompt',
      imagePromptTip:
        'Image Prompt you can use to generate images for situation or stimulus using AI (Dalle 3)',
      imagePromptPlaceholder: 'Enter Dalle 3 AI Image Prompt',
      imageDeleteWarning: 'Are you sure you want to delete this image?',
      addSituationOrStimuli: 'Add Situation or Stimulus',
      sud: 'Level of stress and anxiety (SUD)',
      sudTip: 'How distressing is the situation or stimulus? (0-100)',
      saveSuccess: 'Exposure saved successfully',
      situationOrStimulusSaveSuccess:
        'Situation or Stimulus saved successfully',
      rank: 'Rank',
      editTitleAndDescription: 'Edit Title and Description',
      avoiding: 'Avoiding',
      doYouAvoid: 'Do you avoid this situation or stimulus?',
      worstThingThatCouldHappen:
        'Worst thing that could happen in this situation?',
      situationOrStimuliImages: 'Situation or Stimuli Images',
      generateImageUsingAI: 'Generate Image using AI',
      generateImageUsingAIMobile: 'Generate Image with AI',
      deleteAvailable120Minutes:
        'Delete is available for therapists only upto 2 hours after creation to prevent deletion of client-generated content',
      startExposure: 'Start a New Exposure',
      newExposure: 'New Exposure',
      anticipated: 'Anticipated',
      reactionNotes: 'Notes',
      addReaction: 'Add Reaction',
      exposureGraph: 'Exposures Graph',
      expositionDuration: 'Exposition Duration',
      highestSud: 'Highest SUD',
      endSud: 'End SUD',
      duration: 'Duration',
      imageGenerationError: 'Error generating image',
      contentFilter:
        'We could not generate the image due to OpenAI content filter restirctions. Please try again with a different prompt that is more neutral.',
      safetyBehaviorsToAvoid: 'Safety Behaviors (Compulsion) to Avoid',
      edit: 'Edit Exposure',
      create: 'Create Exposure',
      upsertExposureLoading: 'Saving Exposure...',
      stimuludDeleteWarning:
        'Warning! This will delete the situation or stimulus and all related information. Are you sure you want to delete this exposure?',
      editDetails: 'Edit Details',
    },
    downwardArrows: {
      downwardArrows: 'Downward Arrows',
      initialThought: 'Initial Thought',
      totalCount: 'Total: {{count}}',
      edit: 'Edit Downward Arrow',
      create: 'Create Downward Arrow',
    },
    downwardArrow: {
      title: 'Downward Arrow',
      saveSuccess: 'Downward Arrow saved successfully',
      thought: 'Thought, Underlying Assumption or Belief',
      question: 'If that were true it would bother me because it would mean...',
      description: `The Downward Arrow technique is used to get from superficial levels of thoughts (negative automatic thoughts) to deeper levels (underlying assumptions or core beliefs) that underlie a person's distress or problematic behavior.

It involves asking a series of questions that aim to identify the underlying assumptions, beliefs, or fears that are driving a person's negative thoughts. The technique gets its name from the visual metaphor of "digging deeper" into a person's beliefs, starting with a surface-level thought and using a series of questions to get to the root cause of the negative thought.

Socratic dialogue is a key component of the Downward Arrow technique. It involves the therapist asking questions in a non-judgmental, curious, and exploratory manner, encouraging the patient to examine their thoughts and beliefs more closely. 

The therapist asks the patient (or the patient asks himself) to identify their negative automatic thought, such as "I'm a failure." The therapist then asks a series of questions to dig deeper, such as "What does it mean to you to be a failure?" and "Why is that important to you?" By continuing to ask these types of questions, the therapist can help the patient uncover the underlying beliefs and assumptions that are fueling their negative thoughts.
      `,
    },
    worryTrees: {
      worryAbout: 'Worry',
      isReal: 'Is real',
      notSolvable: 'Not solvable',
      options: 'Options to solve',
      immediateAction: 'Immediate action',
      plan: 'Plan for later',
      refocusActivity: 'Refocus activity',
      definition:
        'The Worry Decision Trees tool in Cognitive Behavior Therapy is a systematic approach for conceptualizing and treating Generalized Anxiety Disorder (GAD) by breaking down complex, anxiety-provoking situations into smaller, more manageable parts. This tool helps clients with GAD to avoid "analysis paralysis" and take action by distinguishing the type of worry they are facing and identifying appropriate responses. By using this tool, clients are encouraged to view their problems as challenges that can be overcome through problem-solving skills rather than getting stuck in worry and anxiety. The Worry Decision Trees tool aims to communicate to clients that there is a better alternative to worrying and that they can improve their problem-solving skills to manage their anxiety effectively.',
      deleteSuccess: 'Worry Decision Trees cycle deleted successfully',
      view: 'View the Worry Tree',
      create: 'Create Worry Decision Tree',
    },
    worryTree: {
      worryTree: 'Worry Tree',
      worryTrees: 'Worry Trees',
      worryTreesTitle: 'Worry Decision Trees',
      title: 'Worry Decision Tree',
      worryAbout: 'What am I worrying about?',
      isReal: 'Is this a real problem?',
      decision: 'Decision',
      selectedActivity: 'Selected Activity',
      listOptions: 'List your options to solve the problem.',
      developPlan: 'Plan what you could do and when you will do it.',
      isDoable: 'Can I do anything to solve this problem?',
      isDoableNow: 'Can I do anything right now?',
      refocusTitle: 'Let the worry go. Change the focus of your attention.',
      doneTitle: 'Let the worry go. Do the action now.',
      immediateAction: 'Name the thing that you are going to do right now.',
      savedForReference: 'Your Worry Tree has been saved for future reference.',
      saveSuccess: 'Worry Tree saved successfully',
      refocusIdeas: [
        {
          title: 'Practice deep breathing',
          info: 'Take slow, deep breaths in and out to help slow down your heart rate and calm your mind.',
        },
        {
          title: 'Engage in physical activity',
          info: 'Exercise or go for a walk to help release endorphins and reduce stress.',
        },
        {
          title: 'Practice mindfulness',
          info: 'Focus on the present moment by paying attention to your thoughts, feelings, and sensations without judgment.',
          mobileExtraInfo:
            'You can go the Meditations section by following the link below.',
          link: 'screens.meditations',
        },
        {
          title: 'Do a creative activity',
          info: 'Engage in an activity that requires your full attention, such as drawing, painting, or playing an instrument.',
        },
        {
          title: 'Listen to music',
          info: 'Listen to calming music or your favorite songs to help change focus.',
        },
        {
          title: 'Practice visualization',
          info: 'Visualize a peaceful scene or situation that helps you feel calm and relaxed.',
        },
        {
          title: 'Write in a journal',
          info: 'Write down your thoughts and feelings to help organize them and gain clarity.',
        },
        {
          title: 'Do a puzzle',
          info: 'Engage in a challenging puzzle or game to help refocus your attention.',
        },
        {
          title: 'Practice gratitude',
          info: 'Focus on the things in your life that you are thankful for and express gratitude for them.',
        },
        {
          title: 'Seek professional help',
          info: 'If your anxiety is persistent and interfering with your daily life, consider seeking help from a mental health professional.',
          mobileExtraInfo:
            'You can go the Therapists Search section by following the link below.',
          link: 'screens.therapists',
          root: 'screens.tests',
        },
        {
          title: 'Practice Progressive Muscle Relaxation',
          info: '',
          mobileExtraInfo:
            'You can find a video and instructions on how to do it by following the link below.',
          link: 'screens.muscleRelaxation',
          root: 'screens.crisis',
        },
      ],
    },
  },
  audios: [
    {
      url: 'meditation-1-en.m4a',
      title: 'Breathing body',
      duration: 319,
    },
    {
      url: 'meditation-4-en.m4a',
      title: 'A safe place',
      duration: 362,
    },
    {
      url: 'meditation-2-en.m4a',
      title: 'Hourglass',
      duration: 227,
    },
    {
      url: 'meditation-3-en.m4a',
      title: 'Sounds and thoughts',
      duration: 229,
    },
  ],
  videos: [
    {
      id: '9c_Bv_FBE-c',
      name: 'What is CBT',
    },
    {
      id: 'bUOaHsxe8OQ',
      name: 'What is CBT',
    },
    {
      id: 'WRRdSm4ZjX4',
      name: 'ABC Model',
    },
    {
      id: 'RyWXTK4xDD0',
      name: 'Automatic Negative Thoughts',
    },
    {
      id: 'Yw1p9YlZKEU',
      name: 'Core Beliefs',
    },
    {
      id: 'qJpwEFTh1y0',
      name: 'Depression and Anxiety',
    },
    {
      id: '3VIL1L_ypMg',
      name: 'CBT Exercises',
    },
  ],
  cognitiveDistorions: {
    10: 'All-or-Nothing thinking (black and white thinking, polarized thinking)',
    20: 'Catastrophizing',
    30: 'Over-generalization',
    40: 'Discounting the positive (devaluation) / exaggerating the negative',
    50: 'Should Statements (unrealistic, super high standards, "I have to" mindset)',
    60: 'Labeling and Mislabeling',
    70: 'Personalization (taking things personally, attribution)',
    80: 'Subjective reasoning (emotional reasoning)',
    90: 'Jumping to conclusions (illogical thinking)',
    100: 'Mental Filters (selective abstraction)',
    110: 'Tunnel Vision (e.g. seeing only the negative)',
    120: 'Mind reading, fortune-telling',
  },
  oldVersionModal: {
    appOutdatedTitle: 'App is Outdated',
    yourVersionIsOutdated:
      'The App version is outdated and needs to be upgraded.',
    installWarning:
      '⚠️ If the button below does not redirect you to the App/Play Store automatically, please update the App manually.',
    getUpdate: 'Get Update',
  },
};
